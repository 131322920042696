import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Utils
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class StatisticsService {
    static getAdminDashboardInfos() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/statistics/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('dashboard_loading_failed');
            return;
        });
    }

    static getUserDashboardInfos() {
        return Axios.get(BACKEND_ENDPOINT + 'statistics/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('dashboard_loading_failed');
            return;
        });
    }

    static getHistories(startDate, endDate, period, accumulate, cancelTokenSource) {
        return Axios.get(`${BACKEND_ENDPOINT}admin/statistics/histories?startDate=${startDate.toJSON()}&endDate=${endDate.toJSON()}&period=${period}&accumulate=${accumulate}`, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' },
            cancelToken: cancelTokenSource?.token
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) if (navigator.onLine) showToast('trees_histories_loading_failed'); // TODO
            return;
        });
    }

    static getCanopiesWithoutOverlap(baseProjectId, elementIds) {
        return Axios.post(BACKEND_ENDPOINT + 'statistics/canopies/', { baseProjectId, elementIds }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('canopies_loading_failed');
            return;
        });
    }
}