import React, { Component } from 'react';
// Composants
import { Button, Form, Input, Label, Message, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker } from 'react-color';
import IconPicker from '../../Utils/IconPicker';
// Librairies
import { faCheck, faHouse, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setCustomFieldCategories, setOrganizationCustomFields } from '../../../actionCreators/usersActions';
import i18n from '../../../locales/i18n';
import reactCSS from 'reactcss';
import { isMobileOnly } from 'react-device-detect';

// Services
import CustomFieldsService from '../../../services/CustomFieldsService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';

const defaultStyles = {
    'default': {
        color: {
            width: '20px',
            height: '20px',
            borderRadius: '50%'
        },
        swatch: {
            padding: '3px',
            marginLeft: '1px',
            background: '#fff',
            borderRadius: '50%',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            position: 'relative',
            top: '2px'
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
            top: '5px',
            left: '35px'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            zIndex: '-1'
        }
    }
};

const initialErrorState = {
    label: false,
    messages: []
};

class CustomFieldCategoryForm extends Component {
    state = {
        customFieldCategory: {
            label: '',
            color: 'rgba(175, 175, 175, 1)',
            icon: 'plus',
            organizationId: this.props.activeOrganization.id
        },
        linkedCustomFields: [],
        error: initialErrorState,
        isColorPickerOpen: false
    };

    render() {
        const { isOnline, customFieldCategoryToEdit, customFieldCategories, organizationCustomFields, customFieldTypes } = this.props;
        const { customFieldCategory, linkedCustomFields, error } = this.state;
        const customFields = (organizationCustomFields || []).map(ucf => {
            const customFieldCategory = customFieldCategories.find(ucfc => ucfc.id === ucf.customFieldCategoryId);
            return {
                key: ucf.id, text: ucf.label, value: ucf.id,
                content: (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <FontAwesomeIcon icon={customFieldTypes.find(cft => cft.key === ucf.type).icon} style={{ marginRight: '10px' }} />
                        <span style={{ marginRight: '10px' }}>{ucf.label}</span>
                        <div>
                            {ucf.tags?.length ? ucf.tags.map(tag =>
                                <Label className='label--secondary' style={{ fontSize: '10px' }}>
                                    {tag}
                                </Label>)
                                : <Label color='grey' style={{ fontSize: '10px' }}>
                                    {i18n.t("Aucun tag")}
                                </Label>}
                        </div>
                        <Label style={{ marginLeft: 'auto', fontSize: '10px', backgroundColor: customFieldCategory?.color || 'grey', color: 'white' }}>
                            <FontAwesomeIcon icon={customFieldCategory?.icon || faHouse} style={{ marginRight: '10px' }} />
                            {customFieldCategory?.label || i18n.t("Général")}
                        </Label>
                    </div>
                )
            };
        });

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} error>
                    <Form.Field
                        control={Input} width={isMobileOnly ? 16 : 5} label={i18n.t("Libellé") + '* :'} placeholder={`Ex: ${i18n.t("Indicateurs")}`}
                        name='label' value={customFieldCategory.label}
                        onChange={this.handleChange} error={error.label}
                    />
                    <div>
                        <label style={{ fontWeight: 'bolder' }}>{i18n.t("Couleur")}* :</label>
                        {this.renderColorPicker()}
                    </div>
                    <div>
                        <label style={{ fontWeight: 'bolder' }}>{i18n.t("Icône")}* :</label>
                        <IconPicker iconName={customFieldCategory.icon} direction='bottomright' closeOnSelect={true} handleIconChange={(icon) => this.setState(prevState => ({ customFieldCategory: { ...prevState.customFieldCategory, icon: icon.iconName } }))} />
                    </div>
                    <Form.Field
                        control={Select} label={`${i18n.t("Champs personnalisés liés")} :`} placeholder={i18n.t("Sélectionnez un ou plusieurs champs")}
                        name='linkedCustomFields' options={customFields} value={linkedCustomFields} clearable
                        multiple search={FormattersUtil.searchList} selection selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                        onChange={this.handleSelectedCustomFieldsChange}
                    />
                </Form>
                <div style={{ marginTop: 'auto' }}>
                    <Message
                        error style={{ textAlign: 'left' }} hidden={!error.messages.length}
                        header='Erreur' list={error.messages}
                    />
                    <Button type='button' color='red' onClick={this.props.cancel}>
                        <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Annuler")}
                    </Button>
                    <Button type='submit' color={customFieldCategoryToEdit ? 'yellow' : 'green'} disabled={!isOnline} onClick={this.handleSubmit}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{customFieldCategoryToEdit ? i18n.t("Modifier") : i18n.t("Valider")}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        const { customFieldCategoryToEdit, organizationCustomFields } = this.props;

        if (customFieldCategoryToEdit) {
            const linkedCustomFields = organizationCustomFields.filter(ucf => ucf.customFieldCategoryId === customFieldCategoryToEdit.id).map(ucf => ucf.id);
            this.setState({
                customFieldCategory: { ...customFieldCategoryToEdit },
                linkedCustomFields
            });
        }
    }

    renderColorPicker = () => {
        const { customFieldCategory, isColorPickerOpen } = this.state;
        const styles = reactCSS({ ...defaultStyles });

        return (
            <div style={{ position: 'relative' }}>
                {isColorPickerOpen &&
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={() => this.setState({ isColorPickerOpen: false })} />
                        <div style={{ zIndex: 4 }}>
                            <SketchPicker color={customFieldCategory.color}
                                onChange={color => {
                                    color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                    this.setState(prevState => ({ customFieldCategory: { ...prevState.customFieldCategory, color } }));
                                }}
                            />
                        </div>
                    </div>}
                <div style={{ ...styles.swatch, cursor: 'pointer', zIndex: 3, marginRight: '5px' }} onClick={() => this.setState({ isColorPickerOpen: true })}>
                    <div style={{ ...styles.color, backgroundColor: customFieldCategory.color }} />
                </div>
            </div>
        );
    }

    handleChange = (_, { name, value }) => {
        this.setState(prevState => ({
            customFieldCategory: { ...prevState.customFieldCategory, [name]: value },
            error: { ...prevState.error, [name]: false }
        }));
    }

    handleSelectedCustomFieldsChange = (_, { value }) => this.setState({ linkedCustomFields: [...value] });
    handleSubmit = () => {
        const { customFieldCategoryToEdit } = this.props;
        const { customFieldCategory, linkedCustomFields } = this.state;
        let isOk = true;
        let error = { ...initialErrorState };

        if (!customFieldCategory.label?.length) {
            isOk = false;
            error.label = true;
            error.messages.push(i18n.t("Le libellé est invalide"));
        }

        if (isOk) {
            const updateLinkedOrganizationCustomFields = (customFieldCategoryId) => {
                const userCustomFieldsRemoved = customFieldCategoryToEdit ? this.props.organizationCustomFields.filter(ucf => ucf.customFieldCategoryId === customFieldCategoryToEdit.id && !linkedCustomFields.includes(ucf.id)) : [];
                userCustomFieldsRemoved.forEach(ucfr => ucfr.customFieldCategoryId = null);

                const userCustomFieldsAdded = this.props.organizationCustomFields.filter(ucf => ucf.customFieldCategoryId !== customFieldCategoryId && linkedCustomFields.includes(ucf.id));
                userCustomFieldsAdded.forEach(ucfa => ucfa.customFieldCategoryId = customFieldCategoryId);

                const userCustomFieldsToUpdate = [...userCustomFieldsRemoved, ...userCustomFieldsAdded];

                if (userCustomFieldsToUpdate.length)
                    CustomFieldsService.updateCustomFields(userCustomFieldsToUpdate, false).then(updatedOrganizationCustomFields => {
                        if (updatedOrganizationCustomFields) {
                            const organizationCustomFields = [];
                            this.props.organizationCustomFields.forEach(ucf => {
                                const updatedUserCustomField = updatedOrganizationCustomFields.find(uucf => uucf.id === ucf.id);
                                organizationCustomFields.push(updatedUserCustomField || ucf);
                            });

                            this.props.setOrganizationCustomFields(organizationCustomFields);
                            WebSocketUtil.updateOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, organizationCustomFields);
                        }

                        this.props.cancel();
                    });
                else this.props.cancel();
            };

            if (customFieldCategoryToEdit) {
                if (customFieldCategory.label !== customFieldCategoryToEdit.label || customFieldCategory.color !== customFieldCategoryToEdit.color || customFieldCategory.icon !== customFieldCategoryToEdit.icon)
                    CustomFieldsService.updateCustomFieldCategories([customFieldCategory]).then(updatedCustomFieldCategories => {
                        if (updatedCustomFieldCategories) {
                            const customFieldCategories = [];
                            this.props.customFieldCategories.forEach(ucfc => {
                                customFieldCategories.push(ucfc.id !== updatedCustomFieldCategories[0].id ? ucfc : updatedCustomFieldCategories[0]);
                            });

                            this.props.setCustomFieldCategories(customFieldCategories);
                            WebSocketUtil.updateOrganizationCustomFieldCategories(this.props.webSocketHubs, this.props.activeOrganization.id, updatedCustomFieldCategories);
                            updateLinkedOrganizationCustomFields(customFieldCategory.id);
                        }
                    });
                else updateLinkedOrganizationCustomFields(customFieldCategory.id);
            } else
                CustomFieldsService.addCustomFieldCategory(customFieldCategory).then(customFieldCategory => {
                    if (customFieldCategory) {
                        const customFieldCategories = [...this.props.customFieldCategories, customFieldCategory]
                        this.props.setCustomFieldCategories(customFieldCategories);
                        this.props.setActiveCategory(customFieldCategory.id);
                        WebSocketUtil.sendOrganizationCustomFieldCategories(this.props.webSocketHubs, this.props.activeOrganization.id, [customFieldCategory]);
                        updateLinkedOrganizationCustomFields(customFieldCategory.id);
                    }
                });
        } else this.setState({ error });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        customFieldCategories: state.customFieldCategories,
        organizationCustomFields: state.organizationCustomFields,
        activeOrganization: state.activeOrganization,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setCustomFieldCategories,
    setOrganizationCustomFields
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldCategoryForm);