import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dimmer, Form, Grid, Message, Select } from 'semantic-ui-react';
// Librairies
import { faFloppyDisk, faTimes } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';

class SharingManagement extends Component {
    render() {
        const { onCancel, onChange, onSubmit, options, values, active, loading } = this.props;

        return (
            <Dimmer active={active} style={StylesUtil.getMapStyles().dimmerStyle}>
                <Grid style={{ height: '100%' }}>
                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='center'>
                            <Message className='tableConfirmation'>
                                <Message.Header>{i18n.t("Gestion des partages")}</Message.Header>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    <Form.Field
                                        control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs collaborateurs")}
                                        selectOnBlur={false} upward multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        options={options} value={values || []} onChange={(_, { value }) => onChange(value)}
                                        disabled={loading} style={{ width: '330px', marginBottom: '10px' }}
                                    />
                                    <Button type='button' color='green' onClick={onSubmit} disabled={loading || !this.props.isOnline} loading={loading}>
                                        <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                    </Button>
                                    <Button type='button' color='red' onClick={onCancel} disabled={loading}>
                                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(SharingManagement);