export const setUserInfos = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_USER_INFOS', data: action });
    return Promise.resolve();
};

export const setThemes = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_THEMES', data: action });
    return Promise.resolve();
};

export const setFavoriteTools = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_FAVORITE_TOOLS', data: action });
    return Promise.resolve();
};

export const setOrganizationCustomFields = (organizationCustomFields) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ORGANIZATION_CUSTOM_FIELDS', data: organizationCustomFields });
    return Promise.resolve();
};

export const setCustomFieldCategories = (customFieldCategories) => async (dispatch, getState) => {
    dispatch({ type: 'SET_CUSTOM_FIELD_CATEGORIES', data: customFieldCategories });
    return Promise.resolve();
};

export const setPriceLists = (priceList) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PRICE_LISTS', data: priceList });
    return Promise.resolve();
};

export const setHomeInfos = (homeInfos) => async (dispatch, getState) => {
    dispatch({ type: 'SET_HOME_INFOS', data: homeInfos });
    return Promise.resolve();
};

export const setRoleTemplates = (templates) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ROLE_TEMPLATES', data: templates });
    return Promise.resolve();
};

export const setActiveOrganization = (activeOrganization) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ACTIVE_ORGANIZATION', data: activeOrganization });
    return Promise.resolve();
};

export const setOrganizations = (organizations) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ORGANIZATIONS', data: organizations });
    return Promise.resolve();
};