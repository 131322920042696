import React, { Component } from 'react';
// Composants
import ImportPhotosDropZone from '../../../Dropzones/ImportPhotosDropZone';
import { Button, Form, Label, Segment } from 'semantic-ui-react';
// Librairies
import i18n from '../../../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import FormattersUtil from '../../../../utils/FormattersUtil';

class ImportPhotosForm extends Component {
    render() {
        const { isLoading, config } = this.props;

        return (
            <div className='modal-content-body' style={{ display: 'flex', flexDirection: 'column' }}>
                <Segment style={{ flex: '1' }}>
                    <Form loading={isLoading} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ display: 'flex', gap: '10px', flex: 1 }}>
                            <ImportPhotosDropZone handleConfigChange={this.props.handleConfigChange} />
                            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '350px', border: '1px solid white', borderRadius: '5px', padding: '10px' }}>
                                <h4>Fichier(s) sélectionné(s)</h4>
                                {config?.file
                                    ? <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>{this.renderFiles()}</div>
                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>{i18n.t("Aucun fichier sélectionné.")}</div>}
                            </div>
                        </div>
                        <small style={{ marginTop: '10px' }}>{i18n.t("Taille maximale de l'archive")} : 500Mo</small>
                        <a href={'https://greenqualityindicatorst.blob.core.windows.net/blobs/Exemple de fichier d\'import de photos.zip'}>
                            <small>{i18n.t("Télécharger un exemple de fichier Zip")}</small>
                        </a>
                    </Form>
                </Segment>

            </div>
        );
    }

    renderFiles = () => {
        return [this.props.config.file].map(file => {
            const { name, size } = file;
            const extension = name.split('.')[name.split('.').length - 1];

            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label>{name.replace(`.${extension}`, '')}</label>
                    <Label title={i18n.t("Extension du fichier")} style={{ marginLeft: 'auto' }}>{extension.toUpperCase()}</Label>
                    <Label title={i18n.t("Taille du fichier")} style={{ marginLeft: '5px', marginRight: '5px' }}>{FormattersUtil.numberPrettyBytesSI(size)}</Label>
                    <Button color='red' title={i18n.t("Supprimer le fichier")} onClick={() => this.removeFile()} style={{ padding: 0, height: '100%', aspectRatio: '1 / 1' }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            );
        });
    }

    removeFile = () => {
        this.props.handleConfigChange(null, { name: 'file', value: null });
    }
}

export default ImportPhotosForm;