import React, { Component } from 'react';
// Librairies
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import { polygon, transformScale, multiPolygon, bboxPolygon, bbox } from '@turf/turf';
import tinycolor from 'tinycolor2';
import { connect } from 'react-redux';
// Ressources
import GDK from '../../resources/gdk'; // Google Dark Theme
// Styles
import 'leaflet/dist/leaflet.css';
// Utils
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import StylesUtil from '../../utils/StylesUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';

class MapPreview extends Component {
    render() {
        let { key, id, title, style, hideAttribution, onClick } = this.props;
        const previewStyle = { height: '100%', width: '100%', borderRadius: 0, marginTop: 0, zIndex: 1, ...(style || {}) }

        return (
            <div key={key || 'mapPreview1'} id={`mapPreview${id}`} className={`mapPreview ${hideAttribution ? 'hideAttribution' : ''}`} style={previewStyle} title={title} onClick={onClick}></div>
        );
    }

    componentDidMount = () => {
        const { id, surroundings, features, dragging, zooming, allowMoving, isDarkTheme } = this.props;
        this.layers = [];
        const layers = this.props.noLayers ? null
            : isDarkTheme ?
                L.gridLayer.googleMutant({
                    maxZoom: 22,
                    type: 'roadmap',
                    styles: GDK
                })
                : L.gridLayer.googleMutant({
                    maxZoom: 22,
                    type: 'roadmap',
                    styles: [
                        { elementType: 'labels', stylers: [{ visibility: 'off' }] }, // On masque tous les labels
                        { featureType: 'road', elementType: 'labels.text', stylers: [{ visibility: 'on' }] }, // Sauf les noms des routes
                        { featureType: 'administrative', elementType: 'labels.text', stylers: [{ visibility: 'on' }] } // Et des communes/provinces
                    ]
                });

        this.surroundingsLayer = null;
        if (surroundings) {
            const surroundingsToRender = JSON.parse(JSON.stringify(surroundings));
            // On crée la map en lui fournissant les bounds à ne pas dépasser
            const surroundingsPolygon = surroundingsToRender.geometry.type === 'Polygon'
                ? polygon(surroundingsToRender.geometry.coordinates)
                : multiPolygon(surroundingsToRender.geometry.coordinates);
            const boundsPolygon = bboxPolygon(bbox(surroundingsPolygon));
            const scaledCoordinates = transformScale(boundsPolygon, 1.2).geometry.coordinates;
            this.maxBounds = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(scaledCoordinates)).getBounds();

            const geoJson = GeoJsonUtil.generateGeoJson(GeometriesUtil.invertPolygon(surroundingsPolygon));
            this.surroundingsLayer = L.geoJson(geoJson, { // On affiche les polygones issus du GeoJSON en le stylisant
                style: this.getSurroundingsStyle,
                pmIgnore: true
            });
        }

        let mapOptions = { layers, minZoom: 3, zoomControl: false, attributionControl: false };
        if (!allowMoving) {
            mapOptions.maxBounds = this.maxBounds;
            mapOptions.maxBoundsViscosity = 1.1;
        }

        this.map = L.map(`mapPreview${id}`, mapOptions);
        if (this.maxBounds) this.map.fitBounds(this.maxBounds);
        if (this.surroundingsLayer) this.map.addLayer(this.surroundingsLayer);

        if (features?.length) this.showFeatures(features);

        // Désactivation des intéractions avec la carte
        if (!dragging) this.map.dragging.disable();
        if (!zooming) {
            this.map.touchZoom.disable();
            this.map.doubleClickZoom.disable();
            this.map.scrollWheelZoom.disable();
            this.map.boxZoom.disable();
        }
        this.map.keyboard.disable();
        if (this.map.tap) this.map.tap.disable();
        document.getElementById(`mapPreview${id}`).style.cursor = 'pointer';
    }

    componentDidUpdate = (prevProps) => {
        const { features } = this.props;
        if (prevProps.features !== features) this.showFeatures(features);
        if (this.surroundingsLayer) this.surroundingsLayer.setStyle(this.getSurroundingsStyle());
    }

    showFeatures = (features) => {
        const { theme, zoomOnFeatures } = this.props;

        this.layers.forEach(layer => this.map.removeLayer(layer));
        this.layers = [];

        features.forEach(feature => {
            const { type, coordinates } = feature.geometry;
            const { category } = feature.properties;
            const isMarker = ['marker', 'point'].includes(type.toLowerCase());

            // Ajout à la carte
            const elementStyle = category === 'Arbre' ? this.props.elementStyle?.tree
                : category === 'Mobilier' ? this.props.elementStyle?.furniture
                    : category === 'Repère' ? this.props.elementStyle?.marker
                        : category === 'Espace vert' ? this.props.elementStyle?.greenSpace
                            : this.props.elementStyle?.station;
            const style = theme?.primary ? { ...elementStyle, fillColor: theme.primary } : elementStyle;
            const layer = isMarker ? L.circleMarker({ lat: coordinates[1], lng: coordinates[0] }, style) : L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(coordinates), style);
            layer.feature = feature;
            this.map.addLayer(layer);
            this.layers.push(layer);
        });

        // Le setTimeout permet de laisser le temps au container Google Mutant de s'initialiser pour que l'élément soit bien centré
        if (this.layers.length === 1 && this.layers[0].getLatLng) setTimeout(() => this.map.setView(this.layers[0].getLatLng(), 15, { animate: false }), 100)
        else if ((!this.maxBounds || zoomOnFeatures) && this.layers.length) setTimeout(() => this.map.fitBounds(L.featureGroup(this.layers).getBounds(), { animate: false }), 100);
        else if (this.maxBounds) setTimeout(() => this.map.fitBounds(this.maxBounds, { animate: false }), 100);
    }

    getSurroundingsStyle = () => {
        const { isDarkTheme, theme } = this.props;
        const surroundingsStyle = isDarkTheme ? StylesUtil.getSurroundingsDarkStyle() : StylesUtil.getSurroundingsLightStyle();
        let fillColor = ((isDarkTheme && theme?.black) || (!isDarkTheme && theme?.grey)) && tinycolor(isDarkTheme ? theme.black : theme.grey);
        if (!fillColor) fillColor = isDarkTheme ? 'var(--black-80)' : 'var(--grey-80)';
        const themeStyle = theme ? { color: theme?.primary || surroundingsStyle.color, fillColor } : {};
        return { ...surroundingsStyle, ...themeStyle, weight: 1.5 };
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(MapPreview);