import React, { Component } from 'react';
// Composants
import InfoIcon from '../Utils/InfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Label, Card, Button, Segment, Divider } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import MapPreview from '../Utils/MapPreview';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { withSize } from 'react-sizeme';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setPhotosGalleries } from '../../actionCreators/elementsActions';
import { booleanPointInPolygon } from '@turf/turf';
// Ressources
import { faCalculator, faCrown, faEye, faHeartbeat, faListAlt, faMapMarkedAlt, faPencilRuler, faSkull, faTachometerAlt, faTag, faTree, faAxe, faQuestionCircle, faArrowUpRightFromSquare, faImage, faUserHelmetSafety, faCalendarClock, faSquareCheck, faExclamationTriangle, faAward, faWarning, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { faCircle, faDotCircle } from '@fortawesome/pro-regular-svg-icons';
// Styles
import '../../styles/details.css';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import TreesUtil from '../../utils/TreesUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';
import FormulasUtil from '../../utils/FormulasUtil';
import DatesUtil from '../../utils/DatesUtil';
import RightsUtil from '../../utils/RightsUtil';
import FormattersUtil from '../../utils/FormattersUtil';

const initialState = {
    requiredFields: null,
    publicFields: null,
    feature: null,
    properties: null,
    customFields: [],
    stationLabels: []
};

const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
};

const treeOrgans = [
    { organ: 'root', label: i18n.t("racines") },
    { organ: 'collar', label: i18n.t("collet") },
    { organ: 'trunk', label: i18n.t("tronc") },
    { organ: 'branch', label: i18n.t("branches") },
    { organ: 'leaf', label: i18n.t("feuilles") }
];

class TreeDetail extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { activeOrganization, project, layer, currentYear, timelineYears, photosGalleries, rights, isDarkTheme } = this.props;
        const { id, requiredFields, publicFields, properties, customFields, stationLabels } = this.state;
        const projectSubscription = project.organization.subscription;
        let stumpDiameter = null;
        if (properties?.trunks?.length) stumpDiameter = TreesUtil.getStumpDiameterByCircumference(Math.max(...properties.trunks.map(t => t.circumference)));

        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        const labelStyle = { margin: '0 5px', display: 'flex' };
        const width = this.props.size.width;

        if (properties && requiredFields && publicFields) {
            const firstTrunk = properties.trunks?.length ? properties.trunks[0] : null;
            const biggestTrunk = TreesUtil.getBiggestTrunk(properties.trunks);
            const treeCanopy = biggestTrunk ? Math.round((Math.pow((biggestTrunk.crownDiameter / 100) / 2, 2) * Math.PI) * 100) / 100 : 0;
            const treeStability = biggestTrunk && biggestTrunk.circumference > 0 ? Math.round(biggestTrunk.height / (biggestTrunk.circumference / Math.PI)) : 0;
            const treesRF = requiredFields.trees;
            const treesPF = publicFields.trees;
            const defaultFieldCategories = this.props.defaultFieldCategories.filter(dfc => dfc.category === 'Arbre');
            let coordinates = layer[0].feature.geometry.coordinates;
            if (layer && layer[0]) coordinates = GeoJsonUtil.projectMarkerCoordinates(coordinates, this.props.projections, project?.projectionId);

            const SR = { // shouldRender
                isEmpty: treesRF.isEmpty && treesPF.isEmpty && properties.isEmpty,
                isDead: treesRF.isDead && treesPF.isDead && properties.isDead,
                isStump: treesRF.isStump && treesPF.isStump && properties.isStump,
                isIndexed: treesRF.isIndexed && treesPF.isIndexed && properties.isIndexed,
                isRemarkable: treesRF.isRemarkable && treesPF.isRemarkable && properties.isRemarkable,
                tags: treesRF.tags && treesPF.tags && properties.tags?.length > 0,
                place: treesRF.place && treesPF.place && properties.place?.trim(),
                plantationType: treesRF.plantationType && treesPF.plantationType && properties.plantationType,
                coverType: treesRF.coverType && treesPF.coverType && properties.coverType,
                interactions: treesRF.interactions && treesPF.interactions && properties.interactions?.length > 0,
                microHabitats: treesRF.microHabitats && treesPF.microHabitats && properties.microHabitats?.length > 0,
                ...treeOrgans.reduce((prevValue, { organ }) => ({
                    ...prevValue,
                    [`${organ}Symptoms`]: treesRF[`${organ}Symptoms`] && treesPF[`${organ}Symptoms`] && properties[`${organ}Symptoms`]?.length > 0,
                    [`${organ}Pathogens`]: treesRF[`${organ}Pathogens`] && treesPF[`${organ}Pathogens`] && properties[`${organ}Pathogens`]?.length > 0,
                    [`${organ}Pests`]: treesRF[`${organ}Pests`] && treesPF[`${organ}Pests`] && properties[`${organ}Pests`]?.length > 0,
                    [`${organ}Epiphytes`]: ['trunk', 'branch'].includes(organ) && treesRF[`${organ}Epiphytes`] && treesPF[`${organ}Epiphytes`] && properties[`${organ}Epiphytes`]?.length > 0
                }), {}),
                vernacularName: treesRF.vernacularName && treesPF.vernacularName && properties.essence?.vernacularName,
                order: (treesPF.gender || treesPF.species || treesPF.cultivar) && properties.essence?.order,
                gender: treesRF.gender && treesPF.gender && properties.essence?.gender,
                species: treesRF.species && treesPF.species && properties.essence?.species,
                cultivar: treesRF.cultivar && treesPF.cultivar && properties.essence?.cultivar,
                ontogenicStage: treesRF.ontogenicStage && treesPF.ontogenicStage && properties.ontogenicStage,
                plantingDate: treesRF.plantingDate && treesPF.plantingDate && properties.plantingDate,
                age: treesRF.age && treesPF.age && properties.age > 0,
                healthReview: treesRF.healthReview && treesPF.healthReview && properties.healthReview,
                vigor: treesRF.vigor && treesPF.vigor && properties.vigor,
                risk: treesRF.risk && treesPF.risk && properties.risk,
                accurateRisk: treesRF.accurateRisk && treesPF.risk && (properties.tippingRisk || properties.organCaliber || properties.target),
                treePort: treesRF.treePort && treesPF.treePort && properties.treePort,
                stumpDiameter: treesRF.isStump && treesRF.trunks && treesPF.stumpDiameter && properties.isStump && stumpDiameter,
                height: properties.dimensions.height > 0 && properties.dimensions.height !== firstTrunk?.height,
                numberOfTrunks: treesRF.numberOfTrunks && treesPF.numberOfTrunks && properties.numberOfTrunks > 0,
                trunkHeight: treesRF.trunkHeight && treesPF.trunkHeight && properties.dimensions.trunkHeight > 0,
                trunks: treesRF.trunks && treesPF.trunks && properties.trunks?.length > 0,
                plantationCoefficient: treesRF.plantationCoefficient && treesPF.plantationCoefficient && properties.plantationCoefficient,
                situationCoefficient: treesRF.situationCoefficient && treesPF.situationCoefficient && properties.situationCoefficient,
                patrimonialCoefficient: treesRF.patrimonialCoefficient && treesPF.patrimonialCoefficient && properties.patrimonialCoefficient,
                observation: treesRF.observation && treesPF.observation && properties.observation,
                carbonStock: treesRF.carbonStock && treesPF.carbonStock && properties.carbonStock > 0 &&
                    (projectSubscription?.carbonStockFormula || !projectSubscription) && activeOrganization?.subscription.carbonStockFormula,
                totalCarbonStock: treesRF.carbonStock && treesPF.carbonStock && properties.totalCarbonStock > 0 &&
                    (projectSubscription?.carbonStockFormula || !projectSubscription) && activeOrganization?.subscription.carbonStockFormula,
                fruitProduction: properties.essence?.fruitProduction > 0 && treesPF.isFruit,
                coolingIndicator: treesRF.coolingIndicator && treesPF.coolingIndicator && properties.coolingIndicator > 0 &&
                    (projectSubscription?.coolingFormula || !projectSubscription) && activeOrganization?.subscription.coolingFormula,
                coolingEnergyIndicator: treesPF.coolingIndicator && properties.coolingEnergyIndicator > 0,
                coolingEconomicValue: treesPF.coolingIndicator && properties.coolingEconomicValue > 0,
                oxygenProduction: treesRF.oxygenProduction && treesPF.carbonStock && properties.oxygenProduction > 0,
                biodiversityIndex: treesRF.biodiversityIndex && treesPF.biodiversityIndex && properties.biodiversityIndex > 0,
                canopy: treesRF.canopy && treesRF.trunks && treesPF.canopy && treeCanopy > 0 &&
                    (projectSubscription?.canopyFormula || !projectSubscription) && activeOrganization?.subscription.canopyFormula,
                stability: treesRF.stability && treesRF.trunks && treesPF.trunks && treeStability > 0,
                amenityValue: treesRF.amenityValue && treesPF.amenityValue && !properties.isEmpty && properties.essenceId && projectSubscription?.amenityFormula && properties.amenityValue > 0,
                customFields: properties.customFields && Object.keys(properties.customFields).length > 0
                    && (projectSubscription?.customFields || !projectSubscription)
                    && Object.keys(properties.customFields).some(key => properties.customFields[key] && customFields.find(cf => cf.id === Number(key)))
            };

            const observationCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Observation'));
            const coefficientsCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Coefficients'));
            const indicatorsCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Indicateurs'));
            const stateCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'État'));
            const environmentCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Environnement'));
            treeOrgans[0].customFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'VTA racines'));
            treeOrgans[1].customFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'VTA collet'));
            treeOrgans[2].customFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'VTA tronc'));
            treeOrgans[3].customFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'VTA branches'));
            treeOrgans[4].customFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'VTA feuilles'));
            const dimensionsCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Dimensions'));
            const descriptionCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Description'));
            const siteCustomFields = SR.customFields && this.renderFields(defaultFieldCategories.find(dfc => dfc.label === 'Emplacement'));
            const photos = photosGalleries.filter(photo => photo.elementId === id);
            photos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const previewPhoto = photos.length ? (photos.find(photo => photo.blobName === properties.previewBlobName) || photos[0]) : null;
            const latestActionsDone = this.getLatestActionsDone();
            const actionsToDo = this.getActionsToDo();

            return (
                <div className='modal-content'>
                    <div id='detail-grid' className='modal-content-body'>
                        {isMobileOnly && properties.projectReference > 0 &&
                            <div id='gUT35zHR' style={{ width: '100%', textAlign: 'center', marginBottom: '2px' }}>
                                {i18n.t("Référence")} : {properties.projectReference}{properties.customReference && (' | "' + properties.customReference) + '"'}
                            </div>}
                        {(properties.toCutDown === 4 || SR.isEmpty || SR.isDead || SR.isStump || SR.isIndexed || SR.isRemarkable || SR.tags) &&
                            <div id='tags'>
                                {properties.toCutDown === 4 &&
                                    <Label color='red' style={labelStyle}>
                                        <FontAwesomeIcon icon={faAxe} style={{ marginRight: '5px' }} />{i18n.t("Abattu")}
                                    </Label>}
                                {SR.isRemarkable &&
                                    <Label color='yellow' style={labelStyle}>
                                        <FontAwesomeIcon icon={faAward} style={{ marginRight: '5px' }} />{i18n.t("Remarquable")}
                                    </Label>}
                                {SR.isIndexed &&
                                    <Label className='label--secondary' style={labelStyle}>
                                        <FontAwesomeIcon icon={faCrown} style={{ marginRight: '5px' }} />{i18n.t("Classé")}
                                    </Label>}
                                {SR.isEmpty &&
                                    <Label className='label--secondary' style={labelStyle}>
                                        <FontAwesomeIcon icon={faCircle} style={{ marginRight: '5px' }} />{i18n.t("Vide")}
                                    </Label>}
                                {SR.isDead &&
                                    <Label className='label--secondary' style={labelStyle}>
                                        <FontAwesomeIcon icon={faSkull} style={{ marginRight: '5px' }} />{i18n.t("Mort")}
                                    </Label>}
                                {SR.isStump &&
                                    <Label className='label--secondary' style={labelStyle}>
                                        <FontAwesomeIcon icon={faDotCircle} style={{ marginRight: '5px' }} />{i18n.t("Souche")}
                                    </Label>}
                                {SR.tags &&
                                    // id='7C7XTt6g'
                                    <>
                                        {properties.tags.map(tag => (
                                            <Label key={tag} className='label--primary' style={{ ...labelStyle, whiteSpace: 'nowrap' }}>
                                                <FontAwesomeIcon icon={faTag} style={{ marginRight: '5px' }} />{tag}
                                            </Label>
                                        ))}
                                    </>}
                            </div>}
                        <Masonry breakpointCols={breakpointColumnsObj} className='detail-masonry' columnClassName='detail-masonry__column'>
                            {publicFields.main.photos && previewPhoto &&
                                <Card id='684F879A' title={i18n.t("Accéder à la galerie photos de l'arbre")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('PhotosGallery', i18n.t("Photos"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faImage} />
                                        {i18n.t("Aperçu")}
                                    </Card.Header>
                                    <Card.Content style={{ padding: 0, overflow: 'hidden', height: '50vh', display: 'flex', justifyContent: 'center', backgroundColor: '#111111' }}>
                                        <img src={previewPhoto.url} alt='img' style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                                    </Card.Content>
                                </Card>}
                            <Card id='eMziYNLn' style={{ borderBottom: 'solid 4px var(--yellow-100)' }}>
                                <Card.Header style={{ color: 'var(--yellow-100)' }}>
                                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    {i18n.t("Emplacement")}
                                </Card.Header>
                                <Card.Content>
                                    {SR.place && <Grid.Row id='KmL3Mkfm'><label>{i18n.t("Lieu")} :</label><span>{properties.place}</span></Grid.Row>}
                                    <Grid.Row><label>{i18n.t("Latitude (y)")} :</label><span>{coordinates[1]}</span></Grid.Row>
                                    <Grid.Row><label className='unselectable'>{i18n.t("Longitude (x)")} :</label><span>{coordinates[0]}</span></Grid.Row>
                                    {stationLabels?.length > 0 && <Grid.Row><label>{i18n.t("Stations")} :</label><span>{stationLabels.join(', ')}</span></Grid.Row>}
                                    {siteCustomFields}
                                </Card.Content>
                                <Card.Content style={{ padding: '1px' }}>
                                    <MapPreview
                                        id={layer[0].feature.id} style={{ height: '100px', width: '100%' }} features={[layer[0].feature]} elementStyle={{ tree: StylesUtil.getTreeStyle() }}
                                        title={i18n.t("Voir sur la carte")} onClick={() => this.props.showElement(properties.category, layer[0].feature, null, { highlight: false, blink: true })}
                                    />
                                </Card.Content>
                            </Card>
                            {publicFields.main.photos && previewPhoto && <div></div>}
                            {publicFields.main.photos && previewPhoto && <div></div>}
                            {(SR.vernacularName || SR.order || SR.gender || SR.species || SR.cultivar || SR.treePort || SR.ontogenicStage || SR.plantingDate || SR.age || (descriptionCustomFields && descriptionCustomFields.length > 0)) ?
                                <Card id='tnyk8PtV' style={{ borderBottom: 'solid 4px var(--orange-100)' }}>
                                    <Card.Header style={{ color: 'var(--orange-100)' }}>
                                        <FontAwesomeIcon icon={faListAlt} />
                                        {i18n.t("Description")}
                                    </Card.Header>
                                    <Card.Content>
                                        {(SR.order || SR.gender || SR.species || SR.cultivar) &&
                                            <Grid.Row id='LfBJxHne'>
                                                <label>{i18n.t("Essence")} :</label>
                                                <span>
                                                    {properties.essence.gender} {properties.essence.species} {properties.essence.cultivar} {properties.essence.order && `(${properties.essence.order})`}
                                                </span>
                                            </Grid.Row>}
                                        {SR.vernacularName &&
                                            <Grid.Row id='0RDvxJ6G'>
                                                <label>{i18n.t("Nom vernaculaire")}<InfoIcon content={i18n.t("Nom courant donné en français")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.essence.vernacularName}</span>
                                            </Grid.Row>}
                                        {SR.treePort &&
                                            <Grid.Row>
                                                <label>{i18n.t("Port de l'arbre")}<InfoIcon content={i18n.t("Mode de conduite de l'arbre (contraintes sur la formes de l'arbre)")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.treePort}</span>
                                            </Grid.Row>}
                                        {SR.ontogenicStage &&
                                            <Grid.Row id='pXkUgyaa'>
                                                <label>{i18n.t("Stade ontogénique")}<InfoIcon content={i18n.t("Stade de développement de l'arbre (selon Rimbault)")} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getOntogenicStageColor(properties.ontogenicStage) }}
                                                    ></span>
                                                    <span>{properties.ontogenicStage}</span>
                                                </div>
                                            </Grid.Row>}
                                        {SR.plantingDate &&
                                            <Grid.Row>
                                                <label>{i18n.t("Date de plantation")} :</label>
                                                <span>{properties.plantingDate ? DatesUtil.getFormattedLocaleDateString(properties.plantingDate) : ''}</span>
                                            </Grid.Row>}
                                        {SR.age &&
                                            <Grid.Row id='gePDIaoI'>
                                                <label>{i18n.t("Âge")}<InfoIcon content={i18n.t("L'âge est mis à jour automatiquement")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.age} {properties.age > 1 ? i18n.t("Ans").toLowerCase() : i18n.t("An")}</span>
                                                {timelineYears[currentYear]?.estimatedValues?.[this.state.id]?.includes('age') &&
                                                    <InfoIcon icon={faQuestionCircle} color='var(--yellow-100)' content={i18n.t("Cette donnée a été estimée en fonction des valeurs connues")} iconStyle={iconStyle} />}
                                            </Grid.Row>}
                                        {descriptionCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {width > 1300 && <div></div>}
                            {width > 1300 && <div></div>}
                            {(SR.height || SR.crownDiameter > 0 || SR.stumpDiameter > 0 || SR.numberOfTrunks || SR.trunkHeight || SR.trunks || (dimensionsCustomFields && dimensionsCustomFields.length > 0)) ?
                                <Card id='wuM5v22y' style={{ borderBottom: 'solid 4px var(--purple-100)' }}>
                                    <Card.Header style={{ color: 'var(--purple-100)' }}>
                                        <FontAwesomeIcon icon={faPencilRuler} />
                                        {i18n.t("Dimensions")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.stumpDiameter > 0 &&
                                            <Grid.Row>
                                                <label>{i18n.t("Diamètre de la souche")} :</label>
                                                <span>{stumpDiameter.label}cm</span>
                                            </Grid.Row>}
                                        {SR.height &&
                                            <Grid.Row id='temp-height'>
                                                <label>{i18n.t("Hauteur totale")}<InfoIcon icon={faWarning} color='var(--yellow-100)' content={i18n.t("Ce champ est obsolète et a été remplacé par la hauteur totale présente dans la liste d'axes")} iconStyle={iconStyle} /> :</label>
                                                <span>{Math.round((properties.dimensions.height / 100) * 100) / 100}m</span>
                                            </Grid.Row>}
                                        {(SR.numberOfTrunks || SR.trunkHeight || SR.trunks) &&
                                            <>
                                                {SR.numberOfTrunks && properties.numberOfTrunks > 0 &&
                                                    <Grid.Row>
                                                        <label>{i18n.t("Nombre d'axes")} :</label>
                                                        <span>{properties.numberOfTrunks}</span>
                                                    </Grid.Row>}
                                                {SR.trunkHeight && properties.dimensions.trunkHeight > 0 &&
                                                    <Grid.Row>
                                                        <label>{i18n.t("Hauteur du tronc")} :</label>
                                                        <span>{Math.round((properties.dimensions.trunkHeight / 100) * 100) / 100}m</span>
                                                    </Grid.Row>}
                                                {SR.trunks && properties.trunks?.length > 0 &&
                                                    <Grid.Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        <label>{i18n.t("Axes")}<InfoIcon content={i18n.t("Axes qui composent l'arbre")} iconStyle={iconStyle} /> :</label>
                                                        <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', flexWrap: 'wrap', gap: '10px', width: '100%' }}>
                                                            {properties.trunks
                                                                .toSorted((a, b) => a.order - b.order)
                                                                .map((trunk, index) => (
                                                                    <div key={trunk.id} style={{ position: 'relative', flex: isMobileOnly ? '1' : '1 0 auto', border: '1px solid var(--grey-120)', backgroundColor: isDarkTheme ? 'var(--black-80)' : 'var(--white-90)', borderRadius: '10px', padding: '5px 10px' }}>
                                                                        <div style={{ position: 'absolute', top: '5px', right: '5px', fontSize: '12px', borderRadius: '10px', backgroundColor: 'var(--grey-100)', color: 'var(--white-100)', padding: '2px 5px' }}>
                                                                            {i18n.t("Axe {{index}}", { index: index + 1 })}
                                                                        </div>
                                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                                            <div>{i18n.t('Hauteur totale')} :</div>
                                                                            <div style={{ color: isDarkTheme ? 'var(--grey-60)' : 'var(--grey-80)' }}>{trunk.height != null ? Math.round((trunk.height / 100) * 100) / 100 : '?'}m</div>
                                                                            {timelineYears[currentYear]?.estimatedValues?.[this.state.id]?.includes(`height-${trunk.id}`) &&
                                                                                <InfoIcon icon={faQuestionCircle} color='var(--yellow-100)' content={i18n.t("Cette donnée a été estimée en fonction des valeurs connues")} iconStyle={iconStyle} />}
                                                                            {timelineYears[currentYear]?.cantEstimateValues?.[this.state.id]?.includes(`height-${trunk.id}`) &&
                                                                                <InfoIcon icon={faQuestionCircle} color='var(--red-100)' content={i18n.t("Cette donnée n'a pas pu être estimée, la dernière valeur connue est donc renseignée")} iconStyle={iconStyle} />}
                                                                        </div>
                                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                                            {project.trunkCircumferenceUnit === 'circumference' ? i18n.t('Circonférence') : i18n.t('Diamètre')} :
                                                                            <div style={{ color: isDarkTheme ? 'var(--grey-60)' : 'var(--grey-80)' }}>
                                                                                {project.trunkCircumferenceUnit === 'circumference'
                                                                                    ? trunk.circumference != null ? trunk.circumference : '?'
                                                                                    : trunk.circumference != null ? FormattersUtil.getTrunkCircumference(trunk.circumference, project.trunkCircumferenceUnit) : '?'}
                                                                                cm
                                                                            </div>
                                                                            {timelineYears[currentYear]?.estimatedValues?.[this.state.id]?.includes(`circumference-${trunk.id}`) &&
                                                                                <InfoIcon icon={faQuestionCircle} color='var(--yellow-100)' content={i18n.t("Cette donnée a été estimée en fonction des valeurs connues")} iconStyle={iconStyle} />}
                                                                            {timelineYears[currentYear]?.cantEstimateValues?.[this.state.id]?.includes(`circumference-${trunk.id}`) &&
                                                                                <InfoIcon icon={faQuestionCircle} color='var(--red-100)' content={i18n.t("Cette donnée n'a pas pu être estimée, la dernière valeur connue est donc renseignée")} iconStyle={iconStyle} />}
                                                                        </div>
                                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                                            <div>{i18n.t('Diamètre couronne')} :</div>
                                                                            <div style={{ color: isDarkTheme ? 'var(--grey-60)' : 'var(--grey-80)' }}>{trunk.crownDiameter != null ? Math.round((trunk.crownDiameter / 100) * 100) / 100 : '?'}m</div>
                                                                            {timelineYears[currentYear]?.estimatedValues?.[this.state.id]?.includes(`crownDiameter-${trunk.id}`) &&
                                                                                <InfoIcon icon={faQuestionCircle} color='var(--yellow-100)' content={i18n.t("Cette donnée a été estimée en fonction des valeurs connues")} iconStyle={iconStyle} />}
                                                                            {timelineYears[currentYear]?.cantEstimateValues?.[this.state.id]?.includes(`crownDiameter-${trunk.id}`) &&
                                                                                <InfoIcon icon={faQuestionCircle} color='var(--red-100)' content={i18n.t("Cette donnée n'a pas pu être estimée, la dernière valeur connue est donc renseignée")} iconStyle={iconStyle} />}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            {properties.trunks.length % 2 > 0 && <div style={{ width: '49%' }}></div>}
                                                        </div>
                                                    </Grid.Row>}
                                            </>}
                                        {dimensionsCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {width > 900 && <div></div>}
                            {(SR.plantationType || SR.coverType || SR.interactions || SR.microHabitats || (environmentCustomFields && environmentCustomFields.length > 0)) ?
                                <Card id='dVetdybF' style={{ borderBottom: 'solid 4px var(--green-100)' }}>
                                    <Card.Header style={{ color: 'var(--green-100)' }}>
                                        <FontAwesomeIcon icon={faTree} />
                                        {i18n.t("Environnement")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.plantationType &&
                                            <Grid.Row id='EH2I9TiU'><label>{i18n.t("Type de plantation")}<InfoIcon content={i18n.t("Précise le type de plantation")} iconStyle={iconStyle} /> :</label><span>{properties.plantationType}</span></Grid.Row>}
                                        {SR.coverType &&
                                            <Grid.Row id='nGzcLtk8'><label>{i18n.t("Type de couverture au sol")}<InfoIcon content={i18n.t("Décrit ce qui recouvre le sol à proximité de l'arbre")} iconStyle={iconStyle} /> :</label><span>{properties.coverType}</span></Grid.Row>}
                                        {SR.interactions &&
                                            <Grid.Row id='DERtRwEG' divided={!isMobile} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <label>{i18n.t("Interactions")}<InfoIcon content={i18n.t("Éléments avec lesquels l'arbre est en contact")} iconStyle={iconStyle} /> :</label>
                                                <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                                    {properties.interactions.map(interaction => (
                                                        <li key={interaction}>{interaction}</li>
                                                    ))}
                                                </ul>
                                            </Grid.Row>}
                                        {SR.microHabitats && <Grid.Row><label>{i18n.t("Dendro-microhabitats")} :</label><span>{properties.microHabitats.join(', ')}</span></Grid.Row>}
                                        {environmentCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {width > 1300 && <div></div>}
                            {(SR.healthReview || SR.vigor || SR.risk || SR.accurateRisk || SR.stability || (stateCustomFields && stateCustomFields.length > 0)) ?
                                <Card id='sRghMdgV' style={{ borderBottom: 'solid 4px var(--red-100)' }}>
                                    <Card.Header style={{ color: 'var(--red-100)' }}>
                                        <FontAwesomeIcon icon={faHeartbeat} />
                                        {i18n.t("État")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.healthReview &&
                                            <Grid.Row id='WOTw15Of'>
                                                <label>{i18n.t("Cote sanitaire")}<InfoIcon content={i18n.t("Note globale de l'arbre sur 10")} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getHealthReviewColor(properties.healthReview.value) }}
                                                    ></span>
                                                    <span>{properties.healthReview.value + ' (' + properties.healthReview.description + ')'}</span>
                                                </div>
                                            </Grid.Row>}
                                        {SR.vigor &&
                                            <Grid.Row id='fv2ICnob'>
                                                <label>{i18n.t("Vigueur")}<InfoIcon content={i18n.t("Vigueur de l'arbre")} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getVigorColor(properties.vigor) }}
                                                    ></span>
                                                    <span>{properties.vigor}</span>
                                                </div>
                                            </Grid.Row>}
                                        {SR.risk &&
                                            <Grid.Row id='NgsdSAaK'>
                                                <label>{i18n.t("Risque")}<InfoIcon content={i18n.t("Niveau de risque que représente l'arbre")} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getRiskColor(properties.risk) }}
                                                    ></span>
                                                    <span>{properties.risk}</span>
                                                </div>
                                            </Grid.Row>}
                                        {SR.accurateRisk &&
                                            <Segment className='no-themed' style={{ padding: '7px' }}>
                                                <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '13pt', marginBottom: '5px' }}>{i18n.t("Risque (Matheny & Clarck)")}</div>
                                                {properties.tippingRisk &&
                                                    <Grid.Row>
                                                        <label>{i18n.t("Risque de basculement/rupture")} :</label>
                                                        <span>{properties.tippingRisk.label + ' (' + properties.tippingRisk.value + '/4)'}</span>
                                                    </Grid.Row>}
                                                {properties.organCaliber &&
                                                    <Grid.Row>
                                                        <label>{i18n.t("Calibre de l'organe instable")} :</label>
                                                        <span>{properties.organCaliber.label + ' (' + properties.organCaliber.value + '/4)'}</span>
                                                    </Grid.Row>}
                                                {properties.target &&
                                                    <Grid.Row>
                                                        <label>{i18n.t("Cible")} :</label>
                                                        <span>{properties.target.label + ' (' + properties.target.value + '/4)'}</span>
                                                    </Grid.Row>}
                                                {properties.tippingRisk && properties.organCaliber && properties.target &&
                                                    <>
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <Grid.Row>
                                                            <label>{i18n.t("Résultat")} :</label>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <span
                                                                    className='color-circle'
                                                                    style={{ backgroundColor: StylesUtil.getAccurateRiskColor(properties.accurateRisk) }}
                                                                ></span>
                                                                <span>{`${i18n.t("Risque")} ${TreesUtil.getAccurateRiskLabel(properties.accurateRisk).toLowerCase()} - ${properties.accurateRisk}/12`}</span>
                                                            </div>
                                                        </Grid.Row>
                                                    </>}
                                            </Segment>}
                                        {SR.stability &&
                                            <Grid.Row>
                                                <label>{i18n.t("Stabilité")}<InfoIcon content={i18n.t("Le facteur de stabilité donne une indication sur la résistance au basculement d'un arbre situé dans un massif en fonction de ses dimensions (hauteur arbre/diamètre tronc) : <30 = excellent, 30 à 50 = bon, 50 à 70 = moyen, >70 = mauvais")} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getStabilityColor(treeStability) }}
                                                    ></span>
                                                    <span>{treeStability} ({TreesUtil.getStabilityLabel(treeStability)})</span>
                                                </div>
                                            </Grid.Row>}
                                        {stateCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {width > 900 && <div></div>}
                            {width > 1300 && <div></div>}
                            {properties.toCutDown !== 4 && (SR.carbonStock || SR.totalCarbonStock || SR.fruitProduction || SR.coolingIndicator || SR.coolingEnergyIndicator || SR.coolingEconomicValue || SR.oxygenProduction || SR.biodiversityIndex || SR.canopy || SR.amenityValue || (indicatorsCustomFields && indicatorsCustomFields.length > 0)) ?
                                <Card id='PuaLRZhU' style={{ borderBottom: 'solid 4px var(--blue-100)' }}>
                                    <Card.Header style={{ color: 'var(--blue-100)' }}>
                                        <FontAwesomeIcon icon={faTachometerAlt} />
                                        {i18n.t("Indicateurs")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.fruitProduction > 0 &&
                                            <Grid.Row>
                                                <label>{i18n.t("Production de fruits")} :</label>
                                                <span>{properties.essence.fruitProduction}kg/an</span>
                                            </Grid.Row>}
                                        {SR.canopy > 0 &&
                                            <Grid.Row id='NR3Setod'>
                                                <label>{i18n.t("Canopée de l'arbre")}<InfoIcon content={i18n.t("Surface projetée du houppier")} iconStyle={iconStyle} /> :</label>
                                                <span>{treeCanopy}m²</span>
                                            </Grid.Row>}
                                        {SR.carbonStock > 0 &&
                                            <Grid.Row id='jribsh8Q'>
                                                <label>{i18n.t("Stockage annuel CO2")}<InfoIcon content={i18n.t("Quantité de carbone stockée par l'arbre")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.carbonStock.toFixed(2)}kg/an</span>
                                            </Grid.Row>}
                                        {SR.totalCarbonStock > 0 &&
                                            <Grid.Row id='sebbsh6D'>
                                                <label>{i18n.t("Stockage total CO2")} :</label>
                                                <span>{properties.totalCarbonStock.toFixed(2)}kg</span>
                                            </Grid.Row>}
                                        {SR.coolingIndicator > 0 &&
                                            <Grid.Row id='t8cB43pS'>
                                                <label>{i18n.t("Rafraîchissement")}<InfoIcon content={i18n.t("Évaluation du potentiel rafraîchissement assuré par l'arbre")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.coolingIndicator.toFixed(2)}m²</span>
                                            </Grid.Row>}
                                        {SR.coolingEnergyIndicator &&
                                            <Grid.Row id='ehbyhP0s' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Rafraîchissement") + ` (${i18n.t("énergie")})`}<InfoIcon content={i18n.t("Quantité de chaleur absorbée par l'arbre par jour, compte tenu de l'évapo-transpiration, dans des conditions estivales standards")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.coolingEnergyIndicator.toFixed(2)}kWh/an</span>
                                            </Grid.Row>}
                                        {SR.coolingEconomicValue &&
                                            <Grid.Row id='Wh8HCFrl' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Rafraîchissement") + ` (${i18n.t("valeur").toLowerCase()})`}<InfoIcon content={i18n.t("Consommation électrique annuelle pour produire ce rafraîchissement s'il fallait le produire articiellement (climatisation)")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.coolingEconomicValue.toFixed(2)}€/an</span>
                                            </Grid.Row>}
                                        {SR.oxygenProduction &&
                                            <Grid.Row id='8mtvD4PX' computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Production d'oxygène")}<InfoIcon content={i18n.t("Quantité annuelle d'oxygène produits par photosynthèse par l'arbre")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.oxygenProduction.toFixed(2)}kg/an</span>
                                            </Grid.Row>}
                                        {SR.biodiversityIndex &&
                                            <Grid.Row computer={5} tablet={5} mobile={16}>
                                                <label>{i18n.t("Potentiel de biodiversité")}<InfoIcon content={TreesUtil.getBiodiversityTooltip(properties).tooltip} color={TreesUtil.getBiodiversityTooltip(properties).isEstimated && 'var(--yellow-100)'} iconStyle={iconStyle} /> :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span
                                                        className='color-circle'
                                                        style={{ backgroundColor: StylesUtil.getHealthReviewColor(Math.round(properties.biodiversityIndex * 10)) }}
                                                    ></span>
                                                    <span>{Math.round((properties.biodiversityIndex * 100) * 100) / 100}/100</span>
                                                </div>
                                            </Grid.Row>}
                                        {SR.amenityValue > 0 &&
                                            <Grid.Row id='ks5xLSkC'>
                                                <label>{i18n.t("Valeur d'agrément")}<InfoIcon content={i18n.t("Calcul de la valeur d'agrément selon formule applicable dans la zone géographique choisie")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.amenityValue.toFixed(2)}€</span>
                                            </Grid.Row>}
                                        {indicatorsCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {width > 900 && <div></div>}
                            {width > 1300 && <div></div>}
                            {(SR.plantationCoefficient || SR.situationCoefficient || SR.patrimonialCoefficient || (coefficientsCustomFields && coefficientsCustomFields.length > 0)) ?
                                <Card style={{ borderBottom: 'solid 4px var(--pink-100)' }}>
                                    <Card.Header style={{ color: 'var(--pink-100)' }}>
                                        <FontAwesomeIcon icon={faCalculator} />
                                        {i18n.t("Coefficients")}
                                    </Card.Header>
                                    <Card.Content>
                                        {SR.plantationCoefficient &&
                                            <Grid.Row id='dkhzCCA8'>
                                                <label>{i18n.t("Coefficient de plantation")}<InfoIcon content={i18n.t("Situation dans le paysage / degré d'isolement")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.plantationCoefficient}</span>
                                            </Grid.Row>}
                                        {SR.situationCoefficient &&
                                            <Grid.Row id='QTQlKheO'>
                                                <label>{i18n.t("Coefficient de situation")}<InfoIcon content={i18n.t("Environnement dans lequel il est situé")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.situationCoefficient}</span>
                                            </Grid.Row>}
                                        {SR.patrimonialCoefficient &&
                                            <Grid.Row id='APrkulTH'>
                                                <label>{i18n.t("Coefficient patrimonial")}<InfoIcon content={i18n.t("Importance patrimoniale")} iconStyle={iconStyle} /> :</label>
                                                <span>{properties.patrimonialCoefficient}</span>
                                            </Grid.Row>}
                                        {coefficientsCustomFields}
                                    </Card.Content>
                                </Card> : <div></div>}
                            {treeOrgans.map(({ organ, label, customFields }) => (
                                <>
                                    {(SR[`${organ}Symptoms`] || SR[`${organ}Pathogens`] || SR[`${organ}Pests`] || SR[`${organ}Epiphytes`] || (customFields && customFields.length > 0)) ?
                                        <Card style={{ borderBottom: 'solid 4px var(--brown-100)' }}>
                                            <Card.Header style={{ color: 'var(--brown-100)' }}>
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                {`${i18n.t("VTA")} ${label}`}
                                            </Card.Header>
                                            <Card.Content>
                                                {SR[`${organ}Symptoms`] && <Grid.Row><label>{`${i18n.t("Symptômes")} ${label}`} :</label><span>{properties[`${organ}Symptoms`].join(', ')}</span></Grid.Row>}
                                                {SR[`${organ}Pathogens`] && <Grid.Row><label>{`${i18n.t("Pathogènes")} ${label}`} :</label><span>{properties[`${organ}Pathogens`].join(', ')}</span></Grid.Row>}
                                                {SR[`${organ}Pests`] && <Grid.Row><label>{`${i18n.t("Ravageurs")} ${label}`} :</label><span>{properties[`${organ}Pests`].join(', ')}</span></Grid.Row>}
                                                {SR[`${organ}Epiphytes`] && <Grid.Row><label>{`${i18n.t("Épiphytes")} ${label}`} :</label><span>{properties[`${organ}Epiphytes`].join(', ')}</span></Grid.Row>}
                                                {customFields}
                                            </Card.Content>
                                        </Card> : <div></div>}
                                </>
                            ))}
                            {(SR.observation || (observationCustomFields && observationCustomFields.length > 0)) &&
                                <Card id='GE8t4imw' style={{ borderBottom: 'solid 4px var(--grey-100)' }}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faEye} />
                                        {i18n.t("Observation")}
                                    </Card.Header>
                                    <Card.Content style={{ overflowWrap: 'anywhere' }}>
                                        <span style={{ whiteSpace: 'pre-line' }}>{properties.observation}</span>
                                        {observationCustomFields}
                                    </Card.Content>
                                </Card>}
                            {(latestActionsDone.length > 0 || actionsToDo.length > 0) && RightsUtil.canRead(rights?.actions) &&
                                <Card id='684F879A' title={i18n.t("Accéder aux actions de l'arbre")} style={{ borderBottom: 'solid 4px var(--grey-100)' }} onClick={() => this.props.changeModalContentType('ActionForm', i18n.t("Actions"))}>
                                    <Card.Header style={{ color: 'var(--grey-100)' }}>
                                        <FontAwesomeIcon icon={faUserHelmetSafety} />
                                        {i18n.t("Actions")}
                                    </Card.Header>
                                    <Card.Content style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        {actionsToDo.length > 0 && this.renderActionsToDo(actionsToDo)}
                                        {latestActionsDone.length > 0 && this.renderLatestActionsDone(latestActionsDone)}
                                    </Card.Content>
                                </Card>}
                            {SR.customFields && this.renderCustomFields(properties.customFields)}
                        </Masonry>
                    </div>
                </div >
            );
        }
        else return (<p>{i18n.t("Chargement...")}</p>);
    }

    componentDidMount = () => {
        if (!this.state.requiredFields) {
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project, this.props.layer[0].feature.projectId);
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            this.setState({ requiredFields: requiredFields, publicFields: publicFields }, () => this.setProperties());
        } else this.setProperties();
    }

    componentDidUpdate = () => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.layer[0].feature) !== JSON.stringify(this.state.feature))
            this.setState(prevState => ({
                ...prevState.requiredFields, ...prevState.publicFields,
                id: this.props.layer[0].feature.id,
                feature: this.props.layer[0].feature
            }), () => this.setProperties());
    }

    renderFields = (category) => {
        const { project } = this.props;
        const customFields = this.state.customFields.map(cf => {
            const value = this.state.properties.customFields[cf.id];
            return value ? { ...cf, value } : null;
        }).filter(cf => cf);
        const projectCustomFields = project?.projectCustomFields || [];

        const fields = customFields
            .filter(cf => projectCustomFields.find(pcf => pcf.customFieldId === cf.id && pcf.fieldCategoryId === category?.id))
            .sort((a, b) => projectCustomFields.find(pcf => pcf.customFieldId === a.id && pcf.fieldCategoryId === category?.id)?.order
                - projectCustomFields.find(pcf => pcf.customFieldId === b.id && pcf.fieldCategoryId === category?.id)?.order);
        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        const renderedFields = fields.map((cf, i) => {
            let value = cf.value && cf.type === 'boolean' ? cf.value === 'true' ? i18n.t("Oui") : i18n.t("Non")
                : cf.type === 'date' ? DatesUtil.getFormattedLocaleDateString(cf.value)
                    : cf.type === 'list' && cf.isMultiple ? cf.value.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v)
                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === cf.value)?.label
                            : cf.value;
            if (cf.type === 'number' && cf.unit?.trim()) value += cf.unit;
            if (cf.type === 'formula') value = FormattersUtil.formatFormulaCustomField(cf, value);

            return (
                cf.type !== 'url' ?
                    <Grid.Row key={`extra-field-${i + 1}`} style={Array.isArray(value) ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : null}>
                        <label>{cf.label}{cf.description?.trim() && <InfoIcon content={cf.description} iconStyle={iconStyle} />} :</label>
                        {!Array.isArray(value) ? <span>{value}</span>
                            : <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                {value.map(v => (
                                    <li key={v}>{v}</li>
                                ))}
                            </ul>}
                    </Grid.Row>
                    : <Grid.Row>
                        <Button size='small' style={{ padding: 0 }} type='button' color='green'>
                            <a href={cf.value?.includes('http') ? cf.value : '//' + cf.value} target='_blank' rel='noreferrer' style={{ padding: '7px', display: 'block', height: '100%', width: '100%', color: 'inherit' }}>
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{cf.label}
                            </a>
                        </Button>
                    </Grid.Row>
            );
        });

        return renderedFields;
    }

    renderCustomFields = (elementCustomFields) => {
        const { project } = this.props;
        const customFields = this.state.customFields.map(cf => {
            const value = this.state.properties.customFields[cf.id];
            return value ? { ...cf, value } : null;
        }).filter(cf => cf);
        const projectCustomFields = project?.projectCustomFields || [];

        const othersCategory = this.props.defaultFieldCategories.find(dfc => dfc.label === 'Autres' && dfc.category === 'Arbre');
        let categories = [{
            id: othersCategory.id, label: i18n.t("Autres"), color: othersCategory.color, icon: othersCategory.icon,
            fields: customFields.filter(cf => {
                const pcf = projectCustomFields.find(pcf => pcf.customFieldId === cf.id);
                if (pcf) return pcf.fieldCategoryId === othersCategory.id && pcf;
                return elementCustomFields[cf.id];
            })
        }];

        if (project?.fieldCategories?.length)
            categories = [...categories, ...project.fieldCategories.filter(fc => fc.category === 'Arbre').map(fc => {
                return { ...fc, fields: customFields.filter(cf => projectCustomFields.find(pcf => cf.id === pcf.customFieldId)?.fieldCategoryId === fc.id) };
            })];
        categories = categories.filter(c => c.fields.length);

        const iconStyle = { position: 'relative', top: 0, right: 0, marginLeft: '3px' };
        return categories.map(category => (
            <Card key={category.label} style={{ borderBottom: `solid 4px ${category.color}` }}>
                <Card.Header style={{ color: category.color }}>
                    <FontAwesomeIcon icon={category.icon} />
                    {category.label}
                </Card.Header>
                <Card.Content>
                    {category.fields
                        .sort((a, b) => projectCustomFields.find(pcf => a.id === pcf.customFieldId && pcf.fieldCategoryId === category.id)?.order
                            - projectCustomFields.find(pcf => b.id === pcf.customFieldId && pcf.fieldCategoryId === category.id)?.order)
                        .map((cf, i) => {
                            let value = cf.value && cf.type === 'boolean' ? cf.value === 'true' ? i18n.t("Oui") : i18n.t("Non")
                                : cf.type === 'date' ? DatesUtil.getFormattedLocaleDateString(cf.value)
                                    : cf.type === 'list' && cf.isMultiple ? cf.value.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v)
                                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === cf.value)?.label
                                            : cf.value;
                            if (cf.type === 'number' && cf.unit?.trim()) value += cf.unit;
                            if (cf.type === 'formula') value = FormattersUtil.formatFormulaCustomField(cf, value);

                            return (
                                cf.type !== 'url' ?
                                    <Grid.Row key={`extra-field-${i + 1}`} style={Array.isArray(value) ? { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } : null}>
                                        <label>{cf.label}{cf.description?.trim() && <InfoIcon content={cf.description} iconStyle={iconStyle} />} :</label>
                                        {!Array.isArray(value) ? <span>{value}</span>
                                            : <ul style={{ marginTop: 0, marginBottom: 0 }}>
                                                {value.map(v => (
                                                    <li key={v}>{v}</li>
                                                ))}
                                            </ul>}
                                    </Grid.Row>
                                    : <Grid.Row>
                                        <Button size='small' style={{ padding: 0 }} type='button' color='green' >
                                            <a href={cf.value?.includes('http') ? cf.value : '//' + cf.value} target='_blank' rel='noreferrer' style={{ padding: '7px', display: 'block', height: '100%', width: '100%', color: 'inherit' }}>
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{cf.label}
                                            </a>
                                        </Button>
                                    </Grid.Row>
                            );
                        })}
                </Card.Content>
            </Card>
        ));
    }

    renderLatestActionsDone = (latestActionsDone) => {
        const { isDarkTheme } = this.props;

        return (
            <div style={{ flex: 1, marginTop: isMobileOnly && '10px' }}>
                <Grid.Row>
                    <FontAwesomeIcon icon={faSquareCheck} style={{ marginRight: '7px', color: isDarkTheme ? 'white' : 'black' }} />
                    <label>{i18n.t("Dernières réalisées")}</label>
                </Grid.Row>
                {latestActionsDone.map((recurrence, index) =>
                    <div key={index} style={{ marginLeft: '8px' }}>
                        <label>{recurrence.actionLabel} :</label> <span title={i18n.t("Date de réalisation")}>{DatesUtil.getFormattedLocaleDateString(recurrence.validationDate)}</span>
                    </div>)}
            </div>
        );
    }

    renderActionsToDo = (actionsToDo) => {
        const { isDarkTheme } = this.props;

        return (
            <div style={{ flex: 1 }}>
                <Grid.Row>
                    <FontAwesomeIcon icon={faCalendarClock} style={{ marginRight: '7px', color: isDarkTheme ? 'white' : 'black' }} />
                    <label>{i18n.t("À venir")}</label>
                </Grid.Row>
                {actionsToDo.map((recurrence, index) =>
                    <div key={index} style={{ marginLeft: '8px' }}>
                        <label>
                            {recurrence.actionLabel} :</label> <span title={i18n.t("Date planifiée")}>{DatesUtil.getFormattedLocaleDateString(recurrence.date)}
                            {DatesUtil.convertUTCDateToDate(recurrence.date) < new Date() && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("En retard")} style={{ marginLeft: '5px', color: 'var(--red-100)' }} />}
                        </span>
                    </div>)}
            </div>
        );
    }

    setProperties = () => {
        const { project } = this.props;
        const properties = this.props.layer[0].feature.properties;
        const essence = this.props.essences.find(x => x.id === properties.essenceId);
        const coverType = this.props.coverTypes.find(x => x.id === properties.coverTypeId)?.label;
        const vigor = this.props.vigors.find(x => x.id === properties.vigorId)?.label;
        const healthReview = this.props.healthReviews.find(x => x.id === properties.healthReviewId);
        const ontogenicStage = this.props.ontogenicStages.find(x => x.id === properties.ontogenicStageId)?.value;
        const risk = this.props.risks.find(x => x.id === properties.riskId)?.label;
        const accurateRisk = properties.tippingRiskId && properties.organCaliberId && properties.targetId && (
            this.props.tippingRisks.find(tippingRisk => tippingRisk.id === properties.tippingRiskId)?.value
            + this.props.organCalibers.find(organCaliber => organCaliber.id === properties.organCaliberId)?.value
            + this.props.targets.find(target => target.id === properties.targetId)?.value
        );
        const tippingRisk = this.props.tippingRisks.find(x => x.id === properties.tippingRiskId);
        const organCaliber = this.props.organCalibers.find(x => x.id === properties.organCaliberId);
        const target = this.props.targets.find(x => x.id === properties.targetId);
        const treePort = this.props.treePorts.find(x => x.id === properties.treePortId)?.label;
        const plantationType = this.props.plantationTypes.find(x => x.id === properties.plantationTypeId)?.label;
        const amenityFormulaType = project?.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const propertyName = amenityFormulaType === 'Wallonie' ? 'descriptionWln'
            : amenityFormulaType === 'Bruxelles' ? 'descriptionBxl'
                : 'descriptionFr';
        const interactions = properties.interactionId?.map(id => this.props.interactions.find(x => x.id === id)?.label) || [];
        const microHabitats = properties.microHabitatId?.map(id => this.props.microHabitats.find(x => x.id === id)?.label) || [];
        // Tags
        let tags = [];
        if (properties.tagId && this.state.requiredFields.trees.tags && project?.tags) {
            properties.tagId.forEach(tId => {
                const tag = project.tags.find(x => x.id === tId)?.label;
                tags.push(tag);
            });
        }
        // Coefficient de plantation
        let plantationCoefficient = this.props.plantationCoefficients.find(x => x.id === properties.plantationCoefficientId);
        if (plantationCoefficient) {
            let value = plantationCoefficient.value;
            if (plantationCoefficient[propertyName]) value += ' (' + plantationCoefficient[propertyName] + ')';
            plantationCoefficient = value;
        }
        // Coefficient de situation
        let situationCoefficient = this.props.situationCoefficients.find(x => x.id === properties.situationCoefficientId);
        if (situationCoefficient) {
            let value = situationCoefficient.value;
            if (situationCoefficient[propertyName]) value += ' (' + situationCoefficient[propertyName] + ')';
            situationCoefficient = value;
        }

        // Coefficient patrimonial
        let patrimonialCoefficient = this.props.patrimonialCoefficients.find(x => x.id === properties.patrimonialCoefficientId);
        if (patrimonialCoefficient) {
            let value = patrimonialCoefficient.value;
            if (patrimonialCoefficient.description)
                value += ' (' + patrimonialCoefficient.description + ')';
            patrimonialCoefficient = value;
        }

        const biggestTrunk = TreesUtil.getBiggestTrunk(properties.trunks);
        // Stockage total CO2
        const totalCarbonStock = FormulasUtil.getTreeTotalCarbonStock(properties.carbonStock, biggestTrunk?.circumference || 0);
        // Rafraîchissement
        const coolingEconomicValue = FormulasUtil.getCoolingEconomicValue(properties.coolingEnergyIndicator);
        // Production d'oxygène
        const oxygenProduction = FormulasUtil.getOxygenProduction(properties.carbonStock);
        // Date de plantation
        const plantingDate = typeof properties.plantingDate === 'string' ? DatesUtil.convertDateStringToDate(properties.plantingDate) : properties.plantingDate;
        // Stations
        const stationsLayer = this.props.layers.find(l => l.label === i18n.t("Stations"));
        const layers = stationsLayer ? stationsLayer.getLayers() : [];
        const stationLabels = layers.filter(l => booleanPointInPolygon(this.props.layer[0].feature, l.feature)).map(l => l.feature.properties.label);

        this.setState({
            properties: {
                ...properties,
                essence, coverType, interactions, microHabitats, tags, vigor,
                healthReview, ontogenicStage, risk, tippingRisk, organCaliber, target, accurateRisk, treePort, plantationType, plantationCoefficient, situationCoefficient,
                patrimonialCoefficient, totalCarbonStock, coolingEconomicValue, oxygenProduction, plantingDate,
                ...(['root', 'collar', 'trunk', 'branch', 'leaf'].reduce((prevValue, organ) => ({
                    ...prevValue,
                    [`${organ}Symptoms`]: properties[`${organ}SymptomId`]?.map(id => this.props[`${organ}Symptoms`].find(x => x.id === id)?.label) || [],
                    [`${organ}Pathogens`]: properties[`${organ}PathogenId`]?.map(id => this.props.pathogens.find(x => x.id === id)?.label) || [],
                    [`${organ}Pests`]: properties[`${organ}PestId`]?.map(id => this.props.pests.find(x => x.id === id)?.label) || [],
                    [`${organ}Epiphytes`]: properties[`${organ}EpiphyteId`]?.map(id => this.props.epiphytes.find(x => x.id === id)?.label) || []
                }), {}))
            },
            customFields: this.props.customFields.filter(cf => (
                cf.category === 'Arbre'
                && (!properties.isEmpty || cf.forEmpty)
                && (!properties.isDead || cf.forDead)
                && (!properties.isStump || cf.forStump)
            )),
            stationLabels
        });
    }

    getLatestActionsDone = () => {
        let recurrences = [];
        this.props.projectActions
            ?.filter(pa => pa.action.categories.includes('Arbre'))
            .forEach(pa => {
                const pae = pa.projectActionElements.find(x => x.elementId === this.state.id);
                if (pae?.projectActionElementRecurrences?.length)
                    recurrences.push(...pae.projectActionElementRecurrences.filter(paer => paer.validationDate).map(paer => ({ ...paer, actionLabel: pa.action.label })));
            });

        recurrences.sort((a, b) => new Date(b.validationDate) - new Date(a.validationDate));
        return recurrences.slice(0, 5);
    }

    getActionsToDo = () => {
        let recurrences = [];
        this.props.projectActions
            ?.filter(pa => pa.action.categories.includes('Arbre'))
            .forEach(pa => {
                const pae = pa.projectActionElements.find(x => x.elementId === this.state.id);
                if (pae?.projectActionElementRecurrences?.length)
                    recurrences.push(...pae.projectActionElementRecurrences.filter(paer => !paer.validationDate).map(paer => ({ ...paer, actionLabel: pa.action.label })));
            });

        recurrences.sort((a, b) => new Date(a.date) - new Date(b.date));
        return recurrences.slice(0, 5);
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        essences: state.essences,
        coverTypes: state.coverTypes,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        treePorts: state.treePorts,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isOnline: state.isOnline,
        elementHistory: state.elementHistory,
        activeOrganization: state.activeOrganization,
        projections: state.projections,
        webSocketHubs: state.webSocketHubs,
        isDarkTheme: state.isDarkTheme,
        photosGalleries: state.photosGalleries,
        projectActions: state.projectActions,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields,
        rights: state.rights,
    };
};

const mapDispatchToProps = {
    setPhotosGalleries
};

export default compose(withSize(), connect(mapStateToProps, mapDispatchToProps))(TreeDetail);