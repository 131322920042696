import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class ParametersService {
    static getVersions() {
        return Axios.get(BACKEND_ENDPOINT + 'parameters/versions/').then(response => {
            return response.data;
        }, error => { return; });
    }

    static toggleMaintenanceStatus(status) {
        return Axios.get(BACKEND_ENDPOINT + 'admin/parameters/toggleMaintenanceStatus/?isUnderMaintenance=' + status, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.status;
        }, () => {
            return;
        });
    }
}