import React, { Component } from 'react';
// Composants
import LinkAccountsForm from '../Forms/Users/LinkAccountsForm';
import Woops from '../Utils/Woops';
import UserInfosForm from '../Forms/Users/UserInfosForm';
import PasswordForm from '../Forms/Users/PasswordForm';
import MyAccountSidebar from '../Navbars/MyAccountSidebar';
import DeleteAccountForm from '../Forms/Users/DeleteAccountForm';
import TutorialList from '../Lists/TutorialList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MailsConfiguration from '../Forms/Users/MailsConfiguration';
// Librairies
import { isMobileOnly, isMobile } from 'react-device-detect';
import Cookies from 'universal-cookie';
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setProject, setViewProjectAsData } from '../../actionCreators/projectsActions';
import { setActiveOrganization } from '../../actionCreators/usersActions';
// Ressources
import { faBook, faShieldAlt, faLink, faTrash, faClapperboardPlay, faVideo, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
// Semantic UI
import { Button, Dimmer, Divider, Grid, Loader, Message, Segment } from 'semantic-ui-react';
// Services
import UsersService from '../../services/UsersService';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class AccountPanel extends Component {
    state = {
        activeItem: 'general',
        externalAuthentications: [],
        linkedAccounts: [],
        isLoading: false
    };

    render() {
        const { isOnline, loginAsData, logged } = this.props;
        const { activeItem } = this.state;

        const gridStyle = {
            margin: 0, paddingTop: isMobile ? '0px' : '30px', paddingBottom: isMobile ? '0px' : '30px',
            flexGrow: 1, display: 'block', height: '80vh', position: 'relative'
        };

        return (
            <Grid style={gridStyle} className='grality-background'>
                {logged ?
                    <Grid.Row style={{ padding: 0, height: '100%', maxWidth: isMobile ? '100vw' : '80vw', marginLeft: 'auto', marginRight: 'auto' }} stretched>
                        <Grid.Column computer={4} tablet={2} mobile={2} style={{ padding: 0 }}>
                            <MyAccountSidebar activeItem={activeItem} setActiveItem={this.setActiveItem} />
                        </Grid.Column>
                        <Grid.Column computer={12} tablet={14} mobile={14} style={{ padding: 0, height: '100%', overflowY: 'hidden', borderRadius: '0 5px 5px 0' }}>
                            <Segment style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: '100%', overflowY: 'overlay' }}>
                                <Grid>
                                    {activeItem === 'general' &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                        <h3> <FontAwesomeIcon icon={faBook} style={{ marginRight: '10px' }} />{i18n.t("Vos informations")}</h3>
                                                        <Divider />
                                                        <UserInfosForm />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                        <h3><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />{i18n.t("Configuration des mails")}</h3>
                                                        <Divider />
                                                        <MailsConfiguration />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment>
                                                        <h3><FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Suppression du compte")}</h3>
                                                        <Divider />
                                                        <DeleteAccountForm history={this.props.history} logUser={this.props.logUser} />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </>}
                                    {activeItem === 'security' &&
                                        <>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                        <h3><FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Comptes liés")}</h3>
                                                        <Divider />
                                                        {isOnline ?
                                                            <>
                                                                <LinkAccountsForm
                                                                    linkedAccounts={this.state.linkedAccounts} externalAuthentications={this.state.externalAuthentications}
                                                                    setLinkedAccounts={(linkedAccounts) => this.setState({ linkedAccounts })}
                                                                />
                                                                <Message info>
                                                                    <Message.Header>{i18n.t("Pourquoi lier votre compte ?")}</Message.Header>
                                                                    {i18n.t("Cela vous permet de vous connecter plus rapidement et en toute sécurité, sans même devoir entrer votre mot de passe.")}
                                                                </Message>
                                                            </>
                                                            : <Woops />}
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                        <h3>
                                                            <FontAwesomeIcon icon={faShieldAlt} style={{ marginRight: '10px' }} />{i18n.t("Modification du mot de passe")}
                                                        </h3>
                                                        <Divider />
                                                        <PasswordForm />
                                                    </Segment>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </>}
                                    {activeItem === 'tutorials' &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Segment style={isMobileOnly ? { marginBottom: '30px' } : null}>
                                                    <h3><FontAwesomeIcon icon={faClapperboardPlay} style={{ marginRight: '10px' }} />{i18n.t("Tutoriels")}</h3>
                                                    <Divider />
                                                    <TutorialList />
                                                    <Button
                                                        color='green' type='button' className='form-button' style={{ marginLeft: 'auto', marginTop: '10px' }}
                                                        disabled={loginAsData?.readOnly}
                                                        onClick={() => {
                                                            new Cookies().set('isTutorialDone', false, { maxAge: 31536000, path: '/' });
                                                            this.props.history.push('/');
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faVideo} style={{ marginRight: '10px' }} />{i18n.t("Tutoriel complet")}
                                                    </Button>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>}
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    :
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Loader content={i18n.t("Chargement en cours...")} />
                    </Dimmer>}
            </Grid>
        );
    }

    componentDidMount = () => {
        this.props.changeActiveItem('accountPanel'); // Sélectionne l'onglet gestion de compte dans la Navbar quand elle est affichée
        this.props.setViewProjectAsData(null);
        this.redirectTimeout = setTimeout(() => this.props.history.push('/'), 2000);
    }

    componentDidUpdate = (prevProps) => { // Si l'utilisateur n'est pas connecté, on l'empêche d'accéder au composant gestion de compte
        const { logged } = this.props;
        if (!prevProps.logged && logged || (logged && this.redirectTimeout != null))
            if (new Cookies().get('token')) {
                UsersService.getExternalAuthentications().then(eaResponse => {
                    if (eaResponse) {
                        this.setState({ externalAuthentications: eaResponse })
                        UsersService.getUserExternalAuthentication().then(ueaResponse => {
                            if (ueaResponse) this.setState({ linkedAccounts: ueaResponse });
                        });
                    }
                });

                this.setActiveItem(this.props.match.params.category || 'general');
                clearTimeout(this.redirectTimeout);
                this.redirectTimeout = null;
            } else this.props.history.push('/login');

        if (prevProps.logged && !logged) this.props.history.push('/login');
    }

    setActiveItem = (item) => {
        this.setState({ activeItem: item }, () => this.props.history.push(`/accountPanel/${item}`));
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        tutorialTour: state.tutorialTour,
        loginAsData: state.loginAsData
    };
}

const mapDispatchToProps = {
    setProject,
    setActiveOrganization,
    setViewProjectAsData
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanel);