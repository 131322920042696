import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Form } from 'semantic-ui-react';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { faBan, faCircle1, faCircleA, faTimes } from '@fortawesome/pro-solid-svg-icons';
// Utils
import OfflineUtil from '../../../utils/OfflineUtil';

class ReferencesForm extends Component {
    render() {
        const { isToolbarExpanded } = this.props;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form error style={{ width: '100%' }}>
                    {this.renderReferences()}
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <Button
                            type='button' color='red' title={i18n.t("Annuler")} size='small'
                            style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                            onClick={this.props.hideForm}
                        >
                            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Fermer")}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }

    renderReferences = () => {
        const { references } = this.props;
        const categories = {
            'trees': {
                label: i18n.t('Arbres'), onClick: async (referencesType, areReferencesShowed, forceRender, showTip) => {
                    await this.props.renderMarkersReferences('Arbre', referencesType, areReferencesShowed, forceRender, showTip);
                    this.saveOverlaysInCache();
                }
            },
            'furnitures': {
                label: i18n.t('Mobilier urbain'), onClick: async (referencesType, areReferencesShowed, forceRender, showTip) => {
                    await this.props.renderMarkersReferences('Mobilier', referencesType, areReferencesShowed, forceRender, showTip);
                    this.saveOverlaysInCache();
                }
            },
            'markers': {
                label: i18n.t('Repères'), onClick: async (referencesType, areReferencesShowed, forceRender, showTip) => {
                    await this.props.renderMarkersReferences('Repère', referencesType, areReferencesShowed, forceRender, showTip);
                    this.saveOverlaysInCache();
                }
            },
            'greenSpaces': {
                label: i18n.t('Espaces verts'), onClick: (referencesType, areReferencesShowed, forceRender, showTip) => {
                    this.props.renderGreenSpacesReferences(referencesType, areReferencesShowed, forceRender, showTip);
                    this.saveOverlaysInCache();
                }
            }
        };

        return <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {Object.keys(references).map((key) => {
                const activeReferences = references[key];
                const { label, onClick } = categories[key];

                return <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>{label}</div>
                    <div>
                        <ButtonGroup className='base-layers-buttons'>
                            <Button title={i18n.t("Aucune")} color={!activeReferences ? 'blue' : undefined} onClick={() => onClick(null, false)}>
                                <FontAwesomeIcon icon={faBan} />
                            </Button>
                            <Button title={i18n.t("Références auto-incrémentées")} color={activeReferences === 'projectReference' ? 'blue' : undefined} onClick={() => onClick('projectReference', true, false, true)}>
                                <FontAwesomeIcon icon={faCircle1} />
                            </Button>
                            <Button title={i18n.t("Références personnalisées")} color={activeReferences === 'customReference' ? 'blue' : undefined} onClick={() => onClick('customReference', true, false, true)}>
                                <FontAwesomeIcon icon={faCircleA} />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            })}
        </div>
    }

    saveOverlaysInCache = () => {
        if (!this.props.project) return;

        let overlays = [];
        this.props.overlays.forEach(overlay => {
            let overlayCopy = { label: overlay.label };
            if (overlay.hasOwnProperty('activeChild')) overlayCopy['activeChild'] = overlay.activeChild;
            if (overlay.hasOwnProperty('activeOptions')) overlayCopy['activeOptions'] = overlay.activeOptions;
            if (overlay.hasOwnProperty('isShown')) overlayCopy['isShown'] = overlay.isShown;
            if (overlay.hasOwnProperty('isLegendShown')) overlayCopy['isLegendShown'] = overlay.isLegendShown;
            if (overlay.children) overlayCopy['children'] = overlay.children.map(child => {
                let newChild = { label: child.label, parentLabel: child.parentLabel };
                if (child.hasOwnProperty('isShown')) newChild['isShown'] = child.isShown;
                if (child.hasOwnProperty('isLegendShown')) newChild['isLegendShown'] = child.isLegendShown;
                if (child.hasOwnProperty('buttons')) newChild['buttons'] = child.buttons;
                return newChild;
            });
            overlays.push(overlayCopy);
        });

        if (overlays.length) OfflineUtil.updateProjectView(this.props.project.id, 'overlays', overlays);
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        isToolbarExpanded: state.isToolbarExpanded,
    };
};

export default connect(mapStateToProps)(ReferencesForm);