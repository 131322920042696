import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'semantic-ui-react';
// Librairies
import { isMobile } from 'react-device-detect';
import i18n from '../../locales/i18n';
// Ressources
import { faUser, faShieldAlt, faClapperboardPlay } from '@fortawesome/pro-solid-svg-icons'

class MyAccountSidebar extends Component {
    render() {
        const { activeItem } = this.props;
        const iconStyle = { fontSize: '20px', width: '20px', marginRight: !isMobile && '10px' };
        const itemStyle = isMobile ? { paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' } : null;
        const labelStyle = { fontSize: '18px' };

        return (
            <Menu fluid vertical tabular style={{ borderRadius: '5px 0 0 5px' }}>
                <Menu.Item name='general' style={itemStyle} active={activeItem === 'general'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faUser} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Général")}</span>}
                </Menu.Item>
                <Menu.Item name='security' style={itemStyle} active={activeItem === 'security'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faShieldAlt} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Sécurité et Connexion")}</span>}
                </Menu.Item>
                <Menu.Item name='tutorials' style={itemStyle} active={activeItem === 'tutorials'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faClapperboardPlay} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Tutoriels")}</span>}
                </Menu.Item>
            </Menu>
        );
    }

    handleClick = (_, { name }) => this.props.setActiveItem(name);
}

export default MyAccountSidebar;