import React, { Component } from 'react';
// Composants
import { Form, Select, Grid } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
// Ressources
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step7 extends Component {
    state = {
        properties: {
            rootSymptomId: [],
            collarSymptomId: [],
            trunkSymptomId: [],
            branchSymptomId: [],
            leafSymptomId: []
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.trees;
        const rootSymptoms = this.props.rootSymptoms.map(x => { return { text: x.label, value: x.id } });
        const collarSymptoms = this.props.collarSymptoms.map(x => { return { text: x.label, value: x.id } });
        const trunkSymptoms = this.props.trunkSymptoms.map(x => { return { text: x.label, value: x.id } });
        const branchSymptoms = this.props.branchSymptoms.map(x => { return { text: x.label, value: x.id } });
        const leafSymptoms = this.props.leafSymptoms.map(x => { return { text: x.label, value: x.id } });
        const pathogens = this.props.pathogens.map(x => { return { text: x.label, value: x.id } });
        const pests = this.props.pests.map(x => { return { text: x.label, value: x.id } });
        const epiphytes = this.props.epiphytes.map(x => { return { text: x.label, value: x.id } });

        const treeOrgans = [
            { organ: 'root', label: i18n.t("racines"), options: rootSymptoms },
            { organ: 'collar', label: i18n.t("collet"), options: collarSymptoms },
            { organ: 'trunk', label: i18n.t("tronc"), options: trunkSymptoms },
            { organ: 'branch', label: i18n.t("branches"), options: branchSymptoms },
            { organ: 'leaf', label: i18n.t("feuilles"), options: leafSymptoms }
        ];

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16,
            style: isMobileOnly ? { padding: '4px' } : null
        };

        return (
            <>
                <div id='cat--vta' style={{ order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'VTA', false) }}></div>
                {treeOrgans.map(({ organ, label, options }) => {
                    const customFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === `VTA ${label}`));
                    return (
                        (customFields?.length > 0 || requiredFields[`${organ}Symptoms`] || requiredFields[`${organ}Pathogens`] || requiredFields[`${organ}Pests`] || (['trunk', 'branch'].includes(organ) && requiredFields[`${organ}Epiphytes`])) && (
                            <Grid style={{ margin: '14px 0 0 0', border: 'solid 2px var(--brown-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', `VTA ${label}`) }}>
                                {!isMobileOnly &&
                                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--brown-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} style={{ alignSelf: 'center', marginRight: '5px' }} />
                                        <span className='no-themed'>{`${i18n.t("VTA")} ${label}`}</span>
                                    </Grid.Row>}
                                {requiredFields[`${organ}Symptoms`] &&
                                    <Grid.Column {...columnProps}>
                                        <Form.Field
                                            control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs symptômes racines")}
                                            label={<label>{`${i18n.t("Symptômes")} ${label}`} :</label>}
                                            name={`${organ}SymptomId`} options={options} clearable disabled={this.props.properties.isEmpty}
                                            value={!this.props.properties.isEmpty ? (this.props.properties[`${organ}SymptomId`] || []) : []}
                                            selectOnBlur={false} multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            onChange={this.props.handleChange}
                                        />
                                    </Grid.Column>}
                                {requiredFields[`${organ}Pathogens`] &&
                                    <Grid.Column {...columnProps}>
                                        <Form.Field
                                            control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs pathogènes")}
                                            label={<label>{`${i18n.t("Pathogènes")} ${label}`} :</label>}
                                            name={`${organ}PathogenId`} options={pathogens} clearable disabled={this.props.properties.isEmpty}
                                            value={!this.props.properties.isEmpty ? (this.props.properties[`${organ}PathogenId`] || []) : []}
                                            selectOnBlur={false} multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            onChange={this.props.handleChange}
                                        />
                                    </Grid.Column>}
                                {requiredFields[`${organ}Pests`] &&
                                    <Grid.Column {...columnProps}>
                                        <Form.Field
                                            control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs ravageurs")}
                                            label={<label>{`${i18n.t("Ravageurs")} ${label}`} :</label>}
                                            name={`${organ}PestId`} options={pests} clearable disabled={this.props.properties.isEmpty}
                                            value={!this.props.properties.isEmpty ? (this.props.properties[`${organ}PestId`] || []) : []}
                                            selectOnBlur={false} multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            onChange={this.props.handleChange}
                                        />
                                    </Grid.Column>}
                                {['trunk', 'branch'].includes(organ) && requiredFields[`${organ}Epiphytes`] &&
                                    <Grid.Column {...columnProps}>
                                        <Form.Field
                                            control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs épiphytes")}
                                            label={<label>{`${i18n.t("Épiphytes")} ${label}`} :</label>}
                                            name={`${organ}EpiphyteId`} options={epiphytes} clearable disabled={this.props.properties.isEmpty}
                                            value={!this.props.properties.isEmpty ? (this.props.properties[`${organ}EpiphyteId`] || []) : []}
                                            selectOnBlur={false} multiple selection search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            onChange={this.props.handleChange}
                                        />
                                    </Grid.Column>}
                                {customFields}
                            </Grid>
                        ));
                }).filter(grid => grid)}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step7);