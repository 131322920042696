import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Composants
import ExternalAuthenticationForm from '../Forms/Users/ExternalAuthenticationForm';
import SignupForm from '../Forms/Users/SignupForm';
// Librairies
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import i18n from '../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
// Ressources
import logoMonoWhite from '../../resources/images/logo-mono-white.jpg';
// Semantic UI
import { Divider, Grid, Header, Image, Segment } from 'semantic-ui-react';
// Services
import ProjectsService from '../../services/ProjectsService';

class Signup extends Component {
    state = {
        invitationEmail: null,
        invitationType: null,
        invitationExpired: false
    };

    render() {
        const { isOnline } = this.props;
        const { invitationEmail, invitationType, invitationExpired } = this.state;

        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                <Grid.Column style={{ maxWidth: 650 }}>
                    <Header as='h2' className='text-light' textAlign='center'>
                        <Image style={{ borderRadius: '30px', marginTop: 0, marginRight: '3px' }} src={logoMonoWhite} /> {i18n.t("Inscription")}
                    </Header>
                    <Segment>
                        <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row' }}>
                            <SignupForm {...this.props} invitationEmail={invitationEmail} invitationType={invitationType} invitationExpired={invitationExpired} />
                            {!isMobileOnly ? <div className='custom-divider--vertical' style={{ marginLeft: '15px' }}></div> : <Divider />}
                            {isOnline &&
                                <div style={{ margin: '0 0 0 5px' }}>
                                    <ExternalAuthenticationForm
                                        title={i18n.t("S'authentifier avec")} history={this.props.history}
                                        logUser={this.props.logUser} changeActiveItem={this.props.changeActiveItem}
                                    />
                                </div>}
                        </div>
                        <small style={{ display: 'block', marginTop: '20px', textAlign: 'left', color: 'grey' }}>En créant votre compte, vous acceptez les <a href='/termsOfUse' target='_blank'>conditions d'utilisation</a></small>
                    </Segment>
                    <Segment>
                        {i18n.t("Déjà inscrit ?")} <Link to='/login'>{i18n.t("Se connecter")}</Link>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }

    componentDidMount = () => {
        this.props.changeActiveItem('signup');
        this.props.setProject(null);

        // Si l'utilisateur accède à la page avec un id d'invitation et qu'il n'est pas connecté
        if (this.props.match.params.invitationId && !new Cookies().get('token')) {
            ProjectsService.getInvitation(this.props.match.params.invitationId).then(response => { // On vérifie qu'il est encore valide
                if (response === 400) {
                    this.setState({ invitationExpired: true }); // Si ce n'est pas le cas on affiche un message d'erreur
                } else {
                    this.setState({ invitationEmail: response.data.email, invitationType: response.data.type });
                    new Cookies().set('invitation', JSON.stringify(response.data), { path: '/' }); // Sinon on crée un cookie
                }
            });
        } else if (new Cookies().get('invitation')) { // Sinon si un cookie d'invitation est défini
            ProjectsService.getInvitation(new Cookies().get('invitation').id).then(response => { // On vérifie qu'il est encore valide
                if (response === 400) {
                    this.setState({ invitationExpired: true }); // Si ce n'est pas le cas on affiche un message d'erreur
                    new Cookies().remove('invitation', { path: '/' }); // Et on supprime le cookie
                } else this.setState({ invitationEmail: response.data.email });
            });
        }
    }

    componentDidUpdate = () => { // Si l'utilisateur est déjà connecté, on l'empêche d'accéder au formulaire d'inscription
        if (this.props.logged !== false) { // Si l'utilisateur est déjà connecté
            if (this.props.match.params.invitationId) { // S'il accède à la page d'inscription avec une invitation
                ProjectsService.getInvitation(this.props.match.params.invitationId).then(response => { // On vérifie qu'il est encore valide
                    if (response.status === 400) {
                        this.setState({ invitationExpired: true }); // Si ce n'est pas le cas on affiche un message d'erreur
                        new Cookies().remove('invitation', { path: '/' }); // Et on supprime le cookie
                    } else {
                        const { id, type } = response.data;

                        if (type === 'project')
                            ProjectsService.addCollaboratorWithInvitation( // On l'ajoute au projet
                                id,
                                { userId: jwt_decode(new Cookies().get('token')).id }
                            );
                    }
                });
            }
            this.props.changeActiveItem('home');
            this.props.history.push('/');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
}

const mapDispatchToProps = {
    setProject
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);