import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { Form, Select, Grid, TextArea, Segment } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
// Ressources
import { faEye, faHeartbeat } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import StylesUtil from '../../../../utils/StylesUtil';
import TreesUtil from '../../../../utils/TreesUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step5 extends Component {
    state = {
        properties: {
            vigorId: 0,
            healthReviewId: 0,
            riskId: 0,
            tippingRiskId: 0,
            organCaliberId: 0,
            targetId: 0,
            observation: null
        },
        error: {
            hidden: true,
            messages: [],
            vigorId: false,
            healthReviewId: false,
            observation: false
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.trees;

        const vigors = this.props.vigors.map(x => { return { text: x.label, value: x.id } });
        const healthReviews = this.props.healthReviews.map(x => { return { text: x.value + ' (' + x.description + ')', value: x.id } });
        const risks = this.props.risks.map(x => { return { text: x.label, value: x.id } });
        const tippingRisks = this.props.tippingRisks.map(x => { return { text: x.label + ' (' + x.value + '/4)', value: x.id } });
        const organCalibers = this.props.organCalibers.map(x => { return { text: x.label + ' (' + x.value + '/4)', value: x.id } });
        const targets = this.props.targets.map(x => { return { text: x.label + ' (' + x.value + '/4)', value: x.id } });

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16,
            style: isMobileOnly ? { padding: '4px' } : null
        };

        const { tippingRiskId, organCaliberId, targetId } = this.props.properties;
        const accurateRisk = tippingRiskId && organCaliberId && targetId && (
            this.props.tippingRisks.find(tippingRisk => tippingRisk.id === tippingRiskId)?.value
            + this.props.organCalibers.find(organCaliber => organCaliber.id === organCaliberId)?.value
            + this.props.targets.find(target => target.id === targetId)?.value
        );

        const conditionCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'État'));
        const observationCustomFields = this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Observation'));

        return (
            <>
                {(requiredFields.vigor || requiredFields.healthReview || requiredFields.risk || requiredFields.accurateRisk || conditionCustomFields?.length > 0) &&
                    <Grid id='cat--condition' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--red-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'État') }}>
                        {!isMobileOnly &&
                            <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--red-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                                <FontAwesomeIcon icon={faHeartbeat} style={{ alignSelf: 'center', marginRight: '5px' }} />
                                <span className='no-themed'>{i18n.t("État")}</span>
                            </Grid.Row>}
                        {requiredFields.vigor &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez une vigueur")} className='vigor'
                                    label={<label>{i18n.t("Vigueur")} <InfoIcon content={i18n.t("Vigueur de l'arbre")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='vigorId' options={vigors} clearable disabled={this.props.properties.isEmpty || this.props.properties.isDead || this.props.properties.isStump}
                                    value={!this.props.properties.isEmpty
                                        ? (!this.props.properties.isDead && !this.props.properties.isStump ? (this.props.properties.vigorId || '') : 1)
                                        : ''}
                                    selectOnBlur={false}
                                    error={this.props.error.vigorId} onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {requiredFields.healthReview &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez une cote")} className='health-review'
                                    label={<label>{i18n.t("Cote sanitaire")} <InfoIcon content={i18n.t("Note globale de l'arbre sur 10")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='healthReviewId' options={healthReviews} clearable disabled={this.props.properties.isEmpty || this.props.properties.isDead || this.props.properties.isStump}
                                    value={!this.props.properties.isEmpty
                                        ? (!this.props.properties.isDead && !this.props.properties.isStump ? (this.props.properties.healthReviewId || '') : 1)
                                        : ''}
                                    selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    error={this.props.error.healthReviewId} onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {requiredFields.risk &&
                            <Grid.Column {...columnProps}>
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez un risque")}
                                    label={<label>{i18n.t("Risque")} <InfoIcon content={i18n.t("Niveau de risque que représente l'arbre")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                    name='riskId' options={risks} disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                                    value={!this.props.properties.isEmpty ? (!this.props.properties.isStump ? (this.props.properties.riskId || '') : 1) : ''}
                                    selectOnBlur={false} clearable
                                    onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {requiredFields.accurateRisk &&
                            <Grid.Row style={{ padding: 0 }}>
                                <label style={{ fontSize: '.92857143em', fontWeight: 'bold', marginTop: '10px', marginLeft: '14px' }}>{i18n.t("Risque (Matheny & Clarck)")} :</label>
                                <Segment style={{ margin: '7px 14px 14px 14px', width: '100%' }}>
                                    <Grid style={{ padding: 0 }}>
                                        <Grid.Column {...columnProps}>
                                            <Form.Field
                                                control={Select} placeholder={i18n.t("Sélectionnez un risque de basculement/rupture")}
                                                label={<label>{i18n.t("Risque de basculement/rupture")} :</label>}
                                                name='tippingRiskId' options={tippingRisks} disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                                                value={!this.props.properties.isEmpty ? (!this.props.properties.isStump ? (this.props.properties.tippingRiskId || '') : 1) : ''}
                                                selectOnBlur={false} clearable
                                                onChange={this.props.handleChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column {...columnProps}>
                                            <Form.Field
                                                control={Select} placeholder={i18n.t("Sélectionnez un calibre")}
                                                label={<label>{i18n.t("Calibre de l'organe instable")} :</label>}
                                                name='organCaliberId' options={organCalibers} disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                                                value={!this.props.properties.isEmpty ? (!this.props.properties.isStump ? (this.props.properties.organCaliberId || '') : 1) : ''}
                                                selectOnBlur={false} clearable
                                                onChange={this.props.handleChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column {...columnProps}>
                                            <Form.Field
                                                control={Select} placeholder={i18n.t("Sélectionnez une cible")}
                                                label={<label>{i18n.t("Cible")} :</label>}
                                                name='targetId' options={targets} disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                                                value={!this.props.properties.isEmpty ? (!this.props.properties.isStump ? (this.props.properties.targetId || '') : 1) : ''}
                                                selectOnBlur={false} clearable
                                                onChange={this.props.handleChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column {...{ ...columnProps, stretched: false, style: { ...(columnProps.style || {}), display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' } }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label style={{ fontSize: '.92857143em', fontWeight: 'bold', marginRight: '10px' }}>{i18n.t("Score")} :</label>
                                                <span>{accurateRisk ? accurateRisk + '/12' : '?'}</span>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                                <label style={{ fontSize: '.92857143em', fontWeight: 'bold', marginRight: '10px' }}>{i18n.t("Niveau de dangerosité")} :</label>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {accurateRisk ?
                                                        <>
                                                            <span
                                                                className='color-circle'
                                                                style={{ backgroundColor: StylesUtil.getAccurateRiskColor(accurateRisk), marginRight: '5px' }}
                                                            ></span>
                                                            <span>{TreesUtil.getAccurateRiskLabel(accurateRisk)}</span>
                                                        </>
                                                        : '?'}
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </Grid.Row>}
                        {conditionCustomFields}
                    </Grid>}
                {(requiredFields.observation || observationCustomFields?.length > 0) &&
                    <Grid id='cat--observation' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--grey-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'Observation') }}>
                        {!isMobileOnly &&
                            <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--grey-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                                <FontAwesomeIcon icon={faEye} style={{ alignSelf: 'center', marginRight: '5px' }} />
                                <span className='no-themed'>{i18n.t("Observation")}</span>
                            </Grid.Row>}
                        {requiredFields.observation &&
                            <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: '4px', padding: '0 14px' }}>
                                <Form.Field style={{ marginBottom: '14px', minHeight: isMobileOnly ? '200px' : '100px' }}
                                    control={TextArea} placeholder={i18n.t("Maximum 5000 caractères")}
                                    name='observation' value={this.props.properties.observation || ''}
                                    error={this.props.error.observation} onChange={this.props.handleChange}
                                />
                            </Grid.Column>}
                        {observationCustomFields}
                    </Grid>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step5);