import React, { Component } from 'react';
// Composants
import { Button } from 'semantic-ui-react';
import Toolbar from '../Navbars/Toolbar';
import BrowserNavbar from '../Navbars/BrowserNavbar';
import MobileNavbar from '../Navbars/MobileNavbar';
import MapPreview from './MapPreview';
import L from 'leaflet';
// Librairies
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { polygon } from '@turf/turf';
import tinycolor from 'tinycolor2';
// Utils
import ThemesUtil from '../../utils/ThemesUtil';
import { isMobileOnly } from 'react-device-detect';
import StylesUtil from '../../utils/StylesUtil';
import GeoJsonUtil from '../../utils/GeoJsonUtil';

const buttonsColors = ['grey', 'red', 'blue', 'green', 'yellow', 'orange', 'pink', 'purple', 'brown'];

class ThemePreview extends Component {
    render() {
        const colors = ThemesUtil.getColorNames(true);

        return (
            <div id='theme-preview'>
                <div style={{ padding: '5px 5px 0px 5px', display: 'flex', width: 'fit-content', maxWidth: '100%', margin: '0 auto', overflowX: 'auto' }}>
                    {buttonsColors.map(color => (
                        <Button key={color} color={color} style={{ margin: '2px 2px 0 0' }}>
                            {colors.find(c => c.id === color)?.label || ''}
                        </Button>
                    ))}
                </div>
                <div style={{ borderBottom: 'solid 1px var(--grey-50)', margin: '5px 0' }}></div>
                {isMobileOnly
                    ? <MobileNavbar logged={true} isPreview={true} theme={this.props.theme} />
                    : <BrowserNavbar logged={true} isPreview={true} theme={this.props.theme} logoUrl={this.props.logoUrl} />}
                <div style={{ display: 'flex' }}>
                    <Toolbar isPreview={true} />
                    <MapPreview
                        id={uuidv4()} style={{ aspectRatio: isMobileOnly ? '9/18' : '16/9' }} theme={this.props.theme} surroundings={this.getSurroundings()}
                        features={this.getFeatures()} elementStyle={{ tree: StylesUtil.getTreeStyle(), furniture: StylesUtil.getFurnitureStyle(), greenSpace: StylesUtil.getGreenSpaceStyle() }}
                    />
                </div>
            </div >
        );
    }

    componentDidMount = () => {
        this.updateButtonsColor(buttonsColors);
        this.updateNavbarAndToolbarColor();
    }

    componentDidUpdate = (prevProps) => {
        const { theme, isDarkTheme } = this.props;

        this.updateButtonsColor(buttonsColors.filter(color => prevProps.theme[color] !== theme[color]));
        if (prevProps.isDarkTheme !== isDarkTheme
            || (isDarkTheme && prevProps.theme.black !== theme.black)
            || (!isDarkTheme && prevProps.theme.white !== theme.white)
            || (prevProps.primary !== theme.primary)) {
            this.updateNavbarAndToolbarColor();
        }
    }

    updateNavbarAndToolbarColor = () => {
        const { theme, isDarkTheme } = this.props;

        // Navbar
        ThemesUtil.changeStylesheetRule(
            '#theme-preview #navbar', 'background-color',
            isDarkTheme
                ? theme.black || getComputedStyle(document.documentElement).getPropertyValue(`--black-100`)
                : theme.white || getComputedStyle(document.documentElement).getPropertyValue(`--white-100`)
        );
        // Toolbar
        ThemesUtil.changeStylesheetRule(
            '#theme-preview #toolbar', 'background-color',
            isDarkTheme
                ? theme.black || getComputedStyle(document.documentElement).getPropertyValue(`--black-100`)
                : theme.white || getComputedStyle(document.documentElement).getPropertyValue(`--white-100`)
        );
        // Toolbar - boutons
        const colorName = isDarkTheme ? 'black' : 'white';
        const baseColor = theme[colorName] || getComputedStyle(document.documentElement).getPropertyValue(`--${colorName}-100`);
        const colorValues = ThemesUtil.getColorPalette(colorName, baseColor);
        const brightnessValues = isDarkTheme ? [90, 80, 70, 60] : [100, 80, 70, 60];
        for (let i = 0; i < 4; i++) {
            const colorValue = colorValues.find(color => color.brightness === brightnessValues[i]);
            ThemesUtil.changeStylesheetRule(
                `#theme-preview #toolbar-category-${i}`, 'background-color',
                isDarkTheme
                    ? colorValue?.value || getComputedStyle(document.documentElement).getPropertyValue(`--black-${brightnessValues[i]}`)
                    : colorValue?.value || getComputedStyle(document.documentElement).getPropertyValue(`--white-${brightnessValues[i]}`)
            );
        }
        // Toolbar - icônes
        ThemesUtil.changeStylesheetRule(
            '#theme-preview #toolbar .ui.button.active', 'color', theme.primary || getComputedStyle(document.documentElement).getPropertyValue(`--primary-100`)
        );
    }

    updateButtonsColor = (buttonsColors) => {
        buttonsColors.forEach(color => {
            const baseColor = this.props.theme[color] || getComputedStyle(document.documentElement).getPropertyValue(`--${color}-100`);
            const colorValues = ThemesUtil.getColorPalette(color, baseColor);
            ThemesUtil.changeStylesheetRule(`#theme-preview .ui.${color}.button`, 'background-color', colorValues.find(color => color.brightness === 100).value);
            ThemesUtil.changeStylesheetRule(`#theme-preview .ui.${color}.button:hover:not(:active)`, 'background-color', colorValues.find(color => color.brightness === 110).value);
            ThemesUtil.changeStylesheetRule(`#theme-preview .ui.${color}.button:active`, 'background-color', colorValues.find(color => color.brightness === 120).value);

            const isDark = tinycolor(baseColor).getBrightness() < 180;
            ThemesUtil.changeStylesheetRule(`#theme-preview .ui.button.${color}`, 'color', isDark ? 'white' : 'black');
            ThemesUtil.changeStylesheetRule(`#theme-preview.ui.button.${color}:hover:not(:active)`, 'color', isDark ? 'white' : 'black');
            ThemesUtil.changeStylesheetRule(`#theme-preview .ui.button.${color}:active`, 'color', isDark ? 'white' : 'black');
            ThemesUtil.changeStylesheetRule(`#theme-preview.ui.button.${color}:focus`, 'color', isDark ? 'white' : 'black');
            ThemesUtil.changeStylesheetRule(` #theme-preview .ui.active.button.${color}`, 'color', isDark ? 'white' : 'black');
        });
    }

    getFeatures = () => {
        return [
            GeoJsonUtil.generateMarkerFeature({ category: 'Arbre' }, this.getTreeCoordinates()),
            GeoJsonUtil.generateMarkerFeature({ category: 'Mobilier' }, this.getFurnitureCoordinates()),
            GeoJsonUtil.generatePolygonFeature({ category: 'Espace vert' }, this.getGreenSpaceCoordinates())
        ];
    }

    getSurroundings = () => {
        return polygon([[
            [
                4.442553520202637,
                50.410814904735474
            ],
            [
                4.4484758377075195,
                50.410814904735474
            ],
            [
                4.4484758377075195,
                50.41341299115119
            ],
            [
                4.442553520202637,
                50.41341299115119
            ],
            [
                4.442553520202637,
                50.410814904735474
            ]
        ]]);
    }

    getGreenSpaceCoordinates = () => {
        return [
            [
                [
                    4.445396661758423,
                    50.41245581799453
                ],
                [
                    4.446051120758057,
                    50.41163536846878
                ],
                [
                    4.446426630020142,
                    50.41147127685875
                ],
                [
                    4.4474029541015625,
                    50.41246949203291
                ],
                [
                    4.446018934249878,
                    50.41302328727127
                ],
                [
                    4.445396661758423,
                    50.41245581799453
                ]
            ]
        ];
    }

    getTreeCoordinates = () => L.latLng(50.41160801990657, 4.444549083709717);
    getFurnitureCoordinates = () => L.latLng(50.41290801990657, 4.447549083709717);
}


const mapStateToProps = (state) => {
    return {
        municipalities: state.municipalities,
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(ThemePreview);