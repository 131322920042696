import React, { Component } from 'react';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setEssences } from '../../../actionCreators/treesActions';
// Semantic UI
import { Form, Input, Select, Icon, Button, Segment, Divider } from 'semantic-ui-react';
// Services
import TreesService from '../../../services/TreesService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';

const initialEssence = {
    order: null,
    vernacularName: null,
    gender: null,
    species: null,
    cultivar: null,
    typeCoefficient: 0,
    silhouetteCoefficient: 0,
    speciesCoefficient: 0,
    coolingCoefficient: 0,
    density: 0,
    fruitProduction: 0,
    maxHeight: 0,
    maxCircumference: 0,
    maxCrownDiameter: 0,
    growthIndex: 0,
    biodiversityIndex: 0,
    circumferencePerYear: 0
};

const initialError = {
    order: false,
    vernacularName: false,
    gender: false,
    typeCoefficient: false,
    silhouetteCoefficient: false,
    speciesCoefficient: false,
    coolingCoefficient: false,
    density: false,
    fruitProduction: false,
    biodiversityIndex: false
};

class EssenceForm extends Component {
    state = {
        newEssence: initialEssence,
        error: initialError,
        isLoading: false
    }

    render() {
        return (
            <Segment style={{ padding: '20px', width: '50vw' }}>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Ajout d'une nouvelle essence")}</h3>
                <Divider />
                <Form onSubmit={this.handleSubmit} error style={{ textAlign: 'left' }} widths='equal'>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={16} label={i18n.t("Nom vernaculaire") + ' : '} placeholder={i18n.t("Facultatif")}
                            name='vernacularName' value={this.state.newEssence.vernacularName || ''}
                            onChange={this.handleChange} error={this.state.error.vernacularName}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Select} width={8} style={{ minWidth: 0 }} label={i18n.t("Ordre") + '* : '} placeholder={i18n.t("Obligatoire")} selectOnBlur={false}
                            name='order' value={this.state.newEssence.order || ''}
                            options={[{ text: i18n.t("Feuillu"), value: 'Feuillu' }, { text: i18n.t("Résineux"), value: 'Résineux' }]}
                            onChange={this.handleChange} error={this.state.error.order}
                        />
                        <Form.Field
                            control={Input} width={8} label={i18n.t("Genre") + '* :'} placeholder={i18n.t("Obligatoire")}
                            name='gender' value={this.state.newEssence.gender || ''}
                            onChange={this.handleChange} error={this.state.error.gender}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} label={i18n.t("Espèce") + ' :'} placeholder={i18n.t("Facultatif")}
                            name='species' value={this.state.newEssence.species || ''}
                            onChange={this.handleChange} error={this.state.error.species}
                        />
                        <Form.Field
                            control={Input} width={8} label={i18n.t("Cultivar") + ' :'} placeholder={i18n.t("Facultatif")}
                            name='cultivar' value={this.state.newEssence.cultivar || ''}
                            onChange={this.handleChange} error={this.state.error.cultivar}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} type='number' step='0.01' label={i18n.t("Coefficient de type") + '* :'} placeholder='0 à 10'
                            name='typeCoefficient'
                            value={this.state.newEssence.typeCoefficient || ''}
                            onChange={this.handleChange} error={this.state.error.typeCoefficient}
                        />
                        <Form.Field
                            control={Input} width={8} type='number' step='0.01' label={i18n.t("Coefficient de silhouette") + '* :'} placeholder='0 à 20'
                            name='silhouetteCoefficient'
                            value={this.state.newEssence.silhouetteCoefficient || ''}
                            onChange={this.handleChange} error={this.state.error.silhouetteCoefficient}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} type='number' step='0.01' label={i18n.t("Coefficient d'espèce") + '* :'} placeholder='0 à 5'
                            name='speciesCoefficient'
                            value={this.state.newEssence.speciesCoefficient || ''}
                            onChange={this.handleChange} error={this.state.error.speciesCoefficient}
                        />
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Coefficient de rafraîchissement") + ' :'} placeholder='0 à 10'
                            name='coolingCoefficient' value={this.state.newEssence.coolingCoefficient || ''}
                            onChange={this.handleChange} error={this.state.error.coolingCoefficient}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Densité") + '* :'} placeholder='100 à 1000'
                            name='density' value={this.state.newEssence.density || ''}
                            onChange={this.handleChange} error={this.state.error.density}
                        />
                        <Form.Field
                            control={Input} width={8} type='number' placeholder='0 à 10.000' label={`${i18n.t("Production de fruits")} (kg/${i18n.t("an")}) :`}
                            name='fruitProduction' value={this.state.newEssence.fruitProduction || ''}
                            onChange={this.handleChange} error={this.state.error.fruitProduction}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Hauteur maximale") + '(m) :'} placeholder='Facultatif'
                            name='maxHeight' value={this.state.newEssence.maxHeight || ''}
                            onChange={this.handleChange}
                        />
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Circonférence maximale") + '(cm) :'} placeholder='Facultatif'
                            name='maxCircumference' value={this.state.newEssence.maxCircumference || ''}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Diamètre de couronne maximal") + '(m) :'} placeholder='Facultatif'
                            name='maxCrownDiameter' value={this.state.newEssence.maxCrownDiameter || ''}
                            onChange={this.handleChange}
                        />
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Indice de croissance") + ' :'} placeholder='Facultatif'
                            name='growthIndex' value={this.state.newEssence.growthIndex || ''}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Potentiel de biodiversité") + ' :'} placeholder='Facultatif'
                            name='biodiversityIndex' value={this.state.newEssence.biodiversityIndex || ''}
                            onChange={this.handleChange} error={this.state.error.biodiversityIndex}
                        />
                        <Form.Field
                            control={Input} width={8} type='number' label={i18n.t("Croissance en circonférence") + '(cm/an) :'} placeholder='Facultatif'
                            name='circumferencePerYear' value={this.state.newEssence.circumferencePerYear || ''}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Button type='submit' color='green' disabled={this.state.isLoading}>
                        {i18n.t("Ajouter")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                    </Button>
                </Form>
            </Segment>
        );
    }

    handleChange = (e, { name, value, type }) => {
        if (type === 'number')
            value = value ? `${Number(value)}` : 0;
        else value = value ? value.trim() : null;
        this.setState({
            newEssence: { ...this.state.newEssence, [name]: value },
            error: { ...this.state.error, [name]: false }
        });
    }

    handleSubmit = () => {
        let isValid = true;
        let error = { ...initialError };

        const addError = (property) => {
            error[property] = true;
            isValid = false;
        }

        if (!this.state.newEssence.order?.trim())
            addError('order');
        if (!this.state.newEssence.gender?.trim())
            addError('gender');
        if (!this.state.newEssence.typeCoefficient || this.state.newEssence.typeCoefficient < 0 || this.state.newEssence.typeCoefficient > 10)
            addError('typeCoefficient');
        if (!this.state.newEssence.silhouetteCoefficient || this.state.newEssence.silhouetteCoefficient < 0 || this.state.newEssence.silhouetteCoefficient > 20)
            addError('silhouetteCoefficient');
        if (!this.state.newEssence.speciesCoefficient || this.state.newEssence.speciesCoefficient < 0 || this.state.newEssence.speciesCoefficient > 5)
            addError('speciesCoefficient');
        if (this.state.newEssence.coolingCoefficient && (this.state.newEssence.coolingCoefficient < 0 || this.state.newEssence.coolingCoefficient > 10))
            addError('coolingCoefficient');
        if (!this.state.newEssence.density || this.state.newEssence.density < 100 || this.state.newEssence.density > 1000)
            addError('density');
        if (this.state.newEssence.fruitProduction && (this.state.newEssence.fruitProduction < 0 || this.state.newEssence.fruitProduction > 10000))
            addError('fruitProduction');
        if (this.state.newEssence.biodiversityIndex && (this.state.newEssence.biodiversityIndex < 0 || this.state.newEssence.biodiversityIndex > 5))
            addError('biodiversityIndex');

        const newEssence = this.state.newEssence;
        if (this.props.essences.find(essence => essence.order === newEssence.order && essence.gender === newEssence.gender
            && essence.species === newEssence.species && essence.cultivar === newEssence.cultivar)) {
            showToast('essence_addition_not_allowed');
            isValid = false;
        }

        if (isValid) {
            this.setState({ isLoading: true });
            TreesService.addEssence(this.state.newEssence).then((essence) => {
                this.setState({ newEssence: initialEssence, error: initialError, isLoading: false });
                this.props.setEssences([...this.props.essences, essence]);
            });
        } else this.setState({ error: { ...error } });
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences
    };
};

const mapDispatchToProps = {
    setEssences
};

export default connect(mapStateToProps, mapDispatchToProps)(EssenceForm);