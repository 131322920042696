import React, { Component } from 'react';
// Composants
import { Button, Form } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librarires
import { faTimes, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';

class ModifySurroundingsForm extends Component {
    render() {
        const { isToolbarExpanded, style } = this.props;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms', ...(style || {}) }}>
                <Form error style={{ width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        <Button
                            type='button' color='red' title={i18n.t("Annuler")} size='small'
                            style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                            onClick={() => this.props.finishSurroundingsUpdate()}
                        >
                            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                        </Button>
                        <Button
                            id='DAyCHVgg' type='button' color='green' title={i18n.t("Valider")} size='small'
                            style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                            onClick={() => this.props.finishSurroundingsModification()}
                        >
                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isToolbarExpanded: state.isToolbarExpanded
    };
}

export default connect(mapStateToProps)(ModifySurroundingsForm);