import React, { Component } from 'react';
import { connect } from 'react-redux';
// Composants
import Dropzone from 'react-dropzone';
import { Button, Dimmer, Form, Loader, Progress, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import L from 'leaflet';
import { fromBlob } from 'geotiff';
import axios from 'axios';
import i18n from '../../../locales/i18n';
import { booleanIntersects, multiPolygon, polygon } from '@turf/turf';
import JSZip from 'jszip';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
// Services
import BackgroundImagesService from '../../../services/BackgroundImagesService';
// Utils
import StylesUtil from '../../../utils/StylesUtil';
import { showToast } from '../../../utils/ToastsUtil';
import GeometriesUtil from '../../../utils/GeometriesUtil';

const maxSize = 209761537; // 200mo

class BackgroundImageAdditionForm extends Component {
    state = {
        isLoading: false,
        message: '',
        maxItems: 10,
        dropZone: {
            isDragAccept: false,
            isDragActive: false,
            isDragReject: false,
            isTooManyItems: false,
            message: ''
        },
        progress: {
            message: '',
            percentage: 0,
            showProgress: true
        }
    }

    render() {
        const { dropZone, isLoading, progress, maxItems } = this.state;
        let dropzoneStyle = StylesUtil.getDropZoneStyle(dropZone);
        dropzoneStyle.height = '100%';
        dropzoneStyle.justifyContent = 'center';
        if (!this.props.isOnline) dropzoneStyle.borderColor = 'grey';

        return (
            <div className='modal-content'>
                <div className='modal-content-body' style={{ paddingBottom: '15px' }}>
                    <Segment style={{ height: '100%' }}>
                        {!isLoading ?
                            <Form style={{ height: '100%' }}>
                                <Dropzone accept={{ '': ['.png', '.jpg', '.jpeg', '.tif', '.tiff'],  }} useFsAccessApi={false} multiple={false} disabled={!this.props.isOnline} onDrop={this.handleDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section style={{ height: '100%' }}>
                                            <div {...getRootProps()}
                                                style={dropzoneStyle}>
                                                <input {...getInputProps()} />
                                                {dropZone.isDragAccept && (<p>{dropZone.message}</p>)}
                                                {dropZone.isDragReject && (<p>{dropZone.message}</p>)}
                                                {dropZone.isTooManyItems && (<p>{i18n.t("Vous ne pouvez pas mettre plus de {{maxItems}} calque(s)", { maxItems })}</p>)}
                                                {!dropZone.isDragActive && (<p>{i18n.t("Glissez-déposez ou cliquez pour importer un calque")} (.png, .jpg, .jpeg, .tif, .tiff)</p>)}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Form>
                            : <Dimmer active={true} style={{ ...StylesUtil.getMapStyles().dimmerStyle }}>
                                {progress.showProgress ?
                                    <>
                                        {progress.message}
                                        <Progress percent={progress.percentage} progress active color='blue' size='small' style={{ marginTop: '5px' }} />
                                    </>
                                    : <Loader content={progress.message} />}
                            </Dimmer>}
                    </Segment>
                </div>
                <div className='modal-content-footer'>
                    <Button
                        type='button' className='form-button' color='red'
                        onClick={() => this.props.hideForm(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                    </Button>
                </div>
            </div >
        );
    }

    componentWillUnmount = () => {
        if (this.cancelTokenSource) this.cancelTokenSource.cancel();
    }

    handleDrop = async (images) => {
        if (images.length === 1 && images[0].size <= maxSize) {
            this.setState({ isLoading: true });

            const isRaster = images[0].type.includes('tif');
            let image = isRaster ? await (await fromBlob(images[0])).getImage() : images[0];
            let bounds = null;
            if (isRaster) {
                this.setState({ progress: { message: i18n.t("Récupération du géoréférencement de l'image en cours..."), percentage: 0, showProgress: true } });
                if (image.geoKeys) {
                    bounds = image.getBoundingBox();
                    const newBounds = [ // Corners: TL, TR, BL, BR
                        [bounds[0], bounds[3]],
                        [bounds[2], bounds[3]],
                        [bounds[0], bounds[1]],
                        [bounds[2], bounds[1]]
                    ];

                    const projectionSource = this.props.projections.find(p => p.code === image.geoKeys.ProjectedCSTypeGeoKey);
                    if (projectionSource) {
                        bounds = newBounds.map(b => {
                            const coords = GeometriesUtil.projectPoint(b, projectionSource).reverse();
                            return new L.latLng(coords[0], coords[1]);
                        });

                        const rasterGeometry = polygon([[[bounds[0].lng, bounds[0].lat], [bounds[1].lng, bounds[1].lat], [bounds[3].lng, bounds[3].lat], [bounds[2].lng, bounds[2].lat], [bounds[0].lng, bounds[0].lat]]])
                        const rawSurroundings = JSON.parse(this.props.project.surroundings);
                        const surroundingGeometry = rawSurroundings.geometry.type === 'Polygon'
                            ? polygon(rawSurroundings.geometry.coordinates)
                            : multiPolygon(rawSurroundings.geometry.coordinates);
                        if (!booleanIntersects(surroundingGeometry, rasterGeometry)) bounds = null;
                    } else bounds = null;
                }

                // Compression du fichier TIFF en ZIP
                this.setState(prevState => ({ progress: { ...prevState.progress, message: i18n.t("Compression de l'image avant envoi.."), percentage: 0 } }));
                const fileName = images[0].name;
                const zipName = fileName.split('.').slice(0, -1).join('.') + '.zip';
                const zip = new JSZip();
                let reader = new FileReader();
                reader.onload = async (e) => {
                    let arrayBuffer = new Uint8Array(reader.result);
                    zip.file(fileName, arrayBuffer, { binary: true, compression: 'DEFLATE', compressionOptions: { level: 1 } });
                    const blob = await zip.generateAsync({ type: 'blob', streamFiles: true }, (metadata) => {
                        if (Math.round(metadata.percent) !== this.state.progress.percentage)
                            this.setState(prevState => ({ progress: { ...prevState.progress, percentage: Math.round(metadata.percent) } }));
                    });
                    const zipFile = new File([blob], zipName, { type: blob.type })

                    // Envoie du ZIP à l'API
                    this.setState(prevState => ({ progress: { ...prevState.progress, message: i18n.t("Envoie de l'image en cours..."), percentage: 0 } }));
                    const formData = new FormData();
                    formData.append('zipFile', zipFile);
                    this.cancelTokenSource = axios.CancelToken.source();
                    const axiosOptions = {
                        onUploadProgress: (processEvent) => {
                            const { loaded, total } = processEvent;
                            let percent = Math.floor((loaded * 100) / total);
                            if (percent !== this.state.progress.percentage)
                                this.setState(prevState => ({ progress: { ...prevState.progress, percentage: percent } }), () => {
                                    if (this.state.progress.percentage === 100)
                                        this.setState(prevState => ({ progress: { ...prevState.progress, message: i18n.t("Traitement de l'image en cours..."), showProgress: false } }));
                                });
                        },
                        cancelToken: this.cancelTokenSource?.token
                    }
                    BackgroundImagesService.convertRasterToPng(formData, axiosOptions, this.cancelTokenSource).then(blob => {
                        this.cancelTokenSource = null;
                        let name = images[0].name;
                        const extension = name.split('.').pop();
                        name = name.replace(`.${extension}`, '.png');
                        const png = new File([blob], name, { type: 'image/png' });
                        this.addBackgroundImageToMap(png, bounds);
                    });
                }
                reader.readAsArrayBuffer(images[0]);
            } else {
                this.setState(prevState => ({ progress: { ...prevState.progress, message: i18n.t("Ajout de l'image en cours..."), showProgress: false } }));
                this.addBackgroundImageToMap(images[0]);
            }
        } else if (images.length > 1) {
            this.setState(prevState => ({
                dropZone: {
                    ...prevState.dropZone,
                    isTooManyItems: true
                }
            }));
        } else if (images[0].size > maxSize) {
            this.setState(prevState => ({
                dropZone: {
                    ...prevState.dropZone,
                    isDragReject: true,
                    message: i18n.t("L'image ne peut dépasser la taille de 200mo.")
                }
            }));
        }
    }

    addBackgroundImageToMap = (image, bounds = null) => {
        if (this.props.layer.getLayers().length + 1 <= this.state.maxItems) {
            this.props.addBackgroundImage(image, true, true, bounds);
            this.props.hideForm(true);
        } else {
            showToast('background_images_limit_reached', this.state.maxItems);
            this.setState(prevState => ({
                isLoading: false,
                dropZone: {
                    ...prevState.dropZone,
                    isDragAccept: false,
                    isDragReject: false,
                    isDragActive: false,
                    isTooManyItems: true
                }
            }));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        projections: state.projections,
        project: state.project,
        isOnline: state.isOnline
    };
}

export default connect(mapStateToProps)(BackgroundImageAdditionForm);