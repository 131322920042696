import React, { Component } from 'react';
// Composants
import { Label, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import tinycolor from 'tinycolor2';
import { faQuestion, faUsers } from '@fortawesome/pro-solid-svg-icons';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect';
// Utils
import WebSocketUtil from '../../utils/WebSocketUtil';

class FormMemberList extends Component {
    state = {
        id: null,
        members: []
    };

    render() {
        return (<>{(!isMobileOnly || this.props.isLandscape) && this.renderMembers()}</>);
    }

    componentDidMount = () => this.setState({ id: this.props.id }, () => this.props.webSocketHubs?.formsHub.stop().then(this.handleFormsHub));
    componentDidUpdate = (prevProps) => {
        if (this.props.id && prevProps.id !== this.props.id) {
            clearTimeout(this.formsHubTimeout);
            this.setState({ id: this.props.id, members: [] }, () => {
                if (this.props.webSocketHubs?.formsHub) this.props.webSocketHubs.formsHub.stop().then(this.handleFormsHub);
            });
        }
    }

    componentWillUnmount = () => {
        this.props.webSocketHubs?.formsHub.stop();
        if (this.timeout) clearTimeout(this.timeout);
    }

    handleFormsHub = () => {
        const { id } = this.state;

        if (id && this.props.webSocketHubs.formsHub.state === 'Disconnected') {
            const handleHub = () => {
                if (id === this.props.id) {
                    this.props.setIsLoading(true);
                    // Invokers
                    try {
                        this.props.webSocketHubs.formsHub.invoke('JoinGroup', id);
                        this.props.webSocketHubs.formsHub.invoke('GetLatestFormState', id);
                    } catch {
                        this.props.setIsLoading(false);
                    }

                    // Events
                    this.props.webSocketHubs.formsHub.on('MemberJoinedGroup', members => {
                        const membersParsed = JSON.parse(members);
                        if (!membersParsed.filter(mp => mp.connectionId !== this.props.webSocketHubs.formsHub.connectionId).length)
                            this.props.setIsLoading(false);

                        membersParsed.forEach(newMember => {
                            const projectRole = this.props.projectCollaborators.find(pc => pc.userId === newMember.userId)?.projectRole;
                            newMember.color = projectRole?.color;
                            newMember.icon = projectRole?.icon || faQuestion;
                        });

                        const uniqueMembers = membersParsed.reduce((accumulator, member) => {
                            if (!accumulator.find((m) => m.connectionId === member.connectionId && m.userId === member.userId))
                                accumulator.push(member);
                            return accumulator;
                        }, []);

                        this.setState({ members: uniqueMembers });
                    });

                    this.props.webSocketHubs.formsHub.on('MemberDisconnected', connectionId => {
                        this.setState(prevState => ({ members: prevState.members.filter(x => x.connectionId !== connectionId) }));
                    });
                    this.props.webSocketHubs.formsHub.on('GetLatestFormState', connectionId => {
                        if (this.props.webSocketHubs.formsHub.connectionId !== connectionId && this.props.stateToSend)
                            WebSocketUtil.sendLatestFormStateToMember(this.props.webSocketHubs, connectionId, this.props.stateToSend);
                        else this.props.setIsLoading(false);
                    });
                    this.props.webSocketHubs.formsHub.on('UpdateForm', state => this.props.updateForm(JSON.parse(state)));
                }
            };

            if (id === this.props.id) {
                this.props.setIsLoading(true);
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => this.props.setIsLoading(false), 5000);
            }
            this.props.webSocketHubs.formsHub.start().then(handleHub).catch(() => this.props.setIsLoading(false));
            this.props.webSocketHubs.formsHub.onreconnected(handleHub);
            this.props.webSocketHubs.formsHub.onclose(() => this.setState({ members: [] }));
        }
    }

    renderMembers = () => {
        const members = JSON.parse(JSON.stringify(this.state.members));
        members.sort((a) => a.connectionId !== this.props.webSocketHubs.formsHub.connectionId ? 1 : -1);
        const connectionId = this.props.webSocketHubs.formsHub.connection.connectionId;
        const userId = jwt_decode(new Cookies().get('token'))?.id;
        let membersToRender = members.filter(m => m.userId !== userId || m.connectionId === connectionId); //? Masquage temporaire des utilisateurs ayant le même userId à cause du bug de connexion persistante à la websocket
        membersToRender = membersToRender.filter(
            (member, index) =>
                membersToRender.findIndex((m) => m.userId === member.userId) === index
        );
        const nbMembers = membersToRender.length;
        const nbMaxMembers = isMobileOnly ? 0 : isTablet ? 5 : 10;
        const nbMembersHidden = nbMembers > nbMaxMembers ? nbMembers - nbMaxMembers : 0;
        const memberList = [];

        let index = 0;
        membersToRender
            .slice(0, nbMembers - nbMembersHidden)
            .forEach(member => {
                const userName = !member.lastName ? member.firstName : `${member.firstName} ${member.lastName}`;
                const popupContent = userName + (member.connectionId === connectionId ? ` (${i18n.t("vous")})` : '');
                const border = this.props.isDarkTheme ? '1px solid white' : '1px solid black';
                const backgroundColor = member.color;
                const isDark = tinycolor(backgroundColor).getBrightness() < 180;
                memberList.push(
                    <Popup key={index++}
                        content={<><FontAwesomeIcon icon={member.icon} style={{ marginRight: '10px' }} />{popupContent}</>}
                        trigger={
                            <Label style={{ borderRadius: '100%', backgroundColor, border, color: isDark ? 'white' : 'black' }}>
                                {member.firstName[0].toUpperCase()}
                            </Label>}
                    />);
            });

        if (nbMembersHidden)
            memberList.push(
                <Popup
                    key={index}
                    content={<div style={{ display: 'flex', flexDirection: 'column', gap: '5px', maxHeight: '250px', overflowY: 'auto', width: 'fit-content' }}>
                        {membersToRender
                            .slice(nbMaxMembers)
                            .map((m, index) => {
                                const userName = !m.lastName ? m.firstName : `${m.firstName} ${m.lastName}`;
                                return (
                                    <div key={index}>
                                        <FontAwesomeIcon icon={m.icon} style={{ marginRight: '10px' }} />{userName}
                                    </div>
                                )
                            })}
                    </div>}
                    hoverable
                    mouseEnterDelay={500} position='bottom left'
                    trigger={
                        <Label key={0} style={{
                            backgroundColor: 'var(--blue-100)', color: 'var(--white-100)'
                        }}>
                            <FontAwesomeIcon icon={faUsers} style={{ marginRight: '5px' }} />{nbMembersHidden}
                        </Label>}
                />);

        return memberList;
    }
}

const mapStateToProps = (state) => {
    return {
        projectCollaborators: state.projectCollaborators,
        webSocketHubs: state.webSocketHubs
    };
};

export default withOrientationChange(connect(mapStateToProps)(FormMemberList));