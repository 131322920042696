import React, { Component } from 'react';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setActions } from '../../../actionCreators/projectsActions';
// Semantic UI
import { Form, Input, Select, Icon, Button, Segment, Divider, Checkbox } from 'semantic-ui-react';
// Services
import ActionsService from '../../../services/ActionsService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';

const initialAction = {
    label: null,
    categories: [],
    recurrencesAllowed: true,
    price1: 0,
    price2: 0,
    price3: 0,
    price4: 0,
    price5: 0,
    price6: 0,
    calculationUnit: 'sqmt'
};

const initialError = {
    label: false,
    price1: false,
    price2: false,
    price3: false,
    price4: false,
    price5: false,
    price6: false
};

class EssenceForm extends Component {
    state = {
        newAction: initialAction,
        error: initialError,
        isLoading: false
    }

    render() {
        return (
            <Segment style={{ padding: '20px', width: '50vw' }}>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Ajout d'une nouvelle action")}</h3>
                <Divider />
                <Form onSubmit={this.handleSubmit} error style={{ textAlign: 'left' }} widths='equal'>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={5} label={i18n.t("Libellé") + '* : '} placeholder={i18n.t("Obligatoire")}
                            name='label' value={this.state.newAction.label || ''}
                            onChange={this.handleChange} error={this.state.error.label}
                        />
                        <Form.Field
                            control={Checkbox} width={5} label={i18n.t("Récurrences")} style={{ marginTop: '32px' }}
                            name='recurrencesAllowed' checked={this.state.newAction.recurrencesAllowed}
                            onChange={this.handleCheckboxChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Select} multiple style={{ minWidth: 0 }} label={i18n.t("Catégories") + '* : '} placeholder={i18n.t("Obligatoire")} selectOnBlur={false}
                            name='categories' value={this.state.newAction.categories || []}
                            options={[{ text: i18n.t("Arbre"), value: 'Arbre' }, { text: i18n.t("Espace vert"), value: 'Espace vert' }, { text: i18n.t("Massif arboré"), value: 'Massif arboré' }, { text: i18n.t("Mobilier"), value: 'Mobilier' }]}
                            onChange={this.handleChange} error={this.state.error.categories}
                        />
                        {this.state.newAction.categories?.includes('Espace vert') && <Form.Field
                            control={Select} label={i18n.t("Méthode de calcul") + '* : '} selectOnBlur={false}
                            name='calculationUnit' value={this.state.newAction.calculationUnit || []}
                            options={[{ text: i18n.t("Unité"), value: 'unit' }, { text: i18n.t("Mètre carré"), value: 'sqmt' }]}
                            onChange={this.handleChange}
                        />}
                    </Form.Group>
                    <Form.Group style={{ marginTop: '14px' }}>
                        <Form.Field
                            control={Input} width={5} type='number' step='0.01' label={`${i18n.t("Prix")} 1 :`} placeholder={i18n.t("Facultatif")}
                            name='price1' value={this.state.newAction.price1 || ''}
                            onChange={this.handleChange} error={this.state.error.price1}
                        />
                        <Form.Field
                            control={Input} width={5} type='number' step='0.01' label={`${i18n.t("Prix")} 2 :`} placeholder={i18n.t("Facultatif")}
                            name='price2' value={this.state.newAction.price2 || ''}
                            onChange={this.handleChange} error={this.state.error.price2}
                        />
                        <Form.Field
                            control={Input} width={5} type='number' step='0.01' label={`${i18n.t("Prix")} 3 :`} placeholder={i18n.t("Facultatif")}
                            name='price3' value={this.state.newAction.price3 || ''}
                            onChange={this.handleChange} error={this.state.error.price3}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            control={Input} width={5} type='number' step='0.01' label={`${i18n.t("Prix")} 4 :`} placeholder={i18n.t("Facultatif")}
                            name='price4' value={this.state.newAction.price4 || ''}
                            onChange={this.handleChange} error={this.state.error.price4}
                        />
                        <Form.Field
                            control={Input} width={5} type='number' step='0.01' label={`${i18n.t("Prix")} 5 :`} placeholder={i18n.t("Facultatif")}
                            name='price5' value={this.state.newAction.price5 || ''}
                            onChange={this.handleChange} error={this.state.error.price5}
                        />
                        <Form.Field
                            control={Input} width={5} type='number' step='0.01' label={`${i18n.t("Prix")} 6 :`} placeholder={i18n.t("Facultatif")}
                            name='price6' value={this.state.newAction.price6 || ''}
                            onChange={this.handleChange} error={this.state.error.price6}
                        />
                    </Form.Group>
                    <Button type='submit' color='green' disabled={this.state.isLoading}>
                        {i18n.t("Ajouter")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                    </Button>
                </Form>
            </Segment>
        );
    }

    handleChange = (_, { name, value }) => {
        this.setState({
            newAction: { ...this.state.newAction, [name]: value },
            error: { ...this.state.error, [name]: false }
        });
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState({ newAction: { ...this.state.newAction, [name]: checked } });
    }

    handleSubmit = () => {
        let isValid = true;
        let error = { ...initialError };
        const newAction = this.state.newAction;

        const addError = (property) => {
            error[property] = true;
            isValid = false;
        }

        if (!newAction.label?.trim())
            addError('label');
        if (!newAction.categories?.length)
            addError('categories');
        if (isNaN(newAction.price1) || newAction.price1 < 0)
            addError('price1');
        if (isNaN(newAction.price2) || newAction.price2 < 0)
            addError('price2');
        if (isNaN(newAction.price3) || newAction.price3 < 0)
            addError('price3');
        if (isNaN(newAction.price4) || newAction.price4 < 0)
            addError('price4');
        if (isNaN(newAction.price5) || newAction.price5 < 0)
            addError('price5');
        if (isNaN(newAction.price6) || newAction.price6 < 0)
            addError('price6');

        if (this.props.actions.find(action => action.categories.some(category => newAction.categories.includes(category)) && action.label === newAction.label)) {
            showToast('action_addition_not_allowed');
            isValid = false;
        }

        if (isValid) {
            Object.keys(newAction).forEach(property => {
                if (property.includes('price')) newAction[property] = Number(newAction[property]);
            });
            if (!newAction.categories.includes('Espace vert')) newAction.calculationUnit = null;

            this.setState({ isLoading: true });
            ActionsService.addAction(newAction).then(action => {
                this.setState({ newAction: initialAction, error: initialError, isLoading: false });
                this.props.setActions([...this.props.actions, action]);
            });
        } else this.setState({ error: { ...error } });
    }
}

const mapStateToProps = (state) => {
    return {
        actions: state.actions
    };
};

const mapDispatchToProps = {
    setActions
};

export default connect(mapStateToProps, mapDispatchToProps)(EssenceForm);