import React, { Component } from 'react';
import AppSettings from '../../AppSettings';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'semantic-ui-react';
// Librairies
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
// Ressources
import { faCircleDollar, faPalette, faPenField, faLock, faSackDollar, faUsers, faBook, faFolders } from '@fortawesome/pro-solid-svg-icons'

class OrganizationSidebar extends Component {
    render() {
        const { activeItem, activeOrganization } = this.props;
        const iconStyle = { fontSize: '20px', width: '20px', marginRight: !isMobile && '10px' };
        const itemStyle = isMobile ? { paddingLeft: '0px', paddingRight: '0px', textAlign: 'center' } : null;
        const labelStyle = { fontSize: '18px' };
        const areCustomFieldsDisabled = !activeOrganization?.subscription?.customFields;
        const isBrandingDisabled = !activeOrganization?.subscription?.branding;
        const isCostEstimationDisabled = !activeOrganization?.subscription?.costEstimation;
        const title = i18n.t("Upgradez votre licence pour débloquer cette fonctionnalité");
        const isUrbasense = AppSettings.isUrbasenseUrl();

        return (
            <Menu fluid vertical tabular style={{ borderRadius: '5px 0 0 5px' }}>
                <Menu.Item name='general' style={itemStyle} active={activeItem === 'general'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faBook} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Informations")}</span>}
                </Menu.Item>
                <Menu.Item name='members' style={itemStyle} active={activeItem === 'members'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faUsers} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Membres")}</span>}
                </Menu.Item>
                <Menu.Item name='projects' style={itemStyle} active={activeItem === 'projects'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faFolders} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Projets")}</span>}
                </Menu.Item>
                <Menu.Item name='subscription' style={itemStyle} active={activeItem === 'subscription'} onClick={this.handleClick}>
                    <FontAwesomeIcon icon={faCircleDollar} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Abonnement")}</span>}
                </Menu.Item>
                {!isUrbasense &&
                    <Menu.Item name='themes' title={isBrandingDisabled && title} style={itemStyle} active={activeItem === 'themes'} onClick={this.handleClick} disabled={isBrandingDisabled}>
                        <FontAwesomeIcon icon={isBrandingDisabled ? faLock : faPalette} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Thèmes")}</span>}
                    </Menu.Item>}
                <Menu.Item name='customFields' title={areCustomFieldsDisabled && title} style={itemStyle} active={activeItem === 'customFields'} onClick={this.handleClick} disabled={areCustomFieldsDisabled}>
                    <FontAwesomeIcon icon={areCustomFieldsDisabled ? faLock : faPenField} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Champs personnalisés")}</span>}
                </Menu.Item>
                <Menu.Item name='priceLists' title={isCostEstimationDisabled && title} style={itemStyle} active={activeItem === 'priceLists'} onClick={this.handleClick} disabled={isCostEstimationDisabled}>
                    <FontAwesomeIcon icon={isCostEstimationDisabled ? faLock : faSackDollar} style={iconStyle} />{!isMobile && <span style={labelStyle}>{i18n.t("Prix personnalisés")}</span>}
                </Menu.Item>
            </Menu>
        );
    }

    handleClick = (_, { name }) => this.props.setActiveItem(name);
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization
    };
}

export default connect(mapStateToProps)(OrganizationSidebar);