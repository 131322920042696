// Librairies
import Geocode from 'react-geocode';

export default class LocationsService {
    static getPlace(lat, lng) { // Permet de récupérer un nom de commune/ville en fonction des coordonnées fournies
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
        Geocode.setLanguage('fr');
        return Geocode.fromLatLng(lat, lng).then(response => {
            let street, code, city;
            if (response.results[0].address_components[1]) street = response.results[0].address_components[1].long_name;
            if (response.results[0].address_components[6]) code = response.results[0].address_components[6].long_name;
            if (response.results[0].address_components[2]) city = response.results[0].address_components[2].long_name;

            if (code && city) return street + ', ' + code + ' ' + city;
            else return street;
        }, () => {
            return;
        });
    }

    static getCountry(lat, lng) {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
        Geocode.setLanguage('en');
        return Geocode.fromLatLng(lat, lng).then(response => {
            return response.results.find(r => r.types.includes('country'))?.address_components[0].long_name || null;
        }, () => {
            return null;
        });
    }
}