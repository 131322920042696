import React, { Component } from 'react';
// Composants
import { DateInput, DatesRangeInput } from 'semantic-ui-calendar-react';
// Librairies
import { endOfDay, format, parse, startOfDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import 'moment/locale/fr';
import 'moment/locale/nl';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
// Utils
import LanguagesUtil from '../../utils/LanguagesUtil';

class DatePicker extends Component {
    state = {
        date: ''
    }

    render() {
        const { id, label, type, name, minDate, maxDate, initialDate, pointing, disabled, style, inputStyle } = this.props;
        const { date } = this.state;
        let popupPosition = pointing || 'top left';
        if (isMobileOnly) {
            popupPosition = popupPosition.replace('left', 'center');
            popupPosition = popupPosition.replace('right', 'center');
        }

        const culture = LanguagesUtil.getUserCulture(this.props.userInfos);
        const localization = culture.startsWith('fr') ? 'fr' : culture.startsWith('nl') ? 'nl' : 'en';
        const placeholder = culture.startsWith('fr') ? 'jj/MM/aaaa' : 'dd/MM/yyyy'

        return (
            <>
                {label && <label style={{ ...style, fontSize: '.92857143em' }}>{label}</label>}
                {!type ?
                    <DateInput
                        name={name} placeholder={placeholder} value={date} disabled={disabled} id={id}
                        minDate={minDate ? startOfDay(minDate) : minDate} initialDate={minDate || maxDate || initialDate || new Date()} maxDate={maxDate ? endOfDay(maxDate) : maxDate}
                        dateFormat='DD/MM/yyyy' marked={new Date()} iconPosition='left' localization={localization} closable popupPosition={popupPosition} hideMobileKeyboard closeOnMouseLeave={false}
                        autoComplete='off' enable={disabled ? [date] : []} onChange={this.handleDateChange} style={inputStyle}
                    />
                    : <DatesRangeInput
                        className='suicr-input' name={name} value={date} disabled={disabled}
                        minDate={minDate ? startOfDay(minDate) : minDate} maxDate={maxDate ? endOfDay(maxDate) : maxDate}
                        dateFormat='DD/MM/yyyy' marked={new Date()} iconPosition='left' localization={localization} closable popupPosition={popupPosition} pickerWidth='300px'
                        hideMobileKeyboard closeOnMouseLeave={false} allowSameEndDate onChange={this.handleDatesChange} style={inputStyle}
                    />}
            </>
        );
    }

    componentDidMount = () => {
        const { value } = this.props;
        if (value && value instanceof Date) this.setState({ date: format(value, 'dd/MM/yyyy') });
    }

    componentDidUpdate = (prevProps) => {
        if ((Array.isArray(this.props.value) && JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value))
            || (!Array.isArray(this.props.value) && JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value))) {
            const { value } = this.props;
            let date = '';
            if (Array.isArray(value)) {
                if (value.length) {
                    date = format(value[0], 'dd/MM/yyyy') + ' - ';
                    if (value.length > 1) date += format(value[1], 'dd/MM/yyyy');
                }
            } else if (value && value instanceof Date && !isNaN(value.getTime())) date = format(value, 'dd/MM/yyyy');

            this.setState({ date });
        }
    }

    handleDateChange = (_, { name, value }) => {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!this.props.disabled && value.match(regex)) {
            value = parse(value, 'dd/MM/yyyy', new Date(), { locale: fr });
            if (!this.props.forceTime) {
                const now = new Date();
                if (value) value.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
            } else if (value) value.setHours(23, 59, 59, 999);
            this.setState({ date: value }, () => this.props.onChange(_, { name, value }));
        } else if (!this.props.disabled) {
            value = null;
            this.setState({ date: value }, () => this.props.onChange(_, { name, value }));
        }
    }

    handleDatesChange = (_, { name, value }) => {
        this.setState({ date: value }, () => {
            if (value) {
                let dates = value.split(' - ').map(date => parse(date, 'dd/MM/yyyy', new Date(), { locale: fr })).filter(date => !isNaN(date.getTime()));
                if (!this.props.forceTime) {
                    const now = new Date();
                    if (dates[0]) dates[0].setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                    if (dates[1]) dates[1].setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                } else {
                    if (dates[0]) dates[0].setHours(0, 0, 0, 0);
                    if (dates[1]) dates[1].setHours(23, 59, 59, 999);
                }
                this.props.onChange(_, { name, value: dates });
            } else this.props.onChange(_, { name, value: [] });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        userInfos: state.userInfos
    };
};

export default connect(mapStateToProps)(DatePicker);