import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import PasswordInput from './PasswordInput';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import Cookies from 'universal-cookie';
import i18n from '../../../locales/i18n';
import jwt_decode from 'jwt-decode';
// Services
import ProjectsService from '../../../services/ProjectsService';
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialForm = {
    firstName: '',
    lastName: '',
    email: '',
    password1: '',
    password2: '',
    error: {
        hidden: true,
        messages: [],
        firstName: false,
        lastName: false,
        email: false,
        password: false
    }
};

class SignupForm extends Component {
    state = {
        ...initialForm,
        created: false,
        isLoading: false
    };

    render() {
        const { invitationType, invitationExpired } = this.props;
        const { firstName, lastName, email, password1, password2, isLoading, error, created } = this.state;
        return (
            <Form size='large' style={{ borderRadius: '5px', flex: 1 }} loading={isLoading} error success warning>
                <Grid>
                    <Grid.Row>
                        <Grid.Column stretched computer={8} tablet={8} mobile={16} style={!isMobileOnly ? { paddingRight: '2px' } : null}>
                            <Form.Input
                                icon='user' iconPosition='left' placeholder={i18n.t("Nom")}
                                name='lastName' value={lastName} onChange={this.handleChange} error={error.lastName}
                            />
                        </Grid.Column>
                        <Grid.Column stretched computer={8} tablet={8} mobile={16} style={!isMobileOnly ? { paddingLeft: '2px' } : null}>
                            <Form.Input
                                icon='user' iconPosition='left' placeholder={i18n.t("Prénom")}
                                name='firstName' value={firstName} onChange={this.handleChange} error={error.firstName}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Form.Input
                    fluid icon='mail' iconPosition='left' placeholder={i18n.t("Adresse e-mail")} style={{ margin: '10px 0' }}
                    name='email' value={email} onChange={this.handleChange} error={error.email}
                />
                <PasswordInput name='password1' value={password1} handleChange={this.handleChange} error={error.password} handleSubmit={this.handleSubmit} />
                <PasswordInput
                    name='password2' value={password2} placeholder={i18n.t("Confirmation")} handleChange={this.handleChange} error={error.password}
                    handleSubmit={this.handleSubmit} style={{ margin: '10px 0' }}
                />
                <Button color='green' fluid size='large' onClick={this.handleSubmit}>
                    {i18n.t("S'inscrire")}
                </Button>
                <Message
                    error style={{ textAlign: 'left' }} hidden={error.hidden}
                    header={i18n.t("Erreur")} list={error.messages}
                />
                <Message
                    success style={{ textAlign: 'left' }} hidden={!created}
                    header={i18n.t("Inscription réussie")}
                    content={i18n.t("Vous allez recevoir un e-mail de confirmation dans quelques secondes. Merci de vérifier votre boîte mail ainsi que la section 'Spams'.")}
                />
                <Message
                    info style={{ textAlign: 'left' }}
                    hidden={!invitationType || invitationExpired || created || !error.hidden}
                    header={i18n.t("Invitation")}
                    content={invitationType === 'project'
                        ? i18n.t("En vous inscrivant, vous serez automatiquement ajouté au projet dans lequel vous avez été invité !")
                        : i18n.t("En vous inscrivant, vous serez automatiquement ajouté à l'organisation à laquelle vous avez été invité !")}
                />
                <Message
                    warning style={{ textAlign: 'left' }}
                    hidden={!invitationExpired || created || !error.hidden}
                    header={i18n.t("Invitation")}
                    content={i18n.t("L'invitation a expiré !")}
                />
            </Form>
        );
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.invitationEmail && this.props.invitationEmail && !this.state.email)
            this.setState({ email: this.props.invitationEmail });
    }

    handleChange = async (_, { name, value }) => {
        let globalName = ['password1', 'password2'].includes(name) ? 'password' : name;
        this.setState(prevState => ({
            [name]: value,
            error: {
                ...prevState.error,
                [globalName]: false
            }
        }));
    };

    checkFields = async () => {
        const { firstName, lastName, email, password1, password2 } = this.state;
        let flag = true;
        let messages = [];

        let errors = {
            firstName: false,
            lastName: false,
            email: false,
            password: false
        }

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            flag = false;
        }

        if (!FormattersUtil.checkName(firstName))
            addError('firstName', i18n.t("Le prénom est invalide"));

        if (!FormattersUtil.checkName(lastName))
            addError('lastName', i18n.t("Le nom est invalide"));

        if (!FormattersUtil.checkEmail(email))
            addError('email', i18n.t("L'adresse e-mail est invalide"));
        else {
            const { isUsed, message } = await UsersService.isEmailInUse(email);
            if (isUsed) addError('email', message);
        }

        if (password1 !== password2)
            addError('password', i18n.t("Les mots de passe ne correspondent pas"));
        else if (password1.length < 8)
            addError('password', i18n.t("Le mot de passe doit contenir au moins 8 caractères"));

        return {
            flag: flag,
            errors: errors,
            messages: messages
        };
    }

    handleSubmit = async (_) => {
        const { flag, errors, messages } = await this.checkFields();

        if (flag) { // Si toutes les vérifications sont bonnes
            const invitation = new Cookies().get('invitation');
            const register = {
                firstName: this.state.firstName.trim(),
                lastName: this.state.lastName.trim(),
                email: this.state.email.trim(),
                password: this.state.password1.trim(),
                invitationId: invitation?.id ?? null
            };

            this.setState({ isLoading: true });
            UsersService.register(register).then(response => { // On tente de créer l'utilisateur
                this.setState({ isLoading: false });
                if (response.status === 200) {
                    const token = response.data?.token;
                    this.setState({ ...initialForm, created: true });
                    if (invitation) { // Si un cookie d'invitation est défini
                        const { id, type } = invitation;
                        new Cookies().remove('invitation', { path: '/' }); // Et on supprime le cookie
                        const userId = token && jwt_decode(token)?.id;
                        if (userId) {
                            if (type === 'project') ProjectsService.addCollaboratorWithInvitation(id, userId); // On ajoute l'utilisateur au projet
                            this.props.logUser(token);
                            this.props.changeActiveItem('home');
                            this.props.history.push('/');
                        }
                    }
                }
                else { // Sinon on affiche la liste des messages d'erreurs
                    this.setState({
                        errors: {
                            ...errors,
                            hidden: false,
                            messages: [i18n.t("Une erreur est survenue")]
                        },
                        created: false
                    });
                }
            });
        }
        else this.setState({ // Si les vérifications ne sont pas bonnes, on affiche les messages d'erreurs correspondants
            error: {
                ...errors,
                hidden: false,
                messages: messages
            },
            created: false
        });
    };
}

export default SignupForm;