import React, { Component } from 'react';

export default class DropDownEditor extends Component {
    render() {
        const { row, column, onRowChange, options, emptyValue } = this.props;
        const selectValue = options.find(option => option.label === row[column.key])?.value;

        return (
            <select
                className='rdg-text-editor' style={{ textAlignLast: 'center' }}
                value={selectValue || ''} autoFocus
                onKeyDown={(e) => this.handleKeyDown(e, row, column, onRowChange, options)}
                onChange={e => { this.handleDropDownChange(e.target.value, row, column, onRowChange, options) }}
            >
                {emptyValue && (<option value=''></option>)}
                {options.map(option => (<option key={option.label} value={option.value}>{option.label}</option>))}
            </select>
        );
    }

    handleDropDownChange = (targetValue, row, column, onRowChange, options) => {
        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.id);
        if (index === -1) {
            let element = JSON.parse(JSON.stringify(this.props.elements.find(element => element.id === row.id)));
            element[column.key] = targetValue;
            elementsToModify.push(element);
        } else elementsToModify[index][column.key] = targetValue;

        // Obligé de sauvegarder dans l'historique ici car le dropdown unfocus dès la sélection d'une valeur
        this.props.pushToModificationsHistory([{ property: column.key, elementId: row.id, oldValue: row[column.key] }]);
        this.props.changeElementsToModify(elementsToModify);
        const newValue = options.find(option => option.value === targetValue);
        const newRow = { ...row, [column.key]: newValue.label || '' };
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow, true);
    }

    handleKeyDown = (e, row, column, onRowChange, options) => {
        if (e.ctrlKey && e.key === 'v') {
            navigator.clipboard.readText().then(clipboardValue => {
                const value = options.find(option => option.label === clipboardValue)?.value;
                if (value || (this.props.emptyValue && value === ''))
                    this.handleDropDownChange(value, row, column, onRowChange, options);
            });
        } else if ([37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
            this.props.updateSelectedRow(row);
            onRowChange(row, true);
        } else if (e.keyCode === 8 && this.props.emptyValue)
            this.handleDropDownChange('', row, column, onRowChange, options);
    }
}