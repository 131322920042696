import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';

class SelectElementTypeForm extends Component {
    render() {
        return (
            <Modal open size='small'>
                <Modal.Header>Choix du type d'élément</Modal.Header>
                <Modal.Actions style={{ float: 'left' }}>
                    <Button color='blue' onClick={() => this.props.performAdminTask('Arbres')}>{i18n.t("Arbres")}</Button>
                    <Button color='blue' onClick={() => this.props.performAdminTask('Espaces verts')}>{i18n.t("Espaces verts")}</Button>
                    <Button color='blue' onClick={() => this.props.performAdminTask('Mobiliers')}>{i18n.t("Mobiliers")}</Button>
                    <Button color='red' onClick={this.props.hideForm}>{i18n.t("Annuler")}</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default SelectElementTypeForm;