import React, { Component } from 'react';
// Composants
import { Button, Dropdown } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { setRights, setViewProjectAsData, setUserProjects } from '../../actionCreators/projectsActions';
import RightsUtil from '../../utils/RightsUtil';

class ViewProjectAsBand extends Component {
    render() {
        const { viewProjectAsData } = this.props;
        const projectRoleName = viewProjectAsData?.rightsToApply?.label;

        return (
            <>
                {viewProjectAsData &&
                    <div id='view-project-as-band' style={{ position: 'relative', width: '100%', display: 'flex', backgroundColor: 'var(--blue-80)', alignItems: 'center', padding: '5px 0', zIndex: '1100' }}>
                        <Button color='blue' size='tiny' onClick={() => this.exitViewProjectAs(true)} style={{ padding: '6px 8px', marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
                            {i18n.t("Revenir à la gestion des rôles")}
                        </Button>
                        <h4 style={{ position: 'absolute', left: '50%', margin: 0, transform: 'translate(-50%, 0)' }}>
                            {i18n.t("Vous visualisez ce projet en tant que")}
                            <Dropdown text={projectRoleName} style={{ marginLeft: '5px' }}>
                                <Dropdown.Menu>
                                    {this.renderProjectRoles()}
                                </Dropdown.Menu>
                            </Dropdown>
                        </h4>
                        <Button color='blue' size='tiny' onClick={() => this.exitViewProjectAs()} style={{ padding: '6px 8px', marginLeft: 'auto', marginRight: '5px' }}>
                            {i18n.t("Désactiver")}
                        </Button>
                    </div>}
            </>
        );
    }

    renderProjectRoles = () => {
        const { project, rights, viewProjectAsData } = this.props;
        if (!project?.projectRoles?.length) return [];

        let projectRolesToRender = project.projectRoles.filter(pr => pr.type !== 'owner');
        const defaultRole = projectRolesToRender.splice(projectRolesToRender.findIndex(pr => pr.type === 'default'), 1)[0];
        if (defaultRole) projectRolesToRender.push(defaultRole);

        return projectRolesToRender
            .map(pr => (
                <Dropdown.Item key={pr.id} disabled={pr.id === rights.id} onClick={() => this.props.setRights(RightsUtil.getRights(pr)).then(() => this.props.setViewProjectAsData({ ...viewProjectAsData, rightsToApply: pr }).then(() => this.props.viewProjectAsData.apply()))}>
                    <FontAwesomeIcon icon={pr.icon} style={{ color: pr.color, marginRight: '10px' }} />
                    {pr.type ? i18n.t(pr.label) : pr.label}
                </Dropdown.Item>
            ));
    }

    exitViewProjectAs = (backToForm = false) => {
        const { viewProjectAsData, project } = this.props;
        const { layerContainers, map } = viewProjectAsData;
        this.props.setRights(viewProjectAsData.rights).then(() => {
            this.props.setViewProjectAsData(null).then(() => {
                this.props.setUserProjects([...project.userBaseProjects]).then(() => {
                    if (backToForm) viewProjectAsData.back();

                    const isGeographicallyLimited = viewProjectAsData.rights.projectRoleStations?.length > 0 || viewProjectAsData.rights.areas?.length > 0;
                    viewProjectAsData.setMapSurroundings({ stations: viewProjectAsData.stations, isGeographicallyLimited });
                    Object.keys(layerContainers).forEach(key => {
                        layerContainers[key].clearLayers();
                        const layers = viewProjectAsData[key];
                        if (layers) layers.forEach(layer => layerContainers[key].addLayer(layer));
                        map.addLayer(layerContainers[key]);
                    });
                });
            });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        rights: state.rights,
        viewProjectAsData: state.viewProjectAsData
    };
};

const mapDispatchToProps = {
    setRights,
    setViewProjectAsData,
    setUserProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProjectAsBand);