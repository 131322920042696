import React, { Component } from 'react';

export default class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        startValue: null,
        endValue: null
    };

    render() {
        const { row, column, onRowChange } = this.props;

        return (
            <input
                className='rdg-text-editor' style={{ textAlign: 'center' }}
                ref={this.autoFocusAndSelect} value={row[column.key] || ''}
                onChange={e => this.handleTextChange(e, row, column, onRowChange)} onBlur={this.handleBlur}
            />
        );
    }

    componentDidMount = () => this.setState({ startValue: this.props.row[this.props.column.key] });

    componentDidUpdate = () => { // Permet de mettre à jour la startValue lorsqu'on modifie le champs lié
        if (this.inputRef.current && document.activeElement !== this.inputRef.current && this.state.startValue !== this.props.row[this.props.column.key])
            this.setState({ startValue: this.props.row[this.props.column.key] });
    }

    componentWillUnmount = () => { if (!this.blurred) this.handleBlur(); } // L'event blur n'est pas déclenché lorsqu'on appuie sur enter/tab

    handleBlur = () => {
        const { startValue, endValue } = this.state;
        const { row, column, onClose, pushToModificationsHistory } = this.props;
        if (startValue !== endValue && (endValue || endValue === '')) {
            pushToModificationsHistory([
                { property: column.key, elementId: row.id, oldValue: startValue }
            ]);
            this.setState({ startValue: endValue });
        }
        onClose(true);
        this.blurred = true;
        setTimeout(() => this.blurred = false, 100);
    }

    handleTextChange = (e, row, column, onRowChange) => {
        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.id);
        if (index === -1) {
            let element = JSON.parse(JSON.stringify(this.props.elements.find(element => element.id === row.id)));
            element[column.key] = e.target.value || null;
            elementsToModify.push(element);
        } else elementsToModify[index][column.key] = e.target.value || null;
        this.setState({ endValue: e.target.value });
        this.props.changeElementsToModify(elementsToModify);
        const newRow = { ...row, [column.key]: e.target.value };
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow);
    }

    autoFocusAndSelect = (input) => {
        if (input) {
            input.focus();
            input.select();
            this.inputRef.current = input;
        }
    }
}