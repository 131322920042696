import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*     Filters     */
import DropDownFilter from '../../Tables/Filters/DropDownFilter';
import TextFilter from '../../Tables/Filters/TextFilter';
// Librairies
import DataGrid, { Row as GridRow, SortableHeaderCell } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Ressources
import { faBezierCurve, faFolder, faFolderOpen, faPlus } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Form, Button, Menu, Input, Segment } from 'semantic-ui-react';
// Services
import FormulasService from '../../../services/FormulasService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialFilters = {
    category: '',
    label: '',
    value: '',
    trunksTarget: '',
    trunksOperation: ''
};

const trunksTargets = [
    { label: i18n.t("Tous"), value: 'all' },
    { label: i18n.t("Plus petit"), value: 'smallest' },
    { label: i18n.t("Plus grand"), value: 'tallest' },
    { label: i18n.t("Plus fin"), value: 'thinnest' },
    { label: i18n.t("Plus gros"), value: 'largest' }
];

const trunksOperations = [
    { label: i18n.t("Somme"), value: 'sum' },
    { label: i18n.t("Moyenne"), value: 'average' },
    { label: i18n.t("Multiplication"), value: 'multiplication' },
    { label: i18n.t("Min"), value: 'min' },
    { label: i18n.t("Max"), value: 'max' }
];

class EssenceTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        addChildren: true,
        filters: initialFilters,
        isLoading: true,
        categories: ['Arbre', 'Espace vert']
    }

    render() {
        const { data, sortColumn, sortDirection, enableFilterRow, addChildren, filters, rowIndex, isLoading } = this.state;
        const rows = this.getFilteredRows();

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des formules")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={addChildren ? i18n.t("Exclure les versions des filtres") : i18n.t("Inclure les versions aux filtres")}
                                        className={addChildren && 'button--secondary'} color={!addChildren && 'grey'} style={{ padding: '11px' }}
                                        onClick={this.toggleAddChildren}
                                    >
                                        <FontAwesomeIcon icon={faBezierCurve} style={{ height: '12px' }} />
                                    </Button>
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button title={i18n.t("Masquer toutes les versions")} className='button--secondary' style={{ padding: '11px' }} onClick={this.collapseAll}>
                                        <FontAwesomeIcon icon={faFolder} style={{ height: '12px' }} />
                                    </Button>
                                    <Button title={i18n.t("Afficher toutes les versions")} className='button--secondary' style={{ padding: '11px' }} onClick={this.expandAll}>
                                        <FontAwesomeIcon icon={faFolderOpen} style={{ height: '12px' }} />
                                    </Button>
                                </Button.Group>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.createFromRow}>{i18n.t("Ajouter une version")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        const getExpandFormatter = (value, expanded, onCellExpand) => {
            return (
                <div className='disabled'>
                    <div className='rdg-cell-value'>
                        <div>{value}</div>
                    </div>
                    <div className='expand-formatter'>
                        <span onClick={e => { e.stopPropagation(); onCellExpand() }}>
                            <span tabIndex={-1}>
                                {expanded ? '\u25BC' : '\u25B6'}
                            </span>
                        </span>
                    </div>
                </div>
            )
        };

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Catégorie"), key: 'category', width: 180,
                sortable: true, editable: false,
                formatter: (props) => !props.row.parentId
                    ? <div className='disabled'>{i18n.t(props.row.category)}</div>
                    : <div className='disabled overriding'>{i18n.t(props.row.category)}</div>,
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={this.state.categories.map(category => ({ label: i18n.t(category), value: category }))} />
            },
            {
                name: i18n.t("Libellé"), key: 'label', width: 220,
                sortable: true, editable: false,
                formatter: (props) => !props.row.parentId ?
                    getExpandFormatter(
                        props.row.label,
                        props.row.isExpanded === true,
                        () => this.toggleSubRows([props.row.id])
                    )
                    : <div className='disabled'>{props.row.label}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Axes ciblés"), key: 'trunksTarget', width: 180,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{trunksTargets.find(target => target.value === props.row.trunksTarget)?.label}</div>,
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={trunksTargets} isNullable />
            },
            {
                name: i18n.t("Réduction des résultats"), key: 'trunksOperation', width: 200,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{trunksOperations.find(target => target.value === props.row.trunksOperation)?.label}</div>,
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={trunksOperations} isNullable />
            },
            {
                name: '', key: 'new', width: 40,
                sortable: true, resizable: false,
                formatter: (props) => (
                    <div className='disabled'>
                        <Button
                            color='green' style={{ padding: '6px 9px' }} content={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() => this.createFromRow(null, { rowIdx: props.rowIdx })}
                        />
                    </div>
                )
            },
            {
                name: i18n.t("Valeur"), key: 'value', width: 1750,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled' style={{ justifyContent: 'left' }}>{props.row.value}</div>,
                headerRenderer: ({ column, onSort, sortColumn, sortDirection }) => (
                    <div style={{ textAlign: 'left' }}>
                        <SortableHeaderCell column={column} onSort={onSort} sortColumn={sortColumn} sortDirection={sortDirection}>
                            {column.name}
                        </SortableHeaderCell>
                    </div>
                ),
                filterRenderer: (props) => <TextFilter p={props} />
            }
        ];

        // Ajout des données
        FormulasService.getFormulasWithValue().then(formulas => {
            data.rows = formulas
                .map(formula => {
                    return {
                        id: formula.id,
                        category: formula.category === 'trees' ? 'Arbre' : 'Espace vert',
                        label: formula.label,
                        value: '',
                        children: formula.formulaVersions.map(formulaVersion => ({
                            id: formulaVersion.id,
                            parentId: formula.id,
                            category: '',
                            label: formulaVersion.label,
                            value: formulaVersion.value,
                            trunksTarget: formulaVersion.trunksTarget,
                            trunksOperation: formulaVersion.trunksOperation
                        }))
                    };
                });

            const elements = JSON.parse(JSON.stringify(data.rows));

            const initialOrder = [];
            data.rows.forEach(row => {
                initialOrder.push(row.id);
                row.children.forEach(childRow => initialOrder.push(`${row.id}-${childRow.id}`));
            });
            this.setState({ data, elements, initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    toggleAddChildren = () => this.setState(prevState => ({ addChildren: !prevState.addChildren }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        const rowMatchesFilters = (row) => {
            return (filters.category ? $(row.category)?.includes($(filters.category)) : true)
                && (filters.label ? $(row.label)?.includes($(filters.label)) : true)
                && (filters.value ? $(row.value)?.includes($(filters.value)) : true)
                && (filters.trunksTarget ? (row.trunksTarget === filters.trunksTarget || (filters.trunksTarget === 'empty' && !row.trunksTarget)) : true)
                && (filters.trunksOperation ? (row.trunksOperation === filters.trunksOperation || (filters.trunksOperation === 'empty' && !row.trunksOperation)) : true);
        };
        const parentMatchesFilters = (parentId) => {
            const parentRow = rows.filter(row => row.children).find(row => row.id === parentId);
            return rowMatchesFilters(parentRow) || (this.state.addChildren && parentRow.children.some(child => rowMatchesFilters(child)));
        };

        return rows.filter(row => {
            const isParent = row.children ? true : false;
            // On affiche la ligne seulement si :
            return !this.state.enableFilterRow // Les fitres sont désactivés
                // Ou la ligne correspond à un parent & qu'elle respecte les filtres ou que, si l'inclusion est activée, l'un de ses enfants les respecte
                || (isParent && (rowMatchesFilters(row) || (this.state.addChildren && row.children.some(child => rowMatchesFilters(child)))))
                // Ou la ligne correspond à un enfant et la ligne parent est affichée et, si l'inclusion est activée, la ligne correspond aux filtres
                || (!isParent && parentMatchesFilters(row.parentId) && (rowMatchesFilters(row) || !this.state.addChildren))
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const { sortColumn, sortDirection, initialOrder, data } = this.state;

        const sort = (rows) => {
            let sortedRows = [...rows];
            sortedRows = sortedRows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));
            if (sortDirection === 'DESC') sortedRows.reverse()
            return sortedRows;
        };

        let rows;
        if (sortDirection === 'NONE') {
            rows = [...data.rows];
            let replaceIndex = 0;
            initialOrder.forEach(elementId => {
                let index = rows.findIndex(row => !row.parentId && row.id === elementId);
                if (index === -1) {
                    const ids = elementId.split('-').map(id => +id);
                    index = rows.findIndex(row => row.parentId === ids[0] && row.id === ids[1]);
                }
                if (index !== -1) {
                    let temp = rows[replaceIndex];
                    rows[replaceIndex] = rows[index];
                    rows[index] = temp;
                    replaceIndex++;
                }
            });
        } else {
            rows = sort([...data.rows.filter(row => !row.parentId)]);

            let childRows = {};
            data.rows.filter(row => row.parentId).forEach(row => {
                if (childRows[row.parentId]) childRows[row.parentId].push(row);
                else childRows[row.parentId] = [row];
            });
            for (const parentId in childRows) {
                let sortedChildren = sort(childRows[parentId]);
                let index = rows.findIndex(row => row.id === sortedChildren[0].parentId);
                if (index !== -1)
                    sortedChildren.forEach(row => {
                        rows.splice(index + 1, 0, row);
                        index++;
                    });
            }
        }

        this.setState(prevState => ({
            data: {
                columns: prevState.data.columns,
                rows: rows
            }
        }));
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    expandAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && !row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    collapseAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    toggleSubRows = (elementIds) => {
        let rows = [...this.state.data.rows];

        elementIds.forEach(elementId => {
            const rowIndex = rows.findIndex((r) => r.id === elementId);
            const row = rows[rowIndex];
            let { children } = row;

            if (children) {
                rows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
                if (!row.isExpanded) rows.splice(rowIndex + 1, 0, ...children);
                else rows.splice(rowIndex + 1, children.length);
            }
        });

        this.setState(prevState => ({ data: { ...prevState.data, rows: rows } }), this.sortRows);
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) { // Pas besoin de mapping pour les formules
            default: return value;
        }
    }

    createFromRow = (_, { rowIdx }) => {
        const row = this.getFilteredRows()[rowIdx];
        this.props.createFormulaFromTemplate({
            formulaId: row.parentId || row.id,
            label: row.parentId ? row.label : '',
            value: row.value,
            trunksTarget: row.trunksTarget,
            trunksOperation: row.trunksOperation,
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(EssenceTable);