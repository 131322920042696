import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Ressources
import { faPencilRuler, faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Input, Form, Select, Grid, Button } from 'semantic-ui-react';
// Utils
import TreesUtil from '../../../../utils/TreesUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step3 extends Component {
    state = {
        properties: {
            numberOfTrunks: 0,
            stumpDiameter: 0,
            trunks: [],
            dimensions: {
                trunkHeight: 0,
            },
        },
        error: {
            hidden: true,
            messages: [],
            height: false,
            circumference: false,
            crownDiameter: false
        }
    }

    render() {
        const { project } = this.props;
        const requiredFields = this.props.requiredFields.trees;
        const stumpDiameters = TreesUtil.getStumpDiameters().map(x => { return { text: x.label, value: x.id } });
        const trunks = (this.props.properties.trunks || [])
            .toSorted((a, b) => a.order - b.order)
            .slice(0, this.props.properties.numberOfTrunks);
        const lastTrunk = trunks?.length ? trunks[trunks.length - 1] : null;

        return (
            <Grid id='cat--dimensions' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--purple-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'Dimensions') }}>
                {!isMobileOnly &&
                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--purple-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                        <FontAwesomeIcon icon={faPencilRuler} style={{ alignSelf: 'center', marginRight: '5px' }} />
                        <span className='no-themed'>{i18n.t("Dimensions")}</span>
                    </Grid.Row>}
                {(requiredFields.numberOfTrunks || requiredFields.trunkHeight || requiredFields.trunks) &&
                    <Grid.Column width={16}>
                        {requiredFields.trunks && <label>{i18n.t('Axes')} <InfoIcon content={i18n.t("Axes qui composent l'arbre")} iconStyle={{ marginLeft: '3px' }} /></label>}
                        <Grid>
                            <Grid.Row columns={1}>
                                {requiredFields.numberOfTrunks &&
                                    <Grid.Column stretched>
                                        <Form.Field
                                            control={Input} type='number' step='1' label={i18n.t("Nombre d'axes") + ' : '} placeholder='Ex: 3'
                                            name='numberOfTrunks' disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                                            value={this.props.properties.isEmpty ? 0 : this.props.properties.numberOfTrunks || ''}
                                            onChange={this.props.handleChange} style={{ marginBottom: '5px' }}
                                        />
                                    </Grid.Column>}
                            </Grid.Row>
                        </Grid>
                        {requiredFields.isStump && requiredFields.trunks && this.props.properties.isStump &&
                            <Grid.Column>
                                <Form.Field
                                    control={Select} label={i18n.t("Diamètre de la souche") + ' : '} placeholder={i18n.t("Sélectionnez un diamètre")}
                                    name='stumpDiameterId' options={stumpDiameters} value={this.state.properties.stumpDiameter || ''}
                                    selectOnBlur={false} clearable onChange={(_, { name, value }) => {
                                        this.setState(prevState => ({ properties: { ...prevState.properties, stumpDiameter: value } }),
                                            () => this.props.handleDimensionsChange(_, { name, value }))
                                    }}
                                />
                            </Grid.Column>}
                        {requiredFields.trunks && (!this.props.properties.isStump || this.state.properties.stumpDiameter > 0) &&
                            <Grid.Column>
                                {this.props.properties.isEmpty ?
                                    <Button color='grey' disabled style={{ width: '100%' }}>
                                        <FontAwesomeIcon style={{ marginRight: '10px' }} />
                                        {i18n.t("Aucun axe")}
                                    </Button>
                                    : <Grid>
                                        {trunks.map((trunk, index) => (
                                            <Grid.Row key={trunk.id} columns={isMobileOnly ? 1 : 4} style={{ width: '100%', paddingBottom: 0 }}>
                                                <Grid.Column className='trunks-form' width={16} style={{ display: 'flex', gap: '10px' }}>
                                                    <div style={{ flex: 1 }}>
                                                        <Form.Field
                                                            control={Input} type='number' step='0.1' label={i18n.t("Hauteur totale axe") + ' (m) :'} placeholder='Ex: 10'
                                                            name='height' value={Math.round((trunk.height / 100) * 100) / 100 || ''}
                                                            onChange={(_, { name, value }) => this.props.handleTrunkChange(trunk.id, name, value)}
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <Form.Field
                                                            control={Input} type='number' step='0.1' placeholder='Ex: 120'
                                                            label={<label>{project.trunkCircumferenceUnit === 'circumference' ? i18n.t("Circonférence axe") : i18n.t("Diamètre axe")} (cm) <InfoIcon content={i18n.t("Mesure de la circonférence de l'axe à 150 cm (à 130cm pour la France)")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                                                            name='circumference' className='circumference' value={trunk.circumference || ''}
                                                            onChange={(_, { name, value }) => this.props.handleTrunkChange(trunk.id, name, value)}
                                                        />
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <Form.Field
                                                            control={Input} type='number' step='0.1' label={i18n.t("Diamètre couronne axe") + ' (m) :'} placeholder='Ex: 3'
                                                            name='crownDiameter' value={Math.round((trunk.crownDiameter / 100) * 100) / 100 || ''}
                                                            onChange={(_, { name, value }) => this.props.handleTrunkChange(trunk.id, name, value)}
                                                        />
                                                    </div>
                                                    {!this.props.properties.isStump &&
                                                        <div style={{ alignSelf: 'flex-end' }}>
                                                            <Button
                                                                color='red' onClick={() => this.props.handleDeleteTrunk(trunk.id)}
                                                                style={{ marginBottom: '2px', height: '38px', aspectRatio: '1 / 1', padding: 0 }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </Button>
                                                        </div>}
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}
                                        {!this.props.properties.isStump &&
                                            <Grid.Column width={16}>
                                                <Button
                                                    color='blue' onClick={this.props.handleAddTrunk} disabled={trunks.length && lastTrunk && !lastTrunk.height && !lastTrunk.circumference && !lastTrunk.crownDiameter}
                                                    style={{ width: '100%' }}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
                                                    {i18n.t("Ajouter un axe")}
                                                </Button>
                                            </Grid.Column>}
                                    </Grid>}
                            </Grid.Column>}
                        <Grid>
                            <Grid.Row columns={1}>
                                {requiredFields.trunkHeight &&
                                    <Grid.Column stretched>
                                        <Form.Field
                                            control={Input} type='number' step='0.1' label={<label>{i18n.t("Hauteur du tronc")} (m) <InfoIcon content={i18n.t("Ne pas confondre avec la hauteur de l'arbre. Ce champ correspond à la hauteur de la première branche")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Ex: 1'
                                            name='trunkHeight' disabled={this.props.properties.isEmpty || this.props.properties.isStump}
                                            value={!this.props.properties.isEmpty && !this.props.properties.isStump ? (Math.round((this.props.properties.dimensions.trunkHeight / 100) * 100) / 100 || '') : ''}
                                            error={this.props.error.trunkHeight} onChange={this.props.handleDimensionsChange}
                                        />
                                    </Grid.Column>}
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Dimensions'))}
            </Grid >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        defaultFieldCategories: state.defaultFieldCategories,
        project: state.project
    };
};

export default connect(mapStateToProps)(Step3);