import React, { Component } from 'react';
// Composants
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { Slider } from 'react-semantic-ui-range';
import { Form } from 'semantic-ui-react';
import InfoIcon from '../../Utils/InfoIcon';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';

class BackgroundImageManagementForm extends Component {
    state = {
        backgroundImages: [],
        initialOrder: []
    }

    render() {
        const { isToolbarExpanded } = this.props;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form error style={{ width: '100%' }}>
                    <div style={{ overflow: 'auto', flexGrow: 1 }}>
                        <label>{i18n.t("Gestion des calques")}<InfoIcon content={i18n.t("Glissez-déposez les calques pour les réordonner")} iconStyle={{ marginLeft: '3px' }} /> : </label>
                        {this.state.backgroundImages.length ?
                            <ReactSortable
                                className='background-images-order' list={this.state.backgroundImages}
                                setList={list => { this.setState({ backgroundImages: list }) }}
                                onEnd={this.handleOrderChanged}
                            >
                                {this.renderBackgroundImages()}
                            </ReactSortable>
                            : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', pointerEvents: 'none', marginTop: '15px' }}>
                                <FontAwesomeIcon icon={faLayerGroup} size='4x' style={{ marginTop: 'auto' }} />
                                <h4 style={{ marginTop: '15px' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                            </div>}
                    </div>
                </Form>
            </div>
        );
    }

    componentDidMount = () => {
        this.orderChanged = false;
        const backgroundImages = this.props.backgroundImagesLayer.getLayers().sort((a, b) => b.feature.properties.index - a.feature.properties.index);
        this.setState({ backgroundImages, initialOrder: backgroundImages.map(bi => bi.feature.id) });
    }

    componentDidUpdate = () => {
        if (this.orderChanged && this.state.backgroundImages.length !== this.props.backgroundImagesLayer.getLayers().length) {
            this.orderChanged = false;
            let backgroundImages = [];
            this.state.initialOrder.forEach(id => {
                const backgroundImage = this.state.backgroundImages.find(bi => bi.feature.id === id);
                if (backgroundImage) backgroundImages.push(backgroundImage);
            });
            this.setState({ backgroundImages });
        } else if (this.visibilityChanged && this.state.backgroundImages.length === this.props.backgroundImagesLayer.getLayers().length) {
            this.orderChanged = false;
            this.visibilityChanged = false;
            const backgroundImages = this.props.backgroundImagesLayer.getLayers().sort((a, b) => b.feature.properties.index - a.feature.properties.index);
            this.setState({ backgroundImages, initialOrder: backgroundImages.map(bi => bi.feature.id) });
        }
    }

    renderBackgroundImages = () => {
        return this.state.backgroundImages.map((bgImg, index) => {
            const isVisible = bgImg.feature.properties.isVisible;

            return (
                <div key={index}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ margin: 0, marginRight: '5px' }}>{index + 1}</p>
                        <div style={{ flex: 1 }}>
                            <Slider
                                color='blue' value={bgImg.feature.properties.opacity}
                                settings={{ min: 0, max: 100, step: 1, onChange: (value) => this.handleOpacityChange(bgImg, value) }}
                            />
                        </div>
                        <FontAwesomeIcon
                            icon={isVisible ? faEye : faEyeSlash} style={{ marginLeft: '5px', cursor: 'pointer' }}
                            title={isVisible ? i18n.t("Masquer le calque") : "Afficher le claque"} onClick={() => this.toggleVisibility(bgImg)}
                        />
                    </div>
                    <div className='background-image'>
                        <img src={bgImg.feature.properties.url} alt='calque' />
                    </div>
                </div>);
        });
    }

    handleOrderChanged = () => {
        let { backgroundImages } = this.state;
        this.props.pushActionHistory(backgroundImages.map(layer => ({ layer })));
        this.props.updateBackgroundImagesLayer(backgroundImages.map(bgImg => bgImg.feature), true, false);
        this.orderChanged = true;
    }

    toggleVisibility = (backgroundImage) => {
        this.visibilityChanged = true;
        let feature = backgroundImage.feature;
        const backgroundImages = [...this.state.backgroundImages];
        this.props.pushActionHistory(backgroundImages.map(layer => ({ layer })));
        const index = backgroundImages.findIndex(bi => bi.feature.id === feature.id);
        if (index !== -1) {
            const isVisible = !feature.properties.isVisible;
            feature.properties.isVisible = isVisible;
            feature.properties.opacity = isVisible && !feature.properties.opacity ? 100 : feature.properties.opacity;
            backgroundImages[index].feature = feature;
            this.props.changeBackgroundImageVisibility(feature);
            this.setState({ backgroundImages });

        }
    }

    handleOpacityChange = (backgroundImage, opacity) => {
        let feature = backgroundImage.feature;
        const backgroundImages = [...this.state.backgroundImages];
        if (!this.visibilityChanged) {
            this.visibilityChanged = true;
            this.props.pushActionHistory(backgroundImages.map(layer => ({ layer })));
        }
        setTimeout(() => {
            this.visibilityChanged = true;
            this.props.pushActionHistory(backgroundImages.map(layer => ({ layer })))
        }, 500);
        const index = backgroundImages.findIndex(bi => bi.feature.id === feature.id);
        if (index !== -1) {
            feature.properties.opacity = opacity;
            feature.properties.isVisible = opacity > 0;
            backgroundImages[index].feature = feature;
            this.props.changeBackgroundImageVisibility(feature);
            this.setState({ backgroundImages });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isToolbarExpanded: state.isToolbarExpanded,
        project: state.project,
        webSocketHubs: state.webSocketHubs
    };
}

export default connect(mapStateToProps)(BackgroundImageManagementForm);