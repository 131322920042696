import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Form, Grid, Label, Select } from 'semantic-ui-react';
// Services
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step4 extends Component {
    state = {
        properties: {
            spaceTypeCategoryId: 0,
            spaceTypeId: 0
        },
        error: {
            hidden: true,
            messages: [],
            spaceType: false
        }
    };

    render() {
        const requiredFields = this.props.requiredFields.greenSpaces;
        const { dominantCompositionId, spaceTypeCategoryId, spaceTypeId } = this.props.properties;

        const stcId = spaceTypeCategoryId ? spaceTypeCategoryId : this.props.spaceTypes.find(spaceType => spaceType.id === spaceTypeId)?.spaceTypeCategoryId;
        const dominantComposition = this.props.dominantCompositions.find(dominantComposition => dominantComposition.id === dominantCompositionId);
        const spaceTypeCategories = FormattersUtil.sortByAlphabeticalOrder(
            this.props.spaceTypeCategories
                .filter(stc => !dominantComposition || dominantComposition.dominantCompositionSpaceTypeCategories.find(dcstc => dcstc.spaceTypeCategoryId === stc.id))
                .map(x => { return { text: x.label, value: x.id } })
        );
        const spaceTypes = stcId
            ? FormattersUtil.sortByAlphabeticalOrder(
                this.props.spaceTypes
                    .filter(spaceType => spaceType.spaceTypeCategoryId === stcId)
                    .map(x => { return { text: <span>{x.shortLabel || x.label}{x.definition && <InfoIcon content={x.definition} iconStyle={{ float: 'right', marginLeft: '5px' }} />}</span>, rawText: x.shortLabel || x.label, value: x.id } })
            )
            : FormattersUtil.sortByAlphabeticalOrder(
                this.props.spaceTypes
                    .filter(spaceType => spaceTypeCategories.find(stc => stc.value === spaceType.spaceTypeCategoryId))
                    .map(x => { return { text: <span>{x.label}{x.definition && <InfoIcon content={x.definition} iconStyle={{ float: 'right', marginLeft: '5px' }} />}</span>, rawText: x.label, value: x.id } })
            );

        const ecopotentialIndex = this.props.spaceTypes.find(spaceType => spaceType.id === spaceTypeId)?.ecopotentialIndex;

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16
        };

        const iconStyle = { position: 'relative', marginLeft: '5px', marginBottom: '2px' };

        return (
            <Grid id='cat--biodiversity' style={{ margin: 0, order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Mobilier', 'Entretien') }}>
                <Grid.Row style={{ padding: '14px 14px 0 14px' }}>
                    {!isMobileOnly && <h3 style={{ marginBottom: 0 }}>{i18n.t("Biodiversité : Écopotentiel", { nsSeparator: '|' })}<InfoIcon content={i18n.t("Potentiel de développement/d'accueil de la biodiversité, ou intérêt écologique du site pour la biodiversité, selon le coefficient développé par Bruxelles Environnement")} iconStyle={iconStyle} /></h3>}
                    {spaceTypeId > 0 && <Label style={{ float: 'right', padding: '5px 8px', backgroundColor: 'var(--green-100)', color: 'white', fontSize: '9pt', marginLeft: '10px' }}>{ecopotentialIndex}</Label>}
                </Grid.Row>
                {requiredFields.spaceType && <>
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez une catégorie")} style={{ width: '100%' }}
                            label={i18n.t("Catégorie du type de surface") + ' : '}
                            name='spaceTypeCategoryId' options={spaceTypeCategories} value={stcId || ''}
                            selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleSpaceTypeCategoryChange}
                        />
                    </Grid.Column>
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un type")} style={{ width: '100%' }}
                            label={<label>{i18n.t("Type de surface")} <InfoIcon content={i18n.t("Classification selon indice écopotentiel")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='spaceTypeId' options={spaceTypes} value={spaceTypeId || ''}
                            selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            error={this.props.error.spaceType} onChange={this.props.handleSpaceTypeChange}
                        />
                    </Grid.Column>
                </>}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        spaceTypeCategories: state.spaceTypeCategories,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step4);