// Librairies
import L from 'leaflet';

export default class TooltipsUtil {
    static setMarkerTooltip(text, layer, referencesLayer, checkIfAlreadyExists = false) {
        if (text) {
            const labelIcon = L.divIcon({
                className: 'tooltip-reference',
                html: String(text),
                iconSize: [100, 10],
                iconAnchor: [50, 25],
            });

            let labelMarker = null;
            if (checkIfAlreadyExists) {
                const layers = referencesLayer.getLayers();
                labelMarker = layers.find(l => l.elementId === layer.feature.id && l.category === layer.feature.properties.category)
            }

            if (labelMarker) {
                labelMarker.setIcon(labelIcon);
                labelMarker.setLatLng(layer._latlng);
            } else {
                labelMarker = L.marker(layer._latlng, { icon: labelIcon, pmIgnore: true }).addTo(referencesLayer);
                labelMarker.category = layer.feature.properties.category;
                labelMarker.elementId = layer.feature.id;
            }
        }
    }

    static setGreenSpaceTooltip(text, layer, referencesLayer, checkIfAlreadyExists = false) {
        if (text) {
            const labelIcon = L.divIcon({
                className: 'tooltip-reference',
                html: String(text),
                iconSize: [100, 10],
                iconAnchor: [50, 25],
            });

            let labelMarker = null;
            if (checkIfAlreadyExists) {
                const layers = referencesLayer.getLayers();
                labelMarker = layers.find(l => l.elementId === layer.feature.id && l.category === layer.feature.properties.category)
            }

            if (labelMarker) {
                labelMarker.setIcon(labelIcon);
                labelMarker.setLatLng(layer.getCenter())
            } else {
                labelMarker = L.marker(layer.getCenter(), { icon: labelIcon, pmIgnore: true }).addTo(referencesLayer);
                labelMarker.category = layer.feature.properties.category;
                labelMarker.elementId = layer.feature.id;
            }
        }
    }
}


