export default class UrlsUtil {
    static getModalPath = (modalType, feature) => {
        const categories = { 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures', 'Repère': 'markers' };

        const modalPaths = {
            'FilterForm': 'filters',
            'TreeTable': 'tables/trees',
            'GreenSpaceTable': 'tables/greenSpaces',
            'FurnitureTable': 'tables/furnitures',
            'ProjectDetail': 'statistics/general',
            'GendersChart': 'statistics/genders',
            'OntogenicsChart': 'statistics/ontogenicStages',
            'VigorsChart': 'statistics/vigors',
            'CutDownsChart': 'statistics/cutDowns',
            'ActionsChart': 'statistics/actions',
            'HealthReviewsChart': 'statistics/healthReviews',
            'CustomCharts': 'statistics/customCharts',
            'ActionTable': 'actions',
            'ProjectHistory': 'history',
            'TreeDetail': `trees/${feature?.id}/detail`,
            'TreeForm': feature?.id && `trees/${feature?.id}/form`,
            'TreeHistory': `trees/${feature?.id}/history`,
            'GreenSpaceDetail': `greenSpaces/${feature?.id}/detail`,
            'GreenSpaceForm': feature?.id && `greenSpaces/${feature?.id}/form`,
            'GreenSpaceHistory': `greenSpaces/${feature?.id}/history`,
            'FurnitureForm': feature?.id && `furnitures/${feature?.id}/form`,
            'FurnitureDetail': `furnitures/${feature?.id}/detail`,
            'FurnitureHistory': `furnitures/${feature?.id}/history`,
            'MarkerForm': feature?.id && `markers/${feature?.id}/form`,
            'MarkerDetail': `markers/${feature?.id}/detail`,
            'PhotosGallery': `${categories[feature?.properties?.category]}/${feature?.id}/photos`,
            'ElementFilesGallery': `${categories[feature?.properties?.category]}/${feature?.id}/files`,
            'ActionForm': `${categories[feature?.properties?.category]}/${feature?.id}/actions`,
            'LinkedElementList': `${categories[feature?.properties?.category]}/${feature?.id}/linkedElements`
        };

        return modalPaths[modalType];
    }

    static adjustSearch(search, properties) {
        let newSearch;
        if (!search?.long) {
            if (!Object.keys(properties).some(property => properties[property] || typeof properties[property] === 'boolean'))
                return;
            else newSearch = '?';
        } else newSearch = search.long;

        Object.keys(properties).forEach(property => {
            const startIndex = newSearch.indexOf(`${property}=`);
            const hasValue = (properties[property] || [false, 0].includes(properties[property])) && !`${properties[property]}`.includes('&');

            if (startIndex === -1) {
                if (hasValue) newSearch += `${newSearch !== '?' ? '&' : ''}${property}=${properties[property]}`;
            } else {
                const endIndex = newSearch.indexOf('&', startIndex);
                if (hasValue) newSearch = `${newSearch.slice(0, startIndex)}${property}=${properties[property]}${endIndex !== -1 ? newSearch.slice(endIndex) : ''}`;
                else newSearch = `${newSearch.slice(0, startIndex !== 1 ? startIndex - 1 : startIndex)}${endIndex !== -1 ? newSearch.slice(startIndex !== 1 ? endIndex : endIndex + 1) : ''}`;
            }
        });

        if (!newSearch || newSearch === '?') return null;
        else return {
            short: search?.short?.includes('viewId') ? search.short : `?${newSearch.slice(1).split('&').filter(property => ['x', 'y', 'zoom'].includes(property?.split('=')[0])).join('&')}`,
            long: `?${newSearch.slice(1).split('&').sort((a, b) => a.length - b.length).join('&')}`
        };
    }

    static getSearchParams(search) {
        const searchParams = {};
        if (!search || search === '?') return searchParams;

        search.slice(1).split('&').forEach(param => {
            const separatorIndex = param.indexOf('=');
            const property = param.slice(0, separatorIndex);
            let propertyValue = decodeURI(param.slice(separatorIndex + 1));
            if (!isNaN(propertyValue)) propertyValue = Number(propertyValue);
            if (propertyValue === 'true') propertyValue = true;
            if (propertyValue === 'false') propertyValue = false;
            searchParams[property] = propertyValue;
        });

        return searchParams;
    }

    static getOverlaysParam(overlays) {
        let overlaysParam = [];
        overlays.filter(overlay => overlay.isShown).forEach(overlay => {
            if (overlay.activeChild) {
                const activeChild = overlay.children?.find(childOverlay => [childOverlay.id, childOverlay.label].includes(overlay.activeChild));
                if (overlay.activeOptions?.length) {
                    const activeOptionsLabels = overlay.activeOptions.map(option => overlay.children?.find(childOverlay => childOverlay.label === option)?.originalLabel);
                    overlaysParam.push(`${overlay.originalLabel}:${activeChild?.id || activeChild?.originalLabel}:${activeOptionsLabels.join(':')}`);
                }
                else overlaysParam.push(`${overlay.originalLabel}:${activeChild?.id || activeChild?.originalLabel}`);
            } else overlaysParam.push(overlay.id || overlay.originalLabel);
        });

        return overlaysParam.length ? overlaysParam.join(',') : null;
    }
}
