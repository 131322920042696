import React, { Component } from 'react';
// Componsants
import RequiredFieldsForm from '../RequiredFieldsForm';
import { Link } from 'react-router-dom';
// Librairies
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
// Services
import ProjectsService from '../../../../services/ProjectsService';
// Utils
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import { setProject, setProjects } from '../../../../actionCreators/projectsActions';

class ProjectElementsSettingsForm extends Component {
    render() {
        const { project, subscription, projectListState, activeOrganization } = this.props;
        const currentFolder = projectListState?.currentFolder;
        const requiredFields = ProjectsUtil.getProjectRequiredFields(project, project.id, currentFolder?.organization || activeOrganization);
        const amenityFormulaType = project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;

        return (
            <>
                <h3 style={{ margin: isMobile ? '0 0 20px 0' : 0 }}>{i18n.t("Paramètres des champs")}</h3>
                {!isMobile && <small>
                    <p style={{ margin: 0 }}>{i18n.t("La création de champs se fait depuis la page « Mon compte ».")}</p>
                    <Link to='/accountPanel/customFields'>{i18n.t("Cliquez ici pour accéder la gestion de vos champs personnalisés.")}</Link>
                </small>}
                <div style={{ marginTop: !isMobile && '15px', flex: 1, overflowY: 'hidden', display: 'flex' }}>
                    <RequiredFieldsForm
                        requiredFields={requiredFields} amenityFormulaType={amenityFormulaType} project={project}
                        subscription={subscription} handleChange={this.props.handleChange} handleFieldCategory={this.props.handleFieldCategory}
                    />
                </div>
            </>
        );
    }

    componentDidMount = () => {
        if (this.props.isOnline)
            ProjectsUtil.loadMissingEntities(this.props.project, this.props.project, this.props.projects, this.props.formulas, this.props.setProject, this.props.setProjects, ['fieldCategories', 'projectCustomFields']).then(response => {
                if (response) this.props.updateSubEntitiesInProject(response);
            });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        projects: state.projects,
        formulas: state.formulas,
        projectCollaborators: state.projectCollaborators,
        activeOrganization: state.activeOrganization
    };
};

const mapDispatchToProps = {
    setProjects,
    setProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectElementsSettingsForm);