import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { showToast } from '../../../utils/ToastsUtil';
import i18n from '../../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

class MergeForm extends Component {
    state = {
        properties: {}
    };

    render() {
        const { differentProperties, currentAction } = this.props;
        const { properties } = this.state;
        const propertiesKeys = Object.keys(differentProperties || {});

        return (
            <div className='modal-content'>
                <div className='modal-content-header'>
                    <h3 style={{ textAlign: 'center', paddingBottom: '15px' }}>
                        {propertiesKeys.length > 1 ? i18n.t("Quelles propriétés souhaitez-vous conserver ?")
                            : i18n.t("Quelle propriété souhaitez-vous conserver ?")}
                    </h3>
                </div>
                <div className='modal-content-body'>
                    {differentProperties &&
                        <Grid>
                            {propertiesKeys.filter(property => property !== 'customFields').map(property => {
                                const firstValue = differentProperties[property][0];
                                const firstDisplayedValue = this.getPropertyValue(property, firstValue);
                                const secondValue = differentProperties[property][1];
                                const secondDisplayedValue = this.getPropertyValue(property, secondValue);
                                return (
                                    <Grid.Row verticalAlign='middle' key={property}>
                                        <Grid.Column computer={4} tablet={4} mobile={16} textAlign={isMobileOnly ? 'center' : 'right'}>
                                            <label style={{ fontWeight: 'bold', fontSize: isMobileOnly ? '13pt' : '16pt' }}>{this.getPropertyName(property)} :</label>
                                        </Grid.Column>
                                        <Grid.Column computer={8} tablet={8} mobile={16}>
                                            <Button.Group fluid>
                                                <Button
                                                    color={properties[property] === firstValue ? 'teal' : 'grey'} style={{ width: '50%' }}
                                                    active={properties[property] === firstValue}
                                                    content={firstDisplayedValue || firstDisplayedValue === 0 ? firstDisplayedValue : i18n.t("Vide")}
                                                    onClick={() => this.setState(prevState => ({ properties: { ...prevState.properties, [property]: firstValue } }))}
                                                />
                                                <Button.Or text={i18n.t("ou")} style={{ margin: 'auto 0' }} />
                                                <Button
                                                    color={properties[property] === secondValue ? 'teal' : 'grey'} style={{ width: '50%' }}
                                                    active={properties[property] === secondValue}
                                                    content={secondDisplayedValue || secondDisplayedValue === 0 ? secondDisplayedValue : i18n.t("Vide")}
                                                    onClick={() => this.setState(prevState => ({ properties: { ...prevState.properties, [property]: secondValue } }))}
                                                />
                                            </Button.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>}
                </div>
                <div>
                    {isMobile ?
                        <>
                            <Button.Group style={{ width: '100%' }}>
                                {currentAction !== 'cutting' &&
                                    <Button
                                        type='button' className='form-button' color='red' style={{ width: '50%' }}
                                        onClick={this.props.cancelGreenSpacesMerge}
                                    >
                                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>}
                                <Button className='form-button' color='green' style={{ width: '50%' }} onClick={this.handleSubmit} >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </Button.Group>
                        </>
                        :
                        <>
                            {currentAction !== 'cutting' &&
                                <Button type='button' className='form-button' color='red' onClick={this.props.cancelGreenSpacesMerge}>
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>}
                            <Button className='form-button' color='green' onClick={this.handleSubmit}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </>}
                </div>
            </div>
        );
    }

    getPropertyName = (property) => {
        switch (property) {
            case 'customReference': return i18n.t("Référence personnalisée");
            case 'place': return i18n.t("Lieu");
            case 'placeExtra': return i18n.t("Libellé");
            case 'spaceFunctionId': return i18n.t("Fonction de l'espace");
            case 'spaceTypeId': return i18n.t("Type de surface");
            case 'dominantCompositionId': return i18n.t("Composition dominante");
            case 'detailedComposition': return i18n.t("Composition détaillée");
            case 'managementClassId': return i18n.t("Classe de gestion");
            case 'annualMaintenanceFrequency': return i18n.t("Fréquence annuelle d'entretien");
            case 'isTreeBase': return i18n.t("Pied d'arbre");
            case 'observation': return i18n.t("Observation");
            case 'density': return i18n.t("Densité");
            case 'nbTrees': return i18n.t("Nombre d'arbres");
            case 'distanceBetweenTrunks': return i18n.t("Distance entre les troncs");
            case 'dominantEssenceId': return i18n.t("Essence dominante");
            case 'averageHealthReviewId': return i18n.t("Cote sanitaire moyenne");
            case 'averageHeight': return i18n.t("Hauteur moyenne");
            case 'averageCircumference': return i18n.t("Circonférence moyenne des troncs");
            case 'averageCrownDiameter': return i18n.t("Diamètre moyen des couronnes");
            case 'tagId': return i18n.t("Tags");
            default: return !isNaN(property)
                ? this.props.customFields.find(customField => customField.id === Number(property))?.label || ''
                : null;
        }
    }

    getPropertyValue = (property, propertyValue) => {
        if (property === 'annualMaintenanceFrequency' && propertyValue === 0) propertyValue = i18n.t("Aucune");
        switch (property) {
            case 'spaceFunctionId': return this.props.spaceFunctions.find(sf => sf.id === propertyValue)?.label;
            case 'spaceTypeId': return this.props.spaceTypes.find(st => st.id === propertyValue)?.label;
            case 'dominantCompositionId': return this.props.dominantCompositions.find(dc => dc.id === propertyValue)?.label;
            case 'managementClassId': return this.props.managementClasses.find(mc => mc.id === propertyValue)?.value;
            case 'tagId': return propertyValue.map(tagId => this.props.project.tags.find(tag => tag.id === tagId)?.label).join(', ');
            case 'dominantEssenceId':
                const { gender, species, cultivar } = this.props.essences.find(e => e.id === propertyValue) || {};
                return `${gender || ''} ${species || ''} ${cultivar || ''}`.trim();
            case 'averageHealthReviewId':
                const averageHealthReview = this.props.healthReviews.find(hr => hr.id === propertyValue);
                return averageHealthReview ? `${averageHealthReview.value} (${averageHealthReview.description})` : '';
            default:
                if (isNaN(property)) return propertyValue;
                const cf = this.props.customFields.find(customField => customField.id === Number(property));
                if (!cf) return propertyValue;
                return propertyValue && cf.type === 'boolean' ? propertyValue === 'true' ? i18n.t("Oui") : i18n.t("Non")
                    : cf.type === 'list' && cf.isMultiple ? propertyValue?.split(',').map(v => cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === v)?.label).filter(v => v).join(', ')
                        : cf.type === 'list' ? cf.dropdownCustomFieldValues.find(dcfv => String(dcfv.id) === propertyValue)?.label
                            : propertyValue;
        }
    }

    handleSubmit = () => {
        const { differentProperties } = this.props;
        const { properties } = this.state;

        if (Object.keys(differentProperties).length === Object.keys(properties).length) this.props.mergeGreenSpaces({ ...properties });
        else showToast('merge_submit_not_allowed');
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        healthReviews: state.healthReviews,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        project: state.project,
        currentAction: state.currentAction,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

export default connect(mapStateToProps)(MergeForm);