import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'semantic-ui-react';
import DatePicker from './DatePicker';
// Librairies
import { faAngleLeft, faAngleRight, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { addDays, addMonths, addWeeks, addYears, startOfDay, endOfDay, subDays, subMonths, subWeeks, subYears, startOfMonth, endOfMonth } from 'date-fns';
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { v4 as uuidv4 } from 'uuid';

const id = uuidv4();
class DatePickerWithPeriod extends Component {
    render() {
        const { period, startDate, endDate, setPeriod, hideLabel, minDate, maxDate, forceTime } = this.props;
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;

        return (
            <>
                {!isMobileOnly && setPeriod &&
                    <Button
                        className='button--secondary' disabled={!startDate || !endDate}
                        style={{ marginRight: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={this.previousPeriod}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>}
                <div className={`custom-range-picker ${this.props.className || ''}`}>
                    <h4 style={{ margin: 0 }}>{i18n.t("Du")}</h4>
                    <DatePicker name='startDate' id={`start-picker-${id}`} value={startDate} initialDate={startDate || endDate} minDate={minDate} pointing='bottom left' forceTime={forceTime} onChange={this.handleDateChange} inputStyle={{ width: startDate ? '75px' : '85px' }} />
                    <h4 style={{ margin: 0 }}>{i18n.t("au")}</h4>
                    <DatePicker name='endDate' id={`end-picker-${id}`} value={endDate} initialDate={endDate || startDate} maxDate={maxDate} pointing='bottom left' forceTime={forceTime} onChange={this.handleDateChange} inputStyle={{ width: endDate ? '75px' : '85px' }} />
                </div>
                {!isMobileOnly && setPeriod && <>
                    <Button
                        className='button--secondary' disabled={!startDate || !endDate}
                        style={{ marginLeft: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={this.nextPeriod}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                    <Dropdown floating labeled={!hideLabel} text={!hideLabel ? i18n.t('Période') : ''} title={i18n.t("Période")} button icon='calendar alternate' className='icon button--secondary'>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Jour"), period, 'day', checkIcon, null)}
                                onClick={() => this.handlePeriodChange('day')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Semaine"), period, 'week', checkIcon, null)}
                                onClick={() => this.handlePeriodChange('week')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Mois"), period, 'month', checkIcon, null)}
                                onClick={() => this.handlePeriodChange('month')}
                            />
                            <Dropdown.Item
                                text={this.renderDropdownText(i18n.t("Année"), period, 'year', checkIcon, null)}
                                onClick={() => this.handlePeriodChange('year')}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </>}
            </>
        );
    }

    componentDidMount = () => {
        this.startPicker = document.getElementById(`start-picker-${id}`);
        this.endPicker = document.getElementById(`end-picker-${id}`);
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    handleDateChange = (_, { name, value }) => {
        let { startDate, endDate } = this.props;
        if (name === 'startDate') {
            startDate = value;
            if (startDate > endDate) endDate = null;
        }
        else {
            endDate = value;
            if (endDate < startDate) startDate = null;
        }

        this.props.setDates(startDate, endDate);
        if (!startDate) {
            this.startPicker.click();
            this.startPicker.focus();
        }
        if (!endDate) {
            this.endPicker.click();
            this.endPicker.focus();
        }
    }

    handlePeriodChange = (period) => this.props.setPeriod(period);
    previousPeriod = () => {
        let { startDate, endDate, period, minDate } = this.props;
        if (!startDate || !endDate) return;

        switch (period) {
            case 'day': startDate = subDays(startDate, 1); endDate = subDays(endDate, 1); break;
            case 'week': startDate = subWeeks(startDate, 1); endDate = subWeeks(endDate, 1); break;
            case 'month':
                if (startDate.getTime() === startOfDay(startOfMonth(startDate)).getTime() && endDate.getTime() === endOfDay(endOfMonth(startDate)).getTime()) { // On recule par mois
                    startDate = startOfMonth(subMonths(startDate, 1));
                    endDate = endOfMonth(startDate);
                } else { // On avance de 30 jours
                    startDate = subMonths(startDate, 1);
                    endDate = subMonths(endDate, 1);
                }
                break;
            case 'year': startDate = subYears(startDate, 1); endDate = subYears(endDate, 1); break;
            default: break;
        }

        if (!minDate || startDate >= startOfDay(minDate)) this.props.setDates(startDate, endDate, true);
    }

    nextPeriod = () => {
        let { startDate, endDate, period, maxDate } = this.props;
        if (!startDate || !endDate) return;

        switch (period) {
            case 'day': startDate = addDays(startDate, 1); endDate = addDays(endDate, 1); break;
            case 'week': startDate = addWeeks(startDate, 1); endDate = addWeeks(endDate, 1); break;
            case 'month':
                if (startDate.getTime() === startOfDay(startOfMonth(startDate)).getTime() && endDate.getTime() === endOfDay(endOfMonth(startDate)).getTime()) { // On avance par mois
                    startDate = startOfMonth(addMonths(startDate, 1));
                    endDate = endOfMonth(startDate);
                } else { // On avance de 30 jours
                    startDate = addMonths(startDate, 1);
                    endDate = addMonths(endDate, 1);
                }
                break;
            case 'year': startDate = addYears(startDate, 1); endDate = addYears(endDate, 1); break;
            default: break;
        }

        if (!maxDate || endDate <= endOfDay(maxDate)) this.props.setDates(startDate, endDate, true);
    }
}

export default DatePickerWithPeriod;