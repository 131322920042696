import React, { Component } from 'react';
import AppSettings from '../../AppSettings';
// Composants
import ThemeForm from '../Forms/Tools/ThemeForm';
import { Button, Card, Dimmer, Dropdown, Grid, Label, Loader, Message, Select } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setThemes } from '../../actionCreators/usersActions';
import { setProjects } from '../../actionCreators/projectsActions';
import i18n from '../../locales/i18n';
// Ressources
import { faCheck, faPalette, faPlus, faRightLeft, faTimes, faTimesCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
import logo from '../../resources/images/logo-mono-white.jpg';
// Services
import ThemesService from '../../services/ThemesService';
import OrganizationsService from '../../services/OrganizationsService';
// Utils
import ThemesUtil from '../../utils/ThemesUtil';
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';

const breakpointColumnsObj = {
    default: 2,
    950: 1
};

class ThemeList extends Component {
    state = {
        themes: [],
        themeToEdit: null,
        removeId: 0,
        isDeleting: false,
        isCreating: false,
        isTransferring: false,
        isLoading: true,
        selectedElements: [],
        selectedOrganization: null
    };

    render() {
        const { isDarkTheme, isOnline, loginAsData, organizations, activeOrganization } = this.props;
        const { themes, themeToEdit, removeId, isDeleting, isCreating, isLoading, isTransferring, selectedElements, selectedOrganization } = this.state;
        const themeToRemove = !isDeleting && themes.find(x => x.id === removeId);

        const organizationOptions = organizations
            .filter(organization => organization.id !== activeOrganization.id)
            .map(organization => ({ text: organization.label, value: organization.id }));

        return (
            <>
                {isLoading ?
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Loader content={isTransferring ? i18n.t("Transfert des thèmes en cours...") : i18n.t("Chargement des thèmes en cours...")} />
                    </Dimmer>
                    :
                    <>
                        {themeToEdit || isCreating ?
                            <ThemeForm theme={themeToEdit} isCreating={isCreating} cancel={this.cancel} updateThemeList={this.updateThemeList} />
                            :
                            <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                {!isTransferring ?
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                        {organizations?.length > 1 &&
                                            <Button
                                                color='blue' disabled={!isOnline || loginAsData?.readOnly}
                                                onClick={() => this.setState(prevState => ({ isTransferring: !prevState.isTransferring, selectedElements: prevState.isTransferring ? [] : prevState.selectedElements }))}
                                                style={{ width: isMobileOnly && '100%', marginRight: '10px' }}
                                            >
                                                <FontAwesomeIcon icon={faRightLeft} style={{ marginRight: '10px' }} />{i18n.t("Transférer")}
                                            </Button>}
                                        <Button
                                            className='button--primary' disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ isCreating: true })}
                                            style={{ marginLeft: !(organizations?.length > 1) && 'auto', width: isMobileOnly && '100%', marginRight: 0 }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Créer")}
                                        </Button>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', height: '36.5px', alignItems: 'center', marginBottom: '10px' }}>
                                        <Select
                                            placeholder={i18n.t("Sélectionnez l'organisation cible...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                                            options={organizationOptions} value={selectedOrganization} onChange={(_, { value }) => this.setState({ selectedOrganization: value })}
                                        />
                                        <Button color='red' style={{ margin: '0 0 0 5px', padding: '11px' }} onClick={() => this.setState({ isTransferring: false, selectedElements: [], selectedOrganization: null })}>
                                            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color='green' style={{ margin: '0 0 0 5px', padding: '11px' }} disabled={!selectedElements?.length || !selectedOrganization} onClick={this.transferElements}>
                                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                        </Button>
                                    </div>}
                                {themes.length > 0 ?
                                    <Masonry breakpointCols={breakpointColumnsObj} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
                                        {this.renderThemes()}
                                    </Masonry>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', pointerEvents: 'none' }}>
                                        <FontAwesomeIcon icon={faPalette} size='6x' style={{ marginTop: 'auto' }} />
                                        <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                                    </div>}
                            </div>}
                        {themeToRemove &&
                            <Dimmer
                                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ removeId: 0 }); }}
                            >
                                <Grid style={{ height: '100%' }}>
                                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                        <Grid.Column textAlign='center'>
                                            <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                                <Message.Content style={{ marginTop: '10px' }}>
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                        <div style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{themeToRemove.label}</div>
                                                    </div>
                                                    <div style={{ marginBottom: '10px' }}>{i18n.t("Êtes-vous certain de vouloir supprimer ce thème ? Vous ne pourrez plus le récupérer par la suite.")}</div>
                                                    <Button color='grey' onClick={() => this.setState({ removeId: 0 })}>
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                    </Button>
                                                    <Button color='red' disabled={!isOnline} onClick={() => this.removeTheme(removeId)}>
                                                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                    </Button>
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Dimmer>}
                        {isTransferring &&
                            <Label color='blue' style={{ width: 'fit-content', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '20px' }}>
                                {i18n.t("Cliquez pour sélectionner les éléments à transférer")}
                            </Label>}
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        const isUrbasense = AppSettings.isUrbasenseUrl();
        if (isUrbasense) {
            if (!this.props.logged) this.props.history.push('/login');
            else this.props.history.push('/');
        } else this.loadThemes();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.themes && this.props.themes && JSON.stringify(prevProps.themes) !== JSON.stringify(this.props.themes))
            this.setState({ themes: this.props.themes });
    }

    loadThemes = () => {
        if (this.props.themes) this.setState({ themes: this.props.themes, isLoading: false });
        else ThemesService.getThemes().then(response => {
            this.setState({ isLoading: false });
            if (response) {
                this.props.setThemes(response);
                this.setState({ themes: response });
            }
        });
    }

    renderThemes = () => {
        const { isDarkTheme } = this.props;
        const { themes, isDeleting, removeId, selectedElements, isTransferring } = this.state;

        return themes.map((theme, index) => {
            const { id, label, logoName, website } = theme;
            const themeColors = ThemesUtil.getThemeColors(theme);
            const blobInfos = AppSettings.getBlobInfos();
            const logoUrl = logoName ? `${blobInfos.endpoint}${blobInfos.containers.photos}/${logoName}` : logo;
            const isLoading = isDeleting && removeId === theme.id;

            return (
                <Card
                    key={index} style={{ width: '100%', position: 'relative', ...(isTransferring ? { boxShadow: 'none', border: selectedElements?.includes(id) ? 'solid 1px var(--primary-100)' : 'dashed 1px var(--grey-100)' } : {}) }}
                    onClick={isTransferring ? this.selectElement(id) : null}
                >
                    {isLoading &&
                        <div style={{
                            position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: isDarkTheme ? 'rgb(40,40,40,0.75)' : 'rgb(200,200,200,0.75)', pointerEvents: isLoading && 'none', cursor: 'pointer',
                            borderRadius: !isMobileOnly && '10px', zIndex: 10
                        }}
                        >
                            <Loader active inline size='small' content={i18n.t("Suppression du thème en cours...")} />
                        </div>}
                    <Grid style={{ padding: '10px' }}>
                        <Grid.Row>
                            <Grid.Column computer={4} tablet={4} mobile={16} style={{ paddingRight: !isMobileOnly && 0, marginBottom: isMobileOnly && '10px' }}>
                                <div style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '4 / 4', backgroundColor: 'black',
                                    borderRadius: !isMobileOnly && '5px'
                                }}>
                                    <img src={logoUrl} alt='logo' style={{ borderRadius: '5px', maxHeight: '100%', maxWidth: '100%' }} />
                                </div>
                            </Grid.Column>
                            <Grid.Column computer={12} tablet={12} mobile={16} style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3 style={{ padding: 0, margin: 0 }}>{label}</h3>
                                <div title={website} style={{ color: 'var(--black-10)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: isMobileOnly ? '100%' : '85%' }}>
                                    {website}
                                </div>
                                <div style={{ marginTop: isMobileOnly ? '5px' : 'auto' }}>
                                    <div>
                                        {Object.keys(themeColors).map((key, index) => (
                                            <span key={key} className='color-circle' style={{ backgroundColor: themeColors[key] || `var(--${key}-100)`, margin: index && '0 2px' }}></span>
                                        ))}
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ position: 'absolute', top: '10px', right: '10px', padding: 0, margin: 0 }}>
                        <Dropdown icon='ellipsis vertical' disabled={isTransferring} floating button direction='left' className='icon' style={{ fontSize: '10px', marginRight: 0 }}>
                            <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                    {this.renderOptions(theme)}
                                </Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card>
            );
        });
    }

    renderOptions = (theme) => {
        const { isOnline, loginAsData } = this.props;
        return (<>
            <Dropdown.Item icon='edit' text={i18n.t("Éditer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ themeToEdit: theme })} />
            <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ removeId: theme.id })} />
        </>);
    }

    cancel = () => this.setState({ themeToEdit: null, isCreating: false });

    removeTheme = (id) => {
        let { themes } = this.state;
        this.setState({ isDeleting: true });
        setTimeout(() => {
            ThemesService.deleteTheme(id).then((response) => {
                if (response) themes = themes.filter(t => t.id !== id);
                this.props.setThemes(themes);
                this.setState({ themes, isDeleting: false, removeId: 0 });
                WebSocketUtil.removeOrganizationThemes(this.props.webSocketHubs, this.props.activeOrganization.id, [id]);
            });
        }, 250);
    }

    updateThemeList = (theme) => {
        const { themes } = this.state;
        const themeIndex = themes.findIndex(x => x.id === theme.id);
        if (themeIndex !== -1) {
            themes[themeIndex] = theme;
            WebSocketUtil.updateOrganizationThemes(this.props.webSocketHubs, this.props.activeOrganization.id, [theme]);
        }
        else {
            themes.push(theme);
            WebSocketUtil.sendOrganizationThemes(this.props.webSocketHubs, this.props.activeOrganization.id, [theme]);
        }
        this.props.setThemes(themes);

        let projects = this.props.projects ? JSON.parse(JSON.stringify(this.props.projects)) : null;
        let projectsToUpdate = projects?.filter(x => x.themeId === theme.id);
        if (projectsToUpdate?.length) {
            projectsToUpdate.forEach(project => {
                const index = projects.indexOf(project);
                projects[index].theme = theme;
            });
            this.props.setProjects(projects);
        }

        this.setState({ themes });
    };

    selectElement = (id) => () => this.setState(prevState => ({
        selectedElements: prevState.selectedElements.includes(id) ? prevState.selectedElements.filter(el => el !== id) : [...prevState.selectedElements, id]
    }));

    transferElements = () => {
        const { selectedOrganization, selectedElements } = this.state;

        this.setState({ isLoading: true });
        OrganizationsService.transferOrganizationData({ targetOrganization: selectedOrganization, themes: true, elementIds: selectedElements }).then(() => {
            const transferredThemes = this.state.themes.filter(theme => selectedElements.includes(theme.id))
            this.setState(prevState => ({
                isLoading: false, isTransferring: false, selectedOrganization: null, selectedElements: [],
                themes: prevState.themes.filter(theme => !selectedElements.includes(theme.id))
            }));

            WebSocketUtil.removeOrganizationThemes(this.props.webSocketHubs, this.props.activeOrganization.id, transferredThemes);
            WebSocketUtil.sendOrganizationThemes(this.props.webSocketHubs, selectedOrganization, transferredThemes);
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        themes: state.themes,
        projects: state.projects,
        loginAsData: state.loginAsData,
        organizations: state.organizations,
        activeOrganization: state.activeOrganization,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setThemes,
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeList);