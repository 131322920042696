export const setSpaceFunctions = (spaceFunctions) => async (dispatch, getState) => {
  dispatch({ type: 'SET_SPACE_FUNCTIONS', data: spaceFunctions });
  return Promise.resolve();
};

export const setSpaceTypes = (spaceTypes) => async (dispatch, getState) => {
  dispatch({ type: 'SET_SPACE_TYPES', data: spaceTypes });
  return Promise.resolve();
};

export const setSpaceTypeCategories = (spaceTypeCategories) => async (dispatch, getState) => {
  dispatch({ type: 'SET_SPACE_TYPE_CATEGORIES', data: spaceTypeCategories });
  return Promise.resolve();
};

export const setRunoffCoefficients = (runoffCoefficients) => async (dispatch, getState) => {
  dispatch({ type: 'SET_RUNOFF_COEFFICIENTS', data: runoffCoefficients });
  return Promise.resolve();
};

export const setManagementClasses = (managementClasses) => async (dispatch, getState) => {
  dispatch({ type: 'SET_MANAGEMENT_CLASSES', data: managementClasses });
  return Promise.resolve();
};

export const setDominantCompositions = (dominantCompositions) => async (dispatch, getState) => {
  dispatch({ type: 'SET_DOMINANT_COMPOSITIONS', data: dominantCompositions });
  return Promise.resolve();
};