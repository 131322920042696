export default class ShortcutsUtil {
    static getMapShortcuts() {
        return {
            addImage: 'i',
            addBookmark: 'b',
            addTree: 't',
            dragTrees: 'shift+m',
            removeTrees: 'shift+d',
            addPolygon: 'g',
            addLine: 'l',
            addRectangle: 'r',
            addCircle: 'o',
            dragGreenSpaces: 'alt+m',
            rotateGreenSpaces: 'alt+r',
            mergeGreenSpaces: 'alt+f',
            splitGreenSpaces: 'alt+s',
            editGreenSpaces: 'alt+e',
            cutGreenSpaces: 'alt+c',
            removeGreenSpaces: 'alt+d',
            addFurniture: 'm',
            dragFurnitures: 'alt+shift+m',
            removeFurnitures: 'alt+shift+d',
            deleteElements: 'del',
            copy: 'ctrl+c',
            paste: 'ctrl+v',
            quit: 'esc',
            undo: 'ctrl+z',
            redo: 'ctrl+y',
            save: 'ctrl+s',
            history: 'ctrl+shift+h',
            actions: 'ctrl+shift+a',
            table: 'ctrl+shift+d',
            filters: 'ctrl+shift+f',
            statistics: 'ctrl+shift+s',
            printer: 'ctrl+shift+p'
        };
    }
}