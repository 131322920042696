import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';

class EssenceEditor extends Component {
    render() {
        const { row, column, onRowChange, propertyOptions } = this.props;
        const selectValue = propertyOptions.find(x => x === row[column.key]);
        const options = this.getPropertyOptions(row, column, propertyOptions);

        return (
            <select
                className='rdg-text-editor' autoFocus
                value={selectValue || ''}
                onKeyDown={(e) => this.handleKeyDown(e, row, column, onRowChange, propertyOptions)}
                onChange={e => { this.handleEssenceChange(e.target.value, row, column, onRowChange, propertyOptions) }}
            >
                {column.key !== 'gender' && options.length > 0 && (<option value=''></option>)}
                {options.map(option => (<option key={option} value={option}>{option}</option>))}
            </select >
        );
    }

    handleEssenceChange = (targetValue, row, column, onRowChange, propertyOptions) => {
        let elementsToModify = this.props.elementsToModify;
        let species, cultivar;
        if (column.key === 'cultivar') {
            const result = (targetValue || '').match(/^([^\(]+)\s?\(?([^\)]*)?\)?$/);
            cultivar = result?.[1]?.trim();
            species = result?.[2];
        }

        if (!species) species = column.key === 'species' ? (targetValue || null) : (row.species || null);
        if (!cultivar) cultivar = column.key === 'cultivar' ? (targetValue || null) : (row.cultivar || null);
        if (column.key === 'gender') {
            species = null;
            cultivar = null;
        } else if (column.key === 'species')
            cultivar = null;
        const essence = this.props.essences.find(x => // On recherche l'essence correspondante aux données saisies
            (column.key !== 'vernacularName'
                && x.gender === (column.key === 'gender' ? targetValue : row.gender)
                && x.species === (species || null)
                && x.cultivar === (cultivar || null))
            || (column.key === 'vernacularName' && x.vernacularName === targetValue)
        );

        if (essence) { // Si elle est trouvée
            const index = elementsToModify.findIndex(x => x.id === row.elementId);
            if (index === -1) { // On modifie les propriétés de l'arbre
                let feature = JSON.parse(JSON.stringify(this.props.elements[row.id].feature));
                feature.properties[this.props.propertyName] = essence.id;
                elementsToModify.push(feature);
            } else elementsToModify[index].properties[this.props.propertyName] = essence.id;

            let modificationsToCreate = [];
            if (['gender', 'vernacularName'].includes(column.key)) modificationsToCreate.push({ property: 'gender', elementId: row.elementId, oldValue: row.gender });
            if (['cultivar', 'species', 'gender', 'vernacularName'].includes(column.key)) modificationsToCreate.push({ property: 'species', elementId: row.elementId, oldValue: row.species });
            modificationsToCreate.push({ property: 'cultivar', elementId: row.elementId, oldValue: row.cultivar });
            modificationsToCreate.push({ property: 'vernacularName', elementId: row.elementId, oldValue: row.vernacularName });
            this.props.pushToModificationsHistory(modificationsToCreate);

            this.props.changeElementsToModify(elementsToModify);
            const newValue = propertyOptions.find(x => x === (column.key === 'cultivar' ? cultivar : targetValue));
            let newRow = { ...row, [column.key]: newValue || '' };

            if (column.key === 'vernacularName') newRow = { ...newRow, gender: essence.gender || '', species: essence.species || '', cultivar: essence.cultivar || '' };
            else if (column.key === 'gender') newRow = { ...newRow, species: '', cultivar: '', vernacularName: essence.vernacularName || '' };
            else if (column.key === 'species') newRow = { ...newRow, cultivar: '', vernacularName: essence.vernacularName || '' };
            else if (column.key === 'cultivar') newRow = { ...newRow, species: species || '', vernacularName: essence.vernacularName || '' };
            this.props.updateSelectedRow(newRow);
            onRowChange(newRow, true);
        }
    }

    getPropertyOptions = (row, column, propertyOptions) => {
        let options = [...propertyOptions];
        if (column.key === 'species')
            options = [...new Set(this.props.essences
                .filter(x => x.gender === row.gender && x.species)
                .map(x => x.species))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
        else if (column.key === 'cultivar')
            options = this.props.essences
                .filter(x => x.gender === row.gender
                    && (x.species === row.species || !row.species)
                    && x.cultivar)
                .map(x => row.species ? x.cultivar : `${x.cultivar}${x.species ? ` (${x.species})` : ''}`)
                .sort((a, b) => { // On trie par ordre alphabétique
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
        return options;
    }

    handleKeyDown = (e, row, column, onRowChange, propertyOptions) => {
        if (e.ctrlKey && e.key === 'v') {
            navigator.clipboard.readText().then(clipboardValue => {
                const value = propertyOptions.find(option => option === clipboardValue) || '';
                if (value || value === '')
                    this.handleEssenceChange(value, row, column, onRowChange, propertyOptions);
            });
        } else if ([37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
            this.props.updateSelectedRow(row);
            onRowChange(row, true);
        } else if (e.keyCode === 8) this.handleEssenceChange('', row, column, onRowChange, propertyOptions);
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences
    };
};

export default connect(mapStateToProps)(EssenceEditor);