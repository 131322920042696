import React, { Component } from 'react';
import { Form, Grid, Input } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';

class DropdownCustomFieldValuesEditor extends Component {
    state = {
        id: null,
        isNumeric: false,
        dropdownCustomFieldValues: []
    };

    render() {
        const { isNumeric, dropdownCustomFieldValues } = this.state;

        return (
            <Form style={{ margin: '30px' }}>
                {dropdownCustomFieldValues.map(({ label, value }, index) => (
                    <Grid key={index} style={{ margin: '0' }}>
                        <Grid.Column computer={8} tablet={8} mobile={16} style={{ padding: '0 3px 0 0' }}>
                            <Form.Field
                                control={Input} placeholder={i18n.t("Libellé")} name='label' value={label || ''}
                                onChange={(_, { value }) => this.handleDropdownValueChange('label', value, index)}
                            />
                        </Grid.Column>
                        {isNumeric &&
                            <Grid.Column computer={8} tablet={8} mobile={16} style={{ padding: '0 0 0 3px' }}>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Valeur")}
                                    name='value' value={value || ''} disabled={!label?.trim() && !value && value !== 0}
                                    onChange={(_, { value }) => this.handleDropdownValueChange('value', value, index)}
                                />
                            </Grid.Column>}
                    </Grid>
                ))}
            </Form>
        );
    }

    componentDidMount = () => {
        const { displayedList } = this.props;

        this.setState({
            id: displayedList.id,
            isNumeric: displayedList.isNumeric === i18n.t("Oui"),
            dropdownCustomFieldValues: [...displayedList.dropdownCustomFieldValues, { label: '', value: '' }]
        });
    }

    componentWillUnmount = () => {
        let { id, isNumeric, dropdownCustomFieldValues } = this.state;
        if (isNumeric) dropdownCustomFieldValues = dropdownCustomFieldValues.filter(dcfv => dcfv.label.trim());
        else dropdownCustomFieldValues = dropdownCustomFieldValues.filter(dcfv => dcfv.label.trim()).map(dcfv => ({ ...dcfv, value: dcfv.label }));
        this.props.updateListValues(id, dropdownCustomFieldValues);
    }

    handleDropdownValueChange = (name, value, index) => {
        this.setState(prevState => {
            prevState.dropdownCustomFieldValues[index][name] = value;
            if ((name === 'value' || !this.props.isNumeric)
                && index === prevState.dropdownCustomFieldValues.length - 1)
                prevState.dropdownCustomFieldValues.push({ label: '', value: '' });
            return prevState.dropdownCustomFieldValues;
        });
    }
}

export default DropdownCustomFieldValuesEditor;