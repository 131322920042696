import React, { Component } from 'react';
// Composants
import Step1 from './Step1';
import Step2 from './Step2';
import ExtraStep from '../ExtraStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenField } from '@fortawesome/pro-regular-svg-icons';
import { Form, Button, Message, Segment, Grid, Input, Select } from 'semantic-ui-react';
import InfoIcon from '../../../Utils/InfoIcon';
import FormMemberList from '../../../Lists/FormMemberList';
import DatePicker from '../../../Utils/DatePicker';
// Librairies
import { isMobileOnly, isMobile } from 'react-device-detect';
import { v4 as uuidv4, validate } from 'uuid';
import i18n from '../../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setEditedProperties, unlockEditedProperties, setExitFormWithChanges } from '../../../../actionCreators/componentsActions';
import { setPlace, setElementHistory, setPhotosGalleries, setFilesGalleries, setLayer } from '../../../../actionCreators/elementsActions';
import { setProject, setProjectActions } from '../../../../actionCreators/projectsActions';
// Ressources
import { faArrowLeft, faArrowRight, faCheck, faEye, faHeartbeat, faMapMarkedAlt, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
// Services
import ProjectsService from '../../../../services/ProjectsService';
import LocationsService from '../../../../services/LocationsService';
import GeoJsonUtil from '../../../../utils/GeoJsonUtil';
import FurnituresService from '../../../../services/FurnituresService';
// Utils
import { showToast } from '../../../../utils/ToastsUtil';
import UpdatesUtil from '../../../../utils/UpdatesUtil';
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../../utils/WebSocketUtil';
import TooltipsUtil from '../../../../utils/TooltipsUtil';
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

const initialError = {
    hidden: true,
    messages: [],
    description: false
};

const initialState = {
    properties: {
        category: 'Mobilier',
        place: '',
        typeId: 0,
        conditionId: 0,
        tagId: [],
        description: null,
        customReference: null,
        customFields: {}
    },
    requiredFields: null,
    projectTags: null,
    projectTagsToAdd: [],
    isLoading: false,
    placeLoaded: false,
    error: initialError
}

class FurnitureForm extends Component {
    state = this.props.layer[0]
        ? { ...initialState, id: this.props.layer[0].feature.id }
        : initialState;

    render() {
        if (this.state.requiredFields) {
            this.checkStepsToAvoid();

            const { id, properties } = this.state;
            const requiredFields = this.state.requiredFields;

            // Props à passer aux différentes étapes
            const stepProps = {
                requiredFields: requiredFields,
                properties: this.state.properties,
                error: this.state.error,
                isTabletLandscape: this.props.isTabletLandscape,
                orderConfig: this.props.project.orderConfig,
                handleChange: this.handleChange,
                completeProperties: this.completeProperties,
                renderFields: this.renderFields
            };

            return (
                <Form className='modal-content' onSubmit={this.handleSubmit} loading={this.state.isLoading} error>
                    <Grid style={{ margin: 0 }}>
                        <Grid.Column textAlign='center' style={{ padding: '5px', paddingTop: 0 }}>
                            <Button.Group size='tiny' id='anchor-buttons'>
                                {this.renderAnchorButtons()}
                            </Button.Group>
                            {id &&
                                <div style={{ position: 'absolute', right: 0, top: '3px' }}>
                                    <FormMemberList
                                        id={`furniture-form-${this.props.project.id}-${id}`} stateToSend={properties} setIsLoading={(isLoading) => this.setState({ isLoading })}
                                        updateForm={(properties) => this.setState({ isLoading: false, properties })}
                                    />
                                </div>}
                        </Grid.Column>
                    </Grid>
                    <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '5px', display: 'flex', flexDirection: 'column' }}>
                        {isMobileOnly &&
                            <Message
                                error
                                style={{ textAlign: 'left', overflow: 'auto', marginTop: '10px' }}
                                hidden={this.state.error.hidden}
                                header='Erreur'
                                list={this.state.error.messages}
                            />}
                        {!this.stepsToAvoid.includes(1) &&
                            <Step1
                                {...stepProps} placeLoaded={this.state.placeLoaded} projectTags={this.state.projectTags}
                                handleTagsChange={this.handleTagsChange} handleAddTag={this.handleAddTag}
                            />}
                        {!this.stepsToAvoid.includes(2) &&
                            <Step2 {...stepProps} />}
                        {!this.stepsToAvoid.includes(3) &&
                            <ExtraStep {...stepProps} category='Mobilier' handleCustomFieldChange={this.handleCustomFieldChange} deleteCustomField={this.deleteCustomField} />}
                        {!isMobileOnly &&
                            <Message
                                error hidden={this.state.error.hidden}
                                header={i18n.t("Erreur")} list={this.state.error.messages}
                                style={{ textAlign: 'left', overflow: 'auto', marginBottom: '10px' }}
                            />}
                    </Segment>
                    {!this.props.isKeyboardOpen &&
                        <div className='modal-content-footer'>
                            {!this.state.id ?
                                <>
                                    {isMobile ?
                                        <Button.Group widths={4}>
                                            {this.renderCancelButton()}
                                            {this.renderSubmitButton()}
                                        </Button.Group>
                                        :
                                        <>
                                            {this.renderCancelButton()}
                                            {this.renderSubmitButton()}
                                        </>}
                                </>
                                :
                                <>
                                    {isMobile ?
                                        <>
                                            <Button.Group widths={4}>
                                                {this.renderDeleteButton()}
                                                {this.state.properties.toCutDown === 4 ? this.renderReplantButton() : this.renderSubmitButton(properties)}
                                            </Button.Group>
                                        </>
                                        : this.renderSubmitButton(properties)}
                                </>}
                            {!isMobile && this.state.id && this.renderDeleteButton()}
                        </div>}
                </Form >
            );
        } else return (<p>Chargement...</p>);
    }

    componentDidMount = () => this.setInitialState();
    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0] && this.props.layer[0].feature.id !== this.state.id) {
            const { projectTags, ...state } = initialState;
            this.setState({ ...state, id: this.props.layer[0].feature.id }, this.setInitialState());
        } else if (this.props.project && JSON.stringify(prevProps.project.tags) !== JSON.stringify(this.props.project.tags))
            this.setState({ projectTags: this.props.project.tags });
    }

    componentWillUnmount = () => {
        // Si les propriétés du state local sont différentes de celles présentes dans le store Redux, on les sauvegarde
        if (JSON.stringify(this.state.properties) !== JSON.stringify(this.props.editedProperties))
            this.props.setEditedProperties(this.addedElement ? null : this.state.properties);
        if (this.props.webSocketHubs?.formsHub) this.props.webSocketHubs.formsHub.stop();
        this.props.setExitFormWithChanges(null);
    }

    renderFields = (category) => {
        const { project, activeOrganization } = this.props;
        const projectSubscription = project.organization.subscription;
        const areCustomFieldsAvailable = (projectSubscription || activeOrganization.subscription).customFields;
        if (!areCustomFieldsAvailable) return null;

        const projectCustomFields = [...(this.props.project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map(projectCustomField => {
            const customField = this.props.customFields.find(cf => cf.id === projectCustomField.customFieldId);
            const value = this.state.properties.customFields?.[customField?.id] || null;

            return customField?.category === 'Mobilier' && customField.type !== 'formula' ? (
                <Grid.Column key={customField.id} computer={8} table={8} mobile={16}>
                    {['text', 'url', 'number'].includes(customField.type) ?
                        <Form.Field
                            control={Input} type={customField.type} min={customField.min} max={customField.max} step={customField.step || 1} placeholder={i18n.t("Indiquez une valeur")}
                            label={<label>{customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : '')}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                            name={customField.id} value={value || ''} onChange={this.handleCustomFieldChange}
                        />
                        : customField.type === 'boolean' ?
                            <Form.Field
                                control={Select} placeholder={i18n.t("Sélectionnez une valeur")} selectOnBlur={false} clearable
                                label={<label>{customField.label}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                                name={customField.id} options={[{ text: i18n.t("Oui"), value: 'true' }, { text: i18n.t("Non"), value: 'false' }]} value={value || ''}
                                onChange={this.handleCustomFieldChange}
                            />
                            : customField.type === 'date' ?
                                <DatePicker
                                    label={<label>{customField.label}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                                    name={customField.id} value={value ? new Date(value) : ''} style={{ marginTop: '5px' }}
                                    minDate={customField.minDate ? new Date(customField.minDate) : ''} maxDate={customField.maxDate ? new Date(customField.maxDate) : ''}
                                    onChange={this.handleCustomFieldChange}
                                />
                                :
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez une valeur")}
                                    clearable selection={customField.isMultiple} search={FormattersUtil.searchList} selectOnBlur={false} multiple={customField.isMultiple} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    label={<label>{customField.label}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                                    name={customField.id} options={customField.dropdownCustomFieldValues.map(dcfv => ({ text: dcfv.label, value: String(dcfv.id) }))} value={value ? (!customField.isMultiple ? value : value.split(',')) : (customField.isMultiple ? [] : '')}
                                    onChange={this.handleCustomFieldChange}
                                />}
                </Grid.Column>
            ) : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    renderAnchorButtons = () => {
        const { project } = this.props;

        const categories = [
            { title: i18n.t("Emplacement & en-tête"), id: 'cat--site', color: 'var(--yellow-100)', icon: faMapMarkedAlt, step: 1, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Emplacement') },
            { title: i18n.t("État"), id: 'cat--condition', color: 'var(--red-100)', icon: faHeartbeat, step: 1, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'État') },
            { title: i18n.t("Description"), id: 'cat--description', color: 'var(--grey-100)', icon: faEye, step: 2, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Description') },
            ...(project?.fieldCategories || [])
                .filter(fieldCategory => fieldCategory.category === 'Mobilier' && project?.projectCustomFields.find(pcf => pcf.fieldCategoryId === fieldCategory.id))
                .map(fieldCategory => ({ title: fieldCategory.label, id: 'cat--' + fieldCategory.id, color: fieldCategory.color, icon: fieldCategory.icon, step: 6, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Mobilier', `${fieldCategory.id}`) })),
            { title: i18n.t("Champs supplémentaires"), id: 'cat--extra', color: 'var(--grey-60)', icon: faPenField, step: 3, order: 999 }
        ];

        return categories.map(({ title, id, color, icon, step, order }, i) => !this.stepsToAvoid.includes(step) && (
            <Button
                key={i} type='button' style={{ padding: '6px 10px', backgroundColor: color, width: '40px', order }} title={title}
                onClick={() => {
                    const category = document.getElementById(id);
                    const modalBody = document.getElementsByClassName('modal-content-body')[0];
                    if (category && modalBody) modalBody.scrollTo(0, category.offsetTop - 5);
                }}
            >
                <FontAwesomeIcon icon={icon} style={{ fontSize: '12pt' }} />
            </Button>
        )).filter(cat => cat);
    }

    renderCancelButton = () => (
        <Button
            type='button' className='form-button' color='red'
            onClick={() => this.props.hideForm(false)}
        >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Annuler")}
        </Button>
    );

    arePropertiesModified = (oldProperties, newProperties) => {
        if (!oldProperties || !newProperties) return false;
        const properties = Object.keys(initialState.properties);
        for (let i = 0; i < properties.length; i++)
            if (JSON.stringify(oldProperties[properties[i]]) !== JSON.stringify(newProperties[properties[i]]))
                return true;
        return false;
    }

    renderSubmitButton = (properties = null) => {
        const disabled = this.props.layer[0] && !this.arePropertiesModified(this.props.layer[0].feature.properties, properties);

        if (!disabled && !this.props.exitFormWithChanges) this.props.setExitFormWithChanges({ submit: this.handleSubmit, cancel: () => this.props.hideForm(false) });
        else if (disabled && this.props.exitFormWithChanges) this.props.setExitFormWithChanges(null);

        return (
            <Button id='o3RMEGnb' type='submit' className='form-button' color='green' disabled={disabled}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Valider")}
            </Button>
        );
    }

    renderDeleteButton = () => (
        <Button
            id='jkVioxVT' type='button' className='form-button' color='red' floated='right'
            onClick={this.handleDelete}
        >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Supprimer")}
        </Button>
    );

    checkStepsToAvoid = () => {
        this.stepsToAvoid = [];
        const requiredFields = this.state.requiredFields.furnitures;

        const checkIfRequired = (properties, fieldCategories, stepNumber) => {
            const isRequired = properties.some(property => requiredFields[property])
                || fieldCategories.some(category => this.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === category))?.length > 0);
            if (!isRequired) this.stepsToAvoid.push(stepNumber);
        };

        // On vérifie si au moins un champs de chaque étape est à compléter, sinon on ajoute l'étape dans les étapes à éviter
        checkIfRequired(['place', 'customReference', 'tags', 'condition'], ['Emplacement', 'État'], 1);
        checkIfRequired(['type', 'description'], ['Description'], 2);

        if (!this.state.id && !this.props.project?.projectCustomFields?.filter(pcf => this.props.customFields?.find(customField => customField.category === 'Mobilier' && customField.id === pcf.customFieldId)).length)
            this.stepsToAvoid.push(3);
    }

    handleChange = (_, { name, value }) => {
        if (['conditionId', 'typeId'].includes(name) && !value) value = 0;

        this.setState(prevState => ({
            properties: { ...prevState.properties, [name]: value },
            error: { ...prevState.error, [name]: false }
        }), () => {
            if (this.props.project && this.state.id) {
                if (name !== 'description') WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
                else {
                    clearTimeout(this.updateFormTimeout);
                    this.updateFormTimeout = setTimeout(() => {
                        this.updateFormTimeout = null;
                        WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
                    }, 1000);
                }
            }
        });
    }

    handleTagsChange = (e, { name, value }) => {
        let newValues = [];
        value.forEach(v => {
            if (validate(v))
                newValues.push(v);
        });

        this.setState({
            properties: { ...this.state.properties, [name]: newValues },
            error: { ...this.state.error, [name]: false }
        }, () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    handleAddTag = (tag) => {
        tag = tag.trim();
        let found = false;
        for (let i = 0; i < this.state.projectTags.length && !found; i++)
            if (this.state.projectTags[i].label.toUpperCase() === tag.toUpperCase()
                && this.state.projectTags[i].category === 'Mobilier')
                found = true;

        if (!found) {
            const id = uuidv4();
            this.setState(prevState => ({
                properties: {
                    ...prevState.properties,
                    tagId: [...prevState.properties.tagId, id]
                },
                projectTags: [...prevState.projectTags, { id: id, label: tag, projectId: this.props.project.id, category: 'Mobilier' }],
                projectTagsToAdd: [...prevState.projectTagsToAdd, { id: id, label: tag, projectId: this.props.project.id, category: 'Mobilier' }]
            }));
        }
    }

    deleteCustomField = (name) => {
        this.setState(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            delete newState.properties.customFields[name];
            newState.properties = { ...newState.properties };
            return { properties: newState.properties };
        }, () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties)
        });
    }

    handleCustomFieldChange = (_, { name, value }) => {
        if (value?.toISOString) value = value.toISOString();
        if (Array.isArray(value)) value = value.join(',');
        this.setState(prevState => {
            if (!value) {
                delete prevState.properties.customFields[name];
                prevState.properties = { ...prevState.properties };
            } else prevState.properties = { ...prevState.properties, customFields: { ...prevState.properties.customFields, [name]: value } };
            return { properties: prevState.properties };
        }, () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties)
        });
    }

    handleSubmit = () => {
        if (this.verifyProperties()) {
            let properties = JSON.parse(JSON.stringify(this.state.properties));
            this.props.setPlace(properties.place);

            if (this.state.projectTagsToAdd.length > 0) {
                let project = { ...this.props.project };
                project.tags = this.state.projectTags;
                this.props.setProject(project);
                ProjectsService.addProjectTags(this.state.projectTagsToAdd).then(response => {
                    if (response) {
                        project.tags = response;
                        this.props.setProject(project);
                        WebSocketUtil.sendTags(this.props.webSocketHubs, this.props.project.id, this.state.projectTagsToAdd);
                    }
                });
            }

            if (!this.state.id) {
                const feature = GeoJsonUtil.generateMarkerFeature(properties, this.props.layer._latlng, this.props.project?.id || 0);
                this.setState({ isLoading: true });
                FurnituresService.addFurniture(feature, 'adding', this.props.webSocketHubs).finally(() => {
                    this.addedElement = true;
                    this.props.addMarker('Mobilier', this.props.layer, feature, { showContainer: true, showModal: true });
                });
            } else {
                if (JSON.stringify(this.props.layer[0].feature.properties) !== JSON.stringify(properties)) {
                    const { layer, elementHistory } = this.props;
                    this.setState({ isLoading: true });
                    UpdatesUtil.updateFurniture(layer[0], properties, layer, true, this.props.fieldList, this.props.furnituresLayer.activeChild, 'updating', layer[0].feature.projectId, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps })
                        .then(response => {
                            if (response?.data?.history && elementHistory) {
                                this.props.setElementHistory([...elementHistory, response.data.history]);
                                if (this.props.project) WebSocketUtil.sendElementsHistories(this.props.webSocketHubs, this.props.project.id, [response.data.history]);
                            }
                        })
                        .finally(() => {
                            if (this.props.references.furnitures === 'customReference')
                                this.props.layer.forEach(layer => {
                                    TooltipsUtil.setMarkerTooltip(layer.feature.properties.customReference, layer, this.props.referencesLayer, true);
                                });

                            this.setState({ isLoading: false });
                            this.props.setLayer([...this.props.layer]);
                            this.props.updateLegend(i18n.t("Mobilier urbain"));
                            this.setState({ properties });
                        });
                } else showToast('element_update_not_allowed');
            }
        }
    }

    handleDelete = () => {
        if (!this.props.project || !ProjectsUtil.isElementLocked(this.props.lockedElements, { id: this.state.id }, this.props.project, this.props.projectCollaborators, []))
            this.props.showRemoveForm();
    }

    completeProperties = (newProperties) => {
        this.setState(prevState => ({ properties: { ...prevState.properties, ...newProperties } }));
    }

    verifyProperties = () => {
        let isValid = true;
        const error = {
            messages: [],
            description: false
        };

        const addError = (property, message) => {
            error.messages = [...(error.messages || []), message];
            error[property] = true;
            isValid = false;
        };

        // Étape 2
        if (this.state.properties.description && this.state.properties.description.length > 5000)
            addError(2, 'description', i18n.t("La description doit être composée de 5000 caractères maximum"));

        if (!isValid) this.setState({ error: { hidden: error.messages.length > 0 ? false : true, ...error } });
        else this.setState({ error: initialError });

        return isValid;
    }

    setInitialState = () => {
        this.props.unlockEditedProperties(); // On établit la possibilité de modifier les propriétés dans Redux
        this.stepsToAvoid = [];

        if (!this.state.requiredFields) {
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);

            if (!this.state.projectTags && this.props.project && requiredFields.furnitures.tags) {
                ProjectsService.getTags(this.props.project.id).then(response => {
                    let projectTags = [];
                    if (response) {
                        projectTags = response;
                        let project = { ...this.props.project };
                        project.tags = projectTags;
                        this.props.setProject(project);
                    } else if (!this.props.isOnline)
                        projectTags = this.props.project.tags;

                    this.setState({ projectTags: projectTags });
                });
            }

            this.setState({ requiredFields: requiredFields }, () => this.checkStepsToAvoid());
        }

        const layer = Array.isArray(this.props.layer) ? this.props.layer[0] : this.props.layer;
        const feature = layer?.feature ? JSON.parse(JSON.stringify(layer.feature)) : null;
        if (feature?.id) { // Si l'utilisateur est en train d'éditer un mobilier déjà existant
            let properties = this.props.editedProperties ? this.props.editedProperties : feature.properties;
            this.setState({ properties: { ...properties }, placeLoaded: true });
        } else { // Si l'utilisateur est en train d'ajouter un nouvel mobilier
            const latlngs = this.props.layer._latlng;
            LocationsService.getPlace(latlngs.lat, latlngs.lng).then(place => { // On rempli le champs 'Commune' avec le lieu récupérer depuis les coordonnées
                if (place) this.props.setPlace(place);
                this.setState(prevState => ({ properties: { ...prevState.properties, place: place || this.props.place }, placeLoaded: true }));
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        projectCollaborators: state.projectCollaborators,
        place: state.place,
        editedProperties: state.editedProperties,
        layer: state.layer,
        rights: state.rights,
        project: state.project,
        projectActions: state.projectActions,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        isKeyboardOpen: state.isKeyboardOpen,
        elementHistory: state.elementHistory,
        photosGalleries: state.photosGalleries,
        filesGalleries: state.filesGalleries,
        webSocketHubs: state.webSocketHubs,
        lockedElements: state.lockedElements,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields,
        exitFormWithChanges: state.exitFormWithChanges,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setProject,
    setProjectActions,
    setPlace,
    setElementHistory,
    setPhotosGalleries,
    setFilesGalleries,
    setLayer,
    setExitFormWithChanges
}

export default connect(mapStateToProps, mapDispatchToProps)(FurnitureForm);