import React, { Component } from 'react';
// Composants
import { Form, Input, Button, Select, Checkbox, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly, withOrientationChange } from 'react-device-detect';
import { distance, transformTranslate, point, bearing } from '@turf/turf';
import L from 'leaflet';
import { connect } from 'react-redux';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Helmet } from 'react-helmet';
// Styles
import i18n from '../../../locales/i18n';
// Utils
import GeometriesUtil from '../../../utils/GeometriesUtil';

const paperFormats = [
    { id: 'A0', height: 118.9, width: 84.1 },
    { id: 'A1', height: 84.1, width: 59.4 },
    { id: 'A2', height: 59.4, width: 42 },
    { id: 'A3', height: 42, width: 29.7 },
    { id: 'A4', height: 29.7, width: 21 },
    { id: 'A5', height: 21, width: 14.8 }
];

const initialErrors = {
    hidden: true,
    messages: [],
    distances: {
        map: false,
        actual: false
    }
};

const gcd = function (a, b) {
    if (!b) return a;
    return gcd(b, a % b);
}

class ScreenshotForm extends Component {
    state = {
        captureMode: 'screenshot',
        formats: {
            file: 'JPEG',
            paper: 'A4'
        },
        titles: {
            left: '',
            middle: '',
            right: ''
        },
        distances: {
            map: '',
            actual: ''
        },
        scaleDisplay: {
            numeric: true,
            graphic: true
        },
        showLegend: !isMobileOnly,
        showSurroundings: true,
        referencesToShow: null,
        isPrinting: false,
        isLandscape: true,
        errors: initialErrors,
        isLoading: false
    }

    render() {
        const { isOnline, isToolbarExpanded, isDarkTheme } = this.props;
        const {
            captureMode, formats, titles, distances, scaleDisplay, showLegend, showSurroundings, referencesToShow, isPrinting, exportedBounds, isLandscape, errors, isLoading
        } = this.state;

        const fileFormatOptions = [
            { text: 'JPEG', value: 'JPEG' },
            { text: 'PNG', value: 'PNG' },
            { text: 'PDF', value: 'PDF' }
        ];
        const paperFormatOptions = paperFormats.map(paperFormat => ({ text: paperFormat.id, value: paperFormat.id }));
        const orientationOptions = [{ text: i18n.t("Paysage"), value: true }, { text: i18n.t("Portrait"), value: false }];
        const booleanOptions = [{ text: i18n.t("Oui"), value: true }, { text: i18n.t("Non"), value: false }];

        return (
            <>
                <Helmet>
                    {isPrinting && exportedBounds && <link rel='stylesheet' type='text/css' href='/hide-ui.css' />}
                </Helmet>
                <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                    <Form error style={{ width: '100%' }}>
                        <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px' }}>
                            <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Mode de capture")} :</b></label>
                            <Button.Group vertical style={{ width: '100%', marginBottom: '8px' }}>
                                <Button
                                    inverted={isDarkTheme} style={{ textAlign: 'left' }} active={captureMode === 'screenshot'}
                                    onClick={() => this.setState({ captureMode: 'screenshot' })}
                                >
                                    {i18n.t("Capture d'écran")}
                                    {captureMode === 'screenshot' && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }} />}
                                </Button>
                                <Button
                                    inverted={isDarkTheme} style={{ textAlign: 'left' }} active={captureMode === 'printing'}
                                    onClick={() => this.setState({ captureMode: 'printing' })}
                                >
                                    {i18n.t("Impression")}
                                    {captureMode === 'printing' && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '8px' }} />}
                                </Button>
                            </Button.Group>
                            <Form.Field
                                control={Select} label={`${i18n.t("Type de fichier")} :`} placeholder={i18n.t("Sélectionnez un format")} selectOnBlur={false}
                                name='fileFormat' options={fileFormatOptions} value={formats.file} onChange={this.handleFormatChange}
                            />
                            <Form.Field
                                control={Input} label={`${i18n.t("En-tête gauche")} :`} placeholder='Jake Jeffery' autoComplete='off'
                                name='leftTitle' value={titles.left} onChange={this.handleTitleChange}
                            />
                            <Form.Field
                                control={Input} label={`${i18n.t("En-tête central")} :`} placeholder={i18n.t("Aménagement des parcs")} autoComplete='off'
                                name='middleTitle' value={titles.middle} onChange={this.handleTitleChange}
                            />
                            <Form.Field
                                control={Input} label={`${i18n.t("En-tête droit")} :`} placeholder='06/05/2020' autoComplete='off'
                                name='rightTitle' value={titles.right} onChange={this.handleTitleChange}
                            />
                            {captureMode === 'printing' &&
                                <>
                                    {!isMobileOnly &&
                                        <Form.Field
                                            control={Select} label={`${i18n.t("Légende")} :`} selectOnBlur={false}
                                            name='showLegend' options={booleanOptions} value={showLegend} onChange={this.handleChange}
                                        />}
                                    {this.props.project &&
                                        <Form.Field
                                            control={Select} label={`${i18n.t("Zone géographique")} :`} selectOnBlur={false}
                                            name='showSurroundings' options={booleanOptions} value={showSurroundings} onChange={this.handleChange}
                                        />}
                                    {this.props.project &&
                                        <div style={{ marginBottom: '10px' }}>
                                            <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Références")} :</b></label><br />
                                            <Button.Group style={{ width: '100%' }}>
                                                <Button
                                                    style={{ width: '33%', padding: '6px 0' }} color={referencesToShow === null ? 'blue' : 'grey'}
                                                    content={i18n.t("Aucune")} onClick={() => this.setState({ referencesToShow: null })}
                                                />
                                                <Button
                                                    style={{ width: '33%', padding: '6px 0' }} color={referencesToShow === 'projectReference' ? 'blue' : 'grey'}
                                                    content={i18n.t("Auto")} onClick={() => this.setState({ referencesToShow: 'projectReference' })}
                                                />
                                                <Button
                                                    style={{ width: '33%', padding: '6px 0' }} color={referencesToShow === 'customReference' ? 'blue' : 'grey'}
                                                    content={i18n.t("Perso")} onClick={() => this.setState({ referencesToShow: 'customReference' })}
                                                />
                                            </Button.Group>
                                        </div>}
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Options d'impression")}
                                        name='isPrinting' checked={isPrinting} onChange={this.handleCheckboxChange}
                                    />
                                    <Form.Field
                                        control={Select} label={`${"Format papier"} :`} placeholder={i18n.t("Sélectionnez un format papier")} selectOnBlur={false}
                                        name='paperFormat' options={paperFormatOptions} value={formats.paper} onChange={this.handleFormatChange}
                                        disabled={!isPrinting}
                                    />
                                    <Form.Field
                                        control={Select} label={`${"Orientation"} :`} placeholder={i18n.t("Sélectionnez une orientation")} selectOnBlur={false}
                                        name='isLandscape' options={orientationOptions} value={isLandscape} onChange={this.handleChange}
                                        disabled={!isPrinting}
                                    />
                                    <div style={{ margin: '2px 0 10px 0' }}>
                                        <label style={{ fontSize: '.92857143em', opacity: isPrinting ? 1 : 0.2 }}><b>{i18n.t("Échelle")} :</b></label><br />
                                        <Input
                                            type='number' min='1' step='1' placeholder='1' error={errors.distances.map}
                                            name='mapDistance' value={distances.map} onChange={this.handleDistanceChange}
                                            disabled={!isPrinting} style={{ width: '75px' }}
                                        />
                                        <span style={{ margin: '21.6px 6px 0 6px', fontSize: '20pt', opacity: isPrinting ? 1 : 0.2 }}>/</span>
                                        <Input
                                            type='number' min='1' step='1' placeholder='1000' error={errors.distances.actual}
                                            name='actualDistance' value={distances.actual} onChange={this.handleDistanceChange}
                                            disabled={!isPrinting} style={{ width: '100px' }}
                                        />
                                    </div>
                                    <div>
                                        <label style={{ fontSize: '.92857143em', opacity: isPrinting ? 1 : 0.2 }}><b>{i18n.t("Affichage")} :</b></label><br />
                                        <Checkbox
                                            label='Numérique' disabled={!isPrinting}
                                            name='numeric' checked={scaleDisplay.numeric} onChange={this.handleScaleDisplayChange}
                                        /><br />
                                        <Checkbox
                                            label='Graphique' disabled={!isPrinting}
                                            name='graphic' checked={scaleDisplay.graphic} onChange={this.handleScaleDisplayChange}
                                        />
                                    </div>
                                </>}
                        </div>
                        <div style={{ display: 'flex', marginTop: '10px', flexDirection: 'column' }}>
                            <Message
                                error hidden={errors.hidden} style={{ textAlign: 'left', overflow: 'auto' }}
                                header={i18n.t("Erreur")} list={errors.messages}
                            />
                            <div style={{ display: 'flex' }}>
                                <Button
                                    type='button' color='red' size='small'
                                    style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                    disabled={isLoading} onClick={() => this.props.hideForm(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button
                                    id='dDCWEgiE' type='submit' color='green' size='small'
                                    style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                    disabled={isLoading || !isOnline} loading={isLoading} onClick={this.handleSubmit}
                                >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </>
        )
    }

    componentDidMount = () => {
        this.initialMaxBounds = this.props.map.options.maxBounds;
        if (this.initialMaxBounds) this.props.map.setMaxBounds(null);
    }

    componentDidUpdate = (_, prevState) => {
        const { isPrinting, distances, isLandscape, exportedBounds } = this.state;
        let { formats } = this.state;

        if (isPrinting) {
            const prevStateOptions = JSON.stringify({
                isPrinting: prevState.isPrinting, paperFormat: prevState.formats.paper,
                distances: prevState.distances, isLandscape: prevState.isLandscape
            });

            const actualStateOptions = JSON.stringify({ isPrinting, paperFormat: formats.paper, distances, isLandscape });

            if (prevStateOptions !== actualStateOptions) {
                clearTimeout(this.previewTimeout);
                if (this.areDistancesValid(false)) {
                    this.previewTimeout = setTimeout(() => {
                        const initialCenter = exportedBounds && L.polygon([exportedBounds.getLatLngs()[1]]).getBounds().getCenter();
                        const paperFormat = paperFormats.find(paperFormat => paperFormat.id === formats.paper);
                        const distanceToDisplay = this.getDistanceToDisplay(paperFormat, isLandscape, distances.map, distances.actual);

                        if (prevState.isPrinting || this.props.map.getZoom() !== this.printingZoom) {
                            this.printingZoom = this.getZoomLevels(distanceToDisplay, true).fitZoom;
                            if (!exportedBounds) this.props.map.getRenderer(this.props.map).options.padding = 2;
                            if (this.printingZoom !== this.props.map.getZoom()) this.props.map.setZoom(this.printingZoom, { animate: false });
                        }
                        this.props.map.setMinZoom(this.printingZoom);
                        this.props.map.setMaxZoom(this.printingZoom);

                        let center = L.latLngBounds(
                            this.props.map.containerPointToLatLng(L.point(270, 0)),
                            this.props.map.getBounds().getSouthEast()
                        ).getCenter();
                        center = [center.lng, center.lat];

                        const northWest = transformTranslate(
                            transformTranslate(point(center), distanceToDisplay.x / 2, 270, { units: 'centimeters' }),
                            distanceToDisplay.y / 2, 0, { units: 'centimeters' }
                        ).geometry.coordinates;
                        const southEast = transformTranslate(
                            transformTranslate(point(center), distanceToDisplay.x / 2, 90, { units: 'centimeters' }),
                            distanceToDisplay.y / 2, 180, { units: 'centimeters' }
                        ).geometry.coordinates;

                        if (exportedBounds) this.props.map.removeLayer(exportedBounds);
                        else {
                            this.props.map.on('movestart', this.handleMoveStart);
                            this.props.map.on('move', this.handleMove);
                        }

                        const newExportedBounds = L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs([
                            [
                                [-200, -90],
                                [200, -90],
                                [200, 90],
                                [-200, 90]
                            ],
                            [
                                northWest,
                                [southEast[0], northWest[1]],
                                [southEast[0], southEast[1]],
                                [northWest[0], southEast[1]],
                                northWest
                            ]
                        ]), { fillColor: 'var(--primary-100)', color: 'var(--secondary-60)' }).addTo(this.props.map);

                        this.setState({ exportedBounds: newExportedBounds });

                        if ((prevState.isPrinting || this.props.map.getZoom() !== this.printingZoom) && initialCenter) { // Pour garder toujours le même centre dans la preview
                            const newCenter = L.polygon([newExportedBounds.getLatLngs()[1]]).getBounds().getCenter();
                            const dist = distance([newCenter.lng, newCenter.lat], [initialCenter.lng, initialCenter.lat]);
                            const angle = bearing([newCenter.lng, newCenter.lat], [initialCenter.lng, initialCenter.lat]);
                            const mapCenter = this.props.map.getBounds().getCenter();
                            const newMapCenter = transformTranslate(point(([mapCenter.lng, mapCenter.lat])), dist, angle)?.geometry.coordinates;
                            this.props.map.setView(L.latLng(newMapCenter[1], newMapCenter[0]), this.printingZoom, { animate: false });
                        }
                    }, !prevState.isPrinting ? 0 : 500);
                }
            }
        }
    }

    componentWillUnmount = () => {
        if (!this.state.isLoading) {
            if (this.initialMaxBounds) this.props.map.setMaxBounds(this.initialMaxBounds);
            this.props.map.getRenderer(this.props.map).options.padding = 0.1;
            this.props.map.setMinZoom(3);
            delete this.props.map.options.maxZoom;
            if (this.state.exportedBounds) this.props.map.removeLayer(this.state.exportedBounds);
        }
    }

    // Calcul de la distance à afficher en fonction du papier et de l'échelle
    getDistanceToDisplay = (paperFormat, isLandscape, mapDistance, actualDistance) => {
        const distanceScale = Number(actualDistance) / Number(mapDistance);
        return {
            x: paperFormat[isLandscape ? 'height' : 'width'] * distanceScale,
            y: paperFormat[isLandscape ? 'width' : 'height'] * distanceScale
        };
    }

    // Calcul du scale à utiliser avec le niveau de zoom actuel de la carte
    getScale = (distanceToDisplay, isPreview = false) => {
        const bounds = this.props.map.getBounds();
        const northWest = !isPreview ? bounds.getNorthWest() : this.props.map.containerPointToLatLng(L.point(270, 0));
        const southEast = bounds.getSouthEast();

        const displayedDistance = {
            x: distance([northWest.lng, northWest.lat], [southEast.lng, northWest.lat]) * 100000,
            y: distance([southEast.lng, northWest.lat], [southEast.lng, southEast.lat]) * 100000
        };

        return {
            x: distanceToDisplay.x / displayedDistance.x,
            y: distanceToDisplay.y / displayedDistance.y
        };
    }

    getZoomLevels = (distanceToDisplay, isPreview = false) => {
        let overflowZoom = 5, fitZoom; // overflowZoom = Zoom mini pour que la zone à imprimer dépasse l'écran en x & y, fitZoom = Zoom max pour que la zome à imprimer ne dépasse pas l'écran
        this.props.map.setMinZoom(3);
        delete this.props.map.options.maxZoom;
        this.props.map.setZoom(overflowZoom, { animate: false });

        const scale = this.getScale(distanceToDisplay, isPreview);
        while (scale.x < 1 || scale.y < 1) {
            overflowZoom++;
            scale.x *= 2;
            scale.y *= 2;
            if ((scale.x > 1 || scale.y > 1) && !fitZoom)
                fitZoom = overflowZoom - 1;
        }

        return { overflowZoom, fitZoom };
    }

    areDistancesValid = (updateState = true) => {
        const { distances, isPrinting } = this.state;
        const errors = JSON.parse(JSON.stringify(initialErrors));

        if (isPrinting) {
            if (distances.map.trim() === '' || isNaN(distances.map) || distances.actual.trim() === '' || isNaN(distances.actual)) {
                if (distances.map.trim() === '' || isNaN(distances.map)) errors.distances.map = true;
                if (distances.actual.trim() === '' || isNaN(distances.actual)) errors.distances.actual = true;
                errors.messages.push(i18n.t("Veuillez compléter l'échelle à appliquer lors de l'export"));
            } else if (Number(distances.map) > Number(distances.actual)) {
                errors.distances.map = true; errors.distances.actual = true;
                errors.messages.push(i18n.t("La distance carte ne peut être inférieure à la distance réelle"));
            } else if (Number((distances.actual) / Number(distances.map)) > 50000) {
                errors.distances.map = true; errors.distances.actual = true;
                errors.messages.push(i18n.t("L'échelle maximale est 1/50.000"));
            } else if (Number((distances.actual) / Number(distances.map)) < 500) {
                errors.distances.map = true; errors.distances.actual = true;
                errors.messages.push(i18n.t("L'échelle minimale est 1/500"));
            }
        }

        errors.hidden = errors.messages.length ? false : true;
        if (updateState) this.setState({ errors });
        return errors.hidden;
    }

    handleMoveStart = () => {
        if (this.state.exportedBounds) {
            this.initialCenter = this.props.map.getCenter();
            this.initialPolygon = JSON.parse(JSON.stringify(this.state.exportedBounds.getLatLngs()));
        }
    }

    handleMove = () => {
        if (this.initialCenter) {
            const newCenter = this.props.map.getCenter();
            const latDiff = newCenter.lat - this.initialCenter.lat;
            const lngDiff = newCenter.lng - this.initialCenter.lng;
            const newLatLngs = [
                this.initialPolygon[0],
                this.initialPolygon[1].map(latLng => ({
                    lat: latLng.lat + latDiff,
                    lng: latLng.lng + lngDiff
                }))
            ];
            this.state.exportedBounds.setLatLngs(newLatLngs);
        }
    }

    handleChange = (_, { name, value }) => this.setState({ [name]: value });
    handleCheckboxChange = (_, { name, checked }) => this.setState({ [name]: checked }, () => {
        if (name === 'isPrinting' && !checked) {
            this.props.map.setMinZoom(3);
            delete this.props.map.options.maxZoom;
            if (this.state.exportedBounds) this.props.map.removeLayer(this.state.exportedBounds);
            this.areDistancesValid();
        }
    });
    handleFormatChange = (_, { name, value }) => this.setState(prevState => ({ formats: { ...prevState.formats, [name.replace('Format', '')]: value } }));
    handleTitleChange = (_, { name, value }) => this.setState(prevState => ({ titles: { ...prevState.titles, [name.replace('Title', '')]: value } }));
    handleDistanceChange = (_, { name, value }) => this.setState(prevState => ({
        distances: { ...prevState.distances, [name.replace('Distance', '')]: value },
        errors: { ...prevState.errors, distances: initialErrors.distances }
    }));
    handleScaleDisplayChange = (_, { name, checked }) => this.setState(prevState => ({ scaleDisplay: { ...prevState.scaleDisplay, [name]: checked } }));

    handleSubmit = () => {
        const { captureMode, titles, distances, showLegend, showSurroundings, referencesToShow, isPrinting, isLandscape, exportedBounds } = this.state;
        let { formats, scaleDisplay } = this.state;

        if (captureMode === 'screenshot')
            this.props.takeRawScreenshot({ format: formats.file, titles });
        else {
            if (!this.areDistancesValid()) return;

            let scale = { x: 1, y: 1 }, overflowZoom, paperFormat, center;

            const initialBounds = this.props.map.getBounds();

            if (isPrinting) {
                if (exportedBounds) {
                    center = L.polygon([exportedBounds.getLatLngs()[1]]).getBounds().getCenter();
                    this.props.map.removeLayer(exportedBounds);
                }

                paperFormat = paperFormats.find(paperFormat => paperFormat.id === formats.paper);
                formats = { ...formats, paper: paperFormat };

                // Calcul de l'échelle dans son forme réduite pour affichage (Ex: 2/400 => 1/200)
                const cd = gcd(distances.map, distances.actual);
                scaleDisplay = { ...scaleDisplay, values: [distances.map / cd, distances.actual / cd] };

                // On détermine le niveau de zoom minimal & on y ajoute 2 pour une meilleure qualité d'images
                const distanceToDisplay = this.getDistanceToDisplay(paperFormat, isLandscape, distances.map, distances.actual);
                overflowZoom = this.getZoomLevels(distanceToDisplay).overflowZoom;
                let usedZoom = overflowZoom + 2;
                if (usedZoom > 20) usedZoom = 20;

                this.props.map.setMinZoom(3);
                delete this.props.map.options.maxZoom;
                this.props.map.setZoom(usedZoom, { animate: false });
                scale = this.getScale(distanceToDisplay);
            }

            this.setState({ isLoading: true }, () => {
                this.props.hideForm(false);
                this.props.takeScreenshot({
                    formats, titles, isLandscape, overflowZoom, scale, scaleDisplay: isPrinting && scaleDisplay,
                    showLegend, showSurroundings, referencesToShow, center, showScreenshotEditor: true
                }).then(() => {
                    this.props.map.fitBounds(initialBounds, { animate: false });
                    if (this.initialMaxBounds) this.props.map.setMaxBounds(this.initialMaxBounds);
                });
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        project: state.project,
        isToolbarExpanded: state.isToolbarExpanded,
        isDarkTheme: state.isDarkTheme,
    };
}

export default withOrientationChange(connect(mapStateToProps)(ScreenshotForm));