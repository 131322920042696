import AppSettings from "../AppSettings";
// Librairies
import Dexie from 'dexie';
import Axios from "axios";
import { v4 as uuidv4 } from 'uuid';
// Utils
import FormattersUtil from "./FormattersUtil";
import ActionsUtil from "./ActionsUtil";

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();
const DYNAMIC_CACHE_SIZE = 200;

export const staticCacheName = `static-${AppSettings.getAppVersion()}`;
export const staticContent = [
    '/favicon.ico',
    '/mobile.css',
    '/computer.css',
    '/mobile-landscape.css',
    '/tablet.css',
    '/fullscreen-modal.css',
    '/rdg-override.css',
    '/darktheme.css',
    '/manifest.json',
    '/logo192.png',
    '/measure-white.png',
    '/measure-black.png',
    '/hide-ui.css',
    `${BACKEND_ENDPOINT}subscriptions`,
    `${BACKEND_ENDPOINT}projects/mapAreas`,
    `${BACKEND_ENDPOINT}projects/municipalities`
];

export const dynamicCacheName = `dynamic-${AppSettings.getAppVersion()}`;
export const dynamicContent = [
    `${BACKEND_ENDPOINT}trees/history/`,
    `${BACKEND_ENDPOINT}trees/isMaxReached/`,
    `${BACKEND_ENDPOINT}greenSpaces/history/`,
    `${BACKEND_ENDPOINT}greenSpaces/isMaxReached/`,
    `${BACKEND_ENDPOINT}furnitures/history/`,
    `${BACKEND_ENDPOINT}furnitures/isMaxReached/`,
    `${BACKEND_ENDPOINT}actions/isMaxReached/`,
    `${BACKEND_ENDPOINT}projects/userBaseProjects/`,
    `${BACKEND_ENDPOINT}projects/linkedElements/`,
    `${BACKEND_ENDPOINT}actions/projectActions/`,
    `${BACKEND_ENDPOINT}filters/`,
    `${BACKEND_ENDPOINT}customCharts/`,
    `${BACKEND_ENDPOINT}bookmarks/`,
    `${BACKEND_ENDPOINT}subscriptions/userSubscriptions/`,
    `${BACKEND_ENDPOINT}backgroundImages/`,
    `${BACKEND_ENDPOINT}customFields/`,
    `${BACKEND_ENDPOINT}statistics/`,
    `${BACKEND_ENDPOINT}projects/projections/`,
    `${BACKEND_ENDPOINT}formulas/`,
    `${BACKEND_ENDPOINT}statistics/`,
    `${BACKEND_ENDPOINT}customFields/`,
    `${BACKEND_ENDPOINT}customFields/projects/`,
    `${BACKEND_ENDPOINT}priceLists/`,
    `${BACKEND_ENDPOINT}priceLists/projects/`
];

export const offlineRequests = [
    { url: `${BACKEND_ENDPOINT}trees/`, methods: ['POST', 'PUT', 'DELETE'] },
    { url: `${BACKEND_ENDPOINT}trees/bulk/`, methods: ['POST', 'PUT'] },
    { url: `${BACKEND_ENDPOINT}greenSpaces/`, methods: ['POST', 'PUT', 'DELETE'] },
    { url: `${BACKEND_ENDPOINT}greenSpaces/bulk/`, methods: ['POST', 'PUT'] },
    { url: `${BACKEND_ENDPOINT}furnitures/`, methods: ['POST', 'PUT', 'DELETE'] },
    { url: `${BACKEND_ENDPOINT}furnitures/bulk/`, methods: ['POST', 'PUT'] },
    { url: `${BACKEND_ENDPOINT}markers/`, methods: ['POST', 'PUT', 'DELETE'] },
    { url: `${BACKEND_ENDPOINT}markers/bulk/`, methods: ['PUT'] },
    { url: `${BACKEND_ENDPOINT}stations/`, methods: ['POST', 'PUT', 'DELETE'] },
    { url: `${BACKEND_ENDPOINT}stations/bulk/`, methods: ['PUT'] },
    { url: `${BACKEND_ENDPOINT}projects/tags/`, methods: ['POST'] },
    { url: `${BACKEND_ENDPOINT}projects/linkedElements/`, methods: ['POST', 'DELETE'] },
    {
        url: `${BACKEND_ENDPOINT}actions/`, methods: ['POST', 'PUT', 'DELETE'],
        tempIdPaths: { 'POST': ['id', 'projectActionElements/projectActionId', 'projectActionElements/projectActionElementRecurrences/id'], 'PUT': ['paeToAdd/projectActionElementRecurrences/id'] },
        customProcess: {
            'POST': (requestData) => {
                const { id, projectActionElements } = requestData;
                if (projectActionElements)
                    projectActionElements.filter(pae => !pae.projectActionId || typeof pae.projectActionId === 'string').forEach(pae => {
                        pae.projectActionId = id;
                        pae.projectActionElementRecurrences = ActionsUtil.createOfflineRecurrences(requestData);
                    });
            },
            'PUT': (requestData) => {
                const { projectAction, paeToAdd } = requestData;
                if (paeToAdd)
                    paeToAdd.filter(pae => !pae.projectActionId || typeof pae.projectActionId === 'string').forEach(pae => {
                        pae.projectActionId = projectAction.id;
                        pae.projectActionElementRecurrences = ActionsUtil.createOfflineRecurrences(projectAction);
                    });
            }
        },
    },
    { url: `${BACKEND_ENDPOINT}actions/updateRecurrence/`, methods: ['PUT'], tempIdPaths: { 'POST': ['id'] } },
    { url: `${BACKEND_ENDPOINT}backgroundImages/`, methods: ['PUT', 'DELETE'] },
    { url: `${BACKEND_ENDPOINT}backgroundImages/bulk/`, methods: ['PUT'] },
    { url: `${BACKEND_ENDPOINT}fileInfos/`, methods: ['POST', 'PUT', 'DELETE'], tempIdPaths: { 'POST': ['id'] } }
];

export const ignoredRequests = [
    `${BACKEND_ENDPOINT}backgroundImages/convertRasterToPng/`,
    `${BACKEND_ENDPOINT}files/getImportMapping/trees/`,
    `${BACKEND_ENDPOINT}files/getImportMapping/greenSpaces/`,
    `${BACKEND_ENDPOINT}files/getImportMapping/furnitures/`,
    `${BACKEND_ENDPOINT}files/getImportMapping/stations/`,
    `${BACKEND_ENDPOINT}files/getImportSample/trees/`,
    `${BACKEND_ENDPOINT}files/getImportSample/greenSpaces/`,
    `${BACKEND_ENDPOINT}files/getImportSample/furnitures/`,
    `${BACKEND_ENDPOINT}files/getImportSample/stations/`,
    `${BACKEND_ENDPOINT}files/importTreesInProject/`,
    `${BACKEND_ENDPOINT}files/importGreenSpacesInProject/`,
    `${BACKEND_ENDPOINT}files/importFurnituresInProject/`,
    `${BACKEND_ENDPOINT}files/importStationsInProject/`,
    `${BACKEND_ENDPOINT}files/importPhotosInProject/`,
    `${BACKEND_ENDPOINT}fileInfos/`
];

export default class OfflineUtil {
    static getIndexedDb = () => {
        const indexedDB = new Dexie('PWA-DB'); // IndexedDB
        indexedDB.version(1).stores({
            requests: '++id,method,headers,url,data', // Requêtes à effectuer au retour de la connexion
            guidLinks: 'guid,id,type' // Liens entre les GUID locaux et les ID réels
        });
        return indexedDB;
    }

    static deleteGuidLinks = () => {
        const db = this.getIndexedDb();
        return db.requests.count().then(count => { // On clear les liens GUID - ID si plus aucune requête n'est dans l'IndexedDB
            if (count < 1) return db.guidLinks.clear();
            else return new Promise(resolve => resolve());
        });
    }

    static handleRequestWithGuid = (db, request, guid, type) => {
        return new Promise((resolve, reject) => {
            Axios(request).then(response => {
                if (response.data && !isNaN(response.data.id)) {
                    const id = response.data.id; // On récupère l'ID réel généré par EF
                    db.guidLinks.add({ guid, id: id, type });
                }
                resolve(response.data);
            }).catch(error => reject(error));
        });
    }

    static modifyDynamicCache(url, processBody) {
        if (process.env.NODE_ENV === 'production')
            return caches.match(url).then(cacheRes => {
                if (cacheRes) {
                    return cacheRes.json().then(response => {
                        const newCacheRes = processBody(response);
                        return caches.open(dynamicCacheName).then(cache => {
                            return cache.put(url,
                                new Response(JSON.stringify(newCacheRes), {
                                    status: cacheRes.status,
                                    statusText: cacheRes.statusText,
                                    headers: cacheRes.headers
                                })
                            );
                        });
                    });
                } else if ([`${BACKEND_ENDPOINT}projects/userBaseProjects/`, `${BACKEND_ENDPOINT}projects/linkedElements/`, `${BACKEND_ENDPOINT}actions/projectActions/`].includes(FormattersUtil.getBaseURL(url))) {
                    const newCacheRes = processBody([]);
                    return caches.open(dynamicCacheName).then(cache => {
                        return cache.put(url,
                            new Response(JSON.stringify(newCacheRes), {
                                status: 200,
                                statusText: 'OK',
                            })
                        ).then(() => this.limitCacheSize(0));
                    });
                }
            });
        else return Promise.resolve();
    }

    static limitCacheSize = (index) => {
        caches.open(dynamicCacheName).then(cache => {
            cache.keys().then(keys => {
                if (keys.length > DYNAMIC_CACHE_SIZE) {
                    if (keys[index].url.includes(`${BACKEND_ENDPOINT}trees/isMaxReached/`)) {
                        index++;
                        this.limitCacheSize(index);
                    } else cache.delete(keys[index]).then(this.limitCacheSize(index));
                }
            });
        });
    };

    static getValueInDynamicCache(url) {
        return caches.match(url).then(cacheRes => {
            if (cacheRes) {
                return cacheRes.json().then(response => {
                    return response;
                });
            }
            return;
        }).catch(() => { return; });
    }

    static async deleteOldCaches() {
        return caches.keys().then(keys => { // Pour chacune des caches
            return Promise.all(keys
                .filter(key => key !== staticCacheName && key !== dynamicCacheName && key !== 'workbox-precache-v2-' + AppSettings.getAppVersion()) // Si la cache ne correspond pas aux nom des caches actuelles
                .map(key => caches.delete(key)) // On supprime la cache
            );
        });
    }

    static updateProjectView(id, key, value) {
        const projectsViewInCache = localStorage.getItem('projectsView');
        const projectsView = projectsViewInCache ? JSON.parse(projectsViewInCache) : [];
        const index = projectsView.findIndex(view => view.id === id);
        let projectView = index !== -1
            ? projectsView[index]
            : { id }
        projectView[key] = value;
        if (index === -1) projectsView.push(projectView);
        localStorage.setItem('projectsView', JSON.stringify(projectsView));
    }

    static getProjectViewValue(id, key) {
        const projectsViewInCache = localStorage.getItem('projectsView');
        const projectsView = projectsViewInCache ? JSON.parse(projectsViewInCache) : [];
        const index = projectsView.findIndex(view => view.id === id);
        if (index === -1) return null;
        return projectsView[index][key];
    }

    static removeTempIds(requestData, tempIdPaths) {
        const tempIds = [];

        const tryRemove = (properties, remainingPaths, tempIds) => {
            const property = remainingPaths.shift();
            if (!remainingPaths.length) {
                if (typeof properties[property] === 'string' || properties[property] instanceof String) {
                    tempIds.push(properties[property]);
                    delete properties[property];
                } else tempIds.push(null);
            } else if (properties[property]) {
                tempIds.push([]);
                properties[property].forEach(element => tryRemove(element, [...remainingPaths], tempIds.at(-1)));
            }
        };

        tempIdPaths.forEach(path => tryRemove(requestData, path.split('/'), tempIds));
        return tempIds;
    }

    static putTempIds(requestData, tempIdPaths, tempIds) {
        const tryRemove = (properties, remainingPaths, tempIds) => {
            const property = remainingPaths.shift();
            if (!remainingPaths.length) {
                if (tempIds && !Array.isArray(tempIds)) properties[property] = tempIds;
                else if (!properties[property]) properties[property] = uuidv4();
            } else if (properties[property]) properties[property].forEach((element, index) => tryRemove(element, [...remainingPaths], tempIds?.[index]));
        };

        tempIdPaths.forEach((path, index) => tryRemove(requestData, path.split('/'), tempIds?.[index]));
    }

    static async isOnline() {
        if (!window.navigator.onLine) return false;
        const url = new URL(window.location.origin);
        url.searchParams.set('rand', Math.random().toString(36).substring(2, 15));

        try {
            const response = await fetch(url.toString(), { method: 'HEAD' });
            return response.ok;
        } catch { return false; }
    }
}