import React, { Component } from 'react';
// Composants
import { Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
// Librairies
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
import i18n from '../../locales/i18n';
// Ressources
import logoMonoWhite from '../../resources/images/logo-mono-white.jpg';
// Services
import UsersService from '../../services/UsersService';

class ConfirmEmail extends Component {
    state = {
        success: false,
        error: false
    };

    render() {
        const { success, error } = this.state;
        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' className='text-light' textAlign='center'>
                        <Image style={{ borderRadius: '30px', marginTop: 0, marginRight: '3px' }} src={logoMonoWhite} /> {i18n.t("Activation du compte")}
                    </Header>
                    <Segment>
                        {success
                            ? <Message
                                success style={{ textAlign: 'left' }} hidden={!success}
                                header={i18n.t("Activation réussie")} content={i18n.t("Votre compte a bien été activé. Nous vous connectons...")}
                            />
                            : <Message
                                error style={{ textAlign: 'left' }} hidden={!error}
                                header={i18n.t("Activation échouée")} content={i18n.t("Ce compte n'existe pas ou a déjà été activé.")}
                            />}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }

    componentDidMount = () => {
        this.props.setProject(null);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const userId = urlParams.get('userId');
        const token = urlParams.get('token')?.replace(/ /g, '+');

        if (userId && token) {
            this.props.changeActiveItem('');
            this.confirmEmail(userId, token);
        } else { // On redirige vers le composant map
            this.props.changeActiveItem('home');
            this.props.history.push('/');
        }
    }

    componentDidUpdate = () => { // Si l'utilisateur est déjà connecté, on l'empêche d'accéder au composant login
        if (this.props.logged) {
            this.props.changeActiveItem('home');
            this.props.history.push('/');
        }
    }

    confirmEmail = (userId, token) => {
        const confirmEmail = {
            userId: userId,
            token: token
        };

        UsersService.confirmEmail(confirmEmail).then(response => {
            if (response?.token) {
                this.setState({ success: true });
                setTimeout(() => {
                    this.props.logUser(response.token);
                    this.props.changeActiveItem('home');
                    this.props.history.push('/');
                }, 4000);
            } else this.setState({ error: true });
        });
    }
}

const mapDispatchToProps = {
    setProject
};

export default connect(null, mapDispatchToProps)(ConfirmEmail);