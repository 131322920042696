// Librairies
import tinycolor from 'tinycolor2';
import i18n from '../locales/i18n';
import AppSettings from '../AppSettings';

export default class ThemesUtil {
    static getColors() {
        return [
            'var(--primary-100)',
            'var(--secondary-100)',
            'var(--red-100)',
            'var(--green-100)',
            'var(--blue-100)',
            'var(--yellow-100)',
            'var(--orange-100)',
            'var(--pink-100)',
            'var(--purple-100)',
            'var(--brown-100)'
        ];
    }

    static getColorNames(withLabels = false) {
        const colorNames = [
            { id: 'primary', label: i18n.t("Primaire") }, { id: 'secondary', label: i18n.t("Secondaire") }, { id: 'black', label: i18n.t("Noir") }, { id: 'grey', label: i18n.t("Gris") },
            { id: 'white', label: i18n.t("Blanc") }, { id: 'red', label: i18n.t("Rouge") }, { id: 'green', label: i18n.t("Vert") }, { id: 'blue', label: i18n.t("Bleu") },
            { id: 'yellow', label: i18n.t("Jaune") }, { id: 'orange', label: i18n.t("Orange") }, { id: 'pink', label: i18n.t("Rose") }, { id: 'purple', label: i18n.t("Violet") },
            { id: 'brown', label: i18n.t("Brun") }
        ];
        return withLabels ? colorNames : colorNames.map(color => color.id);
    }

    static getThemeColors(theme) {
        const colorNames = this.getColorNames();
        let colors = {};
        colorNames.forEach(name => colors[name] = theme[name]);
        return colors;
    }

    static applyTheme(theme) {
        if (!theme) theme = {};
        if (AppSettings.isUrbasenseUrl()) theme = this.getUrbasenseTheme();
        const colorNames = this.getColorNames();
        colorNames.forEach(name => {
            const baseColor = theme[name] || getComputedStyle(document.documentElement).getPropertyValue(`--${name}-100`);
            const colors = this.getColorPalette(name, baseColor);
            colors.forEach(color => document.body.style.setProperty(`--${name}-${color.brightness}`, color.value));

            this.updateElementsColor(name, baseColor);
        });
    }

    static getColorPalette = (name, baseColor, min = 10, max = 120, step = 10) => {
        const colors = [];
        for (let i = min; i <= max; i += step) {
            const color = i < 100 || (i > 100 && name === 'white') ? tinycolor(baseColor).brighten((name === 'white' ? (i - 100) : (100 - i)) / 2).toString()
                : i > 100 || (i < 100 && name === 'white') ? tinycolor(baseColor).darken((name === 'white' ? (100 - 10) : (i - 100)) / 2).toString()
                    : baseColor;
            colors.push({ brightness: i, value: color });
        }
        return colors;
    }

    static updateElementsColor(name, baseColor) {
        const isDark = tinycolor(baseColor).getBrightness() < 180;

        const buttonSelector = ['primary', 'secondary'].includes(name) ? `.button--${name}` : `.ui.button.${name}`;
        this.changeStylesheetRule(buttonSelector, 'color', isDark ? 'white' : 'black');
        this.changeStylesheetRule(`${buttonSelector}:hover:not(:active)`, 'color', isDark ? 'white' : 'black');
        this.changeStylesheetRule(`${buttonSelector}:active`, 'color', isDark ? 'white' : 'black');
        this.changeStylesheetRule(`${buttonSelector}:focus`, 'color', isDark ? 'white' : 'black');
        this.changeStylesheetRule(`${buttonSelector}.active`, 'color', isDark ? 'white' : 'black');
        this.changeStylesheetRule(`${buttonSelector} > i.icon`, 'color', isDark ? 'white' : 'black');

        const labelSelector = ['primary', 'secondary'].includes(name) ? `.label--${name}` : `.ui.label.${name}`;
        this.changeStylesheetRule(labelSelector, 'color', isDark ? 'white' : 'black');
    }

    static changeStylesheetRule(selector, property, value) {
        const stylesheet = document.getElementById('branding-style').sheet;
        if (!stylesheet) return;

        selector = selector.toLowerCase();
        property = property.toLowerCase();
        value = value.toLowerCase();

        for (let i = 0; i < stylesheet.cssRules.length; i++) {
            let rule = stylesheet.cssRules[i];
            if (rule.selectorText === selector) {
                rule.style.setProperty(property, value, 'important');
                return;
            }
        }

        stylesheet.insertRule(selector + ' { ' + property + ': ' + value + ' !important; }', 0);
    }

    static getUrbasenseTheme() {
        return {
            primary: 'rgba(80, 195, 116, 1)',
            secondary: 'rgba(80, 195, 116, 1)',
            black: null,
            grey: null,
            white: null,
            red: 'rgba(204, 69, 69, 1)',
            green: null,
            blue: null,
            yellow: 'rgba(206, 171, 23, 1)',
            orange: null,
            pink: null,
            purple: null,
            brown: null
        };
    }
}
