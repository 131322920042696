import React, { Component } from 'react';
// Composants
import Dropzone from 'react-dropzone';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
// Services
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';

const MAX_SIZE = 524288000; // 500mo

class ImportPhotosDropZone extends Component {
    state = {
        maxItems: 1,
        file: null,
        dropZone: {
            isDragAccept: false,
            isDragActive: false,
            isDragReject: false,
            isTooManyItems: false,
            message: ''
        }
    }

    render() {
        const { dropZone } = this.state;
        let dropzoneStyle = StylesUtil.getDropZoneStyle(dropZone);
        dropzoneStyle.height = '100%';
        dropzoneStyle.justifyContent = 'center';
        if (!this.props.isOnline) dropzoneStyle.borderColor = 'grey';

        return (
            <>
                <Dropzone accept={{ '': ['.zip'] }} useFsAccessApi={false} onDrop={this.handleDrop} disabled={!this.props.isOnline}>
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ flex: 1 }}>
                            <div {...getRootProps()} style={dropzoneStyle}>
                                <input {...getInputProps()} />
                                {dropZone.isDragAccept && (<p>{dropZone.message}</p>)}
                                {dropZone.isDragReject && (<p>{dropZone.message}</p>)}
                                {dropZone.isTooManyItems && (<p>{dropZone.message}</p>)}
                                {!dropZone.isDragActive && (<p>{i18n.t("Glissez-déposez ou cliquez pour importer un fichier")} (.zip)</p>)}
                            </div>
                        </section>
                    )}
                </Dropzone>
            </>
        )
    }

    handleDrop = async (files) => {
        if (files.length <= this.state.maxItems && files[0].size <= MAX_SIZE) {
            this.props.handleConfigChange(null, { name: 'file', value: files[0] });
            this.setState(prevState => ({
                file: files[0],
                dropZone: {
                    ...prevState.dropZone,
                    isDragAccept: false,
                    isDragReject: false,
                    isDragActive: false,
                    isTooManyItems: false
                }
            }));
        } else if (files[0].size > MAX_SIZE)
            this.setState(prevState => ({
                dropZone: {
                    ...prevState.dropZone,
                    isDragAccept: false,
                    isDragReject: true,
                    isDragActive: false,
                    isTooManyItems: false,
                    message: i18n.t("Impossible de sélectionner un fichier de plus de {{size}}", { size: FormattersUtil.numberPrettyBytesSI(MAX_SIZE) })
                }
            }));
        else
            this.setState(prevState => ({
                dropZone: {
                    ...prevState.dropZone,
                    isDragAccept: false,
                    isDragReject: false,
                    isDragActive: false,
                    isTooManyItems: true,
                    message: this.state.maxItems === 1
                        ? i18n.t("Vous ne pouvez pas mettre plus de 1 fichier")
                        : i18n.t("Vous ne pouvez pas mettre plus de {{count}} fichiers", { count: this.state.maxItems })
                }
            }));
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(ImportPhotosDropZone);