import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faPencil, faRotateLeft, faTrash, faLocationCrosshairs, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
// Librairies
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import { isMobileOnly, isMobile } from 'react-device-detect';
import i18n from '../../../locales/i18n';
// Semantic UI
import { Input, Form, Select, Button, Segment, Popup, Label, Dimmer, Grid, Message } from 'semantic-ui-react';
// Redux
import { connect } from 'react-redux';
import { setFilterFormState, setFilterLists } from '../../../actionCreators/componentsActions';
import { setCurrentAction } from '../../../actionCreators/appActions';
// Services
import FiltersService from '../../../services/FiltersService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import DatesUtil from '../../../utils/DatesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import GreenSpacesUtil from '../../../utils/GreenSpacesUtil';
import ActionsUtil from '../../../utils/ActionsUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import DatePicker from '../../Utils/DatePicker';
import DatePickerWithPeriod from '../../Utils/DatePickerWithPeriod';
import RightsUtil from '../../../utils/RightsUtil';

const treeOrgans = [
    { organ: 'root', label: i18n.t("racines") },
    { organ: 'collar', label: i18n.t("collet") },
    { organ: 'trunk', label: i18n.t("tronc") },
    { organ: 'branch', label: i18n.t("branches") },
    { organ: 'leaf', label: i18n.t("feuilles") }
];

const baseFields = {
    category: { type: 'list' },
    customReference: { type: 'text' },
    place: { type: 'text' },
    projectReference: { type: 'number', step: 1 },
    carbonStock: { type: 'number', categories: ['Arbre', 'Espace vert'] },
    nbPhotos: { type: 'number', step: 1 },
    nbFiles: { type: 'number', step: 1 },
    actionId: { type: 'list', isMultiple: true },
    actionDate: { type: 'date' },
    projectActionId: { type: 'list', isMultiple: true },
    localisation: { type: 'button' },
    station: { type: 'list' },
    creationDate: { type: 'date' },
    modificationDate: { type: 'date' },
    tagId: { type: 'list', isMultiple: true },
    /* Arbres */
    // Booléens
    isEmpty: { type: 'boolean', category: 'Arbre' },
    isDead: { type: 'boolean', category: 'Arbre' },
    isStump: { type: 'boolean', category: 'Arbre' },
    isIndexed: { type: 'boolean', category: 'Arbre' },
    isRemarkable: { type: 'boolean', category: 'Arbre' },
    isFruit: { type: 'boolean', category: 'Arbre' },
    // Textes
    observation: { type: 'text', categories: ['Arbre', 'Espace verts'] },
    // Dates
    plantingDate: { type: 'date', category: 'Arbre' },
    // Nombres
    numberOfTrunks: { type: 'number', category: 'Arbre' },
    trunkHeight: { type: 'number', category: 'Arbre' },
    height: { type: 'number', category: 'Arbre' },
    circumference: { type: 'number', category: 'Arbre' },
    crownDiameter: { type: 'number', category: 'Arbre' },
    fruitProduction: { type: 'number', category: 'Arbre' },
    age: { type: 'number', category: 'Arbre', step: 1 },
    // Listes déroulantes
    toCutDown: { type: 'list', category: 'Arbre' },
    vernacularName: { type: 'list', category: 'Arbre' },
    gender: { type: 'list', category: 'Arbre' },
    species: { type: 'list', category: 'Arbre' },
    cultivar: { type: 'list', category: 'Arbre' },
    treePortId: { type: 'list', category: 'Arbre' },
    coverTypeId: { type: 'list', category: 'Arbre' },
    vigorId: { type: 'list', category: 'Arbre' },
    riskId: { type: 'list', category: 'Arbre' },
    tippingRiskId: { type: 'list', category: 'Arbre' },
    organCaliberId: { type: 'list', category: 'Arbre' },
    targetId: { type: 'list', category: 'Arbre' },
    healthReviewId: { type: 'list', category: 'Arbre', isNumeric: true },
    ontogenicStageId: { type: 'list', category: 'Arbre', isNumeric: true },
    plantationCoefficientId: { type: 'list', category: 'Arbre', isNumeric: true },
    situationCoefficientId: { type: 'list', category: 'Arbre', isNumeric: true },
    patrimonialCoefficientId: { type: 'list', category: 'Arbre', isNumeric: true },
    interactionId: { type: 'list', category: 'Arbre', isMultiple: true },
    microHabitatId: { type: 'list', category: 'Arbre', isMultiple: true },
    ...treeOrgans.reduce((prevValue, { organ }) => ({
        ...prevValue,
        [`${organ}SymptomId`]: { type: 'list', category: 'Arbre', isMultiple: true },
        [`${organ}PathogenId`]: { type: 'list', category: 'Arbre', isMultiple: true },
        [`${organ}PestId`]: { type: 'list', category: 'Arbre', isMultiple: true },
        [`${organ}EpiphyteId`]: ['trunk', 'branch'].includes(organ) && { type: 'list', category: 'Arbre', isMultiple: true }
    }), {}),
    plantationTypeId: { type: 'list', category: 'Arbre' },
    /* Espaces verts */
    // Booléens
    isTreeBase: { type: 'boolean', category: 'Espace vert' },
    // Textes
    placeExtra: { type: 'text', category: 'Espace vert' },
    detailedComposition: { type: 'text', category: 'Espace vert' },
    // Nombres
    surface: { type: 'number', category: 'Espace vert' },
    annualMaintenanceFrequency: { type: 'number', category: 'Espace vert', step: 1 },
    nbTrees: { type: 'number', category: 'Espace vert' },
    density: { type: 'number', category: 'Espace vert' },
    distanceBetweenTrunks: { type: 'number', category: 'Espace vert' },
    averageHeight: { type: 'number', category: 'Espace vert' },
    averageCircumference: { type: 'number', category: 'Espace vert' },
    averageCrownDiameter: { type: 'number', category: 'Espace vert' },
    // Listes déroulantes
    spaceFunctionId: { type: 'list', category: 'Espace vert' },
    spaceTypeId: { type: 'list', category: 'Espace vert' },
    dominantCompositionId: { type: 'list', category: 'Espace vert' },
    runoffCoefficientId: { type: 'list', category: 'Espace vert' },
    managementClassId: { type: 'list', category: 'Espace vert', isNumeric: true },
    dominantVernacularName: { type: 'list', category: 'Espace vert' },
    dominantGender: { type: 'list', category: 'Espace vert' },
    dominantSpecies: { type: 'list', category: 'Espace vert' },
    dominantCultivar: { type: 'list', category: 'Espace vert' },
    averageHealthReviewId: { type: 'list', category: 'Espace vert', isNumeric: true },
    /* Mobilier */
    // Textes
    description: { type: 'text', category: 'Mobilier' },
    // Listes déroulantes
    conditionId: { type: 'list', category: 'Mobilier' },
    typeId: { type: 'list', category: 'Mobilier' }
};

class FilterForm extends Component {
    state = {
        properties: [],
        fieldNumber: 1,
        names: [],
        conditions: [],
        values: [],
        vernacularNames: [],
        genders: [],
        species: [],
        cultivars: [],
        treePorts: [],
        coverTypes: [],
        vigors: [],
        healthReviews: [],
        ontogenicStages: [],
        risks: [],
        tippingRisks: [],
        organCalibers: [],
        targets: [],
        plantationTypes: [],
        plantationCoefficients: [],
        situationCoefficients: [],
        patrimonialCoefficients: [],
        interactions: [],
        microHabitats: [],
        rootSymptoms: [],
        collarSymptoms: [],
        trunkSymptoms: [],
        branchSymptoms: [],
        leafSymptoms: [],
        pathogens: [],
        pests: [],
        epiphytes: [],
        spaceFunctions: [],
        spaceTypes: [],
        dominantCompositions: [],
        managementClasses: [],
        runoffCoefficients: [],
        furnitureConditions: [],
        furnitureTypes: [],
        projectTags: [],
        projectActions: [],
        filterLists: [],
        filterListToRemove: 0,
        newFilterName: '',
        isSaving: false,
        isDeleting: false,
        filterToDelete: -1,
        customFieldsToRender: []
    };

    render() {
        const { names, newFilterName, isSaving, filterListToRemove, isDeleting } = this.state;
        const { project, loginAsData, isOnline } = this.props;

        return (
            <Form className='modal-content' style={{ padding: '5px' }} onSubmit={this.handleSubmit}>
                <div className='modal-content-header'>
                    <div style={{ overflowX: 'hidden', width: '100%' }}>{this.renderFilterLists()}</div>
                    <Label color='grey' content={i18n.t("Filtres différents = ET | Filtres identiques = OU")} style={{ float: 'right', marginTop: '5px' }} />
                </div>
                <div className='modal-content-body'>
                    {filterListToRemove > 0 &&
                        <Dimmer active onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ filterListToRemove: 0 }); }}>
                            <Grid>
                                <Grid.Row verticalAlign='middle'>
                                    <Grid.Column textAlign='center'>
                                        <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                            <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                            <Message.Content style={{ marginTop: '10px' }}>
                                                <div style={{ marginBottom: '10px' }}>
                                                    {i18n.t("Êtes-vous certain de vouloir supprimer ce filtre ?")}
                                                </div>
                                                <Button color='grey' disabled={isDeleting} onClick={() => this.setState({ filterListToRemove: 0 })}>
                                                    <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                </Button>
                                                <Button color='red' disabled={!isOnline || isDeleting} loading={isDeleting} onClick={() => this.deleteFilterList(filterListToRemove)}>
                                                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                </Button>
                                            </Message.Content>
                                        </Message>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Dimmer>}
                    {new Cookies().get('token') && !loginAsData?.readOnly && isMobileOnly &&
                        <Input
                            placeholder={i18n.t("Nom du filtre")} value={newFilterName || ''} style={{ marginBottom: '5px', width: 'calc(100% - 39px)' }} action
                            onChange={(_, { value }) => this.setState({ newFilterName: value.length > 30 ? value.substring(0, 30) : value })}
                        >
                            <input />
                            <Button
                                title={i18n.t("Sauvegarder le filtre")} icon='save' color='green'
                                disabled={isSaving} loading={isSaving} onClick={this.saveFilterList}
                            />
                        </Input>}
                    {this.renderFields()}
                </div>
                <div className='modal-content-footer' style={{ marginTop: '10px' }}>
                    {isMobile ?
                        <>
                            <Button className='form-button' type='button' color='blue' floated='right' onClick={() => this.resetFilters()}>
                                <FontAwesomeIcon icon={faBan} style={{ marginRight: '10px' }} />{i18n.t("Réinitialiser")}
                            </Button>
                            <Button.Group style={{ width: '100%' }}>
                                <Button
                                    type='button' className='form-button' color='red' style={{ width: '50%' }}
                                    onClick={() => this.props.hideForm(false)}
                                >
                                    <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button className='form-button' type='submit' color='green' disabled={!this.props.isOnline} style={{ width: '50%' }} >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </Button.Group>
                        </>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={() => this.props.hideForm(false)}>
                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' type='submit' color='green' disabled={!this.props.isOnline}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                            <Button type='button' className='form-button' color='blue' floated='right' disabled={names?.length === 0} onClick={() => this.resetFilters()}>
                                <FontAwesomeIcon icon={faBan} style={{ marginRight: '10px' }} />{i18n.t("Réinitialiser")}
                            </Button>
                        </>}
                    {RightsUtil.canWrite(this.props.rights?.filters) && new Cookies().get('token') && !loginAsData?.readOnly &&
                        !isMobileOnly && (!project || project.type === 'project') &&
                        <Input
                            placeholder={i18n.t("Nom du filtre")} value={newFilterName || ''} action
                            onChange={(_, { value }) => this.setState({ newFilterName: value.length > 30 ? value.substring(0, 30) : value })}
                        >
                            <input />
                            <Button
                                type='button' title={i18n.t("Sauvegarder le filtre")} icon='save' color='green'
                                disabled={isSaving || !this.props.isOnline} loading={isSaving} onClick={this.saveFilterList}
                            />
                        </Input>}
                </div>
            </Form>
        );
    }

    componentDidMount = () => {
        const customFieldsToRender = this.props.project ? [
            ...new Set(this.props.treesLayer.getLayers().flatMap(layer => Object.keys(layer.feature.properties.customFields || {}).map(id => Number(id)))),
            ...new Set(this.props.greenSpacesLayer.getLayers().flatMap(layer => Object.keys(layer.feature.properties.customFields || {}).map(id => Number(id)))),
            ...new Set(this.props.furnituresLayer.getLayers().flatMap(layer => Object.keys(layer.feature.properties.customFields || {}).map(id => Number(id))))
        ] : null;

        const addFilterListsCF = (filterLists) => {
            filterLists.forEach(filterList => {
                filterList.filters.forEach(filter => {
                    if (!isNaN(filter.name) && !customFieldsToRender.includes(Number(filter.name)))
                        customFieldsToRender.push(Number(filter.name));
                });
            });
        };

        if (this.props.filterFormState) {
            let { localisationRowIndex, ...state } = this.props.filterFormState;
            if (customFieldsToRender) addFilterListsCF(state.filterLists);
            if (!this.props.layer || (!localisationRowIndex && localisationRowIndex !== 0))
                this.setState({ ...state, customFieldsToRender });
            else {
                state.values[localisationRowIndex] = JSON.stringify(this.props.layer.getLatLngs()[0]);
                this.setState({ ...state, customFieldsToRender }, () => {
                    const properties = this.state.properties;
                    const index = properties.findIndex(x => x.name === 'localisation');
                    let filterIndex = properties[index].filters.findIndex(filter => filter.id === localisationRowIndex);
                    if (filterIndex === -1)
                        properties[index].filters.push({
                            id: localisationRowIndex,
                            condition: this.state.conditions[localisationRowIndex],
                            value: this.state.values[localisationRowIndex]
                        });
                    else properties[index].filters[filterIndex] = { id: localisationRowIndex, condition: this.state.conditions[localisationRowIndex], value: this.state.values[localisationRowIndex] };
                    let fieldNumber = this.state.fieldNumber;
                    if (localisationRowIndex === fieldNumber - 1) fieldNumber++;
                    this.setState({ properties, fieldNumber });
                });
            }
            this.props.setFilterFormState(null);
        } else {
            const setInitialState = (filterLists) => {
                // On charge les listes de valeurs pour chaque propriété
                const projectTags = this.props.project?.tags || [];
                if (this.props.project && this.props.projectActions)
                    this.setState({ projectActions: this.props.projectActions });

                if (customFieldsToRender) addFilterListsCF(filterLists);

                this.setState({
                    projectTags, filterLists, customFieldsToRender,
                    vernacularNames: [...new Set(this.props.essences
                        .map(x => x.vernacularName))]
                        .filter(x => x)
                        .map(x => { return { text: x, value: x } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            var textA = a.text.toUpperCase();
                            var textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }),
                    genders: [...new Set(this.props.essences
                        .map(x => x.gender))]
                        .map(x => { return { text: x, value: x } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            var textA = a.text.toUpperCase();
                            var textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }),
                    species: [...new Set(this.props.essences
                        .filter(x => x.species)
                        .map(x => x.species))]
                        .map(x => { return { text: x, value: x } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            var textA = a.text.toUpperCase();
                            var textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }),
                    cultivars: [...new Set(this.props.essences
                        .filter(x => x.cultivar)
                        .map(x => x.cultivar))]
                        .map(x => { return { text: x, value: x } })
                        .sort((a, b) => { // On trie par ordre alphabétique
                            var textA = a.text.toUpperCase();
                            var textB = b.text.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }),
                    treePorts: this.props.treePorts.map(x => { return { text: x.label, value: x.label } }),
                    coverTypes: this.props.coverTypes.map(x => { return { text: x.label, value: x.label } }),
                    vigors: this.props.vigors.map(x => { return { text: x.label, value: x.label } }),
                    healthReviews: this.props.healthReviews.map(x => { return { text: x.value + ' (' + x.description + ')', value: x.value } }),
                    ontogenicStages: this.props.ontogenicStages.map(x => { return { text: x.value, value: x.value } }),
                    risks: this.props.risks.map(x => { return { text: x.label, value: x.label } }),
                    tippingRisks: this.props.tippingRisks.map(x => { return { text: x.label, value: x.value } }),
                    organCalibers: this.props.organCalibers.map(x => { return { text: x.label, value: x.value } }),
                    targets: this.props.targets.map(x => { return { text: x.label, value: x.value } }),
                    plantationTypes: this.props.plantationTypes.map(x => { return { text: x.label, value: x.label } }),
                    plantationCoefficients: this.props.plantationCoefficients.map(x => { return { text: x.value, value: x.value } }),
                    situationCoefficients: this.props.situationCoefficients.map(x => { return { text: x.value, value: x.value } }),
                    patrimonialCoefficients: this.props.patrimonialCoefficients.map(x => { return { text: x.value, value: x.value } }),
                    interactions: this.props.interactions.map(x => { return { text: x.label, value: x.label } }),
                    microHabitats: this.props.microHabitats.map(x => { return { text: x.label, value: x.label } }),
                    rootSymptoms: this.props.rootSymptoms.map(x => { return { text: x.label, value: x.label } }),
                    collarSymptoms: this.props.collarSymptoms.map(x => { return { text: x.label, value: x.label } }),
                    trunkSymptoms: this.props.trunkSymptoms.map(x => { return { text: x.label, value: x.label } }),
                    branchSymptoms: this.props.branchSymptoms.map(x => { return { text: x.label, value: x.label } }),
                    leafSymptoms: this.props.leafSymptoms.map(x => { return { text: x.label, value: x.label } }),
                    pathogens: this.props.pathogens.map(x => { return { text: x.label, value: x.label } }),
                    pests: this.props.pests.map(x => { return { text: x.label, value: x.label } }),
                    epiphytes: this.props.epiphytes.map(x => { return { text: x.label, value: x.label } }),
                    spaceFunctions: this.props.spaceFunctions.map(x => { return { text: x.label, value: x.label } }),
                    spaceTypes: this.props.spaceTypes.map(x => { return { text: x.label, value: x.label } }),
                    dominantCompositions: this.props.dominantCompositions.map(x => { return { text: x.label, value: x.label } }),
                    managementClasses: this.props.managementClasses.map(x => { return { text: x.value, value: x.value } }),
                    runoffCoefficients: this.props.runoffCoefficients.map(x => { return { text: GreenSpacesUtil.getRunoffCoefficientString(x), value: GreenSpacesUtil.getRunoffCoefficientString(x) } }),
                    furnitureConditions: this.props.conditions.map(x => { return { text: x.label, value: x.label } }),
                    furnitureTypes: this.props.furnitureTypes.map(x => { return { text: x.label, value: x.label } }),
                    actions: this.props.actions
                });
            }

            const loadedFilterLists = this.props.filterLists || {};
            if (loadedFilterLists['0'] && (!this.props.project || loadedFilterLists[this.props.project.id])) { // Si on a déjà chargé les graphiques globaux et du projet
                let filterLists = [...this.props.filterLists['0']];
                if (this.props.project) filterLists = [...filterLists, ...this.props.filterLists[this.props.project.id]];
                setInitialState(this.sortFilterLists(filterLists));
            } else {
                let promises = [], filterLists = [];
                if (!loadedFilterLists['0']) {
                    promises.push(new Promise(resolve => {
                        FiltersService.getFilterLists(0).then(filters => {
                            if (filters) filterLists.push(...filters);
                            resolve();
                        });
                    }));
                } else filterLists.push(...loadedFilterLists['0']);
                if (this.props.project)
                    if (!loadedFilterLists[this.props.project.id]) {
                        promises.push(new Promise(resolve => {
                            FiltersService.getFilterLists(this.props.project.id).then(filters => {
                                if (filters) filterLists.push(...filters);
                                resolve();
                            });
                        }));
                    } else filterLists.push(...loadedFilterLists[this.props.project.id]);
                Promise.all(promises).then(() => {
                    setInitialState(this.sortFilterLists(filterLists || []));
                    let filterListsToStore = { ...loadedFilterLists, 0: filterLists.filter(filterList => !filterList.projectId) };
                    if (this.props.project) filterListsToStore = { ...filterListsToStore, [this.props.project.id]: filterLists.filter(filterList => filterList.projectId === this.props.project.id) };
                    this.props.setFilterLists(filterListsToStore);
                });
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.project && JSON.stringify(prevProps.filterLists) !== JSON.stringify(this.props.filterLists))
            this.setState({ filterLists: this.sortFilterLists([...this.props.filterLists['0'], ...this.props.filterLists[this.props.project.id]]) });
    }

    renderFields = () => {
        const fields = [];
        const { names, conditions, values, fieldNumber } = this.state;
        const nameOptions = this.getNameOptions();

        for (let i = 0; i < fieldNumber; i++) {
            const name = names?.[i]; // Nom de la propriété
            const condition = conditions?.[i]; // Nom de la propriété
            const value = values?.[i]; // Valeur de la propriété
            const field = baseFields[name] || this.props.customFields.find(cf => cf.id === name);
            const valueCondition = (['=', '!='].includes(condition) || (condition === 'c' && (field?.type === 'list' && field?.isMultiple))) && field?.type !== 'boolean';

            const nameOption = nameOptions.find(option => option.value === name);
            if (!nameOption?.disabled)
                fields.push(
                    <Segment style={{ marginTop: 0, marginBottom: 0, paddingBottom: isMobileOnly ? null : 0 }} key={i}>
                        <Form.Group widths='equal'>
                            <Form.Field
                                control={Select} placeholder={i18n.t("Champs")} name={i} options={nameOptions} value={name || ''} className='E2pMtdjT'
                                selectOnBlur={false} selectOnNavigation={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                onChange={(_, { name, value }) => this.changeName(name, value)}
                            />
                            <Form.Field
                                control={Select} placeholder={i18n.t("Condition")} name={i} options={this.getConditionOptions(i)} value={condition || ''}
                                selectOnBlur={false} selectOnNavigation={false} upward={isMobileOnly && i > 0}
                                disabled={!name || this.getConditionOptions(i).length < 2}
                                onChange={(_, { name, value }) => this.changeCondition(name, value)}
                            />
                            {this.getValueOptions(name, condition) ?
                                <>
                                    {condition === '<.<' ?
                                        <Form.Field style={{ display: 'flex', gap: '10px', marginTop: 0 }}>
                                            <Form.Field
                                                control={Select} className='grow' style={{ flexGrow: 1 }} placeholder={i18n.t("Valeur min")} selectOnBlur={false} selectOnNavigation={false} search={FormattersUtil.searchList}
                                                upward={isMobileOnly && i > 0} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                                name={i} options={this.getValueOptions(name, condition) || []} value={value?.min || ''}
                                                onChange={(_, { name, value: newValue }) => this.changeValue(name, { min: newValue, max: value?.max })}
                                            />
                                            <Form.Field
                                                control={Select} className='grow' style={{ flexGrow: 1 }} placeholder={i18n.t("Valeur max")} selectOnBlur={false} selectOnNavigation={false} search={FormattersUtil.searchList}
                                                upward={isMobileOnly && i > 0} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                                name={i} options={this.getValueOptions(name, condition) || []} value={value?.max || ''}
                                                onChange={(_, { name, value: newValue }) => this.changeValue(name, { max: newValue, min: value?.min })}
                                            />
                                        </Form.Field>
                                        :
                                        <Form.Field
                                            control={Select} placeholder={i18n.t("Valeur")} name={i} options={this.getValueOptions(name, condition) || []}
                                            value={!value && value !== false
                                                ? (valueCondition ? [] : '')
                                                : !Array.isArray(value) && valueCondition
                                                    ? [value]
                                                    : (field?.type === 'boolean' ? value : value || '')}
                                            selectOnBlur={false} selectOnNavigation={false} search={FormattersUtil.searchList} upward={isMobileOnly && i > 0} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                            multiple={(['=', '!=', 'gs', 's=', 's!='].includes(condition) && field?.type !== 'boolean') || (condition === 'c' && (field?.type === 'list' && field?.isMultiple))}
                                            disabled={!condition} onChange={(_, { name, value }) => this.changeValue(name, value)}
                                        />}
                                </>
                                :
                                <>
                                    {field?.type === 'date' ?
                                        <>
                                            {condition === '<.<'
                                                ? <DatePickerWithPeriod hideLabel={true} startDate={value?.startDate} endDate={value?.endDate} forceTime={true} setDates={(startDate, endDate) => this.changeValue(i, { startDate, endDate })} className='field' />
                                                : <DatePicker name={i} value={value || ''} disabled={!condition} forceTime={true} onChange={(_, { name, value }) => this.changeValue(name, value)} />}
                                        </>
                                        :
                                        <>
                                            {name !== 'localisation' ?
                                                <>
                                                    {field?.type === 'number' ?
                                                        <>
                                                            {condition === '<.<' ?
                                                                <Form.Field style={{ display: 'flex', gap: '10px', marginTop: 0 }}>
                                                                    <Form.Field
                                                                        control={Input} className='grow' style={{ flexGrow: 1 }} placeholder={i18n.t("Valeur min")} type='number' step={field?.step || '0.1'}
                                                                        name={i} value={value?.min || ''} autoComplete='off'
                                                                        onChange={(_, { name, value: newValue }) => this.changeValue(name, { min: newValue, max: value?.max })}
                                                                    />
                                                                    <Form.Field
                                                                        control={Input} className='grow' style={{ flexGrow: 1 }} placeholder={i18n.t("Valeur max")} type='number' step={field?.step || '0.1'}
                                                                        name={i} value={value?.max || ''} autoComplete='off'
                                                                        onChange={(_, { name, value: newValue }) => this.changeValue(name, { max: newValue, min: value?.min })}
                                                                    />
                                                                </Form.Field>
                                                                :
                                                                <Form.Field
                                                                    control={Input} placeholder={i18n.t("Valeur")} type='number' step={field?.step || '0.1'}
                                                                    name={i} value={value || ''} autoComplete='off'
                                                                    disabled={!condition || ['empty', 'not_empty'].includes(condition)}
                                                                    onChange={(_, { name, value }) => this.changeValue(name, value)}
                                                                />}
                                                        </>
                                                        :
                                                        <Form.Field
                                                            control={Input} placeholder={i18n.t("Valeur")} name={i} value={value || ''} autoComplete='off'
                                                            disabled={!condition || ['empty', 'not_empty'].includes(condition)}
                                                            onChange={(_, { name, value }) => this.changeValue(name, value)}
                                                        />}
                                                </>
                                                :
                                                <Form.Field
                                                    control={Button} name={i} style={{ width: '100%' }} color='blue'
                                                    content={<span><FontAwesomeIcon icon={faPencil} style={{ marginRight: '10px' }} />{i18n.t("Dessiner la zone")}</span>}
                                                    onClick={(_, { name }) => {
                                                        this.props.setCurrentAction('drawingFilterSurroundings');
                                                        this.props.setFilterFormState({ ...this.state, localisationRowIndex: name }).then(this.props.drawFilterSurroundings);
                                                    }}
                                                />}
                                        </>}
                                </>}
                        </Form.Group>
                    </Segment>
                );
        }
        return fields.length > 0 &&
            <Segment.Group style={{ padding: 0, marginTop: '5px' }}>{fields}</Segment.Group>;
    }

    renderFilterLists = () => {
        const { filterLists } = this.state;

        if (filterLists) {
            let filterListsButtons = [];
            for (let i = 0; i < filterLists.length; i++) {
                const filterList = filterLists[i];
                filterListsButtons.push(
                    <Button.Group key={uuidv4()} style={{ marginBottom: '5px', marginRight: '5px', flexShrink: 0 }}>
                        <Button
                            className='wgHinzi1 button--secondary' type='button' title={i18n.t("Restaurer le filtre")} style={{ padding: '11px' }}
                            name={i} key={uuidv4()} content={<><FontAwesomeIcon icon={faRotateLeft} style={{ marginRight: '10px' }} />{filterList.label}</>}
                            onClick={(_, { name }) => this.restoreFilterList(name)}
                        />
                        {RightsUtil.canWrite(this.props.rights?.filters) &&
                            <Button
                                className='sJzjmP51' type='button' title={i18n.t("Supprimer le filtre")} color='red' style={{ padding: '8px', borderLeft: 'solid 1px black' }}
                                name={i} key={uuidv4()} content={<FontAwesomeIcon icon={faTrash} style={{ padding: '0 2px' }} />}
                                onClick={() => this.setState({ filterListToRemove: filterList.id })}
                            />}
                    </Button.Group>
                );
            }

            filterListsButtons = [<div className='left' key={uuidv4()}>{filterListsButtons}</div>];

            return (<div className='modalNavbar' style={{ display: isMobileOnly ? 'block' : null }}>{filterListsButtons}</div>);
        } else return;
    }

    sortFilterLists = (filterLists) => {
        return filterLists.sort((a, b) => {
            const isOwnerOfA = a.userId === jwt_decode(new Cookies().get('token')).id;
            const isOwnerOfB = b.userId === jwt_decode(new Cookies().get('token')).id;
            return isOwnerOfA && isOwnerOfB ? b.projectId - a.projectId : (isOwnerOfA ? -1 : 1);
        });
    }

    getLinkedValue = (newProperty, oldValue) => {
        const field = baseFields[newProperty] || this.props.customFields.find(cf => cf.id === newProperty);
        const isCustomField = field && !baseFields[newProperty];
        // Permet de récupérer une valeur compatible à la nouvelle propriété lorsqu'on change la propriété d'une ligne où une valeur a déjà été encodée
        let propertyOptions, newValue;
        if ((!oldValue && oldValue !== false) || ['localisation', 'station'].includes(newProperty)) return;
        if (field.type === 'number' && (isNaN(oldValue) || typeof oldValue === 'boolean')) return;
        // Lorsqu'on passe d'un champs booléen à un autre type de champs (ex: Texte)
        if ([true, 'true'].includes(oldValue)) return field.type === 'boolean' ? (isCustomField ? 'true' : true) : i18n.t("Oui");
        if ([false, 'false'].includes(oldValue)) return field.type === 'boolean' ? (isCustomField ? 'false' : false) : i18n.t("Non");
        // On regarde si la nouvelle propriété possède une liste de choix
        propertyOptions = this.getValueOptions(newProperty);
        // Si ce n'est pas le cas, on retourne l'ancienne valeur en assurant la compatibilité avec les champs qui n'ont pas de liste de choix
        if (!propertyOptions) return Array.isArray(oldValue) ? oldValue[0] : oldValue;
        if (Array.isArray(oldValue)) { // Si la nouvelle propriété possède une liste de choix, alors on regarde si l'ancienne valeur est un tableau
            newValue = [];
            oldValue.forEach(ov => { // Si c'est le cas, on fait le match entre le tableau et les options disponibles pour la nouvelle propriété
                propertyOptions.forEach(po => {
                    if ((String(ov).toUpperCase().replace(',', '.') === String(po.value).toUpperCase().replace(',', '.') && !newValue.includes(ov))
                        || (ov === i18n.t("Oui") && po.value)
                        || (ov === i18n.t("Non") && !po.value))
                        newValue.push(ov);
                });
            });
            // En cas de booléen, on retourne la première valeur du tableau uniquement
            return field.type === 'boolean' ? newValue[0] || '' : newValue;
        }
        // Si l'ancienne valeur n'est pas un tableau, alors on recherche simplement la correspondance de cette valeur dans la liste de choix
        newValue = propertyOptions.find(x => String(x.value).toUpperCase().replace(',', '.') === String(oldValue).toUpperCase().replace(',', '.')
            || (oldValue === i18n.t("Oui") && x.value) || (oldValue === i18n.t("Non") && x.value === false));
        return field.type === 'boolean' || !newValue ? newValue?.value : [newValue.value];
    }

    changeName = (name, value) => {
        const { names, properties } = this.state;
        const index = name;
        const field = baseFields[value] || this.props.customFields.find(cf => cf.id === value);
        const isCustomField = field && !baseFields[value];
        let oldValue, oldCondition;

        if (names[index]) { // Si un nom de champs avait déjà été défini à cette ligne
            var oldPropertyFound = properties.findIndex(p => p.name === names[index]); // On recherche l'ancien champs dans les propriétés
            if (oldPropertyFound !== -1) { // Si on l'a trouvé
                if (properties[oldPropertyFound].filters?.length > 0) { // Et qu'il possède une liste de valeurs
                    oldValue = properties[oldPropertyFound].filters.filter(x => x.id === index)[0]; // On récupère l'ancienne valeur
                    if (oldValue) {
                        oldValue = oldValue.value;
                        if (['localisation', 'station'].includes(properties[oldPropertyFound].name)) oldValue = null;
                        else if (['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(properties[oldPropertyFound].name)) oldValue = oldValue / 100;
                    }
                    oldCondition = properties[oldPropertyFound].filters.filter(x => x.id === index)[0]; // On récupère l'ancienne condition
                    if (oldCondition) oldCondition = oldCondition.condition;
                    properties[oldPropertyFound].filters = properties[oldPropertyFound].filters.filter(x => x.id !== index); // On supprime l'ancien objet du champs
                    if (properties[oldPropertyFound].filters.length < 1) properties.splice(oldPropertyFound, 1);
                } else properties.splice(oldPropertyFound, 1);
            }
        }

        oldValue = this.getLinkedValue(value, oldValue);
        // On ajuste la condition du champs en fonction des conditions disponibles pour la nouvelle propriété
        if (['localisation', 'station'].includes(value)) oldCondition = 'fd';
        else if ((field.type === 'list' && !field.isMultiple && !field.isNumeric) || field.type === 'boolean')
            oldCondition = '=';
        else if ((field.type === 'list' && field.isNumeric) || field.type === 'number') {
            if (!['=', '!=', '<', '>'].includes(oldCondition) && oldCondition) oldCondition = '=';
            if (oldValue && !['=', '!='].includes(oldCondition) && (field.type === 'list' && field.isNumeric))
                oldValue = oldValue[0];
        } else if ((field.type === 'list' && field.isMultiple) && !['=', '!=', 'c'].includes(oldCondition) && oldCondition)
            oldCondition = '=';

        if (value !== '') {
            names[index] = value; // On stocke le nom du champs
            this.setState({ names }); // On push dans le state
            let newPropertyFound = properties.find(p => p.name === value) ? true : false; // On recherche si la propriété a déjà été ajoutée aux properties
            if (!newPropertyFound) { // Si ce n'est pas le cas, on l'ajoute
                this.setState({ properties: [...properties, { name: value, category: field.category, categories: field.categories, customField: isCustomField && field, isMultiple: field.isMultiple }] }, () => {
                    // Si on a remplacé un champs (ex: genre -> espèce) alors on met les ancienne valeur dans le nouveau champs
                    this.changeCondition(index, oldCondition);
                    this.changeValue(index, oldValue);
                });
            } else {
                this.changeCondition(index, oldCondition);
                this.changeValue(index, oldValue);
            }
        }
    }

    changeCondition = (name, value) => {
        const { names, conditions, values, properties, fieldNumber } = this.state;
        const index = name;
        const field = baseFields[names[index]] || this.props.customFields.find(cf => cf.id === names[index]);
        let oldValue, oldPropertyFound;

        if (conditions[index]) { // Si une condition avait déjà été défini à cette ligne
            if (conditions[index] === '<.<') {
                oldValue = null;
                values[index] = null;
            }
            else {
                oldPropertyFound = properties.findIndex(p => p.name === names[index]); // On recherche l'ancien champs dans les propriétés
                if (oldPropertyFound !== -1 // Si on l'a trouvé
                    && properties[oldPropertyFound].filters?.length > 0) {  // Et qu'il possède une liste de valeurs
                    oldValue = properties[oldPropertyFound].filters.filter(x => x.id === index)[0]; // On récupère l'ancienne valeur
                    if (oldValue) {
                        oldValue = oldValue.value;
                        if (['localisation', 'station'].includes(properties[oldPropertyFound].name)) oldValue = null;
                        else if (['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(properties[oldPropertyFound].name)) oldValue = oldValue / 100;
                    }
                }
            }
        }

        if (oldValue) {
            if (!['=', '!='].includes(value) && (value !== 'c' || !field?.isMultiple) && Array.isArray(oldValue)) oldValue = oldValue[0];
            if ((['=', '!='].includes(value) || (value === 'c' && field?.isMultiple)) && !Array.isArray(oldValue) && field.type === 'list')
                oldValue = [oldValue];
            values[index] = oldValue;
            this.setState({ values });
        }

        conditions[index] = value; // On garde une trace de la condition de la ligne
        this.setState({ conditions }); // Ce qui permettra d'activer le champs 'valeur' de cette ligne là

        const found = properties.findIndex(p => p.name === names[index]); // Voir changeValue pour plus d'infos sur l'algorithme
        if (!properties[found].filters) properties[found] = { ...properties[found], filters: [{ id: index, condition: value }] };
        else {
            const idFound = properties[found].filters.findIndex(v => v.id === index);
            if (idFound !== -1) {
                properties[found].filters[idFound].condition = value;
                if (oldValue) this.changeValue(index, oldValue);
                else delete properties[found].filters[idFound].value;
            } else {
                properties[found].filters = ([...properties[found].filters, { id: index, condition: value }]);
                if (oldValue) this.changeValue(index, oldValue);
            }
        }
        this.setState({ properties });

        if (index === fieldNumber - 1 && ['empty', 'not_empty'].includes(value))
            this.setState({ fieldNumber: fieldNumber + 1 });
    }

    changeValue = (name, value) => {
        const index = name;
        const { names, values, properties, fieldNumber } = this.state;

        values[index] = value; // On garde une trace de la valeur de la ligne
        this.setState({ values });

        if (value && ['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(names[index]))
            if (typeof value === 'object') {
                value = JSON.parse(JSON.stringify(value))
                if (value?.min) value.min = Math.round((value.min * 100) * 100) / 100;
                if (value?.max) value.max = Math.round((value.max * 100) * 100) / 100;
            } else value = Math.round((value * 100) * 100) / 100;

        const found = properties.findIndex(p => p.name === names[index]); // On recherche la propriété dont on doit modifier la liste de valeurs
        // Si la propriété n'a pas encore de liste de valeurs, on crée cette liste
        if (!properties[found].filters) properties[found] = { ...properties[found], filters: [{ id: index, value: value }] };
        else { // Sinon on recherche dans la liste de valeurs si le champs n'est pas présent (id)
            const idFound = properties[found].filters.findIndex(v => v.id === index);
            if (idFound !== - 1) properties[found].filters[idFound].value = value;// Si un id correspondant au champs est trouvé dans la liste, on modifilie la valeur
            else properties[found].filters = ([...properties[found].filters, { id: index, value: value }]); // Sinon on ajoute une valeur à la liste
        }
        this.setState({ properties }); // On stocke les modifications dans le state
        // Si on se trouve sur la dernière ligne du formulaire, on rajoute une ligne
        if (index === fieldNumber - 1 && value !== undefined) //! undefined est important
            this.setState({ fieldNumber: fieldNumber + 1 });
    }

    handleSubmit = () => { // TODO Retirer valeurs des catégories non sélectionnées
        let shouldFilter = false;
        const nameOptions = this.getNameOptions();
        const properties = this.state.properties.filter(property => !nameOptions.find(option => option.value === property.name)?.disabled);
        properties.forEach(property => {
            if (property.filters.length > 0) shouldFilter = true;
        });

        if (shouldFilter)
            this.props.setFilterFormState(this.state).then(() => {
                this.props.filterLayers(properties, true);
            });
        else this.props.hideForm(true);
    }

    getNameOptions = () => { // Retourne les filtres diponibles par catégorie
        const { trees: treesRF, greenSpaces: greenSpacesRF, furnitures: furnituresRF } = ProjectsUtil.getProjectRequiredFields(this.props.project);
        const { main: mainPF, trees: treesPF, greenSpaces: greenSpacesPF, furnitures: furnituresPF } = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        const subscription = this.props.project?.organization.subscription;
        const trunkCircumferenceUnit = this.props.project?.trunkCircumferenceUnit;

        let categories = this.state.properties.find(property => property.name === 'category')?.filters?.flatMap(filter => filter.value).filter(category => category);
        if (!categories || categories.length < 1) categories = ['Arbre', 'Espace vert', 'Mobilier'];
        let options = [{ text: i18n.t("Catégorie"), value: 'category' }];

        if (mainPF.actions && this.state.projectActions.length > 0) {
            options.push({ text: i18n.t("Action"), value: 'actionId' });
            options.push({ text: i18n.t("Date d'action"), value: 'actionDate' });
            options.push({ text: i18n.t("Action avec date"), value: 'projectActionId' });
        }
        if (treesRF.tags && treesPF.tags && this.state.projectTags.length > 0)
            options.push({ text: i18n.t("Tags"), value: 'tagId' });
        if ((treesRF.customReference || greenSpacesRF.customReference || furnituresRF.customReference) && mainPF.references)
            options.push({ text: i18n.t("Référence personnalisée"), value: 'customReference' });
        if ((treesRF.place && treesPF.place) || (greenSpacesRF.place && greenSpacesPF.place) || (furnituresRF.place && furnituresPF.place))
            options.push({ text: i18n.t("Lieu"), value: 'place' });
        if ((treesRF.observation && treesPF.observation) || (greenSpacesRF.observation && greenSpacesPF.observation))
            options.push({ text: i18n.t("Observation"), value: 'observation', disabled: !['Arbre', 'Espace vert'].some(category => categories.includes(category)) });
        if (((treesRF.carbonStock && treesPF.carbonStock) || (greenSpacesRF.carbonStock && greenSpacesPF.carbonStock)) && subscription?.carbonStockFormula)
            options.push({ text: i18n.t("Stock carbone"), value: 'carbonStock', disabled: !['Arbre', 'Espace vert'].some(category => categories.includes(category)) });
        if (mainPF.references && this.props.project) options.push({ text: i18n.t("Référence"), value: 'projectReference' });
        options.push({ text: i18n.t("Date de création"), value: 'creationDate' });
        options.push({ text: i18n.t("Dernière modification"), value: 'modificationDate' });
        if (mainPF.photos && this.props.project) options.push({ text: i18n.t("Nombre de photos"), value: 'nbPhotos' });
        if (mainPF.files && this.props.project) options.push({ text: i18n.t("Nombre de fichiers"), value: 'nbFiles' });

        if (treesPF.vernacularName) options.push({ text: i18n.t("Nom vernaculaire"), value: 'vernacularName', disabled: !categories.includes('Arbre') });
        if (treesPF.gender) options.push({ text: i18n.t("Genre"), value: 'gender', disabled: !categories.includes('Arbre') });
        if (treesRF.species && treesPF.species) options.push({ text: i18n.t("Espèce"), value: 'species', disabled: !categories.includes('Arbre') });
        if (treesRF.cultivar && treesPF.cultivar) options.push({ text: i18n.t("Cultivar"), value: 'cultivar', disabled: !categories.includes('Arbre') });
        if (treesRF.numberOfTrunks && treesPF.numberOfTrunks) options.push({ text: i18n.t("Nombre d'axes"), value: 'numberOfTrunks', disabled: !categories.includes('Arbre') });
        if (treesRF.trunkHeight && treesPF.trunkHeight) options.push({ text: i18n.t("Hauteur du tronc"), value: 'trunkHeight', disabled: !categories.includes('Arbre') });
        if (treesRF.trunks && treesPF.trunks) options.push({ text: i18n.t("Hauteur totale axe"), value: 'height', disabled: !categories.includes('Arbre') });
        if (treesRF.trunks && treesPF.trunks) options.push({ text: trunkCircumferenceUnit === 'circumference' ? i18n.t("Circonférence axe") : i18n.t("Diamètre axe"), value: 'circumference', disabled: !categories.includes('Arbre') });
        if (treesRF.trunks && treesPF.trunks) options.push({ text: i18n.t("Diamètre couronne axe"), value: 'crownDiameter', disabled: !categories.includes('Arbre') });
        if (treesPF.isFruit) options.push({ text: i18n.t("Production de fruits"), value: 'fruitProduction', disabled: !categories.includes('Arbre') });
        if (treesRF.treePort && treesPF.treePort) options.push({ text: i18n.t("Port de l'arbre"), value: 'treePortId', disabled: !categories.includes('Arbre') });
        if (treesRF.coverType && treesPF.coverType) options.push({ text: i18n.t("Type de couverture au sol"), value: 'coverTypeId', disabled: !categories.includes('Arbre') });
        if (treesRF.vigor && treesPF.vigor) options.push({ text: i18n.t("Vigueur"), value: 'vigorId', disabled: !categories.includes('Arbre') });
        if (treesRF.risk && treesPF.risk) options.push({ text: i18n.t("Risque"), value: 'riskId', disabled: !categories.includes('Arbre') });
        if (treesRF.accurateRisk && treesPF.risk) {
            options.push({ text: i18n.t("Risque de basculement/rupture"), value: 'tippingRiskId', disabled: !categories.includes('Arbre') });
            options.push({ text: i18n.t("Calibre de l'organe instable"), value: 'organCaliberId', disabled: !categories.includes('Arbre') });
            options.push({ text: i18n.t("Cible"), value: 'targetId', disabled: !categories.includes('Arbre') });
        }
        if (treesRF.plantationType && treesPF.plantationType) options.push({ text: i18n.t("Type de plantation"), value: 'plantationTypeId', disabled: !categories.includes('Arbre') });
        if (treesRF.healthReview && treesPF.healthReview) options.push({ text: i18n.t("Cote sanitaire"), value: 'healthReviewId', disabled: !categories.includes('Arbre') });
        if (treesRF.ontogenicStage && treesPF.ontogenicStage) options.push({ text: i18n.t("Stade ontogénique"), value: 'ontogenicStageId', disabled: !categories.includes('Arbre') });
        if (this.props.project && treesRF.plantationCoefficient && treesPF.plantationCoefficient) options.push({ text: i18n.t("Coefficient de plantation"), value: 'plantationCoefficientId', disabled: !categories.includes('Arbre') });
        if (this.props.project && treesRF.situationCoefficient && treesPF.situationCoefficient) options.push({ text: i18n.t("Coefficient de situation"), value: 'situationCoefficientId', disabled: !categories.includes('Arbre') });
        const amenityFormulaType = this.props.project?.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        if (amenityFormulaType === 'Wallonie' && treesRF.patrimonialCoefficient && treesPF.patrimonialCoefficient)
            options.push({ text: i18n.t("Coefficient patrimonial"), value: 'patrimonialCoefficientId', disabled: !categories.includes('Arbre') });
        if (treesRF.isEmpty && treesPF.isEmpty) options.push({ text: i18n.t("Vide"), value: 'isEmpty', disabled: !categories.includes('Arbre') });
        if (treesRF.isDead && treesPF.isDead) options.push({ text: i18n.t("Mort"), value: 'isDead', disabled: !categories.includes('Arbre') });
        if (treesRF.isStump && treesPF.isStump) options.push({ text: i18n.t("Souche"), value: 'isStump', disabled: !categories.includes('Arbre') });
        if (treesRF.isIndexed && treesPF.isIndexed) options.push({ text: i18n.t("Classé"), value: 'isIndexed', disabled: !categories.includes('Arbre') });
        if (treesRF.isRemarkable && treesPF.isRemarkable) options.push({ text: i18n.t("Remarquable"), value: 'isRemarkable', disabled: !categories.includes('Arbre') });
        if (treesPF.isFruit) options.push({ text: i18n.t("Fruitier"), value: 'isFruit', disabled: !categories.includes('Arbre') });
        if (treesPF.toCutDown && this.props.project) options.push({ text: i18n.t("Abattage"), value: 'toCutDown', disabled: !categories.includes('Arbre') });
        if (treesRF.interactions && treesPF.interactions) options.push({ text: i18n.t("Interactions"), value: 'interactionId', disabled: !categories.includes('Arbre') });
        if (treesRF.microHabitats && treesPF.microHabitats) options.push({ text: i18n.t("Dendro-microhabitats"), value: 'microHabitatId', disabled: !categories.includes('Arbre') });

        treeOrgans.forEach(({ organ, label }) => {
            if (treesRF[`${organ}Symptoms`] && treesPF[`${organ}Symptoms`]) options.push({ text: `${i18n.t("Symptômes")} ${label}`, value: `${organ}SymptomId`, disabled: !categories.includes('Arbre') });
            if (treesRF[`${organ}Pathogens`] && treesPF[`${organ}Pathogens`]) options.push({ text: `${i18n.t("Pathogènes")} ${label}`, value: `${organ}PathogenId`, disabled: !categories.includes('Arbre') });
            if (treesRF[`${organ}Pests`] && treesPF[`${organ}Pests`]) options.push({ text: `${i18n.t("Ravageurs")} ${label}`, value: `${organ}PestId`, disabled: !categories.includes('Arbre') });
            if (['trunk', 'branch'].includes(organ) && treesRF[`${organ}Epiphytes`] && treesPF[`${organ}Epiphytes`]) options.push({ text: `${i18n.t("Épiphytes")} ${label}`, value: `${organ}EpiphyteId`, disabled: !categories.includes('Arbre') });
        });

        if (treesRF.plantingDate && treesPF.plantingDate) options.unshift({ text: i18n.t("Date de plantation"), value: 'plantingDate', disabled: !categories.includes('Arbre') });

        if (greenSpacesRF.placeExtra && greenSpacesPF.placeExtra) options.push({ text: i18n.t("Libellé"), value: 'placeExtra', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.surface && greenSpacesPF.surface) options.push({ text: i18n.t("Surface"), value: 'surface', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.spaceFunction && greenSpacesPF.spaceFunction) options.push({ text: i18n.t("Fonction de l'espace"), value: 'spaceFunctionId', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.spaceType && greenSpacesPF.spaceType) options.push({ text: i18n.t("Type de surface"), value: 'spaceTypeId', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.dominantComposition && greenSpacesPF.dominantComposition) options.push({ text: i18n.t("Composition dominante"), value: 'dominantCompositionId', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.detailedComposition && greenSpacesPF.detailedComposition) options.push({ text: i18n.t("Composition détaillée"), value: 'detailedComposition', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.managementClass && greenSpacesPF.managementClass) options.push({ text: i18n.t("Classe de gestion"), value: 'managementClassId', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.dominantComposition && greenSpacesPF.dominantComposition) options.push({ text: i18n.t("Coefficient de ruissellement"), value: 'runoffCoefficientId', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.annualMaintenanceFrequency && greenSpacesPF.annualMaintenanceFrequency) options.push({ text: i18n.t("Fréquence annuelle d'entretien"), value: 'annualMaintenanceFrequency', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.isTreeBase && greenSpacesPF.isTreeBase) options.push({ text: i18n.t("Pied d'arbre"), value: 'isTreeBase', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.density && greenSpacesPF.density) {
            options.push({ text: i18n.t("Nombre d'arbres"), value: 'nbTrees', disabled: !categories.includes('Espace vert') });
            options.push({ text: i18n.t("Densité"), value: 'density', disabled: !categories.includes('Espace vert') });
            options.push({ text: i18n.t("Distance moyenne entre les troncs"), value: 'distanceBetweenTrunks', disabled: !categories.includes('Espace vert') });
        }
        if (greenSpacesRF.dominantEssence && greenSpacesPF.dominantEssence) {
            options.push({ text: i18n.t("Nom vernaculaire dominant"), value: 'dominantVernacularName', disabled: !categories.includes('Espace vert') });
            options.push({ text: i18n.t("Genre dominant"), value: 'dominantGender', disabled: !categories.includes('Espace vert') });
            options.push({ text: i18n.t("Espèce dominante"), value: 'dominantSpecies', disabled: !categories.includes('Espace vert') });
            options.push({ text: i18n.t("Cultivar dominant"), value: 'dominantCultivar', disabled: !categories.includes('Espace vert') });
        }
        if (greenSpacesRF.averageHealthReview && greenSpacesPF.averageHealthReview) options.push({ text: i18n.t("Cote sanitaire moyenne"), value: 'averageHealthReviewId', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.averageHeight && greenSpacesPF.averageHeight) options.push({ text: i18n.t("Hauteur moyenne"), value: 'averageHeight', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.averageCircumference && greenSpacesPF.averageCircumference) options.push({ text: i18n.t("Circonférence moyenne des troncs"), value: 'averageCircumference', disabled: !categories.includes('Espace vert') });
        if (greenSpacesRF.averageCrownDiameter && greenSpacesPF.averageCrownDiameter) options.push({ text: i18n.t("Diamètre moyen des couronnes"), value: 'averageCrownDiameter', disabled: !categories.includes('Espace vert') });

        if (furnituresRF.condition && furnituresPF.condition) options.push({ text: i18n.t("État"), value: 'conditionId', disabled: !categories.includes('Mobilier') });
        if (furnituresRF.type && furnituresPF.type) options.push({ text: i18n.t("Type"), value: 'typeId', disabled: !categories.includes('Mobilier') });
        if (furnituresRF.description && furnituresPF.description) options.push({ text: i18n.t("Description"), value: 'description', disabled: !categories.includes('Mobilier') });

        options.push({ text: i18n.t("Station"), value: 'station' });

        options = options.sort((a, b) => { // On trie par ordre alphabétique
            const textA = a.text.toUpperCase();
            const textB = b.text.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        if (treesRF.age && treesPF.age) options.unshift({ text: i18n.t("Âge"), value: 'age', disabled: !categories.includes('Arbre') });
        options.unshift({ text: <span><FontAwesomeIcon icon={faLocationCrosshairs} style={{ marginRight: '5px' }} />{i18n.t("Localisation")}</span>, rawText: i18n.t("Localisation"), value: 'localisation' });
        this.props.customFields
            .filter(cf => categories.includes(cf.category) && (!this.state.customFieldsToRender || this.state.customFieldsToRender.includes(cf.id)))
            .forEach(customField => options.push({ text: customField.label, value: customField.id }));
        return options;
    }

    getConditionOptions = (i) => { // Permet d'ajuster la liste de conditions disponibles en fonction de la propriété choisie
        const name = this.state.names[i];
        const field = baseFields[name] || this.props.customFields.find(cf => cf.id === name);

        if (name === 'localisation')
            return this.props.project ? [
                { text: i18n.t("Dessin libre"), value: 'fd' },
                { text: i18n.t("Espace vert"), value: 'gs' },
                { text: i18n.t("Station"), value: 's=' }
            ] : [{ text: i18n.t("Dessin libre"), value: 'fd' }];
        else if (name === 'station')
            return [{ text: i18n.t("Egale à"), value: 's=' }, { text: i18n.t("Différent de"), value: 's!=' }];
        else if (field?.type === 'date')
            return [
                { text: i18n.t("Avant"), value: '<' }, { text: i18n.t("Du ... au ..."), value: '<.<' },
                { text: i18n.t("Après"), value: '>' }
            ];
        else if (field?.type === 'text')
            return [
                { text: i18n.t("Egale à"), value: '=' }, { text: i18n.t("Différent de"), value: '!=' }, { text: i18n.t("Contient"), value: 'c' },
                { text: i18n.t("Commence par"), value: 'sw' }, { text: i18n.t("Se termine par"), value: 'ew' },
                { text: i18n.t("Donnée manquante"), value: 'empty' }, { text: i18n.t("Donnée présente"), value: 'not_empty' }
            ];
        else if (field?.type === 'list' && field?.isMultiple)
            return [{ text: i18n.t("Egale à"), value: '=' }, { text: i18n.t("Différent de"), value: '!=' }, { text: i18n.t("Contient"), value: 'c' }];
        else if ((field?.type === 'list' && field?.isNumeric) || field?.type === 'number')
            return [
                { text: i18n.t("Egale à"), value: '=' }, { text: i18n.t("Différent de"), value: '!=' }, { text: i18n.t("Inférieur à"), value: '<' },
                { text: i18n.t("Supérieur à"), value: '>' }, { text: i18n.t("De ... à ..."), value: '<.<' },
                { text: i18n.t("Donnée manquante"), value: 'empty' }, { text: i18n.t("Donnée présente"), value: 'not_empty' }
            ];
        else if (field?.type === 'list' || field?.type === 'boolean')
            return [{ text: i18n.t("Egale à"), value: '=' }, { text: i18n.t("Différent de"), value: '!=' }];
        else return [
            { text: i18n.t("Egale à"), value: '=' }, { text: i18n.t("Différent de"), value: '!=' }, { text: i18n.t("Contient"), value: 'c' },
            { text: i18n.t("Commence par"), value: 'sw' }, { text: i18n.t("Se termine par"), value: 'ew' },
            { text: i18n.t("Inférieur à"), value: '<' }, { text: i18n.t("Supérieur à"), value: '>' }
        ];
    }

    getValueOptions = (property, condition) => { // Retourne la liste de choix des propriétés qui en possèdent une
        let categories = this.state.properties.find(property => property.name === 'category')?.filters?.flatMap(filter => filter.value).filter(category => category);
        if (!categories || categories.length < 1) categories = ['Arbre', 'Espace vert', 'Mobilier'];

        switch (property) {
            case 'localisation':
                if (condition === 'gs') return this.props.greenSpacesLayer.getLayers().map(layer => ({ text: layer.feature.properties.projectReference + (layer.feature.properties.customReference ? ` (${layer.feature.properties.customReference})` : ''), value: layer.feature.id }));
                if (condition === 's=') return this.props.stationsLayer.getLayers().map(layer => ({ text: layer.feature.properties.label, value: layer.feature.id }));
                return;
            case 'station': return this.props.stationsLayer.getLayers().map(layer => ({ text: layer.feature.properties.label, value: layer.feature.id }));
            case 'category': return ['Arbre', 'Espace vert', 'Mobilier'].map(category => ({ text: category, value: category }));
            case 'vernacularName': case 'dominantVernacularName': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.vernacularNames] : this.state.vernacularNames;
            case 'gender': case 'dominantGender': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.genders] : this.state.genders;
            case 'species': case 'dominantSpecies': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.species] : this.state.species;
            case 'cultivar': case 'dominantCultivar': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.cultivars] : this.state.cultivars;
            case 'treePortId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.treePorts] : this.state.treePorts;
            case 'coverTypeId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.coverTypes] : this.state.coverTypes;
            case 'vigorId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.vigors] : this.state.vigors;
            case 'healthReviewId': case 'averageHealthReviewId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.healthReviews] : this.state.healthReviews;
            case 'ontogenicStageId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.ontogenicStages] : this.state.ontogenicStages;
            case 'riskId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.risks] : this.state.risks;
            case 'tippingRiskId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.tippingRisks] : this.state.tippingRisks;
            case 'organCaliberId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.organCalibers] : this.state.organCalibers;
            case 'targetId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.targets] : this.state.targets;
            case 'plantationTypeId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.plantationTypes] : this.state.plantationTypes;
            case 'plantationCoefficientId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.plantationCoefficients] : this.state.plantationCoefficients;
            case 'situationCoefficientId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.situationCoefficients] : this.state.situationCoefficients;
            case 'patrimonialCoefficientId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.patrimonialCoefficients] : this.state.patrimonialCoefficients;
            case 'interactionId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.interactions] : this.state.interactions;
            case 'microHabitatId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.microHabitats] : this.state.microHabitats;
            case 'rootSymptomId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.rootSymptoms] : this.state.rootSymptoms;
            case 'collarSymptomId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.collarSymptoms] : this.state.collarSymptoms;
            case 'trunkSymptomId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.trunkSymptoms] : this.state.trunkSymptoms;
            case 'branchSymptomId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.branchSymptoms] : this.state.branchSymptoms;
            case 'leafSymptomId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.leafSymptoms] : this.state.leafSymptoms;
            case 'rootPathogenId': case 'collarPathogenId': case 'trunkPathogenId': case 'branchPathogenId': case 'leafPathogenId':
                return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.pathogens] : this.state.pathogens;
            case 'rootPestId': case 'collarPestId': case 'trunkPestId': case 'branchPestId': case 'leafPestId':
                return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.pests] : this.state.pests;
            case 'trunkEpiphyteId': case 'branchEpiphyteId':
                return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.epiphytes] : this.state.epiphytes;
            case 'spaceFunctionId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.spaceFunctions] : this.state.spaceFunctions;
            case 'spaceTypeId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.spaceTypes] : this.state.spaceTypes;
            case 'dominantCompositionId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.dominantCompositions] : this.state.dominantCompositions;
            case 'runoffCoefficientId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.runoffCoefficients] : this.state.runoffCoefficients;
            case 'managementClassId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.managementClasses] : this.state.managementClasses;
            case 'conditionId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.furnitureConditions] : this.state.furnitureConditions;
            case 'typeId': return ['=', '!='].includes(condition) ? [{ text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.furnitureTypes] : this.state.furnitureTypes;
            case 'tagId': return ['=', '!='].includes(condition) ? [
                { text: i18n.t("Donnée manquante"), value: 'empty' }, ...this.state.projectTags
                    .filter(projectTag => categories.includes(projectTag.category))
                    .map(projectTag => { return { text: projectTag.label, value: projectTag.id } })
            ] : this.state.projectTags
                .filter(projectTag => categories.includes(projectTag.category))
                .map(projectTag => { return { text: projectTag.label, value: projectTag.id } });
            case 'toCutDown':
                return [
                    { text: i18n.t("Aucun"), value: 0 },
                    ...this.state.actions.filter(action => action.id < 4).map(action => ({ text: action.label, value: action.id })),
                    { text: i18n.t("Abattu"), value: 4 }
                ];
            case 'actionId': return [...new Set(this.state.actions.filter(action => categories.some(category => action.categories.includes(category))).map(x => x.label))]
                .map(label => ({ text: label, value: label }))
                .sort((a, b) => { // On trie par ordre alphabétique
                    var textA = a.text.toUpperCase();
                    var textB = b.text.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            case 'projectActionId':
                return this.state.projectActions
                    .filter(projectAction => categories.some(category => projectAction.action.categories.includes(category)))
                    .map(projectAction => ({
                        text: projectAction.action.label + ' | '
                            + DatesUtil.getFormattedLocaleDateString(projectAction.startDate)
                            + ' - ' + DatesUtil.getFormattedLocaleDateString(projectAction.endDate)
                            + ' | ' + ActionsUtil.getRecurrencesInfos(projectAction),
                        value: projectAction.id
                    }));
            case 'isEmpty': case 'isDead': case 'isStump': case 'isIndexed': case 'isRemarkable': case 'isFruit': case 'isTreeBase':
                return [{ text: i18n.t("Oui"), value: true }, { text: i18n.t("Non"), value: false }];
            default:
                const customField = this.props.customFields.find(cf => cf.id === property);
                if (customField?.type === 'boolean') return [{ text: i18n.t("Oui"), value: 'true' }, { text: i18n.t("Non"), value: 'false' }]
                else return customField?.dropdownCustomFieldValues?.map(dcfv => ({ text: dcfv.label, value: customField.isNumeric && !customField.isMultiple ? Number(dcfv.value) : String(dcfv.id) }));
        }
    }

    saveFilterList = () => { // TODO Retirer valeurs des catégories non sélectionnées
        let shouldSave = false;
        const { newFilterName } = this.state;
        const nameOptions = this.getNameOptions();
        const properties = this.state.properties.filter(property => !nameOptions.find(option => option.value === property.name)?.disabled);
        properties.forEach(property => {
            property.filters.forEach(filter => {
                if ((filter.value || filter.value === false) && (String(filter.value).trim() !== ''))
                    shouldSave = true;
            });
        });

        const names = JSON.parse(JSON.stringify(this.state.names)), conditions = JSON.parse(JSON.stringify(this.state.conditions)), values = JSON.parse(JSON.stringify(this.state.values));
        for (let i = 0; i < names.length; i++)
            if (nameOptions.find(option => option.value === names[i])?.disabled) {
                names.splice(i, 1);
                conditions.splice(i, 1);
                values.splice(i, 1);
                i--;
            }

        if (shouldSave) {
            this.setState({ isSaving: true });
            let filters = [];
            for (let i = 0; i < names.length; i++)
                filters.push({
                    name: names[i],
                    condition: conditions[i],
                    value: JSON.stringify(values[i])
                });

            let filterList = {
                userId: jwt_decode(new Cookies().get('token')).id,
                projectId: this.props.project.id,
                label: newFilterName?.trim() !== '' && newFilterName.length < 31 ?
                    newFilterName : null,
                filters: filters
            };

            FiltersService.addFilterList(filterList).then(filterList => {
                if (filterList) {
                    this.setState(prevState => ({
                        filterLists: prevState.filterLists ? this.sortFilterLists([...prevState.filterLists, filterList]) : [filterList]
                    }));

                    if (this.props.filterLists)
                        this.props.setFilterLists({
                            ...this.props.filterLists,
                            [filterList.projectId]: [...this.props.filterLists[filterList.projectId], filterList]
                        });
                }

                this.setState({
                    newFilterName: '',
                    isSaving: false
                });
            });
        } else showToast('filter_not_efficient');
    }

    restoreFilterList = (index) => {
        const filters = this.state.filterLists[index].filters;
        let fieldNumber = 1, isRestoredProperly = true;
        let properties = [], names = [], conditions = [], values = [];

        filters.forEach((filter, index) => {
            const field = baseFields[filter.name] || this.props.customFields.find(cf => cf.id === Number(filter.name));
            const isCustomField = field && !baseFields[filter.name];
            if (field) {
                const name = isCustomField ? Number(filter.name) : filter.name;
                names.push(name);
                conditions.push(filter.condition);
                let value = filter.value;
                try {
                    const newValue = JSON.parse(value);
                    value = newValue;
                    if (field.type === 'date')
                        if (typeof value === 'string') value = new Date(value);
                        else {
                            if (value.startDate) value.startDate = new Date(value.startDate);
                            if (value.endDate) value.endDate = new Date(value.endDate);
                        }
                } catch { }
                finally { values.push(value); }
                if (value && ['height', 'crownDiameter', 'trunkHeight', 'averageHeight', 'averageCrownDiameter'].includes(name)) value = Math.round((Number(value) * 100) * 100) / 100;
                if (value || value === false || value === 0) fieldNumber++;
                const propertyIndex = properties.findIndex(property => property.name === name);
                if (propertyIndex === -1) properties.push({ name, customField: isCustomField && field, isMultiple: field.isMultiple, filters: [{ id: index, condition: filter.condition, value: value }] });
                else properties[propertyIndex].filters.push({ id: index, condition: filter.condition, value: value });
            } else isRestoredProperly = false;
        });

        if (!isRestoredProperly) showToast('filter_not_restored_properly');
        this.setState({ properties, fieldNumber, names, conditions, values });
    }

    deleteFilterList = (id) => {
        const userId = jwt_decode(new Cookies().get('token')).id;
        const index = this.state.filterLists.findIndex(cc => cc.id === id);
        const filterList = this.state.filterLists[index];
        this.setState({ isDeleting: true });
        FiltersService.removeFilterList(filterList).then(() => {
            // TODO
            // const usersToAlert = filterList.userFilterLists.filter(ufl => ufl.isOwner !== isOwner && ufl.userId !== userId).map(ufl => ufl.userId);
            // WebSocketUtil.removeFilter(this.props.webSocketHubs, usersToAlert, this.props.project.id, userId, id);
            this.setState(prevState => ({ isDeleting: false, filterListToRemove: 0, filterLists: prevState.filterLists.filter(cc => cc.id !== id) }), () => {
                if (this.props.filterLists)
                    this.props.setFilterLists({
                        ...this.props.filterLists,
                        [filterList.projectId || 0]: this.props.filterLists[filterList.projectId || 0].filter(cc => cc.id !== filterList.id)
                    });
            });
        });
    }

    resetFilters = () => this.setState({
        properties: [],
        fieldNumber: 1,
        names: [], conditions: [], values: []
    });
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        treePorts: state.treePorts,
        coverTypes: state.coverTypes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        interactions: state.interactions,
        microHabitats: state.microHabitats,
        rootSymptoms: state.rootSymptoms,
        collarSymptoms: state.collarSymptoms,
        trunkSymptoms: state.trunkSymptoms,
        branchSymptoms: state.branchSymptoms,
        leafSymptoms: state.leafSymptoms,
        pathogens: state.pathogens,
        pests: state.pests,
        epiphytes: state.epiphytes,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        runoffCoefficients: state.runoffCoefficients,
        managementClasses: state.managementClasses,
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        actions: state.actions,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        filterFormState: state.filterFormState,
        layer: state.layer,
        filterLists: state.filterLists,
        projectActions: state.projectActions,
        rights: state.rights,
        webSocketHubs: state.webSocketHubs,
        isOnline: state.isOnline,
        loginAsData: state.loginAsData,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setFilterFormState,
    setFilterLists,
    setCurrentAction
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);