import React, { Component } from 'react';
// Composants
import { Button, Modal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../locales/i18n';
import { faCheck, faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
// Ressources
import ScientificPNG from '../../resources/pngs/scientific.png';
import { connect } from 'react-redux';

class AIScanWarning extends Component {
    render() {
        return (
            <Modal size='small' open={this.props.isAIWarningVisible}>
                <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faMicrochipAi} style={{ marginRight: '10px' }} />
                    {i18n.t("Inventaire automatique (beta)")}
                </Modal.Header>
                <Modal.Content style={{ padding: 0 }}>
                    <img src={ScientificPNG} alt='scientific' style={{ width: '100%', height: 'auto' }} />
                    <ul style={{ margin: '21px' }}>
                        <li style={{ marginBottom: '8px' }}>{i18n.t("Notre intelligence artificielle est en cours d'éducation pour la détection d’arbres. Par conséquent, la précision des résultats est susceptible de progresser à mesure des travaux d’apprentissage réalisés. Les résultats, qui dépendent de la qualité des images disponibles, peuvent donc ne pas être parfaits : il peut y avoir des omissions ou des \"faux positifs\".")}</li>
                        <li style={{ marginBottom: '8px' }}>
                            {i18n.t("N'hésitez pas à nous faire part de vos observations à ce sujet afin de contribuer à la bonne éducation de notre algorithme !")}
                            <a href='https://grality.atlassian.net/servicedesk/customer/portal/3/group/11/create/38' target='_blank' rel='noopener noreferrer' style={{ cursor: 'pointer', marginLeft: '5px' }}>
                                {i18n.t("Cliquez ici")}
                            </a>
                        </li>
                        <li style={{ marginBottom: '8px' }}>{i18n.t("Une fois la détection terminée et avant d’insérer les nouveaux arbres dans le projet, vous pourrez passer en revue les arbres détectés afin de supprimer ceux que vous ne souhaitez pas reprendre.")}</li>
                        <li style={{ marginBottom: '8px' }}>{i18n.t("La consommation de votre licence tient compte des arbres ajoutés.")}</li>
                        <li>
                            <a href='https://grality.atlassian.net/servicedesk/customer/kb/view/66519058' target='_blank' rel='noopener noreferrer' style={{ cursor: 'pointer' }}>
                                {i18n.t("Plus d'infos")}
                            </a>
                        </li>
                    </ul>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' color='green' onClick={this.close}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("J'ai compris")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    close = () => {
        localStorage.setItem('aiWarningClosed', true);
        this.props.showAIWarning(false);
    }
}

const mapStateToProps = (state) => ({
    currentAction: state.currentAction
})

export default connect(mapStateToProps)(AIScanWarning);