import React, { Component } from 'react';
// Composants
import { Button, Form, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
// Ressources
import { faSquareDashed, faTimes } from '@fortawesome/pro-solid-svg-icons';

class SelectedElementsForm extends Component {
    render() {
        const { selectedElements, isToolbarExpanded } = this.props;

        return (
            <>
                <div className='tool-form' style={{ left: `calc(${(isToolbarExpanded ? 305 : 45) / 2}px + 50%)`, transform: 'translateX(-50%)', top: 'inherit', bottom: '20px', transition: 'left 500ms', zIndex: 999, width: 'fit-content' }}>
                    <Segment style={{ padding: '7px 10px' }}>
                        <FontAwesomeIcon icon={faSquareDashed} style={{ marginRight: '7px' }} />
                        {selectedElements.length > 1 ? i18n.t("{{count}} éléments sélectionnés", { count: selectedElements.length }) : i18n.t("1 élément sélectionné")}
                        <Button color='red' style={{ margin: '0 -3px 0 8px', padding: '3px 5px' }} onClick={() => this.props.unselectElements()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Segment>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isToolbarExpanded: state.isToolbarExpanded
    };
}

export default connect(mapStateToProps)(SelectedElementsForm);