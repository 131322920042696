import React, { Component } from 'react';
import i18n from '../../../locales/i18n';

export default class BooleanFilter extends Component {
    render() {
        const p = this.props.p;

        return (
            <div className='rdg-filter-container'>
                <select style={{ width: '100%' }} className='rdg-filter' value={p.value} onChange={e => p.onChange(e.target.value)}>
                    <option value=''>{i18n.t("Aucun filtre")}</option>
                    <option value={i18n.t("Oui")}>{i18n.t("Oui")}</option>
                    <option value={i18n.t("Non")}>{i18n.t("Non")}</option>
                </select>
            </div>
        );
    }
}
