import AppSettings from '../AppSettings';
// Librairies
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

export default class FilesUtil {
    static downloadAndCompressFilesToZip(type, zipName, fileInfos = []) {
        let zip = new JSZip();
        let count = 0;
        const zipFilename = `${zipName} (fichiers).zip`;
        const blobInfos = AppSettings.getBlobInfos();
        let filenames = [];

        fileInfos.forEach((fileInfo) => {
            const blobUrl = `${blobInfos.endpoint}${type === 'file' ? blobInfos.containers.files : blobInfos.containers.photos}/${fileInfo.blobName}`;
            let filename = `${fileInfo.baseName}.${fileInfo.extension}`;

            // Détection des doublons
            const index = filenames.findIndex(x => x.name === filename);
            if (index !== -1) {
                let filenameInfo = filenames[index];
                filename = `${fileInfo.baseName} (${filenameInfo.nb}).${fileInfo.extension}`
                filenameInfo.nb++;
                filenames[index] = filenameInfo;
            } else filenames.push({ name: filename, nb: 1 });

            // loading a file and add it in a zip file
            JSZipUtils.getBinaryContent(blobUrl, async function (err, data) {
                if (err) throw err; // or handle the error
                zip.file(filename, data, { binary: true });
                count++;
                if (count === fileInfos.length) {
                    const zipFile = await zip.generateAsync({ type: 'blob' });
                    saveAs(zipFile, zipFilename);
                }
            });
        });
    }
}