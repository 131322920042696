import React, { Component } from 'react';
// Composants
import { Grid, Segment } from 'semantic-ui-react';
// Librairies
import { isMobile } from 'react-device-detect';
// Styles;
import 'semantic-ui-css/semantic.min.css';
import TermsOfUseFrBe from './TermsOfUseFrBe';

class TermsOfUse extends Component {
    render() {
        const gridStyle = {
            margin: 0, paddingTop: isMobile ? '0px' : '30px', paddingBottom: isMobile ? '0px' : '30px',
            flexGrow: 1, display: 'block', height: '80vh'
        };

        return (
            <Grid style={gridStyle} className='grality-background'>
                <Grid.Row style={{ padding: 0, height: '100%', maxWidth: isMobile ? '100vw' : '50vw', minWidth: !isMobile && '500px', marginLeft: 'auto', marginRight: 'auto' }} stretched>
                    <Grid.Column style={{ padding: 0, height: '100%', overflowY: 'overlay', borderRadius: '0 5px 5px 0' }}>
                        <Segment style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                            {this.renderTermsOfUse()}
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    renderTermsOfUse = () => {
        let termsOfUse, culture;
        if (this.props.userInfos?.language?.culture) culture = this.props.userInfos.language.culture;
        else {
            const userCulture = localStorage.getItem('i18nextLng') || window.navigator.language;
            if (userCulture.toLowerCase().startsWith('en')) culture = 'en-US';
            else if (userCulture.toLowerCase().startsWith('nl')) culture = 'nl-BE';
            else culture = 'fr-BE';
        }

        switch (culture) {
            case 'fr-BE':
                termsOfUse = <TermsOfUseFrBe />;
                break;
            default:
                termsOfUse = <TermsOfUseFrBe />;
                break;
        }

        return (termsOfUse);
    }
}

export default TermsOfUse;