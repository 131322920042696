import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../locales/i18n';
import { faWifi, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';

class OfflineBand extends Component {
    state = {
        visible: false
    }

    render() {
        const { isOnline } = this.props;
        const { visible } = this.state;

        return (
            <>
                {visible &&
                    <div style={{ width: '100%', display: 'flex', backgroundColor: isOnline ? 'var(--green-100)' : 'var(--red-100)', justifyContent: 'center', alignItems: 'center', padding: '5px 0' }}>
                        <FontAwesomeIcon icon={isOnline ? faWifi : faWifiSlash} style={{ marginRight: '5px' }} />
                        <h4 style={{ margin: 0 }}>{isOnline ? i18n.t("En ligne") : i18n.t("Hors ligne")}</h4>
                    </div>}
            </>
        );
    }

    componentDidMount = () => {
        if (!this.props.isOnline) this.setState({ visible: true });
    }

    componentDidUpdate = (prevProps) => {
        const { isOnline } = this.props;
        if (prevProps.isOnline && !isOnline) {
            clearTimeout(this.timeout);
            this.setState({ visible: true });
        } else if (!prevProps.isOnline && isOnline) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.setState({ visible: false }), 2000);
        }
    }

}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(OfflineBand);