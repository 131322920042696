
import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Utils
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class RolesService {
    static addRoles(roles) {
        return Axios.post(BACKEND_ENDPOINT + 'roles/', roles, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast(roles.length === 1 ? 'role_added' : 'roles_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast(roles.length === 1 ? 'role_addition_failed' : 'roles_addition_failed');
            return;
        });
    }

    static updateRoles(roles) {
        return Axios.put(BACKEND_ENDPOINT + 'roles/', roles, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast(roles.length === 1 ? 'role_updated' : 'roles_updated');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast(roles.length === 1 ? 'role_update_failed' : 'roles_update_failed');
            return;
        });
    }

    static removeRole(id) {
        return Axios.delete(BACKEND_ENDPOINT + 'roles/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('role_deleted');
            return response;
        }, () => {
            if (navigator.onLine) showToast('role_deletion_failed');
            return;
        });
    }

    static getRoleTemplates() {
        return Axios.get(BACKEND_ENDPOINT + 'roles/templates/' + jwt_decode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static addTemplate(template) {
        return Axios.post(BACKEND_ENDPOINT + 'roles/templates/', template, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('template_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('template_addition_failed');
            return;
        });
    }

    static removeTemplate(id) {
        return Axios.delete(BACKEND_ENDPOINT + 'roles/templates/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('template_deleted');
            return response;
        }, () => {
            showToast('template_deletion_failed');
            return;
        });
    }
}