import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Grid, Loader, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
// Librairies
import { connect } from 'react-redux';
import i18n from '../locales/i18n';
// Styles
import StylesUtil from './StylesUtil';


class DimmerConfirmation extends Component {
    state = {
        isLoading: false
    }

    render() {
        const { title, content, loadingMessage, submitColor, submitIcon, submitLabel, cancel, submit, isOnline } = this.props;

        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) cancel(); }}
            >
                {this.state.isLoading
                    ? <Loader inverted>{loadingMessage}</Loader>
                    : <Grid style={{ height: '100%' }}>
                        <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                            <Grid.Column textAlign='center'>
                                <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                    <Message.Header>{title}</Message.Header>
                                    <Message.Content>
                                        {content}
                                        <Button color='grey' onClick={cancel}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color={submitColor} disabled={!isOnline} onClick={() => this.setState({ isLoading: true }, submit)}>
                                            <FontAwesomeIcon icon={submitIcon} style={{ marginRight: '10px' }} />{submitLabel}
                                        </Button>
                                    </Message.Content>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>}
            </Dimmer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};


export default connect(mapStateToProps)(DimmerConfirmation);