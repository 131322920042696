
import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
// Services
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class CustomFieldsService {
    static getDefaultFieldCategories() {
        return Axios.get(BACKEND_ENDPOINT + 'customFields/defaultFieldCategories').then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('default_fields_categories_loading_failed'); // TODO
            return;
        });
    }

    static getCustomFields() {
        return Axios.get(BACKEND_ENDPOINT + 'customFields/').then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_fields_loading_failed');
            return;
        });
    }

    static getOrganizationCustomFields() {
        return Axios.get(BACKEND_ENDPOINT + 'customFields/' + jwt_decode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_fields_loading_failed');
            return;
        });
    }

    static getProjectCustomFields(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'customFields/projects/' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_fields_loading_failed');
            return;
        });
    }

    static addCustomField(customField) {
        return Axios.post(BACKEND_ENDPOINT + 'customFields/' + customField.type + '/', customField, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_field_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_field_addition_failed');
            return;
        });
    }

    static updateCustomFields(customFields, displayToast = true) {
        return Axios.put(BACKEND_ENDPOINT + 'customFields/' + customFields[0].type + '/', customFields, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (displayToast) showToast('custom_field_updated');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast(customFields.length > 1 ? 'custom_fields_update_failed' : 'custom_field_update_failed');
            return;
        });
    }

    static deleteCustomFields(ids, displayToast = true) {
        return Axios.delete(BACKEND_ENDPOINT + 'customFields/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: ids
        }).then(response => {
            if (displayToast) showToast('custom_field_deleted');
            return response;
        }, () => {
            if (navigator.onLine) showToast(ids.length > 1 ? 'custom_fields_deletion_failed' : 'custom_field_deletion_failed');
            return;
        });
    }

    static getCustomFieldCategories() {
        return Axios.get(BACKEND_ENDPOINT + 'customFields/customFieldCategories/' + jwt_decode(new Cookies().get('token')).id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_field_categories_loading_failed');
            return;
        });
    }

    static addCustomFieldCategory(customFieldCategory) {
        return Axios.post(BACKEND_ENDPOINT + 'customFields/customFieldCategories', customFieldCategory, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_field_category_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_field_category_addition_failed');
            return;
        });
    }

    static updateCustomFieldCategories(customFieldCategories) {
        return Axios.put(BACKEND_ENDPOINT + 'customFields/customFieldCategories', customFieldCategories, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_field_category_updated');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_field_category_update_failed');
            return;
        });
    }

    static deleteCustomFieldCategory(id) {
        return Axios.delete(BACKEND_ENDPOINT + 'customFields/customFieldCategories/' + id, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
            }
        }).then(response => {
            showToast('custom_field_category_deleted');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('custom_field_category_deletion_failed');
            return;
        });
    }
}