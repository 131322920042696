import React, { Component } from 'react';
// Composants
import { Form, Button, Loader } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import L from 'leaflet';
import { withOrientationChange } from 'react-device-detect';
import { connect } from 'react-redux';
import { faChevronLeft, faChevronRight, faPause, faPlay, faStop } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../../locales/i18n';
import { setLayer } from '../../../actionCreators/elementsActions';
// Services
import ProjectsService from '../../../services/ProjectsService';
// Utils
import GeometriesUtil from '../../../utils/GeometriesUtil';

const CURRENT_YEAR = new Date().getUTCFullYear();

class TimelineForm extends Component {
    state = {
        isLoading: false,
        timelineItems: [],
        itemsPerPage: 6,
        currentPage: 1,
        maxPage: 1,
        isTimelinePlaying: false,
        isTimelinePaused: false,
        isProgressButtonLoading: false
    };

    render() {
        const { isToolbarExpanded, isOnline } = this.props;
        const { isLoading, timelineItems, isTimelinePlaying, isTimelinePaused, isProgressButtonLoading } = this.state;
        const isLastYearSelected = timelineItems[timelineItems.length - 1]?.label === this.timelineCurrentYear;

        return (
            <>
                <div className='tool-form' style={{ left: `calc(${(isToolbarExpanded ? 305 : 45) / 2}px + 50%)`, transform: 'translateX(-50%)', top: 'inherit', bottom: '20px', transition: 'left 500ms', width: '50%', zIndex: 1000 }}>
                    <Form loading={isLoading} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'start', width: '100%' }}>
                            <Button
                                style={{ width: '30px', aspectRatio: '1 / 1', padding: 0 }} color='red' title={i18n.t("Arrêter")}
                                disabled={(!isTimelinePlaying && this.timelineCurrentYear === CURRENT_YEAR) || isProgressButtonLoading} onClick={this.resetTimeline}
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </Button>
                            <Button
                                id='play-button' className={`progress-button${isTimelinePlaying && ' custom-progress'}`} style={{ width: '30px', aspectRatio: '1 / 1', padding: 0 }} color='blue'
                                title={!isTimelinePlaying ? i18n.t("Jouer") : isTimelinePaused ? i18n.t("Reprendre") : i18n.t("Mettre pause")}
                                disabled={isProgressButtonLoading || isLastYearSelected || !isOnline}
                                onClick={() => !isTimelinePlaying ? this.playTimeline() : isTimelinePaused ? this.resumeTimeline() : this.pauseTimeline()}
                            >
                                {!isProgressButtonLoading
                                    ? <FontAwesomeIcon icon={(!isTimelinePlaying || isTimelinePaused) ? faPlay : faPause} />
                                    : <Loader active inverted size='tiny' />}
                            </Button>
                        </div>
                        {!timelineItems.length ? i18n.t("Chargement en cours...")
                            : timelineItems.length === 1 ? i18n.t("Aucune donnée trouvée")
                                : this.renderTimeline()}
                    </Form>
                </div>
            </>
        )
    }

    componentDidMount = () => {
        this.isYearLoading = false;
        this.setState({ isLoading: true });
        ProjectsService.getOldestHistory(this.props.project.id).then(response => {
            if (response) {
                const firstHistory = new Date(response).getUTCFullYear();
                this.timelineCurrentYear = CURRENT_YEAR;
                const nbItems = Math.ceil((((CURRENT_YEAR + 1) - firstHistory + 1) + (2 * this.state.itemsPerPage)) / this.state.itemsPerPage) * this.state.itemsPerPage;
                const nbItemsInFuture = nbItems - (CURRENT_YEAR - firstHistory + 1);
                const latestHistory = new Date().getUTCFullYear() + nbItemsInFuture;
                this.setTimelineItems(firstHistory, latestHistory);
                const trees = this.props.layers.find(layer => layer.label === i18n.t("Arbres")).getLayers().map(layer => layer.feature);
                const greenSpaces = this.props.layers.find(layer => layer.label === i18n.t("Espaces verts")).getLayers().map(layer => layer.feature);
                const furnitures = this.props.layers.find(layer => layer.label === i18n.t("Mobilier urbain")).getLayers().map(layer => layer.feature);
                for (const key in this.props.filteredLayers) {
                    const filteredLayer = this.props.filteredLayers[key];
                    if (filteredLayer?.length)
                        filteredLayer.forEach(layer => {
                            switch (layer.feature.properties.category) {
                                case 'Arbre':
                                    if (!trees.find(tree => tree.id === layer.feature.id)) trees.push(layer.feature);
                                    break;
                                case 'Espace vert':
                                    if (!greenSpaces.find(greenSpace => greenSpace.id === layer.feature.id)) greenSpaces.push(layer.feature);
                                    break;
                                case 'Mobilier':
                                    if (!furnitures.find(furniture => furniture.id === layer.feature.id)) furnitures.push(layer.feature);
                                    break;
                                default:
                                    break;
                            }
                        });
                }

                this.props.setTimelineYears(CURRENT_YEAR, { trees, greenSpaces, furnitures });
            }

            this.setState({ isLoading: false });
        });
    }

    componentDidUpdate = () => {
        if (this.timelineCurrentYear && this.timelineCurrentYear !== this.props.currentYear) {
            const item = this.state.timelineItems.find(timelineItem => timelineItem.label === this.props.currentYear);
            if (item) this.handleYearChange(item);
            else {
                const firstYear = this.state.timelineItems[0].label;
                const lastYear = this.state.timelineItems[this.state.timelineItems.length - 1].label;
                const currentYear = this.props.currentYear < firstYear ? lastYear : firstYear;
                this.props.setCurrentYear(currentYear);
            }
        }
    }

    componentWillUnmount = () => {
        if (this.timelineInterval) clearInterval(this.timelineInterval);
        this.addElementsToMap(this.props.timelineYears[CURRENT_YEAR], CURRENT_YEAR);
        this.timelineCurrentYear = new Date().getUTCFullYear();
        this.props.setCurrentYear(this.timelineCurrentYear);
    }

    renderTimeline = () => {
        const { timelineItems, itemsPerPage, currentPage, maxPage } = this.state;
        let end = timelineItems.length - ((maxPage - currentPage) * itemsPerPage);
        end = end > 0 ? end : 0;
        end = end < timelineItems.length ? end : timelineItems.length;
        const start = (end - itemsPerPage) >= 0 ? end - itemsPerPage : 0;
        const itemsToRender = timelineItems.slice(start, end);
        const totalItems = itemsToRender.length;
        const numberOfActiveItems = itemsToRender.filter(item => item.active).length;
        const progressBarWidth = numberOfActiveItems > 0 && totalItems > 1 ? (numberOfActiveItems - 1) / (totalItems - 1) * 100 : 0;

        return (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Button className='timeline-pagination' disabled={currentPage === 1} onClick={() => this.setState(prevState => ({ currentPage: prevState.currentPage - 1 >= 1 ? prevState.currentPage - 1 : 1 }))}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className='timeline'>
                    <div className='timeline-progress' style={{ width: `${progressBarWidth}%` }}></div>
                    <div className='timeline-items'>
                        {this.renderTimelineItems(itemsToRender)}
                    </div>
                </div>
                <Button className='timeline-pagination' disabled={currentPage === maxPage} onClick={() => this.setState(prevState => ({ currentPage: prevState.currentPage + 1 <= prevState.timelineItems.length / prevState.itemsPerPage ? prevState.currentPage + 1 : maxPage }))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        );
    }

    renderTimelineItems = (itemsToRender) => {
        const timelineItems = [...this.state.timelineItems].reverse();
        const currentItem = timelineItems.find(timelineItem => timelineItem.active);

        return (
            itemsToRender.map((timelineItem, i) => {
                const isLoaded = this.props.timelineYears[timelineItem.label];
                const isDisabled = currentItem.label === timelineItem.label || (!this.props.isOnline && !isLoaded);

                return (
                    <div
                        key={i} className={`timeline-item${(timelineItem.active ? ' active' : '')}${((isDisabled && currentItem.label !== timelineItem.label) ? ' disabled' : '')}`}
                        style={{ cursor: isDisabled && 'default' }} onClick={() => { if (!isDisabled) this.handleYearChange(timelineItem) }}
                    >
                        <div className='timeline-content' style={{ cursor: isDisabled && 'default' }} onClick={() => { if (!isDisabled) this.handleYearChange(timelineItem) }}>
                            {timelineItem.label}
                        </div>
                    </div>
                );
            })
        );
    }

    setTimelineItems = (firstHistory, latestHistory) => {
        const timelineItems = [];
        for (let i = firstHistory; i <= latestHistory; i++)
            timelineItems.push({ label: i, active: i <= this.timelineCurrentYear });
        const maxPage = Math.ceil(timelineItems.length / this.state.itemsPerPage);
        this.setState({ timelineItems, currentPage: maxPage - 2, maxPage });
    }

    handleYearChange = (item, showLoader = true) => {
        const timelineItems = JSON.parse(JSON.stringify(this.state.timelineItems));
        const index = this.state.timelineItems.indexOf(item);

        if (showLoader) this.props.showLoader(true, 'map', i18n.t("Chargement des données..."));
        if (this.props.timelineYears[item.label]) this.addElementsToMap(this.props.timelineYears[item.label], item.label);
        else ProjectsService.getPointInTime(this.props.project.id, item.label).then((response) => this.addElementsToMap(response, item.label));

        timelineItems.forEach((timelineItem, i) => timelineItem.active = i <= index);
        this.timelineCurrentYear = item.label;
        this.props.setCurrentYear(item.label);
        this.setState({ timelineItems });
    }

    addElementsToMap = (elements, year) => {
        const { estimatedValues, cantEstimateValues, trees, greenSpaces, furnitures } = elements;
        const { layer } = this.props;

        // Mise en cache des données
        if (!this.props.timelineYears[year]) this.props.setTimelineYears(year, { estimatedValues, cantEstimateValues, trees, greenSpaces, furnitures });

        // Mise à jour de la carte
        const treesLayers = this.props.layers.filter(layer => layer.label === i18n.t("Arbres"));
        treesLayers.forEach(layer => layer.clearLayers());
        trees.forEach(tree => this.props.addMarker('Arbre', { _latlng: { lat: tree.geometry.coordinates[1], lng: tree.geometry.coordinates[0] } }, tree));
        this.props.updateLegend(i18n.t("Arbres"));
        this.props.updateHeatmaps();

        const greenSpacesLayers = this.props.layers.filter(layer => layer.label === i18n.t("Espaces verts"));
        greenSpacesLayers.forEach(layer => layer.clearLayers());
        greenSpaces.forEach(greenSpace => this.props.addGreenSpace(new L.polygon(GeometriesUtil.convertPolygonCoordinatesToLatLngs(greenSpace.geometry.coordinates)), greenSpace));
        this.props.updateLegend(i18n.t("Espaces verts"));

        const furnituresLayers = this.props.layers.filter(layer => layer.label === i18n.t("Mobilier urbain"));
        furnituresLayers.forEach(layer => layer.clearLayers());
        furnitures.forEach(furniture => this.props.addMarker('Mobilier', { _latlng: { lat: furniture.geometry.coordinates[1], lng: furniture.geometry.coordinates[0] } }, furniture));
        this.props.updateLegend(i18n.t("Mobilier urbain"));

        const mainFilters = this.props.mainFilters?.length ? JSON.parse(JSON.stringify(this.props.mainFilters)) : [];
        if (mainFilters.length) {
            this.props.clearFilteredLayers();
            this.props.filterLayers(this.props.mainFilters, true, false);
        } else {
            this.props.layers.forEach((layer, i) => {
                if (i !== 1 && !this.props.map.hasLayer(layer))
                    this.props.map.addLayer(layer);
            });
        }

        // Mise à jour du layer ouvert
        if (layer?.length) {
            let newLayer;
            switch (layer[0].feature.properties.category) {
                case 'Arbre':
                    newLayer = treesLayers[0].getLayers().find(l => l.feature.id === layer[0].feature.id);
                    if (newLayer) this.props.setLayer([newLayer, newLayer]);
                    break;
                case 'Espace vert':
                    newLayer = greenSpaces[0].getLayers().find(l => l.feature.id === layer[0].feature.id);
                    if (newLayer) this.props.setLayer([newLayer]);
                    break;
                case 'Mobilier':
                    newLayer = greenSpaces[0].getLayers().find(l => l.feature.id === layer[0].feature.id);
                    if (newLayer) this.props.setLayer([newLayer, newLayer]);
                    break;
                default: break;
            }

            if (!newLayer) this.props.hideForm(false);
        }

        // TODO Bug => Mettre un filtre sur la catégorie, changer d'année : Les légendes s'affiche
        // TODO Bug => Mettre un filtre sur la catégorie arbre, changer d'année, reset le filtre, revenir à 2022, remettre le filtre => les mobiliers ne sont pas retirés de la carte
        this.isYearLoading = false;
        this.setState({ isProgressButtonLoading: false }, () => this.props.showLoader(false, 'map', i18n.t("Chargement des données...")));
    }

    playTimeline = () => {
        this.setState({ isTimelinePlaying: true });
        const goToNextYear = () => {
            this.props.showLoader(true, 'map', i18n.t("Chargement des données..."));
            const button = document.getElementById('play-button');
            button.classList.remove('custom-progress');
            setTimeout(() => {
                const nextYear = this.state.timelineItems.find(timelineItem => timelineItem.label === this.timelineCurrentYear + 1);
                this.timelineCurrentYear++;
                this.handleYearChange(nextYear, false);
                const page = this.getPage(nextYear.label);
                if (page !== this.state.currentPage) this.setState({ currentPage: page });

                if (this.state.timelineItems[this.state.timelineItems.length - 1].label < this.timelineCurrentYear + 1) {
                    clearInterval(this.timelineInterval);
                    this.setState({ isProgressButtonLoading: false });
                    this.pauseTimeline();
                } else button.classList.add('custom-progress');
            }, 1000);
        }

        let time = 0;
        this.timelineInterval = setInterval(() => {
            if (!this.state.isTimelinePaused && !this.isYearLoading) time += 1000;
            if (!this.state.isTimelinePaused && !this.isYearLoading && time === 5000) {
                time = 0;
                this.isYearLoading = true;
                this.setState({ isProgressButtonLoading: true }, goToNextYear);
            }
        }, 1000);
    }

    pauseTimeline = () => {
        const button = document.getElementById('play-button');
        button.classList.add('paused');
        this.setState({ isTimelinePaused: true });
    }

    resumeTimeline = () => {
        const button = document.getElementById('play-button');
        button.classList.remove('paused');
        this.setState({ isTimelinePaused: false });
    }

    resetTimeline = () => {
        if (this.timelineInterval) clearInterval(this.timelineInterval);
        this.props.showLoader(true, 'map', i18n.t("Chargement des données..."));
        const button = document.getElementById('play-button');
        button.classList.remove('custom-progress');

        setTimeout(() => {
            const item = this.state.timelineItems.find(timelineItem => timelineItem.label === CURRENT_YEAR);
            this.handleYearChange(item, false);
            this.setState({ isTimelinePlaying: false, currentPage: this.getPage(item.label) }, () => {
                this.props.showLoader(false, 'map', i18n.t("Chargement des données..."));
            });
        }, 500);
    }

    getPage = (year) => {
        const { timelineItems, itemsPerPage, currentPage, maxPage } = this.state;
        const yearIndex = [...timelineItems].reverse().findIndex(timelineItem => timelineItem.label === year);
        if (yearIndex === -1) return currentPage;
        return maxPage - Math.floor(yearIndex / itemsPerPage);
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isToolbarExpanded: state.isToolbarExpanded,
        project: state.project,
        elementsOfProject: state.elementsOfProject,
        layer: state.layer
    };
}

const mapDispatchToProps = {
    setLayer
}

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(TimelineForm));