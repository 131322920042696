import React, { Component } from 'react';
// Composants
import DropDownWithCheckboxes from '../../Utils/DropDownWithCheckboxes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PACreationForm from '../../Forms/Actions/PACreationForm';
import InputPopupForm from '../../Utils/InputPopupForm';
import PALinkingForm from './PALinkingForm';
import PhotosCarousel from '../../Galleries/PhotosCarousel';
/*     Editors     */
import CommentEditor from '../../Tables/Editors/CommentEditor';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
import Woops from '../../Utils/Woops';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../../locales/i18n';
import { faCalendarClock, faCheck, faSquareCheck, faClock, faExclamationTriangle, faHourglassClock, faTimesCircle, faLink, faPenToSquare, faUnlink, faCamera, faImage, faPlus, faBan } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { showToast } from '../../../utils/ToastsUtil';
import tinycolor from 'tinycolor2';
// Redux
import { connect } from 'react-redux';
import { setCurrentAction, setRequest } from '../../../actionCreators/appActions';
import { setPhotosGalleries, setActionHistory } from '../../../actionCreators/elementsActions';
import { setProjectActions } from '../../../actionCreators/projectsActions';
import { setPriceLists } from '../../../actionCreators/usersActions';
// Ressources
import { faFolder, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Button, Segment, Grid, Message, Dimmer, Dropdown, Select, List, Icon } from 'semantic-ui-react';
// Services
import ActionsService from '../../../services/ActionsService';
import PriceListsService from '../../../services/PriceListsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import ActionsUtil from '../../../utils/ActionsUtil';
import DatesUtil from '../../../utils/DatesUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import StylesUtil from '../../../utils/StylesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import RightsUtil from '../../../utils/RightsUtil';
import TasksUtil from '../../../utils/TasksUtil';
import PAModificationForm from './PAModificationForm';

const initialFilters = {
    label: '',
    recurrence: '',
    price: '',
    status: ['late', 'current', 'upcoming', 'done', 'cancelled']
};

class ActionForm extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isUnlinkLoading: false,
        isLoading: true,
        projectActionToEdit: null,
        nbPaerToDelete: 0,
        nbElementsAffected: 0,
        priceLists: [],
        priceListOptions: [{ text: i18n.t("Par défaut"), value: 0 }],
        priceListId: this.props.project.priceListId || 0,
        arePriceListsLoading: true,
        showExports: false,
        rowToEdit: null,
        paToUnlink: null,
        isAdding: false,
        isLinking: false,
        actionToAdd: null,
        carouselPhoto: null,
        photos: null
    };

    render() {
        const { isOnline, rights, activeOrganization, isDarkTheme, project, projectActions } = this.props;
        const {
            data, sortColumn, sortDirection, enableFilterRow, filters, showExports, rowToEdit, paToUnlink, isAdding, isLinking, actionToAdd, carouselPhoto, photos,
            isLoading, isOverflowing, priceListOptions, priceListId, arePriceListsLoading, isUnlinkLoading, projectActionToEdit, nbPaerToDelete, nbElementsAffected
        } = this.state;
        const rows = this.getFilteredRows();
        const parentRows = rows.filter(row => !row.parentId && !row.isReplantingRow);
        const summaryRow = {
            recurrence: parentRows.map(row => row.children.length).reduce((prev, curr) => prev + curr, 0),
            price: parentRows.map(row => row.price).reduce((prev, curr) => Number(prev) + Number(curr), 0)
        };
        const projectSubscription = project.organization.subscription;
        const isExportable = RightsUtil.canExport(rights?.actions) && projectSubscription.export && activeOrganization?.subscription.export && project.type === 'project';
        let category = this.props.layer[0]?.feature?.properties.category;
        if (category === 'Espace vert' && this.props.layer[0].feature?.properties.dominantCompositionId === 7) category = 'Massif arboré';

        return (
            <>
                <div className='modal-content'>
                    {!projectActions ?
                        <Woops />
                        :
                        <>
                            {rowToEdit &&
                                <InputPopupForm
                                    title={i18n.t("Date de réalisation")} value={new Date(rowToEdit.validationDate.valueOf())}
                                    inputType='date' showCurrentValue={false} submitButtonIcon={faCheck} submitButtonLabel={i18n.t("Valider")} disabled={!this.props.isOnline}
                                    submit={this.handleValidationDateSubmit} cancel={() => this.setState({ rowToEdit: null })}
                                />}
                            <Dimmer active={paToUnlink} style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Message compact className='tableConfirmation'>
                                    <Message.Header>{i18n.t("Êtes vous certain de vouloir délier cette action ?")}</Message.Header>
                                    <Message.Content style={{ marginTop: '10px' }}>
                                        <Button color='grey' onClick={() => this.setState({ paToUnlink: null })} disabled={isUnlinkLoading}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color='red' onClick={this.handleUnlinkConfirmation} disabled={isUnlinkLoading} loading={isUnlinkLoading}>
                                            <FontAwesomeIcon icon={faUnlink} style={{ marginRight: '10px' }} />{i18n.t("Délier")}
                                        </Button>
                                    </Message.Content>
                                </Message>
                            </Dimmer>
                            <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', marginBottom: '4px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobileOnly ? 'column' : 'row', width: '100%', flex: 1, order: isMobile ? 2 : 1 }}>
                                    <div style={{ marginTop: isMobileOnly && '5px' }}>
                                        <Button.Group style={{ marginRight: '3.5px' }}>
                                            <Button
                                                title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                                className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                                onClick={this.toggleFilters}
                                            />
                                            <Button
                                                title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                                onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                            />
                                            <DropDownWithCheckboxes
                                                icon={faClock} buttonStyle={{ height: '100%', borderRadius: 0 }}
                                                options={[
                                                    { label: i18n.t("En retard"), isChecked: filters.status.includes('late'), onClick: () => this.toggleSelection('status', 'late') },
                                                    { label: i18n.t("Aujourd'hui"), isChecked: filters.status.includes('current'), onClick: () => this.toggleSelection('status', 'current') },
                                                    { label: i18n.t("Validée"), isChecked: filters.status.includes('done'), onClick: () => this.toggleSelection('status', 'done') },
                                                    { label: i18n.t("À venir"), isChecked: filters.status.includes('upcoming'), onClick: () => this.toggleSelection('status', 'upcoming') },
                                                    { label: i18n.t("Annulée"), isChecked: filters.status.includes('cancelled'), onClick: () => this.toggleSelection('status', 'cancelled') },
                                                    { label: i18n.t("Urgente uniquement"), isChecked: filters.status.includes('urgent'), onClick: () => this.toggleSelection('status', 'urgent') }
                                                ]}
                                            />
                                        </Button.Group>
                                        {!isMobileOnly &&
                                            <Button.Group style={{ marginRight: '3.5px' }}>
                                                <Button title={i18n.t("Masquer toutes les récurrences")} className='button--secondary' style={{ padding: '11px' }} onClick={this.collapseAll}>
                                                    <FontAwesomeIcon icon={faFolder} style={{ height: '12px' }} />
                                                </Button>
                                                <Button title={i18n.t("Afficher toutes les récurrences")} className='button--secondary' style={{ padding: '11px' }} onClick={this.expandAll}>
                                                    <FontAwesomeIcon icon={faFolderOpen} style={{ height: '12px' }} />
                                                </Button>
                                            </Button.Group>}
                                        {isExportable &&
                                            <>
                                                <Button
                                                    title={i18n.t("Exporter les données")} className='button--secondary' icon='download' content={i18n.t("Exporter")}
                                                    disabled={!isOnline} onClick={() => this.setState(prevState => ({ showExports: !prevState.showExports }))}
                                                />
                                                {showExports &&
                                                    <Button.Group>
                                                        <Button title='Excel' className='button--secondary' icon='file excel' disabled={!isOnline} onClick={this.exportTableAsXLSX} />
                                                        <Button title='Fiche PDF' className='button--secondary' icon='file pdf' disabled={!isOnline} onClick={this.exportTableAsPDF} />
                                                    </Button.Group>}
                                            </>}
                                    </div>
                                </div>
                                {!isMobileOnly &&
                                    <div style={{ marginLeft: 'auto', order: isMobile ? 1 : 2, marginBottom: isMobile && '5px' }}>
                                        {project.type === 'project' &&
                                            <Select
                                                style={{ marginRight: '5px' }} disabled={arePriceListsLoading} loading={arePriceListsLoading}
                                                options={priceListOptions} value={priceListId} onChange={(_, { value }) => this.setState({ priceListId: value })}
                                            />}
                                        {project.type === 'project' && !isMobile && RightsUtil.canWrite(this.props.rights?.actions) && this.renderNewActionButton()}
                                    </div>}
                            </div>
                            <div className='modal-content-body'>
                                <Segment id='action-table__segment' style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                                    {data?.columns &&
                                        <>
                                            <DataGrid
                                                ref={this.gridRef} className={isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                                columns={data.columns}
                                                rows={rows} rowRenderer={this.rowRenderer} rowClass={(row) => row.parentId ? 'child-row' : 'parent-row'}
                                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                                cellNavigationMode='LOOP_OVER_ROW'
                                                sortColumn={sortColumn} sortDirection={sortDirection}
                                                onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                                filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                                    <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                                </div>)}
                                                onSelectedCellChange={({ idx, rowIdx }) => { this.selectedRow = rows[rowIdx]; this.selectedColumn = data.columns[idx]; }}
                                                onRowsChange={this.handleRowsChange}
                                                summaryRows={[summaryRow]}
                                                style={{ height: (72 + (35 * rows.length) + (enableFilterRow ? 45 : 0) + (isOverflowing ? 10 : 0)) + 'px' }}
                                            />
                                            {project.type === 'project' &&
                                                <>
                                                    <ContextMenu id='grid-parent-context-menu'>
                                                        <MenuItem disabled={!this.props.isOnline} onClick={this.editProjectAction}>{i18n.t("Modifier")}</MenuItem>
                                                        <MenuItem disabled={!this.props.isOnline} onClick={this.cancelRecurrence}>{i18n.t("Annuler")}</MenuItem>
                                                        {isExportable && isOnline && <MenuItem onClick={this.exportActionAsPDF}>{i18n.t("Exporter")}</MenuItem>}
                                                        {RightsUtil.canWrite(rights?.actions) && <MenuItem onClick={this.handleUnlink}>{i18n.t("Délier")}</MenuItem>}
                                                    </ContextMenu>
                                                    <ContextMenu id='grid-parent-context-menu-recurrent'>
                                                        {isExportable && isOnline && <MenuItem onClick={this.exportActionAsPDF}>{i18n.t("Exporter")}</MenuItem>}
                                                        {RightsUtil.canWrite(rights?.actions) && <MenuItem onClick={this.handleUnlink}>{i18n.t("Délier")}</MenuItem>}
                                                    </ContextMenu>
                                                    <ContextMenu id='grid-child-context-menu'>
                                                        <MenuItem disabled={!this.props.isOnline} onClick={this.cancelRecurrence}>{i18n.t("Annuler")}</MenuItem>
                                                    </ContextMenu>
                                                </>}
                                        </>}
                                </Segment>
                            </div>
                            {(isAdding || isLinking || projectActionToEdit || isMobile) &&
                                <div className='modal-content-footer'>
                                    {isMobile && !isAdding && !isLinking && this.renderNewActionButton(isMobile)}
                                    {(isAdding || isLinking || projectActionToEdit) &&
                                        <Segment style={{ marginTop: '12px' }}>
                                            {isAdding &&
                                                <PACreationForm
                                                    actionToAdd={actionToAdd} autoLink={true} category={category}
                                                    cancel={this.handleCancel} submit={this.handleAddConfirmation}
                                                />}
                                            {isLinking &&
                                                <PALinkingForm
                                                    updateLegend={this.props.updateLegend}
                                                    treesLayer={this.props.treesLayer} greenSpacesLayer={this.props.greenSpacesLayer} furnituresLayer={this.props.furnituresLayer}
                                                    cancel={this.handleCancel} submit={this.handleLinkConfirmation} fieldList={this.props.fieldList}
                                                />}
                                            {projectActionToEdit &&
                                                <PAModificationForm
                                                    projectAction={projectActionToEdit} cancel={() => this.setState({ projectActionToEdit: null })}
                                                    submit={(projectActionToEdit, nbPaerToDelete) => {
                                                        const nbElementsAffected = projectActionToEdit.projectActionElements.length - 1;

                                                        this.setState({ projectActionToEdit, nbPaerToDelete, nbElementsAffected }, () => {
                                                            if (!nbPaerToDelete && !this.state.nbElementsAffected) this.handleModificationConfirmation();
                                                        })
                                                    }}
                                                />}
                                        </Segment>}
                                </div>}
                        </>}
                    {carouselPhoto && <div id='photo-carousel'><PhotosCarousel photos={photos} selectedPhoto={carouselPhoto} close={() => this.setState({ carouselPhoto: null })} /></div>}
                </div>
                {(nbPaerToDelete > 0 || nbElementsAffected > 0) &&
                    <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed' }}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Content style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Message icon error style={{ textAlign: 'left' }}>
                                                <Icon name='trash' />
                                                <Message.Content>
                                                    <Message.Header style={{ marginBottom: '5px' }}>
                                                        {i18n.t("Êtes-vous certain de vouloir modifier cette action ?")}
                                                    </Message.Header>
                                                    {nbPaerToDelete > 0 &&
                                                        <>
                                                            {i18n.t("Cela engendrera")}
                                                            <List as='ul' style={{ marginTop: 0, marginBottom: '5px' }}>
                                                                <List.Item as='li' className='error'>{nbPaerToDelete > 1 ? i18n.t("La suppression de {{count}} récurrences existantes.", { count: nbPaerToDelete }) : i18n.t("La suppression d'une récurrence existante.")}</List.Item>
                                                                <List.Item as='li' className='error'>{nbPaerToDelete > 1 ? i18n.t("La suppression des photos liées aux {{count}} récurrences.", { count: nbPaerToDelete }) : i18n.t("La suppression des photos liées à la récurrence.")}</List.Item>
                                                            </List>
                                                        </>}
                                                    {nbElementsAffected > 0 && (nbElementsAffected > 1 ? i18n.t("Attention modification portant sur {{count}} autres sujets.", { count: nbElementsAffected }) : i18n.t("Attention modification portant sur un autre sujet."))}
                                                </Message.Content>
                                            </Message>
                                            <div style={{ marginLeft: 'auto' }}>
                                                <Button color='red' onClick={this.handleModificationCancel}>
                                                    {i18n.t("Annuler")}
                                                </Button>
                                                <Button color='green' onClick={this.handleModificationConfirmation}>
                                                    {i18n.t("Valider")}
                                                </Button>
                                            </div>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid >
                    </Dimmer >}
            </>
        );
    }

    componentDidMount = async () => {
        this.cutDownColors = [
            tinycolor(document.body.style.getPropertyValue('--red-100')),
            tinycolor(document.body.style.getPropertyValue('--red-100')),
            tinycolor(document.body.style.getPropertyValue('--red-100'))
        ];
        this.cutDownColors[0].setAlpha(.45); this.cutDownColors[1].setAlpha(.40); this.cutDownColors[2].setAlpha(.30);
        window.addEventListener('resize', this.handleResize);

        const setPriceLists = (priceLists) => {
            if (!priceLists) priceLists = [];
            if (this.props.project.priceList && !priceLists.find(priceList => priceList.id === this.props.project.priceListId))
                priceLists.push(this.props.project.priceList)
            this.setState(prevState => ({
                arePriceListsLoading: false, priceLists,
                priceListOptions: [...prevState.priceListOptions, ...priceLists.map(priceList => ({ text: priceList.label, value: priceList.id }))]
            }));
        };

        if (!this.props.priceLists)
            PriceListsService.getPriceLists().then(priceLists => {
                if (priceLists) this.props.setPriceLists([...priceLists]);
                setPriceLists(priceLists);
            });
        else setPriceLists(this.props.priceLists)

        this.loadData().then(() => {
            const rowToExpand = this.props.actionToShow?.paerId && this.state.data.rows.find(row => row.id === this.props.actionToShow?.paId && row.children?.length !== 1);
            if (rowToExpand) this.toggleSubRows([rowToExpand.id]);
        });

        this.gridRef = React.createRef();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.projectActions) !== JSON.stringify(this.props.projectActions)) {
            const expandedRows = this.state.data.rows.filter(row => row.isExpanded).map(row => row.id);
            this.loadData().then(() => { this.toggleSubRows(expandedRows) });
        }

        if (prevProps.layer[0].feature.id !== this.props.layer[0].feature.id) this.loadData();

        const { isOverflowing, data } = this.state;
        if (isOverflowing === undefined && data?.columns) {
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                this.setState({ isOverflowing: gridElement.clientWidth < width });
            }
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('resize', this.handleResize);
        this.props.resetActionToShow();
    }

    renderNewActionButton = (isMobile) => {
        return RightsUtil.canWrite(this.props.rights?.actions) ? (
            <>
                {isMobile ?
                    <Grid>
                        <Grid.Row columns={isMobileOnly ? 1 : 2}>
                            <Grid.Column style={!isMobileOnly ? { paddingRight: 0 } : null}>
                                <Button
                                    color='green' className='form-button' onClick={this.handleAdd}
                                    style={!isMobileOnly
                                        ? { textAlign: 'center', fontWeight: 'bold', width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                                        : { textAlign: 'center', fontWeight: 'bold', width: '100%' }
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Ajouter une nouvelle action")}
                                </Button>
                            </Grid.Column>
                            <Grid.Column style={!isMobileOnly ? { paddingLeft: 0 } : null}>
                                <Button
                                    color='blue' className='form-button' onClick={this.handleLink}
                                    style={!isMobileOnly
                                        ? { textAlign: 'center', fontWeight: 'bold', width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }
                                        : { textAlign: 'center', fontWeight: 'bold', width: '100%' }
                                    }
                                >
                                    <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Lier l'élément à une action")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    :
                    <Dropdown
                        text={i18n.t("Nouvelle action")} item floating button direction='left' className={`button--primary${isMobile ? ' form-button' : ''}`}
                        style={{ textAlign: isMobile && 'center', marginLeft: 'auto' }}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleAdd}>
                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Créer")}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={this.handleLink}>
                                <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Lier")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>}
            </>
        ) : null;
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    rowRenderer = (props) => (
        <>
            {props.row.parentId ?
                <ContextMenuTrigger id={'grid-child-context-menu'} collect={() => ({ rowIdx: props.rowIdx })}>
                    <GridRow {...props} key={props.row.id}
                        className={(this.props.actionToShow?.paerId && this.props.actionToShow?.paerId === props.row.id) || props.row.replantingDate ? 'highlighted' : null}
                        ref={node => {
                            if (this.props.actionToShow?.paerId && this.props.actionToShow?.paerId === props.row.id) node?.style?.setProperty('background-color', tinycolor(document.body.style.getPropertyValue('--blue-120')), 'important');
                            else if (props.row.replantingDate) node?.style?.setProperty('background-color', this.cutDownColors[props.row.parentId ? 1 : 2], 'important');
                        }}
                    />
                </ContextMenuTrigger>
                :
                <ContextMenuTrigger id={`grid-parent-context-menu${props.row.isReccurent ? '-recurrent' : ''}`} collect={() => ({ rowIdx: props.rowIdx })}>
                    <GridRow {...props} key={props.row.id}
                        className={((!this.props.actionToShow?.paerId || props.row.children?.length === 1) && this.props.actionToShow?.paId === props.row.id) || props.row.replantingDate ? 'highlighted' : null}
                        ref={node => {
                            if ((!this.props.actionToShow?.paerId || props.row.children?.length === 1) && this.props.actionToShow?.paId === props.row.id) node?.style?.setProperty('background-color', tinycolor(document.body.style.getPropertyValue('--blue-120')), 'important');
                            else if (props.row.replantingDate) node?.style?.setProperty('background-color', this.cutDownColors[props.row.parentId ? 1 : 2], 'important');
                        }}
                    />
                </ContextMenuTrigger>}
        </>
    );

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getRows = () => {
        const groupedActions = this.props.projectActions
            .filter(projectAction => projectAction.projectActionElements?.find(pae => pae.elementId === this.props.layer[0].feature.id))
            .reduce((previousValue, projectAction) => {
                const pae = projectAction.projectActionElements.find(pae => pae.elementId === this.props.layer[0].feature.id);
                (previousValue[pae.replantingDate || 'none'] = previousValue[pae.replantingDate || 'none'] || []).push(projectAction);
                return previousValue;
            }, {});

        const rows = Object.keys(groupedActions).sort((a, b) => a === 'none' ? -1 : b === 'none' ? 1 : new Date(b) - new Date(a))
            .flatMap(replantingDate => groupedActions[replantingDate].map(pa => {
                const category = ({ 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures' })[this.props.layer[0].feature.category];
                const startDate = DatesUtil.getFormattedLocaleDateString(pa.startDate);
                const endDate = DatesUtil.getFormattedLocaleDateString(pa.endDate);
                const recurrence = ActionsUtil.getRecurrencesInfos(pa);

                return {
                    id: pa.id,
                    category,
                    replantingDate: replantingDate !== 'none' && replantingDate,
                    label: pa.action.label,
                    isUrgent: pa.isUrgent,
                    action: pa.action,
                    projectActionElements: pa.projectActionElements,
                    recurrence: !recurrence.includes('1x')
                        ? `${startDate} - ${endDate}, ${recurrence}`
                        : `${startDate} (1x)`,
                    isReccurent: pa.startDate !== pa.endDate,
                    children: pa.projectActionElements
                        .filter(pae => pae.elementId === this.props.layer[0].feature.id)
                        .flatMap(pae => pae.projectActionElementRecurrences.map(paer => ({
                            id: paer.id,
                            category,
                            replantingDate: replantingDate !== 'none' && replantingDate,
                            parentId: pa.id,
                            elementId: pae.elementId,
                            status: paer.status,
                            date: paer.date,
                            validationDate: paer.status === 'done' && (paer.validationDate || paer.date),
                            comment: paer.comment
                        })))
                };
            }));

        return rows.map(row => ({ ...row, children: row.children?.sort((a, b) => new Date(a.date) - new Date(b.date)) }));
    }

    loadData = ({ rows } = {}) => new Promise(resolve => {
        const data = {
            columns: [],
            rows: rows || []
        };

        const checkRecurrence = (paer) => {
            const today = DatesUtil.getToday();
            if (!paer) return 0;
            else if (paer.status === 'done') return 2; // Faite
            else if (paer.status === 'cancelled') return 5; // Annulée
            else if (DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(paer.date), today) > 0) return 1; // A venir
            else if (DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(paer.date), today) < 0) return 4; // En retard
            else return 3; // En cours (aujourd'hui)
        };

        const getExpandFormatter = (childRows, value, expanded, onCellExpand) => {
            const globalStatus = Math.max(...childRows.map(row => checkRecurrence(row)));
            const expander = globalStatus === 5 ? { title: i18n.t("Annulée"), color: '#7d7d7d' }
                : globalStatus === 4 ? { title: i18n.t("En retard"), color: '#c83030' }
                    : globalStatus === 3 ? { title: i18n.t("Aujourd'hui"), color: '#daa817' }
                        : globalStatus === 2 ? { title: i18n.t("À jour"), color: '#68bd46' }
                            : { title: i18n.t("À venir"), color: '#c83030' };

            return (
                <div className='expandable'>
                    <div className='rdg-cell-value'>
                        <div>{value}</div>
                    </div>
                    <div className='expand-formatter'>
                        <span title={expander.title} onClick={e => { e.stopPropagation(); onCellExpand() }}>
                            <span tabIndex={-1} className='expander' style={{ color: expander.color }}>
                                {expanded ? '\u25BC' : '\u25B6'}
                            </span>
                        </span>
                    </div>
                </div>
            )
        };

        const getStatusButton = (row) => {
            const { rights } = this.props;
            const { parentId, date, status } = row;
            const projectSubscription = this.props.project.organization.subscription;
            const paerDate = DatesUtil.convertUTCDateToDate(date);
            const nbDays = DatesUtil.daysBetweenTwoDates(paerDate, new Date());
            const color = status === 'done' ? 'green' : status === 'cancelled' ? 'grey' : nbDays === 0 ? 'yellow' : nbDays < 0 ? 'red' : 'blue';
            const title = status ? i18n.t("Cliquer pour invalider l'action") : i18n.t("Cliquer pour valider l'action");
            const icon = status === 'done' ? faSquareCheck : status === 'cancelled' ? faBan : nbDays === 0 ? faClock : nbDays < 0 ? faHourglassClock : faCalendarClock;
            const content = status === 'done' ? DatesUtil.getFormattedLocaleDateString(row.validationDate) : status === 'cancelled' ? i18n.t("Annulée") : nbDays === 0 ? i18n.t("Aujourd'hui") : nbDays < 0 ? i18n.t("En retard") : i18n.t("À venir");

            // Recherche de la currentRecurrence (2)
            const disabled = !RightsUtil.canWrite(rights?.actions) || !projectSubscription.actions || this.props.project.type !== 'project' ? true : false;

            return (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                    <Button
                        color={color} title={title} style={{ padding: '6px 20px', flexGrow: 1, margin: '0 5px' }} disabled={disabled}
                        onClick={() => { if (!disabled) this.updateProjectActionElementRecurrence(parentId, row, !status ? 'done' : null) }}
                        onContextMenu={(e) => { e.stopPropagation(); e.preventDefault(); if (!disabled) this.updateProjectActionElementRecurrence(parentId, row, !status ? 'cancelled' : null) }}
                    >
                        <FontAwesomeIcon icon={icon} style={{ marginRight: '7px' }} />{content}
                    </Button>
                    {status === 'done' &&
                        <Button
                            color='yellow' title={i18n.t("Modifier")} disabled={!row.validationDate || this.props.project.type !== 'project' || !this.props.isOnline || !RightsUtil.canWrite(this.props.rights?.actions)}
                            style={{ padding: '6px' }} onClick={() => this.showEditValidationDate(row)}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>}
                </div >
            );
        };

        const getLabel = (row) => (
            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {row.isUrgent && <FontAwesomeIcon icon={faExclamationTriangle} title={i18n.t("Urgente")} style={{ color: 'rgb(255,165,0)', marginRight: '7px', alignSelf: 'center' }} />}
                {row.label}
            </div>
        );

        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'label', width: 300, sortable: true,
                formatter: (props) => {
                    const row = props.row.children?.length === 1 ? props.row.children[0] : props.row;

                    return (!row.parentId
                        ? row.children?.length
                            ? getExpandFormatter(row.children, getLabel(props.row), row.isExpanded === true, () => this.toggleSubRows([row.id]))
                            : getLabel(props.row)
                        :
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            {props.row.children?.length === 1 && getLabel(props.row)}
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%', marginLeft: 'auto' }}>
                                <Button
                                    as='label' htmlFor={`file-input-${row.id}`}
                                    color='green' title={i18n.t("Ajouter des photos")} style={{ padding: '6px', height: '25px', width: '25px' }}
                                    disabled={/*replantingDate !== 'none' ||*/ this.state.arePhotosLoading || this.props.project.type !== 'project'}
                                >
                                    <FontAwesomeIcon icon={faCamera} />
                                </Button>
                                <input type='file' id={`file-input-${row.id}`} accept='.png,.jpg,.jpeg' multiple hidden onChange={({ target }) => {
                                    this.uploadPhotos(Array.from(target.files), row.id);
                                    target.value = '';
                                }} />
                                <Button
                                    color='yellow' title={i18n.t("Consulter les photos")} style={{ padding: 0, height: '25px', width: '25px' }}
                                    disabled={!(this.state.photos?.filter(photo => photo.recurrenceId === row.id) || []).length}
                                    onClick={() => this.setState({ carouselPhoto: (this.state.photos?.filter(photo => photo.recurrenceId === row.id) || [])[0] })}
                                >
                                    <FontAwesomeIcon icon={faImage} />
                                </Button>
                            </div>
                        </div>);
                },
                summaryFormatter: () => <div className='subheader total'>{i18n.t("Total")}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Date(s)"), key: 'recurrence', width: 325, sortable: true,
                formatter: (props) => {
                    const row = props.row.children?.length === 1 ? props.row.children[0] : props.row;
                    return !row.parentId
                        ? row.recurrence
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex' }}>
                                <div title={i18n.t("Date planifiée")} style={{ width: '110px' }}>
                                    <FontAwesomeIcon icon={faCalendarClock} style={{ marginRight: '5px' }} />
                                    {DatesUtil.getFormattedLocaleDateString(row.date)}
                                </div>
                            </div>
                            {getStatusButton(row)}
                        </div>
                },
                summaryFormatter: (props) => <div className='subheader total'>{props.row.recurrence}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Prix"), key: 'price', width: 140, sortable: true,
                formatter: (props) => {
                    const priceList = this.state.priceLists.find(priceList => priceList.id === this.state.priceListId);
                    const currency = this.props.currencies.find(currency => currency.id === priceList?.currencyId);
                    return props.row.price + (currency?.symbol || currency?.code || '€');
                },
                summaryFormatter: (props) => {
                    const priceList = this.state.priceLists.find(priceList => priceList.id === this.state.priceListId);
                    const currency = this.props.currencies.find(currency => currency.id === priceList?.currencyId);
                    return (<div className='subheader total'>{`${props.row.price}${currency?.symbol || currency?.code || '€'}`}</div>);
                },
                filterRenderer: (props) => <NumberFilter p={props} />
            },
            {
                name: i18n.t("Commentaire"), key: 'comment', width: 600,
                sortable: true,
                formatter: (props) => {
                    const row = props.row.children?.length === 1 ? props.row.children[0] : props.row;
                    return row.comment || (row.parentId || row.children?.length === 1 ? <i style={{ color: 'var(--grey-100)' }}>Ajouter un commentaire</i> : '');
                },
                summaryFormatter: () => <div className='subheader total'></div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editable: (props) => props.parentId || props.children?.length === 1 ? true : false,
                editor: ({ row, onRowChange, onClose }) => <CommentEditor row={row.children?.length === 1 ? row.children[0] : row} onRowChange={onRowChange} onClose={onClose} updateProjectActionElementRecurrence={this.updateProjectActionElementRecurrence} />
            }
        ];

        if (this.props.project.type === 'project' && RightsUtil.canWrite(this.props.rights?.actions))
            data.columns.splice(0, 0, {
                name: '', key: 'actions', width: 110,
                colSpan: (props) => props.row?.isReplantingRow ? 5 : 1,
                formatter: (props) => props.row.isReplantingRow ?
                    <div style={{ fontWeight: 'bold', fontSize: '13pt' }}>
                        {`${i18n.t("Avant replantation du")} ${props.row.replantingDate}`}
                    </div>
                    :
                    <>
                        {!props.row.parentId &&
                            <>
                                <Button
                                    color='blue' title={i18n.t("Délier l'action")} style={{ padding: 0, height: '25px', width: '25px' }}
                                    onClick={() => this.handleUnlink(props.row.id)}
                                >
                                    <FontAwesomeIcon icon={faUnlink} />
                                </Button>
                                {!props.row.isReccurent &&
                                    <Button color='yellow' title={i18n.t("Modifier")} style={{ padding: 0, height: '25px', width: '25px' }} disabled={!this.props.isOnline} onClick={() => this.editProjectAction(null, props)}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </Button>}
                            </>}
                    </>,
                summaryFormatter: () => <div className='subheader total'></div>,
            });

        if (!rows) {
            data.rows = this.getRows();

            const paerList = this.props.projectActions.flatMap(pa => (
                pa.projectActionElements.flatMap(pae => pae.projectActionElementRecurrences)
            ));

            const initialOrder = [];
            data.rows.forEach(row => {
                initialOrder.push(row.id);
                row.children.forEach(childRow => initialOrder.push(`${row.id}|${childRow.id}`));
            });

            this.setState({ data, paerList, initialOrder, isLoading: false }, () => { this.loadPhotos(); resolve(); });
        } else resolve(data);
    });

    setPeriod = (period) => this.setState({ period });
    toggleSelection = (property, element) => {
        const { filters } = this.state;
        if (filters[property].includes(element)) filters[property] = filters[property].filter(e => e !== element);
        else filters[property].push(element);
        this.setState({ filters });
    }

    updateProjectActionElementRecurrence = (paId, paerToUpdate, status, validationDate) => {
        const categories = {
            'Arbre': {
                legendName: i18n.t("Arbres"),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                legendName: i18n.t("Espaces verts"),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                legendName: i18n.t("Mobilier urbain"),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };

        const paer = this.state.paerList.find(paer => paer.id === paerToUpdate.id);
        paer.validationDate = status === 'done' ? validationDate || DatesUtil.getUTCDate() : null;
        paer.status = status; // Met à jour la liste dans le state mais aussi la récurrence dans Redux (même objet)
        paer.comment = paerToUpdate.comment;

        const actionId = this.props.projectActions.find(pa => pa.id === paId)?.action.id;
        if (actionId < 4 && this.props.layer[0].feature.properties.category === 'Arbre') {
            this.props.layer.forEach(layer => {
                let toCutDown = 4;
                if (!status) { // Si on invalide l'action
                    let cutDownProjectActions = this.props.projectActions.filter(pa => pa.action.id < 4);
                    cutDownProjectActions.forEach(pa => { // Pour chaque action abattage du projet
                        // Si l'élément est lié à l'action d'abattage, on modifie le 'toCutDown'
                        if (pa.projectActionElements.find(pae => pae.elementId === this.props.layer[0].feature.id) && (pa.action.id > toCutDown || toCutDown === 4))
                            toCutDown = pa.action.id;
                    });
                }
                layer.feature.properties = { ...layer.feature.properties, toCutDown };
            });
        }

        const { legendName, getStyle } = categories[this.props.layer[0].feature.properties.category];
        this.props.layer.forEach(layer => layer.setStyle(getStyle(layer)));
        this.props.updateLegend(legendName);

        ActionsService.updateRecurrences(this.props.project.id, paId, [paer]).then(response => {
            if (response?.recurrences) WebSocketUtil.updateRecurrences(this.props.webSocketHubs, this.props.project.id, response.recurrences);
            const history = response?.histories?.[0];
            if (history) {
                if (this.props.actionHistory) this.props.setActionHistory([...this.props.actionHistory, history]);
                if (this.props.project) WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, [history]);
            }

            // Mise à jour des rows
            this.setState(prevState => {
                const rows = [...prevState.data.rows];
                const parentRow = rows.find(row => row.id === paerToUpdate.parentId);
                const childRowInParent = parentRow.children.find(row => row.id === paerToUpdate.id);
                childRowInParent.status = paer.status; childRowInParent.comment = paer.comment; childRowInParent.validationDate = paer.validationDate;
                const childRow = rows.find(row => row.parentId && row.id === paerToUpdate.id);
                if (childRow) { childRow.status = paer.status; childRow.comment = paer.comment; childRow.validationDate = paer.validationDate; }
                return { data: { columns: prevState.data.columns, rows }, rowToEdit: null };
            });
        });
    }

    getRowsForExport = () => (
        this.getFilteredRows()
            .filter(row => !row.parentId && !row.isReplantingRow)
            .flatMap(row => [row, ...(row.children || [])])
            .map(row => {
                const nbDays = DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(row.date), new Date());
                const status = row.status === 'done' ? i18n.t("Validée") : row.status === 'cancelled' ? i18n.t("Annulée") : nbDays === 0 ? i18n.t("Aujourd'hui") : nbDays < 0 ? i18n.t("En retard") : i18n.t("À venir");
                return {
                    ...row, status,
                    date: row.date ? DatesUtil.getFormattedLocaleDateString(row.date) : '',
                    category: row.category && (row.category === 'trees' ? i18n.t("Arbre") : row.category === 'greenspaces' ? i18n.t("Espace vert") : i18n.t("Mobilier"))
                };
            })
    );

    exportTableAsPDF = () => {
        this.setState(prevState => ({ showExports: !prevState.showExports }));
        this.props.setCurrentAction('exportingBacklog');
        const { projectReference, customReference } = this.props.layer[0].feature.properties;
        this.props.exportActionTableAsPDF(this.getRowsForExport(), { reference: `${projectReference}${customReference ? ` (${customReference})` : ''}` });
    }

    exportTableAsXLSX = () => {
        this.setState(prevState => ({ showExports: !prevState.showExports }));
        const { projectReference, customReference } = this.props.layer[0].feature.properties;
        ActionsService.exportBacklogAsXLSX(`${projectReference}${customReference ? ` (${customReference})` : ''}`, this.getRowsForExport());
    }

    exportActionAsPDF = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectAction = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.props.setCurrentAction('exportingAction');
        this.props.exportActionAsPDF(projectAction);
    }

    handleAdd = (action = null) => this.setState({ isLinking: false, isAdding: true, actionToAdd: action });
    handleAddConfirmation = (projectAction) => {
        const { layer } = this.props;

        this.setState({ isLinking: false, isAdding: false });

        const categories = {
            'Arbre': {
                legendName: i18n.t("Arbres"),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                legendName: i18n.t("Espaces verts"),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                legendName: i18n.t("Mobilier urbain"),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };

        const category = layer[0].feature.properties.category;
        const { getStyle, legendName } = categories[category];

        // Mise à jour du statut d'abattage
        if (projectAction.action.id < 4 && category === 'Arbre' && (!layer[0].feature.properties.toCutDown || layer[0].feature.properties.toCutDown < projectAction.action.id))
            layer.forEach(layer => layer.feature.properties = { ...layer.feature.properties, toCutDown: projectAction.action.id });

        // Mise à jour de l'urgence d'action
        const projectActionElementRecurrences = projectAction.projectActionElements.find(pae => pae.elementId === layer[0].feature.id)?.projectActionElementRecurrences;
        const actionsUrgency = ActionsUtil.getActionUrgency(layer[0].feature.properties.actionsUrgency, projectAction, projectActionElementRecurrences);
        if (layer[0].feature.properties.actionsUrgency < actionsUrgency)
            layer.forEach(layer => layer.feature.properties = { ...layer.feature.properties, actionsUrgency });

        // Mise à jour style/légende au cas où carte thématique sur actions
        this.props.layer.forEach(layer => layer.setStyle(getStyle(layer)));
        this.props.updateLegend(legendName);

        // this.setRecommendedTechniques();
    }

    handleLink = () => this.setState({ isLinking: true, isAdding: false });
    handleLinkConfirmation = (projectAction, link = false) => {
        const linkPA = (pa) => {
            // On ajoute l'action dans redux
            pa.action = this.props.actions.find(x => x.id === pa.actionId);
            // this.setRecommendedTechniques();
            const projectActions = ActionsUtil.pushProjectActionElements(pa, this.props.projectActions);
            this.props.setProjectActions([...projectActions]).then(() => {
                const expandedRows = this.state.data.rows.filter(row => row.isExpanded).map(row => row.id);
                this.loadData().then(() => { this.toggleSubRows(expandedRows) });
            });
            WebSocketUtil.updateProjectActions(this.props.webSocketHubs, this.props.project.id, [pa]);
        };

        if (!link) linkPA(projectAction);
        else {
            const paeToAdd = [{ elementId: this.props.layer[0].feature.id, elementType: this.props.layer[0].feature.properties.category }];
            ActionsService.updateProjectAction(projectAction, this.props.project.id, { paeToAdd, successToast: 'action_linked_single', errorToast: 'connection_failed' }).then(response => {
                if (response?.history) {
                    if (this.props.actionHistory) this.props.setActionHistory([...this.props.actionHistory, ...response.history]);
                    if (this.props.project) WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, response.history);
                }

                if (response?.projectAction) {
                    linkPA(response.projectAction);
                    projectAction.projectActionElements = response.projectAction.projectActionElements;
                }
            });
        }
    }

    handleUnlink = (projectActionId) => this.setState({ isLinking: false, isAdding: false, paToUnlink: projectActionId });
    handleUnlinkConfirmation = () => {
        const { paToUnlink } = this.state;
        this.setState({ isUnlinkLoading: true });

        const categories = {
            'Arbre': {
                legendName: i18n.t("Arbres"),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                legendName: i18n.t("Espaces verts"),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                legendName: i18n.t("Mobilier urbain"),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };

        const projectAction = this.props.projectActions.find(projectAction => projectAction.id === paToUnlink);
        const paeToRemove = projectAction.projectActionElements.filter(pae => pae.elementId === this.props.layer[0].feature.id);
        ActionsService.updateProjectAction(projectAction, this.props.project.id, { paeToRemove, successToast: 'action_unlinked', errorToast: 'action_unlink_failed' }).then(response => {
            if (response?.history) {
                if (this.props.actionHistory) this.props.setActionHistory([...this.props.actionHistory, ...response.history]);
                if (this.props.project) WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, response.history);
            }

            projectAction.projectActionElements = response.projectAction.projectActionElements;
            WebSocketUtil.updateProjectActions(this.props.webSocketHubs, this.props.project.id, [projectAction]);

            this.setState(prevState => ({ data: { ...prevState.data, rows: prevState.data.rows.filter(row => row.id !== paToUnlink && row.parentId !== paToUnlink) } }));
            this.props.setProjectActions([...this.props.projectActions]);

            const category = this.props.layer[0].feature.properties.category;
            const { legendName, getStyle } = categories[category];

            // Mise à jour du statut d'abattage
            if (projectAction.action.id < 4 && category === 'Arbre')
                this.props.layer.forEach(layer => delete layer.feature.properties.toCutDown);
            // Mise à jour de l'urgence d'action
            const actionsUrgency = ActionsUtil.getElementActionsUrgency(this.props.layer[0].feature.id, this.props.projectActions, category);
            this.props.layer.forEach(layer => layer.feature.properties = { ...layer.feature.properties, actionsUrgency });
            // Mise à jour style/légende au cas où carte thématique sur actions
            this.props.layer.forEach(layer => layer.setStyle(getStyle(layer)));
            this.props.updateLegend(legendName);

            const photos = this.props.photosGalleries.filter(photoInGalleries => !this.state.photos.find(photo => photo.id === photoInGalleries.id));
            this.props.setPhotosGalleries(photos);
            this.setState({ photos: photos.filter(photo => photo.recurrenceId), isUnlinkLoading: false, paToUnlink: null }/*, this.setRecommendedTechniques*/);
        });
    }

    handleCancel = () => this.setState({ isLinking: false, isAdding: false });
    handleModificationCancel = () => this.setState({ projectActionToEdit: this.props.projectActions.find(pa => pa.id === this.state.projectActionToEdit.id), nbPaerToDelete: 0, nbElementsAffected: 0 });
    handleModificationConfirmation = () => {
        ActionsService.updateProjectAction(this.state.projectActionToEdit, this.props.project.id).then(({ projectAction, history }) => {
            if (history) {
                if (this.props.actionHistory) this.props.setActionHistory([...this.props.actionHistory, ...history]);
                if (this.props.project) WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, history);
            }

            const projectActions = JSON.parse(JSON.stringify(this.props.projectActions));
            const index = projectActions.findIndex(pa => pa.id === projectAction.id);
            if (index !== -1) {
                projectActions[index] = projectAction;
                this.props.setProjectActions(projectActions);
                WebSocketUtil.updateProjectActions(this.props.webSocketHubs, this.props.project.id, [projectAction]);
            }

            this.setState({ projectActionToEdit: null, nbPaerToDelete: 0, nbElementsAffected: 0 });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property] && property !== 'elements') filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];
        let { priceListId } = this.state;

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        const rowMatchesFilters = (row) => {
            return (filters.label ? $(row.label)?.includes($(filters.label)) : true)
                && (filters.recurrence ? $(row.recurrence)?.includes($(filters.recurrence)) : true)
                && (filters.price ? row.price === Number(filters.price) : true)
        };
        const parentMatchesFilters = (parentId) => {
            const parentRow = rows.filter(row => row.children).find(row => row.id === parentId);
            return rowMatchesFilters(parentRow);
        };

        const priceList = this.state.priceLists.find(priceList => priceList.id === priceListId);
        rows = rows
            // On retire les lignes qui ne respectent pas la catégorie et les lignes enfants affichées si elles ne sont pas dans la bonne période
            .filter(row => {
                if (!row.parentId) return true;

                const parentRow = rows.find(r => r.id === row.parentId);
                const nbDays = DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(row.date), new Date());
                const status = row.status || (nbDays === 0 ? 'current' : nbDays < 0 ? 'late' : 'upcoming');
                const isUrgent = parentRow.isUrgent;

                return filters.status.includes(status) && (!filters.status.includes('urgent') || isUrgent)
            })
            .map(row => {
                if (row.parentId) return row;

                return { // On retire les récurrences qui ne sont pas dans la période sélectionnée des lignes parents
                    ...row, children: row.children
                        .map(childRow => ({ ...childRow, isUrgent: row.isUrgent }))
                        .filter(childRow => {
                            const nbDays = DatesUtil.daysBetweenTwoDates(DatesUtil.convertUTCDateToDate(childRow.date), new Date());
                            const status = childRow.status || (nbDays === 0 ? 'current' : nbDays < 0 ? 'late' : 'upcoming');
                            return filters.status.includes(status) && (!filters.status.includes('urgent') || childRow.isUrgent)
                        })
                };
            })
            .map(row => { // On calcule les propriétés dynamiques en fonction des résultats des filtres ci-dessus
                let price = 0;
                if (!row.parentId) {
                    const children = row.children.map(childRow => {
                        const childPrice = ActionsUtil.getActionPrice(row.action, this.props.layer[0].feature, priceList);
                        price += childPrice;
                        return { ...childRow, price: childPrice };
                    });
                    //! Attention, modifier la référence de la row peut empêcher de pouvoir éditer si des renders sont déclenché entre temps (ex fixé : onSelectedCellChange)
                    return { ...row, children, price: price % 1 !== 0 ? price.toFixed(2) : price };
                } else {
                    const parentRow = rows.find(r => r.id === row.parentId);
                    price = ActionsUtil.getActionPrice(parentRow.action, this.props.layer[0].feature, priceList);
                    return { ...row, price: price % 1 !== 0 ? price.toFixed(2) : price };
                }
            })
            .filter(row => row.parentId || !this.state.filters.status.includes('urgent') || row.isUrgent);

        const filteredRows = rows.filter(row => { // On retourne le résultat correspondant aux filtres
            const isParent = row.children ? true : false;
            return !this.state.enableFilterRow
                || (isParent && rowMatchesFilters(row))
                || (!isParent && parentMatchesFilters(row.parentId));
        });

        return filteredRows.flatMap((row, index) => { // En ajoutant les subheaders si nécessaire
            const rowToReturn = [row];
            if (!row.parentId && row.replantingDate && row.replantingDate !== filteredRows[index - 1]?.replantingDate)
                rowToReturn.unshift({ isReplantingRow: true, replantingDate: DatesUtil.getFormattedLocaleDateString(row.replantingDate) });
            return rowToReturn;
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const { sortColumn, sortDirection, initialOrder, data, priceListId } = this.state;
        const priceList = this.state.priceLists.find(priceList => priceList.id === priceListId);

        const sort = (rows) => {
            let sortedRows = [...rows];
            if (sortColumn === 'price') {
                sortedRows = sortedRows.sort((a, b) => {
                    const aValue = a.children * (ActionsUtil.getActionPrice(a.action, this.props.layer[0].feature, priceList) || 0)
                    const bValue = b.children * (ActionsUtil.getActionPrice(b.action, this.props.layer[0].feature, priceList) || 0)
                    return aValue - bValue
                });
            }
            else sortedRows = sortedRows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));
            if (sortDirection === 'DESC') sortedRows.reverse()
            return sortedRows;
        };

        let rows;
        if (sortDirection === 'NONE') {
            rows = [...data.rows];
            let replaceIndex = 0;
            initialOrder.forEach(elementId => {
                let index = rows.findIndex(row => !row.parentId && `${row.id}` === `${elementId}`);
                if (index === -1)
                    index = rows.findIndex(row => {
                        const ids = elementId.split?.('|');
                        if (!ids) return false;
                        return `${row.parentId}` === `${ids[0]}` && `${row.id}` === `${ids[1]}`;
                    });
                if (index !== -1) {
                    let temp = rows[replaceIndex];
                    rows[replaceIndex] = rows[index];
                    rows[index] = temp;
                    replaceIndex++;
                }
            });
        } else {
            rows = sort([...data.rows.filter(row => !row.parentId)]);

            let childRows = {};
            data.rows.filter(row => row.parentId).forEach(row => {
                if (childRows[row.parentId]) childRows[row.parentId].push(row);
                else childRows[row.parentId] = [row];
            });
            for (const parentId in childRows) {
                let children = childRows[parentId];
                let index = rows.findIndex(row => row.id === children[0].parentId);
                if (index !== -1)
                    children.forEach(row => {
                        rows.splice(index + 1, 0, row);
                        index++;
                    });
            }
        }

        this.setState(prevState => ({ data: { ...prevState.data, rows } }));
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c' && this.selectedColumn && this.selectedRow)
            navigator.clipboard.writeText(this.selectedRow[this.selectedColumn.key] || '');
    }

    handleResize = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const { isOverflowing, data } = this.state;
            const gridElement = document.getElementsByClassName('rdg')[0];
            if (gridElement && data?.columns) {
                const width = data.columns.reduce((previousValue, column) => previousValue + column.width, 0);
                if (isOverflowing && gridElement.clientWidth >= width) this.setState({ isOverflowing: false });
                if (!isOverflowing && gridElement.clientWidth < width) this.setState({ isOverflowing: true });
            }
        }, 100);
    }

    expandAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && !row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    collapseAll = () => {
        let elementIds = this.state.data.rows
            .filter(row => !row.parentId && row.isExpanded)
            .map(row => row.id);
        this.toggleSubRows(elementIds);
    }

    toggleSubRows = (elementIds) => {
        let rows = [...this.state.data.rows];

        elementIds.forEach(elementId => {
            const rowIndex = rows.findIndex(r => r.id === elementId);
            const row = rows[rowIndex];
            if (row) {
                let { children } = row;

                if (children) {
                    rows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
                    if (!row.isExpanded) rows.splice(rowIndex + 1, 0, ...children);
                    else rows.splice(rowIndex + 1, children.length);
                }
            }
        });

        this.setState(prevState => ({ data: { ...prevState.data, rows } }), this.sortRows);
    }

    showEditValidationDate = (row) => {
        if (!row.validationDate || this.props.project.type !== 'project') return;
        this.setState({ rowToEdit: row });
    }

    handleValidationDateSubmit = (validationDate) => {
        const { rowToEdit } = this.state;
        this.updateProjectActionElementRecurrence(rowToEdit.parentId, rowToEdit, rowToEdit.status, validationDate)
    }

    loadPhotos = async () => {
        const { photosGalleries } = this.props
        const { data } = this.state;

        const id = this.props.layer[0]?.feature?.id || '';
        const photos = photosGalleries.filter(photo => photo.elementId === id);

        const recurrencesId = data.rows.filter(row => !row.parentId).flatMap(row => row.children.map(childRow => childRow.id));
        this.setState({ arePhotosLoading: false, photos: photos.filter(photo => recurrencesId.includes(photo.recurrenceId)) });
    }

    uploadPhotos = (photosToUpload, recurrenceId) => {
        let { photosGalleries } = this.props;
        const nbElementPhotos = this.props.photosGalleries.filter(photo => photo.elementId === this.state.id).length;
        const photos = this.state.photos.filter(photo => photo.recurrenceId === recurrenceId);

        photosToUpload.forEach(photo => {
            if (photo['type'].split('/')[0] !== 'image') showToast('file_format_not_supported');
            else if (nbElementPhotos + 1 <= 20) setTimeout(() => this.uploadPhoto(photo, photos, photosGalleries, recurrenceId), 50);
            else showToast('photos_limit_reached', 20);
        });
    }

    uploadPhoto = (photo, photos, photosGalleries, recurrenceId) => {
        const id = uuidv4();
        const extension = photo.name.split('.').pop();
        const blobName = `${id}.${extension}`;
        const tempPhoto = {
            id: id, projectId: this.props.project?.id || null, createdBy: jwt_decode(new Cookies().get('token')).id,
            blobName, baseName: photo.name.replace(`.${extension}`, ''), extension: extension, elementId: this.props.layer[0]?.feature?.id, recurrenceId,
            size: photo.size, type: 'photo', url: URL.createObjectURL(photo), createdAt: (new Date(photo.lastModified)).toDateString()
        };
        photos.push(tempPhoto);
        photosGalleries.push(tempPhoto);
        this.props.setPhotosGalleries(photosGalleries);
        this.setState({ photos });

        const customProps = { data: { tempPhoto, photo } };
        this.props.setRequest(TasksUtil.createRequest({
            id, functionName: 'uploadPhoto', toastId: 'photo_adding', customProps,
            processMessageId: i18n.t("Ajout de la photo en cours..."),
            successMessageId: i18n.t("La photo a été ajoutée"),
            errorMessageId: i18n.t("L'ajout de la photo a échoué")
        }));
    }

    editProjectAction = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectActionToEdit = this.props.projectActions.find(pa => pa.id === filteredRows[rowIdx].id);
        this.setState(prevState => ({
            projectActionToEdit: !prevState.projectActionToEdit || prevState.projectActionToEdit?.id !== projectActionToEdit.id ? JSON.parse(JSON.stringify(projectActionToEdit)) : null
        }));
    }

    cancelRecurrence = (_, { rowIdx }) => {
        let row = this.getFilteredRows()[rowIdx];
        if (row.children?.length === 1) row = row.children[0];
        this.updateProjectActionElementRecurrence(row.parentId, row, !row.status ? 'cancelled' : null);
    }
}

const mapStateToProps = (state) => {
    return {
        webSocketHubs: state.webSocketHubs,
        layer: state.layer,
        project: state.project,
        projectActions: state.projectActions,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        photosGalleries: state.photosGalleries,
        rights: state.rights,
        priceLists: state.priceLists,
        currencies: state.currencies,
        actions: state.actions,
        actionHistory: state.actionHistory
    };
};

const mapDispatchToProps = {
    setCurrentAction,
    setProjectActions,
    setPhotosGalleries,
    setPriceLists,
    setRequest,
    setActionHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionForm);