import React, { Component } from 'react';
// Composants
import { Button, Divider, Grid, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
import { v4 as uuidv4 } from 'uuid';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

class ProjectShrinkageForm extends Component {
    render() {
        const { trees, greenSpaces, furnitures, markers, stations } = this.props.elementsOutsideNewBounds;
        const gridStyle = { margin: '10px', paddingBottom: '14px', border: 'solid 1px grey', borderRadius: '10px', width: !isMobile ? '60vw' : '100%' };
        const headerRowStyle = { padding: '5px', borderBottom: 'solid 1px grey' };
        const propertiesRowStyle = { fontWeight: 'bold', paddingBottom: '3px' };

        return (
            <div className='modal-content'>
                <div className='modal-content-header'>
                    <Message error style={{ margin: '0px 10px 10px 10px' }}>
                        <Message.Header style={{ fontSize: '14pt' }}>{i18n.t("Attention !")}</Message.Header>
                        <Message.Content>{i18n.t("Les éléments ci-dessous seront supprimés suite à un rétrécissement de la zone géographique.")}</Message.Content>
                    </Message>
                </div>
                <div className='modal-content-body'>
                    {trees.length > 0 &&
                        <Grid style={gridStyle}>
                            <Grid.Row style={headerRowStyle}>
                                <Grid.Column textAlign='center'><h2>{i18n.t("Arbres")}</h2></Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided style={propertiesRowStyle}>
                                <Grid.Column width={2}>{i18n.t("Référence")}</Grid.Column>
                                {trees.some(tree => tree.properties.customReference) &&
                                    <Grid.Column width={4}>{i18n.t("Référence personnalisée")}</Grid.Column>}
                                <Grid.Column width={5}>{i18n.t("Lieu")}</Grid.Column>
                                <Grid.Column width={5}>{i18n.t("Type ou essence")}</Grid.Column>
                            </Grid.Row>
                            <Divider style={{ margin: '2px 14px' }} />
                            {this.renderTreeRows()}
                        </Grid>}
                    {greenSpaces.length > 0 &&
                        <Grid style={gridStyle}>
                            <Grid.Row style={headerRowStyle}>
                                <Grid.Column textAlign='center'><h2>{i18n.t("Espaces verts")}</h2></Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided style={propertiesRowStyle}>
                                <Grid.Column width={2}>{i18n.t("Référence")}</Grid.Column>
                                {greenSpaces.some(greenSpace => greenSpace.properties.customReference) &&
                                    <Grid.Column width={4}>{i18n.t("Référence personnalisée")}</Grid.Column>}
                                <Grid.Column width={5}>{i18n.t("Lieu")}</Grid.Column>
                                {greenSpaces.some(greenSpace => greenSpace.properties.placeExtra) &&
                                    <Grid.Column width={5}>{i18n.t("Libellé")}</Grid.Column>}
                            </Grid.Row>
                            <Divider style={{ margin: '2px 14px' }} />
                            {this.renderGreenSpaceRows()}
                        </Grid>}
                    {furnitures.length > 0 &&
                        <Grid style={gridStyle}>
                            <Grid.Row style={headerRowStyle}>
                                <Grid.Column textAlign='center'><h2>{i18n.t("Mobilier urbain")}</h2></Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided style={propertiesRowStyle}>
                                <Grid.Column width={2}>{i18n.t("Référence")}</Grid.Column>
                                {furnitures.some(furniture => furniture.properties.customReference) &&
                                    <Grid.Column width={4}>{i18n.t("Référence personnalisée")}</Grid.Column>}
                                <Grid.Column width={5}>{i18n.t("Lieu")}</Grid.Column>
                                <Grid.Column width={5}>{i18n.t("Type")}</Grid.Column>
                            </Grid.Row>
                            <Divider style={{ margin: '2px 14px' }} />
                            {this.renderFurnitureRows()}
                        </Grid>}
                    {markers.length > 0 &&
                        <Grid style={gridStyle}>
                            <Grid.Row style={headerRowStyle}>
                                <Grid.Column textAlign='center'><h2>{i18n.t("Mobilier urbain")}</h2></Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided style={propertiesRowStyle}>
                                <Grid.Column width={2}>{i18n.t("Référence")}</Grid.Column>
                                {markers.some(marker => marker.properties.customReference) &&
                                    <Grid.Column width={4}>{i18n.t("Référence personnalisée")}</Grid.Column>}
                            </Grid.Row>
                            <Divider style={{ margin: '2px 14px' }} />
                            {this.renderMarkerRows()}
                        </Grid>}
                    {stations.length > 0 &&
                        <Grid style={gridStyle}>
                            <Grid.Row style={headerRowStyle}>
                                <Grid.Column textAlign='center'><h2>{i18n.t("Stations")}</h2></Grid.Column>
                            </Grid.Row>
                            <Grid.Row divided style={propertiesRowStyle}>
                                <Grid.Column width={16}>{i18n.t("Libellé")}</Grid.Column>
                            </Grid.Row>
                            <Divider style={{ margin: '2px 14px' }} />
                            {this.renderStationRows()}
                        </Grid>}
                </div>
                <div className='modal-content-footer'>
                    <h3 style={{ margin: '10px 0 3px 0' }}>{i18n.t("Êtes-vous certain de vouloir supprimer ces éléments ?")}</h3>
                    {isMobile ?
                        <>
                            <Button.Group style={{ width: '100%' }}>
                                <Button
                                    type='button' className='form-button' color='red' style={{ width: '50%' }}
                                    onClick={this.props.cancelSurroundingsShrinkage}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button className='form-button' color='green' style={{ width: '50%' }} onClick={this.props.confirmProjectShrinkage} >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </Button.Group>
                        </>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={this.props.cancelSurroundingsShrinkage}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' onClick={this.props.confirmProjectShrinkage}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </>}
                </div>
            </div>
        );
    }

    renderTreeRows = () => {
        const { essences, elementsOutsideNewBounds } = this.props;
        const trees = elementsOutsideNewBounds.trees;

        const rows = [];
        trees.forEach(tree => {
            const { projectReference, customReference, place, isEmpty, isStump, essenceId } = tree.properties;
            const essence = essences.find(essence => essence.id === essenceId);
            const type = isEmpty ? i18n.t("Arbre vide")
                : essence ? `${essence.gender} ${essence.species || ''} ${essence.cultivar || ''}`
                    : isStump ? i18n.t("Souche") : '';

            rows.push(
                <Grid.Row key={uuidv4()} divided style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                    <Grid.Column width={2}>{projectReference}</Grid.Column>
                    {trees.some(tree => tree.properties.customReference) &&
                        <Grid.Column width={4}>{customReference || ''}</Grid.Column>}
                    <Grid.Column width={5}>{place}</Grid.Column>
                    <Grid.Column width={5}>{type}</Grid.Column>
                </Grid.Row>
            );
        });

        return rows;
    }

    renderGreenSpaceRows = () => {
        const greenSpaces = this.props.elementsOutsideNewBounds.greenSpaces;

        const rows = [];
        greenSpaces.forEach(greenSpace => {
            const { projectReference, customReference, place, placeExtra } = greenSpace.properties;

            rows.push(
                <Grid.Row key={uuidv4()} divided style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                    <Grid.Column width={2}>{projectReference}</Grid.Column>
                    {greenSpaces.some(greenSpace => greenSpace.properties.customReference) &&
                        <Grid.Column width={4}>{customReference || ''}</Grid.Column>}
                    <Grid.Column width={5}>{place}</Grid.Column>
                    {greenSpaces.some(greenSpace => greenSpace.properties.placeExtra) &&
                        <Grid.Column width={5}>{placeExtra || ''}</Grid.Column>}
                </Grid.Row>
            );
        });

        return rows;
    }

    renderFurnitureRows = () => {
        const { furnitureTypes, elementsOutsideNewBounds } = this.props;
        const furnitures = elementsOutsideNewBounds.furnitures;

        const rows = [];
        furnitures.forEach(furniture => {
            const { projectReference, customReference, place, typeId } = furniture.properties;
            const type = furnitureTypes.find(type => type.id === typeId)?.label || '';

            rows.push(
                <Grid.Row key={uuidv4()} divided style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                    <Grid.Column width={2}>{projectReference}</Grid.Column>
                    {furnitures.some(furniture => furniture.properties.customReference) &&
                        <Grid.Column width={4}>{customReference || ''}</Grid.Column>}
                    <Grid.Column width={5}>{place}</Grid.Column>
                    <Grid.Column width={5}>{type}</Grid.Column>
                </Grid.Row>
            );
        });

        return rows;
    }

    renderMarkerRows = () => {
        const { elementsOutsideNewBounds } = this.props;
        const markers = elementsOutsideNewBounds.markers;

        const rows = [];
        markers.forEach(marker => {
            const { projectReference, customReference } = marker.properties;
            rows.push(
                <Grid.Row key={uuidv4()} divided style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                    <Grid.Column width={2}>{projectReference}</Grid.Column>
                    {markers.some(marker => marker.properties.customReference) &&
                        <Grid.Column width={4}>{customReference || ''}</Grid.Column>}
                </Grid.Row>
            );
        });

        return rows;
    }

    renderStationRows = () => {
        const stations = this.props.elementsOutsideNewBounds.stations;

        const rows = [];
        stations.forEach(station => {
            const { label } = station.properties;

            rows.push(
                <Grid.Row key={uuidv4()} divided style={{ paddingTop: '3px', paddingBottom: '3px' }}>
                    <Grid.Column width={16}>{label}</Grid.Column>
                </Grid.Row>
            );
        });

        return rows;
    }
}

const mapStateToProps = (state) => ({
    essences: state.essences,
    furnitureTypes: state.furnitureTypes
});

export default connect(mapStateToProps)(ProjectShrinkageForm);