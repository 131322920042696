import React, { Component } from 'react';
// Composants
import { Button, Checkbox, Form, Input, Menu } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../locales/i18n';
import FormattersUtil from '../../utils/FormattersUtil';

class DropDownWithCheckboxes extends Component {
    constructor(props) {
        super(props);
        this.dropDownRef = React.createRef();
    }

    state = {
        isOpen: false,
        search: ''
    }

    render() {
        const { label, title, icon, style, buttonStyle, menuStyle, disabled } = this.props;
        const { isOpen, search } = this.state;

        return (
            <div ref={this.dropDownRef} style={{ position: 'relative', ...style }}>
                <Button icon labelPosition={label && 'left'} title={title} disabled={disabled || false} style={buttonStyle} className='button--secondary' onClick={this.toggleMenu}>
                    <i className='icon' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FontAwesomeIcon icon={icon} /></i>{label}
                </Button>
                {isOpen &&
                    <Menu id='dropdown-checkboxes-menu' size='mini' vertical style={menuStyle}>
                        <Form style={{ flexGrow: 1, width: '100%', marginBottom: '5px' }}>
                            <Form.Field
                                control={Input} type='text' placeholder={i18n.t("Rechercher...")} name='search' value={search || ''} autoComplete='off'
                                onChange={(_, { value }) => this.setState({ search: value })} style={{ width: '100%' }}
                            />
                        </Form>
                        <div id='menu-items'>
                            {this.renderCheckboxes()}
                        </div>
                    </Menu>}
            </div>
        );
    }

    componentWillUnmount = () => document.removeEventListener('click', this.onDocumentClick);
    renderCheckboxes = () => {
        const optionsToRender = this.getFilteredOptions();

        return optionsToRender.length > 0 ?
            optionsToRender.map((option, index) => (
                <Menu.Item key={index} style={{ textAlign: 'left' }} onClick={option.onClick}>
                    <Checkbox label={option.label} checked={option.isChecked} />
                </Menu.Item>
            ))
            : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', height: '30px' }}>{i18n.t("Aucun résultat trouvé")}</div>;
    }

    toggleMenu = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen, search: '' }));
        if (this.state.isOpen) document.removeEventListener('click', this.onDocumentClick);
        else document.addEventListener('click', this.onDocumentClick);
    }

    onDocumentClick = (e) => {
        if (!this.dropDownRef.current.contains(e.target)) {
            this.setState({ isOpen: false, search: '' });
            document.removeEventListener('click', this.onDocumentClick);
        }
    }

    getFilteredOptions = () => {
        const { options } = this.props;
        const { search } = this.state;
        let filteredOptions = [];

        if (search.trim() === '') filteredOptions = options || [];
        else filteredOptions = options.filter(o => FormattersUtil.getNormalizedString(o.label).toLowerCase().includes(FormattersUtil.getNormalizedString(search).toLowerCase()));

        return filteredOptions;
    }
}

export default DropDownWithCheckboxes;