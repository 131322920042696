import React, { Component } from 'react';
// ChartJS
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
// Services
import StylesUtil from '../../utils/StylesUtil';

class OntogenicsChart extends Component {
    state = {
        data: null,
        options: null
    }

    render() {
        return (
            <div className='modal-content'>
                <div className='modal-content-body'>
                    {this.state.data && this.state.options
                        ? <Bar data={this.state.data} options={this.state.options} />
                        : i18n.t("Aucune donnée trouvée")}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.loadData();
        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('UpdateElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('RemoveElements', this.loadData);
        }
    }

    loadData = () => {
        // On récupert la liste des genres
        let ontogenicStages = this.props.ontogenicStages.map(x => x.value);
        let values = this.props.ontogenicStages.map(() => 0);
        let count = 0;

        // On compte le nombre d'arbres par stade ontogéniques
        const layers = this.props.layer.getLayers();
        for (const layerName in layers) {
            const layer = layers[layerName];
            const ontogenicStage = this.props.ontogenicStages.find(x => x.id === layer.feature.properties.ontogenicStageId)?.value;
            if (ontogenicStage) {
                const index = ontogenicStages.indexOf(ontogenicStage);
                if (index >= 0) {
                    count++;
                    values[index]++;
                }
            }
        }

        if (count > 0) {
            const themeColor = this.props.isDarkTheme ? 'white' : 'black';
            this.setState({
                data: {
                    labels: ontogenicStages,
                    datasets: [{
                        label: i18n.t("Nombre"),
                        backgroundColor: ontogenicStages.map(ontogenicStage => { return StylesUtil.getOntogenicStageColor(ontogenicStage); }),
                        borderColor: 'rgba(0, 0, 0, 1)',
                        borderWidth: 1,
                        data: values,
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Stades ontogéniques"),
                                fontColor: themeColor
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Nombre d'arbres"),
                                fontColor: themeColor
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            top: 20
                        }
                    },
                    plugins: {
                        labels: {
                            render: 'value',
                            showZero: false,
                            fontColor: themeColor
                        }
                    }
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        ontogenicStages: state.ontogenicStages,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
};

export default connect(mapStateToProps)(OntogenicsChart);