// Librairies
import { isAndroid, isChrome, isDesktop, isEdge, isFirefox, isIE, isIOS, isMacOs, isMobileOnly, isOpera, isSafari, isTablet, isWindows } from 'react-device-detect';

export default class UsersUtil {
    static checkBillingAddress(billingAddress, isPro = false) {
        let isValid = false;

        if (isPro && billingAddress?.customerName1 && billingAddress?.vat && billingAddress?.isValidVat)
            isValid = true;
        if (!isPro && billingAddress?.customerName1 && billingAddress.customerName2)
            isValid = true;
        // Général
        if (!billingAddress?.street || !billingAddress?.postalCode || !billingAddress?.country || !billingAddress?.city)
            isValid = false;

        return isValid;
    }

    static groupUserSubscriptionConsumptionsPerUsers(userSubscriptionConsumptions = []) {
        let result = [];
        userSubscriptionConsumptions.reduce((res, value) => {
            if (!res[value.userId]) {
                res[value.userId] = { ...value, nbProjects: 1 };
                result.push(res[value.userId])
            } else {
                res[value.userId].nbTrees += value.nbTrees;
                res[value.userId].nbGreenSpaces += value.nbGreenSpaces;
                res[value.userId].nbFurnitures += value.nbFurnitures;
                res[value.userId].nbProjects += 1;
            }
            return res;
        }, {});
        return result;
    }

    static getDevice() {
        if (isDesktop && isWindows) return { type: 'desktop', os: 'windows', browser: this.getBrowser() };
        else if (isDesktop && isMacOs) return { type: 'desktop', os: 'macOs', browser: this.getBrowser() };
        else if (isDesktop) return { type: 'desktop', os: null, browser: this.getBrowser() };
        else if (isTablet && isAndroid) return { type: 'tablet', os: 'android', browser: this.getBrowser() };
        else if (isTablet) return { type: 'tablet', os: 'ipadOs', browser: this.getBrowser() };
        else if (isMobileOnly && isAndroid) return { type: 'mobile', os: 'android', browser: this.getBrowser() };
        else if (isMobileOnly && isIOS) return { type: 'mobile', os: 'iOs', browser: this.getBrowser() };
        else if (isMobileOnly) return { type: 'mobile', os: null, browser: this.getBrowser() };
        return { type: null, os: null, browser: this.getBrowser() };
    }

    static getBrowser() {
        return isChrome ? 'chrome'
            : isFirefox ? 'firefox'
                : isOpera ? 'opera'
                    : isEdge ? 'edge'
                        : isIE ? 'ie'
                            : isSafari ? 'safari'
                                : null;
    }
}