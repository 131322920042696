import React, { Component } from 'react';
// Composants
import { Button, Modal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../locales/i18n';
import { faCheck, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';

const LOCAL_STORAGE_ITEM_NAME = 'offlineModalClosed';
class OfflineModal extends Component {
    state = {
        open: false
    }

    render() {
        const { open } = this.state;

        return (
            <Modal size='tiny' open={open} onClose={() => this.setState({ open: false })}>
                <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faWifiSlash} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                    {i18n.t("Connexion perdue !")}
                </Modal.Header>
                <Modal.Content>
                    <p>{i18n.t("Vous avez perdu la connexion à Internet, vous êtes donc passés en mode 'hors ligne'. Vos données seront synchronisées au retour de la connexion.")}</p>
                    <FontAwesomeIcon icon={faWifiSlash} color='red' style={{ position: 'absolute' }} />
                    <p style={{ textIndent: '25px' }}>{i18n.t("Tant que cet icône est visible, le mode 'hors ligne' sera actif. Dans ce mode, certaines fonctionnalités ont été désactivées jusqu'au retour de la connexion.")}</p>
                    <p>{i18n.t("IMPORTANT ! Ne videz pas le cache car cela pourrait entrainer la perte données liées aux actions effectuées en mode 'hors ligne'.")}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' color='green' onClick={this.close}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("J'ai compris")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    componentDidMount = () => {
        const alreadyRead = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
        const showModal = !this.props.isOnline && !alreadyRead;
        if (showModal) this.setState({ open: true });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOnline && !this.props.isOnline) {
            const alreadyRead = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
            if (!alreadyRead) this.setState({ open: true });
        } else if (this.props.isOnline && this.state.open)
            this.setState({ open: false });
    }

    close = () => {
        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, true);
        this.setState({ open: false });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(OfflineModal);