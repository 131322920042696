import React, { Component } from 'react';
// Composants
import { Grid, Segment } from 'semantic-ui-react';
import StatisticsSidebar from './StatisticsSidebar';
import ElementsStatistics from './ElementsStatistics';
// Librairies
import i18n from '../../../locales/i18n';

class Statistics extends Component {
    state = {
        activeItem: 'trees',
        startDate: null,
        endDate: null,
        period: 'week',
        settings: {
            showNbAdded: true,
            showNbModified: true,
            showNbDeleted: true,
            showSurface: true,
            display: 'detail'
        }
    }

    render() {
        const { activeItem, startDate, endDate, period, settings } = this.state;

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Statistiques")}</h3>
                <Grid style={{ height: '100%', margin: 0 }}>
                    <Grid.Column width={3} style={{ paddingRight: 0 }}>
                        <StatisticsSidebar activeItem={activeItem} setActiveItem={this.setActiveItem} />
                    </Grid.Column>
                    <Grid.Column stretched width={13} style={{ paddingLeft: 0 }}>
                        <Segment>
                            <ElementsStatistics
                                category={activeItem} startDate={startDate} endDate={endDate} period={period} settings={settings}
                                setDatesAndPeriod={this.setDatesAndPeriod} updateSettings={this.updateSettings}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }

    setActiveItem = (activeItem) => this.setState({ activeItem });
    setDatesAndPeriod = (startDate, endDate, period) => new Promise(resolve => this.setState({ startDate, endDate, period }, resolve));
    updateSettings = (settingName, value) => {
        return new Promise(resolve => {
            this.setState(prevState => ({
                ...prevState, settings: { ...prevState.settings, [settingName]: value }
            }), resolve);
        });
    }
}

export default Statistics;