import React, { Component } from 'react';
// Librairies
import { connect } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { setUserInfos } from '../../../actionCreators/usersActions';
import i18n from '../../../locales/i18n';
import UsersService from '../../../services/UsersService';

class MailsConfiguration extends Component {
    render() {
        return (
            <div>
                {this.renderMailConfiguration('userAddedToProjectMail', i18n.t("Ajout à un projet"))}
            </div>
        );
    }

    renderMailConfiguration = (name, label) => {
        const isActivated = this.props.userInfos?.mailsConfiguration?.[name];
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox name={name} toggle checked={isActivated} disabled={!this.props.isOnline || this.props.loginAsData?.readOnly} onChange={this.handleCheckboxChange} />
                <div style={{ marginLeft: '10px' }}>{label}</div>
            </div>
        );
    }

    handleCheckboxChange = (_, { name, checked }) => {
        let { mailsConfiguration } = this.props.userInfos;
        mailsConfiguration = { ...mailsConfiguration, [name]: checked };
        UsersService.updateMailsConfiguration(mailsConfiguration).then(response => {
            if (response) this.props.setUserInfos({ ...this.props.userInfos, mailsConfiguration });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        userInfos: state.userInfos,
        loginAsData: state.loginAsData
    };
}

const mapDispatchToProps = {
    setUserInfos
}

export default connect(mapStateToProps, mapDispatchToProps)(MailsConfiguration);