import React, { Component } from 'react';
// Components
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import InputPopupForm from '../../Utils/InputPopupForm';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setEditedProperties, unlockEditedProperties } from '../../../actionCreators/componentsActions';
import { setPlace, setElementHistory, setPhotosGalleries, setFilesGalleries } from '../../../actionCreators/elementsActions';
import { setProject } from '../../../actionCreators/projectsActions';
// Services
import GeoJsonUtil from '../../../utils/GeoJsonUtil';
import StationsService from '../../../services/StationsService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import UpdatesUtil from '../../../utils/UpdatesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import ThemesUtil from '../../../utils/ThemesUtil';

const initialError = {
    label: false
};

const initialState = {
    properties: {
        category: 'Station',
        label: ''
    },
    isLoading: false
};

class StationForm extends Component {
    state = this.props.layer[0]
        ? { ...initialState, id: this.props.layer[0].feature.id, properties: { ...this.props.layer[0].feature.properties } }
        : initialState;

    render() {
        const { id, properties } = this.state;

        return (
            <InputPopupForm
                title={id ? i18n.t("Modification d'une station") : i18n.t("Création d'une station")} value={properties.label} selection={true}
                submitButtonIcon={faCheck} submitButtonLabel={i18n.t("Valider")} showCurrentValue={false} message={!id ? i18n.t("Les espaces verts doivent être entièrement dans une station pour être compris dans celle-ci") : null}
                submit={(value) => this.setState(prevState => ({ properties: { ...prevState.properties, label: value } }), () => this.handleSubmit())}
                cancel={() => this.props.hideForm(false)}
            />
        );
    }

    componentDidMount = () => {
        this.handleFormsHub();
    }

    componentWillUnmount = () => {
        if (this.props.webSocketHubs?.formsHub) this.props.webSocketHubs.formsHub.stop();
    }

    handleSubmit = () => {
        if (this.verifyProperties()) {
            const properties = JSON.parse(JSON.stringify(this.state.properties));

            if (!this.state.id) {
                const feature = GeoJsonUtil.generatePolygonFeature(properties, this.props.layer.getLatLngs(), this.props.project?.id || 0);

                this.setState({ isLoading: true });
                StationsService.addStation(feature, 'adding', this.props.webSocketHubs).finally(() => {
                    this.props.addStation(this.props.layer, feature, { showContainer: true });
                    this.props.hideForm(true);
                });
            } else { // Si on met à jour
                if (JSON.stringify(properties) !== JSON.stringify({ ...this.props.layer[0].feature.properties })) {
                    const { layer } = this.props;
                    this.setState({ isLoading: true });
                    UpdatesUtil.updateStation(layer[0].feature.id, properties, layer, 'updating', layer[0].feature.projectId, { webSocketHubs: this.props.webSocketHubs })
                        .finally(() => {
                            this.props.updateLegend(i18n.t("Stations"));
                            this.props.hideForm(true);
                        });
                } else showToast('element_update_not_allowed');
            }
        }
    }

    handleDelete = () => {
        if (!this.props.project || !ProjectsUtil.isElementLocked(this.props.lockedElements, { id: this.state.id }, this.props.project, this.props.projectCollaborators, []))
            this.props.showRemoveForm();
    }

    completeProperties = (newProperties) => {
        this.setState(prevState => ({ properties: { ...prevState.properties, ...newProperties } }));
    }

    verifyProperties = (step, isSubmit) => {
        const { properties } = this.state;

        let isValid = true;
        const error = { label: false };

        if (!properties.label || properties.label.length > 60) {
            error.label = true;
            isValid = false;
        }

        if (!isValid) this.setState({ error });
        else this.setState({ error: initialError });

        return isValid;
    }

    handleFormsHub = () => {
        if (this.props.project && this.props.layer[0] && this.props.webSocketHubs.formsHub.state === 'Disconnected') {
            this.formsHubTimeout = setTimeout(() => {
                const handleHub = () => {
                    if (this.state.id === this.props.layer[0].feature.id) {
                        this.setState({ isLoading: true });
                        // Invokers
                        this.props.webSocketHubs.formsHub.invoke('JoinGroup', this.state.id);
                        this.props.webSocketHubs.formsHub.invoke('GetLatestFormState', this.state.id);

                        // Events
                        this.props.webSocketHubs.formsHub.on('MemberJoinedGroup', members => {
                            const membersParsed = JSON.parse(members);
                            let newMembers = membersParsed.filter(x => !this.state.members.find(y => y.connectionId === x.connectionId));
                            newMembers.forEach(newMember => {
                                const colors = ThemesUtil.getColorNames();
                                newMember.color = `--${colors[Math.floor(Math.random() * colors.length)]}-${(Math.floor(Math.random() * 10) + 1) * 10}`;
                            });
                            this.setState(prevState => ({ members: [...prevState.members, ...newMembers] }));
                        });
                        this.props.webSocketHubs.formsHub.on('MemberDisconnected', connectionId => {
                            this.setState(prevState => ({ members: prevState.members.filter(x => x.connectionId !== connectionId) }));
                        });
                        this.props.webSocketHubs.formsHub.on('GetLatestFormState', connectionId => {
                            if (this.props.webSocketHubs.formsHub.connectionId !== connectionId)
                                WebSocketUtil.sendLatestFormStateToMember(this.props.webSocketHubs, connectionId, this.state.properties);
                            else this.setState({ isLoading: false });
                        });
                        this.props.webSocketHubs.formsHub.on('UpdateForm', state => {
                            this.setState({ isLoading: false, properties: JSON.parse(state) });
                        });
                    }
                };

                this.props.webSocketHubs.formsHub.start(handleHub);
                this.props.webSocketHubs.formsHub.onreconnected(handleHub);
            }, 100);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        rights: state.rights,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        isKeyboardOpen: state.isKeyboardOpen,
        elementHistory: state.elementHistory,
        photosGalleries: state.photosGalleries,
        filesGalleries: state.filesGalleries,
        webSocketHubs: state.webSocketHubs,
        lockedElements: state.lockedElements
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setProject,
    setPlace,
    setElementHistory,
    setPhotosGalleries,
    setFilesGalleries
}

export default connect(mapStateToProps, mapDispatchToProps)(StationForm);