// Librairies
import i18n from "../locales/i18n";

const checkInput = (selector, value, isDropdown) => {
    return new Promise(resolve => {
        const element = document.querySelector(selector)?.querySelector('input') || document.querySelector(selector);
        element.addEventListener('input', () => {
            const elementValue = element?.value?.toLocaleLowerCase() || element?.value?.toLocaleLowerCase();
            const isValid = elementValue === value.toLocaleLowerCase() || elementValue === value.toLocaleLowerCase() || elementValue.includes(value.toLocaleLowerCase());
            if (isValid) {
                if (!isDropdown) element.blur();
                resolve();
            }
        });
    });
};

export const shouldCreateStep = (subscription, rights = []) => {
    let shouldCreate = true;
    if (Array.isArray(rights) && subscription)
        rights.forEach(right => {
            if (!subscription[right]) shouldCreate = false;
        });
    return shouldCreate;
}

export const tourOptions = {
    defaultStepOptions: {
        cancelIcon: { enabled: true },
        highlightClass: 'highlight',
        scrollTo: false,
        modalOverlayOpeningPadding: 5,
        popperOptions: {
            modifiers: [{
                name: 'focusAfterRender',
                enabled: false
            }]
        }
    },
    useModalOverlay: true
};

export const steps = [
    { target: 'body', title: `${i18n.t("Bienvenue sur Grality")} <span style="margin-top: -1px; margin-left: 5px;">👋</span>`, text: i18n.t("Nous allons désormais vous expliquer les <b>fonctionnalités principales</b> de l'application. Pour cela, nous allons encoder des données fictives."), buttonsFilter: ['next'] },
    {
        title: `${i18n.t("Création d'un premier projet")} <span style="margin-top: -1px; margin-left: 5px;">📂</span>`,
        children: [
            { target: '[to="/"]', text: i18n.t("<i>Cliquez sur '<b>Mes projets</b>'</i> pour accéder à la liste de vos projets.") },
            { target: '#Oyk3jgWc', text: i18n.t("<i>Ajoutez-en un nouveau</i>.") },
            {
                target: '#aooO0Q5Z', text: i18n.t("<i>Saisissez le nom 'Tutoriel'</i>."),
                verificationFn: () => checkInput('#aooO0Q5Z', 'Tutoriel')
            },
            {
                target: '#KAOQzi4L', text: i18n.t("<i>Cliquez sur le menu déroulant</i> et <i>recherchez 'Charleroi'</i>."), placement: 'right',
                verificationFn: () => checkInput('#KAOQzi4L', 'Char', true)
            },
            { target: '#KAOQzi4L > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez la commune trouvée</i>."), placement: 'right' },
            { target: '.modal-content-body > div > form > div:nth-child(3)', text: i18n.t("Vous pouvez choisir la <b>formule à appliquer</b> pour chaque information calculée automatiquement."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            {
                target: '#YriOyByc', text: i18n.t("<i>Cliquez sur le menu déroulant</i> et <i>recherchez 'Wallonie'</i>."), placement: 'left',
                verificationFn: () => checkInput('#YriOyByc', 'W', true)
            },
            { target: '#YriOyByc > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez la formule trouvée</i>."), placement: 'left' },
            { target: '.modal-content-footer > .ui', text: i18n.t("<i>Cliquez ici</i> pour passer à l'étape suivante."), placement: 'right', rights: ['customProjects'] },
            { target: '.modal-content-body > :nth-child(1)', text: i18n.t("Les champs repris ci-dessous sont les données à compléter sur les éléments (arbres, espaces verts, ...). Vous pouvez éventuellement en désactiver certains s'ils ne vous sont pas utiles."), placement: 'top', rights: ['customProjects'], buttonsFilter: ['next'], classes: 'remove-margin', canClickTarget: false },
            { target: 'div.modal-content-footer > button.ui.green.button', text: i18n.t("<i>Cliquez ici</i> pour terminer la création de votre projet."), placement: 'right' },
            {
                target: '#Sp6cRuq0', text: i18n.t("<i>Recherchez 'Tutoriel'</i>."),
                verificationFn: () => checkInput('#Sp6cRuq0', 'Tutoriel')
            },
            { target: '.ggxXoDeE', text: i18n.t("Ce bouton vous permet d'<b>activer/désactiver les rappels par mail</b>."), placement: 'right', rights: ['reminderMails'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.I9TTYHFc', text: i18n.t("Ce bouton vous permet d'<b>ajouter le projet aux favoris</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.w8GBoALg', text: i18n.t("<i>Cliquez sur ce bouton</i> pour accéder aux options supplémentaires du projet."), placement: 'right' },
            { target: '#pk9gikBv', text: i18n.t("Ce bouton vous permet d'<b>ajouter/supprimer des collaborateurs</b> et de <b>gérer les droits</b> de ceux-ci."), placement: 'right', rights: ['nbCollaborators'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#PcCOJRRk', text: i18n.t("Ce bouton vous permet d'<b>importer des données</b> (excel, photos, ...)."), placement: 'right', rights: ['import'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#YaXq6tX8', text: i18n.t("Ce bouton vous permet d'<b>exporter les données du projet</b> (excel, pdf, photos, ...)."), placement: 'right', rights: ['export'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#EMtRaRHB', text: i18n.t("Ce bouton vous permet d'<b>accéder aux fichiers du projet</b>."), placement: 'right', rights: ['filesSharing'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#wwx9xxyd', text: i18n.t("Ce bouton vous permet de <b>modifier les paramètres du projet</b> et de <b>gérer les données que vous souhaitez rendre publiques</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#bFfw2QxC', text: i18n.t("Ce bouton vous permet de <b>supprimer le projet</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#vHKfvMoT', text: i18n.t("<i>Cliquez ici</i> pour ouvrir votre projet."), placement: 'right' },
        ]
    },
    {
        title: `${i18n.t("Outils de base")} <span style="margin-top: -3px; margin-left: 5px;">🧰</span>`,
        children: [
            { target: 'body', text: i18n.t("Vous voilà dans votre projet ! <br/>Nous allons désormais parcourir les <b>différents outils mis à votre disposition</b>."), buttonsFilter: ['next'] },
            { target: '#uhRCkpxG', text: i18n.t("<i>Cliquez ici</i> pour ouvrir les outils de base."), placement: 'right' },
            { target: '#Ueu5GQ3h', text: i18n.t("Cet outil vous permet d'<b>afficher les références des éléments</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#cSCfLHkG', text: i18n.t("<i>Cliquez ici</i> pour ouvrir les outils avancés."), placement: 'right' },
            { target: '#ymw1sd3w', text: i18n.t("Cet outil vous permet d'<b>appliquer des filtres</b>."), placement: 'right', rights: ['filters'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#W8aOEs9N', text: i18n.t("Cet outil vous permet d'<b>accéder au tableau de données</b>."), placement: 'right', rights: ['dataTable'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#Vp7ZCFyO', text: i18n.t("Cet outil vous permet de <b>consulter les statistiques du projet</b>, ainsi que de <b>créer des graphiques personnalisés</b>."), placement: 'right', rights: ['statistics'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#KOmV5Upc', text: i18n.t("Cet outil vous permet d'<b>exporter la carte (PDF, JPG, ...)</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#jugChGsl', text: i18n.t("Cet outil vous permet de <b>gérer les actions des éléments</b>."), placement: 'right', rights: ['actions'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#caMdgjoo', text: i18n.t("Cet outil vous permet de <b>consulter l'historique des modifications relatives au projet</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.trees', text: i18n.t("Cet outil vous permet de <b>gérer les arbres</b>."), placement: 'right', rights: ['nbTrees'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.greenSpaces', text: i18n.t("Cet outil vous permet de <b>gérer les espaces verts</b>."), placement: 'right', rights: ['nbGreenSpaces'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#bSgMFHyv', text: i18n.t("Cet outil vous permet de <b>gérer les calques</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.leaflet-control-layers-toggle', text: i18n.t("Cet outil vous permet de <b>modifier le fond cartographique ainsi que les éléments affichés</b> sur la carte. Il vous permet également d'afficher les éléments selon une propriété (ex: côte sanitaire, risque, ...), mais aussi les services écosystémiques (ex: stock carbone, rafraîchissement, ...)."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.bookmarks-header', text: i18n.t("Cet outil vous permet de <b>gérer les bookmarks</b> (emplacements sauvegardées sur la carte)."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.leaflet-control-measure-toggle', text: i18n.t("Cet outil vous permet de <b>prendre des mesures sur la carte</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false }
        ]
    },
    {
        title: `${i18n.t("Ajout d'un arbre")} <span style="margin-top: -3px; margin-left: 5px;">🌲</span>`,
        rights: ['nbTrees'],
        children: [
            { target: 'body', text: i18n.t("Nous allons maintenant <b>ajouter un arbre sur la carte</b>."), buttonsFilter: ['next'] },
            { target: '.trees', text: i18n.t("<i>Cliquez ici</i> pour ouvrir les outils 'Arbres'"), placement: 'right' },
            { target: '#c3rVPSJ2', text: i18n.t("<i>Cliquez ici</i> pour activer l'outil d'ajout."), placement: 'right' },
            { target: '#add-tree-area', text: i18n.t("<i>Cliquez sur la carte</i> pour ajouter l'arbre."), placement: 'right' },
            { target: '.place', text: i18n.t("Le lieu est complété automatiquement, mais vous pouvez toujours le modifier si nécessaire."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.gender', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'right' },
            { target: '.gender > div > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez un genre</i>."), placement: 'right' },
            { target: '.cover-type', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'right' },
            { target: '.cover-type > div > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez un type de couverture</i>."), placement: 'right' },
            { target: '.modal-content-footer > .ui:last-child', text: i18n.t("<i>Cliquez ici</i> pour passer à l'étape suivante."), placement: 'top' },
            { target: '.height', text: i18n.t("<i>Saisissez '20'</i>"), placement: 'top', verificationFn: () => checkInput('.height', '20') },
            { target: '.crown-diameter', text: i18n.t("<i>Saisissez '9'</i>"), placement: 'top', verificationFn: () => checkInput('.crown-diameter', '9') },
            { target: '.modal-content-footer > .ui:last-child', text: i18n.t("<i>Cliquez ici</i> pour passer à l'étape suivante."), placement: 'top' },
            { target: '.vigor', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'right' },
            { target: '.vigor > div > div.visible.menu.transition', text: i18n.t("<i></i>Sélectionnez une vigueur."), placement: 'right' },
            { target: '.health-review', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'right' },
            { target: '.health-review > div > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez une vigueur</i>."), placement: 'right' },
            { target: '.modal-content-footer > .ui:last-child', text: i18n.t("<i>Cliquez ici</i> pour passer à l'étape suivante."), placement: 'top' },
            { target: '.modal-content-footer > .ui:last-child', text: i18n.t("<i>Cliquez ici</i> pour terminer l'ajout de l'arbre."), placement: 'top' }
        ]
    },
    {
        title: `${i18n.t("Modale de consultation")} <span style="margin-left: 5px;">📖</span>`,
        children: [
            { target: 'body', text: i18n.t("Une fois l'arbre ajouté, une <b>modale de consultation des données</b> apparaît."), buttonsFilter: ['next'] },
            { target: '#gUT35zHR', text: i18n.t("On retrouve ici la <b>référence de l'arbre</b> qui permet de l'identifier. <br/><b>La première est générée automatiquement</b> tandis que <b>la seconde peut être encodée lors de l'ajout</b>. <br/>Vous pourrez, depuis ces menus déroulants, naviguer facilement d'un arbre à un autre."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#eMziYNLn', text: i18n.t("On retrouve ici les informations concernant <b>l'emplacementde l'arbre</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#dVetdybF', text: i18n.t("On retrouve ici les informations concernant <b>le milieu dans lequel se trouve l'arbre</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#tnyk8PtV', text: i18n.t("On retrouve ici l'<b>essence de l'arbre</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#wuM5v22y', text: i18n.t("On retrouve ici les <b>dimensions de l'arbre</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#sRghMdgV', text: i18n.t("On retrouve ici les informations concernant l'<b>état sanitaire de l'arbre</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#PuaLRZhU', text: i18n.t("On retrouve ici les <b>différents indicateurs calculés automatiquement</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#mobile-modal > div.modalNavbar > div.ui.top.attached.tabular.left.menu > a:nth-child(2)', text: i18n.t("<i>Cliquez ici</i> pour accéder au formulaire de modification."), placement: 'bottom' },
            { target: 'body', text: i18n.t("Depuis cet onglet, vous pouvez facilement <b>modifier les informations</b> de l'arbre ou le <b>supprimer</b>."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.gender', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'right' },
            { target: '.gender > div > div.visible.menu.transition', text: i18n.t("<i>Modifiez le genre</i>."), placement: 'right' },
            { target: '.modal-content-footer > .ui:nth-child(3)', text: i18n.t("<i>Validez la modification</i>."), placement: 'top' },
            { target: '#mobile-modal > div.modalNavbar > div.ui.top.attached.tabular.left.menu > a:nth-child(3)', text: i18n.t("<i>Cliquez ici</i> pour accéder à l'historique de modification."), placement: 'bottom' },
            { target: 'body', text: i18n.t("Depuis cet onglet, vous pouvez <b>consulter les modifications effectuées</b> sur l'arbre et <b>restaurer un état antérieur</b>."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.rdt_TableBody > div > div:nth-child(8)', text: i18n.t("Les modifications effectuées apparaissent en vert clair."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.rdt_TableBody > div:nth-child(2) > div:first-child', text: i18n.t("Ce bouton vous permet de <b>restaurer un état antérieur</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#mobile-modal > div.modalNavbar > div.ui.top.attached.tabular.left.menu > a:nth-child(4)', text: i18n.t("<i>Cliquez ici</i> pour accéder à la galerie photo."), placement: 'bottom' },
            { target: 'body', text: i18n.t("Depuis cet onglet, vous pouvez <b>ajouter/supprimer des photos</b> représentant l'arbre en question."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#mobile-modal > div.modalNavbar > div.ui.top.attached.tabular.left.menu > a:nth-child(5)', text: i18n.t("<i>Cliquez ici</i> pour accéder au partage de fichiers."), placement: 'bottom', rights: ['filesSharing'] },
            { target: 'body', text: i18n.t("Depuis cet onglet, vous pouvez <b>ajouter/supprimer des fichiers</b> relatifs à l'arbre (PDFs, Excel, Word, ...)."), placement: 'top', rights: ['filesSharing'], buttonsFilter: ['next'], canClickTarget: false },
        ]
    },
    {
        title: `${i18n.t("Gestion des actions")} <span style="margin-left: 5px;">⚡</span>`,
        rights: ['actions'],
        children: [
            { target: '#mobile-modal > div.modalNavbar > div.ui.top.attached.tabular.left.menu > a:nth-child(6)', text: i18n.t("<i>Cliquez ici</i> pour accéder à la gestion des actions."), placement: 'bottom' },
            { target: 'body', text: i18n.t("Depuis cet onglet, vous pouvez <b>gérer toutes les interventions</b> à effectuer sur l'arbre et <b>obtenir un estimatif de coûts</b>."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-footer > div > div > div:first-child > button', text: i18n.t("<i>Cliquez ici</i> pour ajouter une action."), placement: 'top' },
            { target: '.modal-content-footer form .stretched.row > div.column > div', text: i18n.t("<i>Cliquez sur le menu déroulant</i> & <i>saisissez 'Allègement'</i>."), placement: 'right', verificationFn: () => checkInput('.modal-content-footer form .stretched.row > div.column > div', 'All') },
            { target: '.visible.menu.transition', text: i18n.t("<i>Sélectionnez l'action trouvée</i>."), placement: 'right' },
            { target: '.ui.checkbox', text: i18n.t("<i>Cochez l'option de récurrence</i>."), placement: 'right' },
            { target: '.modal-content-footer div.stretched.row > div:nth-child(2) > div:nth-child(1)', text: i18n.t("<i>Cliquez ici</i>."), placement: 'right' },
            { target: '.clndr-floating', text: i18n.t("<i>Sélectionnez une date de début</i>."), placement: 'bottom' },
            { target: '.modal-content-footer div.stretched.row > div:nth-child(2) > div:nth-child(2)', text: i18n.t("<i>Cliquez ici</i>."), placement: 'right' },
            { target: '.clndr-floating', text: i18n.t("<i>Sélectionnez une date de fin</i>."), placement: 'bottom' },
            { target: 'input[name=recurrenceValue]', text: i18n.t("Ce champs vous permet de <b>modifier l'intervalle entre chaque récurrence</b>."), placement: 'left', buttonsFilter: ['next'], canClickTarget: false },
            { target: 'div[name=recurrenceUnit]', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'left' },
            { target: '.visible.menu.transition', text: i18n.t("<i>Sélectionnez une unité de récurrence</i>."), placement: 'left' },
            { target: '[type=submit]', text: i18n.t("<i>Validez l'ajout</i>."), placement: 'top' },
            { target: '.modal-content-body.menu > div:last-child > .ui.grid', text: i18n.t("L'action a été ajoutée ici."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:last-child > div > div > div:nth-child(1) > div > button', text: i18n.t("Ce bouton permet de <b>consulter les récurrences</b> de l'action."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:last-child > div > div > div.two.wide.column > div > button.NFRDVLiw', text: i18n.t("Ce bouton permet de <b>délier l'action</b> de l'arbre."), placement: 'left', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:last-child > div > div > div.two.wide.column > div > div', text: i18n.t("Ce bouton permet de <b>valider/invalider l'action</b>."), placement: 'left', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:last-child > div > div > div.two.wide.column > div > button.h5cu38ND', text: i18n.t("Ce bouton permet d'<b>ajouter un commentaire</b> sur l'action."), placement: 'left', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#kmP9ozeA', text: i18n.t("<i>Cliquez ici</i> pour fermer la modale de consultation."), placement: 'left' },
            { target: '#cSCfLHkG', text: i18n.t("<i>Cliquez ici</i> pour ouvrir les outils avancés."), placement: 'right' },
            { target: '#jugChGsl', text: i18n.t("<i>Cliquez ici</i> pour accéder au menu de gestion des actions."), placement: 'right' },
            { target: 'body', text: i18n.t("Ce menu vous permet de <b>gérer les actions de manière globale</b>."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:nth-child(2) > div > div > div:nth-child(4)', text: i18n.t("Une action peut être liée à plusieurs éléments. Le <b>nombre de liaisons</b> est indiqué ici."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:nth-child(2) > div > div > div:nth-child(3)', text: i18n.t("Vous pouvez également consulter le <b>prix total</b> de l'action en comptabilisant l'ensemble des liaisons & récurrences"), placement: 'bottom', rights: ['costEstimation'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:nth-child(2) > div > div > div:first-child button:first-child', text: i18n.t("Ce bouton vous permet de <b>consulter toutes les récurrences</b> de l'action."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:nth-child(2) > div > div > div:first-child button.blue', text: i18n.t("Ce bouton vous permet de <b>lier/délier l'action</b> aux éléments depuis la carte."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:nth-child(2) > div > div > div:first-child button.purple', text: i18n.t("Ce bouton vous permet d'<b>exporter l'action</b> en format PDF."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-body.menu > div:nth-child(2) > div > div > div:first-child button.red', text: i18n.t("Ce bouton vous permet de <b>supprimer l'action</b> et toutes ses liaisons."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '#kmP9ozeA', text: i18n.t("<i>Cliquez ici</i> pour fermer la modale."), placement: 'left' }
        ]
    },
    {
        title: `${i18n.t("Application de filtres")} <span style="margin-top: -3px; margin-left: 5px;">🔎</span>`,
        rights: ['filters'],
        children: [
            { target: '#ymw1sd3w', text: i18n.t("<i>Cliquez ici</i> pour accéder à l'<b>outil des filtres</b>."), placement: 'right' },
            { target: '.modal-content-header > div.ui.buttons', text: i18n.t("Ici, vous pouvez <b>sélectionner la catégorie des éléments à fitrer</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            {
                target: '.modal-content-body > div > div > div > div:nth-child(1)', text: i18n.t("<i>Cliquez sur le menu déroulant</i> & <i>saisissez 'Cote'</i>."), placement: 'bottom',
                verificationFn: () => checkInput('.modal-content-body > div > div > div > div:nth-child(1)', 'Cote', true)
            },
            { target: '.visible.menu.transition', text: i18n.t("<i>Sélectionnez la valeur trouvée</i>."), placement: 'bottom' },
            { target: '.modal-content-body > div > div > div > div:nth-child(2)', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'bottom' },
            { target: '.visible.menu.transition', text: i18n.t("<i>Sélectionnez une condition</i>."), placement: 'bottom' },
            { target: '.modal-content-body > div > div > div > div:nth-child(3)', text: i18n.t("<i>Cliquez sur le menu déroulant</i>."), placement: 'bottom' },
            { target: '.visible.menu.transition', text: i18n.t("<i>Sélectionnez une valeur</i>."), placement: 'bottom' },
            { target: '.modal-content-body > div > div:nth-child(2) > div', text: i18n.t("Lorsqu'une ligne est terminée, une autre apparaît. Cela vous permet de réaliser des <b>filtres combinés</b>."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.ui.action.input input', text: i18n.t("Vous pouvez facilement <b>sauvegarder la configuration de votre filtre</b>. Ce champs vous permet de saisir un nom."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-footer > div > .red', text: i18n.t("Par défaut votre filtre est <b>lié au projet dans lequel vous le créer</b>. Si vous souhaitez le créer de manière globale, vous pouvez cliquer sur ce bouton."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-footer > div > .green', text: i18n.t("<i>Cliquez sur ce bouton</i> pour sauvegarder."), placement: 'top' },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .wgHinzi1', text: i18n.t("Ce bouton vous permet de <b>rétablir le filtre sauvegardé</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .YgiYc9uX', text: i18n.t("Si vous avez lié le filtre au projet, ce bouton vous permet de le <b>partager avec vos collaborateurs</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .sJzjmP51', text: i18n.t("Ce bouton vous permet de <b>supprimer le filtre</b>."), placement: 'right', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-footer > .blue', text: i18n.t("Ce bouton vous permet de <b>réinitialiser les filtres</b>."), placement: 'top', buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-footer > .green', text: i18n.t("<i>Cliquez ici</i> pour appliquer le filtre."), placement: 'top' },
            { target: 'body', text: i18n.t("Si des éléments respectent les critères de votre filtre, ils apparaîtront sur la carte."), buttonsFilter: ['next'], canClickTarget: false },
            { target: '#ymw1sd3w', text: i18n.t("<i>Cliquez  à nouveau sur l'outil 'Filtres'</i> pour <b>retirer tous les filtres appliqués</b> à la carte."), placement: 'right' },
            { target: '#kmP9ozeA', text: i18n.t("<i>Cliquez ici</i> pour fermer la modale."), placement: 'left' }
        ]
    },
    {
        title: `${i18n.t("Statistiques & graphiques")} <span style="margin-left: 5px;">📈</span>`,
        rights: ['charts'],
        children: [
            { target: '#Vp7ZCFyO', text: i18n.t("<i>Cliquez ici</i> pour accéder aux statistiques du projet</b>."), placement: 'right' },
            { target: '#modal-content', text: i18n.t("Les statistiques relatifs aux éléments (arbres, espaces verts, ...) sont repris ci-dessous."), placement: 'top', buttonsFilter: ['next'], classes: 'remove-margin', canClickTarget: false },
            { target: 'div.modalNavbar > div > div', text: i18n.t("Ces onglets sont des <b>graphiques de bases</b> mis à votre disposition."), placement: 'bottom', buttonsFilter: ['next'], canClickTarget: false },
            { target: 'div.modalNavbar > div > a:last-child', text: i18n.t("<i>Cliquez ici</i> pour accéder aux graphiques personnalisés."), placement: 'bottom', rights: ['customCharts'] },
            { target: '.modal-content-header > div.ui.buttons', text: i18n.t("Ici, vous pouvez <b>sélectionner la catégorie des éléments</b> dont vous souhaitez gérer les graphiques personnalisés."), placement: 'bottom', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: 'form > div:nth-child(1)', text: i18n.t("Ce champs vous permet de <b>saisir un titre</b>."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'] },
            {
                target: 'div:nth-child(1) > form > div:nth-child(2)', text: i18n.t("<i>Cliquez sur le menu déroulant</i> et <i>recherchez 'Genre'</i>."), placement: 'right', rights: ['customCharts'],
                verificationFn: () => checkInput('div:nth-child(1) > form > div:nth-child(2)', 'Genre', true)
            },
            { target: 'form > div:nth-child(2) > div > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez la propriété trouvée</i>."), placement: 'right', rights: ['customCharts'] },
            {
                target: 'div:nth-child(1) > form > div:nth-child(3)', text: i18n.t("<i>Cliquez sur le menu déroulant</i> et <i>recherchez 'Bâton'</i>."), placement: 'right', rights: ['customCharts'],
                verificationFn: () => checkInput('div:nth-child(1) > form > div:nth-child(3)', 'Bâton', true)
            },
            { target: 'div:nth-child(1) > form > div:nth-child(3) > div > div.visible.menu.transition', text: i18n.t("<i>Sélectionnez le type trouvé</i>."), placement: 'right', rights: ['customCharts'] },
            { target: 'div:nth-child(1) > form > div:nth-child(4)', text: i18n.t("Ce menu déroulant vous permet de sélectionner une propriété secondaire."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: 'div:nth-child(1) > form > div:nth-child(5)', text: i18n.t("Ici, vous pouvez <b>configurer votre graphique</b>."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.ui.action.input input', text: i18n.t("Vous pouvez facilement <b>sauvegarder la configuration de votre graphique</b>. Ce champs vous permet de saisir un nom."), placement: 'top', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: 'button.ui.red.icon.button', text: i18n.t("Par défaut votre graphique est <b>lié au projet dans lequel vous le créer</b>. Si vous souhaitez le créer de manière globale, vous pouvez cliquer sur ce bouton."), placement: 'top', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: 'button.ui.green.icon.button', text: i18n.t("<i>Cliquez sur ce bouton</i> pour sauvegarder."), placement: 'top', rights: ['customCharts'] },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .NUGHw5Zl', text: i18n.t("Ce bouton vous permet de <b>consulter un graphique sauvegardé</b>."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .hLWSnIOs', text: i18n.t("Ce bouton vous permet de <b>modifier le graphique</b>."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .ed3ZZLJL', text: i18n.t("Si vous avez lié le graphique au projet, ce bouton vous permet de le <b>partager avec vos collaborateurs</b>."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '.modal-content-header > div:nth-child(3) > div > div > div:first-child > .L7434QE1', text: i18n.t("Ce bouton vous permet de <b>supprimer le graphique</b>."), placement: 'right', rights: ['customCharts'], buttonsFilter: ['next'], canClickTarget: false },
            { target: '#kmP9ozeA', text: i18n.t("<i>Cliquez ici</i> pour fermer la modale."), placement: 'left' }
        ]
    },
    { target: 'body', title: `${i18n.t("Tutoriel terminé")} <span style="margin-top: -1px; margin-left: 5px;">✅</span>`, text: i18n.t("Vous avez complété le tutoriel avec succès."), buttonsFilter: ['finish'] },
];