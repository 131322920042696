import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { Input, Form, Grid, Select, Segment, Checkbox } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
// Redux
import { connect } from 'react-redux';
// Ressources
import { faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step1 extends Component {
    state = {
        properties: {
            isTreeBase: false,
            place: '',
            placeExtra: '',
            customReference: null,
            tagId: [],
            spaceFunctionId: 0
        },
        error: {
            hidden: true,
            messages: [],
            place: false
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.greenSpaces;
        const { place, placeExtra, customReference, tagId, spaceFunctionId } = this.props.properties;
        const spaceFunctions = FormattersUtil.sortByAlphabeticalOrder(this.props.spaceFunctions.map(x => { return { text: x.label, value: x.id } }));

        let tags = [];
        if (this.props.project && this.props.projectTags) {
            this.props.projectTags.forEach(tag => {
                if (tag.category === 'Espace vert')
                    tags.push({ key: tag.id, text: tag.label, value: tag.id })
            });
        }

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 16,
            mobile: 16
        };

        return (
            <Grid id='cat--site' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--yellow-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Espace vert', 'Emplacement') }}>
                <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--yellow-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                    <FontAwesomeIcon icon={faMapMarkedAlt} style={{ alignSelf: 'center', marginRight: '5px' }} />
                    <span className='no-themed'>{i18n.t("Emplacement & en-tête")}</span>
                </Grid.Row>
                {requiredFields.isTreeBase &&
                    <Grid.Column stretched computer={2} tablet={4} mobile={16} style={isMobileOnly ? { paddingTop: 0, paddingBottom: 0 } : { paddingBottom: 0 }} >
                        <Segment style={{ margin: `${isMobileOnly ? '20px' : '10px'} 0 20px 0` }}>
                            {requiredFields.isTreeBase &&
                                <Form.Field
                                    control={Checkbox} label={i18n.t("Pied d'arbre")}
                                    name='isTreeBase' checked={this.props.properties.isTreeBase || false}
                                    onChange={this.props.handleCheckboxChange}
                                />}
                        </Segment>
                    </Grid.Column>}
                {requiredFields.place &&
                    <Grid.Column stretched computer={5} tablet={6} mobile={16}>
                        <Form.Field
                            control={Input} label={i18n.t("Lieu") + ' : '} placeholder={i18n.t("Indiquez un lieu")}
                            name='place' value={place || ''} error={this.props.error.place} onChange={this.props.handleChange}
                            disabled={!this.props.placeLoaded} loading={!this.props.placeLoaded}
                        />
                    </Grid.Column>}
                {requiredFields.placeExtra &&
                    <Grid.Column stretched computer={4} tablet={6} mobile={16}>
                        <Form.Field
                            control={Input} label={i18n.t("Libellé") + ' : '} placeholder={i18n.t("Indiquez un libellé")}
                            name='placeExtra' value={placeExtra || ''} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.customReference &&
                    <Grid.Column stretched computer={5} tablet={16} mobile={16}>
                        <Form.Field
                            control={Input} type='text' label={<label>{i18n.t("Référence personnalisée")} <InfoIcon content={i18n.t("Référence libre")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Ex: be-ch-13'
                            name='customReference' value={customReference || ''}
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {this.props.project && requiredFields.tags &&
                    <Grid.Column stretched computer={16} tablet={16} mobile={16}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un ou plusieurs tags")} style={{ width: '100%' }}
                            label={<label>{i18n.t("Tags")} <InfoIcon content={i18n.t("Note complémentaire (ex: n° quartier, caractéristique supplémentaire, ...)")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='tagId' options={tags} value={tagId || []} clearable onChange={this.props.handleTagsChange}
                            multiple search={FormattersUtil.searchList} selection allowAdditions selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            additionLabel={i18n.t("Ajouter") + ' '} onAddItem={(e, data) => this.props.handleAddTag(data.value)}
                        />
                    </Grid.Column>}
                {requiredFields.spaceFunction &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} id='VxR5MeM3' placeholder={i18n.t("Sélectionnez une fonction")} style={{ width: '100%' }}
                            label={<label>{i18n.t("Fonction de l'espace")} <InfoIcon content={i18n.t("Usage de l'espace")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='spaceFunctionId' options={spaceFunctions} value={spaceFunctionId || ''}
                            selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement'))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        spaceFunctions: state.spaceFunctions,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step1);