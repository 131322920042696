import React, { Component } from 'react';
// Composants
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { isMobile, isMobileOnly } from 'react-device-detect';
// Redux
import { setProject } from '../../actionCreators/projectsActions';
// Ressources
import { faLink } from '@fortawesome/pro-solid-svg-icons';
// Utils
import LinkedElementCard from '../Cards/LinkedElementCard';
import DatesUtil from '../../utils/DatesUtil';
import RightsUtil from '../../utils/RightsUtil';

class LinkedElementList extends Component {
    state = {
        linkedElementsLayers: []
    }

    render() {
        const { loginAsData, project, rights } = this.props;
        const { linkedElementsLayers } = this.state;
        const category = this.props.layer ? this.props.layer[0].feature.properties.category : null;
        const rightsToCheck = { 'Arbre': rights.trees, 'Espace vert': rights.greenSpaces, 'Mobilier': rights.furnitures, 'Repère': rights.markers }[category];
        const canWrite = !loginAsData?.readOnly && project.type === 'project' && RightsUtil.canWrite(rightsToCheck);

        return (
            <>
                <div className='modal-content'>
                    {canWrite &&
                        <div className='modal-content-header' style={{ marginBottom: '25px', display: 'flex' }}>
                            <Button color='blue' style={{ marginLeft: 'auto' }} onClick={() => this.props.linkElements(linkedElementsLayers)}>
                                <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Gérer les éléments")}
                            </Button>
                        </div>}
                    <div className='modal-content-body'>
                        {this.renderLinkedElements()}
                    </div>
                </div>
            </>
        );
    }

    componentDidMount = () => {
        this.getLinkedElementsLayers();
    }

    componentDidUpdate = (prevProps) => {
        if ((!prevProps.layer && this.props.layer) || (prevProps.layer && this.props.layer && prevProps.layer[0].feature.id !== this.props.layer[0].feature.id))
            this.getLinkedElementsLayers();
    }

    renderLinkedElements = () => {
        const { loginAsData, project, layer, rights } = this.props;
        const elementId = layer[0].feature.id;
        const linkedElementsToRender = project.linkedElements?.length ? project.linkedElements.filter(le => le.elementId === elementId || le.linkedElementId === elementId) : [];
        const category = this.props.layer[0].feature.properties.category;
        const rightsToCheck = { 'Arbre': rights.trees, 'Espace vert': rights.greenSpaces, 'Mobilier': rights.furnitures, 'Repère': rights.markers }[category];
        const canWrite = !loginAsData?.readOnly && project.type === 'project' && RightsUtil.canWrite(rightsToCheck);

        return (
            <>
                {linkedElementsToRender.length ?
                    <div style={{
                        display: 'grid', gridGap: '20px', overflow: 'auto',
                        gridTemplateColumns: isMobileOnly ? 'repeat(1, 1fr)'
                            : isMobile ? 'repeat(2, 1fr)'
                                : 'repeat(4, 1fr)'
                    }}>
                        {linkedElementsToRender
                            .toSorted((a, b) => DatesUtil.convertUTCDateToDate(b.date).getTime() - DatesUtil.convertUTCDateToDate(a.date).getTime())
                            .map((linkedElement, index) => {
                                const layer = this.state.linkedElementsLayers.find(l => l.feature.id !== elementId && (l.feature.id === linkedElement.elementId || l.feature.id === linkedElement.linkedElementId));
                                const canView = layer ? true : false;
                                return (
                                    <LinkedElementCard
                                        key={index} elementId={elementId} linkedElement={linkedElement} feature={layer?.feature} canView={canView} canWrite={canWrite}
                                        showElement={this.props.showElement} removeLinkedElement={this.removeLinkedElement}
                                    />
                                );
                            })}
                    </div>
                    : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                        <FontAwesomeIcon icon={faLink} size='6x' style={{ marginTop: 'auto' }} />
                        <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun élément trouvé")}</h4>
                    </div>}
            </>
        );
    }

    getLinkedElementsLayers = () => {
        const { layerContainers, layer, project } = this.props;
        const { linkedElements } = project;
        const elementId = layer[0].feature.id;
        if (!linkedElements?.length) return;
        const categories = [...new Set(linkedElements.filter(le => le.elementId === elementId || le.linkedElementId === elementId).flatMap(linkedElement => [linkedElement.elementCategory, linkedElement.linkedElementCategory]))];
        let linkedElementsLayers = [];

        categories.forEach(category => {
            let layers = [];
            switch (category) {
                case 'Arbre':
                    layers = layerContainers.treesLayer.getLayers();
                    break;
                case 'Espace vert':
                    layers = layerContainers.greenSpacesLayer.getLayers();
                    break;
                case 'Mobilier':
                    layers = layerContainers.furnituresLayer.getLayers();
                    break;
                case 'Repère':
                    layers = layerContainers.markersLayer.getLayers();
                    break;
                case 'Station':
                    layers = layerContainers.stationsLayer.getLayers();
                    break;
                default:
                    break;
            }

            linkedElementsLayers = [
                ...linkedElementsLayers,
                ...layers.filter(layer => layer.feature.id !== elementId && linkedElements.find(linkedElement =>
                    (linkedElement.elementId === elementId || linkedElement.linkedElementId === elementId) &&
                    (linkedElement.elementId === layer.feature.id || linkedElement.linkedElementId === layer.feature.id)
                ))
            ];
        });

        this.setState({ linkedElementsLayers });
    }

    removeLinkedElement = (linkedElement) => {
        this.setState(prevState => ({ linkedElementsLayers: prevState.linkedElementsLayers.filter(lel => lel.feature.id !== linkedElement.linkedElementId) }));
    }
}

const mapStateToProps = (state) => {
    return {
        rights: state.rights,
        layer: state.layer,
        projects: state.projects,
        project: state.project,
    };
};

const mapDispatchToProps = {
    setProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedElementList);