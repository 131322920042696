import React, { Component } from 'react';
// Composants
import { Dropdown } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Libraries
import i18n from '../../locales/i18n';
import { faEye, faFlowerTulip, faHexagon, faMapMarkerAlt, faMapMarkerQuestion, faQuestion, faTablePicnic, faTree, faUnlink } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setProjects, setProject } from '../../actionCreators/projectsActions';
// Services
import ProjectsService from '../../services/ProjectsService';
// Utils
import MapPreview from '../Utils/MapPreview';
import StylesUtil from '../../utils/StylesUtil';
import DatesUtil from '../../utils/DatesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import UrlsUtil from '../../utils/UrlsUtil';

class LinkedElementCard extends Component {
    render() {
        const { elementId, linkedElement, feature } = this.props;
        const isLinked = linkedElement.linkedElementId === elementId;
        const id = isLinked ? linkedElement.elementId : linkedElement.linkedElementId;
        const category = isLinked ? linkedElement.elementCategory : linkedElement.linkedElementCategory;

        return (
            <div
                className='linked-element-card'
                style={{
                    display: 'flex', flexDirection: 'column', borderRadius: '20px', width: '100%', border: '1px solid var(--black-60)', overflow: 'hidden',
                    backgroundColor: category === 'Arbre' ? 'var(--primary-100)'
                        : category === 'Espace vert' ? 'var(--secondary-100)'
                            : category === 'Mobilier' ? 'var(--blue-100)'
                                : category === 'Repère' ? 'var(--purple-100)'
                                    : 'var(--red-100)'
                }}>
                <div className='header' style={{ display: 'flex', padding: '15px 15px 0 15px' }}>
                    <div style={{ flex: 1 }}>
                        <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                            <FontAwesomeIcon
                                icon={
                                    category === 'Arbre' ? faTree
                                        : category === 'Espace vert' ? faFlowerTulip
                                            : category === 'Mobilier' ? faTablePicnic
                                                : category === 'Repère' ? faMapMarkerQuestion
                                                    : faHexagon
                                }
                                style={{ marginRight: '10px' }}
                            />
                            {this.renderInfos(category)}
                        </h3>
                        <small style={{ display: 'block', color: 'var(--white-80)', margin: '0 0 5px 0' }}>
                            {this.renderReferences(category)}
                        </small>
                    </div>
                    <div>
                        <Dropdown icon='ellipsis vertical' floating button direction='left' className='icon' style={{ padding: '7px' }}>
                            <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                    {this.renderOptions()}
                                </Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className='content' style={{ flex: 1, display: 'flex', padding: '15px 15px 10px 15px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            {feature ?
                                <MapPreview
                                    id={id} style={{ borderRadius: '15px', height: '150px', width: '100%', border: '1px solid var(--black-60)', marginTop: 'auto' }}
                                    features={[feature]} elementStyle={
                                        category === 'Arbre' ? { tree: StylesUtil.getTreeStyle() }
                                            : category === 'Espace vert' ? { greenSpace: StylesUtil.getGreenSpaceStyle() }
                                                : category === 'Mobilier' ? { furniture: StylesUtil.getFurnitureStyle() }
                                                    : category === 'Repère' ? { marker: StylesUtil.getMarkerStyle() }
                                                        : { station: StylesUtil.getStationStyle() }
                                    }
                                    onClick={() => this.props.showElement(feature.properties.category, feature, null, { highlight: false, blink: true })}
                                />
                                : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '150px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={faQuestion} size='3x' />
                                        <p style={{ marginTop: '5px' }}>{i18n.t("Élément introuvable")}</p>
                                    </div>
                                </div>}
                        </div>
                        <small style={{ color: 'var(--grey-60)', marginTop: '10px', textAlign: 'center' }}>{i18n.t("Lié le")} {DatesUtil.convertUTCDateToLocaleDate(linkedElement.date)}</small>
                    </div>
                </div>
            </div>
        );
    }

    renderOptions = () => {
        const { isDarkTheme, linkedElement, feature, canView, canWrite } = this.props;

        return (<>
            {canView && feature?.properties?.category !== 'Station' &&
                <Dropdown.Item onClick={this.openElement}>
                    <FontAwesomeIcon icon={faEye} style={{ marginRight: '12px' }} />{i18n.t("Consulter")}
                </Dropdown.Item>}
            {canView &&
                <Dropdown.Item onClick={() => this.props.showElement(feature.properties.category, feature, null, { highlight: false, blink: true })}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '12px' }} />{i18n.t("Voir (carte)")}
                </Dropdown.Item>}
            {canWrite &&
                <>
                    <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '0' }} />
                    <Dropdown.Item className='delete' onClick={() => this.handleUnlinkElement(linkedElement)}>
                        <FontAwesomeIcon icon={faUnlink} style={{ marginRight: '12px' }} />{i18n.t("Délier")}
                    </Dropdown.Item>
                </>}
        </>)
    }

    renderInfos = (category) => {
        const { feature, essences, dominantCompositions, furnitureTypes } = this.props;
        if (!feature) return i18n.t(category);

        let info;
        switch (category) {
            case 'Arbre':
                if (feature.properties.essenceId > 0)
                    info = essences.find(e => e.id === feature.properties.essenceId)?.vernacularName;
                break;
            case 'Espace vert':
                if (feature.properties.dominantCompositionId > 0)
                    info = dominantCompositions.find(dc => dc.id === feature.properties.dominantCompositionId)?.label;
                break;
            case 'Mobilier':
                if (feature.properties.typeId > 0)
                    info = furnitureTypes.find(ft => ft.id === feature.properties.typeId)?.label;
                break;
            default: break;
        }

        return info ?? i18n.t(category);
    }

    renderReferences = (category) => {
        const { feature } = this.props;
        if (!feature) return i18n.t("Introuvable");

        let references;
        switch (category) {
            case 'Arbre': case 'Espace vert': case 'Mobilier': case 'Repère':
                references = feature.properties.projectReference;
                if (feature.properties.customReference) references += ` | ${feature.properties.customReference}`;
                return (
                    i18n.t("Référence : {{references}}", { references })
                );
            case 'Station':
                return i18n.t("Nom : {{label}}", { label: feature.properties.label });
            default:
                return;
        }
    }

    handleUnlinkElement = (linkedElement) => {
        const { project, projects, formulas, webSocketHubs } = this.props;
        let updatedProject = JSON.parse(JSON.stringify(project));

        ProjectsService.deleteLinkedElements([linkedElement], project.id).then(() => {
            this.props.removeLinkedElement(linkedElement);
            updatedProject.linkedElements = updatedProject.linkedElements.filter(le => le.elementId !== linkedElement.linkedElementId && le.linkedElementId !== linkedElement.linkedElementId);
            ProjectsUtil.updateProjectsInProps(updatedProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
            WebSocketUtil.updateProject(webSocketHubs, project.id, updatedProject);
        });
    }

    openElement = () => {
        const { feature } = this.props;
        const category = feature.properties.category;
        const type = category === 'Arbre' ? 'TreeDetail'
            : category === 'Espace vert' ? 'GreenSpaceDetail'
                : category === 'Mobilier' ? 'FurnitureDetail'
                    : 'MarkerDetail';
        const modalPath = UrlsUtil.getModalPath(type, feature);
        const elementUrl = new URL(`${window.location.origin}/projects/${feature.projectId}/${modalPath}`);
        window.open(elementUrl.href, '_blank');
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projects,
        project: state.project,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs,
        isDarkTheme: state.isDarkTheme,
        essences: state.essences,
        dominantCompositions: state.dominantCompositions,
        furnitureTypes: state.furnitureTypes,
    };
};

const mapDispatchToProps = {
    setProjects,
    setProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedElementCard);