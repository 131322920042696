import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import frBE from './fr-BE.json';
import nlBE from './nl-BE.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import zhCHT from './zh-CHT.json';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'fr-BE': { translation: frBE },
            'nl-BE': { translation: nlBE },
            'en-US': { translation: enUS },
            'es-ES': { translation: esES },
            'zh-CHT': { translation: zhCHT }
        },
        fallbackLng: 'fr-BE'
    });

export default i18n;