import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppSettings from '../../AppSettings';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faBuilding, faCaretDown, faCaretRight, faCircle, faFileAlt, faFolder, faFolders, faHouse, faLock, faMoon, faPenToSquare, faShieldPlus, faSignOut, faSun, faSync, faUser, faWifi, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import Tasks from '../Utils/Tasks';
import Notifications from '../Utils/Notifications';
import { Menu, Sidebar, Popup, Dropdown, Flag } from 'semantic-ui-react';
// Librairies
import { isMobileOnly, withOrientationChange } from 'react-device-detect';
import { connect } from 'react-redux';
import { setIsDarkTheme } from '../../actionCreators/appActions';
import { setUserInfos } from '../../actionCreators/usersActions';
import { setProjectShortcut } from '../../actionCreators/componentsActions';
import { setHomeInfos } from '../../actionCreators/usersActions';
import i18n from '../../locales/i18n';
// Services
import UsersService from '../../services/UsersService';
// Utils
import LanguagesUtil from '../../utils/LanguagesUtil';
import RightsUtil from '../../utils/RightsUtil';

class MobileNavbar extends Component {
  state = {
    isVisible: false,
    connectionState: 2,
    showPopup: false,
    showOrganizationList: false
  }

  render() {
    const { isPreview, theme, project, activeOrganization, organizations } = this.props;
    const { showOrganizationList } = this.state;

    const sidebarStyle = { width: '100%', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: 9999, margin: 0, pointerEvents: isPreview && 'none' };
    const activeItem = this.props.activeItem; // Permet de savoir quelle est la page affichée actuellement
    const firstName = this.props.userInfos?.firstName || null;
    const culture = LanguagesUtil.getUserCulture(this.props.userInfos);
    const sync = this.props.sync?.state && !isNaN(this.props.sync.nbDone) && !isNaN(this.props.sync.nbMax) ? this.props.sync : null;
    const syncMessage = sync ? `${i18n.t("Synchronisation en cours...")} (${sync.nbDone}/${sync.nbMax})` : '';
    const connectionState = this.state.connectionState;
    const isUrbasense = AppSettings.isUrbasenseUrl();

    return (
      <>
        <Menu style={{ margin: 0, pointerEvents: isPreview && 'none' }} id='navbar'>
          <Menu.Item onClick={this.openSidebar}>
            <i className='fa fa-bars' aria-hidden='true' style={{ fontSize: '20px' }}></i>
          </Menu.Item>
          {(project?.type === 'project' || isPreview) ?
            <Dropdown
              item simple disabled={isPreview} style={{ backgroundColor: this.props.isDarkTheme ? 'var(--black-90)' : 'rgba(0,0,0,.05)', flex: 1, fontSize: '12px' }}
              trigger={<>
                <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '10px' }} />{project?.label || theme?.label || 'Projet'}
              </>}
            >
              <Dropdown.Menu style={{ zIndex: 1200 }}>{this.renderOptions()}</Dropdown.Menu>
            </Dropdown>
            : project && <Menu.Item>
              <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }} />{project?.label || theme?.label || 'Projet'}
            </Menu.Item>}
          <Menu.Menu position='right'>
            {connectionState < 2 && !sync ?
              <Popup
                open={this.state.showPopup && !this.props.isLandscape}
                trigger={
                  <Menu.Item onClick={() => this.setState({ showPopup: !this.state.showPopup })}>
                    <FontAwesomeIcon icon={!connectionState ? faWifiSlash : faWifi} color={!connectionState ? 'red' : connectionState === 1 ? 'yellow' : 'green'} />
                  </Menu.Item>
                }
                position='bottom right'
                content={!connectionState
                  ? i18n.t("Pas d'accès internet, vos données seront synchronisées au retour de la connexion")
                  : i18n.t("La connexion est faible, il est recommandé de travailler en mode hors ligne")}
                style={{ zIndex: 700 }}
              />
              : sync &&
              <Menu.Item>
                <Popup position='top center' content={syncMessage} trigger={
                  <FontAwesomeIcon icon={faSync} size='2x' color='green' />
                } />
              </Menu.Item>}
            <Dropdown item simple trigger={<><Flag name={LanguagesUtil.getCultureFlagName(culture)} /> {!isMobileOnly && culture.split('-')[0].toUpperCase()}</>}>
              <Dropdown.Menu style={{ zIndex: 1200 }}>
                <Dropdown.Item active={culture?.toLowerCase().includes('fr')} onClick={() => this.changeLanguage('fr-BE')}>
                  <Flag name='fr' /> FR
                </Dropdown.Item>
                <Dropdown.Item active={culture?.toLowerCase().includes('nl')} onClick={() => this.changeLanguage('nl-BE')}>
                  <Flag name='nl' /> NL
                </Dropdown.Item>
                <Dropdown.Item active={culture?.toLowerCase().includes('en')} onClick={() => this.changeLanguage('en-US')}>
                  <Flag name='gb' /> EN
                </Dropdown.Item>
                <Dropdown.Item active={culture?.toLowerCase().includes('es')} onClick={() => this.changeLanguage('es-ES')}>
                  <Flag name='es' /> ES
                </Dropdown.Item>
                <Dropdown.Item active={culture?.toLowerCase().includes('zh')} onClick={() => this.changeLanguage('zh-CHT')}>
                  <Flag name='tw' /> ZH
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {this.props.logged && <>
              <Tasks />
              <Notifications />
            </>}
          </Menu.Menu>
        </Menu>
        <Sidebar
          as={Menu} icon='labeled' vertical visible={this.state.isVisible}
          width='thin' style={sidebarStyle}
        >
          <Menu.Item as='a' style={{ textAlign: 'left' }} onClick={this.closeSidebar}>
            <i className='fa fa-times' style={{ fontSize: '20px' }}></i>
          </Menu.Item>
          {organizations?.length > 1 && activeOrganization && <>
            <Menu.Item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} onClick={() => this.setState(prevState => ({ showOrganizationList: !prevState.showOrganizationList }))}>
              <span style={{ fontWeight: 'bold', width: 'fit-content' }}>{activeOrganization.label}</span>
              <FontAwesomeIcon icon={showOrganizationList ? faCaretDown : faCaretRight} style={{ marginLeft: '10px', width: '9px' }} />
            </Menu.Item>
            {showOrganizationList && organizations.map(organization => (
              <Menu.Item
                className='organization-item' style={{ display: 'flex', alignItems: 'center' }}
                disabled={organization.id === activeOrganization.id} onClick={() => this.changeOrganization(organization)}
              >
                <span style={{ paddingLeft: '9px', display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={organization.id === activeOrganization.id ? faCircle : faCircleRegular} style={{ fontSize: '7px', marginRight: '8px' }} />{organization.label}
                </span>
              </Menu.Item>
            ))}
          </>}
          <Menu.Item
            as={Link} to='/' name='home' active={activeItem === 'home'}
            onClick={() => { if (activeItem !== 'home') this.props.setHomeInfos(null); this.props.showProjectList(false, { renderDefaultMap: false }); this.closeSidebar(); }}
          >
            <FontAwesomeIcon icon={faHouse} style={{ marginRight: '10px' }} />{i18n.t("Accueil")}
          </Menu.Item>
          {this.props.logged &&
            <Menu.Item
              as={activeItem !== 'projectMap' && activeItem !== 'home' ? Link : null}
              to='/' onClick={() => this.showProjectList(false)}
            >
              <FontAwesomeIcon icon={faFolders} style={{ marginRight: '10px' }} />{i18n.t("Mes projets")}
            </Menu.Item>}
          {this.props.currentFolderState &&
            <Menu.Item onClick={() => this.showProjectList(true)}>
              <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }} />{this.props.currentFolderState.currentFolder?.label}
            </Menu.Item>}
          {/* <Menu.Item as={Link} to='/subscriptions' onClick={this.closeSidebar} active={activeItem === 'subscriptions'}>
            <FontAwesomeIcon
              icon={faCircleDollar} style={{
                display: 'block', marginLeft: 'auto', marginRight: 'auto',
                marginBottom: '7px', fontSize: '1.71428571em'
              }}
            />
            {i18n.t("Abonnements")}
          </Menu.Item> */}
          {!this.props.logged ?
            <>
              <Menu.Item as={Link} to='/signup' active={activeItem === 'signup'} onClick={this.closeSidebar}>
                <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: '10px' }} />{i18n.t("Inscription")}
              </Menu.Item>
              <Menu.Item as={Link} to='/login' active={activeItem === 'login'} onClick={this.closeSidebar}>
                <FontAwesomeIcon icon={faArrowRightToBracket} style={{ marginRight: '10px' }} />{i18n.t("Connexion")}
              </Menu.Item>
            </>
            :
            <>
              <Menu.Item as={Link} to='/organizationPanel/general' active={activeItem === 'organizationPanel'} onClick={this.closeSidebar}>
                <FontAwesomeIcon icon={faBuilding} style={{ marginRight: '10px' }} />{i18n.t("Mon organisation")}
              </Menu.Item>
              <Menu.Item as={Link} to='/accountPanel/general' active={activeItem === 'accountPanel'} onClick={this.closeSidebar}>
                <FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }} />{i18n.t("Mon compte")}
              </Menu.Item>
            </>}
          {!isUrbasense &&
            <Menu.Item onClick={() => {
              this.props.setIsDarkTheme(!this.props.isDarkTheme).then(() => localStorage.setItem('isDarkTheme', this.props.isDarkTheme));
            }}>
              {this.props.isDarkTheme
                ? <><FontAwesomeIcon icon={faSun} style={{ marginRight: '10px' }} />{i18n.t("Mode jour")}</>
                : <><FontAwesomeIcon icon={faMoon} style={{ marginRight: '10px' }} />{i18n.t("Mode nuit")}</>}
            </Menu.Item>}
          {this.props.logged &&
            <Menu.Item as={Link} to='/login' active={activeItem === 'login'} onClick={this.logUser}>
              <FontAwesomeIcon icon={faSignOut} style={{ marginRight: '10px' }} />{i18n.t("Déconnexion")}
            </Menu.Item>}
        </Sidebar>
      </>
    );
  }

  componentDidMount = () => {
    if (navigator.connection) {
      this.checkConnectionSpeed();
      navigator.connection.onchange = () => this.checkConnectionSpeed();
    }
  }

  checkConnectionSpeed = () => {
    const connectionState = this.state.connectionState;
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    const downlink = connection.downlink;
    let shouldUpdate = false;

    switch (connectionState) {
      case 0: if (downlink > 0) shouldUpdate = true; break;
      case 1: if (!downlink || downlink >= 1) shouldUpdate = true; break;
      case 2: if (downlink < 1) shouldUpdate = true; break;
      default: break;
    }

    if (shouldUpdate) {
      this.setState({
        connectionState: !downlink ? 0 : downlink < 1 ? 1 : 2,
        showPopup: true
      }, () => setTimeout(() => this.setState({ showPopup: false }), 5000));
    }
  }

  changeLanguage = (culture) => {
    if (this.props.userInfos) {
      UsersService.changeLanguage(culture).then(response => {
        this.props.setUserInfos({ ...this.props.userInfos, language: response.data });
        LanguagesUtil.clearLists();
        i18n.changeLanguage(culture);
        localStorage.setItem('culture', culture);
        window.location.reload();
      });
    } else {
      LanguagesUtil.clearLists();
      i18n.changeLanguage(culture);
      localStorage.setItem('culture', culture);
      window.location.reload();
    }
  }

  showProjectList = (showCurrentFolder) => {
    this.closeSidebar();
    this.props.showProjectList(true, { showCurrentFolder });
  }

  logUser = () => {
    this.closeSidebar();
    this.props.logUser(null);
  }

  openSidebar = () => {
    this.setState({
      isVisible: true
    });
  }

  closeSidebar = () => {
    this.setState({
      isVisible: false
    });
  }

  renderOptions = () => {
    const { activeOrganization, rights, loginAsData, isOnline, project } = this.props;
    const projectSubscription = project.organization?.subscription;
    const isEditable = (rights.parameters || rights.publicFields || rights.requiredFields || rights.formulas) && isOnline;
    const areCollaboratorsChangeable = rights.collaborators && projectSubscription.nbCollaborators && isOnline;
    const areRightsChangeable = rights.rights && isOnline;
    const isImportable = RightsUtil.canImportGlobally(rights) && projectSubscription.import && activeOrganization?.subscription.import && isOnline && !loginAsData?.readOnly;
    const isExportable = RightsUtil.canExportGlobally(rights) && projectSubscription.export && activeOrganization?.subscription.export && isOnline;
    const isFileGalleryAvailable = projectSubscription.filesSharing;

    return (<>
      <Dropdown.Item
        icon={projectSubscription.nbCollaborators ? 'users' : 'lock'} text={i18n.t("Gérer les collaborateurs")}
        disabled={!areCollaboratorsChangeable} onClick={() => this.changeModalContentType('CollaboratorsForm', 'Gestion des collaborateurs', this.props.project, areCollaboratorsChangeable)}
      />
      <Dropdown.Item disabled={!areRightsChangeable} onClick={() => this.changeModalContentType('RoleList', 'Gestion des rôles', this.props.project, areRightsChangeable)}>
        <FontAwesomeIcon icon={projectSubscription.nbCollaborators ? faShieldPlus : faLock} style={{ marginRight: '12px' }} />{i18n.t("Gérer les rôles")}
      </Dropdown.Item>
      <Dropdown.Item
        icon={projectSubscription.import ? 'upload' : 'lock'} text={i18n.t("Importer des données")}
        disabled={!isImportable} onClick={() => this.changeModalContentType('ImportForm', i18n.t("Import de données"), this.props.project, isImportable)}
      />
      <Dropdown.Item
        icon={projectSubscription.export ? 'download' : 'lock'} text={i18n.t("Exporter des données")}
        disabled={!isExportable} onClick={() => this.changeModalContentType('ExportForm', i18n.t("Export de données"), this.props.project, isExportable)}
      />
      <Dropdown.Item
        icon={projectSubscription.filesSharing ? 'file alternate' : 'lock'} text={i18n.t("Fichiers")}
        disabled={!isFileGalleryAvailable} onClick={() => this.changeModalContentType('ProjectFilesGallery', i18n.t("Fichiers"), this.props.project, isFileGalleryAvailable)}
      />
      <Dropdown.Item icon='setting' text={i18n.t("Paramètres")} disabled={!isEditable} onClick={() => this.changeModalContentType('ProjectModificationForm', 'Paramètres du projet', this.props.project, isEditable)} />
    </>);
  }

  changeModalContentType = (formType, title, project, canAccess) => {
    if (canAccess) this.props.setProjectShortcut({ formType, title, project });
  }
}

const mapStateToProps = (state) => {
  return {
    userInfos: state.userInfos,
    activeOrganization: state.activeOrganization,
    project: state.project,
    isOnline: state.isOnline,
    sync: state.sync,
    isDarkTheme: state.isDarkTheme,
    projectCollaborators: state.projectCollaborators,
    rights: state.rights,
    loginAsData: state.loginAsData,
    currentFolderState: state.currentFolderState,
    organizations: state.organizations
  };
};

const mapDispatchToProps = {
  setIsDarkTheme,
  setUserInfos,
  setProjectShortcut,
  setHomeInfos
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(MobileNavbar));