import React, { Component } from 'react';
// Semantic UI
import { Button, Icon, Input } from 'semantic-ui-react';

class InputWithAction extends Component {
    render() {
        const { active, disabled, id, icon, color, position, onClick } = this.props;

        return (
            <>
                <Input fluid action className={`action-input ${active ? 'active' : ''} ${position === 'left' ? 'left' : ''}`}>
                    {active && position === 'left' &&
                        <Button
                            type='button' color={color || 'red'} id={id || ''} disabled={disabled || false}
                            style={{ marginLeft: '-3px', zIndex: 1, padding: '5px 10px' }} onClick={onClick}
                        >
                            <Icon name={icon || 'eraser'} style={{ margin: 0 }} />
                        </Button>}
                    {this.props.children}
                    {active && position !== 'left' &&
                        <Button
                            type='button' color={color || 'red'} id={id || ''} disabled={disabled || false}
                            style={{ marginLeft: '-3px', zIndex: 1, padding: '5px 10px' }} onClick={onClick}
                        >
                            <Icon name={icon || 'eraser'} style={{ margin: 0 }} />
                        </Button>}
                </Input>
            </>
        );
    }
}

export default InputWithAction;