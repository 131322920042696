import React, { Component } from 'react';
// Composants
import { Button, Form, Input } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { setLayer } from '../../../../actionCreators/elementsActions';
import { isMobileOnly } from 'react-device-detect';
import { faTimes, faCheck } from '@fortawesome/pro-solid-svg-icons';

const initialError = {
    hidden: true,
    messages: [],
    radius: false,
    steps: false
}

class CircleForm extends Component {
    state = {
        isInside: true,
        center: null,
        radius: 0,
        steps: 20,
        error: initialError
    }

    render() {
        const { layer, isToolbarExpanded, radius } = this.props;
        const { isInside, steps, error } = this.state;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form error style={{ width: '100%' }}>
                    <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px' }}>
                        <Form.Field
                            control={Input} fluid type='number' label={`${i18n.t("Rayon")}* (m) :`} step='1' error={error.radius || !isInside}
                            name='radius' value={radius >= 0 ? radius : ''}
                            onChange={layer ? this.handleChange : null}
                        />
                        <Form.Field
                            control={Input} fluid type='number' label={`${i18n.t("Étapes")}* :`} step='1' error={error.steps}
                            name='steps' value={steps >= 0 ? steps : ''}
                            onChange={this.handleChange} disabled={!layer ? true : false}
                        />
                    </div>
                    <div>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                            <Button
                                color='red' type='button' title={i18n.t("Annuler")} size='small'
                                style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                onClick={() => this.props.hideForm(false)}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button
                                id='ED26aPoB' type='button' color='green' title={i18n.t("Valider")} size='small'
                                style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                disabled={!isInside || !layer} onClick={this.handleSubmit}
                            >
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }

    componentDidUpdate = () => {
        if (this.state.center === null && this.props.layer !== null) {
            const center = this.props.layer.getLatLng();
            const radius = Number(this.props.layer.getRadius().toFixed(0));
            const steps = this.state.steps;
            const isInside = this.props.setCircleDimensions(center, radius, steps);
            this.props.handleRadiusChange(radius);
            this.setState({ isInside, center });
        }
    }

    handleSubmit = () => {
        const { layer, currentElementTools, radius } = this.props;
        const { steps } = this.state;
        let isValid = true;
        let error = { ...initialError.error, messages: [] };

        const addError = (property) => {
            isValid = false;
            error[property] = true;
            error.hidden = false;
        }

        if (radius <= 0) {
            addError('radius');
            error.messages.push(i18n.t("Le rayon ne peut être inférieur ou égale à 0"));
        }

        if (steps < 10) {
            addError('steps');
            error.messages.push(i18n.t("Le nombre d'étapes ne peut être inférieur à 10"));
        } else if (steps > 50) {
            addError('steps');
            error.messages.push(i18n.t("Le nombre d'étapes ne peut être supérieur à 50"));
        }

        if (isValid) {
            this.props.setLayer(layer);
            this.props.changeModalContentType(currentElementTools === 'stationTools' ? 'StationForm' : 'GreenSpaceForm', i18n.t("Ajout d'un nouvel élément"));
        } else this.setState({ error: error });
    }

    handleChange = (e, { name, value }) => {
        let { radius } = this.props;
        let { center, steps } = this.state;
        let error = false, isInside = false;

        if (name === 'radius') {
            this.props.handleRadiusChange(value);
            radius = value;
        } else if (name === 'steps') steps = value;

        if (radius > 0 && steps >= 10 && steps <= 50) isInside = this.props.setCircleDimensions(center, radius, steps);
        else error = true;

        this.setState(prevState => ({ isInside, [name]: value, error: { ...prevState.error, [name]: error } }));
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        isToolbarExpanded: state.isToolbarExpanded
    };
};

const mapDispatchToProps = {
    setLayer
}

export default connect(mapStateToProps, mapDispatchToProps)(CircleForm);