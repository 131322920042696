import React, { Component } from 'react';
// Composants
import { Button, Checkbox } from 'semantic-ui-react';
import { faCheck, faCheckCircle, faCrown, faTimes, faUser, faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';

class UserProjectList extends Component {
    state = {
        selectedUserProjects: []
    };

    render() {
        const { isDarkTheme, projectCollaborators } = this.props;
        const { selectedUserProjects } = this.state;
        const areAllSelected = selectedUserProjects.length === projectCollaborators.length;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: isDarkTheme ? 'var(--black-100)' : 'var(--white-100)', borderRadius: '5px', padding: '10px', textAlign: 'left' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <h3 style={{ flex: 1, margin: 0 }}>{i18n.t("Filtrer les collaborateurs ({{nb}}/{{nbMax}})", { nb: selectedUserProjects.length, nbMax: projectCollaborators.length })}</h3>
                    <Checkbox
                        checked={areAllSelected} onChange={() => this.setState({ selectedUserProjects: areAllSelected ? [] : projectCollaborators.map(collaborator => collaborator.userId) })}
                        label={i18n.t("Tout sélectionner")} style={{ marginLeft: 'auto' }}
                    />
                </div>
                <div style={{ height: '500px', maxHeight: !isMobileOnly && '500px', flex: isMobileOnly && 1, overflowY: 'auto', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '10px' }}>{this.renderUserProjects()}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: isMobileOnly ? 'auto' : '20px' }}>
                    <Button color='grey' onClick={this.props.cancel}>
                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                    </Button>
                    <Button color='blue' onClick={() => this.props.submit(selectedUserProjects)}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />
                        {i18n.t("Appliquer")}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.setState({ selectedUserProjects: this.props.selectedUserProjects });
    }

    renderUserProjects = () => {
        const projectCollaborators = [...this.props.projectCollaborators] || [];
        projectCollaborators.sort((a, b) => a.user.lastName < b.user.lastName ? -1 : a.user.lastName > b.user.lastName ? 1 : 0);
        projectCollaborators.sort((up) => up.projectRole.type !== 'manager' ? 1 : -1);
        projectCollaborators.sort((up) => up.projectRole.type !== 'owner' ? 1 : -1);

        return projectCollaborators.map((userProject, index) => {
            const { isOwner, isManager, user } = userProject;
            const { lastName, firstName } = user;
            const name = FormattersUtil.formatLastNameAndFirstName(lastName, firstName);
            const isSelected = this.state.selectedUserProjects.includes(userProject.userId);
            const description = this.props.userProjectDescriptions ? this.props.userProjectDescriptions[userProject.userId] : null;

            return (
                <Button key={index} size='small' title={user.email} style={{
                    display: 'flex', alignItems: 'center', width: isMobileOnly ? '100%' : 'calc(50% - 10px)', margin: 0,
                    backgroundColor: isSelected ? 'var(--primary-100)' : 'var(--black-40)', color: 'var(--white-100)'
                }} onClick={() => this.toggleUserProject(userProject.userId)}
                >
                    <div style={{ flex: 1, textAlign: 'left' }}>
                        <FontAwesomeIcon icon={isOwner ? faCrown : isManager ? faUserShield : faUser} style={{ marginRight: '7px' }} />{name}{description !== null && ` (${description})`}
                    </div>
                    <FontAwesomeIcon icon={isSelected ? faCheckCircle : faCircle} />
                </Button>
            );
        });
    }

    toggleUserProject = (userId) => {
        let selectedUserProjects = [...this.state.selectedUserProjects];
        const index = selectedUserProjects.indexOf(userId);
        if (index === -1) selectedUserProjects.push(userId);
        else selectedUserProjects.splice(index, 1);
        this.setState({ selectedUserProjects });
    }
}

const mapStateToProps = (state) => {
    return {
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(UserProjectList);