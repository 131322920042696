import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import i18n from '../../locales/i18n';

class TimelineShortcuts extends Component {
    render() {
        const { currentYear } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                <Button
                    title={i18n.t("Passer à l'année précédente")} onClick={() => this.props.setCurrentYear(currentYear - 1)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', padding: 0, margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px', backgroundColor: 'var(--grey-40)', color: 'var(--black-100)', fontWeight: 'normal' }}>
                    {currentYear}
                </div>
                <Button
                    title={i18n.t("Passer à l'année suivante")} onClick={() => this.props.setCurrentYear(currentYear + 1)}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', padding: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            </div>
        );
    }
}

export default TimelineShortcuts;