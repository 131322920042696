import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input } from 'semantic-ui-react';
// Libraries
import { distance, point } from '@turf/turf';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { setLayer } from '../../../../actionCreators/elementsActions';
import { faTimes, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
// Services
import GeometriesUtil from '../../../../utils/GeometriesUtil';

const initialError = {
    hidden: true,
    messages: [],
    length: false,
    width: false,
    angle: false
}

class RectangleForm extends Component {
    state = {
        isInside: true,
        dimensions: {
            length: 0,
            width: 0,
            angle: 0
        },
        error: initialError
    }

    render() {
        const { layer, isToolbarExpanded } = this.props;
        const { isInside, dimensions, error } = this.state;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form error style={{ width: '100%' }}>
                    <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px' }}>
                        <Form.Field
                            control={Input} fluid type='number' label={`${i18n.t("Angle")}* :`} step='1' error={error.angle || !isInside}
                            name='angle' value={dimensions.angle}
                            onChange={this.handleChange} disabled={!layer ? true : false}
                        />
                        <Form.Field
                            control={Input} fluid type='number' label={`${i18n.t("Longueur")}* (m) :`} step='1' error={error.length || !isInside}
                            name='length' value={dimensions.length >= 0 ? dimensions.length : ''}
                            onChange={layer && this.handleChange}
                        />
                        <Form.Field
                            control={Input} fluid type='number' label={`${i18n.t("Largeur")}* (m) :`} step='1' error={error.width || !isInside}
                            name='width' value={dimensions.width >= 0 ? dimensions.width : ''}
                            onChange={layer && this.handleChange}
                        />
                    </div>
                    <div>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                            <Button
                                color='red' type='button' title={i18n.t("Annuler")} size='small'
                                style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                onClick={() => this.props.hideForm(false)}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button
                                id='ED26aPoB' type='button' color='green' title={i18n.t("Valider")} size='small'
                                style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                disabled={!isInside || !layer} onClick={this.handleSubmit}
                            >
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }

    componentDidMount = () => {
        this.originalLatLngs = this.props.originalLatLngs;
        this.caculateDimensions();
    }

    componentDidUpdate = (prevProps) => {
        const { width, length, angle } = this.state.dimensions;
        if (!width && !length) this.caculateDimensions();
        if (!this.originalLatLngs) this.originalLatLngs = this.props.originalLatLngs;
        if (!isNaN(angle) && angle !== '' && this.props.angle && angle.toFixed(0) !== this.props.angle.toFixed(0))
            this.setState(prevState => ({
                dimensions: {
                    ...prevState.dimensions,
                    angle: Number(this.props.angle.toFixed(0))
                }
            }));

        if (prevProps.rectangleDimensions !== this.props.rectangleDimensions)
            this.setState(prevState => ({
                dimensions: {
                    ...prevState.dimensions,
                    width: this.props.rectangleDimensions.width,
                    length: this.props.rectangleDimensions.length
                }
            }));
    }

    caculateDimensions = () => {
        const { layer } = this.props;
        if (layer) {
            const latLngs = GeometriesUtil.convertPolygonLatLngsToCoordinates(layer.getLatLngs());
            const from = latLngs[0][0];
            const to1 = point(latLngs[0][1]);
            const to2 = point(latLngs[0][3]);

            this.setState(prevState => ({
                isInside: !this.props.checkIfInsideSurroundings || this.props.checkIfInsideSurroundings('rectangle', layer),
                dimensions: {
                    ...prevState.dimensions,
                    length: Number((distance(from, to2, { units: 'kilometers' }) * 1000).toFixed(2)),
                    width: Number((distance(from, to1, { units: 'kilometers' }) * 1000).toFixed(2)),
                }
            }));
        }
    }

    handleSubmit = () => {
        const { layer, currentElementTools } = this.props;
        let isValid = true;
        let error = { ...initialError.error, messages: [] };
        const { length, width } = this.state.dimensions;

        const addError = (property) => {
            isValid = false;
            error[property] = true;
            error.hidden = false;
        }

        if (length <= 0) {
            addError('length');
            error.messages.push(i18n.t("La longueur ne peut être inférieure ou égale à 0"));
        }
        if (width <= 0) {
            addError('width');
            error.messages.push(i18n.t("La largeur ne peut être inférieure ou égale à 0"));
        }

        if (isValid) {
            this.props.setAngle(0);
            layer.pm.disableRotate();
            this.props.setLayer(layer);
            this.props.changeModalContentType(currentElementTools === 'stationTools' ? 'StationForm' : 'GreenSpaceForm', i18n.t("Ajout d'un nouvel élément"));
        } else this.setState({ error: error });
    }

    handleCancel = () => {
        this.props.setAngle(0);
        this.props.hideForm(false)
    }

    handleChange = (e, { name, value }) => {
        let { length, width, angle } = this.state.dimensions;
        let isInside = false;

        if (value !== '' && !isNaN(value)) value = Number(value);
        if (name === 'length') length = value >= 0 ? value : 0;
        else if (name === 'width') width = value >= 0 ? value : 0;
        else if (name === 'angle') angle = value >= 0 && value <= 360 ? value : value > 360 ? 0 : 360;

        this.props.layer.setLatLngs(this.originalLatLngs);
        if (name === 'angle') this.props.setAngle(angle);
        if (length > 0 && width > 0) isInside = this.props.setRectangleDimensions(length, width, angle);

        this.setState({ isInside, dimensions: { ...this.state.dimensions, [name]: value } });
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        isToolbarExpanded: state.isToolbarExpanded
    };
};

const mapDispatchToProps = {
    setLayer
}

export default connect(mapStateToProps, mapDispatchToProps)(RectangleForm);