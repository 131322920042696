import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Utils
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class CustomChartsService {
    static addCustomChart(customChart) {
        customChart.userId = jwt_decode(new Cookies().get('token')).id; // Pour le add dans App.js
        return Axios.post(BACKEND_ENDPOINT + 'customCharts/', customChart, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'customCharts/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + customChart.projectId, (body) => {
                body.push(response.data);
                return body;
            }).then(() => {
                showToast('custom_chart_added');
                return response.data;
            });
        }, () => {
            showToast('custom_chart_addition_failed');
            return;
        });
    }

    static updateCustomChart(customChart) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'customCharts/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + customChart.projectIdBACKEND_ENDPOINT + 'customCharts/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + customChart.projectId, (body) => {
            const index = body.findIndex(el => el.id === customChart.id);
            body[index] = customChart;
            return body;
        });

        return Axios.put(BACKEND_ENDPOINT + 'customCharts/', customChart, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_chart_updated');
            return response.data;
        }, () => {
            showToast('custom_chart_update_failed');
            return;
        });
    }

    static removeCustomChart(customChart) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'customCharts/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + customChart.projectId, (body) => {
            return body.filter(cc => cc.id !== customChart.id);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'customCharts/' + customChart.id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_chart_deleted');
            return response.data;
        }, () => {
            showToast('custom_chart_deletion_failed');
            return;
        });
    }

    static getCustomCharts(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'customCharts/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            return;
        });
    }

    static shareCustomChart(customChartId, userIds) {
        return Axios.post(BACKEND_ENDPOINT + 'customCharts/share/' + customChartId, userIds, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_chart_shared');
            return response.data;
        }, () => {
            showToast('custom_chart_sharing_failed');
            return;
        });
    }
}