import React, { Component } from 'react';

export default class NumberFilter extends Component {
  render() {
    const p = this.props.p;

    return (
      <div className='rdg-filter-container'>
        <input
          type='number' step={this.props.step}
          className='rdg-filter' style={{ width: '100%' }}
          value={p.value} onChange={(e) => p.onChange(e.target.value)}
        />
      </div>
    );
  }
}
