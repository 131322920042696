export const setEditedProperties = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_EDITED_PROPERTIES', data: properties });
    return Promise.resolve();
};

export const unlockEditedProperties = () => async (dispatch, getState) => {
    dispatch({ type: 'UNLOCK_EDITED_PROPERTIES' });
    return Promise.resolve();
};

export const setTableState = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_TABLE_STATE', data: properties });
    return Promise.resolve();
};

export const setFilterFormState = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_FILTER_FORM_STATE', data: properties });
    return Promise.resolve();
};

export const setFilterLists = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_FILTER_LISTS', data: properties });
    return Promise.resolve();
};

export const setCustomCharts = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_CUSTOM_CHARTS', data: properties });
    return Promise.resolve();
};

export const setProjectListState = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT_LIST_STATE', data: properties });
    return Promise.resolve();
};

export const setCurrentFolderState = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_CURRENT_FOLDER_STATE', data: properties });
    return Promise.resolve();
};

export const setProjectShortcut = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PROJECT_SHORTCUT', data: properties });
    return Promise.resolve();
};

export const setExitFormWithChanges = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_EXIT_FORM_WITH_CHANGES', data: properties });
    return Promise.resolve();
};