// Librairies
import i18n from "../locales/i18n";
import { v4 as uuidv4 } from 'uuid';

export default class FormulasUtil {
    static getOperationSuggestions = () => [
        { display: 'PI', value: 'pi', addWhitespace: true },
        { display: i18n.t("Racine carrée"), value: 'sqrt()' },
        { display: i18n.t("Puissance"), value: '^' },
        { display: i18n.t("Puissance") + ' 10', value: '^10', addWhitespace: true },
        { display: i18n.t("Sinus"), value: 'sin()' },
        { display: i18n.t("Cosinus"), value: 'cos()' },
        { display: i18n.t("Arc sinus"), value: 'asin()' },
        { display: i18n.t("Arc cosinus"), value: 'acos()' },
        { display: i18n.t("Tangente"), value: 'tan()' },
        { display: i18n.t("Cotangente"), value: 'cot()' },
        { display: i18n.t("Arc tangente"), value: 'atan()' },
        { display: i18n.t("Arc cotangente"), value: 'acot()' },
        { display: i18n.t("Logarithme naturel"), value: 'loge()' },
        { display: i18n.t("Logarithme décimal"), value: 'log10()' },
        { display: i18n.t("Logarithme"), value: 'logn()' },
        { display: i18n.t("Condition"), value: 'if(condition;si respectée;si pas respectée)' },
        { display: i18n.t("Maximum"), value: 'max(valeur1;valeur2;...)' },
        { display: i18n.t("Minimum"), value: 'min(valeur1;valeur2;...)' },
        { display: i18n.t("Moyenne"), value: 'average(valeur1;valeur2;...)' },
        { display: i18n.t("Médianne"), value: 'median(valeur1;valeur2;...)' },
        { display: i18n.t("Arrondi"), value: 'round()' },
        { display: i18n.t("Nombre aléatoire"), value: 'random()', addWhitespace: true }
    ].map(suggestion => ({ ...suggestion, id: uuidv4() }));

    static getFieldSuggestions = () => [
        {
            category: 'Arbre',
            suggestions: [
                { display: i18n.t("Coefficient de type"), value: 'TypeCoefficient' },
                { display: i18n.t("Coefficient de silhouette"), value: 'SilhouetteCoefficient' },
                { display: i18n.t("Coefficient d'espèce"), value: 'SpeciesCoefficient' },
                { display: i18n.t("Coefficient de rafraîchissement"), value: 'CoolingCoefficient' },
                { display: i18n.t("Production de fruits"), value: 'FruitProduction' },
                { display: i18n.t("Densité"), value: 'Density' },
                { display: i18n.t("Cote sanitaire"), value: 'HealthReview' },
                { display: i18n.t("Index de santé"), value: 'HealthIndex' },
                { display: i18n.t("Stade ontogénique"), value: 'OntogenicStage' },
                { display: i18n.t("Coefficient de plantation"), value: 'PlantationCoefficient' },
                { display: i18n.t("Coefficient de situation"), value: 'SituationCoefficient' },
                { display: i18n.t("Coefficient patrimonial"), value: 'PatrimonialCoefficient' },
                { display: i18n.t("Index de taille"), value: 'HeightIndex' },
                { display: i18n.t("Prix moyen de l'ordre"), value: 'AveragePrice' },
                { display: i18n.t("Circonférence"), value: 'Circumference' },
                { display: i18n.t("Hauteur"), value: 'Height' },
                { display: i18n.t("Diamètre de la couronne"), value: 'CrownDiameter' },
                { display: i18n.t("Hauteur du tronc"), value: 'TrunkHeight' }
            ]
        },
        {
            category: 'Espace vert',
            suggestions: [
                { display: i18n.t("Stock carbone par m²"), value: 'CarbonStockPerSquareMeter' },
                { display: i18n.t("Coefficient de ruissellement"), value: 'RunoffCoefficient' },
                { display: i18n.t("Surface"), value: 'Surface' }
            ]
        }
    ].flatMap(suggestionGroup => suggestionGroup.suggestions.map(suggestion => ({ ...suggestion, id: uuidv4(), category: suggestionGroup.category })));

    static getTreeTotalCarbonStock(carbonStock = 0, circumference = 0) {
        const totalCarbonStock = carbonStock * (circumference / 3);
        return totalCarbonStock;
    }

    static getCoolingEconomicValue(coolingEnergyIndicator = 0) {
        return (coolingEnergyIndicator / 2.5) * 0.28;
    }

    static getOxygenProduction(carbonStock = 0) {
        return carbonStock * 0.72727;
    }

    static getFormulaTooltip(formulaId) {
        return formulaId === 1 ? i18n.t("Quantité de carbone stockée par l'arbre")
            : formulaId === 2 ? i18n.t("Quantité de carbone stockée par les végétaux")
                : formulaId === 3 ? i18n.t("Évaluation du potentiel rafraîchissement assuré par l'arbre")
                    : formulaId === 4 ? i18n.t("Calcul de la valeur d'agrément selon formule applicable dans la zone géographique choisie")
                        : formulaId === 5 ? i18n.t("Quantité de chaleur absorbée par l'arbre par jour, compte tenu de l'évapo-transpiration, dans des conditions estivales standards")
                            : i18n.t("Consommation électrique annuelle pour produire ce rafraîchissement s'il fallait le produire articiellement (climatisation)");
    }
}
