import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
import { setLayer } from '../../../../actionCreators/elementsActions';
// Semantic UI
import { Checkbox, Form, Input } from 'semantic-ui-react';
// Services
import GeometriesUtil from '../../../../utils/GeometriesUtil';
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import i18n from '../../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import InfoIcon from '../../../Utils/InfoIcon';

class OffsetForm extends Component {
    state = {
        buffer: 0,
        isLocked: false,
        isInside: true
    }

    render() {
        const { isToolbarExpanded, isBuffer } = this.props;
        const { buffer, isLocked, isInside } = this.state;

        return (
            !isLocked &&
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form error style={{ width: '100%' }}>
                    <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px' }}>
                        <Form.Field
                            control={Input} fluid type='number' step='1'
                            label={<label>{i18n.t("Agrandir/rétrécir")} (m) <InfoIcon content={i18n.t("Une valeur négative permet de rétrécir le polygone")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='buffer' value={buffer} onChange={this.handleBufferChange} error={!isInside}
                        />
                        <Form.Field
                            control={Checkbox} label={i18n.t("Zone tampon")}
                            name='isBuffer' checked={isBuffer} onChange={(_, { checked }) => this.props.handleIsBufferChange(checked)}
                        />
                    </div>
                </Form>
            </div>
        );
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.project && this.props.layer && (JSON.stringify(this.basePolygon) !== JSON.stringify(GeometriesUtil.convertPolygonLatLngsToCoordinates(this.props.layer.getLatLngs()))
            || JSON.stringify(prevProps.lockedElements) !== JSON.stringify(this.props.lockedElements))) {
            const isLocked = this.state.isLocked;
            const newIsLocked = ProjectsUtil.isElementLocked(this.props.lockedElements, this.props.layer.feature, this.props.project, this.props.projectCollaborators, []);
            if (newIsLocked !== isLocked) this.setState({ isLocked: newIsLocked });
        }

        if (!this.basePolygon && this.props.layer !== null && this.props.layer.getLatLngs)
            this.basePolygon = GeometriesUtil.convertPolygonLatLngsToCoordinates(this.props.layer.getLatLngs());

        if (!prevProps.isDragFinished && this.props.isDragFinished) {
            this.setState({ buffer: 0 });
            this.basePolygon = GeometriesUtil.convertPolygonLatLngsToCoordinates(this.props.layer.getLatLngs());
            this.pushedToHistory = false;
        }

        if (prevProps.isBuffer !== this.props.isBuffer) this.handleBufferChange(null, { value: this.state.buffer });
    }

    handleBufferChange = (_, { value }) => {
        if (!isNaN(value) && value !== '') {
            const isInside = this.props.setPolygonDimensions(this.basePolygon, Number(value), this.props.layer, !this.pushedToHistory);
            if (!this.pushedToHistory) this.pushedToHistory = true;
            this.props.layer.pm._layerEdited = true;
            this.setState({ isInside });
        }
        this.setState({ buffer: value });
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        lockedElements: state.lockedElements,
        currentAction: state.currentAction,
        isToolbarExpanded: state.isToolbarExpanded
    };
};

const mapDispatchToProps = {
    setLayer
}

export default connect(mapStateToProps, mapDispatchToProps)(OffsetForm);