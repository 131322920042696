import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import PasswordInput from '../Forms/Users/PasswordInput';
// Librairies
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
// Ressources
import logoMonoWhite from '../../resources/images/logo-mono-white.jpg';
// Services
import UsersService from '../../services/UsersService';
import FormattersUtil from '../../utils/FormattersUtil';

const initialError = {
    hidden: true,
    messages: [],
    password: false
}

class ResetPassword extends Component {
    state = {
        isLoading: false,
        success: false,
        email: '',
        token: null,
        password1: '',
        password2: '',
        error: initialError
    };

    render() {
        const { isLoading, success, password1, password2, error } = this.state;

        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' className='text-light' textAlign='center'>
                        <Image style={{ borderRadius: '30px', marginTop: 0, marginRight: '3px' }} src={logoMonoWhite} /> {i18n.t("Mot de passe oublié")}
                    </Header>
                    <Form size='large' style={{ borderRadius: '5px' }} loading={isLoading} error success>
                        <Segment>
                            <PasswordInput name='password1' value={password1} handleChange={this.handleChange} error={error.password} handleSubmit={this.handleSubmit} />
                            <PasswordInput
                                name='password2' value={password2} placeholder={i18n.t("Confirmation")} handleChange={this.handleChange} error={error.password}
                                handleSubmit={this.handleSubmit} style={{ margin: '10px 0' }}
                            />
                            <Button className='btn-primary' fluid size='large' onClick={this.handleSubmit} style={{ marginTop: '10px' }}>
                                {i18n.t("Valider")}
                            </Button>
                            {!error.hidden &&
                                <Message error hidden={error.hidden} header={i18n.t("Erreur")} list={error.messages} style={{ textAlign: 'left' }} />}
                            {success &&
                                <Message
                                    success hidden={!success} header={i18n.t("Réinitialisation réussie")}
                                    content={i18n.t("Vous allez être redirigé vers la page de connexion.")} style={{ textAlign: 'left' }}
                                />}
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }

    componentDidMount = () => {
        this.props.setProject(null);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get('email');
        const token = urlParams.get('token')?.replace(/ /g, '+');
        const isEmailValid = FormattersUtil.checkEmail(email);

        if (!this.state.email && email && isEmailValid && !this.state.token && token)
            this.setState(prevState => ({
                ...prevState,
                email: email,
                token: token
            }));

        if (email && isEmailValid && token)
            this.props.changeActiveItem('forgotPassword');
        else {
            this.props.changeActiveItem('home');
            this.props.history.push('/'); // On redirige vers le composant map
        }
    }

    componentDidUpdate = () => { // Si l'utilisateur est déjà connecté, on l'empêche d'accéder au composant login
        if (this.props.logged) {
            this.props.changeActiveItem('home');
            this.props.history.push('/'); // On redirige vers le composant map
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            error: initialError
        });
    }

    handleSubmit = (e) => {
        let flag = true;
        let passwordError = false;
        let messages = [];
        const { email, password1, password2, token } = this.state;

        const addPasswordError = (message) => {
            messages = [...(messages || []), message];
            passwordError = true;
            flag = false;
        }

        // Vérifications
        if (!password1)
            addPasswordError(i18n.t("Le mot de passe ne peut être vide"));

        if (password1 !== password2)
            addPasswordError(i18n.t("Les mots de passe ne correspondent pas"));

        if (password1.length < 8)
            addPasswordError(i18n.t("Le mot de passe doit contenir au moins 8 caractères"));

        // Si toutes les vérifications sont bonnes
        if (flag) {
            const resetPassword = {
                email: email,
                password: password1,
                confirmPassword: password2,
                token: token
            };

            this.setState({ isLoading: true });
            UsersService.resetPassword(resetPassword).then(response => {
                if (response) {
                    this.setState(prevState => ({
                        error: {
                            ...prevState.error,
                            hidden: true,
                            messages: []
                        },
                        success: true
                    }));
                    setTimeout(() => this.props.history.push('/login'), 3000);
                } else this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        hidden: false,
                        messages: [i18n.t("Le jeton de réinitialisation n'est pas valide")],
                    },
                    success: false
                }));
                this.setState({ isLoading: false });
            });
        } else this.setState({ // Si les vérifications ne sont pas bonnes, on affiche des messages d'erreurs
            error: {
                hidden: false,
                messages: messages,
                password: passwordError
            }
        });
    };
}

const mapDispatchToProps = {
    setProject
};

export default connect(null, mapDispatchToProps)(ResetPassword);