import React, { Component } from 'react';
// Composants
import { Button, Form, Input, Menu, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*     Filters     */
import TextFilter from '../Tables/Filters/TextFilter';
import Woops from '../Utils/Woops';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
import { setActionHistory } from '../../actionCreators/elementsActions';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
// Services
import ActionsService from '../../services/ActionsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import DatesUtil from '../../utils/DatesUtil';
import ActionsUtil from '../../utils/ActionsUtil';
import { faList, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';

const initialFilters = {
    username: '',
    date: '',
    type: '',
    message: ''
};

const initialState = {
    period: 'month',
    data: {
        columns: [],
        rows: []
    },
    elementsToModify: [],
    modificationsHistory: [],
    modificationsHistoryIndex: 0,
    rowIndex: 0,
    sortColumn: null,
    sortDirection: 'NONE',
    enableFilterRow: false,
    filters: initialFilters,
    isLoading: true,
    loadingFailed: false
};

class ActionHistory extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { data, sortColumn, sortDirection, enableFilterRow, filters, rowIndex, isLoading, loadingFailed } = this.state;
        const rows = this.getFilteredRows();
        const category = this.props.layer?.[0].feature.properties.category;

        return (
            <>
                {loadingFailed
                    ? <Woops />
                    :
                    <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                        {data?.columns &&
                            <>
                                <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                                    <Menu.Item>
                                        <Form.Field
                                            control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                            value={rowIndex || ''}
                                            onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                        />
                                        <Button
                                            className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                            onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                        />
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button.Group>
                                            <Button
                                                title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                                className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                                onClick={this.toggleFilters}
                                            />
                                            <Button
                                                title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                                onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                            />
                                        </Button.Group>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button.Group>
                                            <Button
                                                title={i18n.t("Historique des propriétés")} className='button--secondary' style={{ padding: '11px' }}
                                                onClick={() => this.props.changeModalContentType(category === 'Arbre' ? 'TreeHistory' : category === 'Espace vert' ? 'GreenSpaceHistory' : 'FurnitureHistory', i18n.t("Historique"), true)}
                                            >
                                                <FontAwesomeIcon icon={faList} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Propriétés")}
                                            </Button>
                                            <Button className='button--secondary' style={{ padding: '11px' }} disabled>
                                                <FontAwesomeIcon icon={faUserHelmetSafety} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Actions")}
                                            </Button>
                                        </Button.Group>
                                    </Menu.Item>
                                </Menu>
                                <DataGrid
                                    ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                    style={{ flex: '1 1 auto' }}
                                    columns={data.columns} rows={rows} rowRenderer={this.rowRenderer}
                                    defaultColumnOptions={{ sortable: true, resizable: true }}
                                    cellNavigationMode='LOOP_OVER_ROW'
                                    sortColumn={sortColumn} sortDirection={sortDirection}
                                    onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                    filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                    emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                        <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                    </div>)}
                                    onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                />
                            </>}
                    </Segment >}
            </>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.actionHistory) !== JSON.stringify(prevProps.actionHistory))
            this.setState({ ...initialState, id: this.props.layer[0].feature.id }, this.loadData);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    loadData = () => {
        return new Promise(resolve => {
            let data = {
                columns: [],
                rows: []
            };

            // Définition des colonnes
            data.columns = [
                {
                    name: i18n.t("Nom d'utilisateur"), key: 'username', width: 200, sortable: true,
                    formatter: (props) => props.row.username || '',
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Date"), key: 'date', width: 200, sortable: true,
                    formatter: (props) => props.row.date || '',
                    filterRenderer: (props) => <TextFilter p={props} />
                },
                {
                    name: i18n.t("Action"), key: 'message', width: 600, sortable: true,
                    formatter: (props) => (<>
                        {props.row.type && <FontAwesomeIcon icon={ActionsUtil.getActionHistoryIcon(props.row.type)} style={{ marginRight: '10px' }} />}
                        {props.row.message || ''}
                    </>),
                    filterRenderer: p => <TextFilter p={p} />
                }
            ];

            const createRows = (elements) => {
                if (!elements) elements = [];
                data.rows = elements.map((history) => {
                    const { id, elementId, userId, type, message, date, action, projectActionId, recurrenceId } = history;
                    const userBaseProject = this.props.projectCollaborators?.find(collaborator => collaborator.userId === userId);
                    const projectAction = this.props.projectActions.find(pa => pa.id === projectActionId);
                    const pae = projectAction?.projectActionElements?.find(pae => pae.elementId === elementId);
                    const paer = recurrenceId && pae?.projectActionElementRecurrences?.find(paer => paer.id === recurrenceId);
                    const startDate = paer?.date || (!recurrenceId && projectAction?.startDate) || history?.startDate;
                    let recurrenceInfos;
                    if (!recurrenceId) {
                        const endDate = projectAction?.endDate || history.endDate;
                        const recurrence = projectAction?.recurrence || history.recurrence;
                        recurrenceInfos = endDate && recurrence && ActionsUtil.getRecurrencesInfos({ startDate, endDate, recurrence });
                    }

                    return {
                        id,
                        username: userBaseProject ? FormattersUtil.formatLastNameAndFirstName(userBaseProject.user.lastName, userBaseProject.user.firstName) : '',
                        date: `${DatesUtil.getFormattedLocaleDateString(date)}, ${DatesUtil.getFormattedLocaleTimeString(date)}`,
                        orignalDate: date,
                        userId,
                        message: <>{message} {pae || paer
                            ? <>{'('}<a style={{ cursor: 'pointer' }} onClick={() => this.props.showActionInForm({ paId: projectAction.id, paerId: paer?.id })}>{`${action} - ${recurrenceInfos || DatesUtil.getFormattedLocaleDateString(startDate)}`}</a>{')'}</>
                            : `(${action} - ${recurrenceInfos || DatesUtil.getFormattedLocaleDateString(startDate)})`}
                        </>,
                        type
                    };
                }).reverse();

                const initialOrder = data.rows.map(row => row.id);
                this.setState({ data, elements, initialOrder, isLoading: false });
            };

            const { actionHistory, layer } = this.props;
            if (!actionHistory || actionHistory[0]?.elementId !== layer[0].feature.id) {
                ActionsService.getActionHistory(layer[0].feature.id).then(histories => {
                    if (histories) {
                        this.props.setActionHistory(histories);
                        createRows(histories);
                        this.props.setProject({ ...this.props.project, projectLogs: histories || [] });
                    } else this.setState({ loadingFailed: true });
                    resolve();
                });
            } else {
                createRows(actionHistory);
                resolve();
            }
        });
    }

    rowRenderer = (props) => {
        const { targetId, type } = props.row;
        return (
            <>
                {targetId && ['element_addition', 'element_update'].includes(type) ?
                    <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                        <GridRow {...props} />
                    </ContextMenuTrigger>
                    : < GridRow {...props} />}
            </>
        );
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return (!this.state.enableFilterRow || (
                (filters.username ? $(r.username)?.includes($(filters.username)) : true)
                && (filters.date ? $(r.date).includes($(filters.date)) : true)
                && (filters.type ? $(r.type).includes($(filters.type)) : true)
                && (filters.message ? $(r.message)?.includes($(filters.message)) : true)
            ))
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'date')
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        projectActions: state.projectActions,
        rights: state.rights,
        activeOrganization: state.activeOrganization,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        tableState: state.tableState,
        actionHistory: state.actionHistory
    };
};

const mapDispatchToProps = {
    setProject,
    setActionHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionHistory);