import React, { Component } from 'react';
import FormattersUtil from '../../../utils/FormattersUtil';

export default class SmartTextEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        startValue: null,
        endValue: null
    };

    render() {
        const { row, column, onRowChange } = this.props;
        const propertyOptions = this.props.propertyOptions;

        return (
            <input
                className='rdg-text-editor' ref={this.autoFocusAndSelect} value={row[column.key] || ''}
                onChange={e => this.handleValueChange(e, row, column, onRowChange, propertyOptions)} onBlur={this.handleBlur}
            />
        );
    }

    componentDidMount = () => this.setState({ startValue: this.props.row[this.props.column.key] });

    componentDidUpdate = () => { // Permet de mettre à jour la startValue lorsqu'on modifie le champs lié
        if (this.inputRef.current && document.activeElement !== this.inputRef.current && this.state.startValue !== this.props.row[this.props.column.key])
            this.setState({ startValue: this.props.row[this.props.column.key] });
    }

    componentWillUnmount = () => { if (!this.blurred) this.handleBlur(); } // L'event blur n'est pas déclenché lorsqu'on appuie sur enter/tab

    handleBlur = () => {
        const { startValue, endValue } = this.state;
        const { row, column, onClose, pushToModificationsHistory } = this.props;
        if (startValue !== endValue && endValue) {
            pushToModificationsHistory([
                { elementId: row.elementId, property: column.key, oldValue: startValue, customField: column.customField }
            ]);
            this.setState({ startValue: endValue });
        }
        onClose(true);
        this.blurred = true;
        setTimeout(() => this.blurred = false, 100);
    }

    handleValueChange = (e, row, column, onRowChange, propertyOptions) => {
        let values = e.target.value;
        if (values.substring(values.length - 2, values.length) === ',,') // Si la valeur se termine par deux ','
            values = values.substring(0, values.length - 1); // On en retire un
        // Bloque la possiblité de mettre une ',' si l'interaction saisie n'est pas encore trouvée
        if (values.substring(values.length - 1, values.length) === ',' && values.includes('-') && column.key !== 'tags')
            values = values.substring(0, values.length - 1);
        values = values.split(',');
        // Si l'avant dernier tag possède un '- ', on le retire
        if (column.key === 'tags' && values[values.length - 2]?.substring(0, 2).trim() === '-')
            values[values.length - 2] = values[values.length - 2].substring(2);
        let valuesId = [];
        let newValue = '';
        values.forEach((value, i) => {
            if (value.substring(0, 2).trim() === '-') value = value.substring(2); // On retire le '-' s'il y en a un
            // On cherche la valeur correspondante (interaction/tag existant) de manière flexible
            let linkedValue;
            if (value?.trim())
                linkedValue = propertyOptions.find(x => FormattersUtil.getNormalizedString(x.label) === FormattersUtil.getNormalizedString(value.trim()));
            if (linkedValue) { // Si une valeur correspondante est trouvée
                newValue += newValue === '' ? linkedValue.label : ', ' + linkedValue.label;
                if (!valuesId.includes(linkedValue.id)) valuesId.push(linkedValue.id);
            } else { // Sinon c'est que le tag/interaction n'existe pas
                if (value === '') newValue += newValue === '' ? value : ',' + value;
                else {
                    if (i === values.length - 1) newValue += newValue === '' ? '- ' + value : ', -' + value;
                    else newValue += newValue === '' ? value : ', ' + value.trim();
                }
                // Si c'est un tag, on push quand même la valeur en brut dans le tableau d'id des tags, cette valeur sera convertie en ID plus tard
                if (column.key === 'tags' && !valuesId.includes(value.trim()) && value.trim() !== '') valuesId.push(value.trim());
            }
        });

        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.elementId);
        let feature = index === -1
            ? JSON.parse(JSON.stringify(this.props.elements[row.id].feature))
            : elementsToModify[index];

        if (column.customField) this.props.updateElementCustomFields(feature, column.key, valuesId.join(','));
        else feature.properties[column.key.substring(0, column.key.length - 1) + 'Id'] = valuesId;
        if (index === -1) elementsToModify.push(feature);

        this.setState({ endValue: newValue });
        this.props.changeElementsToModify(elementsToModify);
        const newRow = { ...row, [column.key]: newValue || null };
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow);
    }

    autoFocusAndSelect = (input) => {
        if (input) {
            input.focus();
            input.select();
            setTimeout(() => { // On sélectionne et désélectionne pour permettre le Ctrl C/V
                input.setSelectionRange(input.value.length, input.value.length);
            }, 100);
            this.inputRef.current = input;
        }
    }
}