import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Services
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class FiltersService {
    static addFilterList(filterList) {
        filterList.userId = jwt_decode(new Cookies().get('token')).id; // Pour le add dans App.js
        return Axios.post(BACKEND_ENDPOINT + 'filters/', filterList, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'filters/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + filterList.projectId, (body) => {
                body.push(response.data);
                return body;
            }).then(() => {
                showToast('custom_filter_added');
                return response.data;
            });
        }, () => {
            showToast('custom_filter_addition_failed');
            return;
        });
    }

    static removeFilterList(filterList) {
        OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'filters/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + filterList.projectId, (body) => {
            return body.filter(fl => fl.id !== filterList.id);
        });

        return Axios.delete(BACKEND_ENDPOINT + 'filters/' + filterList.id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_filter_deleted');
            return response.data;
        }, () => {
            showToast('custom_filter_deletion_failed');
            return;
        });
    }

    static getFilterLists(projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'filters/?userId=' + jwt_decode(new Cookies().get('token')).id + '&projectId=' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            return;
        });
    }

    static shareFilterList(filterListId, userIds) {
        return Axios.post(BACKEND_ENDPOINT + 'filters/share/' + filterListId, userIds, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('custom_filter_shared');
            return response.data;
        }, () => {
            showToast('custom_filter_sharing_failed');
            return;
        });
    }
}