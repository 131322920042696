import React, { Component } from 'react';
import { DateTime } from 'luxon';
import { endOfDay, intervalToDuration, parse } from 'date-fns';
import { fr } from 'date-fns/locale';

export default class DateEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        startValue: null,
        endValue: null
    };

    render() {
        const { row, column, onRowChange } = this.props;

        return (
            <input
                className='rdg-text-editor' style={{ textAlign: 'center' }}
                ref={this.autoFocusAndSelect} value={row[column.key] || ''}
                onChange={e => this.handleTextChange(e, row, column, onRowChange)}
                onBlur={this.handleBlur} onKeyDown={this.handleKeyDown}
            />
        );
    }

    componentDidMount = () => {
        this.setState({
            startValue: this.props.column.key === 'plantingDate'
                ? { age: this.props.row.age, plantingDate: this.props.row.plantingDate }
                : this.props.row[this.props.column.key]
        });
        this.autoFocusAndSelect(this.inputRef.current);
    }

    componentDidUpdate = () => { // Permet de mettre à jour la startValue lorsqu'on modifie le champs lié
        const startValue = this.props.column.key === 'plantingDate'
            ? { age: this.props.row.age, plantingDate: this.props.row.plantingDate }
            : this.props.row[this.props.column.key];
        if (this.inputRef.current && document.activeElement !== this.inputRef.current && (
            this.props.column.key === 'plantingDate'
                ? JSON.stringify(this.state.startValue) !== JSON.stringify(startValue)
                : this.state.startValue !== this.props.row[this.props.column.key]
        ))
            this.setState({ startValue });
    }

    componentWillUnmount = () => {
        if (!this.blurred) this.handleBlur();
    } // L'event blur n'est pas déclenché lorsqu'on appuie sur enter/tab

    handleKeyDown = (e) => {
        if (e.keyCode === 13) { // Si on appuye sur la touche Enter
            e.stopPropagation();
            this.handleBlur();
        }
    }

    handleBlur = () => { // L'exécution de onClose dans componentWillUnmount ne fonctionne pas, obligé de se servir du onBlur
        const { row, column, futureDate, onRowChange, onClose } = this.props;
        const { startValue, endValue } = this.state;

        if (endValue != null && (this.props.column.key === 'plantingDate' ? startValue.plantingDate : startValue) !== endValue) { // !== null est important car en cas de string vide, il faut mettre à jour la donnée
            let isDate = true, date;

            if (endValue) {
                const verificationDate = endValue.replaceAll('-', '/');
                date = parse(endValue.replaceAll('-', '/').split('/').join('/'), 'dd/MM/yyyy', new Date(), { locale: fr });
                isDate = DateTime.fromFormat(verificationDate, 'dd/MM/yyyy').isValid
                    && ((column.key !== 'plantingDate' && (new Date(date).getTime() >= new Date().getTime() || !futureDate)) || column.key === 'plantingDate');
            }

            if (isDate) {
                date = endOfDay(date);
                let elementsToModify = this.props.elementsToModify;
                const index = elementsToModify.findIndex(element => element.id === (column.key === 'plantingDate' || column.customField ? row.elementId : row.id));

                let element = index !== -1 ? elementsToModify[index]
                    : column.key === 'plantingDate' || column.customField
                        ? JSON.parse(JSON.stringify(this.props.elements[row.id].feature))
                        : JSON.parse(JSON.stringify(this.props.elements.find(element => element.id === row.id)));

                if (column.key === 'plantingDate') {
                    element.properties[column.key] = date || null;
                    element.properties.age = intervalToDuration({ start: date, end: new Date() }).years;
                } else if (column.customField) {
                    this.props.updateElementCustomFields(element, column.key, date);
                } else element[column.key] = date || null;
                if (index === -1) elementsToModify.push(element);

                this.props.changeElementsToModify(elementsToModify);

                if (column.key === 'plantingDate') {
                    const newRow = { ...row, age: element.properties.age };
                    onRowChange(newRow);
                    this.props.updateSelectedRow(newRow);
                    this.props.pushToModificationsHistory(
                        Object.keys(startValue).map(property => ({
                            elementId: row.elementId, property, oldValue: startValue[property], customField: column.customField
                        }))
                    );
                } else this.props.pushToModificationsHistory([{ property: this.props.column.key, elementId: this.props.row.id, oldValue: startValue }]);

                this.setState({ startValue: endValue });
                onClose(true);
            } else {
                const oldRow = { ...row, [column.key]: column.key === 'plantingDate' ? startValue[column.key] : startValue };
                onRowChange(oldRow);
                this.props.updateSelectedRow(oldRow);
                onClose(false);
            }
        }

        this.blurred = true;
        setTimeout(() => this.blurred = false, 100);
    }

    handleTextChange = (e, row, column, onRowChange) => {
        this.setState({ endValue: e.target.value });
        const newRow = { ...row, [column.key]: e.target.value };
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow);
    }

    autoFocusAndSelect = (input) => {
        if (input) {
            input.focus();
            input.select();
            this.inputRef.current = input;
        }
    }
}