import React, { Component, createRef } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import ImageEditor from '@toast-ui/react-image-editor';
import { Button } from 'semantic-ui-react';
// Librairies
import { withOrientationChange } from 'react-device-detect';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { fabric } from 'fabric';
// Ressources
import lightTheme from '../../resources/tuiLight';
import darkTheme from '../../resources/tuiDark';
// Styles
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '../../styles/image-editor.css';
import html2canvas from 'html2canvas';
import FormattersUtil from '../../utils/FormattersUtil';

class ScreenshotEditor extends Component {
    constructor(props) {
        super(props);
        this.editorRef = createRef();
    }

    state = {
        isDownloading: false
    };

    render() {
        const { isDarkTheme, imageToEdit } = this.props;
        const { isDownloading } = this.state;

        return (
            <div className='modal-content'>
                <div id='image-editor'>
                    <Button
                        className='form-button' type='button' color='blue' disabled={isDownloading} loading={isDownloading}
                        style={{ margin: '10px', float: 'right' }} onClick={this.finishScreenshotEdit}
                    >
                        <FontAwesomeIcon icon={faSave} style={{ marginRight: '10px' }} />{i18n.t("Terminer")}
                    </Button>
                    <ImageEditor
                        ref={this.editorRef}
                        includeUI={{
                            loadImage: {
                                path: imageToEdit,
                                name: 'carte.jpeg',
                            },
                            theme: isDarkTheme ? darkTheme : lightTheme,
                            menuBarPosition: 'right'
                        }}
                        cssMaxHeight={window.innerHeight - 100}
                        cssMaxWidth={window.innerWidth - 435}
                        selectionStyle={{
                            cornerSize: 20,
                            rotatingPointOffset: 70,
                        }}
                        usageStatistics={false}
                    />
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        const { config } = this.props;

        window.addEventListener('resize', () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.resetImageSize, 300);
        });

        if (config.titles?.left || config.titles?.middle || config.titles?.right)
            this.drawTitles();
        if (config.showLegend) this.drawLegend();
        this.drawScale();
    }

    componentDidUpdate = () => { if (this.editorRef.current) setTimeout(this.resetImageSize, 50); }

    drawTitles = () => {
        const { config } = this.props;
        const { left: leftTitle, middle: middleTitle, right: rightTitle } = this.props.config.titles;

        const canvas = this.editorRef.current.imageEditorInst._graphics.getCanvas();
        const titlesHeight = config.height / config.paperHeight;
        const marginX = config.width / 100;

        canvas.add(new fabric.Rect({ left: 0, top: 0, fill: 'rgba(104, 189, 70, 0.8)', width: config.width, height: titlesHeight }));
        if (leftTitle) canvas.add(new fabric.Text(leftTitle, { left: marginX, top: titlesHeight / 2, originY: 'center', originX: 'left', fill: 'black', fontFamily: 'Arial', fontSize: titlesHeight / 1.5 }));
        if (middleTitle) canvas.add(new fabric.Text(middleTitle, { left: config.width / 2, top: titlesHeight / 2, originY: 'center', originX: 'center', fill: 'black', fontFamily: 'Arial', fontSize: titlesHeight / 1.5 }));
        if (rightTitle) canvas.add(new fabric.Text(rightTitle, { left: config.width - marginX, top: titlesHeight / 2, originY: 'center', originX: 'right', fill: 'black', fontFamily: 'Arial', fontSize: titlesHeight / 1.5 }));
    }

    drawLegend = async () => {
        const { config } = this.props;
        const canvas = this.editorRef.current.imageEditorInst._graphics.getCanvas();

        const legendElement = document.getElementById('legend-container');
        if (legendElement && legendElement.children.length) {
            const legendsToCollapse = [];
            for (let i = 0; i < legendElement.children.length; i++) {
                legendElement.children[i].children[0].classList.remove('arrow');
                legendElement.children[i].children[1].classList.add('no-max-height');
                if (legendElement.children[i].children[1].classList.contains('collapsed')) {
                    legendsToCollapse.push(i);
                    legendElement.children[i].children[1].classList.remove('collapsed');
                }
            }

            const legendScale = (config.width / (config.paperWidth / 5.5)) / legendElement.offsetWidth; // La légende fera 5.5cm de large
            const legend = await html2canvas(legendElement, {
                backgroundColor: null,
                scale: legendScale < 1 ? 1 : legendScale
            });

            for (let i = 0; i < legendElement.children.length; i++) {
                legendElement.children[i].children[0].classList.add('arrow');
                legendElement.children[i].children[1].classList.remove('no-max-height');
                if (legendsToCollapse.includes(i)) legendElement.children[i].children[1].classList.add('collapsed');
            }

            if (legend)
                fabric.Image.fromURL(legend.toDataURL(), (img) => {
                    img.set({ originX: 'right', left: config.width - (config.width / 100), originY: 'bottom', top: config.height - (config.height / 100) });
                    canvas.add(img);
                });
        }
    }

    drawScale = async () => {
        const { config } = this.props;
        const canvas = this.editorRef.current.imageEditorInst._graphics.getCanvas();

        if (!config.scaleDisplay) { // Si on fait un screenshot sans échelle particulière
            const scaleElement = document.getElementsByClassName('leaflet-control-scale')[0];
            const scale = await html2canvas(scaleElement, { backgroundColor: 'rgba(0, 0, 0, 0)', scale: 1 });
            fabric.Image.fromURL(scale.toDataURL(), (img) => {
                img.set({ left: config.width / 100, originY: 'bottom', top: config.height - (config.height / 100) });
                canvas.add(img);
            });
        } else {
            const scaleHeight = (config.height / (config.paperHeight / 0.5));
            const strokeWidth = Math.floor(scaleHeight / 15);

            // Si la distance carte de l'échelle dépasse 2cm, on crée l'échelle graphique sur 2cm
            let mapDistanceScale = 1;
            if (config.scaleDisplay.values[0] > 2) mapDistanceScale = config.scaleDisplay.values[0] / 2;
            let scaleWidth = (config.width / (config.paperWidth / (config.scaleDisplay.values[0] / mapDistanceScale)));

            if (config.scaleDisplay.graphic) { // Echelle graphique
                let scaleText = FormattersUtil.formatMeter(config.scaleDisplay.values[1] / (100 * mapDistanceScale));
                let textElement = new fabric.Text(scaleText, { left: scaleWidth / 2, top: scaleHeight / 2, originX: 'center', originY: 'center', fill: 'black', fontFamily: 'Arial', fontSize: scaleHeight / 1.5 });

                // Si le texte de l'échelle graphique dépasse du rectangle (plus long que 1cm), alors on la met sur 2cm
                if (textElement.width > (scaleWidth - (strokeWidth * 2))) {
                    mapDistanceScale = config.scaleDisplay.values[0] / 2;
                    scaleWidth = (config.width / (config.paperWidth / (config.scaleDisplay.values[0] / mapDistanceScale)));
                    scaleText = FormattersUtil.formatMeter(config.scaleDisplay.values[1] / (100 * mapDistanceScale));
                    textElement = new fabric.Text(scaleText, { left: scaleWidth / 2, top: scaleHeight / 2, originX: 'center', originY: 'center', fill: 'black', fontFamily: 'Arial', fontSize: scaleHeight / 1.5 });
                }

                const graphicScaleCanvas = new fabric.Canvas(document.createElement('canvas'), { height: scaleHeight, width: scaleWidth });

                // Dessin de l'échelle graphique
                graphicScaleCanvas.add(
                    new fabric.Path(`M${0 + strokeWidth / 2} 0 L${0 + strokeWidth / 2} ${scaleHeight - strokeWidth / 2} L${scaleWidth - strokeWidth / 2} ${scaleHeight - strokeWidth / 2} L${scaleWidth - strokeWidth / 2} 0`, {
                        fill: '', stroke: 'rgba(0, 0, 0, 0.7)', strokeWidth,
                    })
                );
                graphicScaleCanvas.add(new fabric.Rect({ left: strokeWidth, top: 0, width: scaleWidth - (strokeWidth * 2), height: scaleHeight - strokeWidth, fill: 'rgba(255, 255, 255, 0.5)' }));
                graphicScaleCanvas.add(textElement);

                fabric.Image.fromURL(graphicScaleCanvas.toDataURL(), (img) => {
                    img.set({ left: config.width / 100, originY: 'bottom', top: config.height - (config.height / 100) });
                    canvas.add(img);
                    graphicScaleCanvas.remove();
                });
            }

            if (config.scaleDisplay.numeric) { // Echelle numérique
                const scaleText = `${config.scaleDisplay.values[0]}/${config.scaleDisplay.values[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
                canvas.add(
                    new fabric.Text(scaleText, {
                        left: config.width / 100 + (config.scaleDisplay.graphic ? scaleWidth + 10 : 0), originY: 'center', top: config.height - (config.height / 100 + scaleHeight / 2),
                        fill: 'black', fontFamily: 'Arial', fontSize: scaleHeight / 1.5, borderColor: 'white'
                    })
                );
            }
        }
    }

    finishScreenshotEdit = () => {
        this.setState({ isDownloading: true }, () => {
            this.props.downloadScreenshot(this.editorRef.current.imageEditorInst.toDataURL());
        });
    }

    resetImageSize = () => {
        if (this.editorRef.current)
            this.editorRef.current.imageEditorInst.resizeCanvasDimension({
                height: window.innerHeight - 150,
                width: window.innerWidth - 435
            });
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        rights: state.rights,
        projectCollaborators: state.projectCollaborators,
        request: state.request,
        photosGalleries: state.photosGalleries,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        webSocketHubs: state.webSocketHubs
    };
};

export default withOrientationChange(connect(mapStateToProps)(ScreenshotEditor));