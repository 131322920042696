import React, { Component } from 'react';
// Composants
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import DataGrid, { Row as GridRow } from 'react-data-grid';
/*     Filters     */
import BooleanFilter from '../../Tables/Filters/BooleanFilter';
import TextFilter from '../../Tables/Filters/TextFilter';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Segment, Label, Icon } from 'semantic-ui-react';
// Services
import ProjectsService from '../../../services/ProjectsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import DatesUtil from '../../../utils/DatesUtil';

const initialFilters = {
    sender: '',
    receiver: '',
    baseProject: '',
    isAddedToOrganization: '',
    date: ''
};

class InvitationTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        elementsToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false
    }

    render() {
        const {
            data, elementsToModify, elementsToDelete, modificationsHistory, modificationsHistoryIndex,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isLoading
        } = this.state;
        const rows = this.getFilteredRows();

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <Dimmer active={isUpdating} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Mise à jour des invitations en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des invitations")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                        <Button
                                            title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                            onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1 && elementsToDelete.length < 1}
                                        />
                                    </>
                                </Button.Group>
                            </Menu.Item>
                            {elementsToDelete.length > 0 &&
                                <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                    <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + elementsToDelete.length} />
                                </Menu.Item>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    loadData = () => {
        let data = { columns: [], rows: [] };

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Envoyeur"), key: 'sender', width: 250, sortable: true,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Destinataire"), key: 'email', width: 250, sortable: true,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Projet/dossier"), key: 'baseProject', width: 250, sortable: true,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Ajout à l'organisation"), key: 'isAddedToOrganization', width: 175, sortable: true,
                formatter: (props) => props.row.isAddedToOrganization === i18n.t("Oui")
                    ? <Icon name='check' color='green' />
                    : <Icon name='times' color='red' />,
                filterRenderer: (props) => <BooleanFilter p={props} />
            },
            {
                name: i18n.t("Date"), key: 'date', width: 250, sortable: true,
                formatter: (props) => DatesUtil.getFormattedLocaleDateString(props.row.date),
                filterRenderer: (props) => <TextFilter p={props} />
            }
        ];

        ProjectsService.getInvitations().then(invitations => {
            data.rows = invitations.map(invitation => ({
                ...invitation,
                sender: invitation.sender ? FormattersUtil.formatLastNameAndFirstName(invitation.sender.lastName, invitation.sender.firstName) : '',
                baseProject: invitation.baseProject.label,
                isAddedToOrganization: invitation.organizationId ? i18n.t("Oui") : i18n.t("Non")
            }));
            const elements = invitations.map(invitation => ({ ...invitation }));

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data, elements, initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.label ? $(r.label)?.includes($(filters.label)) : true)
                && (filters.sender ? $(r.sender)?.includes($(filters.sender)) : true)
                && (filters.email ? $(r.email)?.includes($(filters.email)) : true)
                && (filters.isAddedToOrganization ? r.isAddedToOrganization === filters.isAddedToOrganization : true)
                && (filters.date ? DatesUtil.getFormattedLocaleDateString(r.date)?.includes(filters.date) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'date')
                rows = rows.sort((a, b) => {
                    const aDate = new Date(a[sortColumn]), bDate = new Date(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const { modificationsHistory, modificationsHistoryIndex: index } = this.state;
        const previousModification = modificationsHistory[index - 1];

        if (previousModification) {
            const data = { columns: [...this.state.data.columns], rows: [...this.state.data.rows] };
            let { elementsToDelete } = this.state;

            let modificationsToCreate = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;
                modificationsToCreate.push({ property, elementId, oldValue });
                elementsToDelete = elementsToDelete.filter(element => element.id !== oldValue.id);
                data.rows.splice(elementId, 0, oldValue);
            });

            if (index === modificationsHistory.length) modificationsHistory.push(modificationsToCreate);
            else modificationsHistory[index] = modificationsToCreate;

            this.setState(prevState => ({
                data, elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        let { modificationsHistory, modificationsHistoryIndex: index } = this.state;
        const nextModification = modificationsHistory[index + 1];

        if (nextModification) {
            const data = { columns: [...this.state.data.columns], rows: [...this.state.data.rows] };
            let { elementsToDelete } = this.state;

            let modificationsToCreate = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;
                modificationsToCreate.push({ property, elementId, oldValue });
                elementsToDelete.push(oldValue);
                data.rows.splice(elementId, 1);
            });

            modificationsHistory[index] = modificationsToCreate;

            if (index === modificationsHistory.length - 2)
                modificationsHistory = modificationsHistory.slice(0, modificationsHistory.length - 1);

            this.setState(prevState => ({
                data, elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    /*     Suppression     */
    deleteRow = (e, { rowIdx }) => {
        let elementsToDelete = this.state.elementsToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        elementsToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: rowIndex, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, elementsToDelete, selectedRow: null, selectedColumn: null });
    }

    handleSubmit = () => {
        let { elementsToDelete, elements } = this.state;

        if (elementsToDelete.length > 0) {
            this.setState({ isUpdating: true });

            ProjectsService.deleteInvitations(elementsToDelete.map(element => element.id)).then(response => {
                if (response === 200) {
                    elements = elements.filter(element => !elementsToDelete.find(elementToDelete => elementToDelete.id === element.id));
                    this.setState({ elementsToDelete: [] });
                }

                this.setState({ elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
            });
        } else {
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('invitations_updated');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        activeOrganization: state.activeOrganization
    };
};

export default connect(mapStateToProps)(InvitationTable);