import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Services
import { showLoadingToast, showToast } from '../utils//ToastsUtil';
import WebSocketUtil from '../utils/WebSocketUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class BackgroundImagesService {
    static addBackgroundImage(formData, webSocketHubs) {
        const promise = Axios.post(BACKEND_ENDPOINT + 'backgroundImages/' + webSocketHubs?.elementsHub?.connectionId, formData, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(response => (response), () => { }); // Sans la fonction vide c'est le toast d'erreur qui apparaît plutôt que celui de synchro en offline

        showLoadingToast('background_image_adding', 'background_image_added', 'background_image_addition_failed', promise);
        return promise;
    }

    static getBackgroundImages(id) {
        return Axios.get(BACKEND_ENDPOINT + 'backgroundImages/?projectId=' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('background_images_loading_failed');
            return;
        });
    }

    static removeBackgroundImages(backgroundImages, webSocketHubs) {
        const promise = Axios.delete(BACKEND_ENDPOINT + 'backgroundImages/' + webSocketHubs?.elementsHub?.connectionId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: backgroundImages
        }).then(response => {
            return response;
        });

        const prefixe = backgroundImages.length > 1 ? 'background_images' : 'background_image';
        if (navigator.onLine) showLoadingToast(prefixe + '_deleting', prefixe + '_deleted', prefixe + '_deletion_failed', promise);
        else showToast('will_sync');
        return promise;
    }

    static updateBackgroundImage(id, backgroundImage, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'backgroundImages/?connectionId=' + webSocketHubs?.elementsHub?.connectionId + '&id=' + id, backgroundImage, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        });

        if (navigator.onLine) showLoadingToast('background_image_updating', 'background_image_updated', 'background_image_update_failed', promise);
        else showToast('will_sync');
        return promise;
    }

    static bulkUpdateBackgroudImages(backgroundImages, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'backgroundImages/bulk/' + webSocketHubs?.elementsHub?.connectionId, backgroundImages, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        });

        if (navigator.onLine) showLoadingToast('background_images_updating', 'background_images_updated', 'background_images_update_failed', promise);
        else showToast('will_sync');
        return promise;
    }

    static convertRasterToPng(formData, axiosOptions) {
        axiosOptions['headers'] = {
            'Authorization': `Bearer ${new Cookies().get('token')}`,
            'content-type': 'multipart/form-data'
        };
        return Axios.post(BACKEND_ENDPOINT + 'backgroundImages/convertRasterToPng/', formData, axiosOptions)
            .then(response => fetch(`data:image/png;base64,${response.data.fileContents}`).then(res => res.blob()));
    }
}
