import { addDays, addMonths, addYears } from 'date-fns';
import React, { Component } from 'react';
import { Button, Grid, GridColumn, Popup } from 'semantic-ui-react';
import DatesUtil from '../../utils/DatesUtil';
import i18n from '../../locales/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { isMobile, isMobileOnly } from 'react-device-detect';

const WEEK_DAYS = [i18n.t("Lun"), i18n.t("Mar"), i18n.t("Mer"), i18n.t("Jeu"), i18n.t("Ven"), i18n.t("Sam"), i18n.t("Dim")];
const MONTHS = [i18n.t("Jan"), i18n.t("Fév"), i18n.t("Mar"), i18n.t("Avr"), i18n.t("Mai"), i18n.t("Jun"), i18n.t("Jul"), i18n.t("Aoû"), i18n.t("Sep"), i18n.t("Oct"), i18n.t("Nov"), i18n.t("Déc")]

class WorkloadChart extends Component {
    state = {
        yearIndex: 0,
        data: null,
        today: null,
        datesArray: null,
        monthsIndexes: null,
        nbTotalRecurrences: 0
    };

    colors = [
        { color: this.props.isDarkTheme ? 'var(--black-90)' : 'var(--white-90)', isApplied: (nbRecurrences) => nbRecurrences < 0 },
        { color: this.props.isDarkTheme ? 'var(--black-60)' : 'var(--white-60)', legend: '0', isApplied: (nbRecurrences) => nbRecurrences < 1 },
        { color: this.props.isDarkTheme ? 'var(--secondary-100)' : 'var(--primary-60)', legend: i18n.t("1 à 2"), isApplied: (nbRecurrences) => nbRecurrences < 3 },
        { color: this.props.isDarkTheme ? 'var(--secondary-60)' : 'var(--primary-100)', legend: i18n.t("3 à 4"), isApplied: (nbRecurrences) => nbRecurrences < 5 },
        { color: this.props.isDarkTheme ? 'var(--primary-100)' : 'var(--secondary-60)', legend: i18n.t("5 à 9"), isApplied: (nbRecurrences) => nbRecurrences < 10 },
        { color: this.props.isDarkTheme ? 'var(--primary-60)' : 'var(--secondary-100)', legend: i18n.t("10 et +"), isApplied: (nbRecurrences) => nbRecurrences >= 10 }
    ];

    render() {
        const { yearIndex, data, today, datesArray, monthsIndexes, nbTotalRecurrences } = this.state;
        const numberOfWeeks = datesArray && Math.ceil(datesArray.length / 7);

        return (
            <Grid style={{ flexDirection: 'column', minWidth: 'fit-content', padding: '20px', justifyContent: 'left', maxWidth: '100%' }}>
                {datesArray &&
                    <>
                        <Grid.Row style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 0 }}>
                            <Button color='blue' size='tiny' style={{ padding: '7px 10px', borderRadius: '50%' }} onClick={() => { this.setState(prevState => ({ yearIndex: prevState.yearIndex - 1 }), () => this.createChart()) }}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </Button>
                            <span style={{ margin: '0 7px', fontSize: '14pt', fontWeight: 'bolder' }}>{`${datesArray[0].getFullYear()} - ${datesArray.at(-1).getFullYear()}`}</span>
                            <Button color='blue' size='tiny' style={{ padding: '7px 10px', borderRadius: '50%' }} onClick={() => { this.setState(prevState => ({ yearIndex: prevState.yearIndex + 1 }), () => this.createChart()) }}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </Button>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                            <span style={{ fontSize: '12pt', fontWeight: 'bold', marginLeft: '35px' }}>
                                {yearIndex >= 0
                                    ? i18n.t("{{nbRecurrences}} actions à venir", { nbRecurrences: nbTotalRecurrences })
                                    : i18n.t("{{nbRecurrences}} actions passées", { nbRecurrences: nbTotalRecurrences })}
                            </span>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0, paddingBottom: '5px' }}>
                            <Grid.Column style={{ paddingLeft: 0, width: '35px' }}></Grid.Column>
                            {monthsIndexes.map(({ month, index }, i) => (
                                <Grid.Column style={{ padding: 0, textAlign: 'left', width: `${(monthsIndexes[i + 1] ? (Math.floor(monthsIndexes[i + 1].index / 7) - Math.floor(index / 7)) : 1) * 20}px` }}>
                                    <span style={{ marginLeft: '2px' }}>{MONTHS[month]}</span>
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                        {[...Array(7)].map((_, i) => (
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column style={{ textAlign: 'right', padding: '2px 5px 0 0', width: '35px' }}><span>{WEEK_DAYS[i]}</span></Grid.Column>
                                {[...Array(numberOfWeeks)].map((_, j) => {
                                    const formattedDate = DatesUtil.getFormattedLocaleDateString(datesArray[j * 7 + i]);
                                    const nbRecurrences = data[formattedDate] || 0;
                                    const isOutsideDates = datesArray[j * 7 + i] < today || (isMobileOnly ? addMonths(today, 3) : isMobile ? addMonths(today, 6) : addYears(today, 1)) < datesArray[j * 7 + i];
                                    const backgroundColor = this.colors.find(({ isApplied }) => isApplied(isOutsideDates ? - 1 : nbRecurrences))?.color;
                                    return (
                                        <Popup disabled={isOutsideDates} position='top center' style={{ padding: '4px', fontSize: '13pt' }} trigger={
                                            <Grid.Column
                                                style={{ cursor: isOutsideDates ? 'default' : 'pointer', padding: 0, height: '14px', width: '14px', backgroundColor, margin: '3px', borderRadius: '2px' }}
                                                onClick={() => this.props.setPAStartDate(datesArray[j * 7 + i])}
                                            >
                                            </Grid.Column>
                                        }>
                                            {`${DatesUtil.getFormattedLocaleDateString(datesArray[j * 7 + i])} (${nbRecurrences})`}
                                        </Popup>
                                    );
                                })}
                            </Grid.Row>
                        ))}
                        <Grid.Row style={{ alignItems: 'center', justifyContent: 'right', paddingBottom: 0 }}>
                            <GridColumn style={{ padding: 0, margin: '0 4px', width: 'fit-content' }}><span>{i18n.t("Moins")}</span></GridColumn>
                            {this.colors.slice(1).map(({ color, legend }) => (
                                <Popup position='top center' style={{ padding: '4px', fontSize: '13pt' }} trigger={
                                    <Grid.Column style={{ cursor: 'pointer', padding: 0, height: '14px', width: '14px', backgroundColor: color, margin: '3px', borderRadius: '2px' }}>
                                    </Grid.Column>
                                }>
                                    {legend}
                                </Popup>
                            ))}
                            <GridColumn style={{ padding: 0, margin: '0 4px', width: 'fit-content' }}><span>{i18n.t("Plus")}</span></GridColumn>
                        </Grid.Row>
                    </>}
            </Grid>
        );
    }

    componentDidMount = () => {
        const data = {};
        if (this.props.recurrences)
            this.props.recurrences.forEach(recurrence => {
                const date = DatesUtil.getFormattedLocaleDateString(new Date(recurrence.date));
                if (data[date]) data[date]++;
                else data[date] = 1;
            });

        this.setState({ data }, () => this.createChart());
    }

    createChart = () => {
        const { data, yearIndex } = this.state;

        const today = isMobileOnly ? addMonths(new Date(), yearIndex * 3) : isMobile ? addMonths(new Date(), yearIndex * 6) : addYears(new Date(), yearIndex);
        today.setHours(0, 0, 0, 0);
        const startDate = addDays(today, -(today.getDay() - 1));
        let endDate = isMobileOnly ? addMonths(today, 3) : isMobile ? addMonths(today, 6) : addYears(today, 1);
        endDate = addDays(endDate, endDate.getDay() === 0 ? 0 : 7 - endDate.getDay());

        const daysBetween = Math.round(Math.abs((startDate - endDate) / (1000 * 60 * 60 * 24)));
        const datesArray = [...Array(daysBetween + 1)].map((_, i) => addDays(startDate, i));
        const monthsIndexes = datesArray.reduce((prevValue, date, index) => date < today ? prevValue : (!prevValue.length ? [{ month: date.getMonth(), index }] : (prevValue.at(-1).month === date.getMonth() ? prevValue : [...prevValue, { month: date.getMonth(), index }])), [])
        const nbTotalRecurrences = datesArray.reduce((prevValue, date) => prevValue + (data[DatesUtil.getFormattedLocaleDateString(date)] || 0), 0);

        this.setState({ today, datesArray, monthsIndexes, nbTotalRecurrences });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
}

export default connect(mapStateToProps)(WorkloadChart);