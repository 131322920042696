import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Form, Grid, Input, Message, Segment } from 'semantic-ui-react';
import ReactJson from 'react-json-view';
// Librairies
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';
// Ressources
import aiExample from '../../../resources/jpgs/ai-example.jpg';
// Services
import AIService from '../../../services/AIService';

const initialMessage = {
    type: null,
    urlError: false,
    content: []
};

const jsonExample = { "predictions": [{ "probability": 0.8638271, "boundingBox": { "left": 0.3248873, "top": 0.5487443, "height": 0.1228275, "width": 0.1351039 } }] };

class CustomScanForm extends Component {
    state = {
        url: '',
        message: initialMessage,
        isUrlValidated: false
    };

    render() {
        const { url, responseJson, message, isFetching, isUrlValidated } = this.state;

        return (
            <Form className='modal-content' error>
                <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '20px' }}>
                    <Grid style={{ margin: 0, flexDirection: 'column', minHeight: '100%', overflow: 'auto' }}>
                        <Grid.Column stretched computer={16} tablet={16} mobile={16} style={{ paddingBottom: 0 }}>
                            <Form.Field control={Input} action label={i18n.t("URL") + '* : '}>
                                <Input name='url' placeholder={i18n.t("Indiquez une URL")} value={url || ''} error={message.url} onChange={this.handleUrlChange} disabled={isFetching} style={{ borderRight: 'none' }} />
                                <Button title={i18n.t("Tester l'URL")} color={isUrlValidated ? 'green' : 'blue'} style={{ marginLeft: '-3px', zIndex: 1, padding: '5px 10px' }} disabled={isFetching || isUrlValidated} loading={isFetching} onClick={this.testCustomApi}>
                                    {isUrlValidated ? i18n.t("Validé") : i18n.t("Tester")}
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                        <Message
                            hidden={!message.type} error={message.type === 'error'} warning={message.type === 'warning'} success={message.type === 'success'}
                            header={message.type === 'error' ? i18n.t("Erreur") : message.type === 'warning' ? i18n.t("Attention") : i18n.t("Succès")}
                            list={message.content} style={{ textAlign: 'left', overflow: 'auto', margin: '14px', display: message.type ? 'block' : 'none' }}
                            content={message.type !== 'success' &&
                                <a href={'https://gralityblob.blob.core.windows.net/blobs/Exemple image scan IA.jpg'} target='_blank'>
                                    {i18n.t("Visualiser l'image de test")}
                                </a>
                            }
                        />
                        {responseJson &&
                            <div>
                                <label style={{ fontSize: '.92857143em', fontWeight: 'bold' }}>{i18n.t("Réponse")} :</label>
                                <Segment style={{ marginTop: '3px' }}>
                                    <ReactJson name={null} src={responseJson} enableClipboard={false} displayObjectSize={false} />
                                </Segment>
                            </div>}
                        <Divider />
                        <p style={{ marginBottom: 0 }}>
                            {i18n.t("Afin de pouvoir utiliser votre propre modèle IA pour la détection d'arbres dans Grality, il est nécessaire de mettre à notre disposition une API que nous contacterons via le endpoint renseigné ci-dessus.")}<br /><br />
                            {i18n.t("Cet endpoint doit être capable de :")}
                        </p>
                        <ul style={{ margin: '4px 21px' }}>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Recevoir une requête POST contenant une image en format JPEG (Content-Type: image/jpeg)", { interpolation: { escapeValue: false } })}</li>
                            <li style={{ marginBottom: '4px' }}>
                                {i18n.t("Retourner une liste de prédictions avec la structure JSON suivante :")}
                                <Segment style={{ marginTop: '5px' }}>
                                    <ReactJson name={null} src={jsonExample} enableClipboard={false} displayObjectSize={false} />
                                </Segment>
                                <u>{i18n.t("Description des attributs")} :</u>
                                <ul>
                                    <li><b>probability :</b> {i18n.t("Probabilité que la détection soit correcte entre 0 et 1")}</li>
                                    <li><b>boundingBox :</b>
                                        <ul>
                                            <li><b>top :</b> {i18n.t("Écart entre le haut de l'image et la détection")}</li>
                                            <li><b>left :</b> {i18n.t("Écart entre la gauche de l'image et la détection")}</li>
                                            <li><b>width :</b> {i18n.t("Largeur de la détection")}</li>
                                            <li><b>height :</b> {i18n.t("Hauteur de la détection")}</li>
                                        </ul>
                                        {i18n.t("Toutes les valeurs sont exprimées entre 0 et 1 et représentent la proportion par rapport à la taille de l'image.")}<br />
                                        {i18n.t("Exemple : left = 0.42 signifie qu'il y a 42% de la largeur de l'image à gauche de la prédiction, soit 350px pour une image de 1000px de large.")}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <img src={aiExample} style={{ maxWidth: '600px', borderRadius: '5%', marginLeft: '55px', marginBottom: '20px' }} />
                        <p style={{ marginBottom: 0 }}>
                            {i18n.t("Notre API prend en charge les choses suivantes :")}
                        </p>
                        <ul style={{ margin: '4px 21px' }}>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Chargement & envoi des tuiles à votre API")}</li>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Calcul des coordonnées des arbres par rapport aux coordonnées de l'image")}</li>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Calcul du diamètre de couronne des arbres en fonction des dimensions de la prédiction")}</li>
                        </ul>
                        {i18n.t("Lors du scan d'une zone, notre API enverra plusieurs requêtes distinctes à votre API, chacune d'entre elles contenant une image reprenant un maximum de 3x3 tuiles.")}<br />
                        {i18n.t("Le nombre de requêtes peut varier en fonction de la taille de la zone scannée.")}
                    </Grid>
                </Segment>
                <div className='modal-content-footer'>
                    {isMobile ?
                        <Button.Group style={{ width: '100%' }}>
                            <Button
                                type='button' className='form-button' color='red' style={{ width: '50%' }}
                                onClick={this.props.cancel} disabled={isFetching}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' style={{ width: '50%' }} disabled={!isUrlValidated && url} onClick={() => this.props.submit(url)}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Button.Group>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={this.props.cancel} disabled={isFetching}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' disabled={!isUrlValidated && url} onClick={() => this.props.submit(url)}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </>}
                </div>
            </Form >
        );
    }

    componentDidMount = () => {
        if (this.props.scan?.customAPI) this.setState({ url: this.props.scan?.customAPI });
    }

    handleUrlChange = (_, { value }) => {
        this.setState(prevState => ({
            url: value || null,
            responseJson: null,
            message: { ...prevState.message, type: null, urlError: false, content: [] },
            isUrlValidated: false
        }));
    }

    testCustomApi = () => {
        this.setState({ isFetching: true });
        AIService.testCustomApi(this.state.url).then(response => {
            const isError = response?.status !== 200;
            const type = isError ? 'error' : response?.data?.predictions?.[0]?.boundingBox ? 'success' : 'warning';
            this.setState({
                isFetching: false,
                isUrlValidated: !isError,
                responseJson: !isError && response.data,
                message: {
                    type, urlError: isError, content: [
                        type === 'error'
                            ? i18n.t("L'API n'a pas pu être contactée ou n'a retourné aucune réponse valable")
                            : type === 'success'
                                ? i18n.t("L'API a pu être contactée et retourne une réponse valable")
                                : i18n.t("L'API a pu être contactée mais ne retourne aucune prédiction valable")
                    ]
                }
            });
        });
    }
}

const mapStateToProps = (state) => ({
    isDarkTheme: state.isDarkTheme
});

export default connect(mapStateToProps)(CustomScanForm);