import React, { Component } from 'react';
// Composants
import { Button, Form, Header, Message } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';
// Services
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialErrorState = {
    hidden: true,
    messages: [],
    email: false,
    password: false
}

class UrbasenseForm extends Component {
    state = {
        isLoading: false,
        email: '',
        password: '',
        error: initialErrorState
    };

    render() {
        const { isLoading, email, password, error } = this.state;

        return (
            <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img
                    src='https://gralitystdev.blob.core.windows.net/blobs/urbasense.png'
                    alt='urbasense' style={{ width: '120px' }}
                />
                <Header as='h1'>{i18n.t("Connexion")} - Urbasense</Header>
                <Form size='large' error style={{ width: '60%' }}>
                    <Form.Input
                        fluid icon='mail' iconPosition='left' placeholder={i18n.t("Adresse e-mail")}
                        name='email' value={email} error={error.email} onChange={this.handleChange}
                        style={{ border: '1px solid grey', borderRadius: '5px' }}
                    />
                    <Form.Input
                        fluid icon='lock' iconPosition='left' placeholder={i18n.t("Mot de passe")}
                        name='password' value={password} type='password' error={error.password} onChange={this.handleChange}
                        style={{ margin: '10px 0', border: '1px solid grey', borderRadius: '5px' }}
                    />
                    <Message
                        error style={{ textAlign: 'left' }} hidden={error.hidden}
                        header='Erreur' list={error.messages}
                    />
                    <Button className='button--urbasense' fluid size='large' loading={isLoading} disabled={isLoading} style={{ color: 'white' }} onClick={this.handleSubmit}>
                        {i18n.t("Confirmer")}
                    </Button>
                </Form>
            </div>
        );
    }

    handleChange = (_, { name, value }) => {
        this.setState({
            [name]: value,
            error: initialErrorState
        });
    };

    handleSubmit = () => {
        const { email, password } = this.state;
        let isValid = true;
        let messages = [];
        let errors = { ...initialErrorState };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            isValid = false;
        }

        // Vérifications
        if (email.length === 0 || !FormattersUtil.checkEmail(email))
            addError('email', i18n.t("L'adresse email est invalide"));

        if (!password)
            addError('password', i18n.t("Le mot de passe ne peut être vide"));

        if (isValid) {
            this.setState({ isLoading: true });
            if (!this.props.isLinking) {
                UsersService.loginWithUrbasense({ email, password }).then(response => {
                    this.setState({ isLoading: false });
                    if (response?.token) this.props.submit(response.token);
                    else if (response?.loginResponse) this.props.submit(response);
                    else {
                        let messages = [i18n.t("Une erreur est survenue")];
                        if (response?.status === 400) messages = [response.data];
                        this.setState({ error: { hidden: false, messages, email: true, password: true } });
                    }
                });
            } else {
                UsersService.linkUrbasenseAccount({ email, password }).then(response => {
                    this.setState({ isLoading: false });
                    this.props.submit(response);
                });
            }
        } else this.setState({ error: { hidden: false, messages, email: errors.email, password: errors.password } });
    };
}

export default UrbasenseForm;