import React, { Component } from 'react';
// Components
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import InputWithAction from '../../Utils/InputWithAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dimmer, Form, Grid, Input, Message, Popup } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Services
import ProjectsService from '../../../services/ProjectsService';
// Utils
import StylesUtil from '../../../utils/StylesUtil';

class ShareForm extends Component {
    state = {
        url: null,
        clipboard: {
            copied: false,
            popup: false
        }
    }

    render() {
        const { url, clipboard } = this.state;

        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.props.hideForm(); }}
            >
                <Grid style={{ height: '100%' }}>
                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='center'>
                            <Message className='input-popup-form'>
                                <Message.Header>{i18n.t("Partage de la vue")}</Message.Header>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    <Form style={{ width: '100%', marginBottom: '10px', width: '500px', maxWidth: '80vh' }}>
                                        <Popup
                                            on='click' open={clipboard.popup} style={{ zIndex: 9999 }} position='top right'
                                            trigger={
                                                <InputWithAction
                                                    icon={!clipboard.copied ? 'clipboard' : 'circle check'} color='blue' active
                                                    disabled={!url} onClick={() => this.copyToClipboard(url)}
                                                >
                                                    <Input type='text' name='url' value={url || i18n.t("Création de l'URL...")} style={{ width: '100%' }} disabled={!url} loading={!url} />
                                                </InputWithAction>
                                            }
                                            content={i18n.t("Copié dans le presse-papier")}
                                        />
                                    </Form>
                                    <Button color='grey' onClick={() => this.props.hideForm()}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Fermer")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    componentDidMount = () => {
        ProjectsService.shareProject(this.props.project.id, this.props.mapSearch).then(response => {
            if (response) this.setState({ url: `${window.location.origin}${window.location.pathname}?viewId=${response.id}` });
        });
    }

    copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        this.setState({ clipboard: { copied: true, popup: true } });
        setTimeout(() => {
            this.setState({ clipboard: { copied: false, popup: false } });
        }, 2000);
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project
    };
};

export default connect(mapStateToProps)(ShareForm);