import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Dropdown, Form, Grid, Input, Loader, Message, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoleForm from '../Forms/Projects/RoleForm';
import ContextMenu from '../Utils/ContextMenu';
// Librairies
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import i18n from '../../locales/i18n';
import { v4 as uuidv4 } from 'uuid';
import { contextMenu } from 'react-contexify';
import { isMobile, isMobileOnly } from 'react-device-detect';
// Redux
import { setProjects, setProject, setUserProjects, setProjectCollaborators, setRights, setViewProjectAsData } from '../../actionCreators/projectsActions';
import { setEditedProperties, unlockEditedProperties } from '../../actionCreators/componentsActions';
import { setRoleTemplates } from '../../actionCreators/usersActions';
// Ressources
import { faArrowRight, faCheck, faCopy, faEye, faHexagon, faPenToSquare, faPlus, faTimes, faTimesCircle, faTrash, faTrashAlt, faUser } from '@fortawesome/pro-solid-svg-icons';
// Services
import RolesService from '../../services/RolesService';
import StationsService from '../../services/StationsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import GeometriesUtil from '../../utils/GeometriesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import RightsUtil from '../../utils/RightsUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import FormattersUtil from '../../utils/FormattersUtil';

const ACTIONS = { list: 'list', newRole: 'newRole', editRole: 'editRole', editAreas: 'areas' };

const defaultRole = {
    label: i18n.t("Nouveau rôle"),
    icon: 'user',
    color: '#606060',
    userBaseProjects: [],
    filters: 'R',
    tables: 'R',
    statistics: 'R',
    charts: 'R',
    timeline: 'R',
    mapExport: null,
    actions: 'R',
    projectHistory: null,
    thematicMaps: 'R',
    trees: 'R',
    greenSpaces: 'R',
    furnitures: 'R',
    markers: 'R',
    stations: 'R',
    backgroundImages: 'R',
    parameters: null,
    formulas: null,
    requiredFields: null,
    publicFields: null,
    rights: null,
};

class RoleList extends Component {
    state = {
        action: ACTIONS.list,
        projectRoles: [],
        initialProjectRoles: [],
        search: null,
        projectRole: null,
        projectRoleToRemove: null,
        isDeleting: false,
        stations: null,
        activeItem: 'main'
    }

    render() {
        const { projectToEdit, loginAsData } = this.props;
        const { action, projectRoles, stations, initialProjectRoles, search, projectRole, isDeleting, projectRoleToRemove, activeItem } = this.state;

        return (
            <>
                <div className='modal-content'>
                    {action === ACTIONS.list ?
                        <>
                            <div className='modal-content-header' style={{ marginBottom: '25px' }}>
                                <div style={{ display: 'flex' }}>
                                    <Form style={{ flexGrow: 1 }}>
                                        <Form.Field
                                            control={Input} type='text' placeholder={i18n.t("Rechercher...")} name='search' value={search || ''} autoComplete='off'
                                            onChange={(_, { value }) => this.setState({ search: value })} style={{ width: '100%', margin: 0 }}
                                        />
                                    </Form>
                                    {!loginAsData?.readOnly &&
                                        <Button color='green' style={{ margin: '2px 0 2px 5px' }} onClick={this.handleAddRole}>
                                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Nouveau rôle")}
                                        </Button>}
                                </div>
                                <small style={{ marginTop: '5px' }}>{i18n.t("Utilise des rôles pour regrouper les collaborateurs du projet et leur assigner des permissions")}</small>
                            </div>
                            <div className='modal-content-body'>
                                <Dimmer active={projectRoleToRemove && !isDeleting} style={StylesUtil.getMapStyles().dimmerStyle}>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Header>{i18n.t("Êtes-vous certain de vouloir supprimer ce rôle ?")}</Message.Header>
                                        <Message.Content style={{ marginTop: '10px' }}>
                                            <Button color='grey' onClick={() => this.setState({ projectRoleToRemove: null })}>
                                                <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                            </Button>
                                            <Button color='red' onClick={() => this.removeRole(projectRoleToRemove)}>
                                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                            </Button>
                                        </Message.Content>
                                    </Message>
                                </Dimmer>
                                {this.renderRoles()}
                            </div>
                        </>
                        :
                        <RoleForm
                            activeItem={activeItem} projectRoles={projectRoles} stations={stations} initialProjectRoles={initialProjectRoles} projectRole={projectRole} projectToEdit={projectToEdit}
                            changeRole={(projectRole) => this.setState({ projectRole })} updateRole={this.updateRole} handleAddRole={this.handleAddRole} handleAddArea={this.handleAddArea} changeMemberRole={this.changeMemberRole}
                            cancel={() => this.setState({ action: ACTIONS.list, activeItem: 'main', projectRole: null, projectRoles: JSON.parse(JSON.stringify(this.state.initialProjectRoles)) })} reset={this.resetChanges}
                            setInitialProjectRoles={this.setInitialProjectRoles} loadStations={this.loadStations} setActiveItem={activeItem => this.setState({ activeItem })}
                            handleRemoveRole={roleId => this.setState(prevState => ({ projectRoles: prevState.projectRoles.filter(role => role.id !== roleId) }))}
                            canEditProjectRole={this.canEditProjectRole} canViewProjectRole={this.canViewProjectRole} duplicate={this.duplicate}
                        />}
                </div>
                {!projectRole &&
                    <ContextMenu
                        id='context-menu-role-list'
                        items={[
                            {
                                icon: faArrowRight,
                                label: i18n.t("Voir le projet depuis le rôle"),
                                isVisible: () => this.canViewProjectRole(this.contextMenuElement),
                                onClick: () => this.viewProjectAs(this.contextMenuElement)
                            },
                            {
                                icon: faCopy,
                                label: i18n.t("Dupliquer"),
                                isVisible: () => this.canEditProjectRole(this.contextMenuElement),
                                onClick: () => this.duplicate(this.contextMenuElement)
                            },
                            {
                                icon: faTrashAlt,
                                label: i18n.t("Supprimer"),
                                className: 'delete',
                                isVisible: () => !this.contextMenuElement?.type,
                                onClick: () => this.setState({ projectRoleToRemove: this.contextMenuElement.id })
                            }
                        ]}
                    />}
            </>
        );
    }

    componentDidMount = () => {
        this.props.unlockEditedProperties(); // On débloque la sauvegarde des propriétés dans le store Redux
        if (this.props.editedProperties) { // Si un state a été sauvegardé, on le restore (contient les propriétés du formulaire mais aussi les données de projet)
            this.setState({ ...this.state, ...this.props.editedProperties }, () => { // Alors on remet ces propriétés dans le state
                this.props.setEditedProperties(null).then(this.props.unlockEditedProperties);
                if (this.props.layer) { // Si un layer a été dessiné;
                    this.setState(prevState => {
                        const projectRoles = JSON.parse(JSON.stringify(prevState.projectRoles));
                        const index = projectRoles.findIndex(r => r.id === this.state.projectRole.id);
                        const areas = [...(projectRoles[index].areas || []), GeometriesUtil.convertPolygonLatLngsToCoordinates(this.props.layer.getLatLngs())];
                        if (index !== -1) projectRoles[index].areas = areas;
                        return { projectRoles, projectRole: { ...prevState.projectRole, areas } };
                    });
                }
            });
        } else {
            if (!this.props.roleTemplates)
                RolesService.getRoleTemplates().then(templates => {
                    this.props.setRoleTemplates(templates);
                });

            const projectRoles = this.props.projectToEdit.projectRoles;
            projectRoles.forEach(projectRole => {
                if (!projectRole.userBaseProjects)
                    projectRole.userBaseProjects = [];
            });

            this.setState({
                project: this.props.projectToEdit,
                projectRoles: JSON.parse(JSON.stringify(projectRoles)),
                initialProjectRoles: JSON.parse(JSON.stringify(projectRoles))
            }, () => {
                if (this.props.isOnline) ProjectsUtil.loadMissingEntities(this.state.project, this.props.project, this.props.projects, this.props.formulas, this.props.setProject, this.props.setProjects, ['surroundings']);
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectToEdit?.projectRoles && this.props.projectToEdit?.projectRoles && JSON.stringify(prevProps.projectToEdit.projectRoles) !== JSON.stringify(this.props.projectToEdit.projectRoles))
            this.setState({
                projectRoles: JSON.parse(JSON.stringify(this.props.projectToEdit.projectRoles)),
                initialProjectRoles: JSON.parse(JSON.stringify(this.props.projectToEdit.projectRoles))
            });
    }

    componentWillUnmount = () => {
        if (!this.props.editedProperties) { // Pour ne pas retirer les UP quand on modifie la zone géographique
            const { project } = this.state;
            const userProject = this.props.userProjects?.find(up => up.userId === jwt_decode(new Cookies().get('token')).id);
            if (!project.parentFolderId || userProject?.baseProjectId === project.id) this.props.setUserProjects(null);
            else this.props.setUserProjects(this.props.userProjects?.filter(up => up.baseProjectId !== project.id));
        }
    }

    renderRoles = () => {
        const { isDarkTheme, loginAsData, projectToEdit } = this.props;
        const { isDeleting, projectRoleToRemove, projectRoles, search } = this.state;
        const projectRolesToRender = FormattersUtil.sortRoles(projectRoles.filter(pr => !search || (search.length && pr.label.toLowerCase().includes(search.toLowerCase()))));

        return (
            <Grid divided='vertically' inverted={isDarkTheme} className='custom-grid'>
                <Grid.Row className='headers' columns={isMobileOnly ? 3 : isMobile ? 5 : 6}>
                    <Grid.Column>{i18n.t("Rôles ({{nbRoles}})", { nbRoles: projectRoles.length })}</Grid.Column>
                    {!isMobile && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Rôle par défaut")}</Grid.Column>}
                    <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Collaborateurs")}</Grid.Column>
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Stations")}</Grid.Column>}
                    {!isMobileOnly && <Grid.Column style={{ textAlign: 'center' }}>{i18n.t("Zones géographiques")}</Grid.Column>}
                </Grid.Row>
                {projectRolesToRender
                    .filter(pr => !pr.deletionDate && pr.baseProjectId === projectToEdit?.id)
                    .map(projectRole => {
                        const isOwner = projectRole.type === 'owner';
                        const isManager = projectRole.type === 'manager';
                        const canEdit = this.canEditProjectRole(projectRole);
                        const canView = this.canViewProjectRole(projectRole);

                        return (
                            <Grid.Row key={projectRole.id} columns={isMobileOnly ? 3 : isMobile ? 5 : 6} onContextMenu={!isOwner ? (event) => this.handleContextMenu(event, projectRole) : null}>
                                <Dimmer active={isDeleting && projectRoleToRemove === projectRole.id} style={StylesUtil.getMapStyles().dimmerStyle}>
                                    <Loader />
                                </Dimmer>
                                <Grid.Column style={{ justifyContent: 'flex-start', textAlign: 'left', paddingTop: (!canEdit || isOwner) && '4.3px', paddingBottom: (!canEdit || isOwner) && '4.3px' }}>
                                    <FontAwesomeIcon icon={projectRole.icon} style={{ marginLeft: '3px', marginRight: '10px', color: projectRole.color }} />
                                    {projectRole.type ? i18n.t(projectRole.label) : projectRole.label}
                                </Grid.Column>
                                {!isMobile &&
                                    <Grid.Column style={{ textAlign: 'center' }}>
                                        <FontAwesomeIcon icon={projectRole.type ? faCheck : faTimes} color={projectRole.type ? 'green' : 'red'} />
                                    </Grid.Column>}
                                <Grid.Column style={{ textAlign: 'center' }}>
                                    <Popup
                                        trigger={
                                            <span onClick={() => { this.setState({ action: ACTIONS.editRole, projectRole }) }} style={{ cursor: 'pointer' }}>
                                                {projectRole.type === 'owner' ? 1 : projectRole.userBaseProjects?.length || 0}
                                                <FontAwesomeIcon icon={faUser} style={{ marginLeft: '10px' }} />
                                            </span>}
                                        content={i18n.t("Voir les collaborateurs")}
                                    />
                                </Grid.Column>
                                {!isMobileOnly &&
                                    <Grid.Column style={{ textAlign: 'center' }}>
                                        {(isOwner || isManager) ? '/'
                                            : <Popup
                                                trigger={
                                                    <span onClick={() => this.setState({ action: ACTIONS.editAreas, projectRole })} style={{ cursor: 'pointer' }}>
                                                        {projectRole.projectRoleStations?.length || 0}
                                                        <FontAwesomeIcon icon={faHexagon} style={{ marginLeft: '10px' }} />
                                                    </span>}
                                                content={i18n.t("Gérer les stations")}
                                            />}
                                    </Grid.Column>}
                                {!isMobileOnly &&
                                    <Grid.Column style={{ textAlign: 'center' }}>
                                        {(isOwner || isManager) ? '/'
                                            : <Popup
                                                trigger={
                                                    <span onClick={() => this.setState({ action: ACTIONS.editAreas, projectRole })} style={{ cursor: 'pointer' }}>
                                                        {projectRole.areas?.length || 0}
                                                        <FontAwesomeIcon icon={faHexagon} style={{ marginLeft: '10px' }} />
                                                    </span>}
                                                content={i18n.t("Gérer les zones géographiques")}
                                            />}
                                    </Grid.Column>}
                                <Grid.Column style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                                    {canEdit &&
                                        <Button color='yellow' onClick={() => this.setState({ action: ACTIONS.editRole, projectRole })} style={{ padding: '7px' }}>
                                            <FontAwesomeIcon icon={faPenToSquare} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Modifier")}
                                        </Button>}
                                    {(loginAsData?.readOnly || !canEdit) &&
                                        <Button color='blue' onClick={() => this.setState({ action: ACTIONS.editRole, projectRole })} style={{ padding: '7px' }}>
                                            <FontAwesomeIcon icon={faEye} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Consulter")}
                                        </Button>}
                                    {!isOwner && !loginAsData?.readOnly &&
                                        <Dropdown icon='ellipsis vertical' floating button direction='left' className='icon' style={{ padding: '7px' }} onClick={() => contextMenu.hideAll()}>
                                            <Dropdown.Menu>
                                                <Dropdown.Menu scrolling>
                                                    {this.renderOptions(projectRole, canEdit, canView)}
                                                </Dropdown.Menu>
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })}
            </Grid>
        );
    }

    renderOptions = (projectRole, canEdit, canView) => {
        const { isDarkTheme } = this.props;

        return (<>
            {canView &&
                <Dropdown.Item onClick={() => this.viewProjectAs(projectRole)}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ marginRight: '12px' }} />{i18n.t("Voir le projet depuis le rôle")}
                </Dropdown.Item>}
            {canEdit &&
                <Dropdown.Item onClick={() => this.duplicate(projectRole)}>
                    <FontAwesomeIcon icon={faCopy} style={{ marginRight: '12px' }} />{i18n.t("Dupliquer")}
                </Dropdown.Item>}
            {!projectRole.type &&
                <>
                    <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '0' }} />
                    <Dropdown.Item className='delete' onClick={() => this.setState({ projectRoleToRemove: projectRole.id })}>
                        <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: '12px' }} />{i18n.t("Supprimer")}
                    </Dropdown.Item>
                </>}
        </>)
    }

    handleAddRole = () => {
        const projectRole = { ...defaultRole, baseProjectId: this.props.projectToEdit.id, id: uuidv4() };
        this.setState(prevState => ({
            action: ACTIONS.newRole, projectRole,
            projectRoles: [...prevState.projectRoles.filter(role => role.id), projectRole]
        }));
    }

    updateRole = (role) => {
        this.setState(prevState => {
            const index = prevState.projectRoles.findIndex(r => r.id === role.id);
            const projectRoles = JSON.parse(JSON.stringify(prevState.projectRoles));
            if (index !== -1) projectRoles[index] = role;
            else projectRoles.push(role);
            return { projectRoles, projectRole: role };
        });
    }

    removeRole = (roleId) => {
        this.setState({ isDeleting: true });
        RolesService.removeRole(roleId).then(() => {
            const { projects } = this.props;
            let projectRoles = JSON.parse(JSON.stringify(this.state.projectRoles));
            const project = JSON.parse(JSON.stringify(projects.find(project => project.id === this.props.projectToEdit.id)));
            const userBaseProjects = project.userBaseProjects;

            const deletedRole = projectRoles.find(pr => pr.id === roleId);
            if (deletedRole.userBaseProjects?.length) {
                let userBaseProjectsToMove = deletedRole.userBaseProjects;
                deletedRole.userBaseProjects = [];

                const defaultRole = projectRoles.find(pr => pr.type === 'default');
                userBaseProjectsToMove.forEach(ubp => {
                    ubp.projectRoleId = defaultRole.id;
                    ubp.projectRole = ubp.projectRole ? defaultRole : null;
                    let userBaseProject = userBaseProjects.find(ubpInProject => ubpInProject.userId === ubp.userId);
                    if (userBaseProject) {
                        userBaseProject.projectRoleId = defaultRole.id;
                        userBaseProject.projectRole = defaultRole;
                    }
                });
                defaultRole.userBaseProjects = userBaseProjectsToMove;
            }

            projectRoles = projectRoles.filter(pr => pr.id !== roleId);
            project.projectRoles = projectRoles;
            this.props.setUserProjects(userBaseProjects);
            if (this.props.projectShortcut || this.props.project?.id === project.id || this.props.project?.path.includes(project.id.toString()))
                this.props.setProjectCollaborators([...this.props.projectCollaborators.filter(pc => !userBaseProjects.find(ubp => ubp.userId === pc.userId)), ...userBaseProjects]);

            this.setState({
                projectRoles, initialProjectRoles: JSON.parse(JSON.stringify(projectRoles)),
                isDeleting: false, projectRoleToRemove: null
            }, () => {
                const usersToAlert = this.props.userProjects
                    .filter(up => up.user.id !== jwt_decode(new Cookies().get('token')).id)
                    .map(up => up.user.id);
                WebSocketUtil.updateProjectRoles(this.props.webSocketHubs, usersToAlert, projectRoles, this.props.projectToEdit.path);
                ProjectsUtil.updateProjectsInProps(project, projects, this.props.formulas, this.props.project, this.props.setProjects, this.props.setProject);
            });
        });
    }

    changeMemberRole = (oldRoleId, newRoleId, userId) => {
        // Mise à jour de la liste des rôles
        let projectRoles = JSON.parse(JSON.stringify(this.state.projectRoles));
        const oldProjectRole = projectRoles.find(pr => pr.id === oldRoleId);
        let userBaseProject = oldProjectRole.userBaseProjects.find(up => up.userId === userId);
        userBaseProject.projectRoleId = newRoleId;
        oldProjectRole.userBaseProjects = oldProjectRole.userBaseProjects.filter(up => up.userId !== userId);
        const newProjectRole = projectRoles.find(pr => pr.id === newRoleId);
        newProjectRole.userBaseProjects.push(userBaseProject);

        // Mise à jour du rôle sélectionné
        let projectRole = JSON.parse(JSON.stringify(this.state.projectRole));
        projectRole.userBaseProjects = projectRole.id === newRoleId ? newProjectRole.userBaseProjects : oldProjectRole.userBaseProjects;

        this.setState({ projectRoles, projectRole });
    }

    setInitialProjectRoles = (projectRoles) => {
        this.setState({
            projectRoles: JSON.parse(JSON.stringify(projectRoles.filter(pr => pr.id))),
            initialProjectRoles: JSON.parse(JSON.stringify(projectRoles.filter(pr => pr.id)))
        })
    };

    resetChanges = () => {
        const projectRoles = JSON.parse(JSON.stringify(this.state.initialProjectRoles));
        const projectRole = { ...projectRoles.find(pr => pr.type === 'default') };

        this.setState({ projectRoles, projectRole });
    }

    loadStations = () => new Promise((resolve) => StationsService.getStations(this.props.projectToEdit.id).then(stations => this.setState({ stations }, resolve)));
    handleAddArea = () => { // Lorsqu'on clique pour dessiner une zone personnalisée
        this.props.setEditedProperties(this.state).then(() => { // On sauvegarde le state actuel
            this.props.drawCustomArea(); // On appelle la méthode permettant de dessiner le polygone sur la carte
        });
    }

    viewProjectAs = (projectRole) => {
        const { layerContainers, map } = this.props;
        const projectInProps = this.props.project;
        const userId = jwt_decode(new Cookies().get('token')).id;
        const rightsToApply = RightsUtil.getRights(projectRole);
        const isProjectOpen = projectInProps?.id === this.props.projectToEdit.id;
        let project = JSON.parse(JSON.stringify(this.props.project));
        if (!isProjectOpen) {
            project = ProjectsUtil.getBaseProject(this.props.projectToEdit.id, this.props.projects);
            this.props.setProjectCollaborators([...this.props.userProjects]);
            this.props.showProjectMap(project);
        }

        const userProjectRole = this.props.userProjects.find(ubp => ubp.userId === userId).projectRole;
        let viewProjectAsData = {
            rights: RightsUtil.getRights(userProjectRole), rightsToApply,
            setMapSurroundings: this.props.setMapSurroundings,
            layerContainers, map,
            back: () => this.props.changeModalContentType('RoleList', 'Gestion des rôles'),
            apply: function (map, layerContainers) {
                map = map || this.map;
                layerContainers = layerContainers || this.layerContainers;
                this.map = map;
                this.layerContainers = layerContainers;

                let surroundings;
                const isGeographicallyLimited = this.rightsToApply.projectRoleStations?.length > 0 || this.rightsToApply.areas?.length > 0;
                const stations = (this.stationsLayer || layerContainers.stationsLayer.getLayers()).map(l => l.feature);
                surroundings = this.setMapSurroundings({ stations, isGeographicallyLimited });

                Object.keys(layerContainers).forEach(key => {
                    if (!this[key]?.length) this[key] = layerContainers[key].getLayers();
                    layerContainers[key].clearLayers();

                    if (RightsUtil.canRead(this.rightsToApply[key.replace('Layer', '')])) {
                        // On retore les éléments si on en a de stocker (avec les limitations géographiques)
                        const layers = this[key];
                        if (layers?.length)
                            layers.forEach(layer => {
                                const shape = key.includes('tree') || key.includes('furniture') || key.includes('marker') ? 'marker'
                                    : key.includes('greenSpace') || key.includes('station') ? 'polygon'
                                        : null;
                                if (shape && ProjectsUtil.checkIfInsideSurroundings(shape, layer, null, surroundings))
                                    layerContainers[key].addLayer(layer);
                            });

                        if (!map.hasLayer(layerContainers[key])) map.addLayer(layerContainers[key]);
                    } else map.removeLayer(layerContainers[key]);
                });
            }
        };

        this.props.setViewProjectAsData(viewProjectAsData).then(() => {
            if (isProjectOpen) {
                this.props.setRights(rightsToApply).then(() => {
                    this.props.viewProjectAsData.apply(map, layerContainers);
                    this.props.hideForm();
                });
            } else this.props.hideForm();
        });
    }

    duplicate = (projectRole) => {
        const label = projectRole.type ? i18n.t(projectRole.label) : projectRole.label;
        const duplicate = { ...JSON.parse(JSON.stringify(projectRole)), id: uuidv4(), label: i18n.t("{{label}} - copie", { label }), type: null };
        this.setState(prevState => ({ action: ACTIONS.newRole, projectRole: duplicate, projectRoles: [...prevState.projectRoles, duplicate] }));
    }

    handleContextMenu = (event, projectRole) => {
        this.contextMenuElement = projectRole;
        contextMenu.show({ event, id: 'context-menu-role-list' });
        this.forceUpdate();
    }

    canEditProjectRole = (projectRole) => {
        if (!projectRole) return false;
        const { loginAsData, userProjects } = this.props;
        const userId = jwt_decode(new Cookies().get('token')).id;
        const userProject = userProjects?.find(up => up.userId === userId);
        const userProjectRole = userProject?.projectRole;
        const isOwner = projectRole.type === 'owner';
        const isManager = projectRole.type === 'manager';
        return !loginAsData?.readOnly && !isOwner && (userProjectRole?.type === 'owner' || (userProjectRole?.id !== projectRole.id && (userProjectRole?.type === 'manager' || !isManager)));
    }

    canViewProjectRole = (projectRole) => {
        if (!projectRole) return false;
        const { userProjects } = this.props;
        const userId = jwt_decode(new Cookies().get('token')).id;
        const userProject = userProjects?.find(up => up.userId === userId);
        const userProjectRole = userProject?.projectRole;
        const isUserOwnerOrManager = ['owner', 'manager'].includes(userProjectRole?.type);
        const isOwner = projectRole.type === 'owner';
        const isManager = projectRole.type === 'manager';
        return !isOwner && ((isUserOwnerOrManager && projectRole.type === 'manager') || !isManager);
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projects,
        project: state.project,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        userProjects: state.userProjects,
        editedProperties: state.editedProperties,
        layer: state.layer,
        loginAsData: state.loginAsData,
        formulas: state.formulas,
        projectCollaborators: state.projectCollaborators,
        roleTemplates: state.roleTemplates,
        viewProjectAsData: state.viewProjectAsData,
        rights: state.rights,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setProjects,
    setEditedProperties,
    unlockEditedProperties,
    setUserProjects,
    setProjectCollaborators,
    setProject,
    setRoleTemplates,
    setRights,
    setViewProjectAsData
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);