import AppSettings from '../AppSettings';
// Services
import { showToast } from '../utils/ToastsUtil';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class FileInfosService {
    static getProjectFileInfos(projectId, type, projectFiles = false) {
        return Axios.get(`${BACKEND_ENDPOINT}fileInfos/?projectId=${projectId}&type=${type}&projectFiles=${projectFiles}`, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) {
                if (type === 'photo') showToast('photos_loading_failed');
                else showToast('files_loading_failed');
            }
            return;
        });
    }

    static addFileInfo(formData, axiosOptions = {}) {
        axiosOptions['headers'] = {
            'Authorization': `Bearer ${new Cookies().get('token')}`,
            'content-type': 'multipart/form-data'
        };

        return Axios.post(BACKEND_ENDPOINT + 'fileInfos/', formData, axiosOptions).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('file_addition_failed');
            return;
        });
    }

    static updateFileInfo(fileInfo) {
        return Axios.put(BACKEND_ENDPOINT + 'fileInfos/' + fileInfo.id, fileInfo, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            if (navigator.onLine) showToast('file_updated');
            return response;
        }, () => {
            if (navigator.onLine) showToast('file_update_failed');
            else showToast('will_sync');
            return;
        });
    }

    static deleteFileInfos(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'fileInfos/', {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            },
            data: { ids }
        }).then(response => {
            if (navigator.onLine && ids.length === 1) showToast('file_deleted');
            else if (navigator.onLine && ids.length > 1) showToast('files_deleted');
            return response;
        }, () => {
            if (navigator.onLine && ids.length === 1) showToast('file_deletion_failed');
            if (navigator.onLine && ids.length > 1) showToast('files_deletion_failed');
            else showToast('will_sync');
            return;
        });
    }

    static updatePhotoPreview(category, elementId, blobName) {
        return Axios.put(BACKEND_ENDPOINT + 'fileInfos/photoPreview/', { category, elementId, blobName }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (navigator.onLine) showToast('preview_updated');
            return response;
        }, () => {
            if (navigator.onLine) showToast('preview_update_failed');
            else showToast('will_sync');
            return;
        });
    }
}
