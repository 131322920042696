import React, { Component } from 'react';
// Composants
import { Menu } from 'semantic-ui-react'
// Librairies
import Cookies from 'universal-cookie';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';

class ModalNavbar extends Component {
    render() {
        const { activeOrganization, modalContentType } = this.props;
        // Required fields
        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);

        return (
            <div className='modalNavbar'>
                <Menu attached='top' tabular className='left'>
                    {activeOrganization.subscription.statistics && publicFields.main.statistics &&
                        <Menu.Item
                            name='statistics' style={{ marginLeft: '3px' }} active={modalContentType === 'ProjectDetail'}
                            onClick={() => this.props.changeModalContentType('ProjectDetail', i18n.t("Statistiques"), 'statistics')}
                        >
                            {i18n.t("Statistiques")}
                        </Menu.Item>}
                    {activeOrganization.subscription.charts && publicFields.main.charts &&
                        <div style={{ display: 'flex' }}>
                            {publicFields.trees.gender &&
                                <Menu.Item
                                    name='genders' style={{ marginLeft: '3px' }} active={modalContentType === 'GendersChart'}
                                    onClick={() => this.props.changeModalContentType('GendersChart', i18n.t("Graphique des genres"), 'genders')}
                                >
                                    {i18n.t("Genres")}
                                </Menu.Item>}
                            {publicFields.trees.ontogenicStage &&
                                <Menu.Item
                                    name='ontogenicStages' active={modalContentType === 'OntogenicsChart'}
                                    style={!requiredFields.trees.ontogenicStage ? { display: 'none' } : null}
                                    onClick={() => this.props.changeModalContentType('OntogenicsChart', i18n.t("Graphique des stades ontogéniques"), 'ontogenicStages')}
                                >
                                    {i18n.t("Stades ontogéniques")}
                                </Menu.Item>}
                            {publicFields.trees.vigor &&
                                <Menu.Item
                                    name='vigors' active={modalContentType === 'VigorsChart'}
                                    style={!requiredFields.trees.vigor ? { display: 'none' } : null}
                                    onClick={() => this.props.changeModalContentType('VigorsChart', i18n.t("Graphique des vigueurs"), 'vigors')}
                                >
                                    {i18n.t("Vigueurs")}
                                </Menu.Item>}
                            {publicFields.trees.toCutDown &&
                                <Menu.Item
                                    name='cutDowns' active={modalContentType === 'CutDownsChart'}
                                    onClick={() => this.props.changeModalContentType('CutDownsChart', i18n.t("Graphique des abattages"), 'cutDowns')}
                                >
                                    {i18n.t("Abattages")}
                                </Menu.Item>}
                            {publicFields.main.actions &&
                                <Menu.Item
                                    name='actions' active={modalContentType === 'ActionsChart'}
                                    onClick={() => this.props.changeModalContentType('ActionsChart', i18n.t("Graphique des actions à réaliser"), 'actions')}
                                >
                                    {i18n.t("Actions")}
                                </Menu.Item>}
                            {publicFields.trees.healthReview &&
                                <Menu.Item
                                    name='scores' active={modalContentType === 'HealthReviewsChart'}
                                    style={!requiredFields.trees.healthReview ? { display: 'none' } : null}
                                    onClick={() => this.props.changeModalContentType('HealthReviewsChart', i18n.t("Graphique des cotes sanitaires"), 'scores')}
                                >
                                    {i18n.t("Cotes sanitaires")}
                                </Menu.Item>}
                            {new Cookies().get('token') && activeOrganization.subscription.customCharts
                                && publicFields.main.charts &&
                                < Menu.Item
                                    name='custom' active={modalContentType === 'CustomCharts'}
                                    onClick={() => this.props.changeModalContentType('CustomCharts', i18n.t("Graphiques personnalisés"), 'custom')}
                                >
                                    {i18n.t("Graphiques personnalisés")}
                                </Menu.Item>}
                        </div>}
                </Menu>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        project: state.project,
        projectCollaborators: state.projectCollaborators
    };
};

export default connect(mapStateToProps)(ModalNavbar);