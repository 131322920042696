import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Form } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import { setCurrentAction } from '../../../actionCreators/appActions';
import { connect } from 'react-redux';
import { setProject, setProjects } from '../../../actionCreators/projectsActions';
import DatesUtil from '../../../utils/DatesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import ProjectsService from '../../../services/ProjectsService';

class LinkingElementsForm extends Component {
    state = {
        isLoading: false
    };

    render() {
        const { isToolbarExpanded, selectedElements } = this.props;
        const { isLoading } = this.state;
        const nbSelectedElements = selectedElements?.length || 0;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form loading={isLoading} error style={{ width: '100%' }}>
                    <div>
                        {nbSelectedElements > 1
                            ? i18n.t("{{nbElements}} éléments liés", { nbElements: nbSelectedElements })
                            : i18n.t("{{nbElements}} élément lié", { nbElements: nbSelectedElements })}
                    </div>
                    <div style={{ display: 'flex', marginTop: '5px' }}>
                        <Button
                            type='button' color='red' title={i18n.t("Annuler")} size='small'
                            style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                            onClick={this.close}
                        >
                            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                        </Button>
                        <Button
                            type='button' color='green' title={i18n.t("Valider")} size='small'
                            style={{ display: 'inline-flex', justifyContent: 'center', width: '50%', marginRight: 0 }}
                            onClick={this.handleSubmit}
                        >
                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }

    close = () => {
        this.props.toolbarRef.current.setButtonsIsDisabled(['advancedTools', 'treeTools', 'greenSpaceTools', 'furnitureTools', 'markerTools', 'stationTools', 'imageTools', 'statistics', 'table'], false);
        this.props.toggleElementsHighlight(this.props.selectedElements);
        this.props.setCurrentAction('viewingModal');
        this.props.changeModalContentType('LinkedElementList', i18n.t("Éléments liés"));
    }

    handleSubmit = () => {
        const { layer, selectedElements, project, projects, formulas, webSocketHubs } = this.props;
        let updatedProject = JSON.parse(JSON.stringify(project));
        const elementId = layer[0].feature.id;
        const elementCategory = layer[0].feature.properties.category;

        const currentLinkedElements = updatedProject.linkedElements.filter(le => le.elementId === elementId || le.linkedElementId === elementId);
        const linkedElements = [];
        const linkedElementsToAdd = [];
        selectedElements.forEach(selectedElement => {
            const linkedElementId = selectedElement.feature.id;
            const linkedElementCategory = selectedElement.feature.properties.category;
            const linkedElement = { elementId, elementCategory, linkedElementId, linkedElementCategory, projectId: project.id, date: DatesUtil.getUTCDate() };
            if (!currentLinkedElements.find(cle => cle.elementId === linkedElementId || cle.linkedElementId === linkedElementId))
                linkedElementsToAdd.push(linkedElement);
            linkedElements.push(linkedElement);
        });


        const linkedElementToDelete = currentLinkedElements.filter(cle => !selectedElements.find(se => (se.feature.id === cle.elementId || se.feature.id === cle.linkedElementId)));
        if (linkedElementsToAdd.length || linkedElementToDelete.length)
            ProjectsService.toggleLinkedElements(linkedElementsToAdd, linkedElementToDelete, project.id).then(() => {
                updatedProject.linkedElements = [...updatedProject.linkedElements.filter(le => !linkedElementToDelete.find(letd =>
                    (letd.elementId === le.elementId && letd.linkedElementId === le.linkedElementId)
                    || (letd.elementId === le.linkedElementId && letd.linkedElementId === le.elementId)
                )), ...linkedElementsToAdd];
                ProjectsUtil.updateProjectsInProps(updatedProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
                WebSocketUtil.updateProject(webSocketHubs, project.id, updatedProject);
                this.close();
            });
        else this.close();
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        webSocketHubs: state.webSocketHubs,
    };
};

const mapDispatchToProps = {
    setCurrentAction,
    setProject,
    setProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkingElementsForm);