import React, { Component } from 'react';
import AppSettings from '../../../AppSettings';
// Composants
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import UrbasenseButton from '../../Forms/Users/UrbasenseButton';
import 'semantic-ui-css/semantic.min.css';
import { Button, Grid, Icon } from 'semantic-ui-react';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import MicrosoftLogin from 'react-microsoft-login';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Ressources
import UrbasenseLogo from '../../../resources/pngs/urbasense.png';
// Services
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

class LinkAccountsForm extends Component {
    render() {
        const { externalAuthentications } = this.props;

        return (
            <>
                {externalAuthentications?.length > 0 &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {this.renderExternalAuthentication()}
                    </div>}
            </>
        );
    }

    renderExternalAuthentication = () => {
        let fields = [];
        const { externalAuthentications, linkedAccounts, loginAsData } = this.props;
        let disableUrbasense = this.props.userInfos.source === "urbasense" && !this.props.userInfos.hasPassword;

        externalAuthentications.forEach(provider => {
            let label = provider.name.charAt(0).toUpperCase() + provider.name.slice(1);
            let linkedAccount, disabled = false;

            const found = linkedAccounts.hasOwnProperty(provider.name);
            if (found) {
                linkedAccount = { loginProvider: provider.name, email: linkedAccounts[provider.name] };
                label = i18n.t("Délier");

                const decodedToken = jwt_decode(new Cookies().get('token'));
                if (linkedAccounts[provider.name].toUpperCase() === decodedToken.email.toUpperCase() && !this.props.userInfos.hasPassword) {
                    disabled = true;
                    disableUrbasense = false;
                }
            }
            else label = i18n.t("Lier");
            if (loginAsData) {
                disabled = true;
                disableUrbasense = true;
            }

            switch (provider.name.toUpperCase()) {
                case 'GOOGLE':
                    fields.push(
                        <GoogleLogin
                            key={provider.name}
                            clientId={AppSettings.getGoogleClientId()}
                            render={renderProps => (
                                <Grid key={provider.name}>
                                    <Grid.Row>
                                        <Grid.Column computer={10} tablet={10} mobile={16} style={{ display: 'flex' }}>
                                            <Icon name='google' size='large' style={{ marginRight: 10 }} /> {found ? linkedAccount.email : i18n.t("Aucun compte lié")}
                                        </Grid.Column>
                                        <Grid.Column computer={6} tablet={6} mobile={16} style={isMobileOnly ? { marginTop: '8px' } : { textAlign: 'right' }}>
                                            <Button
                                                size='mini' color={found ? 'google plus' : null} icon labelPosition='left' disabled={disabled}
                                                style={{ padding: '8px 8px', margin: 0, width: isMobileOnly ? '100%' : null }} onClick={!found ? renderProps.onClick : () => this.unlinkExternalAccount(provider.name, linkedAccount.email)}
                                            >
                                                <Icon name={!found ? 'linkify' : 'unlink'} /> {label}
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                            buttonText='Login'
                            onSuccess={(response) => this.handleResponseGoogle(response, provider)}
                            onFailure={(response) => this.handleResponseGoogle(response, provider)}
                            cookiePolicy={'single_host_origin'}
                        />
                    );
                    break;
                case 'FACEBOOK':
                    fields.push(
                        <FacebookLogin
                            key={provider.name}
                            appId={AppSettings.getFacebookClientId()}
                            callback={(response) => this.handleResponseFacebook(response, provider)}
                            fields='name,email'
                            render={renderProps => (
                                <Grid key={provider.name} style={{ marginTop: 0 }}>
                                    <Grid.Row>
                                        <Grid.Column computer={10} tablet={10} mobile={16} style={{ display: 'flex' }}>
                                            <Icon name='facebook' size='large' style={{ marginRight: 10 }} /> {found ? linkedAccount.email : i18n.t("Aucun compte lié")}
                                        </Grid.Column>
                                        <Grid.Column computer={6} tablet={6} mobile={16} style={isMobileOnly ? { marginTop: '8px' } : { textAlign: 'right' }}>
                                            <Button
                                                size='mini' color={found ? 'facebook' : null} icon labelPosition='left' disabled={disabled}
                                                style={{ padding: '8px 8px', margin: 0, width: isMobileOnly ? '100%' : null }} onClick={!found ? renderProps.onClick : () => this.unlinkExternalAccount(provider.name, linkedAccount.email)}
                                            >
                                                <Icon name={!found ? 'linkify' : 'unlink'} /> {label}
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        />
                    );
                    break;
                case 'MICROSOFT':
                    fields.push(
                        <Grid key={provider.name} style={{ marginTop: 0 }}>
                            <Grid.Row>
                                <Grid.Column computer={10} tablet={10} mobile={16} style={{ display: 'flex' }}>
                                    <Icon name='microsoft' size='large' style={{ marginRight: 10 }} /> {found ? linkedAccount.email : i18n.t("Aucun compte lié")}
                                </Grid.Column>
                                <Grid.Column computer={6} tablet={6} mobile={16} style={isMobileOnly ? { marginTop: '8px' } : { textAlign: 'right' }}>
                                    {!disabled && !linkedAccount ?
                                        <MicrosoftLogin
                                            key={provider.name} clientId={AppSettings.getMicrosoftClientId()} prompt='select_account'
                                            authCallback={(_, data) => this.handleResponseMicrosoft(data, provider)}
                                            children={
                                                <Button
                                                    size='mini' color={found ? 'blue' : null} icon labelPosition='left'
                                                    style={{ padding: '8px 8px', margin: 0, width: isMobileOnly ? '100%' : null }}
                                                >
                                                    <Icon name={!found ? 'linkify' : 'unlink'} /> {label}
                                                </Button>} />
                                        : <Button
                                            size='mini' color={found ? 'blue' : null} icon labelPosition='left' disabled={disabled}
                                            onClick={() => this.unlinkExternalAccount(provider.name, linkedAccount.email)}
                                            style={{ padding: '8px 8px', margin: 0, width: isMobileOnly ? '100%' : null }}
                                        >
                                            <Icon name={!found ? 'linkify' : 'unlink'} /> {label}
                                        </Button>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    );
                    break;
                default: break;
            }
        });

        // Urbasense
        const provider = { name: 'urbasense' };
        const urbasenseEmail = linkedAccounts[provider.name];
        const found = urbasenseEmail && urbasenseEmail.length > 0;
        fields.push(
            <Grid key='urbasense' style={{ marginTop: 0 }}>
                <Grid.Row>
                    <Grid.Column computer={10} tablet={10} mobile={16} style={{ display: 'flex' }}>
                        <img src={UrbasenseLogo} alt='urbasense' style={{ marginLeft: '2px', marginRight: 10, width: '20px', height: '20px', filter: this.props.isDarkTheme ? 'brightness(0) invert(1)' : 'brightness(0)' }} />
                        {found ? urbasenseEmail : i18n.t("Aucun compte lié")}
                    </Grid.Column>
                    <Grid.Column computer={6} tablet={6} mobile={16} style={isMobileOnly ? { marginTop: '8px' } : { textAlign: 'right' }}>
                        {!disableUrbasense && !urbasenseEmail ?
                            <UrbasenseButton key={provider.name} isLinking={true} isLinked={false} submit={this.handleUrbasenseSubmit} />
                            : <Button
                                size='mini' color={!found && 'white'} className={found && 'button--urbasense'} icon labelPosition='left' disabled={disableUrbasense}
                                onClick={() => this.unlinkExternalAccount(provider.name, urbasenseEmail)}
                                style={{ padding: '8px 8px', margin: 0, width: isMobileOnly ? '100%' : null }}
                            >
                                <Icon name={!found ? 'linkify' : 'unlink'} /> {found ? i18n.t("Délier") : i18n.t("Lier")}
                            </Button>}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );

        return fields;
    }

    handleResponseGoogle = (response, provider) => {
        if (response?.tokenId) {
            let externalAuthentication = {
                TokenId: response.tokenId,
                UserId: null,
                Email: response.profileObj.email,
                FirstName: response.profileObj.givenName,
                LastName: response.profileObj.familyName,
                LoginProvider: provider.name
            }
            this.handleSubmit(externalAuthentication);
        }
    }

    handleResponseMicrosoft = (response, provider) => {
        const account = response?.account;
        if (response?.uniqueId && account && FormattersUtil.checkEmail(account.userName)) {
            let externalAuthentication = {
                TokenId: null,
                UserId: response.uniqueId,
                Email: account.userName,
                FirstName: account.name.split(' ')[0],
                LastName: account.name.replace(account.name.split(' ')[0] + ' ', ''),
                LoginProvider: provider.name
            }

            const sessionStorage = window.sessionStorage;
            if (sessionStorage) sessionStorage.removeItem('msal.idtoken');
            this.handleSubmit(externalAuthentication);
        }
    }

    handleResponseFacebook = (response, provider) => {
        if (response?.userID) {
            let externalAuthentication = {
                TokenId: null,
                UserId: response.userID,
                Email: response.email,
                FirstName: response.name.split(' ')[0],
                LastName: response.name.split(' ')[1],
                LoginProvider: provider.name
            }
            this.handleSubmit(externalAuthentication);
        }
    }

    unlinkExternalAccount = (loginProvider, email) => {
        UsersService.unlinkExternalAuthentication(loginProvider, email).then(response => {
            if (response?.success) {
                let { linkedAccounts } = this.props;
                delete linkedAccounts[loginProvider];
                this.props.setLinkedAccounts(linkedAccounts);
            }
        });
    }

    handleSubmit = (externalAuthentication) => {
        UsersService.linkExternalAuthentication(externalAuthentication).then(response => {
            if (response?.success) {
                let { linkedAccounts } = this.props;
                linkedAccounts[externalAuthentication.LoginProvider] = externalAuthentication.Email;
                this.props.setLinkedAccounts(linkedAccounts);
            }
        });
    }

    handleUrbasenseSubmit = (response) => {
        if (response?.success) {
            let { linkedAccounts } = this.props;
            linkedAccounts['urbasense'] = response.loginResponse.email;
            this.props.setLinkedAccounts(linkedAccounts);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        userInfos: state.userInfos,
        loginAsData: state.loginAsData
    };
};

export default connect(mapStateToProps)(LinkAccountsForm);