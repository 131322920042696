import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Services
import { showToast } from '../utils/ToastsUtil';
import OfflineUtil from '../utils/OfflineUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class SubscriptionsService {
    static getSubscriptions() {
        return Axios.get(BACKEND_ENDPOINT + 'subscriptions').then(response => {
            return response.data;
        }, () => {
            showToast('subscriptions_loading_failed');
            return;
        });
    }

    static getSubscriptionTemplates() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/subscriptions/templates', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('subscription_templates_loading_failed');
            return;
        });
    }

    static addTemplate(template) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/subscriptions/templates/', template, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('template_added');
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('template_addition_failed');
            return;
        });
    }

    static createCheckoutSession(priceId, billingAddress) {
        return Axios.post(BACKEND_ENDPOINT + 'subscriptions/createCheckoutSession/' + priceId, billingAddress, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data?.sessionId;
        });
    };

    static checkVat(vat) {
        return Axios.get(BACKEND_ENDPOINT + 'users/vatInfos/?vat=' + vat, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const vatInformation = response?.data;
            if (vatInformation?.valid) {
                return {
                    customerName: vatInformation.name?.split(', ')?.reverse()?.join(' ') || '',
                    street: vatInformation.address?.split('\n')[0] || '',
                    postalCode: vatInformation.address?.split('\n')[1]?.split(' ')[0] || '',
                    city: vatInformation.address?.split('\n')[1]?.split(' ')?.slice(1)?.join(' ') || '',
                    countryCode: vatInformation.countryCode?.toLowerCase() || ''
                }
            } else return;
        }, () => {
            showToast('vat_validation_failed');
            return;
        });
    }

    static redirectToCustomerPortal() {
        return Axios.post(BACKEND_ENDPOINT + 'subscriptions/customerPortal', null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            window.location.href = response.data.url;
        }, () => {
            showToast('subscription_management_failed');
        });
    }

    static toggleSubscriptionRenewal() {
        return Axios.put(BACKEND_ENDPOINT + 'subscriptions/userSubscriptions/toggleRenewal/', null, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const cancelAtPeriodEnd = response.data;
            if (cancelAtPeriodEnd) showToast('subscription_will_cancel');
            else showToast('subscription_renewed');
            return response.data;
        }, () => {
            showToast('subscription_update_failed');
        });
    }

    static getAllSubscriptions() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/subscriptions/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('subscriptions_loading_failed');
            return;
        });
    }

    static addSubscription(subscription) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/subscriptions/', subscription, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('subscription_added');
            return response.data;
        }, () => {
            showToast('subscription_addition_failed');
            return;
        });
    }

    static updateSubscriptions(subscriptions) {
        return Axios.put(BACKEND_ENDPOINT + 'admin/subscriptions/', subscriptions, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('subscriptions_updated');
            return response.status;
        }, () => {
            showToast('subscriptions_update_failed');
            return;
        });
    }

    static deleteSubscriptions(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'admin/subscriptions/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids }
        }).then(response => {
            return response.status;
        }, () => {
            showToast(ids.length > 1 ? 'subscriptions_deletion_failed' : 'subscription_deletion_failed');
            return;
        });
    }

    static getUserSubscriptionConsumptions() {
        return Axios.get(BACKEND_ENDPOINT + 'subscriptions/userSubsriptionConsumptions/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
        }).then(response => {
            return response.data;
        }, () => {
            showToast('connection_failed');
            return;
        });
    }

    static activateSubscription(token) {
        const userToken = new Cookies().get('token');
        return Axios.get(BACKEND_ENDPOINT + 'subscriptions/activate/' + token, {
            headers: { 'Authorization': userToken && `Bearer ${userToken}` }
        }).then(response => {
            return response;
        }, () => {
            return;
        });
    }

    static exportQuotePDF(subscription, vat, recipient, observation) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/subscriptions/exportQuotePDF', { subscription, vat, recipient, observation }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            let filename = i18n.t("Devis");
            filename += '.pdf';
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
        }, () => {
            return;
        });
    }
}