import React, { Component } from 'react';
// Composants
import { Grid, Header, Icon, Image, Message, Segment } from 'semantic-ui-react';
// Librairies
import i18n from '../../locales/i18n';
// Ressources
import logoMonoWhite from '../../resources/images/logo-mono-white.jpg';
// Services
import SubscriptionsService from '../../services/SubscriptionsService';


class StudentActivation extends Component {
    state = {
        isLoading: true,
        isSuccess: false
    }

    render() {
        const { isLoading, isSuccess } = this.state;
        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' className='text-light' textAlign='center'>
                        <Image style={{ borderRadius: '30px', marginTop: 0, marginRight: '3px' }} src={logoMonoWhite} /> {i18n.t("Activation de la licence")}
                    </Header>
                    <Segment>
                        {isLoading ?
                            <Message info icon>
                                <Icon name='circle notched' loading />
                                <Message.Content style={{ textAlign: 'left' }}>
                                    <Message.Header>{i18n.t("Activation en cours")}</Message.Header>
                                    {i18n.t("L'abonnement est en cours d'activation...")}
                                </Message.Content>
                            </Message>
                            : isSuccess
                                ? <Message success icon>
                                    <Icon name='check' />
                                    <Message.Content style={{ textAlign: 'left' }}>
                                        <Message.Header>{i18n.t("Activation réussie")}</Message.Header>
                                        {this.props.logged
                                            ? i18n.t("Votre abonnement prendra effet dans quelques instants, veuillez patienter")
                                            : i18n.t("Nous allons vous connecter, veuillez patienter")}
                                    </Message.Content>
                                </Message>
                                : <Message error icon>
                                    <Icon name='times' />
                                    <Message.Content style={{ textAlign: 'left' }}>
                                        <Message.Header>{i18n.t("Activation échouée")}</Message.Header>
                                        {i18n.t("Impossible d'activer cet abonnement.")}
                                    </Message.Content>
                                </Message>}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }

    componentDidMount = () => {
        SubscriptionsService.activateSubscription(this.props.match.params.token).then(response => {
            const isSuccess = response ? true : false;
            this.setState({ isLoading: false, isSuccess });
            if (isSuccess)
                setTimeout(() => {
                    if (response?.data?.token && !this.props.logged) {
                        this.props.logUser(response.data.token);
                        this.props.history.push('/');
                    } else {
                        this.props.history.push('/');
                        window.location.reload();
                    }
                }, 5000);
        });
    }
}

export default StudentActivation;