import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { Input, Form, Grid, Segment, Select, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
// Ressources
import { faAward, faCrown, faMapMarkedAlt, faSkull } from '@fortawesome/pro-solid-svg-icons';
import { faCircle, faDotCircle } from '@fortawesome/pro-regular-svg-icons';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step1 extends Component {
    state = {
        properties: {
            place: '',
            customReference: null,
            isEmpty: false,
            isDead: false,
            isStump: false,
            tagId: []
        },
        error: {
            hidden: true,
            messages: [],
            place: false
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.trees;
        const { isEmpty, isDead, isStump, isIndexed, isRemarkable, place, customReference, tagId } = this.props.properties;

        let tags = [];
        if (this.props.project && this.props.projectTags) {
            this.props.projectTags.forEach(tag => {
                if (tag.category === 'Arbre')
                    tags.push({ key: tag.id, text: tag.label, value: tag.id });
            });
        }

        return (
            <Grid id='cat--site' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--yellow-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'Emplacement') }}>
                {!isMobileOnly &&
                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--yellow-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                        <FontAwesomeIcon icon={faMapMarkedAlt} style={{ alignSelf: 'center', marginRight: '5px' }} />
                        <span className='no-themed'>{i18n.t("Emplacement & en-tête")}</span>
                    </Grid.Row>}
                {(requiredFields.isEmpty || requiredFields.isDead || requiredFields.isStump) &&
                    <Grid.Column stretched computer={3} tablet={5} mobile={16} style={isMobileOnly ? { paddingTop: 0, paddingBottom: 0 } : null} >
                        <Segment style={isMobileOnly ? { margin: '20px 0', marginBottom: 0 } : null}>
                            {requiredFields.isEmpty &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        size='tiny' className='button--secondary' color={!isEmpty ? 'grey' : null} type='button'
                                        title={!isEmpty ? i18n.t("L'arbre n'est pas vide") : i18n.t("L'arbre est vide")}
                                        disabled={isDead || isStump} onClick={(e) => this.props.handleButtonChange(e, 'isEmpty')}
                                        style={{ aspectRatio: '4/4', padding: '4px', margin: '1px' }}
                                    >
                                        <FontAwesomeIcon icon={faCircle} size='lg' />
                                    </Button>
                                    <span style={{ marginLeft: '5px', fontWeight: isEmpty ? 'bold' : 'normal' }}>{i18n.t("Vide")}</span>
                                </div>}
                            {requiredFields.isDead &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        size='tiny' className='button--secondary' color={!isDead ? 'grey' : null} type='button'
                                        title={!isDead ? i18n.t("L'arbre n'est pas mort") : i18n.t("L'arbre est mort")}
                                        disabled={isEmpty || isStump} onClick={(e) => this.props.handleButtonChange(e, 'isDead')}
                                        style={{ aspectRatio: '4/4', padding: '4px', margin: '1px' }}
                                    >
                                        <FontAwesomeIcon icon={faSkull} size='lg' />
                                    </Button>
                                    <span style={{ marginLeft: '5px', fontWeight: isDead ? 'bold' : 'normal' }}>{i18n.t("Mort")}</span>
                                </div>}
                            {requiredFields.isStump &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        size='tiny' className='button--secondary' color={!isStump ? 'grey' : null} type='button'
                                        title={!isStump ? i18n.t("L'arbre n'est pas mort") : i18n.t("L'arbre est mort")}
                                        disabled={isEmpty || isDead} onClick={(e) => this.props.handleButtonChange(e, 'isStump')}
                                        style={{ aspectRatio: '4/4', padding: '4px', margin: '1px' }}
                                    >
                                        <FontAwesomeIcon icon={faDotCircle} size='lg' />
                                    </Button>
                                    <span style={{ marginLeft: '5px', fontWeight: isStump ? 'bold' : 'normal' }}>{i18n.t("Souche")}</span>
                                </div>}
                        </Segment>
                    </Grid.Column>}
                {(requiredFields.isIndexed || requiredFields.isRemarkable) &&
                    <Grid.Column stretched computer={3} tablet={5} mobile={16} style={isMobileOnly ? { paddingTop: 0, paddingBottom: 0 } : null} >
                        <Segment style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', ...(isMobileOnly ? { margin: '20px 0', marginBottom: 0 } : {}) }}>
                            {requiredFields.isIndexed &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        size='tiny' className='button--secondary' color={!isIndexed || isEmpty ? 'grey' : null}
                                        title={!isIndexed ? i18n.t("L'arbre n'est pas classé") : i18n.t("L'arbre est classé")}
                                        disabled={isEmpty} onClick={(e) => this.props.handleButtonChange(e, 'isIndexed')}
                                        style={{ aspectRatio: '4/4', padding: '4px', margin: '1px' }}
                                    >
                                        <FontAwesomeIcon icon={faCrown} size='lg' />
                                    </Button>
                                    <span style={{ marginLeft: '5px', fontWeight: isIndexed && !isEmpty ? 'bold' : 'normal' }}>{i18n.t("Classé")}</span>
                                </div>}
                            {requiredFields.isRemarkable &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        size='tiny' color={!isRemarkable || isEmpty ? 'grey' : 'yellow'}
                                        title={!isRemarkable ? i18n.t("L'arbre n'est pas remarquable") : i18n.t("L'arbre est remarquable")}
                                        disabled={isEmpty} onClick={(e) => this.props.handleButtonChange(e, 'isRemarkable')}
                                        style={{ aspectRatio: '4/4', padding: '4px 7px', margin: '1px' }}
                                    >
                                        <FontAwesomeIcon icon={faAward} size='lg' />
                                    </Button>
                                    <span style={{ marginLeft: '5px', fontWeight: isRemarkable && !isEmpty ? 'bold' : 'normal' }}>{i18n.t("Remarquable")}</span>
                                </div>}
                        </Segment>
                    </Grid.Column>}
                {requiredFields.place &&
                    <Grid.Column stretched computer={5} tablet={16} mobile={16}>
                        <Form.Field
                            control={Input} label={i18n.t("Lieu") + ' : '} placeholder={i18n.t("Indiquez un lieu")}
                            name='place' value={place || ''} className='place'
                            error={this.props.error.place} onChange={this.props.handleChange}
                            disabled={!this.props.placeLoaded} loading={!this.props.placeLoaded}
                        />
                    </Grid.Column>}
                {requiredFields.customReference &&
                    <Grid.Column stretched computer={5} tablet={16} mobile={16}>
                        <Form.Field
                            control={Input} type='text' label={<label>{i18n.t("Référence personnalisée")} <InfoIcon content={i18n.t("Référence libre")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Ex: be-ch-13'
                            name='customReference' value={customReference || ''}
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.tags &&
                    <Grid.Column stretched computer={16} tablet={16} mobile={16}>
                        <Form.Field
                            control={Select} label={<label>{i18n.t("Tags")} <InfoIcon content={i18n.t("Note complémentaire (ex: n° quartier, caractéristique supplémentaire, ...)")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder={i18n.t("Sélectionnez un ou plusieurs tags")}
                            name='tagId' options={tags} value={tagId || []} clearable
                            multiple search={FormattersUtil.searchList} selection allowAdditions selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            additionLabel={i18n.t("Ajouter") + ' '} onAddItem={(_, data) => this.props.handleAddTag(data.value)}
                            onChange={this.props.handleTagsChange}
                        />
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Emplacement'))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step1);