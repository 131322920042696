import React, { Component } from 'react';
// Librairies
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
// Services
import StylesUtil from '../../utils/StylesUtil';

class VigorsChart extends Component {
    state = {
        data: null,
        options: null
    }

    render() {
        return (
            <div className='modal-content'>
                <div className='modal-content-body'>
                    {this.state.data && this.state.options
                        ? <Pie data={this.state.data} options={this.state.options} />
                        : i18n.t("Aucune donnée trouvée")}
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        this.loadData();
        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('UpdateElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('RemoveElements', this.loadData);
        }
    }

    loadData = () => {
        let labels = this.props.vigors.map(x => x.label);
        let values = this.props.vigors.map(() => 0);
        let count = 0;

        // On compte le nombre d'arbres par vigueur
        const layers = this.props.layer.getLayers();
        for (const layerName in layers) {
            const layer = layers[layerName];
            let vigor = this.props.vigors.find(x => x.id === layer.feature.properties.vigorId)?.label;
            if (vigor) {
                const index = labels.indexOf(vigor);
                if (index >= 0) {
                    count++;
                    values[index]++;
                }
            }
        }
        labels = labels.map((label, index) => label + ` (${values[index]})`);

        if (count > 0) {
            const themeColor = this.props.isDarkTheme ? 'white' : 'black';
            this.setState({
                data: {
                    labels: labels,
                    datasets: [{
                        data: values,
                        backgroundColor: labels.map(label => { return StylesUtil.getVigorColor(label.split(' (')[0]) }),
                        borderColor: 'rgba(0, 0, 0, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        labels: {
                            render: 'percentage',
                            showZero: false,
                            position: 'outside',
                            textMargin: 8,
                            fontColor: themeColor
                        }
                    },
                    legend: {
                        position: isMobileOnly ? 'bottom' : 'right',
                        labels: {
                            fontColor: themeColor
                        }
                    }
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        vigors: state.vigors,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
};

export default connect(mapStateToProps)(VigorsChart);