import React, { Component } from 'react';
// Librairies
import { connect } from 'react-redux';

class HelpdeskButton extends Component {
    render() {
        const linkStyle = {
            width: '40px', height: '40px', borderRadius: '50%', backgroundColor: 'var(--green-100)',
            position: 'absolute', bottom: '22px', right: '55px', zIndex: 500,
            display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', fontSize: '20pt', color: 'white'
        };

        return (
            <a id='help-desk-button' href='https://grality.atlassian.net/servicedesk/customer/portal/' target='_blank' rel='noopener noreferrer' style={linkStyle}>
                ?
            </a>
        );
    }

    componentDidMount = () => {
        if (!this.props.isOnline) {
            const helpDeskButton = document.getElementById('help-desk-button');
            helpDeskButton.style.bottom = '50px';
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOnline !== this.props.isOnline) {
            const helpDeskButton = document.getElementById('help-desk-button');
            clearTimeout(this.timeout);
            if (!this.props.isOnline) helpDeskButton.style.bottom = '50px';
            else this.timeout = setTimeout(() => helpDeskButton.style.bottom = '22px', 2000);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(HelpdeskButton);