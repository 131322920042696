import { Button } from 'semantic-ui-react';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class Breadcrumb extends Component {
    render() {
        const { showPreviousButton, isPreviousButtonDisabled, onPreviousButtonClick } = this.props;

        return (
            <div className='custom-breadcrumb' style={this.props.style}>
                {showPreviousButton &&
                    <Button size='tiny' color='grey' disabled={isPreviousButtonDisabled} onClick={onPreviousButtonClick} style={{ padding: '5px 8px', marginRight: '5px' }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>}
                <div className='sections'>{this.renderSections()}</div>
            </div>
        );
    }

    renderSections = () => {
        const { sections } = this.props;
        return sections.map((section, index) => {
            const isActive = index === sections.length - 1;
            return (
                <div key={index}>
                    {index > 0 && <FontAwesomeIcon icon={faAngleRight} />}
                    <div
                        title={section.title} className={`section${isActive ? ' active' : ''}`} onClick={section.onClick}
                        onDragOver={(e) => e.preventDefault()} onDrop={(e) => this.props.onDrop(e, section.element)}
                    >
                        {section.icon && <FontAwesomeIcon icon={section.icon} />}
                        {section.label}
                    </div>
                </div>
            );
        });
    }
}

export default Breadcrumb;