import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input, Select, Icon, Button, Segment, Checkbox, Grid, Message, TextArea, Label, Radio } from 'semantic-ui-react';
import { MentionsInput, Mention } from 'react-mentions';
// Librairies
import i18n from '../../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
import { faArrowUpRightFromSquare, faCalendarDays, faCheck, faFunction, faListUl, faPenField, faSquareRootAlt, faTimes, faToggleOn } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setCustomFields } from '../../../actionCreators/elementsActions';
// Services
import CustomFieldsService from '../../../services/CustomFieldsService';
import DatePicker from '../../Utils/DatePicker';
// Utils
import FormulasUtil from '../../../utils/FormulasUtil';
import StylesUtil from '../../../utils/StylesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';

const initialCustomField = {
    label: '',
    type: null,
    category: null,
    description: null,
    isGlobal: true,
    forEmpty: true,
    forDead: true,
    forStump: true,
    maxLength: '256',
    min: '0',
    max: '',
    step: '1',
    formula: '',
    trunksTarget: 'all',
    trunksOperation: 'sum',
    unit: null,
    minDate: null,
    maxDate: null,
    isMultiple: false,
    isNumeric: false,
    dropdownCustomFieldValues: [{ label: '', value: '' }],
    formulaCustomFieldFormats: [{ key: '', value: '' }]
};

const initialError = {
    label: false,
    type: false,
    category: false,
    description: false,
    min: false,
    max: false,
    step: false,
    maxLength: false,
    dcfvLabels: [],
    dcfvValues: []
};

const categoryOptions = [
    { text: i18n.t("Arbre"), value: 'Arbre' },
    { text: i18n.t("Espace vert"), value: 'Espace vert' },
    { text: i18n.t("Mobilier"), value: 'Mobilier' }
];

const numberOptions = [
    { text: i18n.t("Inférieur à ..."), value: 'lower' },
    { text: i18n.t("Égal à ..."), value: 'equal' },
    { text: i18n.t("De ... à ..."), value: 'between' },
    { text: i18n.t("Supérieur à ..."), value: 'greater' }
];

const typeOptions = [
    { text: <span><FontAwesomeIcon icon={faToggleOn} style={{ marginRight: '5px' }} />{i18n.t("Booléen")}</span>, value: 'boolean' },
    { text: <span><FontAwesomeIcon icon={faPenField} style={{ marginRight: '5px' }} />{i18n.t("Texte")}</span>, value: 'text' },
    { text: <span><FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ marginRight: '5px' }} />{i18n.t("URL")}</span>, value: 'url' },
    { text: <span><FontAwesomeIcon icon={faSquareRootAlt} style={{ marginRight: '5px' }} />{i18n.t("Nombre")}</span>, value: 'number' },
    { text: <span><FontAwesomeIcon icon={faFunction} style={{ marginRight: '5px' }} />{i18n.t("Formule")}</span>, value: 'formula' },
    { text: <span><FontAwesomeIcon icon={faCalendarDays} style={{ marginRight: '5px' }} />{i18n.t("Date")}</span>, value: 'date' },
    { text: <span><FontAwesomeIcon icon={faListUl} style={{ marginRight: '5px' }} />{i18n.t("Liste déroulante")}</span>, value: 'list' }
];

const operationSuggestions = FormulasUtil.getOperationSuggestions();
const fieldSuggestions = FormulasUtil.getFieldSuggestions();

class CustomFieldForm extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        isLoading: false,
        customField: null,
        tags: [],
        error: JSON.parse(JSON.stringify(initialError))
    };

    render() {
        const { isOnline, isCreating, isAdmin, customFields, organizationCustomFields, categories } = this.props;
        const { customField, tags, confirmation, error, isLoading } = this.state;
        const userCustomFieldCategorOptions = categories.map(c => ({ key: c.id, text: c.label, value: c.id }));
        const tagOptions = tags.map(t => ({ key: t, text: t, value: t }));

        const suggestions = customField?.type === 'formula' && [
            ...fieldSuggestions
                .filter(suggestion => suggestion.category === customField.category)
                .map(field => ({ ...field, value: field.display })),
            ...[...customFields, ...(organizationCustomFields || [])]
                .filter(cf => cf.category === customField.category && ((cf.type === 'list' && cf.isNumeric) || cf.type === 'number'))
                .map(cf => ({ display: cf.label, value: `${cf.id}|${cf.label}`, id: `${cf.id}`, category: cf.category }))
        ];

        return (
            <>
                {customField &&
                    <Form
                        style={{ textAlign: 'left', flex: '1 1 auto', display: 'flex', flexDirection: 'column', height: 0, overflowY: 'auto', overflowX: 'hidden', paddingRight: '5px' }} widths='equal'
                        onSubmit={this.handleSubmit} error loading={isLoading}
                    >
                        <Form.Group style={{ marginBottom: '10px' }}>
                            <Form.Field
                                control={Select} width={5} style={{ minWidth: 0 }} label={i18n.t("Type") + '* : '} placeholder={i18n.t("Obligatoire")} selectOnBlur={false}
                                name='type' options={typeOptions} value={customField.type || ''}
                                onChange={this.handleChange} disabled={!isCreating} error={error.type}
                            />
                            <Form.Field
                                control={Input} width={5} label={i18n.t("Libellé") + '* :'} placeholder={`Ex: ${i18n.t("Longueur racines")}`}
                                name='label' value={customField.label}
                                onChange={this.handleChange} error={error.label}
                            />
                            <Form.Field
                                control={Select} width={5} style={{ minWidth: 0 }} label={i18n.t("Catégorie d'éléments") + '* : '} placeholder={i18n.t("Obligatoire")} selectOnBlur={false}
                                name='category' options={categoryOptions} value={customField.category}
                                onChange={this.handleChange} disabled={!isCreating} error={error.category}
                            />
                        </Form.Group>
                        <Form.Field
                            control={Select} width={5} style={{ minWidth: 0 }} label={i18n.t("Catégorie") + '* : '} selectOnBlur={false}
                            name='customFieldCategoryId' options={userCustomFieldCategorOptions} value={customField.customFieldCategoryId || 0}
                            onChange={this.handleChange}
                        />
                        <Form.Field
                            control={Select} label={i18n.t("Tags")} placeholder={i18n.t("Sélectionnez un ou plusieurs tags")}
                            name='tags' options={tagOptions} value={customField.tags || []} clearable
                            multiple search={FormattersUtil.searchList} selection allowAdditions selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            additionLabel={i18n.t("Ajouter") + ' '}
                            onChange={this.handleTagsChange}
                        />
                        <Form.Field
                            style={{ minHeight: isMobileOnly ? '200px' : '100px' }}
                            control={TextArea} label={i18n.t("Description") + ' : '} placeholder={i18n.t("Maximum 1000 caractères")}
                            name='description' value={customField.description || ''}
                            onChange={this.handleChange} error={error.description}
                        />
                        {customField.category === 'Arbre' &&
                            <>
                                <label style={{ fontSize: '.92857143em', fontWeight: 'bold', marginTop: '10px' }}>{i18n.t("Actif pour")} :</label>
                                <div style={{ display: 'flex', marginLeft: '2px' }}>
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Vide")}
                                        name='forEmpty' checked={customField.forEmpty} onChange={this.handleCheckboxChange}
                                    />
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Mort")} style={{ marginLeft: '8px' }}
                                        name='forDead' checked={customField.forDead} onChange={this.handleCheckboxChange}
                                    />
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Souche")} style={{ marginLeft: '8px' }}
                                        name='forStump' checked={customField.forStump} onChange={this.handleCheckboxChange}
                                    />
                                </div>
                            </>}
                        {customField.type === 'text' &&
                            <Segment style={{ margin: '14px 0', padding: '7px 14px 11px 14px' }}>
                                <Form.Field
                                    control={Input} width={5} type='number' min='1' step='1' label={`${i18n.t("Longueur maximale")} :`} placeholder='Ex: 256'
                                    name='maxLength' value={customField.maxLength || ''}
                                    onChange={this.handleChange} error={error.maxLength}
                                />
                            </Segment>}
                        {customField.type === 'number' &&
                            <Segment style={{ margin: '14px 0', padding: '7px 14px 11px 14px' }}>
                                <Form.Group style={{ marginBottom: 0 }}>
                                    <Form.Field
                                        control={Input} width={5} type='number' step='0.001' label={`${i18n.t("Minimum")} :`} placeholder='Ex: 0'
                                        name='min' value={customField.min || ''}
                                        onChange={this.handleChange} error={error.min}
                                    />
                                    <Form.Field
                                        control={Input} width={5} type='number' step='0.001' label={`${i18n.t("Maximum")} :`} placeholder='Ex: 100'
                                        name='max' value={customField.max || ''}
                                        onChange={this.handleChange} error={error.max}
                                    />
                                    <Form.Field
                                        control={Input} width={5} type='number' min='0.001' step='0.001' label={`${i18n.t("Étape")}* :`} placeholder='Ex: 1'
                                        name='step' value={customField.step || ''}
                                        onChange={this.handleChange} error={error.step}
                                    />
                                    <Form.Field
                                        control={Input} width={5} label={i18n.t("Unité") + ' :'} placeholder={`Ex: cm`}
                                        name='unit' value={customField.unit || ''}
                                        onChange={this.handleChange} error={error.unit}
                                    />
                                </Form.Group>
                            </Segment>}
                        {customField.type === 'formula' && <>
                            <Segment style={{ margin: '14px 0', padding: '7px 14px 11px 14px', ...(!customField.category ? { opacity: .45, pointerEvents: 'none' } : {}) }}>
                                <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Pattern")}* :</b></label>
                                <MentionsInput
                                    inputRef={this.inputRef} className={`${error.value ? 'react-mention--error' : ''}`}
                                    singleLine placeholder={`{${i18n.t("Circonférence")}} * pi * {${i18n.t("Hauteur")}}^2 * sqrt({${i18n.t("Cote sanitaire")}})`} style={StylesUtil.getMentionsStyle()}
                                    value={customField.formula} onChange={this.handleFormulaChange} disabled={!customField.category}
                                >
                                    <Mention
                                        markup='@(operation:__id__)' trigger='@' data={operationSuggestions} allowSuggestionsAboveCursor
                                        displayTransform={(id) => `${operationSuggestions.find(s => s.id === id)?.value}`}
                                    />
                                    <Mention
                                        markup='@(field:__id__)' trigger='{' data={suggestions} appendSpaceOnAdd allowSuggestionsAboveCursor
                                        displayTransform={(id) => `{${suggestions.find(s => s.id === id)?.value}}`}
                                    />
                                </MentionsInput>
                                <Label color='grey' style={{ float: 'right' }} content={i18n.t("'{' pour afficher les champs | '@' pour afficher les opérations")} />
                                <Form.Field
                                    control={Input} width={5} label={i18n.t("Unité") + ' :'} placeholder={`Ex: cm`}
                                    name='unit' value={customField.unit || ''}
                                    onChange={this.handleChange} error={error.unit}
                                />
                                {fieldSuggestions.filter(suggestion => ['TrunkHeight', 'Circumference'].includes(suggestion.value)).map(suggestion => suggestion.id).some(property => customField.formula.includes(property)) &&
                                    <Form.Field>
                                        <label style={{ fontSize: '.92857143em', marginTop: '10px' }}>{i18n.t("Gestion des arbres multi-axes")} :</label>
                                        <div>
                                            <span style={{ marginRight: '15px' }}>{i18n.t("Axes ciblés")} :</span>
                                            <Radio label={i18n.t("Tous")} name='trunksTarget' style={{ marginLeft: '7px' }} value='all' checked={customField.trunksTarget === 'all'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Plus petit")} name='trunksTarget' style={{ marginLeft: '7px' }} value='smallest' checked={customField.trunksTarget === 'smallest'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Plus grand")} name='trunksTarget' style={{ marginLeft: '7px' }} value='tallest' checked={customField.trunksTarget === 'tallest'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Plus fin")} name='trunksTarget' style={{ marginLeft: '7px' }} value='thinnest' checked={customField.trunksTarget === 'thinnest'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Plus gros")} name='trunksTarget' style={{ marginLeft: '7px' }} value='largest' checked={customField.trunksTarget === 'largest'} onChange={this.handleChange} />
                                        </div>
                                        <div>
                                            <span style={{ marginRight: '15px' }}>{i18n.t("Réduction des résultats")} :</span>
                                            <Radio label={i18n.t("Somme")} name='trunksOperation' style={{ marginLeft: '7px' }} value='sum' disabled={customField.trunksTarget !== 'all'} checked={customField.trunksOperation === 'sum'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Moyenne")} name='trunksOperation' style={{ marginLeft: '7px' }} value='average' disabled={customField.trunksTarget !== 'all'} checked={customField.trunksOperation === 'average'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Multiplication")} name='trunksOperation' style={{ marginLeft: '7px' }} value='multiplication' disabled={customField.trunksTarget !== 'all'} checked={customField.trunksOperation === 'multiplication'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Min")} name='trunksOperation' style={{ marginLeft: '7px' }} value='min' disabled={customField.trunksTarget !== 'all'} checked={customField.trunksOperation === 'min'} onChange={this.handleChange} />
                                            <Radio label={i18n.t("Max")} name='trunksOperation' style={{ marginLeft: '7px' }} value='max' disabled={customField.trunksTarget !== 'all'} checked={customField.trunksOperation === 'max'} onChange={this.handleChange} />
                                        </div>
                                    </Form.Field>}
                            </Segment>
                            <Segment style={{ margin: '14px 0', padding: '7px 14px 11px 14px', ...(!customField.category ? { opacity: .45, pointerEvents: 'none' } : {}) }}>
                                <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Formattage")} :</b></label>
                                <Grid style={{ margin: 0 }}>
                                    <Grid.Row style={{ paddingBottom: 0 }}>
                                        <Grid.Column width={5} style={{ fontWeight: 'bold' }}>{i18n.t("Condition")}</Grid.Column>
                                        <Grid.Column width={6} style={{ fontWeight: 'bold' }}>{i18n.t("Valeur(s)")}</Grid.Column>
                                        <Grid.Column width={5} style={{ fontWeight: 'bold' }}>{i18n.t("Texte affiché")}</Grid.Column>
                                    </Grid.Row>
                                    {(!customField.formulaCustomFieldFormats.at(-1) || customField.formulaCustomFieldFormats.at(-1).key?.replace(/[<->]/g, () => '') ? [...customField.formulaCustomFieldFormats, ''] : customField.formulaCustomFieldFormats).map((format, index) => {
                                        return (
                                            <Grid.Row style={{ paddingTop: 0, paddingBottom: '7px' }}>
                                                <Grid.Column width={5}>
                                                    <Form.Field
                                                        control={Select} placeholder={i18n.t("Condition")} selectOnBlur={false} selectOnNavigation={false}
                                                        options={numberOptions} value={this.getNumberCondition(format.key)} onChange={(_, { value }) => this.handleFormatConditionChange(index, value)}
                                                    />
                                                </Grid.Column>
                                                {format.key?.includes('-') ?
                                                    <>
                                                        <Grid.Column width={3}>
                                                            <Form.Field
                                                                control={Input} placeholder={i18n.t("Valeur 1")} type='number' step='0.1' disabled={!format.key && format.key !== ''}
                                                                value={this.getNumberValue(format.key, 0)} autoComplete='off' onChange={(_, { value }) => this.handleFormatKeyChange(index, value, 0)}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={3}>
                                                            <Form.Field
                                                                control={Input} placeholder={i18n.t("Valeur 2")} type='number' step='0.1' disabled={!format.key && format.key !== ''}
                                                                value={this.getNumberValue(format.key, 1)} autoComplete='off' onChange={(_, { value }) => this.handleFormatKeyChange(index, value, 1)}
                                                            />
                                                        </Grid.Column>
                                                    </>
                                                    :
                                                    <Grid.Column width={6}>
                                                        <Form.Field
                                                            control={Input} placeholder={i18n.t("Valeur")} type='number' step='0.1' disabled={!format.key && format.key !== ''}
                                                            value={this.getNumberValue(format.key)} autoComplete='off' onChange={(_, { value }) => this.handleFormatKeyChange(index, value)}
                                                        />
                                                    </Grid.Column>}
                                                <Grid.Column width={5}>
                                                    <Form.Field
                                                        control={Input} placeholder={i18n.t("Libellé")} name='label' value={format.value || ''}
                                                        error={error.dcfvLabels.includes(index)}
                                                        onChange={(_, { value }) => this.handleFormatValueChange(index, value)}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        );
                                    })}
                                </Grid>
                            </Segment>
                        </>}
                        {customField.type === 'date' &&
                            <Segment style={{ margin: '14px 0', padding: '7px 14px 11px 14px' }}>
                                <DatePicker
                                    label={`${i18n.t("Date minimale")} :`} name='minDate' value={customField.minDate}
                                    error={error.minDate} onChange={this.handleChange}
                                />
                                <DatePicker
                                    label={`${i18n.t("Date maximale")} :`} name='maxDate' value={customField.maxDate}
                                    error={error.maxDate} onChange={this.handleChange}
                                />
                            </Segment>}
                        {customField.type === 'list' &&
                            <Segment style={{ margin: '14px 0', padding: '7px 14px 11px 14px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', marginBottom: '7px' }}>
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Multiple")} disabled={!isCreating}
                                        name='isMultiple' checked={customField.isMultiple} onChange={this.handleCheckboxChange}
                                    />
                                    <Form.Field
                                        control={Checkbox} label={i18n.t("Numérique")} style={{ marginLeft: '8px' }}
                                        name='isNumeric' checked={customField.isNumeric} onChange={this.handleCheckboxChange}
                                    />
                                </div>
                                <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Liste de choix")} :</b></label>
                                <div style={{ flex: '1 1 auto', height: 0, minHeight: '100px', overflowY: 'auto', overflowX: 'hidden' }}>
                                    {customField.dropdownCustomFieldValues.map(({ label, value }, index) => (
                                        <Grid key={index} style={{ margin: '3px' }}>
                                            <Grid.Column computer={8} tablet={8} mobile={16} style={{ padding: '0 3px 0 0' }}>
                                                <Form.Field
                                                    control={Input} placeholder={i18n.t("Libellé")} name='label' value={label || ''}
                                                    error={error.dcfvLabels.includes(index)}
                                                    onChange={(_, { value }) => this.handleDropdownValueChange('label', value, index)}
                                                />
                                            </Grid.Column>
                                            {customField.isNumeric &&
                                                <Grid.Column computer={8} tablet={8} mobile={16} style={{ padding: '0 0 0 3px' }}>
                                                    <Form.Field
                                                        control={Input} type='number' step='1' placeholder={i18n.t("Valeur")}
                                                        name='value' value={value || ''} disabled={!label?.trim() && !value && value !== 0}
                                                        onChange={(_, { value }) => this.handleDropdownValueChange('value', value, index)}
                                                        error={error.dcfvValues.includes(index)}
                                                    />
                                                </Grid.Column>}

                                        </Grid>
                                    ))}
                                </div>
                            </Segment>}
                        <div style={{ marginTop: 'auto' }}>
                            {isAdmin ?
                                <Button type='submit' color='green' disabled={!isOnline}>
                                    {i18n.t("Ajouter")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                                </Button>
                                :
                                <>
                                    {!isCreating &&
                                        <Message
                                            size='small' icon='warning circle' negative header={i18n.t("Avertissement")}
                                            content={<>
                                                <p>{i18n.t("Mettre à jour un champs personnalisé pourrait corrompre les données des éléments sur lesquels il a été utilisé.")}</p>
                                                <Form.Field
                                                    name='confirmation' label={i18n.t("Êtes-vous certain de vouloir mettre à jour le champs personnalisé ?")}
                                                    control={Checkbox} checked={confirmation}
                                                    error onChange={(_, { checked }) => this.setState({ confirmation: checked })}
                                                />
                                            </>}
                                        />}
                                    <Button type='button' color='red' onClick={this.props.cancel}>
                                        <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button type='submit' color='green' disabled={!isOnline || (!isCreating && !confirmation)}>
                                        <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </>}
                        </div>
                    </Form>}
            </>
        );
    }

    componentDidMount = () => {
        const { isAdmin, customField, tags, defaultCategory, activeOrganization } = this.props;
        const convertNumberToString = (value) => typeof value === 'number' ? `${value}` : value;

        let formula = customField?.formula || '';
        formula.match(/\{(.+?)\}/g)?.forEach(property => {
            property = property.slice(1, property.length - 1);
            if (!isNaN(property)) formula = formula.replace(`{${property}}`, `@(field:${property})`);
            else formula = formula.replace(`{${property}}`, `@(field:${fieldSuggestions.find(suggestion => suggestion.value === property)?.id})`);
        });

        this.setState({
            customField: {
                ...(
                    customField ? {
                        ...customField,
                        maxLength: convertNumberToString(customField.maxLength),
                        min: convertNumberToString(customField.min),
                        max: convertNumberToString(customField.max),
                        step: convertNumberToString(customField.step),
                        minDate: customField.minDate ? new Date(customField.minDate) : customField.minDate,
                        maxDate: customField.maxDate ? new Date(customField.maxDate) : customField.maxDate,
                        formula
                    } : JSON.parse(JSON.stringify({ ...initialCustomField, customFieldCategoryId: defaultCategory || 0 }))
                ),
                organizationId: !isAdmin ? activeOrganization.id : null,
                isGlobal: isAdmin
            },
            tags
        }, () => { // Pour pouvoir ajouter de nouvelle valeur en cas d'édition d'un CF de type liste
            if (this.state.customField.dropdownCustomFieldValues && this.state.customField.dropdownCustomFieldValues.at(-1)?.label !== '') {
                this.setState(prevState => ({
                    customField: {
                        ...prevState.customField,
                        dropdownCustomFieldValues: [...prevState.customField.dropdownCustomFieldValues, { label: '', value: '' }]
                    }
                }));
            }
        });
    }

    handleChange = (_, { name, value }) => {
        this.setState(prevState => ({
            customField: { ...prevState.customField, [name]: value, formula: name === 'category' ? '' : prevState.customField.formula },
            error: { ...prevState.error, [name]: false }
        }));
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState({ customField: { ...this.state.customField, [name]: checked } });
    }

    handleDropdownValueChange = (name, value, index) => {
        this.setState(prevState => {
            const customField = { ...prevState.customField };
            customField.dropdownCustomFieldValues[index][name] = value;
            if ((name === 'value' || !this.state.customField.isNumeric)
                && index === customField.dropdownCustomFieldValues.length - 1)
                customField.dropdownCustomFieldValues.push({ label: '', value: '' });
            return { customField };
        });
    }

    handleFormulaChange = (_, value, plainValue) => {
        const guidRegExp = new RegExp('({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}');
        const operationMention = value.match(/@\(operation[^ )]+\)/g)?.[0];

        let cursorPosition = -1;
        if (operationMention) {
            const matchingSuggestion = operationSuggestions.find(suggestion => suggestion.id === operationMention.match(guidRegExp)?.[0]);
            const replacementString = `${matchingSuggestion?.value || operationMention}${matchingSuggestion?.addWhitespace ? ' ' : ''}`;
            value = value.replace(operationMention, replacementString);
            if (matchingSuggestion?.value && matchingSuggestion.value.slice(-1) === ')')
                cursorPosition = plainValue.lastIndexOf(matchingSuggestion.value) + matchingSuggestion.value.length;
            else cursorPosition = this.inputRef.current.selectionStart + replacementString.length;
        } else {
            let i = 0;
            let match = value.match(new RegExp('(?<!^)([^ ]{1}){{1}'));
            while (match) {
                i++;
                value = value.slice(0, match.index + 1) + ' ' + value.slice(match.index + 1, value.length);
                match = value.match(new RegExp('(?<!^)([^ ]{1}){{1}'));
            }
            if (i) cursorPosition = this.inputRef.current.selectionStart + (i + 1);
        }

        this.setState(prevState => ({
            customField: { ...prevState.customField, formula: value },
            error: { ...prevState.error, value: false }
        }), () => {
            if (cursorPosition !== -1)
                this.inputRef.current.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
        });
    }

    getChildInstance = (customField) => { // Inutile pour l'instant car le back ajuste déjà l'objet mais permettra l'offline
        if (customField.category !== 'Arbre') {
            customField.forEmpty = false;
            customField.forDead = false;
            customField.forStump = false;
            customField.trunksTarget = null;
            customField.trunksOperation = null;
        }
        if (customField.type === 'text')
            if (customField.maxLength.trim()) customField.maxLength = Number(customField.maxLength);
            else customField.maxLength = null;
        else delete customField.maxLength;
        if (customField.type === 'number') {
            if (customField.min.trim()) customField.min = Number(customField.min);
            else customField.min = null;
            if (customField.max.trim()) customField.max = Number(customField.max);
            else customField.max = null;
            customField.step = Number(customField.step);
        } else {
            delete customField.min;
            delete customField.max;
            delete customField.step;
        }
        if (customField.type === 'formula') {
            let value = this.inputRef.current.value;
            this.inputRef.current.value.match(/\{(.+?)\}/g)?.forEach(property => {
                property = property.slice(1, property.length - 1);
                if (property.includes('|')) value = value.replace(property, property.split('|')[0]);
                else value = value.replace(property, fieldSuggestions.find(suggestion => suggestion.display === property)?.value);
            });
            customField.formula = value;
        } else {
            delete customField.formula;
            delete customField.trunksTarget;
            delete customField.trunksOperation;
        };
        if (customField.type !== 'date') {
            delete customField.minDate;
            delete customField.maxDate;
        }
        if (customField.type === 'list') {
            if (customField.isNumeric) customField.dropdownCustomFieldValues = customField.dropdownCustomFieldValues.filter(dcfv => dcfv.label.trim() && dcfv.value.trim())
            else customField.dropdownCustomFieldValues = customField.dropdownCustomFieldValues
                .filter(dcfv => dcfv.label.trim())
                .map(dcfv => ({ ...dcfv, value: dcfv.label }));
        } else {
            delete customField.isMultiple;
            delete customField.isNumeric;
            delete customField.dropdownCustomFieldValues;
        }

        return customField;
    }

    getNumberCondition = (value) => (
        !value && value !== '' ? ''
            : value.includes('<') ? 'lower'
                : value.includes('-') ? 'between'
                    : value.includes('>') ? 'greater'
                        : 'equal'
    );

    getNumberValue = (key, index) => {
        if (!key) return '';
        if (key.includes('-')) return key.split('-')[index] || '';
        return key.replace(/[<->]/g, () => '');
    };

    handleFormatConditionChange = (formatIndex, value) => {
        value = value === 'lower' ? '<'
            : value === 'equal' ? ''
                : value === 'between' ? '-'
                    : '>';

        this.setState(prevState => {
            const formats = [...prevState.customField.formulaCustomFieldFormats];
            const format = formats[formatIndex];
            if (!format) formats.push({ key: value, value: '' });
            else {
                let index = format.key.indexOf('<');
                if (index === -1) index = format.key.indexOf('-');
                if (index === -1) index = format.key.indexOf('>');
                if (index === -1) format.key = value === '-' ? format.key + value : value + format.key;
                else {
                    if (format.key.includes('-')) {
                        const values = format.key.split('-');
                        if ((value === '<' && values[1]) || !values[0]) format.key = value + format.key.slice(index + 1);
                        else format.key = value + format.key.slice(0, index);
                    } else {
                        if (value === '-' && format.key[index] === '>') format.key = format.key.slice(index + 1) + value;
                        else format.key = value + format.key.slice(index + 1);
                    }
                }
            }
            return { customField: { ...prevState.customField, formulaCustomFieldFormats: formats } };
        });
    };

    handleFormatKeyChange = (formatIndex, value, valueIndex) => {
        this.setState(prevState => {
            const formats = [...prevState.customField.formulaCustomFieldFormats];
            const format = formats[formatIndex];
            let index = format.key.indexOf('<');
            if (index === -1) index = format.key.indexOf('-');
            if (index === -1) index = format.key.indexOf('>');
            if (index === -1) format.key = value;
            else {
                if (format.key.includes('-')) {
                    if (valueIndex) format.key = format.key.slice(0, index + 1) + value;
                    else format.key = value + format.key.slice(index, format.key.length);
                } else format.key = format.key[index] + value;
            }
            return { customField: { ...prevState.customField, formulaCustomFieldFormats: formats } };
        });
    };

    handleFormatValueChange = (formatIndex, value) => {
        this.setState(prevState => {
            const formats = [...prevState.customField.formulaCustomFieldFormats];
            const format = formats[formatIndex];
            format.value = value;
            return { customField: { ...prevState.customField, formulaCustomFieldFormats: formats } };
        });
    };

    handleSubmit = () => {
        const { isCreating, isAdmin } = this.props;
        let customField = this.state.customField;
        let isValid = true;
        let error = JSON.parse(JSON.stringify(initialError));

        const addError = (property) => {
            error[property] = true;
            isValid = false;
        };

        if (!customField.label?.trim() || (isAdmin && this.props.customFields.find(cf => cf.label === customField.label?.trim()))) addError('label');
        if (!customField.type) addError('type');
        if (!customField.category) addError('category');
        if (customField.description?.length > 1000) addError('description');
        if (customField.type === 'text' && Number(customField.maxLength) < 1) addError('maxLength');
        if (customField.type === 'number') {
            if (customField.min && customField.max && Number(customField.min) >= Number(customField.max)) {
                addError('min'); addError('max');
            }
            if (!customField.step.trim() || Number(customField.step) < 0) addError('step');
        }
        if (customField.type === 'formula' && !customField.formula?.trim()) addError('formula');
        if (customField.type === 'date') {
            if (customField.minDate && customField.minDate.getFullYear().toString().length !== 4) addError('minDate');
            if (customField.maxDate && customField.maxDate.getFullYear().toString().length !== 4) addError('maxDate');
            if (customField.minDate && customField.maxDate && customField.minDate >= customField.maxDate) {
                addError('minDate'); addError('maxDate');
            }
        }
        if (customField.type === 'list') {
            // Si aucun choix valide n'est présent
            if (!customField.dropdownCustomFieldValues.some(dcfv => dcfv.label && (dcfv.value || !customField.isNumeric))) {
                if (customField.isNumeric) error.dcfvValues.push(0)
                else error.dcfvLabels.push(0);
                isValid = false;
            }
            // Si un choix n'a pas de valeur
            customField.dropdownCustomFieldValues.forEach((dcfv, index) => {
                if (customField.isNumeric && dcfv.label.trim() && !dcfv.value?.trim()) {
                    error.dcfvValues.push(index);
                    isValid = false;
                }
            });
            // Si deux choix ont le même libellé
            customField.dropdownCustomFieldValues.forEach((dcfv1, index1) => {
                customField.dropdownCustomFieldValues.filter(dcfv => dcfv !== dcfv1).forEach((dcfv2, index2) => {
                    if (dcfv1.label.trim() && dcfv2.label.trim() && dcfv1.label.trim() === dcfv2.label.trim()) {
                        error.dcfvLabels.push(index1);
                        error.dcfvLabels.push(index2);
                        isValid = false;
                    }
                });
            });
        }

        if (isValid) {
            if (customField.type === 'formula')
                customField.formulaCustomFieldFormats = customField.formulaCustomFieldFormats.filter(format => format.key?.replace(/[-<>]/g, '').trim() ? true : false);

            customField.customFieldCategoryId = !customField.customFieldCategoryId ? null : customField.customFieldCategoryId;
            customField = this.getChildInstance(customField);
            this.setState({ isLoading: true });
            const callback = (customField) => {
                if (customField) {
                    if (isAdmin) {
                        this.setState({ customField: JSON.parse(JSON.stringify(initialCustomField)), error: JSON.parse(JSON.stringify(initialError)), isLoading: false });
                        this.props.setCustomFields([...this.props.customFields, customField]);
                    } else {
                        this.props.updateCustomFieldList(customField);
                        this.props.cancel();
                    }
                } else this.setState({ isLoading: false });
            };
            if (isCreating) CustomFieldsService.addCustomField(customField).then(callback);
            else CustomFieldsService.updateCustomFields([customField]).then((customFields) => callback(customFields?.[0]));
        } else this.setState({ error });
    }

    handleTagsChange = (_, { value }) => {
        const tags = value.map(t => t.trim());

        this.setState(prevState => ({
            customField: {
                ...prevState.customField,
                tags
            },
            tags: [...new Set([...prevState.tags, ...tags])]
        }));
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        customFields: state.customFields,
        organizationCustomFields: state.organizationCustomFields,
        activeOrganization: state.activeOrganization
    };
};

const mapDispatchToProps = {
    setCustomFields
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldForm);