import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Composants
import { Button, Form, Message } from 'semantic-ui-react'
import PasswordInput from './PasswordInput';
// Librairies
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setRedirectURL } from '../../../actionCreators/appActions';
// Services
import UsersService from '../../../services/UsersService';
import ProjectsService from '../../../services/ProjectsService';
import FormattersUtil from '../../../utils/FormattersUtil';
// Styles
import 'semantic-ui-css/semantic.min.css';

const initialError = {
    hidden: true,
    messages: [],
    email: false,
    password: false
}

class LoginForm extends Component {
    state = {
        email: '',
        password: '',
        error: initialError,
        emailNotActivated: '',
        isLoading: false
    };

    render() {
        const { email, password, isLoading, error } = this.state;
        return (
            <Form size='large' style={{ borderRadius: '5px', flex: 1 }} loading={isLoading} error>
                <Form.Input
                    fluid icon='mail' iconPosition='left' placeholder={i18n.t("Adresse e-mail")}
                    name='email' value={email} onChange={this.handleChange} error={error.email}
                />
                <PasswordInput name='password' value={password} handleChange={this.handleChange} error={error.password} handleSubmit={this.handleSubmit} style={{ margin: '10px 0' }} />
                <Button color='green' fluid size='large' onClick={this.handleSubmit}>
                    {i18n.t("Se connecter")}
                </Button>
                <Message
                    error style={{ textAlign: 'left' }} hidden={error.hidden}
                    header='Erreur' list={error.messages}
                    content={error.messages[0] === i18n.t("Le compte n'a pas été activé") &&
                        <Link to='/login' onClick={this.resendActivationLink} style={{ textDecoration: 'underline' }}>
                            {i18n.t("Renvoyer le lien d'activation")}
                        </Link>}
                />
                <Message
                    info style={{ textAlign: 'left' }}
                    hidden={!new Cookies().get('invitationId') || !error.hidden}
                    header={i18n.t("Invitation")}
                    content={i18n.t("En vous connectant, vous serez automatiquement ajouté au projet dans lequel vous avez été invité !")}
                />
            </Form>
        );
    }

    componentDidMount = () => {
        if (this.props.match.params.token) {
            this.setState({ isLoading: true });
            UsersService.loginWithUrbasenseToken(this.props.match.params.token).then(response => {
                this.setState({ isLoading: false });
                if (response?.token) {
                    this.props.logUser(response.token);
                    this.props.changeActiveItem('home');
                    this.props.history.push('/');
                }
                // else if (response?.loginResponse) this.props.submit(response);
                else {
                    let messages = [i18n.t("Une erreur est survenue")];
                    if (response?.status === 400) messages = [response.data];
                    this.setState({ error: { hidden: false, messages, email: true, password: true } });
                }
            });
        }
    }

    componentWillUnmount = () => {
        if (this.props.redirectURL)
            this.props.setRedirectURL(null);
    }

    handleChange = (_, { name, value }) => {
        this.setState({
            [name]: value,
            error: initialError
        });
    };

    handleSubmit = async (e) => {
        const { redirectURL } = this.props;
        const { email, password } = this.state;
        let flag = true;
        let messages = [];
        let errors = { ...initialError };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            flag = false;
        }

        // Vérifications
        if (email.length === 0 || !FormattersUtil.checkEmail(email))
            addError('email', i18n.t("L'adresse email est invalide"));

        if (!password)
            addError('password', i18n.t("Le mot de passe ne peut être vide"));

        // Si toutes les vérifications sont bonnes
        if (flag) {
            // On tente de connecter l'utilisateur
            this.setState({ isLoading: true });
            const login = {
                email: email.trim(),
                password: password.trim()
            };

            UsersService.login(login).then(response => {
                this.setState({ isLoading: false });
                if (response?.token) { // Si la réponse est favorable
                    if (new Cookies().get('invitationId')) { // Si un cookie d'invitation est défini
                        ProjectsService.addCollaboratorWithInvitation(new Cookies().get('invitationId'), { userId: jwt_decode(response.token).id }); // On ajoute l'utilisateur au projet
                        new Cookies().remove('invitationId', { path: '/' });
                    }
                    this.props.logUser(response.token);
                    this.props.changeActiveItem('home');
                    if (redirectURL) this.props.history.push(redirectURL);
                    else this.props.history.push('/');
                } else { // Si la réponse n'est pas favorable
                    var message = [i18n.t("Une erreur est survenue")];
                    if (response?.status === 400) {
                        message = [response.data];
                        if (response.data === i18n.t("Le compte n'a pas été activé")) this.setState({ emailNotActivated: this.state.email });
                    }
                    this.setState({ // On affiche un message d'erreur
                        error: {
                            hidden: false,
                            messages: message,
                            email: true,
                            password: true
                        }
                    });
                }
            });
        } else { // Si les vérifications ne sont pas bonnes, on affiche des messages d'erreurs
            this.setState({
                error: {
                    hidden: false,
                    messages: messages,
                    email: errors.email,
                    password: errors.password
                }
            });
        }
    };

    resendActivationLink = (e) => {
        UsersService.resendActivationLink(this.state.emailNotActivated).then(() => {
            this.setState({ error: initialError });
        });
    }
}

const mapStateToProps = (state) => ({
    redirectURL: state.redirectURL
});

const mapDispatchToProps = {
    setRedirectURL
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);