import React, { Component } from 'react';
// Composants
import { Menu, Item, Submenu } from 'react-contexify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

class ContextMenu extends Component {
    render() {
        const { onVisibilityChange } = this.props;

        return (
            <Menu id={this.props.id} theme={this.props.isDarkTheme ? 'dark' : 'light'} animation={false} onVisibilityChange={onVisibilityChange}>{this.renderItems(0, this.props.items)}</Menu>
        );
    }

    renderItems = (index, items) => {
        if (!items?.length) return null;
        return items.map((item, id) => {
            if (!item.isVisible || item.isVisible()) {
                return item.children
                    ? <Submenu key={id} label={item.label} disabled={item.isDisabled}>{this.renderItems(index + 1, item.children)}</Submenu>
                    :
                    <Item key={id} onClick={item.onClick} disabled={item.isDisabled}>
                        <div className={item.className} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                            {item.icon && <FontAwesomeIcon icon={item.icon} style={{ marginRight: '10px', width: '15px' }} />}
                            <span className={item.className || ''}>{item.label}</span>
                            {item.isChecked && item.isChecked() && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '10px' }} />}
                        </div>
                        {item.shortcut && <div style={{ marginLeft: 'auto', color: 'var(--grey-100)' }}>{item.shortcut}</div>}
                    </Item>;
            } else return null;
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
}

export default connect(mapStateToProps)(ContextMenu);