import AppSettings from '../AppSettings';
// Librairies
import Cookies from 'universal-cookie';
import Axios from 'axios';
// Services
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class BackgroundTasksService {
    static getLatestBackgroundTasks() {
        return Axios.get(BACKEND_ENDPOINT + 'backgroundTasks/latest/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            let backgroundTasks = response.data;
            // Gestion du local storage
            let backgroundTasksLS = localStorage.getItem('backgroundTasks');
            if (!backgroundTasksLS) {
                backgroundTasksLS = JSON.stringify([]);
                localStorage.setItem('backgroundTasks', backgroundTasksLS);
            }
            backgroundTasksLS = JSON.parse(backgroundTasksLS);
            // Par défaut elles sont non lues
            backgroundTasks.forEach(backgroundTask => {
                backgroundTask['read'] = false;
            });
            // On vérifie dans le cookie lesquelles ont été lues
            backgroundTasksLS.forEach(backgroundTaskCookie => {
                backgroundTasks.forEach(backgroundTask => {
                    if (backgroundTaskCookie === backgroundTask.id) backgroundTask['read'] = true;
                });
            });
            // On les tries par date et on garde les backgroundTasks qui ont 30 jours ou moins
            return backgroundTasks.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                .filter(x => (new Date().getTime() - new Date(x.date).getTime()) / (1000 * 60 * 60 * 24) <= 30);
        }, () => {
            if (navigator.onLine) showToast('background_tasks_loading_failed');
            return;
        });
    }
}