import React, { Component } from 'react';
// Composants
/*     Editors     */
import BooleanEditor from '../Editors/BooleanEditor';
import DateEditor from '../Editors/DateEditor';
import DropDownEditor from '../Editors/DropDownEditor';
import NumberEditor from '../Editors/NumberEditor';
import TextEditor from '../Editors/TextEditor';
/*     Filters     */
import DropDownFilter from '../../Tables/Filters/DropDownFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
import TextFilter from '../../Tables/Filters/TextFilter';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Segment, Label } from 'semantic-ui-react';
// Services
import TreesService from '../../../services/TreesService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';

const initialFilters = {
    order: '',
    vernacularName: '',
    gender: '',
    species: '',
    cultivar: '',
    typeCoefficient: '',
    silhouetteCoefficient: '',
    speciesCoefficient: '',
    coolingCoefficient: '',
    density: '',
    fruitProduction: '',
    maxHeight: '',
    maxCircumference: '',
    maxCrownDiameter: '',
    growthIndex: '',
    biodiversityIndex: '',
    circumferencePerYear: ''
};

class EssenceTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        elementsToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false,
        orders: []
    }

    render() {
        const {
            data, elementsToModify, elementsToDelete, modificationsHistory, modificationsHistoryIndex,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isLoading
        } = this.state;
        const rows = this.getFilteredRows();

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <Dimmer active={isUpdating} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Mise à jour des essences en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des essences")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                        <Button
                                            title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                            onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1 && elementsToDelete.length < 1}
                                        />
                                    </>
                                </Button.Group>
                            </Menu.Item>
                            {elementsToDelete.length > 0 &&
                                <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                    <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + elementsToDelete.length} />
                                </Menu.Item>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                            <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        this.setState({ orders: [...new Set(this.props.essences.map(essence => essence.order))] });

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Ordre"), key: 'order', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={this.state.orders.map(order => ({ label: order }))} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor(
                    'dropdown', row, column, onRowChange, onClose,
                    { options: this.state.orders.map(order => ({ label: order, value: order })) }
                ),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Nom vernaculaire"), key: 'vernacularName', width: 180,
                sortable: true,
                formatter: (props) => {
                    return !props.row.gender
                        ? (<div className='disabled'></div>)
                        : props.row.vernacularName
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editable: (props) => props.gender ? true : false, // 'true : false' nécessaire sinon condition pas fonctionnelle
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Genre"), key: 'gender', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Espèce"), key: 'species', width: 180,
                sortable: true,
                formatter: (props) => {
                    return !props.row.gender
                        ? (<div className='disabled'></div>)
                        : props.row.species
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editable: (props) => props.gender ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Cultivar"), key: 'cultivar', width: 180,
                sortable: true,
                formatter: (props) => {
                    return !props.row.gender || !props.row.species
                        ? (<div className='disabled'></div>)
                        : props.row.cultivar
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editable: (props) => props.gender && props.species ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Coefficient de type"), key: 'typeCoefficient', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, max: 10, step: 0.01 })
            },
            {
                name: i18n.t("Coefficient de silhouette"), key: 'silhouetteCoefficient', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, max: 20 })
            },
            {
                name: i18n.t("Coefficient d'espèce"), key: 'speciesCoefficient', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, max: 5, step: 0.1 })
            },
            {
                name: i18n.t("Coefficient de rafraîchissement"), key: 'coolingCoefficient', width: 240,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, max: 10 })
            },
            {
                name: i18n.t("Densité"), key: 'density', width: 110,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 100, max: 1000 })
            },
            {
                name: i18n.t("Production de fruits"), key: 'fruitProduction', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, max: 1000 })
            },
            {
                name: i18n.t("Hauteur maximale") + ' (m)', key: 'maxHeight', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Circonférence maximale") + ' (cm)', key: 'maxCircumference', width: 230,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Diamètre de couronne maximal") + ' (m)', key: 'maxCrownDiameter', width: 265,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Indice de croissance"), key: 'growthIndex', width: 170,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Potentiel de biodiversité"), key: 'biodiversityIndex', width: 180,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, max: 5 })
            },
            {
                name: i18n.t("Croissance en circonférence") + ' (cm/an)', key: 'circumferencePerYear', width: 265,
                sortable: true, editable: true,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose)
            }
        ];

        // Ajout des données
        TreesService.getEssences().then(essences => {
            data.rows = essences
                .map((essence) => {
                    return {
                        id: essence.id,
                        order: essence.order,
                        vernacularName: essence.vernacularName,
                        gender: essence.gender,
                        species: essence.species,
                        cultivar: essence.cultivar,
                        typeCoefficient: essence.typeCoefficient,
                        silhouetteCoefficient: essence.silhouetteCoefficient,
                        speciesCoefficient: essence.speciesCoefficient,
                        coolingCoefficient: essence.coolingCoefficient,
                        density: essence.density,
                        fruitProduction: essence.fruitProduction,
                        maxHeight: essence.maxHeight,
                        maxCircumference: essence.maxCircumference,
                        maxCrownDiameter: essence.maxCrownDiameter,
                        growthIndex: essence.growthIndex,
                        biodiversityIndex: essence.biodiversityIndex,
                        circumferencePerYear: essence.circumferencePerYear
                    };
                })
                .sort((a, b) => { // On trie par ordre alphabétique
                    const orderA = a.order?.toUpperCase() || ''; const orderB = b.order?.toUpperCase() || '';
                    const vernacularNameA = a.vernacularName?.toUpperCase() || ''; const vernacularNameB = b.vernacularName?.toUpperCase() || '';
                    const genderA = a.gender?.toUpperCase() || ''; const genderB = b.gender?.toUpperCase() || '';
                    const speciesA = a.species?.toUpperCase() || ''; const speciesB = b.species?.toUpperCase() || '';
                    const cultivarA = a.cultivar?.toUpperCase() || ''; const cultivarB = b.cultivar?.toUpperCase() || '';
                    return (orderA < orderB) ? -1 :
                        (orderA > orderB) ? 1 :
                            (vernacularNameA < vernacularNameB) ? -1 :
                                (genderA < genderB) ? -1 :
                                    (genderA > genderB) ? 1 :
                                        (speciesA < speciesB) ? -1 :
                                            (speciesA > speciesB) ? 1 :
                                                (cultivarA < cultivarB) ? -1 :
                                                    (cultivarA > cultivarB) ? 1 : 0;
                });

            const elements = JSON.parse(JSON.stringify(data.rows));

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data: data, elements: elements, initialOrder: initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.order ? $(r.order)?.includes($(filters.order)) : true)
                && (filters.vernacularName ? $(r.vernacularName)?.includes($(filters.vernacularName)) : true)
                && (filters.gender ? $(r.gender)?.includes($(filters.gender)) : true)
                && (filters.species ? $(r.species)?.includes($(filters.species)) : true)
                && (filters.cultivar ? $(r.cultivar)?.includes($(filters.cultivar)) : true)
                && (filters.typeCoefficient ? r.typeCoefficient === Number(filters.typeCoefficient) : true)
                && (filters.silhouetteCoefficient ? r.silhouetteCoefficient === Number(filters.silhouetteCoefficient) : true)
                && (filters.speciesCoefficient ? r.speciesCoefficient === Number(filters.speciesCoefficient) : true)
                && (filters.coolingCoefficient ? r.coolingCoefficient === Number(filters.coolingCoefficient) : true)
                && (filters.density ? r.density === Number(filters.density) : true)
                && (filters.fruitProduction ? r.fruitProduction === Number(filters.fruitProduction) : true)
                && (filters.maxHeight ? r.maxHeight === Number(filters.maxHeight) : true)
                && (filters.maxCircumference ? r.maxCircumference === Number(filters.maxCircumference) : true)
                && (filters.maxCrownDiameter ? r.maxCrownDiameter === Number(filters.maxCrownDiameter) : true)
                && (filters.growthIndex ? r.growthIndex === Number(filters.growthIndex) : true)
                && (filters.biodiversityIndex ? r.biodiversityIndex === Number(filters.biodiversityIndex) : true)
                && (filters.circumferencePerYear ? r.circumferencePerYear === Number(filters.circumferencePerYear) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (['typeCoefficient', 'silhouetteCoefficient', 'speciesCoefficient', 'coolingCoefficient', 'density', 'fruitProduction',
                'maxHeight', 'maxCircumference', 'maxCrownDiameter', 'growthIndex', 'biodiversityIndex', 'circumferencePerYear'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete = elementsToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }
            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)
                    || previousModification[0].property === 'delete') {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties
                || nextModification[0].property === 'delete') {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) { // Pas besoin de mapping pour les essences
            default: return value;
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getPropertyValue(columnKey, sourceRow[columnKey]);;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (e, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => {
        return {
            ...element
            // Pas besoin de mapping pour les essences
        };
    }

    deleteRow = (_, { rowIdx }) => {
        let elementsToDelete = this.state.elementsToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        elementsToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: rowIndex, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, elementsToDelete, selectedRow: null, selectedColumn: null });
    }

    handleSubmit = () => {
        let { elementsToModify, elementsToDelete, elements } = this.state;
        let elementsNotToModifyAnymore = [];

        elementsToModify.forEach(elementToModify => {
            elementToModify.vernacularName = elementToModify.gender ? elementToModify.vernacularName : null;
            elementToModify.species = elementToModify.gender ? elementToModify.species : null;
            elementToModify.cultivar = elementToModify.gender && elementToModify.species ? elementToModify.cultivar : null;
            if (JSON.stringify(elementToModify) === JSON.stringify(this.state.elements.find(element => element.id === elementToModify.id))
                || elementsToDelete.find(element => element.id === elementToModify.id))
                elementsNotToModifyAnymore.push(elementToModify);
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        if (elementsToModify.length > 0 || elementsToDelete.length > 0) {
            this.setState({ isUpdating: true });
            let promises = [];
            if (elementsToModify.length > 0)
                promises.push(new Promise(resolve => {
                    TreesService.updateEssences(elementsToModify).then(response => {
                        if (response === 200) {
                            elementsToModify.forEach(elementToModify => {
                                const index = elements.findIndex(element => element.id === elementToModify.id);
                                elements[index] = elementToModify;
                            });
                            this.setState({ elementsToModify: [] }, () => resolve());
                        }
                    });
                }));

            if (elementsToDelete.length > 0)
                promises.push(new Promise(resolve => {
                    TreesService.deleteEssences(elementsToDelete.map(element => element.id)).then(response => {
                        if (response === 200) {
                            elements = elements.filter(element => !elementsToDelete.find(elementToDelete => elementToDelete.id === element.id));
                            this.setState({ elementsToDelete: [] }, () => resolve());
                        }
                    });
                }));

            Promise.all(promises).then(() => {
                this.setState({ elements: elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
            });
        } else {
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('essences_updated');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        essences: state.essences
    };
};

export default connect(mapStateToProps)(EssenceTable);