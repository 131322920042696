import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input } from 'semantic-ui-react';
// Librarires
import { lineString, length } from '@turf/turf';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { setLayer } from '../../../../actionCreators/elementsActions';
import { faTimes, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
// Utils
import GeometriesUtil from '../../../../utils/GeometriesUtil';

const initialError = {
    hidden: true,
    messages: [],
    width: false
}

class LineForm extends Component {
    state = {
        isInside: true,
        baseLine: null,
        width: 1,
        length: 0,
        error: initialError
    }

    render() {
        const { layer, currentAction, isToolbarExpanded, drawingSegments } = this.props;
        const { isInside, width, length, error } = this.state;

        return (
            <div className='tool-form' style={{ left: isToolbarExpanded && !isMobileOnly ? '305px' : '45px', transition: 'left 500ms' }}>
                <Form error style={{ width: '100%' }}>
                    <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px' }}>
                        <Form.Field control={Input} fluid type='number' label={`${i18n.t("Longueur")}* (m) :`} value={length} />
                        <Form.Field
                            control={Input} fluid type='number' label={`${i18n.t("Largeur")}* (m) :`} step='0.1' error={error.width || !isInside}
                            name='width' value={width >= 0 ? width : ''}
                            onChange={this.handleWidthChange} disabled={!layer ? true : false}
                        />
                    </div>
                    <div>
                        {currentAction !== 'editing' &&
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                <Button
                                    color='red' type='button' title={i18n.t("Annuler")} size='small'
                                    style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                    onClick={() => this.props.hideForm(false)}
                                >
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button
                                    id='ED26aPoB' type='button' color='green' title={i18n.t("Valider")} size='small'
                                    style={{ display: 'inline-flex', justifyContent: 'center', width: '50%' }}
                                    disabled={!isInside || !layer} onClick={this.handleSubmit}
                                >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </div>}
                    </div>
                </Form>
            </div>
        );
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.baseLine === null && this.props.layer !== null && this.props.layer.getLatLngs) {
            const baseLine = GeometriesUtil.convertLineLatLngsToCoordinates(this.props.layer.getLatLngs());
            this.setState(prevState => ({
                width: this.props.layer.linkedPolygon?.feature.properties.baseLine.width || prevState.width,
                baseLine, maxWidth: Math.round((length(lineString(baseLine), { units: 'meters' }) / 4) * 10) / 10
            }), () => {
                const { baseLine, width } = this.state;
                this.props.setLineDimensions(baseLine, width, this.props.layer.linkedPolygon);
            });
        }

        if (prevProps.drawingSegments !== this.props.drawingSegments && this.props.drawingSegments?.length > 0)
            this.setState({ length: Math.round(this.props.drawingSegments.reduce((prevValue, segment) => prevValue += segment, 0) * 100) / 100 });
    }

    handleSubmit = () => {
        const { layer } = this.props;
        let isValid = true;
        let error = { ...initialError.error, messages: [] };
        const { width, maxWidth, baseLine } = this.state;

        const addError = (property) => {
            isValid = false;
            error[property] = true;
            error.hidden = false;
        }

        if (width < 0.05) {
            addError('width');
            error.messages.push(i18n.t("La largeur ne peut être inférieure à 0.01m"));
        } else if (width > maxWidth) {
            addError('width');
            error.messages.push(i18n.t("La largeur du polygone ne peut dépasser la moitié de la longueur"));
        }

        if (isValid) {
            layer.baseLine = {
                coordinates: baseLine,
                width: width
            };
            this.props.setLayer(layer);
            this.props.changeModalContentType('GreenSpaceForm', i18n.t("Ajout d'un nouvel élément"));
        } else this.setState({ error: error });
    }

    handleWidthChange = (_, { name, value }) => {
        let { baseLine, maxWidth } = this.state;
        let error = false, isInside = false;;

        if (this.props.currentAction === 'editing') {
            baseLine = GeometriesUtil.convertLineLatLngsToCoordinates(this.props.layer.getLatLngs());
            if (JSON.stringify(baseLine) !== JSON.stringify(this.state.baseLine)) {
                this.pushedToHistory = false;
                this.setState({
                    baseLine, maxWidth: Math.round((length(lineString(baseLine), { units: 'meters' }) / 4) * 10) / 10
                });
            }
        }

        if (value >= 0.05 && value <= maxWidth) {
            if (this.props.currentAction === 'editing') {
                if (!this.pushedToHistory) {
                    this.pushedToHistory = true;
                    this.props.pushActionHistory([{ layer: this.props.layer.linkedPolygon }]);
                }
                this.props.layer.linkedPolygon.feature.properties.baseLine.width = value;
                this.props.layer.pm._layerEdited = true;
            }
            isInside = this.props.setLineDimensions(baseLine, value, this.props.layer.linkedPolygon);
        } else error = true;
        this.setState(prevState => ({ isInside: isInside, width: value, error: { ...prevState.error, [name]: error } }));
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        currentAction: state.currentAction,
        isToolbarExpanded: state.isToolbarExpanded
    };
};

const mapDispatchToProps = {
    setLayer
}

export default connect(mapStateToProps, mapDispatchToProps)(LineForm);