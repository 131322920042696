// Librairies
import 'leaflet-draw';
// Services
import GeoJsonUtil from './GeoJsonUtil';
import TreesService from '../services/TreesService';
import GreenSpacesService from '../services/GreenSpacesService';
import FurnituresService from '../services/FurnituresService';
import MarkersService from '../services/MarkersService';
import StationsService from '../services/StationsService';
import BackgroundImagesService from '../services/BackgroundImagesService';
// Utils
import StylesUtil from './StylesUtil';

export default class UpdatesUtil {
    // Ce service permet de faire les modifications nécessaires du côté front ainsi que d'appeler le service se chargeant de faire la requête à l'API
    static updateTree(updatedLayer, properties, layers, history, fieldList, { activeChild, activeOptions }, action, projectId, webSocketHubs, { replanting = false, thematicMaps = null } = {}) {
        let id = updatedLayer.feature.id;
        let feature = GeoJsonUtil.generateMarkerFeature(properties, updatedLayer._latlng, projectId);

        layers.forEach(layer => {
            layer.feature = feature;
            layer.feature.id = id;
            layer._latlng = updatedLayer._latlng;
        });

        return TreesService.updateTree(id, feature, history, action, replanting, webSocketHubs).then(response => {
            layers.forEach(layer => {
                const highlightStyle = StylesUtil.getTreeHighlightStyle({ activeChild, activeOptions }, fieldList, layer.feature.properties);
                if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layer.options[property]))
                    layer.setStyle(StylesUtil.getTreeActiveStyle({ activeChild, activeOptions }, fieldList, layer.feature.properties, thematicMaps));
            });
            return response;
        });
    }

    static bulkUpdateTrees(layersArray, modifiedFeatures, fieldList, projectId, { activeChild, activeOptions }, action, webSocketHubs, { thematicMaps = null }) {
        let treesToSend = [];

        for (let i = 0; i < layersArray.length; i++) {
            const id = layersArray[i][0].feature.id;
            const latlng = { lng: modifiedFeatures[i].geometry.coordinates[0], lat: modifiedFeatures[i].geometry.coordinates[1] }
            let feature = GeoJsonUtil.generateMarkerFeature(modifiedFeatures[i].properties, latlng, projectId);
            treesToSend.push(feature);

            for (let j = 0; j < layersArray[i].length; j++) {
                layersArray[i][j].feature = feature;
                layersArray[i][j].feature.id = id;
                layersArray[i][j]._latlng = layersArray[i][1]._latlng;
            }
        }

        return TreesService.bulkUpdateTrees(treesToSend, action, webSocketHubs).then(response => {
            for (let i = 0; i < layersArray.length; i++)
                for (let j = 0; j < layersArray[i].length; j++) {
                    const highlightStyle = StylesUtil.getTreeHighlightStyle({ activeChild, activeOptions }, fieldList, layersArray[i][j].feature.properties);
                    if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layersArray[i][j].options[property]))
                        layersArray[i][j].setStyle(StylesUtil.getTreeActiveStyle({ activeChild, activeOptions }, fieldList, layersArray[i][j].feature.properties, thematicMaps));
                }
            return response;
        });
    }

    static updateGreenSpace(id, properties, layers, history, fieldList, activeChild, action, projectId, { webSocketHubs = null, showToast = true, thematicMaps = null } = {}) {
        const oldSurface = properties.surface;
        const feature = GeoJsonUtil.generatePolygonFeature(properties, layers[0]._latlngs, projectId);
        const highlightStyle = StylesUtil.getGreenSpaceHighlightStyle();

        layers.forEach(layer => {
            layer.feature = feature;
            layer.feature.id = id;
        });

        if (oldSurface !== feature.properties.surface) history = true;
        return GreenSpacesService.updateGreenSpace(id, feature, history, action, webSocketHubs, showToast).then(response => {
            layers.forEach(layer => {
                if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layer.options[property]))
                    layer.setStyle(StylesUtil.getGreenSpaceActiveStyle(activeChild, fieldList, layer.feature.properties, thematicMaps));
            });
            return response;
        });
    }

    static bulkUpdateGreenSpaces(layersArray, modifiedFeatures, fieldList, projectId, activeChild, action, { webSocketHubs = null, showToast = true, thematicMaps = null } = {}) {
        let greenSpacesToSend = [];
        const highlightStyle = StylesUtil.getGreenSpaceHighlightStyle();

        for (let i = 0; i < layersArray.length; i++) {
            let id = layersArray[i].feature.id;
            const feature = GeoJsonUtil.generatePolygonFeature(modifiedFeatures[i].properties, layersArray[i]._latlngs, projectId);
            greenSpacesToSend.push(feature);

            layersArray[i].feature = feature;
            layersArray[i].feature.id = id;
        }

        return GreenSpacesService.bulkUpdateGreenSpaces(greenSpacesToSend, action, webSocketHubs, showToast).then(response => {
            for (let i = 0; i < layersArray.length; i++)
                if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layersArray[i].options[property]))
                    layersArray[i].setStyle(StylesUtil.getGreenSpaceActiveStyle(activeChild, fieldList, layersArray[i].feature.properties, thematicMaps));
            return response;
        });
    }

    static updateFurniture(updatedLayer, properties, layers, history, fieldList, activeChild, action, projectId, webSocketHubs, { thematicMaps = null }) {
        let id = updatedLayer.feature.id;
        let feature = GeoJsonUtil.generateMarkerFeature(properties, updatedLayer._latlng, projectId);
        const highlightStyle = StylesUtil.getFurnitureHighlightStyle(fieldList);

        layers.forEach(layer => {
            layer.feature = feature;
            layer.feature.id = id;
            layer._latlng = updatedLayer._latlng;
        });

        return FurnituresService.updateFurniture(id, feature, history, action, webSocketHubs).then(response => {
            layers.forEach(layer => {
                if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layer.options[property]))
                    layer.setStyle(StylesUtil.getFurnitureActiveStyle(activeChild, fieldList, layer.feature.properties, thematicMaps));
            });
            return response;
        });
    }

    static bulkUpdateFurnitures(layersArray, modifiedFeatures, fieldList, projectId, activeChild, action, webSocketHubs, { thematicMaps = null }) {
        let furnituresToSend = [];
        const highlightStyle = StylesUtil.getFurnitureHighlightStyle(fieldList);

        for (let i = 0; i < layersArray.length; i++) {
            const id = layersArray[i][0].feature.id;
            const latlng = { lng: modifiedFeatures[i].geometry.coordinates[0], lat: modifiedFeatures[i].geometry.coordinates[1] }
            let feature = GeoJsonUtil.generateMarkerFeature(modifiedFeatures[i].properties, latlng, projectId);
            furnituresToSend.push(feature);

            for (let j = 0; j < layersArray[i].length; j++) {
                layersArray[i][j].feature = feature;
                layersArray[i][j].feature.id = id;
                layersArray[i][j]._latlng = layersArray[i][1]._latlng;
            }
        }

        return FurnituresService.bulkUpdateFurnitures(furnituresToSend, action, webSocketHubs).then(response => {
            for (let i = 0; i < layersArray.length; i++)
                for (let j = 0; j < layersArray[i].length; j++) {
                    if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layersArray[i][j].options[property]))
                        layersArray[i][j].setStyle(StylesUtil.getFurnitureActiveStyle(activeChild, fieldList, layersArray[i][j].feature.properties, thematicMaps));
                }
            return response;
        });
    }

    static updateMarker(updatedLayer, properties, layers, fieldList, action, projectId, webSocketHubs) {
        const id = updatedLayer.feature.id;
        const feature = GeoJsonUtil.generateMarkerFeature(properties, updatedLayer._latlng, projectId);
        const highlightStyle = StylesUtil.getMarkerHighlightStyle(fieldList);

        layers.forEach(layer => {
            layer.feature = feature;
            layer.feature.id = id;
            layer._latlng = updatedLayer._latlng;
        });

        return MarkersService.updateMarker(id, feature, action, webSocketHubs).then(response => {
            layers.forEach(layer => {
                if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layer.options[property]))
                    layer.setStyle(StylesUtil.getMarkerActiveStyle(fieldList));
            });
            return response;
        });
    }

    static bulkUpdateMarkers(layersArray, modifiedFeatures, fieldList, projectId, action, webSocketHubs) {
        const markersToSend = [];
        const highlightStyle = StylesUtil.getMarkerHighlightStyle(fieldList);

        for (let i = 0; i < layersArray.length; i++) {
            const id = layersArray[i].feature.id;
            const latlng = { lng: modifiedFeatures[i].geometry.coordinates[0], lat: modifiedFeatures[i].geometry.coordinates[1] }
            const feature = GeoJsonUtil.generateMarkerFeature(modifiedFeatures[i].properties, latlng, projectId);
            markersToSend.push(feature);

            layersArray[i].feature = feature;
            layersArray[i].feature.id = id;
        }

        return MarkersService.bulkUpdateMarkers(markersToSend, action, webSocketHubs).then(response => {
            for (let i = 0; i < layersArray.length; i++)
                if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layersArray[i].options[property]))
                    layersArray[i].setStyle(StylesUtil.getMarkerActiveStyle(fieldList));
            return response;
        });
    }

    static updateStation(id, properties, layers, action, projectId, { webSocketHubs = null } = {}) {
        const feature = GeoJsonUtil.generatePolygonFeature(properties, layers[0]._latlngs, projectId);
        const highlightStyle = StylesUtil.getStationHighlightStyle();

        layers.forEach(layer => {
            layer.feature = feature;
            layer.feature.id = id;
            if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layer.options[property]))
                layer.setStyle(StylesUtil.getStationActiveStyle());
        });

        return StationsService.updateStation(id, feature, action, webSocketHubs);
    }

    static bulkUpdateStations(layersArray, modifiedFeatures, projectId, action, { webSocketHubs = null } = {}) {
        const stationsToSend = [];
        const highlightStyle = StylesUtil.getStationHighlightStyle();

        for (let i = 0; i < layersArray.length; i++) {
            let id = layersArray[i].feature.id;
            const feature = GeoJsonUtil.generatePolygonFeature(modifiedFeatures[i].properties, layersArray[i]._latlngs, projectId);
            stationsToSend.push(feature);

            layersArray[i].feature = feature;
            layersArray[i].feature.id = id;
            if (Object.keys(highlightStyle).some(property => highlightStyle[property] !== layersArray[i].options[property]))
                layersArray[i].setStyle(StylesUtil.getStationActiveStyle());
        }

        return StationsService.bulkUpdateStations(stationsToSend, action, webSocketHubs);
    }

    static updateBackgroundImage(id, properties, layers, projectId, webSocketHubs) {
        const feature = GeoJsonUtil.generateImageFeature(properties, layers[0].getCorners(), projectId);
        layers.forEach(layer => {
            layer.feature = feature;
            layer.feature.id = id;
            layer.feature.geometry.backgroundImageId = id;
        });

        return BackgroundImagesService.updateBackgroundImage(id, feature, webSocketHubs);
    }

    static bulkUpdateBackgroundImages(layersArray, modifiedFeatures, projectId, webSocketHubs) {
        let backgroundImagesToSend = [];
        for (let i = 0; i < layersArray.length; i++) {
            let id = layersArray[i].feature.id;
            let feature = GeoJsonUtil.generateImageFeature(modifiedFeatures[i].properties, layersArray[i].getCorners(), projectId);
            backgroundImagesToSend.push(feature);

            layersArray[i].feature = feature;
            layersArray[i].feature.id = id;
            layersArray[i].feature.geometry.backgroundImageId = id;
        }

        return BackgroundImagesService.bulkUpdateBackgroudImages(backgroundImagesToSend, webSocketHubs);
    }
}