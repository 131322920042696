import React, { Component } from 'react';
// Composants
import { Radio } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../locales/i18n';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { faFlowerTulip, faTablePicnic, faTree } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
// Utils
import InfoIcon from '../../Utils/InfoIcon';
import RightsUtil from '../../../utils/RightsUtil';

class FormulasForm extends Component {
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: !isMobileOnly ? 'row' : 'column', width: '100%', overflowY: 'scroll' }}>
                {this.renderFormulas()}
            </div>
        );
    }

    renderFormulas = () => {
        const { formulas, rights, loginAsData, project } = this.props;
        const categories = [...new Set(formulas.map(f => f.category))].filter(category => !project?.id || RightsUtil.canRead(rights[category]));

        return categories.map((category, index) => (
            <div
                key={index}
                style={{ display: 'flex', flexDirection: 'column', width: !isMobileOnly ? `${100 / categories.length}%` : '100%' }}
            >
                <h3 style={{ margin: 0 }}>
                    <FontAwesomeIcon icon={this.getCategoryIcon(category)} style={{ marginRight: '10px' }} />
                    {this.getCategoryLabel(category)}
                </h3>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {formulas.filter(formula => formula.category === category).map((formula, index2) => (
                        <div key={index2} className='formula-card' style={{ margin: '5px', width: !isMobile ? '31%' : '100%', borderRadius: '5px', overflow: 'hidden' }}>
                            <div style={{ position: 'relative', display: 'block', height: '150px', width: '100%', overflow: 'hidden' }}>
                                <img src={formula.image} alt={formula.label} style={{ display: 'block', height: '100%', minWidth: '100%' }} />
                                {formula.infoIcon && <InfoIcon content={formula.infoIcon} iconStyle={{ position: 'absolute', top: '10px', right: '10px', marginLeft: '5px' }} />}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                <h4 className={`${formula.isDisabled && 'disabled'}`} style={{ margin: 0 }}>{formula.label}</h4>
                                <Radio
                                    toggle defaultChecked={formula.isChecked && !formula.isDisabled} disabled={formula.isDisabled || loginAsData?.readOnly}
                                    onChange={() => this.props.handleCheckChange(category, formula.id, !formula.isChecked)} style={{ marginLeft: 'auto' }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    }

    getCategoryIcon = (category) => {
        return category === 'trees' ? faTree : category === 'greenSpaces' ? faFlowerTulip : faTablePicnic;
    }

    getCategoryLabel = (category) => {
        return category === 'trees'
            ? i18n.t("Arbres")
            : category === 'greenSpaces'
                ? i18n.t("Espaces verts")
                : i18n.t("Mobilier");
    }
}

const mapStateToProps = (state) => {
    return {
        loginAsData: state.loginAsData,
        rights: state.rights
    };
};

export default connect(mapStateToProps)(FormulasForm);