import React, { Component } from 'react';
// Composants
import { Button, Divider, Form, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
// Ressources
import { faRuler, faSquareDashed, faTimes } from '@fortawesome/pro-solid-svg-icons';

class MeasureForm extends Component {
    render() {
        const { drawingSegments, isToolbarExpanded } = this.props;

        return (
            <>
                <div id='measure-form' className='tool-form' style={{ left: `calc(${(isToolbarExpanded ? 305 : 45) / 2}px + 50%)`, transform: 'translateX(-50%)', top: 'inherit', bottom: '20px', transition: 'left 500ms', zIndex: 999, width: 'fit-content', maxWidth: '50%', overflowX: 'overlay' }}>
                    <Segment id='measure-segment' style={{ padding: '7px 10px', display: 'flex', flexDirection: 'row-reverse' }}>
                        {[...drawingSegments].reverse().map((segment, index) => (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWidth: '65px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <FontAwesomeIcon icon={faRuler} style={{ marginRight: '3px' }} />
                                        <span style={{ fontWeight: 'bold' }}>{drawingSegments.length - index}</span>
                                    </div>
                                    <span>{`${Math.round(segment * 100) / 100}m`}</span>
                                </div>
                                {index < drawingSegments.length - 1 && <div style={{ width: '2px', height: '100%', backgroundColor: 'rgb(84,84,84)', margin: '0 5px' }}></div>}
                            </>
                        ))}
                    </Segment>
                </div>
            </>
        );
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.drawingSegments.length !== this.props.drawingSegments.length) {
            const measureForm = document.getElementById('measure-form');
            if (measureForm) {
                const measureSegment = document.getElementById('measure-segment');
                if (measureSegment) measureForm.scrollLeft = measureSegment.clientWidth;
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isToolbarExpanded: state.isToolbarExpanded
    };
}

export default connect(mapStateToProps)(MeasureForm);