import React, { Component } from 'react';
import i18n from '../../../locales/i18n';

export default class DropDownFilter extends Component {
    render() {
        const { propertyOptions, p, isNullable } = this.props;

        return (
            <div className='rdg-filter-container'>
                <select style={{ width: '100%' }} className='rdg-filter' value={p.value} onChange={e => p.onChange(e.target.value)}>
                    <option value=''>{i18n.t("Aucun filtre")}</option>
                    {isNullable && <option value='empty'>{i18n.t("Donnée manquante")}</option>}
                    {propertyOptions?.map(x => (<option key={x.value || x.label} value={x.value || x.label}>{x.label}</option>))}
                </select>
            </div>
        );
    }
}