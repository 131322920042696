import React, { Component } from 'react';
// Composants
import { Editor } from 'react-draft-wysiwyg';
// Librairies
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Button, Divider, Form, Grid, Icon, Input, Message, Segment } from 'semantic-ui-react';
// Services
import NotificationsService from '../../../services/NotificationsService';
import FormattersUtil from '../../../utils/FormattersUtil';
// Styles
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import i18n from '../../../locales/i18n';

const initialNotification = {
    title: '',
    content: '',
    link: null
};

const initialError = {
    hidden: true,
    messages: [],
    link: false
};

class NotificationForm extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        newNotification: initialNotification,
        error: initialError,
        isLoading: false
    };

    render() {
        const { title, link } = this.state.newNotification;

        let isDisabled = true;
        convertToRaw(this.state.editorState.getCurrentContent()).blocks.forEach(block => { if (block.text.length > 0) isDisabled = false; });
        if (!FormattersUtil.checkString(this.state.newNotification.title) || !this.props.isOnline) isDisabled = true;

        return (
            <Segment style={{ padding: '20px', width: '65vw' }}>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Publier une notification")}</h3>
                <Divider />
                <Form
                    style={{ textAlign: 'left', marginLeft: 'auto', marginRight: 'auto' }}
                    loading={this.state.isLoading} error onSubmit={this.handleSubmit}
                >
                    <Grid style={{ marginBottom: '3px' }}>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Form.Field
                                    control={Input} placeholder={`${i18n.t("Titre")}*`} label={`${i18n.t("Titre")}* : `}
                                    name='title' value={title || ''} onChange={this.handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    control={Input} placeholder={i18n.t("Lien")} name='link' value={link || ''} label={`${i18n.t("Lien")} : `}
                                    onChange={this.handleChange} error={this.state.error.link}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Editor
                        editorState={this.state.editorState} placeholder={`${i18n.t("Contenu")}*`}
                        toolbarClassName='toolbarClassName' wrapperClassName='wrapperClassName' editorClassName='editorClassName'
                        onEditorStateChange={this.onEditorStateChange}
                    />
                    <Message
                        error header={i18n.t("Erreur")} list={this.state.error.messages}
                        style={{ textAlign: 'left' }} hidden={this.state.error.hidden}
                    />
                    <div style={{ marginTop: '15px' }}>
                        <Button className='form-button' type='button' color='red' onClick={this.handleReset}>
                            {i18n.t("Réinitialiser")} <Icon name='trash' style={{ marginLeft: '5px', marginRight: 0 }} />
                        </Button>
                        <Button disabled={isDisabled} className='form-button' type='submit' color='blue'>
                            {i18n.t("Publier")} <Icon name='send' style={{ marginLeft: '5px', marginRight: 0 }} />
                        </Button>
                    </div>
                </Form>
            </Segment>
        );
    }

    onEditorStateChange = (editorState) => this.setState({ editorState: editorState });

    handleChange = (e, { name, value }) => {
        this.setState({ newNotification: { ...this.state.newNotification, [name]: value } });
        if (name === 'link') this.setState(prevState => ({ error: { ...prevState.error, link: false } }));
    }

    handleReset = () => this.setState({ editorState: EditorState.createEmpty() });

    handleSubmit = () => {
        let newNotification = this.state.newNotification;

        newNotification.title = newNotification.title.trim();
        if (newNotification.link) newNotification.link = newNotification.link.trim();
        newNotification.content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

        if (!FormattersUtil.checkString(newNotification.link) || FormattersUtil.isURL(newNotification.link)) {
            this.setState({ isLoading: true });
            NotificationsService.addNotification(newNotification).then(() => {
                this.setState({
                    editorState: EditorState.createEmpty(),
                    newNotification: initialNotification,
                    error: initialError,
                    isLoading: false
                });
            });
        } else {
            this.setState({
                error: {
                    messages: [i18n.t("Le lien n'est pas une URL valide")],
                    hidden: false,
                    link: true
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(NotificationForm);