import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
// Semantic UI
import { Popup } from 'semantic-ui-react';
import { isMobileOnly } from 'react-device-detect';

class InfoIcon extends Component {
    render() {
        const { content, position, icon, className, iconStyle, popupStyle, color } = this.props;
        const popupPosition = isMobileOnly ? 'bottom left' : position;
        const style = { position: 'relative', top: 0, right: 0, fontSize: '14px', ...iconStyle };
        return (
            <Popup
                content={content} position={popupPosition} style={popupStyle}
                trigger={<FontAwesomeIcon icon={icon || faInfoCircle} className={className} style={style} color={color} />}
                onOpen={() => {
                    setTimeout(() => {
                        let popup = document.querySelector('.ui.popup');
                        let parent = popup?.parentNode;
                        if (popup && parent?.style.transform) {
                            const values = parent.style.transform.replace('translate(', '').replace(')', '');
                            const x = parseFloat(values.split(',')[0].replace('px', ''));
                            const y = parseFloat(values.split(',')[1].replace('px', ''));
                            if (popup.classList.contains('left')) parent.style.transform = `translate(${x - 13}px, ${y}px)`;
                            else if (popup.classList.contains('right')) parent.style.transform = `translate(${x + 13}px, ${y}px)`;
                        }
                    }, 1);
                }}
            />
        );
    }
}

export default InfoIcon;