import React, { Component } from 'react';
// Composants
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import DataGrid, { Row as GridRow } from 'react-data-grid';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setDefaultWmsServices } from '../../../actionCreators/appActions';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Segment, Label } from 'semantic-ui-react';
// Services
import WmsService from '../../../services/WmsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';

const initialFilters = {
    label: '',
    url: '',
    type: '',
    layers: ''
};

class WmsTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false
    }

    render() {
        const {
            data, elementsToDelete, modificationsHistory, modificationsHistoryIndex,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isLoading
        } = this.state;
        const rows = this.getFilteredRows();

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <Dimmer active={isUpdating} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Suppression des services WMS en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des services WMS")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                        <Button
                                            title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                            onClick={() => this.handleSubmit(false)} disabled={elementsToDelete.length < 1}
                                        />
                                    </>
                                </Button.Group>
                            </Menu.Item>
                            {elementsToDelete.length > 0 &&
                                <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                    <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + elementsToDelete.length} />
                                </Menu.Item>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {selectedColumn
                                    ? <Input value={selectedRow[selectedColumn.key]} />
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'label', width: 200, sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.label}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Type"), key: 'type', width: 150, sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.type}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Couches"), key: 'layers', width: 400, sortable: true, editable: false,
                formatter: (props) => <div className='disabled' style={{ justifyContent: 'left' }}>{props.row.layers}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("URL"), key: 'url', width: 800, sortable: true, editable: false,
                formatter: (props) => <div className='disabled' style={{ justifyContent: 'left' }}>{props.row.url}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            }
        ];

        const setRows = (wmsServices) => {
            data.rows = wmsServices.map(wmsService => ({ ...wmsService, layers: wmsService.layers.join(', '), type: wmsService.type === 'baseLayer' ? i18n.t("Fond de carte") : i18n.t("Overlay") }));
            const elements = JSON.parse(JSON.stringify(wmsServices));
            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data: data, elements: elements, initialOrder: initialOrder, isLoading: false });
        };

        if (!this.props.defaultWmsServices)
            WmsService.getDefaultWmsServices().then(response => {
                this.props.setDefaultWmsServices(response);
                setRows(response);
            });
        else setRows(this.props.defaultWmsServices);
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.label ? $(r.label)?.includes($(filters.label)) : true)
                && (filters.type ? $(r.type)?.includes($(filters.type)) : true)
                && (filters.layers ? $(r.layers)?.includes($(filters.layers)) : true)
                && (filters.url ? $(r.url)?.includes($(filters.url)) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToDelete } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                elementsToDelete = elementsToDelete.filter(element => element.id !== oldValue.id);
                data.rows.splice(elementId, 0, oldValue);

            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            this.setState(prevState => ({
                data: data,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToDelete } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                elementsToDelete.push(oldValue);
                data.rows.splice(elementId, 1);
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            modificationsHistory = this.state.modificationsHistory;
            modificationsHistory[index] = modificationsToCreate;

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) {
            case 'recurrencesAllowed': return value === i18n.t("Oui");
            default: return value;
        }
    }

    getRowValue = (element) => {
        return {
            ...element,
            recurrencesAllowed: element.recurrencesAllowed ? i18n.t("Oui") : i18n.t("Non")
        };
    }

    /*     Suppression     */
    deleteRow = (e, { rowIdx }) => {
        let elementsToDelete = this.state.elementsToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        elementsToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: rowIndex, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, elementsToDelete, selectedRow: null, selectedColumn: null });
    }

    handleSubmit = () => {
        let { elementsToDelete, elements } = this.state;

        if (elementsToDelete.length > 0) {
            this.setState({ isUpdating: true });
            if (elementsToDelete.length > 0)
                WmsService.removeWmsServices(elementsToDelete.map(element => element.id)).then(response => {
                    if (response === 200) {
                        elements = elements.filter(element => !elementsToDelete.find(elementToDelete => elementToDelete.id === element.id));
                        this.props.setDefaultWmsServices(this.props.defaultWmsServices.filter(wmsService => !elementsToDelete.find(elementToDelete => elementToDelete.id === wmsService.id)));
                        this.setState({ elementsToDelete: [], elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
                    }
                });
        } else {
            this.setState({ modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('wms_services_deleted');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        activeOrganization: state.activeOrganization,
        defaultWmsServices: state.defaultWmsServices
    };
};

const mapDispatchToProps = {
    setDefaultWmsServices
};

export default connect(mapStateToProps, mapDispatchToProps)(WmsTable);