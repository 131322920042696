import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
// Utils
import { showLoadingToast, showToast } from '../utils/ToastsUtil';
import WebSocketUtil from '../utils/WebSocketUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class StationsService {
    static addStation(station, action, webSocketHubs) {
        station.id = uuidv4();
        station.properties.stationId = station.id;
        const promise = Axios.post(BACKEND_ENDPOINT + 'stations/' + webSocketHubs?.elementsHub?.connectionId, station, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            station.properties = response.data.properties;
            return response.data;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'station_' + action;
        const successId = 'station_' + action.replace('ing', 'ed');
        const errorId = 'station_addition_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static getStations(projectId, data = {}) {
        return Axios.post(BACKEND_ENDPOINT + 'stations/projects/' + projectId, data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('stations_loading_failed');
            return;
        });
    }

    static removeStations(stations, webSocketHubs) {
        const promise = Axios.delete(BACKEND_ENDPOINT + 'stations/' + webSocketHubs?.elementsHub?.connectionId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: stations
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        let prefixe = stations.length > 1 ? 'stations' : 'station';
        if (navigator.onLine) showLoadingToast(prefixe + '_deleting', prefixe + '_deleted', prefixe + '_deletion_failed', promise)
        else showToast('will_sync');
        return promise;
    }

    static updateStation(id, station, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'stations/?connectionId=' + webSocketHubs?.elementsHub?.connectionId + '&id=' + id, station, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'station_' + action;
        const successId = 'station_' + action.replace('ing', 'ed');
        const errorId = 'station_' + (action === 'updating' ? action.replace('ing', 'e')
            : ['deleting', 'rotating'].includes(action) ? action.replace('ing', 'ion')
                : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static bulkUpdateStations(stations, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'stations/bulk/' + webSocketHubs?.elementsHub?.connectionId, stations, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'stations_' + action;
        const successId = 'stations_' + action.replace('ing', 'ed');
        const errorId = 'stations_' + (action === 'updating' ? action.replace('ing', 'e')
            : ['deleting', 'rotating'].includes(action) ? action.replace('ing', 'ion')
                : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static exportStationsFromProjectAsExcel(name, id, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'stations/exportXLSX/' + id, { projection }, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id} - ${name} (${i18n.t("Stations").toLowerCase()}).xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static exportStationsAsSHP(projectLabel, projectId, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'stations/exportSHP/' + projectId, { projection }, {
            responseType: 'blob',
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (response.data?.size > 0) {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${projectId} - ${projectLabel} (${i18n.t("Stations").toLowerCase()}).zip`);
                document.getElementById('modal-content').appendChild(link);
                link.click();
                document.getElementById('modal-content').removeChild(link);
            }
        }, () => {
            if (navigator.onLine) showToast('elements_shp_export_failed');
        });
    }

    static getElementStations(elementType, projectId) {
        return Axios.get(BACKEND_ENDPOINT + 'stations/getElementsStations/' + elementType + '/' + projectId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, (error) => {
            showToast('stations_loading_failed');
            return;
        });
    }
}
