import React, { Component } from 'react';
// Composants
import { Popup } from 'semantic-ui-react';
// Libraries
import { connect } from 'react-redux';
// Utils
import ThemesUtil from '../../utils/ThemesUtil';

const COLORS = ThemesUtil.getColors();

class GaugeProgressBar extends Component {
    render() {
        const { isDarkTheme, label, className, style } = this.props;

        return (
            <div className={`progress-bar ${className ?? ''}`} style={{ position: 'relative', height: '1.5rem', overflow: 'hidden', backgroundColor: isDarkTheme ? 'var(--black-60)' : 'var(--grey-20)', borderRadius: '5px', ...(style || {}) }}>
                {label !== undefined && label > 0 &&
                    <div style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', height: '100%', padding: '0 5px', zIndex: '10', pointerEvents: 'none' }}>{label}</div>}
                <div style={{ width: '100%', height: '100%', zIndex: 5 }}>
                    {this.renderValues()}
                </div>
            </div>
        );
    }

    renderValues = () => {
        const { values } = this.props;

        return (values || []).map((v, index) => {
            const { value, maxValue, popupContent, color } = v;
            return (
                <Popup
                    key={index}
                    disabled={!popupContent}
                    trigger={<div style={{ display: 'inline-block', height: '100%', width: `${value / maxValue * 100}%`, backgroundColor: color || COLORS[index], margin: 0 }}></div>}
                    content={popupContent}
                />
            );
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
    };
}

export default connect(mapStateToProps)(GaugeProgressBar);