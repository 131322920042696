import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SketchPicker } from 'react-color';
import { Button, Checkbox, Form, Grid, Input, Message, Segment, Select } from 'semantic-ui-react';
import InfoIcon from '../../Utils/InfoIcon';
// Librairies
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import reactCSS from 'reactcss';
import { setProject } from '../../../actionCreators/projectsActions';
import i18n from '../../../locales/i18n';
// Services
import ThematicMapsService from '../../../services/ThematicMapsService';
// Utils
import GreenSpacesUtil from '../../../utils/GreenSpacesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import StylesUtil from '../../../utils/StylesUtil';
import { showToast } from '../../../utils/ToastsUtil';
import DatePicker from '../../Utils/DatePicker';

const categoryOptions = [
    { text: i18n.t("Arbre"), value: 'Arbre' },
    { text: i18n.t("Espace vert"), value: 'Espace vert' },
    { text: i18n.t("Mobilier"), value: 'Mobilier' }
];

const numberOptions = [
    { text: i18n.t("Inférieur à ..."), value: 'lower' },
    { text: i18n.t("Égal à ..."), value: 'equal' },
    { text: i18n.t("De ... à ..."), value: 'between' },
    { text: i18n.t("Supérieur à ..."), value: 'greater' }
];

const dateOptions = [
    { text: i18n.t("Avant ..."), value: 'before' },
    { text: i18n.t("Égal à ..."), value: 'equal' },
    { text: i18n.t("De ... à ..."), value: 'between' },
    { text: i18n.t("Après ..."), value: 'after' }
];

const defaultStyles = {
    'default': {
        color: {
            width: '18px',
            height: '18px',
            borderRadius: '50%'
        },
        swatch: {
            padding: '3px',
            marginLeft: '1px',
            background: '#fff',
            borderRadius: '50%',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            zIndex: 1
        }
    }
};

const initialError = {
    hidden: true,
    messages: [],
    label: false,
    category: false,
    property: false
};

class ThematicMapForm extends Component {
    state = {
        initialThematicMap: null,
        thematicMap: {
            projectId: this.props.project.id,
            label: '',
            category: null,
            property: null,
            type: null,
            isNumeric: false,
            useNumericalConditions: false,
            useLateActions: false,
            colors: []
        },
        error: initialError,
        availableProperties: [],
        colorToModify: null,
        isLoading: false,
        customFieldsToRender: null
    };

    requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);
    publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);

    baseFields = [
        // Dates
        { key: 'creationDate', label: i18n.t("Date de création"), type: 'date' },
        { key: 'modificationDate', label: i18n.t("Dernière modification"), type: 'date' },
        { key: 'actionId', label: i18n.t("Type d'action"), type: 'list' },
        // { key: 'carbonStock', label: i18n.t("Stock carbone"), type: 'number', categories: ['Arbre', 'Espace vert'] },
        /* Arbres */
        // Booléens
        { key: 'isEmpty', label: i18n.t("Vide"), type: 'boolean', category: 'Arbre' },
        { key: 'isDead', label: i18n.t("Mort"), type: 'boolean', category: 'Arbre' },
        { key: 'isStump', label: i18n.t("Souche"), type: 'boolean', category: 'Arbre' },
        { key: 'isIndexed', label: i18n.t("Classé"), type: 'boolean', category: 'Arbre' },
        { key: 'isRemarkable', label: i18n.t("Remarquable"), type: 'boolean', category: 'Arbre' },
        { key: 'isFruit', label: i18n.t("Fruitier"), type: 'boolean', category: 'Arbre' },
        { key: 'toCutDown', label: i18n.t("À abattre"), type: 'boolean', category: 'Arbre' },
        // Nombres
        { key: 'numberOfTrunks', label: i18n.t("Nombre d'axes"), type: 'number', category: 'Arbre', isNumeric: true, isAvailable: () => this.requiredFields.trees.numberOfTrunks && this.publicFields.trees.numberOfTrunks },
        { key: 'trunkHeight', label: i18n.t("Hauteur du tronc"), type: 'number', category: 'Arbre' },
        { key: 'height', label: i18n.t("Hauteur totale axe"), type: 'number', category: 'Arbre' },
        { key: 'crownDiameter', label: i18n.t("Diamètre couronne axe"), type: 'number', category: 'Arbre' },
        { key: 'fruitProduction', label: i18n.t("Production de fruit"), type: 'number', category: 'Arbre' },
        { key: 'age', label: i18n.t("Âge"), type: 'number', category: 'Arbre' },
        // Dates
        { key: 'plantingDate', label: i18n.t("Date de plantation"), type: 'date', category: 'Arbre' },
        // Listes déroulantes
        { key: 'vernacularName', label: i18n.t("Nom vernaculaire"), type: 'list', category: 'Arbre', isAvailable: () => this.publicFields.trees.vernacularName },
        { key: 'gender', label: i18n.t("Genre"), type: 'list', category: 'Arbre', isAvailable: () => this.publicFields.trees.gender },
        { key: 'species', label: i18n.t("Espèce"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.species && this.publicFields.trees.species },
        { key: 'cultivar', label: i18n.t("Cultivar"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.cultivar && this.publicFields.trees.cultivar },
        { key: 'treePortId', label: i18n.t("Port de l'arbre"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.treePort && this.publicFields.trees.treePort },
        { key: 'coverTypeId', label: i18n.t("Type de couverture"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.coverType && this.publicFields.trees.coverType },
        { key: 'vigorId', label: i18n.t("Vigueur"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.vigor && this.publicFields.trees.vigor },
        { key: 'riskId', label: i18n.t("Risque"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.risk && this.publicFields.trees.risk },
        { key: 'tippingRiskId', label: i18n.t("Risque de basculement/rupture"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.accurateRisk && this.publicFields.trees.risk },
        { key: 'organCaliberId', label: i18n.t("Calibre de l'organe instable"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.accurateRisk && this.publicFields.trees.risk },
        { key: 'targetId', label: i18n.t("Cible"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.accurateRisk && this.publicFields.trees.risk },
        { key: 'healthReviewId', label: i18n.t("Cote sanitaire"), type: 'list', category: 'Arbre', isNumeric: true, isAvailable: () => this.requiredFields.trees.healthReview && this.publicFields.trees.healthReview },
        { key: 'ontogenicStageId', label: i18n.t("Stade ontogénique"), type: 'list', category: 'Arbre', isNumeric: true, isAvailable: () => this.requiredFields.trees.ontogenicStage && this.publicFields.trees.ontogenicStage },
        { key: 'plantationCoefficientId', label: i18n.t("Coefficient de plantation"), type: 'list', category: 'Arbre', isNumeric: true, isAvailable: () => this.requiredFields.trees.plantationCoefficient && this.publicFields.trees.plantationCoefficient },
        { key: 'situationCoefficientId', label: i18n.t("Coefficient de situation"), type: 'list', category: 'Arbre', isNumeric: true, isAvailable: () => this.requiredFields.trees.situationCoefficient && this.publicFields.trees.situationCoefficient },
        {
            key: 'patrimonialCoefficientId', label: i18n.t("Coefficient patrimonial"), type: 'list', category: 'Arbre', isNumeric: true,
            isAvailable: () => this.requiredFields.trees.patrimonialCoefficient && this.publicFields.trees.patrimonialCoefficient && this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType === 'Wallonie'
        },
        { key: 'plantationTypeId', label: i18n.t("Type de plantation"), type: 'list', category: 'Arbre', isAvailable: () => this.requiredFields.trees.plantationType && this.publicFields.trees.plantationType },
        /* Espaces verts */
        // Booléens
        { key: 'isTreeBase', label: i18n.t("Pied d'arbre"), type: 'boolean', category: 'Espace vert' },
        // Nombres
        { key: 'surface', label: i18n.t("Surface"), type: 'number', category: 'Espace vert' },
        { key: 'annualMaintenanceFrequency', label: i18n.t("Fréquence annuelle d'entretien"), type: 'number', category: 'Espace vert' },
        { key: 'density', label: i18n.t("Densité"), type: 'number', category: 'Espace vert' },
        { key: 'averageHeight', label: i18n.t("Hauteur moyenne"), type: 'number', category: 'Espace vert' },
        { key: 'averageCircumference', label: i18n.t("Circonférence moyenne des troncs"), type: 'number', category: 'Espace vert' },
        { key: 'averageCrownDiameter', label: i18n.t("Diamètre moyenne des couronnes"), type: 'number', category: 'Espace vert' },
        // Listes déroulantes
        { key: 'spaceFunctionId', label: i18n.t("Fonction de l'espace"), type: 'list', category: 'Espace vert', isAvailable: () => this.requiredFields.greenSpaces.spaceFunction && this.publicFields.greenSpaces.spaceFunction },
        { key: 'spaceTypeId', label: i18n.t("Type d'espace"), type: 'list', category: 'Espace vert', isAvailable: () => this.requiredFields.greenSpaces.spaceType && this.publicFields.greenSpaces.spaceType },
        { key: 'dominantCompositionId', label: i18n.t("Composition dominante"), type: 'list', category: 'Espace vert', isAvailable: () => this.requiredFields.greenSpaces.dominantComposition && this.publicFields.greenSpaces.dominantComposition },
        { key: 'runoffCoefficientId', label: i18n.t("Coefficient de ruissellement"), type: 'list', category: 'Espace vert', isAvailable: () => this.requiredFields.greenSpaces.dominantComposition && this.publicFields.greenSpaces.dominantComposition },
        { key: 'managementClassId', label: i18n.t("Classe de gestion"), type: 'list', category: 'Espace vert', isNumeric: true, isAvailable: () => this.requiredFields.greenSpaces.managementClass && this.publicFields.greenSpaces.managementClass },
        // { key: 'dominantVernacularName', label: i18n.t("Nom vernaculaire dominant"), type: 'list', category: 'Espace vert' },
        // { key: 'dominantGender', label: i18n.t("Genre dominant"), type: 'list', category: 'Espace vert' },
        // { key: 'dominantSpecies', label: i18n.t("Espèce dominante"), type: 'list', category: 'Espace vert' },
        // { key: 'dominantCultivar', label: i18n.t("Cultivar dominant"), type: 'list', category: 'Espace vert' },
        // { key: 'averageHealthReviewId', label: i18n.t("Cote sanitaire moyenne"), type: 'list', category: 'Arbre', isNumeric: true },
        /* Mobilier */
        // Listes déroulantes
        { key: 'conditionId', label: i18n.t("État"), type: 'list', category: 'Mobilier', isAvailable: () => this.requiredFields.furnitures.condition && this.publicFields.furnitures.condition },
        { key: 'typeId', label: i18n.t("Type"), type: 'list', category: 'Mobilier', isAvailable: () => this.requiredFields.furnitures.type && this.publicFields.furnitures.type }
    ];

    render() {
        const { isOnline } = this.props;
        const { initialThematicMap, thematicMap, error, availableProperties, colorToModify, isLoading } = this.state;

        let styles = { ...defaultStyles };
        styles = reactCSS(styles);

        const isDisabled = !isOnline || isLoading || (initialThematicMap && JSON.stringify(initialThematicMap) === JSON.stringify(thematicMap));

        return (
            <Form className='modal-content' onSubmit={this.handleSubmit} loading={isLoading} error>
                <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '5px' }}>
                    <Grid style={{ margin: 0 }}>
                        <Grid.Column stretched computer={4} tablet={6} mobile={16}>
                            <Form.Field
                                control={Input} label={i18n.t("Libellé") + '* : '} placeholder={i18n.t("Indiquez un libellé")}
                                name='label' value={thematicMap.label || ''} onChange={this.handleChange} error={error.label}
                            />
                        </Grid.Column>
                        <Grid.Column stretched computer={4} tablet={6} mobile={16}>
                            <Form.Field
                                control={Select} label={i18n.t("Catégorie") + '* : '} placeholder={i18n.t("Sélectionnez une catégorie")} selectOnBlur={false}
                                name='category' options={categoryOptions} value={thematicMap.category}
                                onChange={this.handleCategoryChange} error={error.category}
                            />
                        </Grid.Column>
                        <Grid.Column stretched computer={4} tablet={6} mobile={16}>
                            <Form.Field
                                control={Select} label={i18n.t("Propriété") + '* : '} placeholder={i18n.t("Sélectionnez une propriété")} selectOnBlur={false}
                                name='property' options={availableProperties} value={thematicMap.property} search
                                disabled={!thematicMap.category} onChange={this.handlePropertyChange} error={error.property}
                            />
                        </Grid.Column>
                        {thematicMap.property === 'actionId' &&
                            <Grid.Column stretched computer={4} tablet={6} mobile={16}>
                                <Form.Field
                                    control={Select} selectOnBlur={false}
                                    label={<label>{i18n.t("Actions en retard")}<InfoIcon content={i18n.t("Par défaut, la carte thématique \"Type d'action\" ne prend en compte que les actions futures. Activer cette option permet de prendre en compte les actions passées non réalisées lors de l'attribution d'une couleur.")} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '3px' }} /> :</label>}
                                    name='useLateActions' options={[{ text: i18n.t("Oui"), value: true }, { text: i18n.t("Non"), value: false }]} value={thematicMap.useLateActions}
                                    onChange={(_, { value }) => this.setState(prevState => ({ thematicMap: { ...prevState.thematicMap, useLateActions: value } }))}
                                />
                            </Grid.Column>}
                    </Grid>
                    {thematicMap.type === 'number' || (thematicMap.isNumeric && thematicMap.useNumericalConditions) ?
                        <Grid style={{ margin: 0 }}>
                            {(colorToModify || colorToModify === 0) &&
                                <div>
                                    <div style={styles.cover} onClick={() => this.setState({ colorToModify: null })} />
                                    <div style={{ zIndex: 4, position: 'absolute', width: 'fit-content' }}>
                                        <SketchPicker id='theme-form__color-picker' color={thematicMap.colors[colorToModify]}
                                            onChange={color => {
                                                color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                                this.setState(prevState => {
                                                    if (!prevState.thematicMap.colors[colorToModify]) prevState.thematicMap.colors[colorToModify] = {};
                                                    prevState.thematicMap.colors[colorToModify].value = color;
                                                    return { thematicMap: { ...prevState.thematicMap } }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>}
                            <label style={{ marginBottom: '5px', fontWeight: 'bold' }}>{i18n.t("Couleurs")} :</label>
                            {thematicMap.isNumeric && <Checkbox toggle label={i18n.t("Conditions numériques")} checked={thematicMap.useNumericalConditions} onChange={this.toggleUseNumericalConditions} />}
                            {(!thematicMap.colors.at(-1) || thematicMap.colors.at(-1).key?.replace(/[<->]/g, () => '') ? [...thematicMap.colors, ''] : thematicMap.colors).map((color, index) => {
                                return (
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width={4}>
                                            <Form.Field
                                                control={Select} placeholder={i18n.t("Condition")} selectOnBlur={false} selectOnNavigation={false}
                                                options={numberOptions} value={this.getNumberCondition(color.key)} onChange={(_, { value }) => this.handleConditionChange(index, value)}
                                            />
                                        </Grid.Column>
                                        {color.key?.includes('-') ?
                                            <>
                                                <Grid.Column width={2}>
                                                    <Form.Field
                                                        control={Input} placeholder={i18n.t("Valeur 1")} type='number' step='0.1' disabled={!color.key && color.key !== ''}
                                                        value={this.getNumberValue(color.key, 0)} autoComplete='off' onChange={(_, { value }) => this.handleValueChange(index, value, 0)}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={2}>
                                                    <Form.Field
                                                        control={Input} placeholder={i18n.t("Valeur 2")} type='number' step='0.1' disabled={!color.key && color.key !== ''}
                                                        value={this.getNumberValue(color.key, 1)} autoComplete='off' onChange={(_, { value }) => this.handleValueChange(index, value, 1)}
                                                    />
                                                </Grid.Column>
                                            </>
                                            :
                                            <Grid.Column width={4}>
                                                <Form.Field
                                                    control={Input} placeholder={i18n.t("Valeur")} type='number' step='0.1' disabled={!color.key && color.key !== ''}
                                                    value={this.getNumberValue(color.key)} autoComplete='off' onChange={(_, { value }) => this.handleValueChange(index, value)}
                                                />
                                            </Grid.Column>}
                                        <Grid.Column width={2} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <div style={{ ...styles.swatch, zIndex: 3 }} onClick={() => this.setState(prevState => ({ colorToModify: prevState.colorToModify !== index && index }))}>
                                                <div style={{ ...styles.color, backgroundColor: color.value }} />
                                            </div>
                                            <div style={{ marginLeft: '5px' }}>{color.label}</div>
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                        :
                        (
                            thematicMap.type === 'date' ?
                                <Grid style={{ margin: 0 }}>
                                    {(colorToModify || colorToModify === 0) &&
                                        <div>
                                            <div style={styles.cover} onClick={() => this.setState({ colorToModify: null })} />
                                            <div style={{ zIndex: 4, position: 'absolute', width: 'fit-content' }}>
                                                <SketchPicker id='theme-form__color-picker' color={thematicMap.colors[colorToModify]}
                                                    onChange={color => {
                                                        color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                                        this.setState(prevState => {
                                                            if (!prevState.thematicMap.colors[colorToModify]) prevState.thematicMap.colors[colorToModify] = {};
                                                            prevState.thematicMap.colors[colorToModify].value = color;
                                                            return { thematicMap: { ...prevState.thematicMap } }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>}
                                    <label style={{ marginBottom: '5px', fontWeight: 'bold' }}>{i18n.t("Couleurs")} :</label>
                                    {(!thematicMap.colors.at(-1) || thematicMap.colors.at(-1).key?.replace(/[<|>]/g, () => '') ? [...thematicMap.colors, ''] : thematicMap.colors).map((color, index) => {
                                        return (
                                            <Grid.Row style={{ paddingTop: 0 }}>
                                                <Grid.Column width={4}>
                                                    <Form.Field
                                                        control={Select} placeholder={i18n.t("Condition")} selectOnBlur={false} selectOnNavigation={false}
                                                        options={dateOptions} value={this.getDateCondition(color.key)} onChange={(_, { value }) => this.handleConditionChange(index, value, true)}
                                                    />
                                                </Grid.Column>
                                                {color.key?.includes('|') ?
                                                    <>
                                                        <Grid.Column width={4}>
                                                            <DatePicker
                                                                value={this.getDateValue(color.key, 0)} disabled={!color.key && color.key !== ''}
                                                                onChange={(_, { value }) => this.handleValueChange(index, value, 0, true)}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <DatePicker
                                                                value={this.getDateValue(color.key, 1)} disabled={!color.key && color.key !== ''}
                                                                onChange={(_, { value }) => this.handleValueChange(index, value, 1, true)}
                                                            />
                                                        </Grid.Column>
                                                    </>
                                                    :
                                                    <Grid.Column width={4}>
                                                        <DatePicker
                                                            value={this.getDateValue(color.key, 0)} disabled={!color.key && color.key !== ''}
                                                            onChange={(_, { value }) => this.handleValueChange(index, value, 0, true)}
                                                        />
                                                    </Grid.Column>}
                                                <Grid.Column width={2} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                    <div style={{ ...styles.swatch, zIndex: 3 }} onClick={() => this.setState(prevState => ({ colorToModify: prevState.colorToModify !== index && index }))}>
                                                        <div style={{ ...styles.color, backgroundColor: color.value }} />
                                                    </div>
                                                    <div style={{ marginLeft: '5px' }}>{color.label}</div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        );
                                    })}
                                </Grid>
                                :
                                <>
                                    {thematicMap.colors.length > 0 &&
                                        <Grid style={{ margin: 0 }}>
                                            {(colorToModify || colorToModify === 0) &&
                                                <div>
                                                    <div style={styles.cover} onClick={() => this.setState({ colorToModify: null })} />
                                                    <div style={{ zIndex: 4, position: 'absolute', width: 'fit-content' }}>
                                                        <SketchPicker id='theme-form__color-picker' color={thematicMap.colors[colorToModify].value}
                                                            onChange={color => {
                                                                color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                                                this.setState(prevState => {
                                                                    prevState.thematicMap.colors[colorToModify].value = color;
                                                                    return { thematicMap: { ...prevState.thematicMap } }
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>}
                                            <label style={{ marginBottom: '5px', fontWeight: 'bold' }}>{i18n.t("Couleurs")} :</label>
                                            {thematicMap.isNumeric && <Checkbox toggle label={i18n.t("Conditions numériques")} checked={thematicMap.useNumericalConditions} onChange={this.toggleUseNumericalConditions} />}
                                            <Grid.Row style={{ padding: 0 }}>{this.renderColorPickers(styles)}</Grid.Row>
                                        </Grid>}
                                </>
                        )}
                    {!isMobileOnly &&
                        <Message
                            error hidden={error.hidden}
                            header={i18n.t("Erreur")} list={error.messages}
                            style={{ textAlign: 'left', overflow: 'auto', marginBottom: '10px' }}
                        />}
                </Segment>
                <div className='modal-content-footer'>
                    {isMobile ?
                        <Button.Group style={{ width: '100%' }}>
                            <Button
                                type='button' className='form-button' color='red' style={{ width: '50%' }}
                                onClick={this.props.hideForm} disabled={isLoading}
                            >
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' style={{ width: '50%' }} disabled={isDisabled}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Button.Group>
                        :
                        <>
                            <Button type='button' className='form-button' color='red' onClick={this.props.hideForm} disabled={isLoading}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' color='green' disabled={isDisabled}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </>}
                </div>
            </Form >
        );
    }

    componentDidMount = () => {
        const { thematicMapToEdit } = this.props;

        const customFieldsToRender = [
            ...new Set(this.props.treesLayer.getLayers().flatMap(layer => Object.keys(layer.feature.properties.customFields || {}).map(id => Number(id)))),
            ...new Set(this.props.greenSpacesLayer.getLayers().flatMap(layer => Object.keys(layer.feature.properties.customFields || {}).map(id => Number(id)))),
            ...new Set(this.props.furnituresLayer.getLayers().flatMap(layer => Object.keys(layer.feature.properties.customFields || {}).map(id => Number(id))))
        ];
        // Si le champs custom n'est pas utilisé mais qu'on édite une carte thématique sur celui-ci, on l'affiche quand même
        if (!isNaN(thematicMapToEdit?.property) && !customFieldsToRender.includes(Number(thematicMapToEdit?.property)))
            customFieldsToRender.push(Number(thematicMapToEdit.property));

        this.setState({ customFieldsToRender }, () => {
            if (thematicMapToEdit) {
                const thematicMap = JSON.parse(JSON.stringify(thematicMapToEdit));
                const options = this.getPropertyOptions(thematicMap.property, thematicMap.category);
                if (options?.length) {
                    thematicMap.colors.forEach(color => {
                        const option = options.find(option => option.key === color.key);
                        if (option) color.label = option.label;
                    });

                    options.filter(option => !thematicMap.colors.find(color => color.key === option.key))
                        .forEach(option => thematicMap.colors.push({ ...option, value: option.value || this.getRandomColor() }));
                }
                this.setState({
                    initialThematicMap: JSON.parse(JSON.stringify(thematicMap)), thematicMap,
                    availableProperties: this.getAvailableProperties(thematicMap.category)
                });
            }
        });
    }

    handleChange = (_, { name, value }) => {
        this.setState(prevState => ({
            thematicMap: { ...prevState.thematicMap, [name]: value },
            error: { ...prevState.error, [name]: false }
        }));
    }

    renderColorPickers = (styles) => {
        const { thematicMap } = this.state;

        return (thematicMap.colors.map((color, index) => {
            return color.label ? (
                <Grid.Column key={index} computer={4} tablet={8} mobile={8} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={{ ...styles.swatch, zIndex: 3 }} onClick={() => this.setState(prevState => ({ colorToModify: prevState.colorToModify !== index && index }))}>
                        <div style={{ ...styles.color, backgroundColor: color.value }} />
                    </div>
                    <div style={{ marginLeft: '5px' }}>{color.label}</div>
                </Grid.Column>
            ) : null;
        })).filter(color => color);
    }

    handleCategoryChange = (_, { value }) => {
        this.setState(prevState => ({
            thematicMap: { ...prevState.thematicMap, category: value, property: null, colors: [] },
            error: { ...prevState.error, category: false },
            availableProperties: this.getAvailableProperties(value)
        }));
    }

    handlePropertyChange = (_, { value }) => {
        const colors = this.getPropertyOptions(value, this.state.thematicMap.category) || [];
        if (colors.some(color => !color.value)) colors.forEach(color => color.value = this.getRandomColor());

        const field = this.baseFields.find(field => field.key === value) || this.props.customFields.find(field => field.id === +value);
        this.setState(prevState => ({
            error: { ...prevState.error, property: false },
            thematicMap: { ...prevState.thematicMap, property: value, type: field.type, isNumeric: field.isNumeric, useNumericalConditions: field.isNumeric && prevState.thematicMap.useNumericalConditions, useLateActions: value === 'actionId' && prevState.thematicMap.useLateActions, colors }
        }));
    }

    getAvailableProperties = (category) => {
        return [
            ...this.baseFields
                .filter(field => (!field.category || field.category === category) && (!field.isAvailable || field.isAvailable()))
                .map(field => ({ text: field.label, value: field.key }))
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.text.toUpperCase(), textB = b.text.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            ...this.props.customFields
                .filter(field => field.category === category && !field.isMultiple && field.type !== 'text' &&
                    (!this.state.customFieldsToRender || this.state.customFieldsToRender.includes(field.id)))
                .map(field => ({ text: field.label, value: field.id + '' }))
        ];
    }

    getRandomColor = () => {
        const color = 'rgba('
            + Math.floor(Math.random() * 256) + ', '
            + Math.floor(Math.random() * 256) + ', '
            + Math.floor(Math.random() * 256) + ', 0.7)';
        return color;
    }

    getPropertyOptions = (property, category) => { // Retourne la liste de choix des propriétés qui en possèdent une
        if (this.state.thematicMap.useNumericalConditions) return null;

        const layers = !property.includes('dominant') ? this.props.treesLayer.getLayers() : this.props.greenSpacesLayer.getLayers();
        const propertyName = !property.includes('dominant') ? 'essenceId' : 'dominantEssenceId';
        const essences = this.props.essences.filter(essence => layers.some(layer => layer.feature?.properties[propertyName] === essence.id));

        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const descriptionName = amenityFormulaType === 'Wallonie' ? 'descriptionWln'
            : amenityFormulaType === 'Bruxelles' ? 'descriptionBxl'
                : 'descriptionFr';
        const plantationCoefficients = this.props.plantationCoefficients.filter(plantationCoefficient => plantationCoefficient[descriptionName]);
        const situationCoefficients = this.props.situationCoefficients.filter(situationCoefficient => situationCoefficient[descriptionName]);

        switch (property) {
            case 'actionId':
                const actions = this.props.projectActions
                    ?.filter(projectAction => projectAction.action.categories.includes(category))
                    .reduce((prevValue, projectAction) => ({ ...prevValue, [projectAction.action.id]: projectAction.action.label }), {});
                return Object.keys(actions)
                    .map(actionId => ({ key: actionId, label: actions[actionId] }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.label.toUpperCase(), textB = b.label.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'vernacularName': case 'dominantVernacularName':
                return [...new Set(essences
                    .filter(essence => essence.vernacularName)
                    .map(essence => essence.vernacularName))]
                    .map(vernacularName => ({ key: vernacularName, label: vernacularName }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'gender': case 'dominantGender':
                return [...new Set(essences
                    .filter(essence => essence.gender)
                    .map(essence => essence.gender))]
                    .map(gender => ({ key: gender, label: gender }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'species': case 'dominantSpecies':
                return [...new Set(essences
                    .filter(essence => essence.species)
                    .map(essence => essence.species))]
                    .map(species => ({ key: species, label: species }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'cultivar': case 'dominantCultivar':
                return [...new Set(essences
                    .filter(essence => essence.cultivar)
                    .map(essence => essence.cultivar))]
                    .map(cultivar => ({ key: cultivar, label: cultivar }))
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.key.toUpperCase(), textB = b.key.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
            case 'treePortId': return this.props.treePorts.map(treePort => ({ key: `${treePort.id}`, label: treePort.label }));
            case 'coverTypeId': return this.props.coverTypes.map(coverType => ({ key: `${coverType.id}`, label: coverType.label }));
            case 'vigorId': return this.props.vigors.map(vigor => ({ key: `${vigor.id}`, label: vigor.label, value: StylesUtil.getVigorColor(vigor.label) }));
            case 'healthReviewId': case 'averageHealthReviewId': return this.props.healthReviews.map(healthReview => ({ key: `${healthReview.id}`, label: `${healthReview.value} (${healthReview.description})`, value: StylesUtil.getHealthReviewColor(healthReview.value) }))
            case 'ontogenicStageId': return this.props.ontogenicStages.map(ontogenicStage => ({ key: `${ontogenicStage.id}`, label: ontogenicStage.value, value: StylesUtil.getOntogenicStageColor(ontogenicStage.value) }));
            case 'riskId': return this.props.risks.map(risk => ({ key: `${risk.id}`, label: risk.label, value: StylesUtil.getRiskColor(risk.label) }));
            case 'tippingRiskId': return this.props.tippingRisks.map(tippingRisk => ({ key: `${tippingRisk.id}`, label: tippingRisk.label }));
            case 'organCaliberId': return this.props.organCalibers.map(organCaliber => ({ key: `${organCaliber.id}`, label: organCaliber.label }));
            case 'targetId': return this.props.targets.map(target => ({ key: `${target.id}`, label: target.label }));
            case 'plantationTypeId': return this.props.plantationTypes.map(plantationType => ({ key: `${plantationType.id}`, label: plantationType.label }));
            case 'plantationCoefficientId': return plantationCoefficients.map(plantationCoefficient => ({ key: `${plantationCoefficient.id}`, label: `${plantationCoefficient.value} (${plantationCoefficient[descriptionName]})` }));
            case 'situationCoefficientId': return situationCoefficients.map(situationCoefficient => ({ key: `${situationCoefficient.id}`, label: `${situationCoefficient.value} (${situationCoefficient[descriptionName]})` }));
            case 'patrimonialCoefficientId': return this.props.patrimonialCoefficients.map(patrimonialCoefficient => ({ key: `${patrimonialCoefficient.id}`, label: `${patrimonialCoefficient.value} (${patrimonialCoefficient.description})` }));
            case 'spaceFunctionId': return this.props.spaceFunctions.map(spaceFunction => ({ key: `${spaceFunction.id}`, label: spaceFunction.label }));
            case 'spaceTypeId': return this.props.spaceTypes.map(spaceType => ({ key: `${spaceType.id}`, label: spaceType.label }));
            case 'dominantCompositionId': return this.props.dominantCompositions.map(dominantComposition => ({ key: `${dominantComposition.id}`, label: dominantComposition.label }));
            case 'runoffCoefficientId': return this.props.runoffCoefficients.map(runoffCoefficient => ({ key: GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient), label: GreenSpacesUtil.getRunoffCoefficientString(runoffCoefficient) }));
            case 'managementClassId': return this.props.managementClasses.map(managementClass => ({ key: `${managementClass.id}`, label: managementClass.label }));
            case 'conditionId': return this.props.conditions.map(condition => ({ key: `${condition.id}`, label: condition.label }));
            case 'typeId': return this.props.furnitureTypes.map(furnitureType => ({ key: `${furnitureType.id}`, label: furnitureType.label }));
            case 'isEmpty': case 'isDead': case 'isStump': case 'isIndexed': case 'isRemarkable': case 'isFruit': case 'toCutDown': case 'isTreeBase':
                return [{ key: 'true', label: i18n.t("Oui") }, { key: 'false', label: i18n.t("Non") }];
            default:
                const customField = this.props.customFields.find(cf => cf.id === +property);
                if (customField?.type === 'boolean') return [{ key: 'true', label: i18n.t("Oui") }, { key: 'false', label: i18n.t("Non") }]
                else return customField?.dropdownCustomFieldValues?.map(dcfv => ({ key: dcfv.id + '', label: dcfv.label }));
        }
    }

    getNumberCondition = (value) => (
        !value && value !== '' ? ''
            : value.includes('<') ? 'lower'
                : value.includes('-') ? 'between'
                    : value.includes('>') ? 'greater'
                        : 'equal'
    );

    getDateCondition = (value) => (
        !value && value !== '' ? ''
            : value.includes('<') ? 'before'
                : value.includes('|') ? 'between'
                    : value.includes('>') ? 'after'
                        : 'equal'
    );

    handleConditionChange = (colorIndex, value, isDate = false) => {
        const betweenChar = isDate ? '|' : '-'; // Pour les dates on ne peut pas utiliser '-' comme séparateur car l'ISO string en contient
        value = value === (isDate ? 'before' : 'lower') ? '<'
            : value === 'equal' ? ''
                : value === 'between' ? betweenChar
                    : '>';

        this.setState(prevState => {
            const colors = [...prevState.thematicMap.colors];
            const color = colors[colorIndex];
            if (!color) colors.push({ key: value, value: this.getRandomColor() });
            else {
                let index = color.key.indexOf('<');
                if (index === -1) index = color.key.indexOf(betweenChar);
                if (index === -1) index = color.key.indexOf('>');
                if (index === -1) color.key = value === betweenChar ? color.key + value : value + color.key;
                else {
                    if (color.key.includes(betweenChar)) {
                        const values = color.key.split(betweenChar);
                        if ((value === '<' && values[1]) || !values[0]) color.key = value + color.key.slice(index + 1);
                        else color.key = value + color.key.slice(0, index);
                    } else {
                        if (value === betweenChar && color.key[index] === '>') color.key = color.key.slice(index + 1) + value;
                        else color.key = value + color.key.slice(index + 1);
                    }
                }
            }
            return { thematicMap: { ...prevState.thematicMap, colors } };
        });
    };

    getNumberValue = (key, index) => {
        if (!key) return '';
        if (key.includes('-')) return key.split('-')[index] || '';
        return key.replace(/[<->]/g, () => '');
    };

    getDateValue = (key, index) => {
        if (!key) return '';
        const value = key.includes('|')
            ? (key.split('|')[index] || '')
            : key.replace(/[<|>]/g, () => '');
        return value.trim() ? new Date(value) : value;
    };

    handleValueChange = (colorIndex, value, valueIndex, isDate = false) => {
        if (isDate && value.toISOString) value = value.toISOString();
        this.setState(prevState => {
            const colors = [...prevState.thematicMap.colors];
            const color = colors[colorIndex];
            let index = color.key.indexOf('<');
            if (index === -1) index = color.key.indexOf(isDate ? '|' : '-');
            if (index === -1) index = color.key.indexOf('>');
            if (index === -1) color.key = value;
            else {
                if (color.key.includes(isDate ? '|' : '-')) {
                    if (valueIndex) color.key = color.key.slice(0, index + 1) + value;
                    else color.key = value + color.key.slice(index, color.key.length);
                } else color.key = color.key[index] + value;
            }
            return { thematicMap: { ...prevState.thematicMap, colors } };
        });
    };

    toggleUseNumericalConditions = (_, { checked }) => {
        this.setState(prevState => ({
            thematicMap: { ...prevState.thematicMap, useNumericalConditions: checked }
        }), () => this.handlePropertyChange(null, { value: this.state.thematicMap.property }));
    }

    verifyProperties = () => {
        const { label, category, property } = this.state.thematicMap;

        let isValid = true;
        const error = {
            messages: [],
            label: false, category: false, property: false
        };

        const addError = (property, message) => {
            if (!isMobileOnly) {
                error.messages = [...(error.messages || []), message];
                error[property] = true;
            }
            isValid = false;
        };

        if (!label.trim()) addError('label', i18n.t("Le libellé ne peut être vide"));
        if (!category) addError('category', i18n.t("Veuillez sélectionner une catégorie"));
        else if (!property) addError('property', i18n.t("Veuillez sélectionner une propriété"));

        if (!isValid) this.setState({ error: { hidden: error.messages.length > 0 ? false : true, ...error } });
        else this.setState({ error: initialError });

        return isValid;
    }

    handleSubmit = () => {
        const { thematicMap } = this.state;

        if (this.verifyProperties()) {
            if (thematicMap.type === 'number') {
                const colors = thematicMap.colors.filter(color => color.key?.replace(/[<->]/g, '').trim() ? true : false);
                if (!colors.length) {
                    showToast('thematic_map_addition_not_allowed');
                    return;
                } else thematicMap.colors = colors;
            }

            this.setState({ isLoading: true });
            if (thematicMap.id) {
                ThematicMapsService.updateThematicMap(thematicMap).then(thematicMap => {
                    if (thematicMap) {
                        const { project } = this.props;
                        const index = project.thematicMaps.findIndex(tm => tm.id === thematicMap.id);
                        if (index !== -1) project.thematicMaps[index] = thematicMap;
                        this.props.setProject(project).then(() => this.props.updateThematicMapStyle(thematicMap));
                        this.props.updateOverlayInControlLayer(thematicMap.label, null, thematicMap.id);
                    }
                    this.setState({ isLoading: false, initialThematicMap: JSON.parse(JSON.stringify(this.state.thematicMap)) });
                });
            } else ThematicMapsService.addThematicMap(thematicMap).then(thematicMap => {
                if (thematicMap) {
                    const { project } = this.props;
                    if (!project.thematicMaps) project.thematicMaps = [];
                    project.thematicMaps.push(thematicMap);
                    this.props.setProject(project);
                    const parentLabel = thematicMap.category === 'Arbre' ? i18n.t("Arbres") : thematicMap.category === 'Espace vert' ? i18n.t("Espaces verts") : i18n.t("Mobilier urbain");
                    this.props.addOverlayToControlLayer(thematicMap.label, { parentLabel, customMap: thematicMap });
                }
                this.props.hideForm(true);
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        projectActions: state.projectActions,
        essences: state.essences,
        treePorts: state.treePorts,
        coverTypes: state.coverTypes,
        vigors: state.vigors,
        healthReviews: state.healthReviews,
        ontogenicStages: state.ontogenicStages,
        risks: state.risks,
        tippingRisks: state.tippingRisks,
        organCalibers: state.organCalibers,
        targets: state.targets,
        plantationTypes: state.plantationTypes,
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        runoffCoefficients: state.runoffCoefficients,
        managementClasses: state.managementClasses,
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        customFields: [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
    };
};

const mapDispatchToProps = {
    setProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ThematicMapForm);