import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Services
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class ThemesService {
    static getThemes() {
        return Axios.get(BACKEND_ENDPOINT + 'themes/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('connection_failed');
            return;
        });
    }

    static addTheme(formData) {
        return Axios.post(BACKEND_ENDPOINT + 'themes/', formData, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            if (navigator.onLine) showToast('theme_added');
            return response;
        }, () => {
            showToast('theme_addition_failed');
            return;
        });
    }

    static updateTheme(id, formData) {
        return Axios.put(BACKEND_ENDPOINT + 'themes/' + id, formData, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            if (navigator.onLine) showToast('theme_updated');
            return response;
        }, () => {
            showToast('theme_update_failed');
            return;
        });
    }

    static deleteTheme(id) {
        return Axios.delete(BACKEND_ENDPOINT + 'themes/' + id, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('theme_deleted');
            return response;
        }, () => {
            showToast('theme_deletion_failed');
            return;
        });
    }
}