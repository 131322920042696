import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
// Services
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class FormulasService {
    static getFormulas() {
        return Axios.get(BACKEND_ENDPOINT + 'formulas/').then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('formulas_loading_failed');
            return;
        });
    }

    static getFormulasWithValue() {
        return Axios.get(BACKEND_ENDPOINT + 'admin/formulas/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('formulas_loading_failed');
            return;
        });
    }

    static addFormulaVersion(formulaVersion) {
        return Axios.post(BACKEND_ENDPOINT + 'admin/formulas/formulaVersion/', formulaVersion, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('formula_version_added');
            return response.data;
        }, (error) => {
            if (error?.response?.status === 400) {
                showToast('formula_version_invalid');
                return 400;
            } else if (navigator.onLine) showToast('formula_version_addition_failed');
            return;
        });
    }
}