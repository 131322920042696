import React, { Component } from 'react';
// Composants
import { Menu, Item, Submenu } from 'react-contexify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../locales/i18n';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';
import RightsUtil from '../../utils/RightsUtil';
import { booleanPointInPolygon, multiPolygon, point, polygon } from '@turf/turf';

class MapContextMenu extends Component {
    state = {
        openProject: {
            label: i18n.t("Ouvrir le projet"), isVisible: () => false
        },
        new: {
            label: i18n.t("Nouveau"), isVisible: () => RightsUtil.canWrite(this.props.rights?.trees) || RightsUtil.canWrite(this.props.rights?.greenSpaces) || RightsUtil.canWrite(this.props.rights?.furnitures),
            children: {
                addTree: { label: i18n.t("Arbre"), shortcut: 'T', isVisible: () => RightsUtil.canWrite(this.props.rights?.trees), isDisabled: () => !this.props.isButtonClickable('addTree') },
                addGreenSpace: {
                    label: i18n.t("Espace vert"), isVisible: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    isDisabled: () => !this.props.isButtonClickable('addGreenSpace'),
                    children: {
                        addPolygon: { label: i18n.t("Personnalisé"), shortcut: 'G' },
                        addLine: { label: i18n.t("Ligne"), shortcut: 'L' },
                        addRectangle: { label: i18n.t("Rectangle"), shortcut: 'R' },
                        addCircle: { label: i18n.t("Cercle"), shortcut: 'O' }
                    }
                },
                addImage: { label: i18n.t("Calque"), shortcut: 'I', isVisible: () => RightsUtil.canWrite(this.props.rights?.backgroundImages), isDisabled: () => !this.props.isButtonClickable('addImage') },
                addBookmark: { label: i18n.t("Bookmark"), shortcut: 'B' }
            }
        },
        // paste: { label: i18n.t("Coller"), isDisabled: () => true, onClick: () => doSomething() },
        display: {
            label: i18n.t("Affichage"), children: {
                map: {
                    label: i18n.t("Carte"), children: {
                        cartographicBackgrounds: {
                            label: i18n.t("Fonds cartographiques"), children: {
                                googleRoadmap: { label: 'Google Roadmap', isChecked: () => this.props.map?.hasLayer(this.props.googleLayerRoadmap) },
                                googleRoadmapDark: { label: 'Google Roadmap Dark', isChecked: () => this.props.map?.hasLayer(this.props.googleLayerRoadmapDark) },
                                googleHybrid: { label: 'Google Hybrid', isChecked: () => this.props.map?.hasLayer(this.props.googleLayerHybrid) },
                                OSM: { label: 'OpenStreetMap', isChecked: () => this.props.map?.hasLayer(this.props.OSMLayer) },
                                plan: { label: i18n.t("Plan"), isChecked: () => this.props.map?.hasLayer(this.props.planLayer) },
                                urbis: { label: i18n.t("UrbIS"), isVisible: () => this.props.urbisLayer, isChecked: () => this.props.map?.hasLayer(this.props.urbisLayer) },
                                ignPlan: { label: 'IGN Plan', isVisible: () => this.props.ignLayerPlan, isChecked: () => this.props.map?.hasLayer(this.props.ignLayerPlan) },
                                ignOrtho: { label: 'IGN Orthophoto', isVisible: () => this.props.ignLayerOrtho, isChecked: () => this.props.map?.hasLayer(this.props.ignLayerOrtho) }
                            }
                        },
                        trees: {
                            label: i18n.t("Arbres"), isVisible: () => this.publicFields?.main.trees,
                            children: {
                                noTreeLayer: { label: i18n.t("Aucun"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Aucun") },
                                healthReviews: { label: i18n.t("Cotes sanitaires"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Cotes sanitaires") },
                                vigors: { label: i18n.t("Vigueurs"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Vigueurs") },
                                risks: { label: i18n.t("Risques"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Risques") },
                                ontogenicStages: { label: i18n.t("Stades ontogéniques"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Stades ontogéniques") },
                                crownDiameters: { label: i18n.t("Diamètre des couronnes"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeOptions?.includes(i18n.t("Diamètre des couronnes")) },
                                ages: { label: i18n.t("Âges"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Âges") },
                                toCutDown: { label: i18n.t("Abattages"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Abattages") },
                                treesActionsUrgency: { label: i18n.t("Actions"), isChecked: () => this.props.map?.hasLayer(this.props.treesLayer) && this.props.treesLayer?.activeChild === i18n.t("Actions") }
                            }
                        },
                        greenSpaces: {
                            label: i18n.t("Espaces verts"), isVisible: () => this.publicFields?.main.greenSpaces,
                            children: {
                                noGreenSpaceLayer: { label: i18n.t("Aucun"), isChecked: () => this.props.map?.hasLayer(this.props.greenSpacesLayer) && this.props.greenSpacesLayer?.activeChild === i18n.t("Aucun") },
                                dominantCompositions: { label: i18n.t("Compositions dominantes"), isChecked: () => this.props.map?.hasLayer(this.props.greenSpacesLayer) && this.props.greenSpacesLayer?.activeChild === i18n.t("Compositions dominantes") },
                                greenSpacesActionsUrgency: { label: i18n.t("Actions"), isChecked: () => this.props.map?.hasLayer(this.props.greenSpacesLayer) && this.props.greenSpacesLayer?.activeChild === i18n.t("Actions") }
                            }
                        },
                        furnitures: {
                            label: i18n.t("Mobilier urbain"), isVisible: () => this.publicFields?.main.trees,
                            children: {
                                noFurnitureLayer: { label: i18n.t("Aucun"), isChecked: () => this.props.map?.hasLayer(this.props.furnituresLayer) && this.props.furnituresLayer?.activeChild === i18n.t("Aucun") },
                                furnituresActionsUrgency: { label: i18n.t("Actions"), isChecked: () => this.props.map?.hasLayer(this.props.furnituresLayer) && this.props.furnituresLayer?.activeChild === i18n.t("Actions") }
                            }
                        },
                        ecosystemServices: {
                            label: i18n.t("Services écosystémiques"), children: {
                                carbonStock: { label: i18n.t("Stock carbone"), isChecked: () => this.props.map?.hasLayer(this.props.carbonStockLayer) },
                                cooling: { label: i18n.t("Rafraîchissement"), isChecked: () => this.props.map?.hasLayer(this.props.coolingLayer) }
                            }
                        },
                        natura2000: {
                            label: 'Natura 2000', children: {
                                birds: { label: i18n.t("Oiseaux"), isChecked: () => this.props.map?.hasLayer(this.props.naturaBirdsLayer) },
                                habitats: { label: i18n.t("Habitats"), isChecked: () => this.props.map?.hasLayer(this.props.naturaHabitatsLayer) }
                            }
                        },
                        cadastre: { label: i18n.t("Parcelles cadastrales"), isVisible: () => this.props.cadastreLayer, isChecked: () => this.props.map?.hasLayer(this.props.cadastreLayer) }
                    }
                },
                references: {
                    label: i18n.t("Références"), isVisible: () => this.props.project && this.publicFields?.main.references,
                    children: {
                        trees: {
                            label: i18n.t("Arbres"),
                            children: {
                                noReferenceTrees: { label: i18n.t("Aucune"), isChecked: () => !this.props.references.trees },
                                projectReferenceTrees: { label: i18n.t("Auto"), isChecked: () => this.props.references.trees === 'projectReference' },
                                customReferenceTrees: { label: i18n.t("Perso"), isChecked: () => this.props.references.trees === 'customReference' }
                            }
                        },
                        greenSpaces: {
                            label: i18n.t("Espaces verts"),
                            children: {
                                noReferenceGreenSpaces: { label: i18n.t("Aucune"), isChecked: () => !this.props.references.greenSpaces },
                                projectReferenceGreenSpaces: { label: i18n.t("Auto"), isChecked: () => this.props.references.greenSpaces === 'projectReference' },
                                customReferenceGreenSpaces: { label: i18n.t("Perso"), isChecked: () => this.props.references.greenSpaces === 'customReference' }
                            }
                        },
                        furnitures: {
                            label: i18n.t("Mobiliers"),
                            children: {
                                noReferenceFurnitures: { label: i18n.t("Aucune"), isChecked: () => !this.props.references.furnitures },
                                projectReferenceFurnitures: { label: i18n.t("Auto"), isChecked: () => this.props.references.furnitures === 'projectReference' },
                                customReferenceFurnitures: { label: i18n.t("Perso"), isChecked: () => this.props.references.furnitures === 'customReference' }
                            }
                        },
                        markers: {
                            label: i18n.t("Repères"),
                            children: {
                                noReferenceMarkers: { label: i18n.t("Aucune"), isChecked: () => !this.props.references.markers },
                                projectReferenceMarkers: { label: i18n.t("Auto"), isChecked: () => this.props.references.markers === 'projectReference' },
                                customReferenceMarkers: { label: i18n.t("Perso"), isChecked: () => this.props.references.markers === 'customReference' }
                            }
                        },
                    }
                },
                legend: { label: i18n.t("Légende"), isChecked: () => this.props.buttonStates.legend === 0 },
                fullscreen: { label: i18n.t("Plein écran"), isChecked: () => this.props.buttonStates.fullscreen === 1 }
            }
        },
        tools: {
            label: i18n.t("Outils"), children: {
                lasso: { label: i18n.t("Lasso"), isChecked: () => this.props.buttonStates.lasso === 1, isDisabled: () => !this.props.isButtonClickable('lasso') },
                geolocation: { label: i18n.t("Géolocalisation"), isChecked: () => this.props.buttonStates.geolocation === 1 },
                filters: {
                    label: i18n.t("Filtres"), shortcut: 'Ctrl+Maj+F', isVisible: () => this.props.activeOrganization?.subscription?.filters && this.publicFields?.main.filters,
                    isChecked: () => this.props.buttonStates.filters === 1, isDisabled: () => !this.props.isButtonClickable('filters')
                },
                table: {
                    label: i18n.t("Tableau de données"), shortcut: 'Ctrl+Maj+D', isVisible: () => this.props.project && !isMobile && this.props.activeOrganization?.subscription?.dataTable && this.publicFields?.main.dataTable,
                    isChecked: () => this.props.buttonStates.table === 1, isDisabled: () => !this.props.isButtonClickable('table')
                },
                statistics: {
                    label: i18n.t("Statistiques"), shortcut: 'Ctrl+Maj+S', isVisible: () => (
                        this.props.project && !isMobileOnly && (
                            (this.props.activeOrganization?.subscription?.statistics || this.props.activeOrganization?.subscription?.charts || this.props.activeOrganization?.subscription?.customCharts)
                            && (this.publicFields?.main?.statistics || this.publicFields?.main?.charts)
                        )
                    ), isChecked: () => this.props.buttonStates.statistics === 1, isDisabled: () => !this.props.isButtonClickable('statistics')
                },
                printer: { label: i18n.t("Export carte"), shortcut: 'Ctrl+Maj+P', isVisible: () => !isMobileOnly, onClick: () => this.props.handleContextMenuButtonClick('printer') },
                actions: {
                    label: i18n.t("Actions"), shortcut: 'Ctrl+Maj+A', isVisible: () => RightsUtil.canWrite(this.props.rights?.actions),
                    isChecked: () => this.props.buttonStates.actions === 1, isDisabled: () => !this.props.isButtonClickable('actions')
                },
                history: { label: i18n.t("Historique"), shortcut: 'Ctrl+Maj+H', isVisible: () => RightsUtil.canRead(this.props.rights?.projectHistory) && !isMobileOnly }
            }
        },
        favoriteTools: { label: i18n.t("Favoris") }
    }

    render() {
        return (
            <Menu id='map-context-menu' theme={this.props.isDarkTheme ? 'dark' : 'light'} animation={false}>{this.renderItems(0, this.state)}</Menu>
        );
    }

    componentDidMount = () => {
        if (this.props.project && this.props.projectCollaborators)
            this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectCollaborators !== this.props.projectCollaborators || prevProps.project !== this.props.project)
            this.publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        if (this.props.findButton && (!this.state.favoriteTools.children || (Object.keys(this.state.favoriteTools.children).toString() !== this.props.favoriteTools.toString()))) {
            let children = {};
            this.props.favoriteTools.forEach(favoriteTool => {
                let button = this.props.findButton(favoriteTool);
                if (button) {
                    while (button['button']) button = button['button'];
                    const buttonName = button.title || button.states.find(s => !s.isActive).title;
                    const label = buttonName.includes('(') ? buttonName.split('(')[0] : buttonName;
                    const shortcut = buttonName.includes('(') ? buttonName.split('(')[1].replace(')', '') : null;
                    const isChecked = button.states ? () => this.props.buttonStates[favoriteTool] === 1 : null;
                    children[favoriteTool] = { label, shortcut, isVisible: button.isVisible, isChecked, isDisabled: () => !this.props.isButtonClickable(favoriteTool) };
                }
            });
            this.setState(prevState => ({ favoriteTools: { ...prevState.favoriteTools, children, isDisabled: () => !children || !Object.keys(children).length } }));
        }

        if (this.props.map && this.props.project?.projectLabels?.length && !this.mapEventsInitialiazed) {
            this.mapEventsInitialiazed = true;
            this.props.map.on('contextmenu', ({ latlng }) => {
                if (this.props.project?.projectLabels?.length) {
                    const openProject = JSON.parse(JSON.stringify(this.state.openProject));
                    const { projectLabels } = this.props.project;
                    const projectLabelsToShow = [];

                    projectLabels.forEach(projectLabel => {
                        let { surroundings } = projectLabel;
                        surroundings = surroundings.geometry.type === 'Polygon'
                            ? polygon(surroundings.geometry.coordinates)
                            : multiPolygon(surroundings.geometry.coordinates)

                        if (booleanPointInPolygon(point([latlng.lng, latlng.lat]), surroundings))
                            projectLabelsToShow.push(projectLabel);
                    });

                    openProject.isVisible = () => projectLabelsToShow.length ? true : false;

                    const openProjectInNewTab = (projectId) => window.open(`${window.location.origin}/projects/${projectId}`, '_blank').focus();
                    if (projectLabelsToShow.length === 1) {
                        openProject.onClick = () => openProjectInNewTab(projectLabelsToShow[0].id);
                        delete openProject.children;
                    } else {
                        openProject.children = {};
                        projectLabelsToShow.forEach(projectLabel => {
                            openProject.children[projectLabel.label] = {
                                label: projectLabel.label,
                                onClick: () => openProjectInNewTab(projectLabel.id)
                            }
                        });
                    }

                    this.setState({ openProject });
                }
            });
        }
    }

    componentWillUnmount = () => {
        if (this.mapEventsInitialiazed && this.props.map)
            this.props.map.off('contextmenu');
    }

    renderItems = (index, items) => {
        return Object.keys(items).map(itemName => {
            const item = items[itemName];
            if (!item.isVisible || item.isVisible()) {
                return item.children
                    ? <Submenu key={itemName} label={item.label} disabled={item.isDisabled}>{this.renderItems(index + 1, item.children)}</Submenu>
                    :
                    <Item key={itemName} onClick={item.onClick ? item.onClick : () => this.props.handleContextMenuButtonClick(itemName)} disabled={item.isDisabled}>
                        <div style={{ display: 'flex', marginRight: '10px' }}>
                            <span>{item.label}</span>
                            {item.isChecked && item.isChecked() && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '10px' }} />}
                        </div>
                        {item.shortcut && <div style={{ marginLeft: 'auto', color: 'var(--grey-100)' }}>{item.shortcut}</div>}
                    </Item>;
            } else return null;
        });
    }
}

const mapStateToProps = (state) => {
    return {
        rights: state.rights,
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        activeOrganization: state.activeOrganization,
        buttonStates: state.buttonStates,
        favoriteTools: state.favoriteTools,
        isDarkTheme: state.isDarkTheme
    };
}

export default connect(mapStateToProps)(MapContextMenu);