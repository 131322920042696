export default class GreenSpacesUtil {
    static getRunoffCoefficientString(runoffCoefficient) {
        if (!runoffCoefficient) return;
        const { min, max } = runoffCoefficient;
        return min === max
            ? `${min}`
            : min
                ? `${min} à ${max}`
                : `< ${max}`;
    }

    static getFields(isPublicFields) {
        let fields = {
            coordinates: true,
            projectReference: true,
            actions: true,
            tags: true,
            surface: true,
            length: true,
            place: true,
            placeExtra: true,
            customReference: true,
            spaceFunction: true,
            spaceType: true,
            dominantComposition: true,
            detailedComposition: true,
            managementClass: true,
            annualMaintenanceFrequency: true,
            isTreeBase: true,
            observation: true,
            density: true,
            dominantEssence: true,
            averageHealthReview: true,
            averageHeight: true,
            averageCircumference: true,
            averageCrownDiameter: true
        };

        let formulas = {
            carbonStock: true,
            coolingIndicator: true,
            oxygenProduction: true
        }

        if (isPublicFields) delete fields.actions;
        return { ...fields, ...formulas };
    }
}
