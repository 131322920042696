import React, { Component } from 'react';
// Composants
import { Header } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { faFaceConfused } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../locales/i18n';

class Woops extends Component {
    render() {
        return (
            <Header as='h1' icon textAlign='center' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faFaceConfused} />
                <Header.Content>{i18n.t("Oops ! Une erreur est survenue...")}</Header.Content>
                <Header.Subheader as='h3'>{i18n.t("Veuillez vérifier votre connexion.")}</Header.Subheader>
            </Header>
        );
    }
}

export default Woops;