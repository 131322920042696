const userPrices = { 0: 100, 1: 50, 5: 25, 20: 15 };
const treePrices = { 0: 0.07, 1000: 0.04, 5000: 0.025, 15000: 0.0175, 30000: 0.0125, 50000: 0.01 };
const greenSpacePrices = { 0: 0.07, 1000: 0.04, 5000: 0.025, 15000: 0.0175, 30000: 0.0125, 50000: 0.01 };
const furniturePrices = { 0: 0.07, 1000: 0.04, 5000: 0.025, 15000: 0.0175, 30000: 0.0125, 50000: 0.01 };

export default class SubscriptionsUtil {
    static getSubscriptionEstimatedPrice(subscription) {
        const prices = {
            nbUsers: this.getPrice(subscription.nbUsers, userPrices),
            nbTrees: this.getPrice(subscription.nbTrees, treePrices),
            nbGreenSpaces: this.getPrice(subscription.nbGreenSpaces, greenSpacePrices),
            nbFurnitures: this.getPrice(subscription.nbFurnitures, furniturePrices),
            reminderMails: subscription.reminderMails ? 30 : 0,
            costEstimation: subscription.costEstimation ? 35 : 0,
            customCharts: subscription.customCharts ? 20 : 0,
            customFields: subscription.customFields ? 50 : 0,
            branding: subscription.branding ? 45 : 0,
            thematicMaps: subscription.thematicMaps ? 40 : 0,
            expertMode: subscription.expertMode ? 60 : 0
        };


        prices.total = Object.keys(prices).reduce((prevValue, price) => prevValue + prices[price], 0);
        return prices;
    }

    static getPrice(nbElements, prices) {
        let price = 0;
        const pricesThreshold = Object.keys(prices);

        for (let i = 0; i < pricesThreshold.length && nbElements > 0; i++)
            if (pricesThreshold[i + 1]) {
                const range = pricesThreshold[i + 1] - pricesThreshold[i];
                price += (nbElements > range ? range : nbElements) * prices[pricesThreshold[i]];
                nbElements -= range;
            } else {
                price += nbElements * prices[pricesThreshold[i]];
                nbElements -= nbElements;
            }

        return price;
    }
}
