import AppSettings from "../AppSettings";
// Librairies
import { HubConnectionBuilder } from "@microsoft/signalr";
import Cookies from "universal-cookie";

export default class WebSocketUtil {
    static createConnection(hubName) {
        if (!navigator.onLine) return;
        const token = new Cookies().get('token');
        const connectionOptions = token ? { withCredentials: false, accessTokenFactory: () => new Cookies().get('token') } : { withCredentials: false };
        return new HubConnectionBuilder()
            .withUrl(`${AppSettings.getBackendUrl()}hubs/${hubName}`, connectionOptions)
            .withAutomaticReconnect()
            .build();
    }

    static invoke(hub, method, groupId, ...objects) {
        if (hub._connectionState === 'Connected')
            hub.invoke(method, groupId, ...objects);
    }

    static sendLatestFormStateToMember(webSocketHubs, connectionId, state) {
        if (!webSocketHubs?.formsHub) return;
        this.invoke(webSocketHubs.formsHub, 'SendLatestFormStateToMember', connectionId, JSON.stringify(state));
    }

    static updateForm(webSocketHubs, groupId, state) {
        if (!webSocketHubs?.formsHub) return;
        this.invoke(webSocketHubs.formsHub, 'UpdateForm', groupId.toString(), JSON.stringify(state));
    }

    static sendTags(webSocketHubs, groupId, tags) {
        if (!webSocketHubs?.projectsHub) return;
        this.invoke(webSocketHubs.projectsHub, 'SendTags', groupId.toString(), JSON.stringify(tags));
    }

    static toggleElementsLock(webSocketHubs, groupId, ids, areLocked, userId) {
        if (!webSocketHubs?.elementsHub || !ids.length) return;
        this.invoke(webSocketHubs.elementsHub, 'ToggleElementsLock', groupId.toString(), JSON.stringify(ids), areLocked, userId);
    }

    static sendLockedElementsToMember(webSocketHubs, connectionId, lockedElements) {
        if (!webSocketHubs?.elementsHub || !lockedElements?.length) return;
        this.invoke(webSocketHubs.elementsHub, 'SendLockedElementsToMember', connectionId, JSON.stringify(lockedElements));
    }

    static sendElementsHistories(webSocketHubs, groupId, histories) {
        if (!webSocketHubs?.elementsHub) return;
        this.invoke(webSocketHubs.elementsHub, 'SendElementsHistories', groupId.toString(), JSON.stringify(histories));
    }

    static sendActionsHistories(webSocketHubs, groupId, histories) {
        if (!webSocketHubs?.elementsHub) return;
        this.invoke(webSocketHubs.elementsHub, 'SendActionsHistories', groupId.toString(), JSON.stringify(histories));
    }

    static sendFileInfos(webSocketHubs, groupId, fileInfos) {
        if (!webSocketHubs?.fileInfosHub) return;
        this.invoke(webSocketHubs.fileInfosHub, 'SendFileInfos', groupId.toString(), JSON.stringify(fileInfos));
    }

    static updateFileInfos(webSocketHubs, groupId, fileInfos) {
        if (!webSocketHubs?.fileInfosHub) return;
        this.invoke(webSocketHubs.fileInfosHub, 'UpdateFileInfos', groupId.toString(), JSON.stringify(fileInfos));
    }

    static removeFileInfos(webSocketHubs, groupId, ids) {
        if (!webSocketHubs?.fileInfosHub) return;
        this.invoke(webSocketHubs.fileInfosHub, 'RemoveFileInfos', groupId.toString(), JSON.stringify(ids));
    }

    static sendFileInfosToUsers(webSocketHubs, userIds, fileInfos) {
        if (!webSocketHubs?.fileInfosHub || !userIds?.length) return;
        this.invoke(webSocketHubs.fileInfosHub, 'SendFileInfosToUsers', userIds, JSON.stringify(fileInfos));
    }

    static updateFileInfosToUsers(webSocketHubs, userIds, fileInfos) {
        if (!webSocketHubs?.fileInfosHub || !userIds?.length) return;
        this.invoke(webSocketHubs.fileInfosHub, 'UpdateFileInfosToUsers', userIds, JSON.stringify(fileInfos));
    }

    static removeFileInfosToUsers(webSocketHubs, userIds, ids) {
        if (!webSocketHubs?.fileInfosHub || !userIds?.length) return;
        this.invoke(webSocketHubs.fileInfosHub, 'RemoveFileInfosToUsers', userIds, JSON.stringify(ids));
    }

    static sendProjectActions(webSocketHubs, groupId, projectActions) {
        if (!webSocketHubs?.actionsHub) return;
        this.invoke(webSocketHubs.actionsHub, 'SendProjectActions', groupId.toString(), JSON.stringify(projectActions));
    }

    static updateProjectActions(webSocketHubs, groupId, projectActions) {
        if (!webSocketHubs?.actionsHub) return;
        this.invoke(webSocketHubs.actionsHub, 'UpdateProjectActions', groupId.toString(), JSON.stringify(projectActions));
    }

    static removeProjectActions(webSocketHubs, groupId, ids) {
        if (!webSocketHubs?.actionsHub) return;
        this.invoke(webSocketHubs.actionsHub, 'RemoveProjectActions', groupId.toString(), JSON.stringify(ids));
    }

    static updateRecurrences(webSocketHubs, groupId, recurrences) {
        if (!webSocketHubs?.actionsHub) return;
        this.invoke(webSocketHubs.actionsHub, 'UpdateRecurrences', groupId.toString(), JSON.stringify(recurrences));
    }

    static updateUserBaseProjects(webSocketHubs, groupIds, userBaseProjects, path) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'UpdateUserBaseProjects', groupIds, JSON.stringify(userBaseProjects), path);
    }

    static updateProjectRoles(webSocketHubs, groupIds, projectRoles, path) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'UpdateProjectRoles', groupIds, JSON.stringify(projectRoles), path);
    }

    static sendProject(webSocketHubs, groupIds, project) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'SendProject', groupIds, JSON.stringify(project));
    }

    static updateProject(webSocketHubs, groupIds, project) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'UpdateProject', groupIds, JSON.stringify(project));
    }

    static moveProjects(webSocketHubs, groupIds, projects, oldPath) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'MoveProjects', groupIds, JSON.stringify(projects), oldPath);
    }

    static removeProject(webSocketHubs, groupIds, projectId, path) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'RemoveProject', groupIds, projectId.toString(), path);
    }

    static sendFilter(webSocketHubs, groupIds, filter) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'SendFilter', groupIds, JSON.stringify(filter));
    }

    static removeFilter(webSocketHubs, groupIds, projectId, filterId) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'RemoveFilter', groupIds, projectId.toString(), filterId.toString());
    }

    static sendCustomChart(webSocketHubs, groupIds, customChart) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'SendCustomChart', groupIds, JSON.stringify(customChart));
    }

    static removeCustomChart(webSocketHubs, groupIds, projectId, customChartId) {
        if (!webSocketHubs?.projectsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.projectsHub, 'RemoveCustomChart', groupIds, projectId.toString(), customChartId.toString());
    }

    static sendOrganization(webSocketHubs, groupIds, organization) {
        if (!webSocketHubs?.organizationsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendOrganization', groupIds, JSON.stringify(organization));
    }

    static updateOrganization(webSocketHubs, groupId, organization) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'UpdateOrganization', groupId.toString(), JSON.stringify(organization));
    }

    static removeOrganization(webSocketHubs, groupIds, organizationId) {
        if (!webSocketHubs?.organizationsHub || !groupIds?.length) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveOrganization', groupIds, organizationId.toString());
    }

    static sendUserOrganizations(webSocketHubs, groupId, userOrganizations) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendUserOrganizations', groupId.toString(), JSON.stringify(userOrganizations));
    }

    static removeUserOrganizations(webSocketHubs, groupId, userOrganizationIds) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveUserOrganizations', groupId.toString(), JSON.stringify(userOrganizationIds));
    }

    static sendOrganizationInvitations(webSocketHubs, groupId, invitations) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendOrganizationInvitations', groupId.toString(), JSON.stringify(invitations));
    }

    static removeOrganizationInvitations(webSocketHubs, groupId, invitationIds) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveOrganizationInvitations', groupId.toString(), JSON.stringify(invitationIds));
    }

    static sendOrganizationThemes(webSocketHubs, groupId, themes) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendOrganizationThemes', groupId.toString(), JSON.stringify(themes));
    }

    static updateOrganizationThemes(webSocketHubs, groupId, themes) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'UpdateOrganizationThemes', groupId.toString(), JSON.stringify(themes));
    }

    static removeOrganizationThemes(webSocketHubs, groupId, themeIds) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveOrganizationThemes', groupId.toString(), JSON.stringify(themeIds));
    }

    static sendOrganizationCustomFields(webSocketHubs, groupId, customFields) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendOrganizationCustomFields', groupId.toString(), JSON.stringify(customFields));
    }

    static updateOrganizationCustomFields(webSocketHubs, groupId, customFields) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'UpdateOrganizationCustomFields', groupId.toString(), JSON.stringify(customFields));
    }

    static removeOrganizationCustomFields(webSocketHubs, groupId, customFieldIds) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveOrganizationCustomFields', groupId.toString(), JSON.stringify(customFieldIds));
    }

    static sendOrganizationCustomFieldCategories(webSocketHubs, groupId, customFieldCategories) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendOrganizationCustomFieldCategories', groupId.toString(), JSON.stringify(customFieldCategories));
    }

    static updateOrganizationCustomFieldCategories(webSocketHubs, groupId, customFieldCategories) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'UpdateOrganizationCustomFieldCategories', groupId.toString(), JSON.stringify(customFieldCategories));
    }

    static removeOrganizationCustomFieldCategories(webSocketHubs, groupId, customFieldCategoryIds) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveOrganizationCustomFieldCategories', groupId.toString(), JSON.stringify(customFieldCategoryIds));
    }

    static sendOrganizationPriceLists(webSocketHubs, groupId, priceLists) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'SendOrganizationPriceLists', groupId.toString(), JSON.stringify(priceLists));
    }

    static updateOrganizationPriceLists(webSocketHubs, groupId, priceLists) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'UpdateOrganizationPriceLists', groupId.toString(), JSON.stringify(priceLists));
    }

    static removeOrganizationPriceLists(webSocketHubs, groupId, priceListIds) {
        if (!webSocketHubs?.organizationsHub || !groupId) return;
        this.invoke(webSocketHubs.organizationsHub, 'RemoveOrganizationPriceLists', groupId.toString(), JSON.stringify(priceListIds));
    }
}