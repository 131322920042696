import React, { Component } from 'react';
// Composants
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { faCheck, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Services
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialState = {
    isLoading: false,
    step: 0,
    password: ''
};

class DeleteAccountForm extends Component {
    state = initialState

    render() {
        const { isLoading, step, password } = this.state;
        const hasPassword = this.props.userInfos?.hasPassword;

        return (
            <>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Button.Group size='tiny'>{this.renderStepButtons(2)}</Button.Group>
                </div>
                {step === 0 &&
                    <>
                        <Message
                            icon error header={i18n.t("La suppression de votre compte engendrera")}
                            list={[
                                i18n.t("La suppression de vos données."),
                                i18n.t("L'impossibilité de restaurer vos données."),
                            ]}
                        />
                        <Button
                            type='button' color='red' className='form-button' disabled={!this.props.isOnline || this.props.loginAsData?.readOnly}
                            onClick={() => this.setState({ step: 1 })}
                        >
                            <FontAwesomeIcon icon={faTrash} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Supprimer mon compte")}
                        </Button>
                    </>}
                {step === 1 &&
                    <>
                        <Message
                            error
                            header={hasPassword
                                ? i18n.t("Confirmez la suppression de votre compte avec votre mot de passe")
                                : i18n.t("Cliquez sur 'Valider' pour supprimer votre compte")}
                            content={
                                hasPassword &&
                                <Form.Field
                                    name='password' control={Input} type='password' placeholder={i18n.t("Mot de passe")}
                                    value={password} onChange={this.handleDeletingChange} style={{ marginTop: 10 }}
                                />
                            }
                            style={{ marginBottom: 0 }}
                        />
                        <Button className='form-button' type='button' color='red' style={{ marginTop: '10px' }} onClick={() => this.setState({ step: 0 })}>
                            <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Annuler")}
                        </Button>
                        <Button className='form-button' type='button' color='green' disabled={isLoading || !this.props.isOnline} loading={isLoading} onClick={this.deleteAccount}>
                            <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                        </Button>
                    </>}
            </>
        );
    }

    renderStepButtons = (stepTotal) => {
        let fields = [];
        for (let i = 0; i < stepTotal; i++)
            fields.push(
                <Button
                    key={i} active={this.state.step === i} color={i <= this.state.step ? 'red' : null}
                    onClick={() => this.setState({ step: i })} disabled={i > this.state.step}
                >
                    {i + 1}
                </Button>
            );
        return fields;
    }

    deleteAccount = () => {
        const { password } = this.state;

        this.setState({ isLoading: true });
        const pwd = FormattersUtil.checkString(password) ? password : null;
        UsersService.deleteUserAccount(pwd).then(response => {
            if (response) {
                this.props.logUser(null)
                this.props.history.push('/');
            }
            this.setState({ isLoading: false });
        });
    }

    handleDeletingChange = (_, { name, value }) => this.setState({ [name]: value });
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        userInfos: state.userInfos,
        loginAsData: state.loginAsData
    };
}

export default connect(mapStateToProps, null)(DeleteAccountForm);