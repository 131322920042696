import React, { Component } from 'react';
// Composants
import Slider from 'react-slick';
// Semantic UI
import { Button } from 'semantic-ui-react';
// Styles
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true
};

class PhotosCarousel extends Component {
    render() {
        const initialSlide = this.props.selectedPhoto ? this.props.photos.indexOf(this.props.selectedPhoto) : 0;

        return (<>
            <Button
                type='button' color='grey' size='small' icon='arrow left' title='Retour'
                style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 9999 }} onClick={this.props.close}
            />
            <Slider id='photos-carousel' {...settings} initialSlide={initialSlide} onSwipe={this.focusPhoto} style={{ height: '100%', width: '100%' }}>
                {this.renderPhotos()}
            </Slider>
        </>);
    }

    componentDidMount = () => this.focusPhoto();
    componentDidUpdate = () => this.focusPhoto();
    renderPhotos = () => {
        return this.props.photos.map((photo, index) => (
            <div id={`photos-carousel-${index}`} key={index} style={{ height: '100%', width: '100%' }}>
                <img src={photo.url} className='slick-slide-image' alt='img' />
            </div>
        ));
    }

    focusPhoto = () => {
        const index = this.props.selectedPhoto ? this.props.photos.indexOf(this.props.selectedPhoto) : 0;
        const photo = document.getElementById(`photos-carousel-${index}`);
        if (photo) photo.focus();
    }
}

export default PhotosCarousel;