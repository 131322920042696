import React, { Component } from 'react';
// Libraries
import i18n from '../../../locales/i18n';
// Semantic UI
import { Checkbox, Confirm, Grid } from 'semantic-ui-react';
// Services
import { showToast } from '../../../utils/ToastsUtil';

class CutForm extends Component {
    state = { action: 'delete' }

    render() {
        const { action } = this.state;

        return (
            <Confirm
                open onCancel={this.cancel} onConfirm={this.confirm}
                cancelButton={i18n.t("Annuler")} confirmButton={i18n.t("Valider")}
                header={i18n.t("Découpe de l'élément")} content={
                    <Grid style={{ padding: '10px' }}>
                        <Grid.Row>
                            <Grid.Column>
                                <Checkbox
                                    id='vo1apmnX' radio label={i18n.t("Supprimer les polygones")} checked={action === 'delete'}
                                    onChange={() => this.setState({ action: 'delete' })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Checkbox
                                    radio label={i18n.t("Conserver les polygones")} checked={action === 'keep'}
                                    onChange={() => this.setState({ action: 'keep' })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Checkbox
                                    id='nQiWVHEG' radio label={i18n.t("Fusionner les polygones")} checked={action === 'merge'}
                                    onChange={() => this.setState({ action: 'merge' })} disabled={!this.props.mergedPolygon}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            />
        );
    }

    confirm = () => {
        this.props.cutGreenSpaces(this.state.action);
        this.props.hideForm(true);
    }

    cancel = () => {
        showToast('greenspace_cut_cancelled');
        this.props.cancelGreenSpacesCut();
        this.props.hideForm(true);
    }
}

export default CutForm;