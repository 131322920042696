import React, { Component } from 'react';
// Composants
import FilesGallery from './FilesGallery';
// Librairies
import Axios from 'axios';
// Redux
import { connect } from 'react-redux';
import { setFilesGalleries } from '../../actionCreators/elementsActions';
// Services
import FileInfosService from '../../services/FileInfosService';
import FileInfosUtil from '../../utils/FileInfosUtil';
import RightsUtil from '../../utils/RightsUtil';

const MAX_SIZE = 52428800; // 50MB

class ElementFilesGallery extends Component {
    state = {
        isLoading: true,
        files: null,
        id: this.props.layer[0].feature.id,
        category: this.props.layer[0].feature.properties.category
    }

    render() {
        const { isLoading, files, id, category } = this.state;
        const canWrite = this.canWrite();

        return (<FilesGallery readOnly={!canWrite} elementId={id} category={category} isLoading={isLoading} files={files} project={this.props.project} setFiles={this.setFiles} maxSize={MAX_SIZE} />);
    }

    componentDidMount = () => this.loadFiles();

    componentDidUpdate = (prevProps) => {
        const layer = this.props.layer;
        if (layer[0].feature.id !== this.state.id) {
            this.setState({ id: layer[0].feature.id, category: layer[0].feature.properties.category }, this.loadFiles);
        } else if (JSON.stringify(this.props.filesGalleries.filter(x => x.elementId === layer[0].feature.id)) !== JSON.stringify(prevProps.filesGalleries.filter(x => x.elementId === layer[0].feature.id)))
            this.loadFiles();
    }

    loadFiles = async () => {
        const { filesGalleries } = this.props
        const id = this.state.id || '';
        let files = filesGalleries.filter(file => file.elementId === id);
        this.setState({ files, isLoading: false });
    }

    setFiles = (files) => {
        let filesGalleries = this.props.filesGalleries.filter(file => file.elementId !== this.state.id);
        this.props.setFilesGalleries([...filesGalleries, ...files]);
        this.setState({ files });
    }

    canWrite = () => {
        if (!this.props.layer) return false;
        const { category } = this.props.layer[0].feature.properties;
        const right = category === 'Arbre' ? 'trees' : category === 'Espace vert' ? 'greenSpaces' : category === 'Mobilier' ? 'furnitures' : 'markers';
        return RightsUtil.canWrite(this.props.rights[right]);
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        filesGalleries: state.filesGalleries,
        isOnline: state.isOnline,
        rights: state.rights
    };
};

const mapDispatchToProps = {
    setFilesGalleries
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementFilesGallery);