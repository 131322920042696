import React, { Component, createRef } from 'react';
import AppSettings from '../../AppSettings';
// Composants
import { GoogleProvider } from 'leaflet-geosearch';
import { Form, Ref, Select } from 'semantic-ui-react';
// Librairies
import L from 'leaflet';
import i18n from '../../locales/i18n';

class GeosearchInput extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        searchQuery: null,
        options: []
    };

    render() {
        const { searchQuery, options } = this.state;

        return (
            <div className='geosearch-input'>
                <Ref innerRef={this.inputRef}>
                    <Form.Field
                        control={Select} search={results => results} selection selectOnBlur={false} selectOnNavigation={false} open={options.length > 0}
                        searchQuery={searchQuery || ''} onSearchChange={this.handleSearchChange} placeholder={i18n.t("Rechercher un lieu...")}
                        options={options} onChange={this.handleBoundsSelection}
                    />
                </Ref>
            </div>
        );
    }

    componentDidMount = () => {
        this.marker = null;
        this.inputRef = createRef();
        this.setState({ searchQuery: '' });
        this.provider = new GoogleProvider({ params: { key: AppSettings.getGoogleApiKey(), region: 'be' } });
    }

    componentDidUpdate = (_, prevState) => {
        if (prevState.searchQuery == null && this.state.searchQuery !== null) this.autoFocusAndSelect();
    }

    handleSearchChange = (_, { searchQuery }) => {
        this.setState({ searchQuery });
        clearTimeout(this.timeout);
        if (searchQuery)
            this.timeout = setTimeout(() => {
                this.provider.search({ query: searchQuery }).then(results => {
                    this.setState({
                        options: results.map(result => {
                            const bounds = result.bounds
                                ? new L.LatLngBounds(result.bounds)
                                : new L.LatLng(result.y, result.x).toBounds(10);
                            return { text: result.label, value: bounds };
                        })
                    });
                });
            }, 250);
        else this.setState({ options: [] });
    }

    handleBoundsSelection = (_, { value }) => {
        this.props.map.fitBounds(value);
        const lat = (value._northEast.lat + value._southWest.lat) / 2;
        const lng = (value._northEast.lng + value._southWest.lng) / 2;
        this.addTemporaryMarkerToCenterOfMap([lat, lng]);
        this.props.closeInput();
    }

    autoFocusAndSelect = () => {
        if (this.inputRef.current) {
            const input = this.inputRef.current.querySelector('input');
            input.focus();
            if (this.selectText) input.setSelectionRange(0, input.value.length);
            this.selectText = false;
        }
    }

    addTemporaryMarkerToCenterOfMap = (latLng) => {
        const { map } = this.props;
        if (!map) return;
        if (this.marker && map.hasLayer(this.marker))
            map.removeLayer(this.marker);

        const svgIcon = L.divIcon({
            html: `
                <svg class="button" expanded="true" height="40px" width="40px">
                    <circle class="pulsing-circle" cx="50%" cy="50%" r="7px"></circle>
                    <circle class="pulsing-circle pulse" cx="50%" cy="50%" r="10px"></circle>
                </svg>
                `,
            className: "",
            iconSize: [40, 40],
        });

        const centerOfMap = map.getCenter();
        const marker = L.marker(latLng || [centerOfMap.lat, centerOfMap.lng], { icon: svgIcon }).addTo(map);
        this.marker = marker;

        setTimeout(() => {
            if (map && map.hasLayer(marker))
                map.removeLayer(marker)
        }, 5000);
    }
}

export default GeosearchInput;