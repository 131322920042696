import React, { Component } from 'react';
import AppSettings from '../../../AppSettings';
// Librairies
import reactCSS from 'reactcss';
// Composants
import { SketchPicker } from 'react-color';
import { Button, Form, Grid, Input, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import { faCheck, faTimes, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { isMobileOnly } from 'react-device-detect';
import Dropzone from 'dropzone';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import i18n from '../../../locales/i18n';
import tinycolor from 'tinycolor2';
// Services
import ThemesService from '../../../services/ThemesService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import ThemesUtil from '../../../utils/ThemesUtil';
import ThemePreview from '../../Utils/ThemePreview';

const initialError = {
    hidden: false,
    messages: [],
    label: false,
    website: false
};

const defaultStyles = {
    'default': {
        color: {
            width: '18px',
            height: '18px',
            borderRadius: '50%'
        },
        swatch: {
            padding: '3px',
            marginLeft: '1px',
            background: '#fff',
            borderRadius: '50%',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            zIndex: 1
        }
    }
};

class ThemeForm extends Component {
    state = {
        isLoading: false,
        theme: null,
        error: initialError,
        colorToModify: null,
        dragEnter: false,
        logoPreview: null
    }

    render() {
        const { isOnline } = this.props;
        const { isLoading, theme, error, colorToModify } = this.state;
        let styles = { ...defaultStyles };
        styles = reactCSS(styles);
        const colorNames = ThemesUtil.getColorNames();
        const presetColors = colorNames.filter(name => name === colorToModify).map(name => getComputedStyle(document.documentElement).getPropertyValue(`--${name}-100`));
        let themeColors;
        if (theme) {
            themeColors = ThemesUtil.getThemeColors(theme);
            colorNames.forEach(name => themeColors[name] = themeColors[name] || getComputedStyle(document.documentElement).getPropertyValue(`--${name}-100`));
        }

        return (<div>
            {theme && <Form error loading={isLoading}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={5} tablet={5} mobile={16}>
                            {colorToModify ?
                                <div>
                                    <div style={styles.cover} onClick={() => this.setState({ colorToModify: null })} />
                                    <div style={{ zIndex: 4, position: 'absolute', width: 'fit-content' }}>
                                        <SketchPicker id='theme-form__color-picker' color={themeColors[colorToModify]}
                                            presetColors={presetColors}
                                            onChange={color => {
                                                color = 'rgba(' + color.rgb.r + ', ' + color.rgb.g + ', ' + color.rgb.b + ', ' + color.rgb.a + ')';
                                                this.setState(prevState => ({ theme: { ...prevState.theme, [colorToModify]: color } }));
                                            }}
                                        />
                                    </div>
                                </div>
                                : this.renderLogo()}
                        </Grid.Column>
                        <Grid.Column computer={11} tablet={11} mobile={16}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column computer={8} tablet={8} mobile={16}>
                                        <Form.Field
                                            control={Input} label={`${i18n.t("Nom du thème")}* :`} placeholder='Ex: Grality' name='label' value={theme.label || ''}
                                            error={error.label} onChange={this.handleChange} style={{ marginBottom: isMobileOnly && '5px' }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={8} mobile={16}>
                                        <Form.Field
                                            control={Input} label={`${i18n.t("Site internet")} :`} placeholder='Ex: https://app.grality.be/' name='website' value={theme.website || ''}
                                            error={error.website} onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid style={{ marginTop: '10px', marginBottom: '0px' }}>
                                <label style={{ marginBottom: '5px' }}>{i18n.t("Couleurs")} :</label>
                                <Grid.Row style={{ padding: 0 }}>{this.renderColorPickers(styles)}</Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0 }}>
                        <Grid.Column>
                            <ThemePreview theme={theme} logoUrl={this.getLogoUrl()} />
                        </Grid.Column>
                    </Grid.Row>
                    {error.messages.length > 0 &&
                        <Grid.Row>
                            <Grid.Column>
                                <Message error style={{ textAlign: 'left' }} header={i18n.t("Erreur")} list={error.messages} />
                            </Grid.Column>
                        </Grid.Row>}
                    <Grid.Row style={{ paddingTop: 0 }}>
                        <Grid.Column>
                            <Button type='button' className='form-button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button type='button' className='form-button' color='green' onClick={this.handleSubmit} disabled={!isOnline}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>}
        </div>);
    }

    componentDidMount = () => {
        Dropzone.autoDiscover = false;
        this.dropZone = null;
        this.setState({ theme: { ...this.props.theme, organizationId: this.props.activeOrganization.id } });
    }

    componentDidUpdate = (_, prevState) => {
        const { colorToModify } = this.state;

        if (!this.dropZone) {
            this.dropZone = new Dropzone('div#dropZone', { previewsContainer: false, autoProcessQueue: false, url: '/', maxFiles: 1 });
            this.dropZone.on('addedfile', file => this.setState({ dragEnter: false }, () => this.uploadLogo(file)));
            this.dropZone.on('dragover', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.setState({ dragEnter: true });
            });
            this.dropZone.on('dragleave', () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    this.setState({ dragEnter: false });
                }, 200);
            });
        }

        if (!prevState.colorToModify && colorToModify) this.dropZone.disable();
        else if (prevState.colorToModify && !colorToModify) this.dropZone.enable();
    }

    renderLogo = () => {
        const { isDarkTheme } = this.props;
        const { dragEnter } = this.state;
        const logoURL = this.getLogoUrl();
        const dropZoneStyle = {
            width: '100%', aspectRatio: '4 / 4', backgroundColor: logoURL ? 'black' : 'rgba(250,250,250,0.05)',
            border: !dragEnter ? '2px dashed var(--white-10)' : isDarkTheme ? '2px dashed white' : '2px dashed black', borderRadius: '20px',
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', overflow: 'hidden',
            marginBottom: isMobileOnly && '10px'
        };

        return (
            <div id='dropZone' title={i18n.t("Cliquez pour séléctionner un logo")} style={dropZoneStyle}>
                {logoURL ?
                    <div style={{ width: '100%', height: '100%', background: `center / contain no-repeat url('${logoURL}')`, pointerEvents: 'none' }}>
                    </div>
                    :
                    <div style={{ pointerEvents: 'none', textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faUpload} size='4x' style={{ margin: '0 5px' }} />
                        <h4 style={{ textAlign: 'center', margin: '10px 5px 0px 5px' }}>{!dragEnter ? i18n.t("Glissez le logo ici") : i18n.t("Déposez le logo ici")}</h4>
                    </div>}
            </div>
        );
    }

    renderColorPickers = (styles) => {
        const { theme } = this.state;
        const colorNames = ThemesUtil.getColorNames(true);
        let themeColors = ThemesUtil.getThemeColors(theme);
        colorNames.map(colorName => themeColors[colorName.id] = themeColors[colorName.id] || `var(--${colorName.id}-100)`);

        return (Object.keys(themeColors).map((key, index) => (
            <Grid.Column key={index} computer={4} tablet={8} mobile={8} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ ...styles.swatch, zIndex: 3 }} onClick={() => this.setState(prevState => ({ colorToModify: prevState.colorToModify !== key && key }))}>
                    <div style={{ ...styles.color, backgroundColor: themeColors[key] }} />
                </div>
                <div style={{ marginLeft: '5px' }}>{i18n.t(colorNames.find(colorName => colorName.id === key).label)}</div>
            </Grid.Column>
        )));
    }

    uploadLogo = (file) => {
        if (file['type'].split('/')[0] !== 'image') showToast('file_format_not_supported');
        else this.setState({ logoPreview: file });
    }

    handleChange = (_, { name, value }) => {
        this.setState(prevState => ({
            theme: {
                ...prevState.theme,
                [name]: value
            },
            error: initialError
        }));
    };

    handleSubmit = () => {
        const { isCreating } = this.props;
        let { theme, logoPreview } = this.state;
        let isValid = true;
        let messages = [];
        let errors = { ...initialError };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            isValid = false;
        }

        const isUrl = (url) => {
            try { return Boolean(new URL(url)); }
            catch (e) { return false; }
        }

        // Vérifications
        if (!theme.label?.length) addError('label', i18n.t("Le label ne peut pas être vide"));
        if (theme.website && !isUrl(theme.website)) addError('website', i18n.t("L'url du site internet est invalide"));

        if (isValid) {
            // On met à null les couleurs ayant été réinitialisées
            const colorNames = ThemesUtil.getColorNames();
            const themeColors = ThemesUtil.getThemeColors(theme);
            for (const colorName of colorNames) {
                const themeColorHex = themeColors[colorName] && tinycolor(themeColors[colorName]).toHexString();
                const defaultColorHex = getComputedStyle(document.documentElement).getPropertyValue(`--${colorName}-100`);
                if (themeColorHex?.trim()?.toUpperCase() === defaultColorHex?.trim()?.toUpperCase()) theme[colorName] = null;
            }

            this.setState({ isLoading: true });
            const formData = new FormData();
            formData.append('file', logoPreview);
            formData.append('theme', JSON.stringify(theme));
            if (isCreating) {
                ThemesService.addTheme(formData).then((response) => {
                    this.setState({ isLoading: false }, () => {
                        if (response) {
                            this.props.updateThemeList(response.data);
                            this.props.cancel();
                        }
                    });
                });
            } else {
                ThemesService.updateTheme(theme.id, formData).then((response) => {
                    this.setState({ isLoading: false }, () => {
                        if (response) {
                            this.props.updateThemeList(response.data);
                            this.props.cancel();
                        }
                    });
                });
            }
        } else {
            this.setState({
                error: {
                    hidden: false,
                    messages: messages,
                    label: errors.label,
                    website: errors.website
                }
            });
        }
    }

    getLogoUrl = () => {
        const { logoPreview, theme } = this.state;
        const blobInfos = AppSettings.getBlobInfos();
        return logoPreview ? URL.createObjectURL(logoPreview) : theme.logoName ? `${blobInfos.endpoint}${blobInfos.containers.photos}/${theme.logoName}` : null;
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        activeOrganization: state.activeOrganization,
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(ThemeForm);