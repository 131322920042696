import React, { Component } from 'react';
// Composants
import { Form } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';
import { v4 as uuidv4 } from 'uuid';

const buttonId = `button-${uuidv4()}`;
class PasswordInput extends Component {
    state = {
        visible: false
    }

    render() {
        const { visible } = this.state;
        const { name, value, handleChange, placeholder, error, style } = this.props;
        const type = visible ? 'text' : 'password';
        const actionIcon = visible ? 'eye slash' : 'eye';

        return (
            <Form.Input
                fluid icon='lock' iconPosition='left' placeholder={placeholder || i18n.t("Mot de passe")} style={{ ...(style || {}) }}
                name={name} value={value} onChange={handleChange} type={type} error={error} className='password'
                action={{ icon: actionIcon, className: `visibility-button`, id: buttonId, onClick: this.toggleVisibility }}
            />
        );
    }

    toggleVisibility = () => {
        const activeElement = document.activeElement;
        if (activeElement && activeElement.tagName.toLowerCase() !== 'input')
            this.setState(prevState => ({ visible: !prevState.visible }));
        else if (this.props.handleSubmit) this.props.handleSubmit();
    }

    componentDidMount = () => {
        const button = document.getElementById(buttonId);
        if (button) button.setAttribute('tabindex', '-1');
    }
}

export default PasswordInput;