import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Grid, Select } from 'semantic-ui-react';
// Librairies
import i18n from '../../../locales/i18n';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
// Redux
import { setActionHistory } from '../../../actionCreators/elementsActions';
// Services
import ActionsService from '../../../services/ActionsService';
// Utils
import ActionsUtil from '../../../utils/ActionsUtil';
import DatesUtil from '../../../utils/DatesUtil';
import StylesUtil from '../../../utils/StylesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';

const initialNewAction = {
    actionId: 0,
    startDate: null,
    endDate: null,
    recurrenceValue: 1,
    recurrenceUnit: 'Années'
}

const initialError = {
    hidden: true,
    messages: [],
    actionId: false,
    startDate: false
}

class PALinkingForm extends Component {
    state = {
        projectAction: [], // Liste des projectActions du select
        newAction: {
            ...initialNewAction,
            projectId: this.props.project.id
        },
        error: initialError,
        isLoading: false
    }

    render() {
        const { projectActions, newAction, error, isLoading } = this.state;
        const isOk = this.state.newAction.actionId;

        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading}>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Row stretched>
                        <Grid.Column>
                            <Form.Field
                                name='actionId' label={`${i18n.t("Action")} : `} placeholder={i18n.t("Sélectionnez une action")}
                                control={Select} options={projectActions || []}
                                noResultsMessage={i18n.t("Aucun résultat trouvé")} search={FormattersUtil.searchList} clearable selectOnBlur={false}
                                value={newAction.id || ''} onChange={this.handleSelectChange}
                                error={error.actionId}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button type='button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button type='submit' color='green' disabled={!isOk || isLoading}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => this.loadProjectActions();

    componentDidUpdate = (prevProps) => {
        let prevPAList = []
        prevProps.projectActions.forEach(pa => {
            if (pa.projectActionElements.filter(pae => pae.elementId !== this.props.layer[0].feature.id))
                prevPAList.push(pa);
        })
        let currentPAList = []
        this.props.projectActions.forEach(pa => {
            if (pa.projectActionElements.filter(pae => pae.elementId !== this.props.layer[0].feature.id))
                currentPAList.push(pa);
        })

        if (JSON.stringify(currentPAList) !== JSON.stringify(prevPAList)) this.loadProjectActions();
    }

    loadProjectActions = () => {
        if (this.props.projectActions) {
            let projectActions = [];
            if (this.props.projectActions) {
                let category = this.props.layer[0].feature.properties.category;
                if (category === 'Espace vert' && this.props.layer[0].feature.properties.dominantCompositionId === 7)
                    category = 'Massif arboré';

                this.props.projectActions.forEach(pa => {
                    if (pa.action.categories.includes(category)) {
                        const found = !(!pa.projectActionElements.find(pae => pae.elementId === this.props.layer[0].feature.id));
                        if (!found) {
                            let text = pa.action.label;
                            if (!ActionsUtil.getRecurrencesInfos(pa).includes('1x'))
                                text += (' | ' + DatesUtil.getFormattedLocaleDateString(pa.startDate) + ' - ' + DatesUtil.getFormattedLocaleDateString(pa.endDate) + ' | ' + ActionsUtil.getRecurrencesInfos(pa));
                            else text += (' | ' + DatesUtil.getFormattedLocaleDateString(pa.startDate));
                            projectActions.push({ text: text, value: pa.id });
                        }
                    }
                });
            }

            this.setState({ projectActions: projectActions });
        }
    }

    handleSubmit = () => {
        const { layer, project } = this.props;
        const { newAction } = this.state;

        const categories = {
            'Arbre': {
                legendName: i18n.t("Arbres"),
                getStyle: (layer) => StylesUtil.getTreeActiveStyle(this.props.treesLayer, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Espace vert': {
                legendName: i18n.t("Espaces verts"),
                getStyle: (layer) => StylesUtil.getGreenSpaceActiveStyle(this.props.greenSpacesLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            },
            'Mobilier': {
                legendName: i18n.t("Mobilier urbain"),
                getStyle: (layer) => StylesUtil.getFurnitureActiveStyle(this.props.furnituresLayer.activeChild, this.props.fieldList, layer.feature.properties, this.props.project.thematicMaps)
            }
        };
        const category = layer[0].feature.properties.category;
        const { legendName, getStyle } = categories[category];

        if (newAction.id) { // Si une action a bien été sélectionnée
            this.setState({ isLoading: true });
            const paeToAdd = [{ elementId: layer[0].feature.id, elementType: category }];

            ActionsService.updateProjectAction(newAction, project.id, { paeToAdd, successToast: 'action_linked_single', errorToast: 'connection_failed' }).then(({ projectAction, history }) => { // On lie l'élément à l'action sélectionnée
                if (history) {
                    if (this.props.actionHistory && history[0].elementId === this.props.actionHistory[0].elementId) this.props.setActionHistory([...this.props.actionHistory, ...history]);
                    if (this.props.project) WebSocketUtil.sendActionsHistories(this.props.webSocketHubs, this.props.project.id, history);
                }

                if (projectAction) {
                    newAction.projectActionElements = projectAction.projectActionElements;
                    let shouldUpdateLegend = false;

                    const projectActions = this.state.projectActions.filter(x => x.value !== newAction.id); // On la retire de la liste des actions qu'on peut ajouter

                    // Mise à jour du statut d'abattage
                    if (newAction.action.id < 4 && category === 'Arbre' && ((!layer[0].feature.properties.toCutDown || layer[0].feature.properties.toCutDown < newAction.action.id)))
                        layer.forEach(layer => layer.feature.properties = { ...layer.feature.properties, toCutDown: newAction.action.id });

                    // Mise à jour de l'urgence d'action
                    const projectActionElementRecurrences = projectAction.projectActionElements.find(pae => pae.elementId === layer[0].feature.id)?.projectActionElementRecurrences;
                    const actionsUrgency = ActionsUtil.getActionUrgency(layer[0].feature.properties.actionsUrgency, projectAction, projectActionElementRecurrences);
                    if (layer[0].feature.properties.actionsUrgency < actionsUrgency)
                        layer.forEach(layer => layer.feature.properties = { ...layer.feature.properties, actionsUrgency });

                    layer.forEach(layer => layer.setStyle(getStyle(layer)));
                    this.props.updateLegend(legendName);

                    this.props.submit(projectAction);
                    this.setState({
                        projectActions: projectActions,
                        newAction: { ...initialNewAction, projectId: this.props.project.id },
                        error: initialError
                    });
                }

                this.setState({ isLoading: false });
            });
        } else this.setState({ // Si aucune action n'a été sélectionnée, on affiche un message d'erreur
            error: {
                hidden: false,
                messages: [i18n.t("Veuillez sélectionner une action")],
                actionId: true,
            }
        });
    }

    handleSelectChange = (e, { name, value }) => {
        if (value !== -1) {
            var newAction = this.props.projectActions.find(x => x.id === value);
            this.setState(prevState => ({ newAction: newAction, error: { ...prevState.error, [name]: false } }));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        projectActions: state.projectActions,
        actionHistory: state.actionHistory,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setActionHistory
};

export default connect(mapStateToProps, mapDispatchToProps)(PALinkingForm);