import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenField } from '@fortawesome/pro-regular-svg-icons';
import { Form, Button, Message, Segment, Grid, Input, Select, TextArea } from 'semantic-ui-react';
import InfoIcon from '../../Utils/InfoIcon';
import FormMemberList from '../../Lists/FormMemberList';
// Librairies
import { isMobileOnly, isMobile } from 'react-device-detect';
import { v4 as uuidv4, validate } from 'uuid';
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setEditedProperties, unlockEditedProperties, setExitFormWithChanges } from '../../../actionCreators/componentsActions';
import { setPlace, setElementHistory, setPhotosGalleries, setFilesGalleries, setLayer } from '../../../actionCreators/elementsActions';
import { setProject, setProjectActions } from '../../../actionCreators/projectsActions';
// Ressources
import { faArrowLeft, faArrowRight, faCheck, faEye, faHeartbeat, faMapMarkedAlt, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
// Services
import ProjectsService from '../../../services/ProjectsService';
import LocationsService from '../../../services/LocationsService';
import GeoJsonUtil from '../../../utils/GeoJsonUtil';
import MarkersService from '../../../services/MarkersService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import UpdatesUtil from '../../../utils/UpdatesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import TooltipsUtil from '../../../utils/TooltipsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import DatePicker from '../../Utils/DatePicker';

const initialError = {
    hidden: true,
    messages: [],
    description: false
};

const initialState = {
    properties: {
        category: 'Repère',
        title: '',
        description: null,
        tagId: [],
        customReference: null
    },
    requiredFields: null,
    projectTags: null,
    projectTagsToAdd: [],
    isLoading: false,
    placeLoaded: false,
    error: initialError
}

class MarkerForm extends Component {
    state = this.props.layer[0]
        ? { ...initialState, id: this.props.layer[0].feature.id }
        : initialState;

    render() {
        const { id, properties, error, projectTags } = this.state;
        const requiredFields = this.state.requiredFields;

        let tags = [];

        if (this.props.project && projectTags) {
            projectTags.forEach(tag => {
                if (tag.category === 'Repère')
                    tags.push({ key: tag.id, text: tag.label, value: tag.id });
            });
        }

        return (
            <Form className='modal-content' onSubmit={this.handleSubmit} loading={this.state.isLoading} error>
                <Grid style={{ margin: 0 }}>
                    <Grid.Column textAlign='center' style={{ padding: '5px', paddingTop: 0 }}>
                        {id &&
                            <div style={{ position: 'absolute', right: 0, top: '3px' }}>
                                <FormMemberList
                                    id={`marker-form-${this.props.project.id}-${id}`} stateToSend={properties} setIsLoading={(isLoading) => this.setState({ isLoading })}
                                    updateForm={(properties) => this.setState({ isLoading: false, properties })}
                                />
                            </div>}
                    </Grid.Column>
                </Grid>
                <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '5px' }}>
                    {isMobileOnly &&
                        <Message
                            error
                            style={{ textAlign: 'left', overflow: 'auto', marginTop: '10px' }}
                            hidden={error.hidden}
                            header='Erreur'
                            list={error.messages}
                        />}
                    <Grid style={{ margin: '14px 0 0 0' }}>
                        <Grid.Column stretched computer={8} tablet={16} mobile={16}>
                            <Form.Field
                                control={Input} type='text' label={<label>{i18n.t("Titre")} :</label>} placeholder='Repère'
                                name='title' value={properties.title || ''}
                                onChange={this.handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column stretched computer={8} tablet={16} mobile={16}>
                            <Form.Field
                                control={Input} type='text' label={<label>{i18n.t("Référence personnalisée")} <InfoIcon content={i18n.t("Référence libre")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Ex: be-ch-13'
                                name='customReference' value={properties.customReference || ''}
                                onChange={this.handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column stretched computer={16} tablet={16} mobile={16}>
                            <Form.Field
                                control={Select} label={<label>{i18n.t("Tags")} <InfoIcon content={i18n.t("Note complémentaire (ex: n° quartier, caractéristique supplémentaire, ...)")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder='Sélectionnez un ou plusieurs tags'
                                name='tagId' options={tags} value={properties.tagId || []} clearable
                                multiple search={FormattersUtil.searchList} selection allowAdditions selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                additionLabel={i18n.t("Ajouter") + ' '} onAddItem={(_, data) => this.handleAddTag(data.value)}
                                onChange={this.handleTagsChange}
                            />
                        </Grid.Column>
                        <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: '4px', padding: '0 14px' }}>
                            <Form.Field style={{ marginBottom: '14px', minHeight: isMobileOnly ? '200px' : '100px' }}
                                control={TextArea} label={i18n.t("Description") + ' : '} placeholder={i18n.t("Max 5000 caractères")}
                                name='description' value={properties.description || ''}
                                error={error.description} onChange={this.handleChange}
                            />
                        </Grid.Column>
                    </Grid>
                    {!isMobileOnly &&
                        <Message
                            error hidden={this.state.error.hidden}
                            header={i18n.t("Erreur")} list={this.state.error.messages}
                            style={{ textAlign: 'left', overflow: 'auto', marginBottom: '10px' }}
                        />}
                </Segment>
                {!this.props.isKeyboardOpen &&
                    <div className='modal-content-footer'>
                        {!this.state.id ?
                            <>
                                {isMobile ?
                                    <Button.Group widths={4}>
                                        {this.renderCancelButton()}
                                        {this.renderSubmitButton()}
                                    </Button.Group>
                                    :
                                    <>
                                        {this.renderCancelButton()}
                                        {this.renderSubmitButton()}
                                    </>}
                            </>
                            :
                            <>
                                {isMobile ?
                                    <>
                                        <Button.Group widths={4}>
                                            {this.renderDeleteButton()}
                                            {this.state.properties.toCutDown === 4 ? this.renderReplantButton() : this.renderSubmitButton(properties)}
                                        </Button.Group>
                                    </>
                                    : this.renderSubmitButton(properties)}
                            </>}
                        {!isMobile && this.state.id && this.renderDeleteButton()}
                    </div>}
            </Form >
        );
    }

    componentDidMount = () => this.setInitialState();
    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0] && this.props.layer[0].feature.id !== this.state.id) {
            const { projectTags, ...state } = initialState;
            this.setState({ ...state, id: this.props.layer[0].feature.id }, this.setInitialState());
        } else if (this.props.project && JSON.stringify(prevProps.project.tags) !== JSON.stringify(this.props.project.tags))
            this.setState({ projectTags: this.props.project.tags });
    }

    componentWillUnmount = () => {
        // Si les propriétés du state local sont différentes de celles présentes dans le store Redux, on les sauvegarde
        if (JSON.stringify(this.state.properties) !== JSON.stringify(this.props.editedProperties))
            this.props.setEditedProperties(this.addedElement ? null : this.state.properties);
        if (this.props.webSocketHubs?.formsHub) this.props.webSocketHubs.formsHub.stop();
        this.props.setExitFormWithChanges(null);
    }

    renderCancelButton = () => (
        <Button
            type='button' className='form-button' color='red'
            onClick={() => this.props.hideForm(false)}
        >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Annuler")}
        </Button>
    );

    arePropertiesModified = (oldProperties, newProperties) => {
        if (!oldProperties || !newProperties) return false;
        const properties = Object.keys(initialState.properties);
        for (let i = 0; i < properties.length; i++)
            if (JSON.stringify(oldProperties[properties[i]]) !== JSON.stringify(newProperties[properties[i]]))
                return true;
        return false;
    }

    renderSubmitButton = (properties = null) => {
        const disabled = this.props.layer[0] && !this.arePropertiesModified(this.props.layer[0].feature.properties, properties);

        if (!disabled && !this.props.exitFormWithChanges) this.props.setExitFormWithChanges({ submit: this.handleSubmit, cancel: () => this.props.hideForm(false) });
        else if (disabled && this.props.exitFormWithChanges) this.props.setExitFormWithChanges(null);

        return (
            <Button id='o3RMEGnb' type='submit' className='form-button' color='green' disabled={disabled}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Valider")}
            </Button>
        );
    }

    renderDeleteButton = () => (
        <Button
            id='jkVioxVT' type='button' className='form-button' color='red' floated='right'
            onClick={this.handleDelete}
        >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Supprimer")}
        </Button>
    );

    handleChange = (_, { name, value }) => {
        if (['conditionId', 'typeId'].includes(name) && !value) value = 0;

        this.setState(prevState => ({
            properties: { ...prevState.properties, [name]: value },
            error: { ...prevState.error, [name]: false }
        }), () => {
            if (this.props.project && this.state.id) {
                if (name !== 'description') WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
                else {
                    clearTimeout(this.updateFormTimeout);
                    this.updateFormTimeout = setTimeout(() => {
                        this.updateFormTimeout = null;
                        WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
                    }, 1000);
                }
            }
        });
    }

    handleTagsChange = (e, { name, value }) => {
        let newValues = [];
        value.forEach(v => {
            if (validate(v))
                newValues.push(v);
        });

        this.setState({
            properties: { ...this.state.properties, [name]: newValues },
            error: { ...this.state.error, [name]: false }
        }, () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    handleAddTag = (tag) => {
        tag = tag.trim();
        let found = false;
        for (let i = 0; i < this.state.projectTags.length && !found; i++)
            if (this.state.projectTags[i].label.toUpperCase() === tag.toUpperCase() && this.state.projectTags[i].category === 'Repère')
                found = true;

        if (!found) {
            const id = uuidv4();
            this.setState(prevState => ({
                properties: {
                    ...prevState.properties,
                    tagId: [...prevState.properties.tagId, id]
                },
                projectTags: [...prevState.projectTags, { id: id, label: tag, projectId: this.props.project.id, category: 'Repère' }],
                projectTagsToAdd: [...prevState.projectTagsToAdd, { id: id, label: tag, projectId: this.props.project.id, category: 'Repère' }]
            }));
        }
    }

    handleSubmit = () => {
        if (this.verifyProperties()) {
            let properties = JSON.parse(JSON.stringify(this.state.properties));
            this.props.setPlace(properties.place);

            if (this.state.projectTagsToAdd.length > 0) {
                let project = { ...this.props.project };
                project.tags = this.state.projectTags;
                this.props.setProject(project);
                ProjectsService.addProjectTags(this.state.projectTagsToAdd).then(response => {
                    if (response) {
                        project.tags = response;
                        this.props.setProject(project);
                        WebSocketUtil.sendTags(this.props.webSocketHubs, this.props.project.id, this.state.projectTagsToAdd);
                    }
                });
            }

            if (!this.state.id) {
                const feature = GeoJsonUtil.generateMarkerFeature(properties, this.props.layer._latlng, this.props.project?.id || 0);
                this.setState({ isLoading: true });
                MarkersService.addMarker(feature, 'adding', this.props.webSocketHubs).finally(() => {
                    this.addedElement = true;
                    this.props.addMarker('Repère', this.props.layer, feature, { showContainer: true, showModal: true });
                });
            } else {
                if (JSON.stringify(this.props.layer[0].feature.properties) !== JSON.stringify(properties)) {
                    const { layer, elementHistory } = this.props;
                    this.setState({ isLoading: true });
                    UpdatesUtil.updateMarker(layer[0], properties, layer, this.props.fieldList, 'updating', layer[0].feature.projectId, this.props.webSocketHubs)
                        .finally(() => {
                            if (this.props.references.markers === 'customReference')
                                this.props.layer.forEach(layer => {
                                    TooltipsUtil.setMarkerTooltip(layer.feature.properties.customReference, layer, this.props.referencesLayer, true);
                                });

                            this.setState({ isLoading: false });
                            this.props.setLayer([...this.props.layer]);
                            // this.props.updateLegend(i18n.t("Repères"));
                            this.setState({ properties });
                        });
                } else showToast('element_update_not_allowed');
            }
        }
    }

    handleDelete = () => {
        if (!this.props.project || !ProjectsUtil.isElementLocked(this.props.lockedElements, { id: this.state.id }, this.props.project, this.props.projectCollaborators, []))
            this.props.showRemoveForm();
    }

    completeProperties = (newProperties) => {
        this.setState(prevState => ({ properties: { ...prevState.properties, ...newProperties } }));
    }

    verifyProperties = () => {
        let isValid = true;
        const error = {
            messages: [],
            description: false
        };

        const addError = (property, message) => {
            error.messages = [...(error.messages || []), message];
            error[property] = true;
            isValid = false;
        };

        // Étape 2
        if (this.state.properties.description && this.state.properties.description.length > 5000)
            addError(2, 'description', i18n.t("La description doit être composée de 5000 caractères maximum"));

        if (!isValid) this.setState({ error: { hidden: error.messages.length > 0 ? false : true, ...error } });
        else this.setState({ error: initialError });

        return isValid;
    }

    setInitialState = () => {
        this.props.unlockEditedProperties(); // On établit la possibilité de modifier les propriétés dans Redux
        this.stepsToAvoid = [];

        if (!this.state.projectTags && this.props.project) {
            ProjectsService.getTags(this.props.project.id).then(response => {
                let projectTags = [];
                if (response) {
                    projectTags = response;
                    let project = { ...this.props.project };
                    project.tags = projectTags;
                    this.props.setProject(project);
                } else if (!this.props.isOnline)
                    projectTags = this.props.project.tags;

                this.setState({ projectTags });
            });
        }

        const layer = Array.isArray(this.props.layer) ? this.props.layer[0] : this.props.layer;
        const feature = layer?.feature ? JSON.parse(JSON.stringify(layer.feature)) : null;
        if (feature?.id) { // Si l'utilisateur est en train d'éditer un mobilier déjà existant
            let properties = this.props.editedProperties ? this.props.editedProperties : feature.properties;
            this.setState({ properties: { ...properties }, placeLoaded: true });
        } else { // Si l'utilisateur est en train d'ajouter un nouvel mobilier
            const latlngs = this.props.layer._latlng;
            LocationsService.getPlace(latlngs.lat, latlngs.lng).then(place => { // On rempli le champs 'Commune' avec le lieu récupérer depuis les coordonnées
                if (place) this.props.setPlace(place);
                this.setState(prevState => ({ properties: { ...prevState.properties, place: place || this.props.place }, placeLoaded: true }));
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        projectCollaborators: state.projectCollaborators,
        place: state.place,
        editedProperties: state.editedProperties,
        layer: state.layer,
        rights: state.rights,
        project: state.project,
        projectActions: state.projectActions,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        isKeyboardOpen: state.isKeyboardOpen,
        elementHistory: state.elementHistory,
        photosGalleries: state.photosGalleries,
        filesGalleries: state.filesGalleries,
        webSocketHubs: state.webSocketHubs,
        lockedElements: state.lockedElements,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields,
        exitFormWithChanges: state.exitFormWithChanges,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setProject,
    setProjectActions,
    setPlace,
    setElementHistory,
    setPhotosGalleries,
    setFilesGalleries,
    setLayer,
    setExitFormWithChanges
}

export default connect(mapStateToProps, mapDispatchToProps)(MarkerForm);