import React, { Component } from 'react';
// Semantic UI
import { Form, Input, Icon, Button, Segment, Divider } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';
// Services
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialUser = {
    email: '',
    lastName: '',
    firstName: ''
}

export default class UserForm extends Component {
    state = {
        newUser: initialUser,
        error: {
            email: false,
            lastName: false,
            firstName: false
        },
        isLoading: false
    };

    render() {
        return (
            <Segment style={{ padding: '20px', width: '500px' }}>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Ajout d'un nouvel utilisateur")}</h3>
                <Divider />
                <Form onSubmit={this.handleSubmit} error style={{ textAlign: 'left' }}>
                    <Form.Field
                        control={Input} label={`${i18n.t("Email")}* :`} placeholder={i18n.t("Obligatoire")}
                        name='email' value={this.state.newUser.email || ''}
                        style={{ marginBottom: '10px' }}
                        onChange={this.handleChange} error={this.state.error.email}
                    />
                    <Form.Field
                        control={Input} label={`${i18n.t("Nom")}* :`} placeholder={i18n.t("Obligatoire")}
                        name='lastName' value={this.state.newUser.lastName || ''}
                        style={{ marginBottom: '10px' }}
                        onChange={this.handleChange} error={this.state.error.lastName}
                    />
                    <Form.Field
                        control={Input} label={`${i18n.t("Prénom")}* :`} placeholder={i18n.t("Obligatoire")}
                        name='firstName' value={this.state.newUser.firstName || ''}
                        style={{ marginBottom: '10px' }}
                        onChange={this.handleChange} error={this.state.error.firstName}
                    />
                    <Button type='submit' color='green' className='form-button' disabled={this.state.isLoading}>
                        {i18n.t("Créer")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                    </Button>
                </Form>
            </Segment>
        );
    }

    handleChange = (e, { name, value }) => {
        if (value.trim() === '') value = null;
        else value = value.trim();
        this.setState({
            newUser: { ...this.state.newUser, [name]: value },
            error: { ...this.state.error, [name]: false }
        });
    }

    handleSubmit = async () => {
        const { firstName, lastName, email } = this.state.newUser;
        let isValid = true;

        let errors = {
            email: false,
            lastName: false,
            firstName: false
        };

        const addError = (property) => {
            errors[property] = true;
            isValid = false;
        }

        if (!(firstName?.trim()) || !FormattersUtil.checkName(firstName))
            addError('firstName');

        if (!(lastName?.trim()) || !FormattersUtil.checkName(lastName))
            addError('lastName');

        if (!email?.length || !FormattersUtil.checkEmail(email))
            addError('email');
        else {
            const { isUsed } = await UsersService.isEmailInUse(email);
            if (isUsed) addError('email');
        }

        if (isValid) {
            this.setState({ isLoading: true });
            UsersService.addUser(this.state.newUser).then(() => {
                this.setState({ newUser: initialUser, isLoading: false });
            });
        } else this.setState({ error: { ...errors } });
    }
}