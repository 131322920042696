import { faCrown, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Segment, Select, Icon } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';
import FormattersUtil from '../../../utils/FormattersUtil';

class MembersManagementForm extends Component {
    state = {
        organizationId: null,
        members: [],
        userToAdd: null
    };

    render() {
        const { rows, subscriptions, users } = this.props;
        const { organizationId, members, userToAdd } = this.state;

        const organization = rows.find(row => row.id === organizationId);
        const userOptions = users.map(user => ({ text: `${user.name} (${user.email})`, value: user.id }));
        const nbUsers = subscriptions.find(subscription => subscription.id === organization?.subscriptionId)?.nbUsers || 1;

        return (
            <Segment.Group style={{ margin: '15px' }}>
                <Segment style={{ display: 'flex' }}>
                    <Select
                        placeholder={i18n.t("Ajouter un utilisateur")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                        disabled={nbUsers !== -1 && members.length >= nbUsers}
                        options={userOptions} value={userToAdd} onChange={(_, { value }) => this.setState({ userToAdd: value })}
                    />
                    <Button
                        color='green' style={{ marginLeft: '10px' }} disabled={nbUsers !== -1 && members.length >= nbUsers}
                        onClick={() => { this.addUserToOrganization(userToAdd); this.setState({ userToAdd: null }) }}
                    >
                        {i18n.t("Ajouter")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                    </Button>
                </Segment>
                {members.map(member => (
                    <Segment key={member.id}>
                        <Button
                            type='button' title={i18n.t("Retirer l'utilisateur")} size='tiny' color='red'
                            disabled={member.isOwner} style={{ padding: '8px' }}
                            onClick={() => { this.removeUserFromOrganization(member.id) }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button
                            type='button' size='tiny' color={!member.isOwner ? 'blue' : 'yellow'} disabled={member.isOwner}
                            title={!member.isOwner ? i18n.t("Rendre propriétaire") : i18n.t("Propriétaire")} style={{ padding: '8px', marginRight: '15px' }}
                            onClick={() => { this.changeOrganizationOwner(member.id) }}
                        >
                            <FontAwesomeIcon icon={faCrown} />
                        </Button>
                        {`${member.name} (${member.email})`}
                    </Segment>
                ))}
            </Segment.Group>
        );
    }

    componentDidMount = () => {
        const { rows, organizationId } = this.props;
        const members = JSON.parse(JSON.stringify(rows.find(row => row.id === organizationId)?.members || []));
        this.setState({ organizationId, members: members.sort((a, b) => a.isOwner ? -1 : b.isOwner ? 1 : 0) });
    }

    componentWillUnmount = () => this.props.updateOrganizationMembers(this.state.organizationId, this.state.members);

    addUserToOrganization = (userId) => {
        const user = this.props.users.find(user => user.id === userId);
        this.setState(prevState => ({ members: [...prevState.members, { ...user, isOwner: false }] }));
    }

    removeUserFromOrganization = (userId) => this.setState(prevState => ({ members: prevState.members.filter(member => member.id !== userId) }));
    changeOrganizationOwner = (userId) => this.setState(prevState => ({ members: prevState.members.map(user => ({ ...user, isOwner: user.id === userId })) }))
}

export default MembersManagementForm;