import { faCrown, faFileAlt, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';

class UserProjects extends Component {
    render() {
        const { projects, isDarkTheme } = this.props;

        return (
            <>
                {projects &&
                    <div style={{ padding: '25px' }}>
                        {projects.owned.length > 0 &&
                            <div style={{ width: '100%', color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>
                                <h4 style={{ margin: '0 0 5px 0' }}><FontAwesomeIcon icon={faCrown} style={{ marginRight: '5px' }} />{i18n.t("En tant que propriétaire")}</h4>
                                <div className='tree-structure' style={{ overflowY: 'auto' }}>
                                    <div className='tree-structure-item' style={{ marginLeft: '7px', overflow: 'hidden' }}>
                                        {projects.owned.map(project => (
                                            <div className='item' style={{ display: 'flex', margin: '5px' }}>
                                                <div onClick={() => this.props.history.push('/projects/' + project.id)}>
                                                    <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px' }} />{project.label}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                        {projects.owned.length > 0 && projects.joined.length > 0 && <Divider style={{ width: '100%' }} />}
                        {projects.joined.length > 0 &&
                            <div style={{ width: '100%', color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>
                                <h4 style={{ margin: '0 0 5px 0' }}><FontAwesomeIcon icon={faUser} style={{ marginRight: '5px' }} />{i18n.t("En tant qu'invité")}</h4>
                                <div className='tree-structure' style={{ overflowY: 'auto' }}>
                                    <div className='tree-structure-item' style={{ marginLeft: '7px', overflow: 'hidden' }}>
                                        {projects.joined.map(project => (
                                            <div className='item' style={{ display: 'flex', margin: '5px' }}>
                                                <div onClick={() => this.props.history.push('/projects/' + project.id)}>
                                                    <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px' }} />{`${project.label} (${project.owner})`}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                    </div>}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization
    };
};

export default connect(mapStateToProps)(UserProjects);