export default class MapsUtil {
    static filterNode(node) {
        if (node instanceof Text) return true;
        if (node.tagName.toLowerCase() === 'img') return node.src.includes('google') || node.classList?.contains('leaflet-tile');
        return [
            'canvas', 'div', 'span', 'label', 'h2', 'p', 'a', 'i', 'strong', 'main', 'aside', 'article', 'pre', 'code', 'time', 'address',
            'header', 'footer', 'iframe', 'svg', 'circle', 'path', 'g'
        ].includes(node.tagName.toLowerCase()) || /^h[123456]$/i.test(node.tagName);
    }

    static toggleControls(visibility, applyToMapTitle = true) { // Permet d'afficher/masquer l'interface
        for (let el of document.querySelectorAll('.leaflet-control')) {
            let apply = true;
            let newVisibility = visibility;

            // Si applyTo... = false, alors on vérifie si l'élément correspond
            if (el.classList.contains('legend-container')) apply = false;
            else if (el.classList.contains('leaflet-control-scale')) apply = false;
            else if (el.classList.contains('leaflet-mapTitle'))
                if (!applyToMapTitle) apply = false;
                else newVisibility = !newVisibility;

            if (apply)
                if (newVisibility) el.style.visibility = 'visible';
                else el.style.visibility = 'hidden';
        }
    }
}
