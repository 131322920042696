import React, { Component } from 'react';
// Composants
import { Button, Form, Input, Popup } from 'semantic-ui-react';
import PublicFieldsForm from '../PublicFieldsForm';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';

class ProjectAccessiblitySettingsForm extends Component {
    state = {
        clipboard: {
            copied: false,
            popup: false
        }
    }

    render() {
        const { project, subscription, loginAsData, isDuplication } = this.props;
        const { clipboard } = this.state;
        const projectUrl = (!project?.id || isDuplication) ? i18n.t("Indisponible") : window.location.origin + '/projects/' + project.id;

        return (
            <>
                <h3 style={{ marginTop: 0, marginBottom: '20px' }}>{i18n.t("Paramètres d'accessibilité")}</h3>
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: isMobileOnly ? 'column' : 'row', gap: isMobileOnly ? '10px' : '30px', marginTop: '3px', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: isMobileOnly && '100%' }}>
                        <label style={{ fontSize: '.92857143em', marginBottom: '6px' }}><b>{i18n.t("Visibilité du projet")} :</b></label>
                        <Button.Group size='tiny' style={isMobileOnly ? { width: '100%' } : { height: '37px' }}>
                            <Button
                                type='button' color={project.isPublic ? 'blue' : null} active={project.isPublic} id='DP8Y6Q8Y'
                                disabled={loginAsData?.readOnly} onClick={() => this.props.handleChange('isPublic', true)}
                            >
                                {i18n.t("Public")}
                            </Button>
                            <Button
                                type='button' color={!project.isPublic ? 'blue' : null} active={!project.isPublic} id='sUZfELmt'
                                disabled={loginAsData?.readOnly} onClick={() => this.props.handleChange('isPublic', false)}
                            >
                                {i18n.t("Privé")}
                            </Button>
                        </Button.Group>
                    </div>
                    <div style={{ flex: 1, width: isMobileOnly && '100%' }}>
                        <Popup
                            on='click' open={clipboard.popup} position='top center'
                            trigger={
                                <Form.Field
                                    control={Input} type='text' label={`${i18n.t("URL d'accès")} : `}
                                    name='url' value={projectUrl} disabled={!project?.id || isDuplication}
                                    style={isMobileOnly ? { width: '100%', marginBottom: '7px' } : { marginBottom: '7px' }}
                                    icon={!clipboard.copied ? 'clipboard' : 'circle check'} iconPosition='left'
                                    onClick={() => { this.copyToClipboard(projectUrl) }}
                                />
                            }
                            content={i18n.t("Copié dans le presse-papier")}
                        />
                    </div>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Données publiques")} :</b></label>
                </div>
                <PublicFieldsForm publicFields={project.publicFields} subscription={subscription} disabled={!project.isPublic} handleChange={this.props.handleChange} />
            </>
        );
    }

    copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        this.setState({ clipboard: { copied: true, popup: true } });
        setTimeout(() => {
            this.setState({ clipboard: { copied: false, popup: false } });
        }, 2000);
    }
}

const mapStateToProps = (state) => {
    return {
        loginAsData: state.loginAsData
    };
};

export default connect(mapStateToProps)(ProjectAccessiblitySettingsForm);