import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Utils
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class ThematicMapsService {
    static addThematicMap(thematicMap) {
        thematicMap.creatorId = jwt_decode(new Cookies().get('token')).id;

        return Axios.post(BACKEND_ENDPOINT + 'thematicMaps/', thematicMap, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('thematic_map_added');
            return response.data;
        }, () => {
            showToast('thematic_map_addition_failed');
            return;
        });
    }

    static updateThematicMap(thematicMap) {
        return Axios.put(BACKEND_ENDPOINT + 'thematicMaps/', thematicMap, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('thematic_map_updated');
            return response.data;
        }, () => {
            showToast('thematic_map_update_failed');
            return;
        });
    }

    static removeThematicMap(thematicMapId) {
        return Axios.delete(BACKEND_ENDPOINT + 'thematicMaps/' + thematicMapId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('thematic_map_deleted');
            return response.data;
        }, () => {
            showToast('thematic_map_deletion_failed');
            return;
        });
    }
}