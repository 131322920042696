import React, { Component } from 'react';
// Composants
import { MentionsInput, Mention } from 'react-mentions';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setFormulas } from '../../../actionCreators/appActions';
// Semantic UI
import { Form, Input, Select, Icon, Button, Segment, Divider, Label, Radio } from 'semantic-ui-react';
// Services
import FormulasService from '../../../services/FormulasService';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import StylesUtil from '../../../utils/StylesUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlowerTulip, faTree } from '@fortawesome/pro-solid-svg-icons';
import FormulasUtil from '../../../utils/FormulasUtil';

const operationSuggestions = FormulasUtil.getOperationSuggestions();
const fieldSuggestions = FormulasUtil.getFieldSuggestions();

const initialFormulaVersion = {
    formulaId: 0,
    label: '',
    value: '',
    trunksTarget: 'all',
    trunksOperation: 'sum'
};

const initialError = {
    formulaId: false,
    label: false,
    value: false
};

class FormulaVersionForm extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        newFormulaVersion: initialFormulaVersion,
        error: initialError,
        isLoading: false
    }

    render() {
        const { newFormulaVersion, error, isLoading } = this.state;
        const formulas = this.props.formulas.map(formula => ({
            text: <label><FontAwesomeIcon icon={formula.category === 'trees' ? faTree : faFlowerTulip} style={{ marginRight: '8px' }} />{formula.label}</label>,
            value: formula.id
        }));

        const currentCategory = this.props.formulas.find(formula => formula.id === newFormulaVersion.formulaId)?.category;
        const suggestions = currentCategory && fieldSuggestions.filter(suggestion => suggestion.category === (currentCategory === 'trees' ? 'Arbre' : 'Espace vert'));

        return (
            <Segment style={{ padding: '20px', width: '50vw' }}>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Ajout d'une nouvelle version de formule")}</h3>
                <Divider />
                <Form onSubmit={this.handleSubmit} error style={{ textAlign: 'left' }} widths='equal'>
                    <Form.Group>
                        <Form.Field
                            control={Select} width={8} style={{ minWidth: 0 }} label={i18n.t("Formule") + '* : '} placeholder={i18n.t("Obligatoire")} selectOnBlur={false}
                            name='formulaId' value={newFormulaVersion.formulaId || ''}
                            options={formulas}
                            onChange={this.handleChange} error={error.formulaId}
                        />
                        <Form.Field
                            control={Input} width={8} label={i18n.t("Nom de version") + '* :'} placeholder={i18n.t("Obligatoire")}
                            name='label' value={newFormulaVersion.label || ''}
                            onChange={this.handleChange} error={error.label}
                        />
                    </Form.Group>
                    <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Pattern")}* :</b></label>
                    <MentionsInput
                        inputRef={this.inputRef} className={`${error.value ? 'react-mention--error' : ''}`}
                        singleLine placeholder={`{${i18n.t("Circonférence")}} * pi * {${i18n.t("Hauteur")}}^2 * sqrt({${i18n.t("Cote sanitaire")}})`} style={StylesUtil.getMentionsStyle()}
                        value={newFormulaVersion.value} onChange={this.handleFormulaChange} disabled={!newFormulaVersion.formulaId}
                    >
                        <Mention
                            markup='@(operation:__id__)' trigger='@' data={operationSuggestions} allowSuggestionsAboveCursor
                            displayTransform={(id) => `${operationSuggestions.find(s => s.id === id)?.value}`}
                        />
                        <Mention
                            markup='@(field:__id__)' trigger='{' data={suggestions} appendSpaceOnAdd allowSuggestionsAboveCursor
                            displayTransform={(id) => `{${suggestions.find(s => s.id === id)?.value}}`}
                        />
                    </MentionsInput>
                    {fieldSuggestions.filter(suggestion => ['TrunkHeight', 'Circumference'].includes(suggestion.value)).map(suggestion => suggestion.id).some(property => newFormulaVersion.value.includes(property)) &&
                        <Form.Field>
                            <label style={{ fontSize: '.92857143em', marginTop: '10px' }}>{i18n.t("Gestion des arbres multi-axes")} :</label>
                            <div>
                                <span style={{ marginRight: '15px' }}>{i18n.t("Axes ciblés")} :</span>
                                <Radio label={i18n.t("Tous")} name='trunksTarget' style={{ marginLeft: '7px' }} value='all' checked={newFormulaVersion.trunksTarget === 'all'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Plus petit")} name='trunksTarget' style={{ marginLeft: '7px' }} value='smallest' checked={newFormulaVersion.trunksTarget === 'smallest'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Plus grand")} name='trunksTarget' style={{ marginLeft: '7px' }} value='tallest' checked={newFormulaVersion.trunksTarget === 'tallest'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Plus fin")} name='trunksTarget' style={{ marginLeft: '7px' }} value='thinnest' checked={newFormulaVersion.trunksTarget === 'thinnest'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Plus gros")} name='trunksTarget' style={{ marginLeft: '7px' }} value='largest' checked={newFormulaVersion.trunksTarget === 'largest'} onChange={this.handleChange} />
                            </div>
                            <div>
                                <span style={{ marginRight: '15px' }}>{i18n.t("Réduction des résultats")} :</span>
                                <Radio label={i18n.t("Somme")} name='trunksOperation' style={{ marginLeft: '7px' }} value='sum' disabled={newFormulaVersion.trunksTarget !== 'all'} checked={newFormulaVersion.trunksOperation === 'sum'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Moyenne")} name='trunksOperation' style={{ marginLeft: '7px' }} value='average' disabled={newFormulaVersion.trunksTarget !== 'all'} checked={newFormulaVersion.trunksOperation === 'average'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Multiplication")} name='trunksOperation' style={{ marginLeft: '7px' }} value='multiplication' disabled={newFormulaVersion.trunksTarget !== 'all'} checked={newFormulaVersion.trunksOperation === 'multiplication'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Min")} name='trunksOperation' style={{ marginLeft: '7px' }} value='min' disabled={newFormulaVersion.trunksTarget !== 'all'} checked={newFormulaVersion.trunksOperation === 'min'} onChange={this.handleChange} />
                                <Radio label={i18n.t("Max")} name='trunksOperation' style={{ marginLeft: '7px' }} value='max' disabled={newFormulaVersion.trunksTarget !== 'all'} checked={newFormulaVersion.trunksOperation === 'max'} onChange={this.handleChange} />
                            </div>
                        </Form.Field>}
                    <Button type='submit' color='green' disabled={isLoading} loading={isLoading} style={{ marginTop: '10px' }}>
                        {i18n.t("Ajouter")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                    </Button>
                    <Label color='grey' style={{ float: 'right' }} content={i18n.t("'{' pour afficher les champs | '@' pour afficher les opérations")} />
                </Form>
            </Segment>
        );
    }

    componentDidMount = () => {
        if (this.props.formulaTemplate) {
            let value = this.props.formulaTemplate.value || '';
            value.match(/\{(.+?)\}/g)?.forEach(property => {
                property = property.slice(1, property.length - 1);
                value = value.replace(`{${property}}`, `@(field:${fieldSuggestions.find(suggestion => suggestion.value === property)?.id})`);
            });
            this.setState(prevState => ({ newFormulaVersion: { newFormulaVersion: prevState.newFormulaVersion, ...this.props.formulaTemplate, value } }));
        }
    }

    handleChange = (_, { name, value }) => {
        this.setState(prevState => ({
            newFormulaVersion: { ...prevState.newFormulaVersion, [name]: value, value: name === 'formulaId' ? '' : prevState.newFormulaVersion.value },
            error: { ...prevState.error, [name]: false }
        }));
    }

    handleFormulaChange = (_, value, plainValue) => {
        const guidRegExp = new RegExp('({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}');
        const operationMention = value.match(/@\(operation[^ )]+\)/g)?.[0];

        let cursorPosition = -1;
        if (operationMention) {
            const matchingSuggestion = operationSuggestions.find(suggestion => suggestion.id === operationMention.match(guidRegExp)?.[0]);
            const replacementString = `${matchingSuggestion?.value || operationMention}${matchingSuggestion?.addWhitespace ? ' ' : ''}`;
            value = value.replace(operationMention, replacementString);
            if (matchingSuggestion?.value && matchingSuggestion.value.slice(-1) === ')')
                cursorPosition = plainValue.lastIndexOf(matchingSuggestion.value) + matchingSuggestion.value.length;
            else cursorPosition = this.inputRef.current.selectionStart + replacementString.length;
        } else {
            let i = 0;
            let match = value.match(new RegExp('(?<!^)([^ ]{1}){{1}'));
            while (match) {
                i++;
                value = value.slice(0, match.index + 1) + ' ' + value.slice(match.index + 1, value.length);
                match = value.match(new RegExp('(?<!^)([^ ]{1}){{1}'));
            }
            if (i) cursorPosition = this.inputRef.current.selectionStart + (i + 1);
        }

        this.setState(prevState => ({
            newFormulaVersion: { ...prevState.newFormulaVersion, value },
            error: { ...prevState.error, value: false }
        }), () => {
            if (cursorPosition !== -1)
                this.inputRef.current.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
        });
    }

    handleSubmit = () => {
        let isValid = true;
        let error = { ...initialError };
        let newFormulaVersion = JSON.parse(JSON.stringify(this.state.newFormulaVersion));

        const addError = (property) => {
            error[property] = true;
            isValid = false;
        }

        if (newFormulaVersion.formulaId <= 0) addError('formulaId');
        if (!newFormulaVersion.label?.trim()) addError('label');
        if (!newFormulaVersion.value?.trim()) addError('value');
        if (this.props.formulas.find(formula => formula.id === newFormulaVersion.formulaId && formula.formulaVersions.find(fv => fv.label === newFormulaVersion.label))) {
            showToast('formula_version_addition_not_allowed');
            isValid = false;
        }

        if (isValid) {
            newFormulaVersion.value = this.inputRef.current.value;
            if (this.props.formulas.find(formula => formula.id === newFormulaVersion.formulaId)?.category !== 'trees') {
                newFormulaVersion.trunksTarget = null;
                newFormulaVersion.trunksOperation = null;
            }
            this.setState({ isLoading: true });
            FormulasService.addFormulaVersion(newFormulaVersion).then((formulaVersion) => {
                if (formulaVersion === 400) {
                    addError('value');
                    this.setState({ error: { ...error } });
                } else if (formulaVersion) {
                    this.setState({ newFormulaVersion: initialFormulaVersion, error: initialError });
                    const formula = this.props.formulas.find(formula => formula.id === formulaVersion.formulaId);
                    formula.formulaVersions.push(formulaVersion);
                }
                this.setState({ isLoading: false });
            });
        } else this.setState({ error: { ...error } });
    }
}

const mapStateToProps = (state) => {
    return {
        formulas: state.formulas
    };
};

const mapDispatchToProps = {
    setFormulas
};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaVersionForm);