import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faChevronRight, faEye } from '@fortawesome/pro-solid-svg-icons';
// Librairies
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
// Ressources
import logoDT from '../../resources/images/logo-dt.png';
import logo from '../../resources/images/logo.png';
// Semantic UI
import { Button, Label, Menu, Popup } from 'semantic-ui-react';
// Services
import NotificationsService from '../../services/NotificationsService';
import FormattersUtil from '../../utils/FormattersUtil';
import DatesUtil from '../../utils/DatesUtil';
// Utils
import NotificationsUtil from '../../utils/NotificationsUtil';
import { isMobile } from 'react-device-detect';

class Notifications extends Component {
    state = {
        npOpen: false,
        notifications: [],
        activeNotification: 0
    }

    render() {
        const { notifications } = this.state;
        let unreadNotifications = 0;
        notifications.forEach(notification => { if (!notification.read) unreadNotifications++; });

        return (
            <Popup
                position={!isMobile ? 'bottom center' : 'bottom left'}
                positionFixed
                content={<>
                    <div style={{ display: 'flex', marginBottom: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3 style={{ margin: 0 }}>{i18n.t("Notifications")}</h3>
                        {unreadNotifications > 0 &&
                            <Button color='blue' style={{ padding: '3px 5px' }} title={i18n.t("Tout marquer comme lu")} onClick={this.readAllNotifications}>
                                <FontAwesomeIcon icon={faEye} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                            </Button>}
                    </div>
                    <div id='notifications-list' style={{ display: !notifications.length && 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {notifications.length > 0 ?
                            this.renderNotifications()
                            : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
                                <FontAwesomeIcon icon={faBell} size='4x' style={{ marginTop: 'auto' }} />
                                <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                            </div>}
                    </div>
                </>}
                style={{ padding: '8px', width: '300px' }}
                on='click'
                popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                trigger={
                    <Menu.Item title={i18n.t("Notifications")} as='a'>
                        <FontAwesomeIcon icon={faBell} />
                        {unreadNotifications > 0 &&
                            <Label color='blue' floating size='mini' style={{ top: '.8em', left: '90%', padding: '3px 5px', borderRadius: '10px' }}>
                                {unreadNotifications}
                            </Label>}
                    </Menu.Item>}
                onOpen={() => this.toggleNotifications(true)}
                onClose={() => this.toggleNotifications(false)}
            />
        );
    }

    componentDidMount = () => {
        this.loadNotifications();
    }

    loadNotifications = () => {
        if (this.state.notifications.length === 0 && this.props.isOnline)
            NotificationsService.getLatestNotifications().then(response => { this.setState({ notifications: response || [] }); });
    }

    renderNotifications = () => {
        const { notifications, activeNotification } = this.state;
        return notifications.map((notification, index) => {
            let link = notification.link;
            const isValidUrl = FormattersUtil.isValidUrl(link);
            if (!isValidUrl && link?.length && link[0] === '/') link = window.location.origin + link;
            const isOpen = activeNotification === index;

            return (
                <div
                    key={index} id={`notification-card-${notification.id}`} className={`notification-card${isOpen ? ' opened' : ''}`} onClick={() => this.handleActiveNotificationChange(notification)}
                    style={{ marginBottom: (index < notifications.length - 1) && '5px', marginRight: '5px', cursor: 'pointer' }}
                >
                    <div>
                        <FontAwesomeIcon icon={faChevronRight} style={{ margin: '0 16px 0 6px' }} />
                        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                            <h4 style={{ margin: 0, marginBottom: '5px' }}>{notification.title}</h4>
                            <div style={{ color: 'var(--white-40)' }}>{DatesUtil.convertUTCDateToLocaleDate(notification.date)}</div>
                        </div>
                        {!notification.senderId &&
                            <div>
                                {<img src={this.props.isDarkTheme ? logoDT : logo} alt='logo' style={{ height: '50px' }} />}
                            </div>}
                    </div>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p dangerouslySetInnerHTML={{ __html: notification.content }} style={{ margin: 0 }}></p>
                            {link &&
                                <Button color='blue' size='mini' as='a' href={link} target='_blank' style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginLeft: 'auto', minWidth: '105px' }}>
                                    <FontAwesomeIcon icon={faEye} style={{ marginRight: '10px' }} />{isValidUrl ? i18n.t("Consulter") : i18n.t("Accéder")}
                                </Button>}
                        </div>
                    </div>
                </div>
            );
        });
    }

    toggleNotification = (notification) => {
        if (notification.content) {
            const notificationCard = document.getElementById(`notification-card-${notification.id}`);
            if (notificationCard.classList.contains('opened')) notificationCard.classList.remove('opened');
            else notificationCard.classList.add('opened');
        }
    }

    handleActiveNotificationChange = (notification) => {
        const { notifications, activeNotification } = this.state;
        const index = notifications.indexOf(notification);
        const newIndex = activeNotification === index ? -1 : index;

        if (!notifications[index].read) {
            notifications[index].read = true;
            NotificationsUtil.readNotification(notifications[index].id);
        }

        if (activeNotification > -1 && !notifications[activeNotification].read) notifications[activeNotification].read = true;
        if (activeNotification !== -1 && activeNotification !== index) this.toggleNotification(notifications[activeNotification]);
        this.toggleNotification(notification);

        this.setState({ notifications: notifications, activeNotification: newIndex });
    }

    toggleNotifications = (state) => {
        const { notifications } = this.state;
        let { activeNotification } = this.state;

        if (notifications.length > 0) {
            if (!state || notifications[0].read) activeNotification = -1;
            else if (state && !notifications[0].read && activeNotification === 0) {
                notifications[0].read = true;
                NotificationsUtil.readNotification(notifications[0].id);
            }
        }

        this.setState({ npOpen: state, notifications: notifications, activeNotification: activeNotification });
    }

    readAllNotifications = () => {
        const { notifications } = this.state;

        notifications.forEach(notification => {
            if (!notification.read) {
                notification.read = true;
                NotificationsUtil.readNotification(notification.id);
            }
        });
        this.setState({ notifications: notifications });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
    };
};

export default connect(mapStateToProps, null)(Notifications);