import React, { Component } from 'react';
// Composants
import ProjectDetail from './ProjectDetail';
import { Button, Grid, Loader } from 'semantic-ui-react';
// Librairies
import { ScrollSync } from 'react-scroll-sync';
import TreesService from '../../services/TreesService';
import GreenSpacesService from '../../services/GreenSpacesService';
import FurnituresService from '../../services/FurnituresService';
import StationsService from '../../services/StationsService';
import i18n from '../../locales/i18n';
import { isMobile, withOrientationChange } from 'react-device-detect';
import { faLineColumns, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompareProjectsTree from '../Utils/CompareProjectsTree';

const initialState = {
    isLoading: true,
    nbLoaded: 0,
    nbToLoad: 4,
    trees: [],
    greenSpaces: [],
    furnitures: [],
    stations: [],
    showComparisonForm: false
};

class ProjectDetailComparison extends Component {
    state = {
        ...initialState
    }

    render() {
        const { trees, greenSpaces, furnitures, stations, projectToCompareWith } = this.props;
        const { showComparisonForm } = this.state;
        const isHorizontalSplit = isMobile && !this.props.isLandscape;

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <Button className='button--secondary' onClick={() => this.setState({ showComparisonForm: true })}>
                        <FontAwesomeIcon icon={faLineColumns} style={{ marginRight: '10px' }} />{i18n.t("Comparer avec")}
                    </Button>
                    <Button color='red' onClick={this.props.exitComparison}>
                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Quitter")}
                    </Button>
                </div>
                <ScrollSync>
                    <Grid style={{ height: '100%', marginTop: 0 }}>
                        {!this.state.isLoading ?
                            <Grid.Row columns={isHorizontalSplit ? 1 : 2} style={{ paddingTop: 0, paddingBottom: '45px' }}>
                                <Grid.Column style={{ paddingRight: !isHorizontalSplit && '5px' }}>
                                    <ProjectDetail trees={trees} greenSpaces={greenSpaces} furnitures={furnitures} stations={stations} isComparison={true} />
                                </Grid.Column>
                                <Grid.Column style={{ paddingTop: isHorizontalSplit && '10px', paddingLeft: !isHorizontalSplit && '5px' }}>
                                    <ProjectDetail
                                        projectToCompareWith={projectToCompareWith} trees={this.state.trees} greenSpaces={this.state.greenSpaces}
                                        furnitures={this.state.furnitures} stations={this.state.stations} isComparison={true}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            : <Grid.Row>
                                <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loader content={i18n.t("Chargement des données en cours...")} />
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid>
                </ScrollSync>
                {showComparisonForm && <CompareProjectsTree cancel={() => this.setState({ showComparisonForm: false })} compareProjectWith={this.props.compareProjectWith} />}
            </>
        );
    }

    componentDidMount = () => this.loadData();
    componentDidUpdate = (prevProps) => {
        if (prevProps.projectToCompareWith && this.props.projectToCompareWith && prevProps.projectToCompareWith.id !== this.props.projectToCompareWith.id)
            this.setState({ ...initialState }, this.loadData);
    }

    loadData = () => {
        const { projectToCompareWith } = this.props;
        TreesService.getTrees(projectToCompareWith.id, {}).then((response) => this.setState(prevState => ({ trees: response.trees, nbLoaded: prevState.nbLoaded + 1, isLoading: prevState.nbLoaded + 1 !== prevState.nbToLoad })));
        GreenSpacesService.getGreenSpaces(projectToCompareWith.id, {}).then((response) => this.setState(prevState => ({ greenSpaces: response.greenSpaces, nbLoaded: prevState.nbLoaded + 1, isLoading: prevState.nbLoaded + 1 !== prevState.nbToLoad })));
        FurnituresService.getFurnitures(projectToCompareWith.id, {}).then((response) => this.setState(prevState => ({ furnitures: response.furnitures, nbLoaded: prevState.nbLoaded + 1, isLoading: prevState.nbLoaded + 1 !== prevState.nbToLoad })));
        StationsService.getStations(projectToCompareWith.id, {}).then((response) => this.setState(prevState => ({ stations: response, nbLoaded: prevState.nbLoaded + 1, isLoading: prevState.nbLoaded + 1 !== prevState.nbToLoad })));
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }
}
export default withOrientationChange(ProjectDetailComparison);