import React, { Component } from 'react';

class TermsOfUseFrBe extends Component {
    render() {
        return (
            <div id='terms-of-use'>
                <small>Version en date du 1er avril 2022</small>
                <h2>Conditions générales d'utilisation</h2>
                <section>
                    <h3>Article 1. Dispositions préalables</h3>
                    La société Grality est une SRL, au capital social de 20 000
                    EUR, enregistrée à la banque carrefour des entreprises
                    sous le numéro 0762.595.093, dont le siège social est situé
                    Rue des Écoles (HA), 11/A 7911 Frasnes-les-Anvaing
                    (Belgique).
                    <br /><br />
                    La société Grality propose des services d’assistance à la
                    gestion et à l’évaluation des espaces verts et assimilés, au
                    travers d’une application web dénommé « Grality »,
                    accessible en ligne depuis le site internet dont est
                    propriétaire et éditrice la société Grality (www.grality.com ;
                    www.grality.be ; www.grality.fr).
                </section>
                <section>
                    <h3>Article 2. Définitions</h3>
                    Dans les présentes conditions générales d’utilisation, les
                    mots ou expressions commençant avec une majuscule
                    auront la signification suivante :
                    <ul>
                        <li>
                            <b>Application Web :</b>
                            <br />
                            désigne l’application web dont est
                            propriétaire et éditrice la Société (https://app.grality.com.),
                            par le biais de laquelle les abonnés ont accès aux Services
                            Grality.
                        </li>
                        <li>
                            <b>Abonné :</b>
                            <br />
                            désigne toute personne physique ou morale,
                            agissant dans le cadre de son activité professionnelle, qui
                            souscrit à l’une des Offres d’abonnement proposées par la
                            Société.
                        </li>
                        <li><b>CGU :</b> désigne le présent document et ses annexes éventuelles.</li>
                        <li>
                            <b>Données à Caractère Personnel/Données Personnelles :</b>
                            <br />
                            désigne toute information susceptible de
                            permettre l’identification d’une personne physique ou
                            morale de manière directe ou indirecte (nom ; prénom ;
                            adresse email ; adresse IP ; données de navigation ; etc.),
                            conformément à la définition donnée par l’article 4 du
                            Règlement Général de l’Union Européenne sur la
                            protection des Données (RGPD 2016/679).
                        </li>
                        <li>
                            <b>Partie(s) :</b>
                            <br />
                            désigne individuellement la Société ou un
                            Utilisateur et collectivement la Société et un Utilisateur.
                            Politique de Confidentialité : désigne l’Article 20 des
                            présentes.
                        </li>
                        <li>
                            <b>Services Grality :</b>
                            <br />
                            désigne les services proposés par la
                            Société en matière d’assistance à la gestion et à l’évaluation
                            des espaces verts et assimilés, destinés à une utilisation
                            professionnelle, accessibles depuis l’application Web, tels
                            que plus amplement détaillés aux présentes (de façon non
                            exhaustive), ainsi que directement sur le Site.
                        </li>
                        <li>
                            <b>Site :</b>
                            <br />
                            désigne le site internet dont est propriétaire et éditrice
                            la Société (www.grality.be), qui présente les Services Grality.
                            Société : désigne la société Grality.
                        </li>
                        <li>
                            <b>Utilisateur :</b>
                            <br />
                            désigne toute personne utilisant l’application
                            Web et le Site, afin notamment de souscrire à l’une des
                            Offres d’abonnements proposées par la Société.
                        </li>
                    </ul>
                </section>
                <section>
                    <h3>Article 3. Mentions légales</h3>
                    L’application Web et le Site sont édités et exploités par la
                    Société ; laquelle est une SRL, au capital social de 20 000
                    EUR, enregistrée à la banque carrefour des entreprises
                    sous le numéro 0762.595.093, dont le siège social est situé
                    Rue des Écoles (HA), 11/A 7911 Frasnes-les-Anvaing
                    (Belgique).
                    <br /><br />
                    Email : info@grality.be.
                    <br /><br />
                    Le directeur de la publication est Clément Van Daele.
                    L’application Web et le Site sont hébergés par la société
                    Microsoft Azure.
                </section>
                <section>
                    <h3>Article 4. Objet</h3>
                    Les présentes CGU ont notamment pour objet de détailler
                    leur champ d’application, ainsi que les conditions de leur
                    acceptation.
                    <br /><br />
                    Elles décrivent également : (i) les fonctionnalités proposées
                    par l’application Web et le Site ; (ii) de façon non
                    exhaustive les Offres d’abonnement et les Services Grality ;
                    (iii) ainsi que les conditions d’accès à l’application Web et
                    au Site, ainsi qu’aux Offres d’abonnement et aux Services
                    Grality.
                    <br /><br />
                    Elles traitent aussi de la limitation de responsabilité de la
                    Société.
                    <br /><br />
                    Les CGU comportent encore une clause relative à la
                    politique de confidentialité des Données à Caractère
                    Personnel/Données Personnelles de ses Utilisateurs.
                    <br /><br />
                    Les modalités souscription et de règlement des Offres
                    d’abonnement, sont pour leur part décrites aux conditions
                    générales d’abonnement de la Société.
                </section>
                <section>
                    <h3>Article 5. Champ d’application</h3>
                    Les Parties conviennent que leurs relations au titre de
                    l’utilisation du Site, sont régies par les présentes, à
                    l’exclusion de toute condition préalablement disponible sur
                    le Site.
                </section>
                <section>
                    <h3>Article 6. Acceptation expresse des CGU</h3>
                    Toute utilisation de l’application Web et du Site suppose la
                    consultation et l’acceptation expresse des présentes par les
                    Utilisateurs.
                    <br /><br />
                    En utilisant l’application Web et le Site, les Utilisateurs
                    reconnaissent avoir lu, compris et accepter sans réserve les
                    présentes.
                </section>
                <section>
                    <h3>Article 7. Fonctionnalités proposées par l’application Web et le Site</h3>
                    Le Site permet notamment à l’Utilisateur :
                    <ul>
                        <li>
                            de découvrir les Offres d'abonnement et les Services
                            Grality, dans les conditions d’accessibilité définies à
                            l’Article 9 des présentes ;
                        </li>
                    </ul>
                    Pour sa part, l’application Web permet notamment à l’Utilisateur :
                    <ul>
                        <li>
                            de découvrir les Offres d'abonnement et les Services
                            Grality, dans les conditions d’accessibilité définies à
                            l’Article 9 des présentes ;
                        </li>
                        <li>de souscrire auxdites Offres.</li>
                    </ul>
                    La liste des fonctionnalités n’est fournie qu’à titre indicatif.
                    La Société se réserve le droit d’en ajouter ou d’en
                    supprimer, temporairement ou définitivement, sans l’accord
                    de l’Utilisateur.
                </section>
                <section>
                    <h3>Article 8. Offres d'abonnement et Services Grality</h3>
                    La Société a développé une Application Web d’assistance à
                    la gestion et à l’évaluation des espaces verts et assimilés,
                    destinée à une utilisation principalement professionnelle.
                    Ainsi, seuls des professionnels ont la possibilité de souscrire
                    aux Offres d'abonnement payantes proposées par la
                    Société.
                    <br /><br />
                    Les Services Grality de ce logiciel comprennent
                    notamment : l’import/export des données des Utilisateurs
                    relatives à leurs espaces verts ; la mise à disposition de
                    cartes thématiques ; le partage d’informations ; la gestion
                    des interventions ; des outils statistiques ; la gestion des
                    collaborateurs des Utilisateurs ; des tutoriels interactifs ; des
                    filtres multiples ; des graphiques personnalisés ; des alertes
                    automatiques ; des historiques des modifications.
                    <br /><br />
                    Les Services Grality sont souscrits par l’Abonné, par le biais
                    de l’une des Offres d'abonnement proposées par la Société.
                    Les Services Grality proposés dans le cadre de ces Offres
                    d'abonnement diffèrent selon l’offre choisie par l’Abonné.
                    <br /><br />
                    Les Services Grality sont plus amplement détaillés au
                    travers du Site.
                </section>
                <section>
                    <h3>Article 9. Accès au Site et aux Offres d'abonnement</h3>
                    L’accès au Site est libre et gratuit.
                    <br /><br />
                    L’accès à l’application Web, aux Offres d'abonnement et
                    aux Services Grality est réservé aux Utilisateurs disposant
                    d’un compte personnel sur l’application Web (les
                    conditions d’ouverture de compte étant définies à l’Article
                    10).
                    <br /><br />
                    Les frais qui pourraient résulter de l’accès et de l’utilisation
                    de l’application Web et du Site sont à la charge exclusive de
                    l’Utilisateur.
                    <br /><br />
                    L’Application Web et le Site sont accessibles 24H/24 7J/7.
                    <br /><br />
                    La Société est en droit de modifier, sans indemnité, ni
                    préavis, les serveurs ou encore les heures d’accessibilité de
                    l’application Web et du Site, ainsi que de clôturer l’accès à
                    l’application Web et au Site, pour effectuer des mises à jour,
                    des modifications ou des améliorations.
                </section>
                <section>
                    <h3>Article 10. Ouverture d’un compte personnel</h3>
                    Seuls les Utilisateurs disposant d’un compte personnel sur
                    l’Application Web, ont la possibilité de souscrire à l’une des
                    Offres d’Abonnement proposées par la Société.
                    <br /><br />
                    L’inscription sur l’Application Web se fait par la création
                    d’un compte personnel, en suivant les indications prévues à
                    cet effet sur ladite Application Web.
                    <br /><br />
                    Notamment, l’Utilisateur doit communiquer des
                    informations personnelles au moyen des formulaires
                    successifs présents sur l’Application Web et le Site (email ;
                    mot de passe ; prénom ; nom ; société le cas échéant ;
                    adresse ; téléphone mobile).
                </section>
                <section>
                    <h3>Article 11. Engagements des Utilisateurs</h3>
                    Dans le cadre de leur utilisation de l’Application Web et du
                    Site, les Utilisateurs s’engagent :
                    <ul>
                        <li>
                            à ne pas utiliser un identifiant qui porterait atteinte aux
                            droits des tiers, à l’ordre public et aux bonnes moeurs,
                            ainsi qu’à fournir des informations exactes et à les
                            mettre à jour ;
                        </li>
                        <li>
                            à ne pas divulguer leurs identifiants, par quelque moyen
                            que ce soit. La confidentialité et l’utilisation de leurs
                            identifiants relèvent de leur seule responsabilité ;
                        </li>
                        <li>
                            à ne pas utiliser tout ou partie de leurs éléments, en
                            dehors des présentes ou d’une manière qui enfreindrait
                            la réglementation ;
                        </li>
                        <li>
                            à ne pas utiliser tout ou partie de leurs éléments, à
                            d’autres fins qu’un usage normal, étant précisé que seront notamment considérés comme ne relevant pas
                            d’un usage normal toute utilisation et activité jugées
                            disproportionnées ;
                        </li>
                        <li>
                            à signaler tout cas de suspicion ou d’utilisation non
                            autorisée de leurs comptes ou d’accès frauduleux à
                            leurs mots de passe ;
                        </li>
                        <li>
                            à ne publier, transmettre, diffuser, éditer ou rendre
                            accessible par le biais de le Site, aucun contenu qui
                            pourrait être constitutif, sans que cette liste soit
                            limitative, de diffamation, d’injures, de dénigrement, de
                            menaces, de chantage, de harcèlement, d’incitation à la
                            violence, à la haine raciale et plus généralement à la
                            réalisation de crimes et délits, de propagation de
                            fausses nouvelles ou d’informations financières
                            couvertes par le secret, de même que tout contenu
                            destiné à représenter ou proposer à la vente des objets
                            et/ou des ouvrages, des logiciels, des contenus interdits
                            par la loi ou portant atteinte aux droits des tiers,
                            d’atteinte à l’autorité de la justice, d’atteinte à la vie
                            privée, à la protection des données personnelles ou au
                            secret des correspondances, d’apologie de crimes
                            contre l’humanité ou de négation de génocide, de
                            divulgation d’informations couvertes par le secret ou
                            par le droit à l’intimité de la vie privée, ou encore d’acte
                            mettant en péril des mineurs, notamment par la
                            fabrication, la transmission, la diffusion ou
                            l’accessibilité de messages à caractère violent ou
                            pornographiques ou encore pédophilique, de nature à
                            porter atteinte à la dignité humaine ou de nature à
                            permettre la fabrication d’explosifs ;
                        </li>
                        <li>
                            à ne pas falsifier des données, messages ou documents,
                            des en-têtes de messages ou de données d’identification
                            ou de connexion ou manipuler de toute autre manière
                            un identifiant ou une information de manière à
                            dissimuler l’origine de la transmission d’un contenu via
                            le Site ;
                        </li>
                        <li>
                            à ne pas télécharger sciemment, afficher, émettre,
                            diffuser, transmettre ou rendre accessible de toute autre
                            manière tout contenu comprenant ou constituant des
                            virus informatiques ou tout autre code ou programme
                            informatique conçus pour interrompre, détruire,
                            détourner ou limiter les fonctionnalités ou les
                            performances de le Site.
                            En cas de non-respect de l’un quelconque des engagements
                            de l’Utilisateur, la Société se réserve le droit de suspendre de
                            plein droit, sans indemnité, ni formalité préalable, le compte
                            dudit Utilisateur concerné, ainsi que de résilier toute
                            souscription ;
                        </li>
                    </ul>
                </section>
                <section>
                    <h3>Article 12. Connexion des Utilisateurs</h3>
                    Afin de se connecter à son compte sur l’Application Web,
                    l’Utilisateur indique, par le biais du formulaire dédié à cet
                    effet, son identifiant, ainsi que son mot de passe.
                </section>
                <section>
                    <h3>Article 13. Modification des CGU</h3>
                    Les présentes CGU sont disponibles sur le Site et
                    l’Application Web.
                    Toutefois, la Société peut être amenée à modifier les
                    présentes à tout moment, sans préavis, sous réserve d’en
                    informer ses Utilisateurs dès l’entrée en vigueur de ces
                    modifications par email et/ou par la publication desdites
                    modifications sur le Site et l’Application Web.
                    La Société actualisera les CGU en indiquant la date de
                    dernière mise à jour en haut à droite des présentes.
                </section>
                <section>
                    <h3>Article 14. Limitation de responsabilité</h3>
                    La responsabilité de la Société ne saurait être engagée pour
                    tous les inconvénients ou dommages inhérents à l'utilisation
                    du réseau internet, notamment une interruption ou rupture
                    de service, une intrusion extérieure ou la présence de virus
                    informatiques.
                </section>
                <section>
                    <h3>Article 15. Propriété intellectuelle</h3>
                    L’intégralité des éléments contenus sur l’Application Web et
                    le Site dont la Société est propriétaire et éditrice, sont
                    protégés par les lois relatives à la propriété intellectuelle.
                    Ainsi, la Société détient seule tous les droits, titres et
                    intérêts, y compris tous les droits de propriété intellectuelle
                    liés à ladite Application Web et audit Site, ainsi que les idées,
                    suggestions, demandes d’amélioration, commentaires, avis,
                    demandes de mise en relation, recommandations et autres
                    informations que les Abonnés et tiers communiqueraient
                    relativement à ladite Application Web et audit Site.
                    En outre, la Société détient les droits d’usage sur tous les
                    éléments accessibles sur son Application Web et son Site,
                    notamment les textes, images, graphismes, logo, icônes,
                    sons, logiciels, etc.
                    En conséquence, toute reproduction, représentation,
                    modification, publication, adaptation totale ou partielle des
                    éléments de l’Application Web et du Site, par quelque
                    moyen ou procédé que ce soit, est interdite, sauf accord
                    préalable express consenti par la Société.
                </section>
                <section>
                    <h3>Article 16. Politique de confidentialité</h3>
                    <div>
                        <h4>16.1. Objet</h4>
                        La présente Politique de Confidentialité a pour objectif de
                        définir les principes et lignes directrices mis en oeuvre par la
                        Société, en matière de traitement des Données à Caractère
                        Personnel des Utilisateurs.
                        <br /><br />
                        Plus précisément, la présente Politique de Confidentialité,
                        détaille notamment l’ensemble des traitements desdites
                        Données, des finalités poursuivies par ces derniers, ainsi que
                        les moyens à la disposition de ses Utilisateurs pour exercer
                        leurs droits.
                        <br /><br />
                        Pour toute information complémentaire sur la protection
                        des Données Personnelles, la Société recommande aux
                        Utilisateurs de se rendre sur le site de la CNIL (www.cnil.fr).
                    </div>
                    <div>
                        <h4>16.2. Données Personnelles</h4>
                        <div>
                            <h5>Collecte des Données Personnelles</h5>
                            Les Données Personnelles sont notamment collectées par la
                            Société à partir du Site (au moment de l’ouverture des
                            comptes des Utilisateurs sur ledit Site), de cookies, de
                            scripts et du navigateur de l’Utilisateur.
                            <br /><br />
                            Les principales Données Personnelles collectées : email ; mot de passe ; prénom ; nom.
                        </div>
                        <div>
                            <h5>Types, finalités et durée du traitement des Données Personnelles collectées</h5>
                            Conformément à la loi 78-17 du 6 janvier 1978, modifiée
                            par les lois du 6 août 2004 et 20 juin 2018, la Société
                            s’engage à traiter les Données Personnelles de ses
                            Utilisateurs, afin de :
                            <ul>
                                <li>
                                    gérer la « relation client »
                                    La Société traite les Données Personnelles, afin de
                                    gérer la « relation client », par exemple en enregistrant
                                    des informations de base concernant les Utilisateurs ou
                                    encore en communiquant avec eux.
                                    Dans ce cadre, la Société gère les informations
                                    d’identité de l’Utilisateur (prénom, nom) et ses
                                    coordonnées (adresse email).
                                    Le traitement est nécessaire pour que la Société puisse
                                    remplir ses obligations envers les Utilisateurs,
                                    conformément aux présentes.
                                    Ces Données Personnelles sont conservées aux fins
                                    expliquées ci-dessus, pendant toute la durée de la
                                    « relation client. »
                                </li>
                                <li>
                                    répondre aux demandes
                                    La Société traite les Données Personnelles, afin de
                                    répondre aux demandes des Utilisateurs.
                                    La Société traite les Données Personnelles suivantes
                                    pour répondre aux demandes des Utilisateurs : les
                                    informations d’identité de l’Utilisateur (prénom, nom)
                                    et ses coordonnées (adresse email).
                                    Le traitement est motivé par l’intérêt légitime de la
                                    Société à répondre aux questions de ses Utilisateurs et à
                                    leur offrir un service client de qualité.
                                </li>
                                <li>
                                    fourniture de newsletters
                                    La Société traite les Données Personnelles des
                                    Utilisateurs, afin de leur fournir des newsletters.
                                    La Société traite les Données Personnelles suivantes
                                    pour fournir des newsletters : les informations
                                    d’identité de l’Utilisateur (prénom, nom) et ses
                                    coordonnées (adresse email).
                                    En s’inscrivant à la newsletter de la Société, l’Utilisateur
                                    donne son consentement au traitement de ses Données
                                    Personnelles à cette fin.
                                    L’Utilisateur a le droit de se désinscrire des listes de
                                    newsletters de la Société, à tout moment.
                                    Ces Données Personnelles sont conservées à cet effet
                                    aussi longtemps que l’Utilisateur est inscrit à la
                                    newsletter de la Société.
                                </li>
                                <li>
                                    obligations légales
                                    La Société traite des Données Personnelles, afin de
                                    remplir ses obligations légales, par exemple en termes
                                    de comptabilité.
                                    A cette fin, la Société traite les Données Personnelles
                                    suivantes : les informations d’identité de l’Utilisateur
                                    (prénom, nom) et ses coordonnées (adresse email),
                                    ainsi que ses informations de paiement.
                                    Le traitement est nécessaire, afin que la Société puisse
                                    remplir les obligations légales qui sont les siennes,
                                    conformément aux lois et réglementations nationales et
                                    européennes.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h5>Destinataires des Données Personnelles</h5>
                            Dans le cadre de l’exécution du Service, les destinataires des
                            Données Personnelles collectées par la Société peuvent être
                            les partenaires de la Société ou encore les organismes de
                            paiement.
                            <br /><br />
                            En outre, la Société est en droit de divulguer les Données
                            Personnelles des Utilisateurs, en cas d’obligation par la loi
                            ou en cas de violation des présentes par lesdits Utilisateurs.
                            <br /><br />
                            La Société s’efforce de traiter les Données Personnelles au
                            sein de l’UE/EEE, afin que les Utilisateurs puissent être
                            rassurés quant à la protection desdites Données
                            Personnelles.
                            Dans certains cas, cependant, les Données Personnelles
                            peuvent être transférées et traitées par des prestataires de
                            services se trouvant dans des pays en dehors de l'UE/EEE.
                            <br /><br />
                            Pour garantir que les Données Personnelles sont toujours
                            protégées, la Société met en oeuvre, en toutes circonstances,
                            des mesures de sécurité adéquates, par exemple par des
                            accords de protection des données qui obligent les
                            sous-traitants à protéger les Données Personnelles de la
                            même manière que la Société. Une liste à jour des pays vers
                            lesquels la Société transfère, le cas échéant, les Données
                            Personnelles peut être obtenue sur demande.
                            <br /><br />
                            Les Utilisateurs ont également la possibilité de demander
                            des informations sur les mesures de sécurité mises en place
                            par la Société.
                        </div>
                    </div>
                    <div>
                        <h4>16.3. Consentement</h4>
                        Le consentement des Utilisateurs est présumé aux fins
                        d’utilisation desdites Données Personnelles, aux fins de
                        souscription au Service proposé par la Société.
                        <br /><br />
                        Lorsque les Données Personnelles des Utilisateurs sont
                        utilisées à des fins marketing, le consentement préalable
                        express des Utilisateurs est nécessaire. Les Utilisateurs ont
                        toujours la possibilité de rétracter leur consentement en
                        adressant un email à l’adresse : info@grality.be.
                    </div>
                    <div>
                        <h4>16.4. Stockage</h4>
                        Le Site est hébergé par la société Microsoft Azure.
                        <br /><br />
                        Les Données Personnelles recueillies sont hébergées par la
                        société Microsoft Azure
                    </div>
                    <div>
                        <h4>16.5. Sécurité</h4>
                        La Société s’engage à mettre en oeuvre tous les moyens
                        nécessaires pour assurer la sécurité et la confidentialité des
                        Données Personnelles.
                        <br /><br />
                        Ainsi, afin de protéger les Données Personnelles des
                        Utilisateurs de son Site, la Société prend un ensemble de
                        précautions et suit les meilleurs pratiques en la matière, pour
                        assurer que lesdites Données ne soient pas perdues,
                        détournées, consultées, divulguées, modifiées ou détruites
                        de manière inappropriée.
                    </div>
                    <div>
                        <h4>16.6. Cookies</h4>
                        <div>
                            <h5>Qu’est qu’un cookie ?</h5>
                            Un cookie ou traceur est un fichier électronique déposé sur
                            un terminal, tel qu’un ordinateur, une tablette ou un
                            smartphone, et notamment lu lors de la connexion à un site
                            internet, de la lecture d’un email, de l’installation ou de
                            l’utilisation d’un logiciel ou d’une application mobile, quel
                            que soit le type de terminal utilisé.
                        </div>
                        <div>
                            <h5>Pourquoi la Société utilise-t-elle des cookies ?</h5>
                            Au cas particulier, le bon fonctionnement du Site implique
                            la présence de cookies implantés dans l’ordinateur de
                            l’Utilisateur, lors de sa connexion, afin d’enregistrer les
                            informations relatives à la navigation (pages consultées, date
                            et heure de la consultation, etc.) et l’identité de ses
                            Utilisateurs.
                            Lors de la première connexion au Site, une bannière
                            d’explication sur l’utilisation des cookies apparaîtra. Dès
                            lors, en poursuivant la navigation, l’Utilisateur sera réputé
                            avoir été informé et avoir accepté l’utilisation desdits
                            cookies.
                            L’Utilisateur est en droit de s’opposer à l'utilisation des
                            cookies en configurant lui-même son logiciel de navigation.
                            L’Utilisateur a également la possibilité de rétracter son
                            consentement à l’utilisation de cookies, à tout moment.
                        </div>
                        <div>
                            <h5>Type de cookies utilisés sur le Site</h5>
                            <b>Cookies de session</b> - ces cookies expirent lorsque
                            l’Utilisateur ferme son navigateur web. La Société utilise ce
                            type de cookie pour, entre autres, faciliter la navigation sur
                            le Site. Certains de ces cookies sont essentiels au bon
                            fonctionnement du Site. Cela signifie que si l’Utilisateur
                            bloque complètement l'utilisation des cookies, il ne pourra
                            pas utiliser toutes les fonctions du Site.
                            <b>Cookies persistants</b> - ces cookies restent sur l’appareil de
                            l’Utilisateur pendant une période déterminée ou jusqu'à ce
                            qu’il les supprime. Ce type de cookie est utilisé dans le but
                            d'améliorer l'expérience de l’Utilisateur sur le Site,
                            notamment en faisant en sorte qu’il n’ait pas à se connecter
                            à chaque visite du Site. La Société utilise également les
                            informations des cookies persistants pour conserver des
                            statistiques sur le Site, afin qu’elle puisse améliorer ses
                            Services en fonction de ce que l’Utilisateur veut et apprécie.
                        </div>
                        <div>
                            <h5>Catégories de cookies utilisés sur le Site</h5>
                            <b>Cookies Fonctionnels et Requis</b> – la Société utilise
                            certains cookies nécessaires car ils permettent aux visiteurs
                            de naviguer et d'utiliser les fonctionnalités clés du Site/ Par
                            exemple, les cookies fonctionnels et requis peuvent aider
                            des fonctionnalités telles que les redirections d'URL. Sans
                            ces cookies, l’Utilisateur ne peut pas naviguer sur le Site.
                        </div>
                        <div>
                            <h5>Plus d’information sur les cookies</h5>
                            https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
                        </div>
                        <div>
                            <h5>Questions</h5>
                            Si vous avez des questions concernant cette politique de
                            cookies, n'hésitez pas à contacter la Société par email à
                            info@grality.be.
                        </div>
                    </div>
                    <div>
                        <h4>16.7. Droits relatifs aux Données Personnelles</h4>
                        L’Utilisateur dispose, conformément aux réglementations
                        nationales et européennes en vigueur d’un droit d’accès
                        permanent, de modification, de rectification, de limitation,
                        d’opposition et de portabilité s’agissant des informations le
                        concernant.
                        <br /><br />
                        Pour exercer ses droits, il suffit à l’Utilisateur d’écrire à
                        l’adresse email suivante : info@grality.be
                        <br /><br />
                        Par ailleurs, si un Utilisateur a des raisons de penser que la
                        sécurité de ses Données Personnelles a été compromise ou
                        que ces Données ont fait l’objet d’une utilisation abusive, il
                        est en droit de contacter la Société à l’adresse email
                        suivante : info@grality.be
                        <br /><br />
                        La Société instruira les réclamations concernant l’utilisation
                        et la divulgation de Données Personnelles et tentera de
                        trouver une solution conformément à la réglementation en
                        vigueur.
                        <br /><br />
                        L’Utilisateur a également la possibilité de formuler une
                        réclamation auprès de la CNIL, dont les coordonnées sont
                        mentionnées sur son site www.cnil.fr.
                    </div>
                </section>
                <section>
                    <h3>Article 17. Divers</h3>
                    <div>
                        <h4>17.1. Intégralité</h4>
                        Les Parties reconnaissent que les présentes constituent
                        l’intégralité des accords passés entre elles relatifs à leur objet
                        et remplacent tous les engagements antérieurs, verbaux
                        et/ou écrits, passés entre les Parties, relatifs audit objet. Sauf
                        stipulation expresse contraire, les termes et conditions et
                        obligations du présent document prévaudront sur tous
                        autres.
                    </div>
                    <div>
                        <h4>17.2. Nullité</h4>
                        La nullité, l'inopposabilité, ou plus généralement, l'absence
                        d'effet de l'une quelconque des stipulations des présentes
                        n'affectera pas les autres stipulations qui demeureront
                        parfaitement valables et contraignantes.
                    </div>
                    <div>
                        <h4>17.3. Non renonciation</h4>
                        Le fait pour l’une des Parties de ne pas se prévaloir d’un
                        manquement par l’autre Partie à l’une quelconque des
                        obligations visées aux présentes, ne saurait être interprétée
                        pour l’avenir comme une renonciation à l’obligation en
                        cause.
                    </div>
                </section>
                <section>
                    <h3>Article 18. Loi applicable et juridiction compétente</h3>
                    Les présentes sont soumises au droit français.
                    Le Tribunal de commerce de Lille sera seul compétent pour
                    connaître des contestations relatives aux CGU, à défaut
                    d’accord amiable entre les Parties.
                </section>
                <section>
                    <h3>Article 19. Version française et étrangère</h3>
                    Les présentes sont rédigées en français. En cas de
                    contradiction ou de contresens, elles prévaudront sur toute
                    autre version qui serait rédigée dans une autre langue à la
                    demande de l’Utilisateur.
                </section>
                <section>
                    <h3>Article 20. Élection de domicile</h3>
                    La Société élit domicile au Rue des Écoles (HA), 11/A 7911 Frasnes-les-Anvaing (Belgique).
                </section>
            </div>
        );
    }
}

export default TermsOfUseFrBe;