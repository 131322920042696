import React, { Component } from 'react';
// Librairies
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';

class CutDownsChart extends Component {
    state = {
        data: null,
        options: null
    }

    render() {
        return (
            <div className='modal-content'>
                <div className='modal-content-body'>
                    {this.state.data && this.state.options
                        ? <Bar data={this.state.data} options={this.state.options} />
                        : i18n.t("Aucune donnée trouvée")}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.loadData();
        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('UpdateElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('RemoveElements', this.loadData);
        }
    }

    loadData = () => {
        // On récupert la liste des genres
        const genders = [...new Set(this.props.essences.filter(essence => essence.gender).map(essence => essence.gender)), i18n.t("Non renseigné")];
        const valuesTCD = [genders.map(() => 0), genders.map(() => 0), genders.map(() => 0), genders.map(() => 0), genders.map(() => 0)]; // Pas à abattre, Abattage 1 - 3 ans, Abattage < 1 an, Abattage d'urgence, Abattu
        const gendersList = genders.reduce((prevValue, gender, index) => ({ ...prevValue, [gender]: index }), {});

        // On compte le nombre d'arbres à abattre et à ne pas abattre
        this.props.layer.eachLayer(layer => {
            const gender = this.props.essences.find(essence => essence.id === layer.feature.properties.essenceId)?.gender;
            valuesTCD[layer.feature.properties.toCutDown || 0][gendersList[gender || i18n.t("Non renseigné")]]++;
        });

        // On retire les genres qui ont une valeur de 0
        for (let i = valuesTCD[1].length - 1; i >= 0; i--)
            if (valuesTCD.slice(1, 5).reduce((prevValue, count) => prevValue + count[i], 0) < 1) {
                genders.splice(i, 1);
                for (let j = 0; j <= 4; j++)
                    valuesTCD[j].splice(i, 1);
            }

        if (genders.length > 0) {
            const themeColor = this.props.isDarkTheme ? 'white' : 'black';
            this.setState({
                data: {
                    labels: genders,
                    datasets: [
                        {
                            label: `${i18n.t("Aucun abattage")} (${valuesTCD[0].reduce((prevValue, cutDowns) => prevValue + cutDowns, 0)})`,
                            backgroundColor: 'green',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1,
                            data: valuesTCD[0],
                        },
                        {
                            label: `${i18n.t("Abattage 1 - 3 ans")} (${valuesTCD[1].reduce((prevValue, cutDowns) => prevValue + cutDowns, 0)})`,
                            backgroundColor: 'yellow',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1,
                            data: valuesTCD[1],
                        },
                        {
                            label: `${i18n.t("Abattage < 1 an")} (${valuesTCD[2].reduce((prevValue, cutDowns) => prevValue + cutDowns, 0)})`,
                            backgroundColor: 'orange',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1,
                            data: valuesTCD[2],
                        },
                        {
                            label: `${i18n.t("Abattage d'urgence")} (${valuesTCD[3].reduce((prevValue, cutDowns) => prevValue + cutDowns, 0)})`,
                            backgroundColor: 'red',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1,
                            data: valuesTCD[3],
                        },
                        {
                            label: `${i18n.t("Abattu")} (${valuesTCD[4].reduce((prevValue, cutDowns) => prevValue + cutDowns, 0)})`,
                            backgroundColor: 'black',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1,
                            data: valuesTCD[4],
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: true,
                        position: isMobileOnly ? 'bottom' : 'right',
                        labels: {
                            fontColor: themeColor
                        }
                    },
                    tooltips: {
                        mode: 'label',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                return data.datasets[tooltipItem.datasetIndex].label.replace(/\s*\(.*?\)\s*/g, '') + ' : ' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Genre"),
                                fontColor: themeColor
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                fontColor: themeColor
                            },
                            scaleLabel: {
                                display: true,
                                labelString: i18n.t("Nombre d'arbres"),
                                fontColor: themeColor
                            }
                        }]
                    },
                    plugins: {
                        labels: {
                            render: () => ''
                        }
                    }
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        essences: state.essences,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
};

export default connect(mapStateToProps)(CutDownsChart);