import React, { Component } from 'react';
// Composants
/*     Editors     */
import BooleanEditor from '../Editors/BooleanEditor';
import DateEditor from '../Editors/DateEditor';
import DropDownEditor from '../Editors/DropDownEditor';
import NumberEditor from '../Editors/NumberEditor';
import TextEditor from '../Editors/TextEditor';
import DropdownCustomFieldValuesEditor from '../Editors/DropdownCustomFieldValuesEditor';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
import BooleanFilter from '../../Tables/Filters/BooleanFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
import DropDownFilter from '../../Tables/Filters/DropDownFilter';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setProjects } from '../../../actionCreators/projectsActions';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Icon, Segment, TransitionablePortal, Modal, Label } from 'semantic-ui-react';
// Services
import CustomFieldsService from '../../../services/CustomFieldsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import DatesUtil from '../../../utils/DatesUtil';

const initialFilters = {
    label: '',
    type: '',
    category: '',
    maxLength: '',
    min: '',
    max: '',
    step: '',
    formula: '',
    minDate: '',
    maxDate: '',
    isMultiple: '',
    isNumeric: '',
    // dropdownCustomFieldValues: '',
    forEmpty: '',
    forDead: '',
    forStump: ''
};

const categoryOptions = [
    { label: i18n.t("Arbre"), value: 'Arbre' },
    { label: i18n.t("Espace vert"), value: 'Espace vert' },
    { label: i18n.t("Mobilier"), value: 'Mobilier' }
];

const typeOptions = [
    { label: i18n.t("Booléen"), value: 'boolean' },
    { label: i18n.t("Texte"), value: 'text' },
    { label: i18n.t("URL"), value: 'url' },
    { label: i18n.t("Nombre"), value: 'number' },
    { label: i18n.t("Formule"), value: 'formula' },
    { label: i18n.t("Date"), value: 'date' },
    { label: i18n.t("Liste déroulante"), value: 'list' }
];

class CustomFieldTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        elementsToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false,
        displayedList: null
    };

    render() {
        const {
            data, elementsToModify, elementsToDelete, modificationsHistory, modificationsHistoryIndex,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isLoading, displayedList
        } = this.state;
        const rows = this.getFilteredRows();

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <TransitionablePortal open={displayedList ? true : false} transition={{ animation: 'fade up', duration: 300 }}>
                    <Modal open mountNode={document.getElementById('admin-panel__grid')} onClose={() => this.setState({ displayedList: null })}>
                        {displayedList && <DropdownCustomFieldValuesEditor displayedList={displayedList} updateListValues={this.updateListValues} />}
                    </Modal>
                </TransitionablePortal>
                <Dimmer active={isUpdating} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={i18n.t("Mise à jour des champs personnalisés en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des champs personnalisés")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                        <Button
                                            title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                            onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1 && elementsToDelete.length < 1}
                                        />
                                    </>
                                </Button.Group>
                            </Menu.Item>
                            {elementsToDelete.length > 0 &&
                                <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                    <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + elementsToDelete.length} />
                                </Menu.Item>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                            <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Libellé"), key: 'label', width: 250,
                sortable: true, editable: true, frozen: true,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Type"), key: 'type', width: 150,
                sortable: true, editable: true,
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={typeOptions} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor(
                    'dropdown', row, column, onRowChange, onClose,
                    { options: typeOptions }
                ),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Catégorie"), key: 'category', width: 125,
                sortable: true, editable: true,
                formatter: (props) => i18n.t(props.row.category),
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={categoryOptions} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor(
                    'dropdown', row, column, onRowChange, onClose,
                    { options: categoryOptions }
                ),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Longueur maximale"), key: 'maxLength', width: 160,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Texte") ? <div className='disabled'></div> : props.row.maxLength || '',
                filterRenderer: (props) => <NumberFilter p={props} />,
                editable: (props) => props.type === i18n.t("Texte") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 1, step: 1, isNullable: true })
            },
            {
                name: i18n.t("Minimum"), key: 'min', width: 110,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Nombre") ? <div className='disabled'></div> : props.row.min || '',
                filterRenderer: (props) => <NumberFilter p={props} />,
                editable: (props) => props.type === i18n.t("Nombre") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { step: 1, isNullable: true })
            },
            {
                name: i18n.t("Maximum"), key: 'max', width: 110,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Nombre") ? <div className='disabled'></div> : props.row.max || '',
                filterRenderer: (props) => <NumberFilter p={props} />,
                editable: (props) => props.type === i18n.t("Nombre") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { step: 1, isNullable: true })
            },
            {
                name: i18n.t("Étape"), key: 'step', width: 110,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Nombre") ? <div className='disabled'></div> : props.row.step || '',
                filterRenderer: (props) => <NumberFilter p={props} />,
                editable: (props) => props.type === i18n.t("Nombre") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0.001, step: 0.001 })
            },
            {
                name: i18n.t("Date minimale"), key: 'minDate', width: 150,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Date") ? <div className='disabled'></div> : props.row.minDate || '',
                filterRenderer: (props) => <TextFilter p={props} />,
                editable: (props) => props.type === i18n.t("Date") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('date', row, column, onRowChange, onClose, { futureDate: true })
            },
            {
                name: i18n.t("Date maximale"), key: 'maxDate', width: 150,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Date") ? <div className='disabled'></div> : props.row.maxDate || '',
                filterRenderer: (props) => <TextFilter p={props} />,
                editable: (props) => props.type === i18n.t("Date") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('date', row, column, onRowChange, onClose, { futureDate: true })
            },
            {
                name: i18n.t("Multiple"), key: 'isMultiple', width: 110,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Liste déroulante") ? <div className='disabled'></div>
                    : props.row.isMultiple === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editable: (props) => props.type === i18n.t("Liste déroulante") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Numérique"), key: 'isNumeric', width: 110,
                sortable: true,
                formatter: (props) => props.row.type !== i18n.t("Liste déroulante") ? <div className='disabled'></div>
                    : props.row.isNumeric === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editable: (props) => props.type === i18n.t("Liste déroulante") ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Choix"), key: 'dropdownCustomFieldValues', width: 100,
                sortable: true, editable: false, resizable: false,
                formatter: (props) => props.row.type !== i18n.t("Liste déroulante") ? <div className='disabled'></div>
                    : <Button
                        color='blue' style={{ padding: '6px 20px' }} content='Voir'
                        onClick={() => this.showListValues(props.row)}
                    />,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Vide"), key: 'forEmpty', width: 110,
                sortable: true,
                formatter: (props) => props.row.category !== 'Arbre' ? <div className='disabled'></div>
                    : props.row.forEmpty === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editable: (props) => props.category === 'Arbre' ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Mort"), key: 'forDead', width: 110,
                sortable: true,
                formatter: (props) => props.row.category !== 'Arbre' ? <div className='disabled'></div>
                    : props.row.forDead === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editable: (props) => props.category === 'Arbre' ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Souche"), key: 'forStump', width: 110,
                sortable: true,
                formatter: (props) => props.row.category !== 'Arbre' ? <div className='disabled'></div>
                    : props.row.forStump === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editable: (props) => props.category === 'Arbre' ? true : false,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            }
        ];

        // Ajout des données
        CustomFieldsService.getCustomFields().then(customFields => {
            data.rows = customFields.map(customField => {
                return {
                    id: customField.id,
                    label: customField.label,
                    type: typeOptions.find(option => option.value === customField.type)?.label,
                    category: customField.category,
                    maxLength: customField.maxLength,
                    min: customField.min,
                    max: customField.max,
                    step: customField.step,
                    formula: customField.formula,
                    minDate: customField.minDate ? DatesUtil.getFormattedLocaleDateString(customField.minDate) : '',
                    maxDate: customField.maxDate ? DatesUtil.getFormattedLocaleDateString(customField.maxDate) : '',
                    isMultiple: customField.isMultiple ? i18n.t("Oui") : i18n.t("Non"),
                    isNumeric: customField.isNumeric ? i18n.t("Oui") : i18n.t("Non"),
                    dropdownCustomFieldValues: customField.dropdownCustomFieldValues,
                    forEmpty: customField.forEmpty ? i18n.t("Oui") : i18n.t("Non"),
                    forDead: customField.forDead ? i18n.t("Oui") : i18n.t("Non"),
                    forStump: customField.forStump ? i18n.t("Oui") : i18n.t("Non")
                };
            }).reverse();

            const elements = customFields.map(customField => {
                return {
                    id: customField.id,
                    label: customField.label,
                    type: customField.type,
                    category: customField.category,
                    isGlobal: customField.isGlobal,
                    maxLength: customField.maxLength,
                    min: customField.min,
                    max: customField.max,
                    step: customField.step,
                    formula: customField.formula,
                    minDate: customField.minDate,
                    maxDate: customField.maxDate,
                    isMultiple: customField.isMultiple,
                    isNumeric: customField.isNumeric,
                    dropdownCustomFieldValues: customField.dropdownCustomFieldValues,
                    forEmpty: customField.forEmpty,
                    forDead: customField.forDead,
                    forStump: customField.forStump
                };
            }).reverse();

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data: data, elements: elements, initialOrder: initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.label ? $(r.label).includes($(filters.label)) : true)
                && (filters.type ? $(r.type).includes($(filters.type)) : true)
                && (filters.category ? $(r.category).includes($(filters.category)) : true)
                && (filters.minDate ? $(r.minDate).includes($(filters.minDate)) : true)
                && (filters.maxDate ? $(r.maxDate).includes($(filters.maxDate)) : true)
                && (filters.maxLength ? r.maxLength === Number(filters.maxLength) : true)
                && (filters.min ? r.min === Number(filters.min) : true)
                && (filters.max ? r.max === Number(filters.max) : true)
                && (filters.step ? r.step === Number(filters.step) : true)
                && (filters.formula ? $(r.formula).includes($(filters.formula)) : true)
                && (filters.isMultiple ? r.type === i18n.t("Liste déroulante") && r.isMultiple === filters.isMultiple : true)
                && (filters.isNumeric ? r.type === i18n.t("Liste déroulante") && r.isNumeric === filters.isNumeric : true)
                && (filters.dropdownCustomFieldValues ? r.type === i18n.t("Liste déroulante") && r.dropdownCustomFieldValues.find(dcfv => $(dcfv.label).includes($(filters.dropdownCustomFieldValues))) : true)
                && (filters.forEmpty ? r.category === 'Arbre' && r.forEmpty === filters.forEmpty : true)
                && (filters.forDead ? r.category === 'Arbre' && r.forDead === filters.forDead : true)
                && (filters.forStump ? r.category === 'Arbre' && r.forStump === filters.forStump : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'dropdownCustomFieldValues')
                rows = rows.sort((a) => a.type !== i18n.t("Liste déroulante") ? -1 : 1);
            else if (['maxLength', 'min', 'max', 'step'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    if ((sortColumn === 'maxLength' && a.type !== i18n.t("Texte")) || (sortColumn !== 'maxLength' && a.type !== i18n.t("Nombre"))) return -1;
                    return a[sortColumn] - b[sortColumn];
                });
            else if (['minDate', 'maxDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => {
                if (['isMultiple', 'isNumeric'].includes(sortColumn) && a.type !== i18n.t("Liste déroulante")) return -1;
                if (['forEmpty', 'forDead', 'forStump'].includes(sortColumn) && a.category !== 'Arbre') return -1;
                return (a[sortColumn] || '').localeCompare(b[sortColumn] || '')
            });

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete = elementsToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }

            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)
                    || previousModification[0].property === 'delete') {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties
                || nextModification[0].property === 'delete') {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) {
            case 'type': return typeOptions.find(option => option.label === value)?.value;
            case 'isMultiple': return value === i18n.t("Oui");
            case 'isNumeric': return value === i18n.t("Oui");
            case 'forEmpty': return value === i18n.t("Oui");
            case 'forDead': return value === i18n.t("Oui");
            case 'forStump': return value === i18n.t("Oui");
            case 'minDate': case 'maxDate': return value ? new Date(value.replaceAll('-', '/').split('/').reverse().join('/')).toISOString() : null;
            default: return value;
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getPropertyValue(columnKey, sourceRow[columnKey]);;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (_, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => {
        return {
            ...element,
            type: typeOptions.find(option => option.value === element.type)?.label,
            isMultiple: element.isMultiple ? i18n.t("Oui") : i18n.t("Non"),
            isNumeric: element.isNumeric ? i18n.t("Oui") : i18n.t("Non"),
            forEmpty: element.forEmpty ? i18n.t("Oui") : i18n.t("Non"),
            forDead: element.forDead ? i18n.t("Oui") : i18n.t("Non"),
            forStump: element.forStump ? i18n.t("Oui") : i18n.t("Non")
        };
    }

    getChildInstance = (customField) => { // Inutile pour l'instant car le back ajuste déjà l'objet mais permettra l'offline
        if (customField.category !== 'Arbre') {
            customField.forEmpty = false;
            customField.forDead = false;
            customField.forStump = false;
        }
        if (customField.type !== 'text')
            delete customField.maxLength;
        if (customField.type !== 'number') {
            delete customField.min;
            delete customField.max;
            delete customField.step;
        }
        if (customField.type !== 'formula') delete customField.formula;
        if (customField.type !== 'date') {
            delete customField.minDate;
            delete customField.maxDate;
        }
        if (customField.type !== 'list') {
            delete customField.isMultiple;
            delete customField.isNumeric;
            delete customField.dropdownCustomFieldValues;
        } else {
            if (customField.isNumeric) customField.dropdownCustomFieldValues = customField.dropdownCustomFieldValues.filter(dcfv => dcfv.label.trim() && dcfv.value.trim() && !isNaN(dcfv.value));
            else customField.dropdownCustomFieldValues = customField.dropdownCustomFieldValues.filter(dcfv => dcfv.label.trim()).map(dcfv => ({ ...dcfv, value: dcfv.label }));
        }

        return customField;
    }

    /*     Suppression     */
    deleteRow = (_, { rowIdx }) => {
        let elementsToDelete = this.state.elementsToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        elementsToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: rowIndex, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, elementsToDelete, selectedRow: null, selectedColumn: null });
    }

    handleSubmit = () => {
        let { elementsToModify, elementsToDelete, elements } = this.state;
        let elementsNotToModifyAnymore = [];

        elementsToModify.forEach((elementToModify, index) => {
            const customField = this.getChildInstance(elementToModify);
            if (JSON.stringify(elementToModify) === JSON.stringify(this.state.elements.find(element => element.id === elementToModify.id))
                || elementsToDelete.find(element => element.id === elementToModify.id))
                elementsNotToModifyAnymore.push(elementToModify);
            else elementsToModify[index] = customField;
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        if (elementsToModify.length > 0 || elementsToDelete.length > 0) {
            this.setState({ isUpdating: true });
            let promises = [];

            const getUpdatePromise = (customFields) => (
                new Promise(resolve => {
                    CustomFieldsService.updateCustomFields(customFields, false).then(response => {
                        if (response) {
                            elementsToModify.forEach(elementToModify => {
                                const index = elements.findIndex(element => element.id === elementToModify.id);
                                elements[index] = elementToModify;
                            });
                            this.setState(prevState => ({ elementsToModify: prevState.elementsToModify.filter(element => !customFields.find(cf => cf === element)) }), () => resolve());
                        }
                    });
                })
            );

            if (elementsToModify.length > 0) {
                const booleanCustomFields = elementsToModify.filter(customField => customField.type === 'boolean');
                if (booleanCustomFields.length > 0) promises.push(getUpdatePromise(booleanCustomFields));
                const stringCustomFields = elementsToModify.filter(customField => customField.type === 'text');
                if (stringCustomFields.length > 0) promises.push(getUpdatePromise(stringCustomFields));
                const urlCustomFields = elementsToModify.filter(customField => customField.type === 'url');
                if (urlCustomFields.length > 0) promises.push(getUpdatePromise(urlCustomFields));
                const dateCustomFields = elementsToModify.filter(customField => customField.type === 'date');
                if (dateCustomFields.length > 0) promises.push(getUpdatePromise(dateCustomFields));
                const numberCustomFields = elementsToModify.filter(customField => customField.type === 'number');
                if (numberCustomFields.length > 0) promises.push(getUpdatePromise(numberCustomFields));
                const formulaCustomFields = elementsToModify.filter(customField => customField.type === 'formula');
                if (formulaCustomFields.length > 0) promises.push(getUpdatePromise(formulaCustomFields));
                const dropdownCustomFields = elementsToModify.filter(customField => customField.type === 'list');
                if (dropdownCustomFields.length > 0) promises.push(getUpdatePromise(dropdownCustomFields));
            }

            if (elementsToDelete.length > 0)
                promises.push(new Promise(resolve => {
                    CustomFieldsService.deleteCustomFields(elementsToDelete.map(element => element.id), false).then(response => {
                        if (response) {
                            elements = elements.filter(element => !elementsToDelete.find(elementToDelete => elementToDelete.id === element.id));
                            this.setState({ elementsToDelete: [] }, () => resolve());
                        }
                    });
                }));

            Promise.all(promises).then(() => {
                showToast('custom_fields_updated');
                this.setState({ elements: elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
            });
        } else {
            showToast('custom_fields_updated');
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
        }
    }

    showListValues = (customField) => this.setState({ displayedList: JSON.parse(JSON.stringify(customField)) });

    updateListValues = (id, dropdownCustomFieldValues) => {
        let rows = this.state.data.rows;
        const rowIndex = rows.findIndex(row => row.id === id);
        if (JSON.stringify(rows[rowIndex].dropdownCustomFieldValues) !== JSON.stringify(dropdownCustomFieldValues)) {
            let elementsToModify = this.state.elementsToModify;
            const index = elementsToModify.findIndex(element => element.id === id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === id)));
                element.dropdownCustomFieldValues = dropdownCustomFieldValues;
                elementsToModify.push(element);
            } else elementsToModify[index].dropdownCustomFieldValues = dropdownCustomFieldValues;
            this.changeElementsToModify(elementsToModify);

            this.pushToModificationsHistory([{ property: 'dropdownCustomFieldValues', elementId: id, oldValue: rows[rowIndex].dropdownCustomFieldValues }]);
            rows[rowIndex].dropdownCustomFieldValues = dropdownCustomFieldValues;
            this.setState(prevState => ({ data: { ...prevState.data, rows: rows } }));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        userInfos: state.userInfos,
        projects: state.projects,
        formulas: state.formulas
    };
};

const mapDispatchToProps = {
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldTable);