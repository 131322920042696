import i18n from "../locales/i18n";

export default class LanguagesUtil {
    static clearLists() {
        const itemsToRemove = [
            'actions', 'projections', 'vigors', 'furnitureTypes', 'spaceFunctions', 'coverTypes', 'spaceTypes', 'ontogenicStages', 'patrimonialCoefficients', 'conditions',
            'actions', 'dominantCompositions', 'essences', 'formulas', 'spaceTypeCategories', 'interactions', 'microHabitats', 'rootSymptoms', 'collarSymptoms', 'trunkSymptoms', 'branchSymptoms',
            'leafSymptoms', 'pathogens', 'pests', 'epiphytes', 'risks', 'tippingRisks', 'organCalibers', 'targets', 'plantationCoefficients', 'treePorts',
            'plantationTypes', 'situationCoefficients', 'healthReviews'
        ];

        for (const item in localStorage)
            if (itemsToRemove.includes(item))
                localStorage.removeItem(item);
    }

    static getCultureFlagName(culture) {
        if (culture.toLowerCase().includes('fr')) return 'fr';
        if (culture.toLowerCase().includes('nl')) return 'nl';
        if (culture.toLowerCase().includes('us')) return 'gb';
        if (culture.toLowerCase().includes('es')) return 'es';
        if (culture.toLowerCase().includes('zh')) return 'tw';
        return '';
    }

    static getUserCulture(userInfos) {
        let culture;
        if (userInfos?.language?.culture) culture = userInfos.language.culture;
        else {
            const userCulture = localStorage.getItem('i18nextLng') || window.navigator.language;
            if (userCulture.toLowerCase().startsWith('en')) culture = 'en-US';
            else if (userCulture.toLowerCase().startsWith('nl')) culture = 'nl-BE';
            else if (userCulture.toLowerCase().startsWith('es')) culture = 'es-ES';
            else culture = 'fr-BE';
        }

        i18n.changeLanguage(culture);
        return culture;
    }
}