import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileAlt, faFlowerTulip, faFolder, faFolderOpen, faHome, faRightLeft, faTablePicnic, faTimes, faTree, faUsers } from '@fortawesome/pro-solid-svg-icons';
import GaugeProgressBar from '../../Utils/GaugeProgressBar';
import { Button, Divider, Form, Grid, Input, Label, Select } from 'semantic-ui-react';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import tinycolor from 'tinycolor2';
import { setActiveOrganization } from '../../../actionCreators/usersActions';
import { setProjects } from '../../../actionCreators/projectsActions';
// Services
import OrganizationsService from '../../../services/OrganizationsService';
import ProjectsService from '../../../services/ProjectsService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const $ = (str) => FormattersUtil.getNormalizedString(str);

class OrganizationProjects extends Component {
    state = {
        searchQuery: '',
        isLoading: false,
        isTransferring: false,
        selectedElements: [],
        selectedOrganization: null,
        baseProjects: [],
    }

    render() {
        const { isDarkTheme, userSubscriptionConsumptions, organizations, isOnline, loginAsData, activeOrganization } = this.props;
        const { searchQuery, isTransferring, selectedElements, selectedOrganization, baseProjects } = this.state;

        const organizationOptions = organizations
            .filter(organization => organization.id !== activeOrganization.id)
            .map(organization => ({ text: organization.label, value: organization.id }));

        const uscToRender = userSubscriptionConsumptions.filter(usc => !searchQuery?.trim() || (
            $(usc.project.label)?.includes($(searchQuery)) ||
            $(FormattersUtil.formatLastNameAndFirstName(usc.user.lastName, usc.user.firstName))?.includes($(searchQuery)) ||
            $(`${usc.nbTrees}`)?.includes(searchQuery) ||
            $(`${usc.nbGreenSpaces}`)?.includes(searchQuery) ||
            $(`${usc.nbFurnitures}`)?.includes(searchQuery)
        ));

        return (
            <>
                <h3 style={{ margin: '0 0 5px 0' }}> {i18n.t("Consommation des projets")}</h3 >
                <Form style={{ width: '250px' }}>
                    <Form.Field control={Input} placeholder={i18n.t("Rechercher...")} value={searchQuery} onChange={(_, { value }) => this.setState({ searchQuery: value })} />
                </Form>
                <Grid style={{ margin: '5px 0px 0 0px', backgroundColor: isDarkTheme ? 'var(--black-90)' : 'var(--white-90)', borderRadius: '10px' }}>
                    <Grid.Row stretched style={{ fontWeight: 'bold', paddingBottom: 0 }}>
                        <Grid.Column computer={4} tablet={7} mobile={7}>{i18n.t("Projet")}</Grid.Column>
                        {!isMobile && <>
                            <Grid.Column width={3}>{i18n.t("Propriétaire")}</Grid.Column>
                        </>}
                        <Grid.Column width={3}>{!isMobile ? i18n.t("Arbres") : <FontAwesomeIcon icon={faTree} />}</Grid.Column>
                        <Grid.Column width={3}>{!isMobile ? i18n.t("Espaces verts") : <FontAwesomeIcon icon={faFlowerTulip} />}</Grid.Column>
                        <Grid.Column width={3}>{!isMobile ? i18n.t("Mobiliers") : <FontAwesomeIcon icon={faTablePicnic} />}</Grid.Column>
                    </Grid.Row>
                    <Divider style={{ margin: '10px 0' }} />
                    <Grid.Row style={{ height: '350px', maxHeight: '350px', overflow: 'auto' }}>
                        <Grid.Column>
                            {uscToRender.length > 0 ?
                                <Grid>
                                    {this.renderProjectsConsumptions(uscToRender)}
                                </Grid>
                                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                    <FontAwesomeIcon icon={faUsers} size='6x' />
                                    <p style={{ margin: '10px 0 0 0' }}>{i18n.t("Aucun projet trouvé.")}</p>
                                </div>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <h3 style={{ margin: '10px 0 5px 0' }}> {i18n.t("Arborescence des projets")}</h3 >
                {!isTransferring ?
                    organizations?.length > 1 &&
                    <div style={{ display: 'flex', alignItems: 'center', height: '38px', marginBottom: '10px' }}>
                        <Button
                            color='blue' disabled={!isOnline || loginAsData?.readOnly}
                            onClick={() => this.setState(prevState => ({ isTransferring: !prevState.isTransferring, selectedElements: prevState.isTransferring ? [] : prevState.selectedElements }))}
                            style={{ marginLeft: 'auto', marginRight: 0 }}
                        >
                            <FontAwesomeIcon icon={faRightLeft} style={{ marginRight: '10px' }} />{i18n.t("Transférer")}
                        </Button>
                    </div>
                    : <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <Select
                            placeholder={i18n.t("Sélectionnez l'organisation cible...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                            options={organizationOptions} value={selectedOrganization} onChange={(_, { value }) => this.setState({ selectedOrganization: value })}
                        />
                        <Button color='red' style={{ margin: '0 0 0 5px', padding: '11px' }} onClick={() => this.setState({ isTransferring: false, selectedElements: [], selectedOrganization: null })}>
                            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                        </Button>
                        <Button color='green' style={{ margin: '0 0 0 5px', padding: '11px' }} disabled={!selectedElements?.length || !selectedOrganization} onClick={this.transferElements}>
                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                        </Button>
                    </div>}
                <Grid style={{ margin: '5px 0px 0 0px', backgroundColor: isDarkTheme ? 'var(--black-90)' : 'var(--white-90)', borderRadius: '10px' }}>
                    <Grid.Row style={{ height: '350px', maxHeight: '350px', overflow: 'auto' }}>
                        <Grid.Column>
                            {baseProjects.length > 0
                                ? this.renderTreeStructure()
                                : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                    <FontAwesomeIcon icon={faFolder} size='6x' />
                                    <p style={{ margin: '10px 0 0 0' }}>{i18n.t("Aucun projet/dossier trouvé.")}</p>
                                </div>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {
                    isTransferring &&
                    <Label color='blue' style={{ width: 'fit-content', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '20px' }}>
                        {i18n.t("Cliquez pour sélectionner les éléments à transférer")}
                    </Label>
                }
            </>
        );
    }

    componentDidMount = () => {
        ProjectsService.getOrganizationBaseProjects().then(baseProjects => this.setState({ baseProjects: baseProjects || [] }));
    }

    renderProjectsConsumptions = (uscToRender) => {
        const { activeOrganization } = this.props;
        const nbMaxTrees = activeOrganization.subscription.nbTrees;
        const nbMaxGreenSpaces = activeOrganization.subscription.nbGreenSpaces;
        const nbMaxFurnitures = activeOrganization.subscription.nbFurnitures;

        return uscToRender.map(usc => {
            const { id, nbTrees, nbGreenSpaces, nbFurnitures } = usc;

            return (
                <Grid.Row key={id} stretched style={{ margin: '0 5px 5px 5px', paddingTop: '7px', paddingBottom: '7px' }}>
                    <Grid.Column computer={4} tablet={7} mobile={7}>{usc.project.label}</Grid.Column>
                    {!isMobile && <>
                        <Grid.Column width={3} title={usc.user.email}>{FormattersUtil.formatLastNameAndFirstName(usc.user.lastName, usc.user.firstName)}</Grid.Column>
                    </>}
                    <Grid.Column width={3}>
                        <div>
                            {!isMobileOnly ?
                                <GaugeProgressBar
                                    label={nbTrees}
                                    values={[{
                                        value: nbTrees,
                                        maxValue: nbMaxTrees <= 0 ? nbTrees : nbMaxTrees,
                                        popupContent: (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>{i18n.t("Quantité")} : {nbTrees}{nbMaxTrees !== -1 && ` (${this.getFormattedValue(nbTrees, nbMaxTrees)}%)`}</div>
                                            </div>
                                        ),
                                        color: 'var(--primary-100)'
                                    }]}
                                /> : `${this.getFormattedValue(nbTrees, nbMaxTrees)}%`}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <div>
                            {!isMobileOnly ?
                                <GaugeProgressBar
                                    label={nbGreenSpaces}
                                    values={[{
                                        value: nbGreenSpaces,
                                        maxValue: nbMaxGreenSpaces <= 0 ? nbGreenSpaces : nbMaxGreenSpaces,
                                        popupContent: (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>{i18n.t("Quantité")} : {nbGreenSpaces}{nbMaxGreenSpaces !== -1 && ` (${this.getFormattedValue(nbGreenSpaces, nbMaxGreenSpaces)}%)`}</div>
                                            </div>
                                        ),
                                        color: 'var(--secondary-100)'
                                    }]}
                                /> : `${this.getFormattedValue(nbGreenSpaces, nbMaxGreenSpaces)}%`}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <div>
                            {!isMobileOnly ?
                                <GaugeProgressBar
                                    label={nbFurnitures}
                                    values={[{
                                        value: nbFurnitures,
                                        maxValue: nbMaxFurnitures <= 0 ? nbFurnitures : nbMaxFurnitures,
                                        popupContent: (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>{i18n.t("Quantité")} : {nbFurnitures}{nbMaxFurnitures !== -1 && ` (${this.getFormattedValue(nbFurnitures, nbMaxFurnitures)}%)`}</div>
                                            </div>
                                        ),
                                        color: 'var(--blue-100)'
                                    }]}
                                /> : `${this.getFormattedValue(nbFurnitures, nbMaxFurnitures)}%`}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            )
        });
    }

    renderTreeStructure = () => {
        const { isDarkTheme } = this.props;
        const { isTransferring, selectedElements, baseProjects } = this.state;
        let selectedColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
        selectedColor.setAlpha(isDarkTheme ? .2 : .4);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%', userSelect: isTransferring ? 'none' : 'inherit' }}>
                {baseProjects
                    .filter(baseProject => !baseProject.path)
                    .map(baseProject => {
                        const isSelected = isTransferring && selectedElements.includes(baseProject.id);
                        return baseProject.type === 'project'
                            ? <div key={baseProject.id} onClick={isTransferring ? this.selectElement(baseProject) : null} style={{ padding: '5px 10px', cursor: isTransferring ? 'pointer' : 'default', ...(isSelected ? { backgroundColor: selectedColor, borderRadius: '10px' } : {}) }}>
                                <div style={{ flex: 1 }}>
                                    <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px' }} />{baseProject.label}
                                </div>
                            </div>
                            : this.renderFoldersStructure([baseProject], isSelected, 0)
                    })}
            </div>
        )
    }

    renderFoldersStructure = (folders, isFolderSelected, level) => {
        const { isDarkTheme } = this.props;
        const { isTransferring, selectedElements, baseProjects } = this.state;
        let selectedColor = tinycolor(document.body.style.getPropertyValue('--primary-100'));
        selectedColor.setAlpha(isDarkTheme ? .2 : .4);

        return folders.map(folder => {
            const icon = folder.type === 'project' ? faFileAlt : faFolder;
            const subFolders = baseProjects.filter(baseProject => baseProject.path && baseProject.path.endsWith(`/${folder.id}/`));
            const isSelected = isTransferring && (isFolderSelected || selectedElements.includes(folder.id));

            return (
                <>
                    <div key={folder.id} onClick={isTransferring ? this.selectElement(folder) : null} style={{ marginLeft: `${level * 5}px`, padding: '5px 10px', cursor: isTransferring ? 'pointer' : 'default', ...(isSelected ? { backgroundColor: selectedColor, borderRadius: '10px' } : {}) }}>
                        <div style={{ flex: 1 }}>
                            <FontAwesomeIcon icon={icon} style={{ marginRight: '5px' }} />{folder.label}
                        </div>
                    </div>
                    {subFolders.length > 0 && this.renderFoldersStructure(subFolders, isSelected, level + 1)}
                </>
            );
        });
    }

    getFormattedValue = (value, maxValue) => maxValue !== -1 ? (value / maxValue * 100) % 1 ? ((value / maxValue * 100).toFixed(2)) : (value / maxValue * 100) : 0;
    selectElement = (baseProject) => () => {
        let selectedElements = [...this.state.selectedElements];

        if (baseProject.path) {
            const parentFolderIds = this.state.baseProjects.filter(bp => baseProject.path.includes(bp.id)).map(bp => bp.id);
            if (selectedElements.find(se => parentFolderIds.includes(se))) return;
        }

        const subBaseProjectIds = this.state.baseProjects.filter(bp => bp.path?.includes(baseProject.id)).map(bp => bp.id);
        if (selectedElements.includes(baseProject.id)) selectedElements = selectedElements.filter(el => el !== baseProject.id && !subBaseProjectIds.includes(el));
        else selectedElements.push(baseProject.id);

        this.setState({ selectedElements });
    }

    transferElements = () => {
        const { userSubscriptionConsumptions, activeOrganization } = this.props;
        const { selectedOrganization, selectedElements, baseProjects } = this.state;

        this.setState({ isLoading: true });
        OrganizationsService.transferOrganizationData({ sourceOrganization: activeOrganization.id, targetOrganization: selectedOrganization, baseProjects: true, elementIds: selectedElements }).then((status) => {
            if (status === 200) {
                const filteredBaseProjects = baseProjects.filter(bp => !selectedElements.includes(bp.id) && (!bp.path || !selectedElements.find(se => bp.path.includes(se))));
                const filteredUSC = userSubscriptionConsumptions.filter(usc => filteredBaseProjects.find(fbp => fbp.id === usc.projectId))
                this.props.setUserSubscriptionConsumptions(filteredUSC);
                this.props.setProjects(null);
                this.setState({ isLoading: false, isTransferring: false, selectedOrganization: null, selectedElements: [], baseProjects: filteredBaseProjects });
            } else this.setState({ isLoading: false, isTransferring: false });
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization,
        organizations: state.organizations,
        loginAsData: state.loginAsData,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
}

const mapDispatchToProps = {
    setActiveOrganization,
    setProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationProjects);