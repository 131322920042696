import i18n from "../locales/i18n";

export default class TreesUtil {
    static getStumpDiameters() {
        return [{ id: 1, label: '<30' }, { id: 2, label: '30-50' }, { id: 3, label: '50-100' },
        { id: 4, label: '100-150' }, { id: 5, label: '150-200' }, { id: 6, label: '>200' }];
    }

    static getCircumferenceByStumpDiameter(stumpDiameterId) {
        const stumpDiameters = this.getStumpDiameters();
        const stumpDiameterLabel = stumpDiameters[stumpDiameterId - 1].label;
        let min = 0, max = 0, diameter = 0;
        if (stumpDiameterLabel.includes('-')) {
            min = Number(stumpDiameterLabel.split('-')[0]);
            max = Number(stumpDiameterLabel.split('-')[1]);
            diameter = (min + max) / 2;
        } else if (stumpDiameterLabel.includes('<')) {
            max = Number(stumpDiameterLabel.replace('<', ''));
            diameter = (min + max) / 2;
        } else diameter = Number(stumpDiameterLabel.replace('>', ''));

        return Math.round(diameter * Math.PI);
    }

    static getStumpDiameterByCircumference(circumference) {
        if (!circumference) return null;
        const stumpDiameters = this.getStumpDiameters();
        circumference = Math.round(circumference / Math.PI);
        let stumpDiameter;
        for (let i = 0; i < stumpDiameters.length && !stumpDiameter; i++) {
            const diameter = stumpDiameters[i].label;
            let min = 0, max = 0;
            if (diameter.includes('-')) {
                min = Number(diameter.split('-')[0]);
                max = Number(diameter.split('-')[1]);
            } else if (diameter.includes('<')) max = Number(diameter.replace('<', ''));
            else min = Number(diameter.replace('>', ''));

            if ((circumference >= min && circumference <= max) || (max === 0 && circumference >= min)) stumpDiameter = stumpDiameters[i];
        }

        return stumpDiameter;
    }

    static getAgeIndex(age) {
        const tempAge = Number(age);
        return (tempAge === 0 ? 0 :
            tempAge >= 0 && tempAge <= 15 ? 1 :
                tempAge >= 16 && tempAge <= 50 ? 2 :
                    tempAge >= 51 && tempAge <= 100 ? 3 :
                        tempAge >= 101 && tempAge <= 200 ? 4 :
                            tempAge >= 201 && tempAge <= 300 ? 5 :
                                6);
    }

    static getFields(isPublicFields) {
        const treeOrgans = [{ organ: 'root' }, { organ: 'collar' }, { organ: 'trunk' }, { organ: 'branch' }, { organ: 'leaf' }];

        const fields = {
            projectReference: true,
            actions: true,
            longitude: true,
            latitude: true,
            vernacularName: true,
            gender: true,
            species: true,
            cultivar: true,
            isDead: true,
            isEmpty: true,
            isStump: true,
            isIndexed: true,
            isRemarkable: true,
            isFruit: true,
            place: true,
            customReference: true,
            tags: true,
            plantationType: true,
            coverType: true,
            interactions: true,
            microHabitats: true,
            ...treeOrgans.reduce((prevValue, { organ }) => ({
                ...prevValue,
                [`${organ}Symptoms`]: isPublicFields,
                [`${organ}Pathogens`]: isPublicFields,
                [`${organ}Pests`]: isPublicFields,
                [`${organ}Epiphytes`]: ['trunk', 'branch'].includes(organ) && isPublicFields,
            }), {}),
            stumpDiameter: true,
            plantationCoefficient: true,
            situationCoefficient: true,
            patrimonialCoefficient: true,
            numberOfTrunks: true,
            trunkHeight: true,
            trunks: true,
            ontogenicStage: true,
            plantingDate: true,
            age: true,
            vigor: true,
            risk: true,
            accurateRisk: false,
            treePort: true,
            healthReview: true,
            observation: true,
            toCutDown: true
        };

        const formulas = {
            stability: true,
            canopy: true,
            carbonStock: true,
            coolingIndicator: true,
            oxygenProduction: true,
            biodiversityIndex: true,
            amenityValue: true
        }

        if (isPublicFields) {
            delete fields.projectReference;
            delete fields.customReference;
            delete fields.actions;
        } else {
            delete fields.canopy;
            delete fields.toCutDown;
            delete fields.isFruits;
            delete fields.stumpDiameter;
        }

        return { ...fields, ...formulas };
    }

    static getAccurateRiskLabel(accurateRisk) {
        return accurateRisk < 5 ? i18n.t("Très faible") :
            accurateRisk < 7 ? i18n.t("Faible") :
                accurateRisk < 9 ? i18n.t("Moyen") :
                    accurateRisk < 11 ? i18n.t("Élevé") :
                        i18n.t("Très élevé");
    }

    static getStabilityLabel(stability) {
        return stability < 30 ? i18n.t("Très bon") :
            stability < 50 ? i18n.t("Bon") :
                stability < 70 ? i18n.t("Moyen") :
                    i18n.t("Mauvais");
    }

    static getSmallestTrunk(trunks) {
        return (trunks || []).reduce((accumulator, value) => {
            return (accumulator = typeof value.circumference !== 'number' || accumulator?.circumference < value.circumference ? accumulator : value);
        }, null);
    }

    static getBiggestTrunk(trunks) {
        if (!trunks?.length) return null;
        const biggest = (trunks || []).reduce((accumulator, value) => {
            return (accumulator = typeof value.circumference !== 'number' || accumulator?.circumference > value.circumference ? accumulator : value);
        }, null);
        return biggest || trunks[0];
    }

    static getBiggestTrunkValue(trunks, propertyName) {
        const biggestTrunk = this.getBiggestTrunk(trunks);
        if (!biggestTrunk) return null;
        return biggestTrunk[propertyName];
    }

    static getBiodiversityTooltip(properties) {
        if (!properties) return;
        const missingProperties = {};
        const biggestTrunk = this.getBiggestTrunk(properties.trunks);
        if (!properties.essenceId) missingProperties.essenceId = i18n.t("Essence");
        if (!properties.coverTypeId) missingProperties.coverTypeId = i18n.t("Type de couverture au sol");
        if (!properties.situationCoefficientId) missingProperties.situationCoefficientId = i18n.t("Coefficient de situation");
        if (!properties.plantationCoefficientId) missingProperties.plantationCoefficientId = i18n.t("Coefficient de plantation");
        if (!biggestTrunk?.circumference && biggestTrunk?.circumference !== 0) missingProperties.circumference = i18n.t("Circonférence");

        if (Object.keys(missingProperties).length)
            return {
                tooltip: `${i18n.t("Le potentiel de biodiversité a été estimé car des données sont manquantes")} : ${Object.keys(missingProperties).map(property => missingProperties[property]?.toLowerCase()).join(', ')}`,
                isEstimated: true
            };
        else return { tooltip: i18n.t("Potentiel de valeur estimé pour la biodiversité de l'arbre, résultat adapté de publications scientifiques") }
    }
}
