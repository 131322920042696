import React, { Component } from 'react';
// Composants
import { SortableHeaderCell } from 'react-data-grid';
import { Dimmer, Form, Button, Menu, Input, Segment, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Woops from '../Utils/Woops';
/*     Filters     */
import TextFilter from '../Tables/Filters/TextFilter';
import DropDownFilter from '../Tables/Filters/DropDownFilter';
// Librairies
import DataGrid from 'react-data-grid';
import i18n from '../../locales/i18n';
import { faCheck, faList, faRotateLeft, faTimesCircle, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setElementHistory } from '../../actionCreators/elementsActions';
import { setEditedProperties, unlockEditedProperties } from '../../actionCreators/componentsActions';
// Services
import FurnituresService from '../../services/FurnituresService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import StylesUtil from '../../utils/StylesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import DatesUtil from '../../utils/DatesUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import RightsUtil from '../../utils/RightsUtil';

const initialFilters = {
    date: '',
    action: '',
    username: '',
    customReference: '',
    place: '',
    tags: '',
    type: '',
    condition: '',
    description: ''
};

const initialState = {
    data: {
        columns: [],
        rows: []
    },
    rowIndex: 0,
    sortColumn: null,
    sortDirection: 'NONE',
    enableFilterRow: false,
    filters: initialFilters,
    isLoading: true,
    isRestoring: false,
    historyToRestore: 0,
    loadingFailed: false
};

class FurnitureHistory extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { data, sortColumn, sortDirection, enableFilterRow, filters, rowIndex, isLoading, isRestoring, loadingFailed, historyToRestore } = this.state;
        const rows = this.getFilteredRows();

        return (
            <>
                {loadingFailed
                    ? <Woops />
                    :
                    <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                        <Dimmer active={historyToRestore} style={StylesUtil.getMapStyles().dimmerStyle}>
                            <Message compact className='tableConfirmation'>
                                <Message.Header>{i18n.t("Êtes vous certain de vouloir restaurer cet historique ?")}</Message.Header>
                                <Message.Content>
                                    <span>{i18n.t("Cela restaurera l'arbre à l'état sélectionné, toute modification ultérieure à celui-ci sera donc annulée")}</span>
                                </Message.Content>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    <Button color='grey' disabled={isRestoring} onClick={() => this.setState({ historyToRestore: 0 })}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button color='green' disabled={isRestoring} loading={isRestoring} onClick={this.restoreHistory}>
                                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Dimmer>
                        {data?.columns &&
                            <>
                                <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                                    <Menu.Item>
                                        <Form.Field
                                            control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                            value={rowIndex || ''}
                                            onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                        />
                                        <Button
                                            className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                            onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                        />
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button.Group>
                                            <Button
                                                title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                                className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                                onClick={this.toggleFilters}
                                            />
                                            <Button
                                                title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                                onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                            />
                                        </Button.Group>
                                    </Menu.Item>
                                    {ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators) && RightsUtil.canRead(this.props.rights?.actions) &&
                                        <Menu.Item>
                                            <Button.Group>
                                                <Button className='button--secondary' style={{ padding: '11px' }} disabled>
                                                    <FontAwesomeIcon icon={faList} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Propriétés")}
                                                </Button>
                                                <Button
                                                    title={i18n.t("Historique des actions")} className='button--secondary' style={{ padding: '11px' }}
                                                    onClick={() => this.props.changeModalContentType('ActionHistory', i18n.t("Historique"), true)}
                                                >
                                                    <FontAwesomeIcon icon={faUserHelmetSafety} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Actions")}
                                                </Button>
                                            </Button.Group>
                                        </Menu.Item>}
                                </Menu>
                                <DataGrid
                                    ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                    style={{ flex: '1 1 auto' }}
                                    columns={data.columns.filter(column => column.visible || !column.hasOwnProperty('visible'))} rows={rows}
                                    defaultColumnOptions={{ sortable: true, resizable: true }}
                                    cellNavigationMode='LOOP_OVER_ROW'
                                    sortColumn={sortColumn} sortDirection={sortDirection}
                                    onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                    filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                    emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                        <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                    </div>)}
                                    onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                />
                            </>}
                    </Segment>}
            </>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        this.setState({
            conditions: this.props.conditions.map(x => { return { label: x.label, id: x.id } }),
            furnitureTypes: this.props.furnitureTypes.map(x => { return { label: x.label, id: x.id } })
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }
    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à le mobilier suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.elementHistory) !== JSON.stringify(prevProps.elementHistory))
            this.setState({ ...initialState, id: this.props.layer[0].feature.id }, this.loadData);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project).furnitures;
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        const mainPF = publicFields.main;
        const furnituresPF = publicFields.furnitures;
        const projectTags = this.props.project?.tags || [];

        const getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, className) => (
            <div className={className}>
                <SortableHeaderCell
                    column={column}
                    onSort={onSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                >
                    {column.name}
                </SortableHeaderCell>
            </div>
        );

        // Définition des colonnes
        data.columns = [
            {
                name: '', key: 'restore', width: 150,
                sortable: true, resizable: false,
                formatter: (props) => (
                    <div style={{ overflow: 'hidden' }}>
                        {props.row.isSameState ?
                            <Button color='yellow' disabled style={{ padding: '6px 20px', width: '132px' }} content={<><FontAwesomeIcon style={{ marginRight: '10px' }} />{i18n.t("État actuel")}</>} />
                            :
                            <Button
                                color='green' style={{ padding: '6px 20px' }} content={<><FontAwesomeIcon icon={faRotateLeft} style={{ marginRight: '10px' }} />{i18n.t("Restaurer")}</>}
                                onClick={() => this.setState({ historyToRestore: props.row.id })}
                            />}
                    </div>
                )
            },
            {
                name: i18n.t("Date"), key: 'date', width: 250, sortable: true,
                formatter: (props) => props.row.date || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexBDD7EE'),
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Action"), key: 'action', width: 150, sortable: true,
                formatter: (props) => props.row.action || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexBDD7EE'),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Nom d'utilisateur"), key: 'username', width: 150, sortable: true,
                formatter: (props) => props.row.username || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexBDD7EE'),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Référence personnalisée"), key: 'customReference', width: 180,
                sortable: true, visible: requiredFields.customReference && mainPF.references,
                formatter: (props) => this.didChange('customReference', props.row.id)
                    ? <div className='modified'>{props.row.customReference}</div>
                    : props.row.customReference || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexBDD7EE'),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Lieu"), key: 'place', width: 250,
                sortable: true, visible: requiredFields.place && furnituresPF.place,
                formatter: (props) => this.didChange('place', props.row.id)
                    ? <div className='modified'>{props.row.place}</div>
                    : props.row.place || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexBDD7EE'),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Tags"), key: 'tags', width: 200,
                sortable: false, visible: requiredFields.tags && furnituresPF.tags,
                formatter: (props) => this.didChange('tags', props.row.id)
                    ? <div className='modified'>{props.row.tags}</div>
                    : props.row.tags || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexE2EFDA'),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Type"), key: 'type', width: 110,
                sortable: true, visible: requiredFields.type && furnituresPF.type,
                formatter: (props) => this.didChange('type', props.row.id)
                    ? <div className='modified'>{props.row.type}</div>
                    : props.row.type || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexF8CBAD'),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.furnitureTypes} isNullable />
            },
            {
                name: i18n.t("État"), key: 'condition', width: 110,
                sortable: true, visible: requiredFields.condition && furnituresPF.condition,
                formatter: (props) => this.didChange('condition', props.row.id)
                    ? <div className='modified'>{props.row.condition}</div>
                    : props.row.condition || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hexF8CBAD'),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.conditions} isNullable />
            },
            {
                name: i18n.t("Description"), key: 'description', width: 500,
                sortable: true, visible: requiredFields.description && furnituresPF.description,
                formatter: (props) => this.didChange('description', props.row.id)
                    ? <div className='modified'>{props.row.description}</div>
                    : props.row.description || '',
                headerRenderer: (props) => getHeaderRenderer(props, 'headerCellOverride hex92D050'),
                filterRenderer: p => <TextFilter p={p} />
            }
        ];

        const createRows = (elements) => {
            data.rows = elements.map((history) => {
                const properties = history.furniture.properties;
                // Récupération des valeurs du mobilier
                const condition = this.props.conditions.find(x => x.id === properties.conditionId)?.label;
                const furnitureType = this.props.furnitureTypes.find(x => x.id === properties.typeId)?.label;
                let tags = '';
                if (properties.tagId)
                    properties.tagId.forEach(tagId => {
                        const tag = projectTags.find(x => x.id === tagId)?.label;
                        tags += tags === '' ? tag : ', ' + tag;
                    });

                return {
                    id: history.id,
                    date: `${DatesUtil.getFormattedLocaleDateString(history.date)}, ${DatesUtil.getFormattedLocaleTimeString(history.date)}`,
                    action: history.action,
                    username: history.username,
                    customReference: properties.customReference,
                    place: properties.place,
                    description: properties.description,
                    condition, type: furnitureType, tags,
                    isSameState: this.isSameState(properties)
                };
            }).reverse();

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data, elements, initialOrder, isLoading: false });
        }

        // Ajout des données
        this.props.unlockEditedProperties();
        const { elementHistory, layer } = this.props;
        if (!elementHistory || elementHistory[0]?.furnitureId !== this.state.id) {
            FurnituresService.getFurnitureHistory(layer[0].feature.id).then(histories => {
                if (histories) {
                    this.props.setElementHistory(histories);
                    createRows(histories);
                } else this.setState({ loadingFailed: true });
            });
        } else {
            const histories = [...elementHistory];
            this.props.setElementHistory(histories);
            createRows(histories);
        }
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.condition ? (r.condition === filters.condition || (filters.condition === 'empty' && !r.condition)) : true)
                && (filters.type ? (r.type === filters.type || (filters.type === 'empty' && !r.type)) : true)
                && (filters.action ? $(r.action)?.includes($(filters.action)) : true)
                && (filters.username ? $(r.username)?.includes($(filters.username)) : true)
                && (filters.date ? $(r.date).includes($(filters.date)) : true)
                && (filters.customReference ? $(r.customReference)?.includes($(filters.customReference)) : true)
                && (filters.place ? $(r.place)?.includes($(filters.place)) : true)
                && (filters.tags ? $(r.tags)?.includes($(filters.tags)) : true)
                && (filters.description ? $(r.description)?.includes($(filters.description)) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'date')
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    didChange = (key, historyId) => {
        if (this.state.elements.length > 0) {
            const index = this.state.initialOrder.findIndex(id => id === historyId);
            if (index !== -1 && index < this.state.initialOrder.length - 1) {
                const history = this.state.data.rows.find(row => row.id === historyId);
                const prevHistoryId = this.state.initialOrder[index + 1];
                const prevHistory = this.state.data.rows.find(row => row.id === prevHistoryId);
                if (JSON.stringify(history[key]) !== JSON.stringify(prevHistory[key]))
                    return true;
            }
        }

        return false;
    }

    isSameState = (properties) => {
        const { elementHistory } = this.props;
        if (elementHistory) {
            const latestHistory = JSON.parse(JSON.stringify(elementHistory[elementHistory.length - 1].furniture.properties));
            const targetHistory = JSON.parse(JSON.stringify(properties));
            delete latestHistory.id; delete targetHistory.id;
            delete latestHistory.furnitureSnapshotId; delete targetHistory.furnitureSnapshotId;

            [...(latestHistory.trunks || []), ...(targetHistory.trunks || [])].forEach(trunk => {
                delete trunk.id;
                delete trunk.propertiesId;
            });

            return JSON.stringify(targetHistory) === JSON.stringify(latestHistory);
        }
    }

    restoreHistory = () => {
        const { historyToRestore } = this.state;

        const history = this.state.elements.find(history => history.id === historyToRestore);
        if (history) {
            const properties = history.furniture.properties;
            const { layer, elementHistory, project } = this.props;

            this.setState({ isRestoring: true });
            UpdatesUtil.updateFurniture(layer[0], properties, layer, true, this.props.fieldList, this.props.furnituresLayer.activeChild, 'restoring', project.id, this.props.webSocketHubs, { thematicMaps: this.props.project.thematicMaps })
                .then(response => {
                    if (response?.data?.history) {
                        this.setState(prevState => ({ elements: [...prevState.elements, response.data.history] }));
                        if (elementHistory) this.props.setElementHistory([...elementHistory, response.data.history]).then(this.loadData);
                    }
                })
                .finally(() => {
                    this.setState({ isRestoring: false, historyToRestore: 0 });
                    this.props.setEditedProperties(null);
                });
        }
    };
}

const mapStateToProps = (state) => {
    return {
        conditions: state.conditions,
        furnitureTypes: state.furnitureTypes,
        project: state.project,
        layer: state.layer,
        elementHistory: state.elementHistory,
        webSocketHubs: state.webSocketHubs,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        rights: state.rights
    };
};

const mapDispatchToProps = {
    setElementHistory,
    setEditedProperties,
    unlockEditedProperties
};

export default connect(mapStateToProps, mapDispatchToProps)(FurnitureHistory);