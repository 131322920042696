import React, { Component } from 'react';
// Librairies
import Shepherd from 'shepherd.js';
import { v4 as uuidv4 } from 'uuid';
// Redux
import { setTutorialTour } from '../../actionCreators/appActions'
// Styles
import 'shepherd.js/dist/css/shepherd.css';
// Utils
import { tourOptions, steps, shouldCreateStep } from '../../utils/TutorialsUtil';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

class Tutorial extends Component {
    render() { return (<></>); }

    componentDidMount = () => {
        this.isTutorialInitialized = false;
        this.tour = new Shepherd.Tour(tourOptions);
        this.stepButtons = [
            { name: 'finish', classes: 'shepherd-button-primary', text: 'Terminer', type: 'next', action: this.finishTutorial },
            { name: 'next', classes: 'shepherd-button-primary', text: 'Suivant', type: 'next', action: this.tour.next }
        ];
        this.props.setTutorialTour(this.tour);

        Shepherd.on('cancel', () => {
            new Cookies().set('isTutorialDone', true, { maxAge: 31536000, path: '/' });
            this.props.setTutorialTour(null);
            this.tour = null;
        });
    }

    componentWillUnmount = () => {
        if (this.tour || this.props.tutorialTour) {
            this.props.setTutorialTour(null);
            this.tour = null;
        }
    }

    componentDidUpdate = () => {
        if (this.props.userInfos && this.props.userInfos.hasOwnProperty('lastLoginDate') && this.props.activeOrganization && !this.isTutorialInitialized) {
            if (!new Cookies().get('isTutorialDone')) new Cookies().set('isTutorialDone', this.props.userInfos?.lastLoginDate, { maxAge: 31536000, path: '/' });
            const isTutorial = !this.props.userInfos.lastLoginDate || new Cookies().get('isTutorialDone') === 'false';
            if (false) { // if(isTutorial)
                this.steps = steps.flatMap(step => step.children
                    ? step.children.map(childStep => this.createStep({ ...childStep, title: step.title }))
                    : this.createStep(step)
                ).filter(x => x);
                this.tour.addSteps(this.steps);
                this.initStepsFromIndex(-1).then(() => this.tour.start());
            }
            else this.tour.cancel();
            this.isTutorialInitialized = true;
        }
    }

    createStep = ({
        target, title = '', text = [], rights = [], placement = 'bottom', event = 'click',
        buttonsFilter = [], classes = '', canClickTarget = true, verificationFn = () => true
    }) => {
        const buttons = this.stepButtons.filter(button => buttonsFilter.includes(button.name));
        const attachTo = target !== 'body' ? { element: document.querySelector(target), on: placement } : target;

        if (!shouldCreateStep(this.props.activeOrganization?.subscription, rights)) return null;
        return {
            id: uuidv4(), target, title, text, attachTo, event, buttons, classes, canClickTarget, verificationFn,
            // showOn: () => this.showStep(rights),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 500);
                });
            },
            when: {
                hide: () => {
                    const index = this.steps.findIndex(step => step.id === this.tour.getCurrentStep().id);
                    this.initStepsFromIndex(index);
                },
                //? Surroundings
                // show: () => {
                //     const steps = this.steps;
                //     const index = steps.findIndex(step => step.id === this.tour.getCurrentStep().id);
                //     if (index === 1) this.updateSvg();
                // }
            }
        }
    }

    findTarget = async (selector) => (
        new Promise(resolve => {
            const find = () => {
                const target = document.querySelector(selector);
                if (target) resolve(target);
                else setTimeout(find, 100);
            }
            find();
        })
    );

    initStepsFromIndex = async (index) => (
        new Promise((resolve) => {
            const steps = this.steps;

            this.findTarget(steps[index + 1]?.target).then((target) => {
                let shepherdStep = this.tour.getById(steps[index + 1]?.id);
                if (steps[index + 1]?.target !== 'body') shepherdStep.options.attachTo.element = target;
                if (!steps[index + 1]?.buttons.find(button => button.type === 'next'))
                    target.addEventListener(steps[index + 1].event, async () => {
                        if (this.tour) {
                            await steps[index + 1].verificationFn()
                            if (steps[index + 2]) this.findTarget(steps[index + 2].target).then(this.tour.next);
                            else this.tour.next();
                        }
                    }, { once: true });
                resolve();
            });
        })
    );

    finishTutorial = () => {
        const index = this.steps.findIndex(step => step.id === this.tour.getCurrentStep().id);
        if (index === this.steps.length - 1) new Cookies().set('isTutorialDone', true, { maxAge: 31536000, path: '/' });
        this.tour.complete();
    }

    //? Surroundings
    // updateSvg = () => {
    //     setTimeout(() => {
    //         const svgToCopy = document.querySelector('#projectMap > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > svg');
    //         let svgToOverride = document.querySelector('body > svg');
    //         svgToOverride.querySelector('path').setAttribute('d', svgToCopy.querySelector('path').getAttribute('d'));
    //         svgToOverride.style.transform = `translateY(${document.getElementById('navbar').clientHeight}px)`;
    //         window.addEventListener('resize', () => {
    //             this.updateSvg();
    //         }, { once: true });
    //     }, 100);
    // }
}

const mapStateToProps = (state) => {
    return {
        userInfos: state.userInfos,
        activeOrganization: state.activeOrganization,
        tutorialTour: state.tutorialTour
    }
}

const mapDispatchToProps = {
    setTutorialTour
};

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);