import React, { Component } from 'react';
// Composants
import ImportPhotosForm from './ImportPhotosForm';
import { Button, Dimmer, Form, Grid, Header, Loader, Progress, Select } from 'semantic-ui-react';
import ImportElementsForm from './ImportElementsForm';
import ImportProjection from './ImportProjection';
import ImportMapping from './ImportMapping';
import ImportResult from './ImportResult';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import { setUserProjects } from '../../../../actionCreators/projectsActions';
import i18n from '../../../../locales/i18n';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
// Services
import FilesService from '../../../../services/FilesService';
// Utils
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import { showToast } from '../../../../utils/ToastsUtil';
import FormattersUtil from '../../../../utils/FormattersUtil';
import StylesUtil from '../../../../utils/StylesUtil';
import RightsUtil from '../../../../utils/RightsUtil';
import StationsService from '../../../../services/StationsService';

const formats = [{ text: 'Excel', value: 'excel' }, { text: 'Shapefiles', value: 'shapefiles' }];
const actions = [
    { text: i18n.t("Écraser les doublons"), value: 1 },
    { text: i18n.t("Ignorer les doublons"), value: 2 }
];

const errors = [
    { text: i18n.t("Ignorer les erreurs"), value: 1 },
    { text: i18n.t("Ajouter à l'observation"), value: 2 },
    { text: i18n.t("Obtenir un rapport par mail"), value: 3 }
];

const initialElementsConfig = {
    format: 'excel',
    projectionId: 0,
    isProjectionValid: false,
    isSampleValid: false,
    action: 2,
    error: 3,
    files: null,
    mapping: {
        headers: null,
        elementFields: null,
        mapping: null
    },
    listValues: [],
    confirmation: false,
    sample: [],
    formData: null
};

const initialPhotosConfig = {
    file: null,
    formData: null
};

const initialState = {
    isLoading: false,
    isUploading: false,
    step: 1,
    fileUpload: 0,
    response: null
};

class ImportForm extends Component {
    state = {
        ...initialState,
        category: 'trees',
        options: [],
        config: {
            ...initialElementsConfig
        },
        stations: null
    }

    render() {
        const { isLoading, isUploading, step, category, config, fileUpload, options, stations } = this.state;
        const { mapping, listValues, confirmation } = config;
        const projections = this.props.projections.map(x => ({ text: x.label, value: x.id, title: x.title }));
        const maxStep = category === 'photos' ? 2 : 4;

        return (
            <>
                {(isLoading || isUploading) &&
                    <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, display: 'flex', position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}>
                        <Grid style={{ height: '100%', padding: 0, margin: 0, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Grid.Row style={{ height: '100%', padding: 0 }} verticalAlign='middle'>
                                <Grid.Column style={{ width: '650px', padding: 0, margin: 0 }}>
                                    {isLoading ? <Loader />
                                        : isUploading &&
                                        <Progress percent={fileUpload} active color='blue' size='small' style={{ width: '100%' }}>
                                            {i18n.t("Upload en cours...")}({fileUpload}%)
                                        </Progress>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer >}
                <div className='modal-content'>
                    {step === 1 &&
                        <>
                            <div className='modal-content-header'>
                                <Header as='h3'>{i18n.t("Configuration de l'import")}</Header>
                                <Form>
                                    <Form.Field
                                        name='category' label={`${i18n.t("Catégorie")}* : `} placeholder={i18n.t("Sélectionnez une catégorie")}
                                        value={category || ''} style={{ marginBottom: '10px' }}
                                        control={Select} search={FormattersUtil.searchList} selectOnBlur={false} disabled={isLoading}
                                        options={options}
                                        onChange={this.handleCategoryChange}
                                    />
                                </Form>
                            </div>
                        </>}
                    {step === 1 ?
                        <>
                            {category === 'photos'
                                ? <ImportPhotosForm config={config} handleConfigChange={this.handleConfigChange} />
                                : <ImportElementsForm category={category} config={config} actions={actions} errors={errors.filter(error => category !== 'stations' || error.value !== 2)} handleConfigChange={this.handleConfigChange} formats={formats} />}
                        </>
                        : step === 2 && category !== 'photos' ?
                            <ImportMapping
                                category={category} mapping={mapping} listValues={listValues} handleMappingChange={this.handleMappingChange}
                                config={config} confirmation={confirmation} handleConfirmationChange={this.handleConfirmationChange} project={this.props.project}
                                setMapping={(mapping) => this.setState(prevState => ({ config: { ...prevState.config, mapping } }))}
                            />
                            : step === 3
                                ? <ImportProjection category={category} config={config} handleConfigChange={this.handleConfigChange} projectionList={projections} project={this.props.project} stations={stations} />
                                : <ImportResult
                                    category={category} config={config} actions={actions} errors={errors} formats={formats} projections={projections}
                                    fileUpload={fileUpload} setFileUpload={(percentage) => this.setState({ fileUpload: percentage })} redoImport={this.redoImport}
                                />}
                    {this.state.step !== maxStep &&
                        <div className='modal-content-footer' style={{ marginTop: '10px', display: isMobile && 'flex' }}>
                            {this.state.step > 1 &&
                                <Button className='form-button' type='button' color='blue' disabled={!config.files?.length} onClick={this.previousStep}>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '10px' }} />{i18n.t("Précédent")}
                                </Button>}
                            {this.state.step + 1 < maxStep ?
                                <Button className='form-button' type='button' color='blue' disabled={(step === 1 && ((config.format === 'excel' && !config.files?.length) || (config.format === 'shapefiles' && config.files?.length !== 3))) || !this.props.isOnline} onClick={this.nextStep}>
                                    <FontAwesomeIcon icon={faArrowRight} style={{ marginRight: '10px' }} />{i18n.t("Suivant")}
                                </Button>
                                : <Button
                                    className='form-button' type='button' color='green' disabled={(config.action === 1 && !confirmation) || (category === 'photos' && !config.file) || (category !== 'photos' && (!config.isProjectionValid || !config.isSampleValid)) || !this.props.isOnline ? true : false}
                                    onClick={this.handleSubmit}
                                >
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>}
                        </div>}
                </div>
            </>
        );
    }

    componentDidMount = () => {
        const userProject = this.props.userProjects?.find(up => up.userId === jwt_decode(new Cookies().get('token')).id);
        const isGeographicallyLimited = userProject.projectRole?.projectRoleStations?.length > 0 || userProject.projectRole?.areas?.length > 0;
        if (isGeographicallyLimited) this.loadStations();
        else this.setState({ stations: [] });
        const projectRole = userProject?.projectRole;
        const options = [];
        if (RightsUtil.canImport(projectRole?.trees)) options.push({ text: i18n.t("Arbres"), value: 'trees' });
        if (RightsUtil.canImport(projectRole?.greenSpaces)) options.push({ text: i18n.t("Espaces verts"), value: 'greenSpaces' });
        if (RightsUtil.canImport(projectRole?.furnitures)) options.push({ text: i18n.t("Mobilier urbain"), value: 'furnitures' });
        if (RightsUtil.canImport(projectRole?.stations)) options.push({ text: i18n.t("Stations"), value: 'stations' });
        if (RightsUtil.canImport(projectRole?.trees) || RightsUtil.canImport(projectRole?.greenSpaces) || RightsUtil.canImport(projectRole?.furnitures))
            options.push({ text: i18n.t("Photos"), value: 'photos' });

        this.setState({ project: this.props.project, options, category: options[0].value });
    }

    componentWillUnmount = () => {
        const { project } = this.state;
        const userProject = this.props.userProjects?.find(up => up.userId === jwt_decode(new Cookies().get('token')).id);
        if (!project.parentFolderId || userProject?.baseProjectId === project.id) this.props.setUserProjects(null);
        else if (this.props.userProjects) this.props.setUserProjects(this.props.userProjects.filter(up => up.baseProjectId !== project.id));
    }

    loadStations = () => new Promise((resolve) => StationsService.getStations(this.props.project.id).then(stations => this.setState({ stations }, resolve)));

    changeCurrentStep = (step) => {
        if (step > this.state.step) this.setState({ step, fileUpload: 0 });
        else this.setState({ isLoading: false, isUploading: false, fileUpload: 0, step });
    }

    previousStep = () => {
        const { step } = this.state;
        this.changeCurrentStep(step - 1);
    }

    nextStep = () => {
        const { category, step, config } = this.state;
        const { files, mapping, sample } = config;

        if (step === 1) {
            if (files.length) {
                if (mapping !== initialElementsConfig.mapping) this.changeCurrentStep(step + 1);
                else {
                    this.setState({ isUploading: true });
                    const axiosOptions = {
                        onUploadProgress: (processEvent) => {
                            const { loaded, total } = processEvent;
                            let percent = Math.floor((loaded * 100) / total);
                            if (percent !== this.props.fileUpload)
                                this.setState({ fileUpload: percent })
                        }
                    }

                    const formData = new FormData();
                    files.forEach(file => formData.append('files', file));
                    FilesService.getImportMapping(category, this.props.project.id, formData, axiosOptions).then(response => {
                        if (response) {
                            this.setDefaultMapping(response);
                            this.changeCurrentStep(step + 1);
                            this.setState({ isUploading: false, fileUpload: 0 });
                        }
                    });
                }
            }
        } else if (step === 2) {
            if (sample.length) this.changeCurrentStep(step + 1);
            else {
                this.setState({ isUploading: true });
                const axiosOptions = {
                    onUploadProgress: (processEvent) => {
                        const { loaded, total } = processEvent;
                        let percent = Math.floor((loaded * 100) / total);
                        if (percent !== this.props.fileUpload)
                            this.setState({ fileUpload: percent })
                    }
                }

                const formData = new FormData();
                files.forEach(file => formData.append('files', file));
                formData.append('mapping', JSON.stringify(mapping.mapping));
                formData.append('projectId', this.props.project.id);
                FilesService.getImportSample(category, formData, axiosOptions).then(response => {
                    if (response)
                        this.setState(prevState => ({
                            config: { ...prevState.config, sample: response || [], projectionId: initialElementsConfig.projectionId }
                        }), () => this.changeCurrentStep(step + 1));
                    this.setState({ isUploading: false, fileUpload: 0 });
                });
            }
        } else this.changeCurrentStep(step + 1);
    }

    handleCategoryChange = (_, { value }) => {
        const previousCategory = this.state.category;
        const previousConfig = this.state.config;
        const newConfig = previousCategory !== 'photos' && value !== 'photos' ? previousConfig : value === 'photos' ? initialPhotosConfig : initialElementsConfig;
        this.setState({ category: value, config: newConfig })
    }

    handleConfigChange = (_, { name, value }) => {
        const reset = (name === 'format' && this.state.config.format !== value) || (name === 'files' && this.state.config.files !== name);
        this.setState(prevState => ({
            config: {
                ...prevState.config,
                projectionId: reset ? initialElementsConfig.projectionId : prevState.config.projectionId,
                [name]: value,
                mapping: reset ? initialElementsConfig.mapping : prevState.config.mapping,
                sample: reset ? initialElementsConfig.sample : prevState.config.sample
            }
        }));
    }

    setDefaultMapping = (mapping) => {
        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);

        for (let key in mapping.mapping)
            for (let field in requiredFields[this.props.category])
                if (field?.toUpperCase() === key?.toUpperCase()) {
                    if (!requiredFields[this.props.category][field]) mapping.mapping[key] = null;
                    break;
                }

        const values = this.getListValues(mapping);
        this.setState(prevState => ({ step: 2, fileUpload: 0, config: { ...prevState.config, mapping, listValues: values } }));
    }

    getListValues = (mapping) => {
        let values = [], headers = mapping.headers;

        headers.forEach(header => { values.push({ text: header, value: header }); });
        return values;
    }

    handleMappingChange = (_, { name, value }) => {
        let mapping = JSON.parse(JSON.stringify(this.state.config.mapping.mapping));
        const header = name;

        for (let key in mapping)
            if (header?.toUpperCase() === key?.toUpperCase()) mapping[key] = value?.length > 0 ? value : null;
            else if (value?.toUpperCase() === mapping[key]?.toUpperCase()) mapping[key] = null

        this.setState(prevState => ({
            config: {
                ...prevState.config, mapping: { ...prevState.config.mapping, mapping },
                sample: ['LONGITUDE', 'LATITUDE', 'COORDINATES'].includes(name) ? initialElementsConfig.sample : prevState.config.sample
            }
        }));
    }

    handleConfirmationChange = () => this.setState(prevState => ({ config: { ...prevState.config, confirmation: true } }));
    handleSubmit = () => {
        const { category, config } = this.state;
        if (['trees', 'greenSpaces', 'furnitures', 'stations'].includes(category)) {
            const mapping = this.state.config.mapping.mapping;
            let isMappingOk = false, longitudeOk = false, latitudeOk = false;

            if (config.files.find(x => x.name.includes('.xls'))) {
                for (let key in mapping) {
                    const value = mapping[key];
                    if (['greenSpaces', 'stations'].includes(category)) {
                        if (key?.toUpperCase() === 'COORDINATES' && value) {
                            isMappingOk = true;
                            break;
                        }
                    } else {
                        if (key?.toUpperCase() === 'LONGITUDE' && value) longitudeOk = true;
                        else if (key?.toUpperCase() === 'LATITUDE' && value) latitudeOk = true;
                        if (longitudeOk && latitudeOk) {
                            isMappingOk = true;
                            break;
                        }
                    }
                }
            } else if (config.files.find(x => x.name.includes('.shp'))) isMappingOk = true;

            if (!isMappingOk) showToast('coordinates_not_found');
            else {
                const projection = this.props.projections.find(x => x.id === config.projectionId);
                const formData = new FormData();
                formData.append('projectId', this.props.project.id);
                formData.append('projection', projection ? JSON.stringify(projection) : null);
                formData.append('action', config.action);
                formData.append('error', config.error);
                formData.append('mapping', JSON.stringify(mapping));
                config.files.forEach(file => formData.append('files', file));
                this.setState(prevState => ({ step: 4, config: { ...prevState.config, formData } }));
            }
        } else if (category === 'photos') {
            const formData = new FormData();
            formData.append('projectId', this.props.project.id);
            formData.append('files', config.file);
            this.setState(prevState => ({ step: 2, config: { ...prevState.config, formData } }));
        }
    }

    redoImport = () => this.setState({
        ...initialState,
        config: {
            ...initialElementsConfig
        }
    });
}

const mapStateToProps = (state) => {
    return {
        userProjects: state.userProjects,
        projections: state.projections,
        projectCollaborators: state.projectCollaborators,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setUserProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportForm);