import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AppSettings from './AppSettings';
// Composants
import MobileNavbar from './components/Navbars/MobileNavbar';
import BrowserNavbar from './components/Navbars/BrowserNavbar';
import Home from './components/Pages/Home';
import ProjectMap from './components/Pages/ProjectMap';
import Map from './components/Pages/Map';
import Signup from './components/Pages/Signup';
import Login from './components/Pages/Login';
import ForgotPassword from './components/Pages/ForgotPassword';
import AccountPanel from './components/Pages/AccountPanel';
import ResetPassword from './components/Pages/ResetPassword';
import ConfirmEmail from './components/Pages/ConfirmEmail';
import AdminPanel from './components/Administration/AdminPanel';
import Tutorial from './components/Utils/Tutorial';
import { Toaster } from 'react-hot-toast';
import UseAnimations from 'react-useanimations';
import GlobalWebSocket from './components/Utils/GlobalWebSocket';
import HelpdeskButton from './components/Utils/HelpdeskButton';
import TermsOfUse from './components/Pages/TermsOfUse/TermsOfUse';
import ChromeModal from './components/Utils/ChromeModal';
import OfflineModal from './components/Utils/OfflineModal';
import StudentActivation from './components/Pages/StudentActivation';
import LoginAsBand from './components/Utils/LoginAsBand';
import ViewProjectAsBand from './components/Utils/ViewProjectAsBand';
import OfflineBand from './components/Utils/OfflineBand';
import AIScanWarning from './components/Utils/AIScanWarning';
// Librairies
import { isBrowser, isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import Axios from 'axios';
import screenfull from 'screenfull';
import i18n from './locales/i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/pro-solid-svg-icons';
// Redux
import { connect } from 'react-redux';
import { setLoginAsData } from './actionCreators/adminActions';
import { setUserInfos, setActiveOrganization, setOrganizations, setFavoriteTools, setOrganizationCustomFields, setCustomFieldCategories, setHomeInfos, setPriceLists, setThemes } from './actionCreators/usersActions';
import { setIsOnline, setSync, setIsKeyboardOpen, setIsDarkTheme, setFormulas, setProjections, setCurrencies } from './actionCreators/appActions';
import { setCustomCharts, setFilterLists } from './actionCreators/componentsActions';
import { setDefaultFieldCategories, setCustomFields, setPhotosGalleries, setFilesGalleries } from './actionCreators/elementsActions';
import {
  setEssences, setCoverTypes, setInteractions, setMicroHabitats, setRootSymptoms, setCollarSymptoms, setTrunkSymptoms, setBranchSymptoms, setLeafSymptoms, setPathogens, setPests, setEpiphytes,
  setVigors, setHealthReviews, setOntogenicStages, setRisks, setTippingRisks, setOrganCalibers, setTargets, setTreePorts, setPlantationTypes, setPlantationCoefficients,
  setSituationCoefficients, setPatrimonialCoefficients
} from './actionCreators/treesActions';
import {
  setSpaceFunctions, setSpaceTypes, setSpaceTypeCategories, setRunoffCoefficients, setManagementClasses, setDominantCompositions
} from './actionCreators/greenSpacesActions';
import { setConditions, setFurnitureTypes } from './actionCreators/furnituresActions';
import { setActions, setProjects, setProjectsCustomFields, setProjectActions, setViewProjectAsData } from './actionCreators/projectsActions';
// Ressources
import alert from 'react-useanimations/lib/alertCircle';
// Semantic UI
import { Dimmer, Loader, Header, Progress } from 'semantic-ui-react';
// Services
import TreesService from './services/TreesService';
import GreenSpacesService from './services/GreenSpacesService';
import FurnituresService from './services/FurnituresService';
import ActionsService from './services/ActionsService';
import ParametersService from './services/ParametersService';
import UsersService from './services/UsersService';
import FormulasService from './services/FormulasService';
import ProjectsService from './services/ProjectsService';
import CustomFieldsService from './services/CustomFieldsService';
import PriceListsService from './services/PriceListsService';
import OrganizationsService from './services/OrganizationsService';
// Styles
import './styles/app.css';
// Utils
import OfflineUtil from './utils/OfflineUtil';
import StylesUtil from './utils/StylesUtil';
import ThemesUtil from './utils/ThemesUtil';
import LanguagesUtil from './utils/LanguagesUtil';
import CachesUtil from './utils/CachesUtil';
import OrganizationPanel from './components/Pages/OrganizationPanel';

class App extends React.Component {
  state = {
    activeItem: 'map',
    logged: false,
    token: '',
    projectListVisible: false,
    numberOfListsToLoad: 33,
    numberOfListsLoaded: 0,
    shouldRenderPage: false,
    loadingMessage: i18n.t("Chargement des ressources nécessaires..."),
    errorWhileLoading: false,
    isUnderMaintenance: false,
    subscriptionLoaded: false,
    isFullScreen: false,
    renderDefaultMap: false,
    openProjectCreation: false,
    isAIWarningVisible: false,
    showCurrentFolder: false
  }

  render() {
    const { projectListVisible, shouldRenderPage, activeItem, errorWhileLoading, isFullScreen, renderDefaultMap, isAIWarningVisible, showCurrentFolder } = this.state;
    const isTutorial = this.props.userInfos ? (!this.props.userInfos.hasOwnProperty('lastLoginDate') || !this.props.userInfos.lastLoginDate || ['false', undefined].includes(new Cookies().get('isTutorialDone'))) : false;

    return (
      <div className='App'>
        <Helmet>
          {isMobileOnly && <link rel='stylesheet' type='text/css' href='/mobile.css' />}
          {isTablet && <link rel='stylesheet' type='text/css' href='/tablet.css' />}
          {!isMobileOnly && !isTablet && <link rel='stylesheet' type='text/css' href='/computer.css' />}
          {this.props.isDarkTheme
            ? <link rel='stylesheet' type='text/css' href='/darktheme.css' />
            : <link rel='stylesheet' type='text/css' href='/lighttheme.css' />}
        </Helmet>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {shouldRenderPage ?
            <BrowserRouter>
              <LoginAsBand logUser={this.logUser} />
              <ViewProjectAsBand />
              {!isFullScreen &&
                <>
                  {isBrowser ?
                    <>
                      {activeItem !== 'adminPanel' &&
                        <BrowserNavbar
                          changeActiveItem={this.changeActiveItem} activeItem={this.state.activeItem}
                          showProjectList={this.showProjectList} logged={this.state.logged}
                          logUser={this.logUser}
                        />}
                    </>
                    : <MobileNavbar
                      changeActiveItem={this.changeActiveItem} activeItem={this.state.activeItem}
                      showProjectList={this.showProjectList} logged={this.state.logged}
                      logUser={this.logUser}
                    />}
                </>}
              <Switch>
                <Route exact path='/' render={(props) => (
                  renderDefaultMap ?
                    <Map
                      {...props} showProjectList={this.showProjectList} projectListVisible={projectListVisible}
                      changeActiveItem={this.changeActiveItem} logged={this.state.logged} openProjectCreation={this.state.openProjectCreation}
                    />
                    : <Home {...props} changeActiveItem={this.changeActiveItem} showProjectList={this.showProjectList} />
                )} />
                <Route path='/projects/:pid/:path1?/:path2?/:path3?' render={(props) =>
                  <ProjectMap
                    {...props} key={this.props.project?.id || 0} currentFolderState={showCurrentFolder && this.props.currentFolderState} showProjectList={this.showProjectList}
                    projectListVisible={this.state.projectListVisible} showAIWarning={this.showAIWarning}
                    changeActiveItem={this.changeActiveItem} logged={this.state.logged} />}
                >
                </Route>
                <Route path='/signup/:invitationId?'
                  render={(props) => <Signup {...props} changeActiveItem={this.changeActiveItem} logUser={this.logUser} logged={this.state.logged} />}>
                </Route>
                <Route path='/login/:token?'
                  render={(props) => <Login {...props} changeActiveItem={this.changeActiveItem} logUser={this.logUser} logged={this.state.logged} />}>
                </Route>
                <Route path='/adminPanel'
                  render={(props) => <AdminPanel {...props} changeActiveItem={this.changeActiveItem} logUser={this.logUser} showProjectList={this.showProjectList} logged={this.state.logged} activeItem={this.state.activeItem} />}>
                </Route>
                <Route path='/organizationPanel/:category?'
                  render={(props) => <OrganizationPanel {...props} changeActiveItem={this.changeActiveItem} logUser={this.logUser} logged={this.state.logged} />}>
                </Route>
                <Route path='/accountPanel/:category?'
                  render={(props) => <AccountPanel {...props} changeActiveItem={this.changeActiveItem} logUser={this.logUser} logged={this.state.logged} />}>
                </Route>
                <Route path='/forgotPassword'
                  render={(props) => <ForgotPassword {...props} changeActiveItem={this.changeActiveItem} logged={this.state.logged} />}>
                </Route>
                <Route path='/resetPassword'
                  render={(props) => <ResetPassword {...props} changeActiveItem={this.changeActiveItem} logged={this.state.logged} />}>
                </Route>
                <Route path='/confirmEmail'
                  render={(props) => <ConfirmEmail {...props} changeActiveItem={this.changeActiveItem} logUser={this.logUser} logged={this.state.logged} />}>
                </Route>
                <Route path='/student/:token'
                  render={(props) => <StudentActivation {...props} logged={this.state.logged} logUser={this.logUser} />}>
                </Route>
                <Route path='/termsOfUse'
                  render={(props) => <TermsOfUse {...props} />}>
                </Route>
                <Redirect from='/*' to='/' />
              </Switch>
            </BrowserRouter >
            :
            <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
              {!errorWhileLoading ?
                <Loader content={
                  <Progress
                    value={this.state.numberOfListsLoaded} total={this.state.numberOfListsToLoad}
                    size='tiny' style={isMobileOnly ? { width: '200px' } : { width: '400px' }}
                  >
                    <div style={{ color: 'white' }}>{this.state.loadingMessage}</div>
                  </Progress>
                } />
                :
                <Header as='h1' icon textAlign='center'>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <UseAnimations animation={alert} strokeColor='white' autoplay loop size={130} />
                  </div>
                  <Header.Content style={{ color: 'white' }}>Oops ! Le chargement des ressources nécessaires a échoué...</Header.Content>
                  <Header.Subheader as='h3' style={{ color: 'white' }}>Veuillez vérifier votre connexion et rafraîchir la page.</Header.Subheader>
                </Header>}
            </Dimmer>}
          <OfflineBand />
        </div>
        {isTutorial && <Tutorial />}
        <ChromeModal />
        <AIScanWarning showAIWarning={this.showAIWarning} isAIWarningVisible={isAIWarningVisible} />
        <OfflineModal />
        <HelpdeskButton />
        <Toaster position='bottom-right' />
        <GlobalWebSocket changeActiveItem={this.changeActiveItem} />
      </div>
    );
  }

  componentDidMount = () => {
    // setTimeout(() => console.clear(), 3500);
    document.documentElement.lang = i18n.language;
    ThemesUtil.applyTheme(null);
    OfflineUtil.deleteOldCaches();
    this.props.setIsDarkTheme(!AppSettings.isUrbasenseUrl() && localStorage.getItem('isDarkTheme') !== 'false');
    if (new Cookies().get('oldSubscription')) setTimeout(() => new Cookies().remove('oldSubscription'), 5000);

    if (screenfull.isEnabled) screenfull.on('change', () => this.setState({ isFullScreen: screenfull.isFullscreen }));

    const resetSync = () => this.props.setSync({ state: false, nbDone: 0, nbMax: 0 });
    const executeOfflineRequests = () => {
      const indexedDB = OfflineUtil.getIndexedDb();

      const executeRequest = (requestArray, index) => {
        const request = requestArray[index];
        if (request) {
          // Affichage de l'icône de synchronisation
          const sync = { ...this.props.sync };
          if (!sync.nbMax) sync.nbMax = requestArray.length;
          sync.state = sync.nbDone < sync.nbMax;
          sync.nbDone++;
          this.props.setSync(sync);

          if (request.data && Object.values(request.data).flat().some(element => element instanceof File)) { // Requêtes contenant des fichiers
            const formData = new FormData();
            Object.entries(request.data).forEach(([key, value]) => {
              if (Array.isArray(value)) value.forEach(element => { formData.append(key, element); });
              else formData.append(key, value);
            });

            request.data = formData;
          }

          Axios(requestArray[index]).then(async response => {
            await CachesUtil.executeSpecialCacheChange(requestArray[index], response, this.props);
            executeRequest(requestArray, index + 1);
          }).catch(() => executeRequest(requestArray, index + 1));
        } else resetSync();
      };

      indexedDB.requests.toArray().then(requests => {
        indexedDB.requests.clear();
        executeRequest(requests, 0);
      });
    };

    window.addEventListener('load', () => {
      let orientation = this.props.isLandscape ? 'landscape' : 'portrait';
      const totalHeight = window.screen.height;
      const totalWidth = window.screen.width;

      window.addEventListener('online', () => {
        resetSync();
        executeOfflineRequests();
        this.props.setIsOnline(true);
      });

      window.addEventListener('offline', () => {
        resetSync();
        this.props.setIsOnline(false);
      });

      window.addEventListener('resize', () => {
        const newTotalHeight = window.screen.height;
        const newTotalWidth = window.screen.width;
        const newOrientation = this.props.isLandscape ? 'landscape' : 'portrait';
        const orientationChanged = orientation !== newOrientation;
        orientation = newOrientation;

        if (isMobileOnly && !orientationChanged) {
          let isKeyboardOpen = false;
          if (newTotalHeight === totalHeight && newTotalWidth === totalWidth) isKeyboardOpen = false
          else isKeyboardOpen = true;
          this.props.setIsKeyboardOpen(isKeyboardOpen);
        }
      });

      window.addEventListener('beforeunload', (event) => {
        const loginAsData = this.props.loginAsData;
        if (loginAsData && !localStorage.getItem('loginAsData')) {
          this.logUser();
          this.logUser(loginAsData);
        } else if (this.props.exitFormWithChanges) {
          event.preventDefault();
          return event.returnValue = '';
        }
      });

      resetSync();
      // Au chargement de la fenêtre, on tente de delete les liens GUID - ID
      OfflineUtil.isOnline().then(isOnline => {
        OfflineUtil.deleteGuidLinks().then(() => {
          if (isOnline) executeOfflineRequests();
        });
        this.props.setIsOnline(isOnline);
      });
      if (isMobileOnly) this.props.setIsKeyboardOpen(false);
    });

    if (new Cookies().get('token')) { // Au lancement de l'application, on récupère le token de l'utilisateur si disponible
      if (new Cookies().get('tokenVersion')?.toString() === AppSettings.getTokenVersion().toString())
        this.logUser(new Cookies().get('token'));
      else {
        this.setState({ subscriptionLoaded: true });
        new Cookies().remove('token');
        new Cookies().remove('tokenVersion');
      }
    } else this.setState({ subscriptionLoaded: true });

    const loadList = (loadingFunction, localStorageItem, loadingMessage, reduxSetFunction, sortProperty = null) => {
      const setList = (list) => {
        if (sortProperty) list.sort((a, b) => a[sortProperty].toLowerCase().localeCompare(b[sortProperty].toLowerCase()));
        reduxSetFunction(list);
        this.setState(prevState => ({
          numberOfListsLoaded: prevState.numberOfListsLoaded + 1,
          loadingMessage: loadingMessage
        }), () => {
          if (this.state.numberOfListsLoaded >= this.state.numberOfListsToLoad) {
            localStorage.setItem('versions', JSON.stringify(localVersions));
            this.setState({ shouldRenderPage: true });
          }
        });
      }

      // On stocke la version database de la liste en question
      let databaseVersion = databaseVersions[localStorageItem] || 0;
      if (localStorage.getItem(localStorageItem) // Si une version locale est renseignée et qu'elle correspond à celle de la base de données
        && localVersions[localStorageItem] && databaseVersion === localVersions[localStorageItem])
        setList(JSON.parse(localStorage.getItem(localStorageItem))); // Alors on se sert de la liste stockée dans le localStorage
      else loadingFunction().then(response => { // Sinon on la récupère depuis l'API
        if (response) {
          localStorage.setItem(localStorageItem, JSON.stringify(response)); // On stocke la nouvelle version dans le localStorage
          localVersions[localStorageItem] = databaseVersion; // Et on met à jour la version renseignée en local
          setList(response);
        } else if (localStorage.getItem(localStorageItem)) // Si une erreur survient mais qu'une liste est connue dans le localStorage
          setList(JSON.parse(localStorage.getItem(localStorageItem))); // On se sert de l'ancienne liste
        else this.setState({ errorWhileLoading: true }); // Sinon on affiche une erreur car aucune liste ne peut être chargée, donc l'application ne sera pas fonctionnelle
      });
    }

    let databaseVersions;
    let localVersions = JSON.parse(localStorage.getItem('versions') || '{}'); // On récupère les versions des listes locales ou on crée l'objet s'il n'existe pas
    ParametersService.getVersions().then(versions => { // On récupère les versions depuis la base de données
      const isUnderMaintenance = versions?.find(x => x.key === 'IsUnderMaintenance').value === '1';
      if (isUnderMaintenance) this.setState({ isUnderMaintenance: true });

      if (versions || localVersions) {
        if (versions)
          databaseVersions = versions.reduce((map, obj) => {
            map[obj.key.charAt(0).toLowerCase() + obj.key.substr(1).replace('Version', '')] = Number(obj.value);
            return map;
          }, {});
        else databaseVersions = localVersions;
        loadList(TreesService.getEssences, 'essences', i18n.t("Chargement des essences..."), this.props.setEssences);
        loadList(TreesService.getCoverTypes, 'coverTypes', i18n.t("Chargement des types de couverture..."), this.props.setCoverTypes, 'label');
        loadList(TreesService.getInteractions, 'interactions', i18n.t("Chargement des interactions..."), this.props.setInteractions, 'label');
        loadList(TreesService.getMicroHabitats, 'microHabitats', i18n.t("Chargement des dendro-microhabitats..."), this.props.setMicroHabitats, 'label');
        loadList(TreesService.getRootSymptoms, 'rootSymptoms', i18n.t("Chargement des symptômes racines..."), this.props.setRootSymptoms, 'label');
        loadList(TreesService.getCollarSymptoms, 'collarSymptoms', i18n.t("Chargement des symptômes collet..."), this.props.setCollarSymptoms, 'label');
        loadList(TreesService.getTrunkSymptoms, 'trunkSymptoms', i18n.t("Chargement des symptômes tronc..."), this.props.setTrunkSymptoms, 'label');
        loadList(TreesService.getBranchSymptoms, 'branchSymptoms', i18n.t("Chargement des symptômes branches..."), this.props.setBranchSymptoms, 'label');
        loadList(TreesService.getLeafSymptoms, 'leafSymptoms', i18n.t("Chargement des symptômes feuilles..."), this.props.setLeafSymptoms, 'label');
        loadList(TreesService.getPathogens, 'pathogens', i18n.t("Chargement des pathogènes..."), this.props.setPathogens, 'label');
        loadList(TreesService.getPests, 'pests', i18n.t("Chargement des symptômes ravageurs..."), this.props.setPests, 'label');
        loadList(TreesService.getEpiphytes, 'epiphytes', i18n.t("Chargement des épiphytes..."), this.props.setEpiphytes, 'label');
        loadList(TreesService.getVigors, 'vigors', i18n.t("Chargement des vigueurs..."), this.props.setVigors);
        loadList(TreesService.getHealthReviews, 'healthReviews', i18n.t("Chargement des cotes sanitaires..."), this.props.setHealthReviews);
        loadList(TreesService.getOntogenicStages, 'ontogenicStages', i18n.t("Chargement des stades ontogéniques..."), this.props.setOntogenicStages);
        loadList(TreesService.getRisks, 'risks', i18n.t("Chargement des risques..."), this.props.setRisks);
        loadList(TreesService.getTippingRisks, 'tippingRisks', i18n.t("Chargement des risques de basculement..."), this.props.setTippingRisks);
        loadList(TreesService.getOrganCalibers, 'organCalibers', i18n.t("Chargement des calibres d'organe instable..."), this.props.setOrganCalibers);
        loadList(TreesService.getTargets, 'targets', i18n.t("Chargement des cibles..."), this.props.setTargets);
        loadList(TreesService.getTreePorts, 'treePorts', i18n.t("Chargement des ports de l'arbre..."), this.props.setTreePorts, 'label');
        loadList(TreesService.getPlantationTypes, 'plantationTypes', i18n.t("Chargement des types de plantations..."), this.props.setPlantationTypes, 'label');
        loadList(TreesService.getPlantationCoefficients, 'plantationCoefficients', i18n.t("Chargement des coefficients de plantation..."), this.props.setPlantationCoefficients);
        loadList(TreesService.getSituationCoefficients, 'situationCoefficients', i18n.t("Chargement des coefficients de situation..."), this.props.setSituationCoefficients);
        loadList(TreesService.getPatrimonialCoefficients, 'patrimonialCoefficients', i18n.t("Chargement des coefficients patrimoniaux..."), this.props.setPatrimonialCoefficients);
        loadList(ActionsService.getActions, 'actions', i18n.t("Chargement des actions..."), this.props.setActions);
        loadList(GreenSpacesService.getSpaceFunctions, 'spaceFunctions', i18n.t("Chargement des fonctions d'espace..."), this.props.setSpaceFunctions, 'label');
        loadList(GreenSpacesService.getSpaceTypes, 'spaceTypes', i18n.t("Chargement des types d'espace..."), this.props.setSpaceTypes, 'label');
        loadList(GreenSpacesService.getSpaceTypeCategories, 'spaceTypeCategories', i18n.t("Chargement des catégories d'espace..."), this.props.setSpaceTypeCategories, 'label');
        loadList(GreenSpacesService.getRunoffCoefficients, 'runoffCoefficients', i18n.t("Chargement des coefficients de ruissellement..."), this.props.setRunoffCoefficients);
        loadList(GreenSpacesService.getManagementClasses, 'managementClasses', i18n.t("Chargement des classes de gestion..."), this.props.setManagementClasses);
        loadList(GreenSpacesService.getDominantCompositions, 'dominantCompositions', i18n.t("Chargement des compositions dominantes..."), this.props.setDominantCompositions, 'label');
        loadList(FurnituresService.getConditions, 'conditions', i18n.t("Chargement des états des mobiliers urbains..."), this.props.setConditions);
        loadList(FurnituresService.getFurnitureTypes, 'furnitureTypes', i18n.t("Chargement des types de mobiliers urbains..."), this.props.setFurnitureTypes, 'label');
        loadList(FormulasService.getFormulas, 'formulas', i18n.t("Chargement des formules..."), this.props.setFormulas);
        loadList(ProjectsService.getProjections, 'projections', i18n.t("Chargement des projections..."), this.props.setProjections);
        loadList(CustomFieldsService.getDefaultFieldCategories, 'defaultCategories', i18n.t("Chargement des catégories de champs..."), this.props.setDefaultFieldCategories);
        loadList(CustomFieldsService.getCustomFields, 'customFields', i18n.t("Chargement des champs supplémentaires..."), this.props.setCustomFields);
        loadList(PriceListsService.getCurrencies, 'currencies', i18n.t("Chargement des devises..."), this.props.setCurrencies);
      } else this.setState({ errorWhileLoading: true }); // Si on ne sait pas récupérer les versions, on affiche une erreur (elles sont récupérées depuis le SW en cas d'offline)
    });

    // Chargement des icônes
    const iconList = Object
      .keys(Icons)
      .filter(key => key !== "fas" && key !== "prefix")
      .map(icon => Icons[icon]);

    library.add(...iconList);
  }

  componentDidUpdate = () => {
    const { updateSW } = this.props;
    const { isUnderMaintenance, subscriptionLoaded } = this.state;
    if (updateSW) throw new Error('updateSW');

    if (isUnderMaintenance && subscriptionLoaded && this.props.activeOrganization?.subscription.shortName !== 'Dev'
      && !window.location.href.includes('login'))
      throw new Error('isUnderMaintenance');
  }

  logUser = (token) => {
    if (!token) { // Permet de déconnecter un utilisateur si le paramètre fourni est null (utilisé par le bouton de déconnexion)
      localStorage.removeItem('loginAsData');
      this.props.setLoginAsData(null);
      this.props.setViewProjectAsData(null);
      if (this.props.webSocketHubs?.usersHub) this.props.webSocketHubs.usersHub.stop();
      UsersService.logout();
      this.props.setActiveOrganization(null);
      this.props.setUserInfos(null);
      setTimeout(() => this.props.setHomeInfos(null), 500); // Le timeout sert car on fait un setHomeInfos dans le WillUnmount de Home.js -> parfois les infos sont remises dans Redux après déconnexion
      this.props.setOrganizationCustomFields(null);
      this.props.setProjectsCustomFields({});
      this.props.setPriceLists(null);
      this.props.setThemes(null);
      new Cookies().remove('token', { path: '/' });
      new Cookies().remove('tokenVersion', { path: '/' });
      this.setState({ logged: false, token: '' });
      this.props.setFilterLists(null);
      this.props.setCustomCharts(null);
      this.props.setProjects(null);
    } else { // Permet de connecter un utilisateur
      this.setState({ subscriptionLoaded: false }, () => { // Pour le remettre à false quand on se connecte depuis le formulaire
        const loginAsData = localStorage.getItem('loginAsData');
        if (loginAsData) this.props.setLoginAsData(JSON.parse(loginAsData));
        let decodedToken = jwt_decode(token);
        const tokenVersion = new Cookies().get('tokenVersion');
        const isNewVersion = tokenVersion && tokenVersion !== AppSettings.getTokenVersion().toString();
        this.props.setUserInfos({ lastName: decodedToken.lastName, firstName: decodedToken.firstName, hasPassword: true });
        // On ne recrée pas les cookies en cas de refresh
        if (!new Cookies().get('token') || isNewVersion) {
          new Cookies().remove('token', { path: '/' });
          new Cookies().set('token', token, { maxAge: 31536000, path: '/' });
        }

        if (!tokenVersion || isNewVersion) {
          new Cookies().remove('tokenVersion', { path: '/' });
          new Cookies().set('tokenVersion', AppSettings.getTokenVersion(), { maxAge: 31536000, path: '/' });
        }

        if (this.props.isOnline) {
          UsersService.getUserInfos().then(userInfos => {
            if (userInfos) {
              const cultureInLocalStorage = localStorage.getItem('i18nextLng');
              if (cultureInLocalStorage && userInfos.language.culture !== cultureInLocalStorage) {
                LanguagesUtil.clearLists();
                window.location.reload();
                i18n.changeLanguage(userInfos.language.culture);
              } else i18n.changeLanguage(userInfos.language.culture);
              this.props.setUserInfos(userInfos);

              CustomFieldsService.getOrganizationCustomFields().then(organizationCustomFields => {
                if (organizationCustomFields) this.props.setOrganizationCustomFields(organizationCustomFields);
              });

              CustomFieldsService.getCustomFieldCategories().then(customFieldCategories => {
                if (customFieldCategories) this.props.setCustomFieldCategories(customFieldCategories);
              });
            }
          });
        }
        OrganizationsService.getUserOrganizations().then(response => {
          if (response) {
            this.props.setOrganizations(response.organizations);
            this.props.setActiveOrganization(response.activeOrganization).then(() => this.setState({ subscriptionLoaded: true }));
          }
          this.setState({ logged: true, token: token });
        });
      });
      if (localStorage.getItem('favoriteTools')) this.props.setFavoriteTools(JSON.parse(localStorage.getItem('favoriteTools')));
    }
  }

  changeActiveItem = (newState) => this.setState({ activeItem: newState });
  showProjectList = (projectListVisible, { renderDefaultMap = true, openProjectCreation = false, showCurrentFolder = false } = {}) => this.setState({ projectListVisible, renderDefaultMap, openProjectCreation, showCurrentFolder });
  manageAccount = () => this.setState({ activeItem: 'manageAccount' });
  showAIWarning = (isVisible) => this.setState({ isAIWarningVisible: isVisible });
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    projects: state.projects,
    essences: state.essences,
    sync: state.sync,
    isKeyboardOpen: state.isKeyboardOpen,
    isDarkTheme: state.isDarkTheme,
    activeOrganization: state.activeOrganization,
    isOnline: state.isOnline,
    userInfos: state.userInfos,
    tutorialTour: state.tutorialTour,
    updateSW: state.updateSW,
    photosGalleries: state.photosGalleries,
    filesGalleries: state.filesGalleries,
    webSocketHubs: state.webSocketHubs,
    projectActions: state.projectActions,
    loginAsData: state.loginAsData,
    exitFormWithChanges: state.exitFormWithChanges,
    currentFolderState: state.currentFolderState
  };
};

const mapDispatchToProps = {
  setUserInfos,
  setActiveOrganization,
  setOrganizations,
  setIsOnline,
  setEssences,
  setCoverTypes,
  setInteractions,
  setMicroHabitats,
  setRootSymptoms,
  setCollarSymptoms,
  setTrunkSymptoms,
  setBranchSymptoms,
  setLeafSymptoms,
  setPathogens,
  setPests,
  setEpiphytes,
  setVigors,
  setHealthReviews,
  setOntogenicStages,
  setRisks,
  setTippingRisks,
  setOrganCalibers,
  setTargets,
  setTreePorts,
  setPlantationTypes,
  setPlantationCoefficients,
  setSituationCoefficients,
  setPatrimonialCoefficients,
  setActions,
  setSync,
  setIsKeyboardOpen,
  setIsDarkTheme,
  setCustomCharts,
  setFilterLists,
  setSpaceFunctions,
  setSpaceTypes,
  setSpaceTypeCategories,
  setRunoffCoefficients,
  setManagementClasses,
  setDominantCompositions,
  setConditions,
  setFurnitureTypes,
  setFormulas,
  setProjects,
  setProjections,
  setFavoriteTools,
  setDefaultFieldCategories,
  setCustomFields,
  setOrganizationCustomFields,
  setCustomFieldCategories,
  setProjectsCustomFields,
  setPriceLists,
  setThemes,
  setPhotosGalleries,
  setFilesGalleries,
  setCurrencies,
  setHomeInfos,
  setProjectActions,
  setLoginAsData,
  setViewProjectAsData
};

export default withOrientationChange(connect(mapStateToProps, mapDispatchToProps)(App));