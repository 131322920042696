import React, { Component } from 'react';
// Composants
import { Menu, Item, Submenu } from 'react-contexify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import i18n from '../../locales/i18n';

class MapContextMenu extends Component {
    state = {
        modifyProperties: { label: i18n.t("Modifier les propriétés"), isVisible: () => this.props.selectedElements?.find(element => element.feature.id === this.props.contextMenuElement?.feature.id) },
        bringToFront: { label: i18n.t("Mettre au premier plan"), isVisible: () => this.props.contextMenuElement?.feature.properties.category === 'Espace vert' },
        bringToBack: { label: i18n.t("Mettre à l'arrière-plan"), isVisible: () => this.props.contextMenuElement?.feature.properties.category === 'Espace vert' }
    }

    render() {
        return (
            <Menu id='element-context-menu' theme={this.props.isDarkTheme ? 'dark' : 'light'} animation={false}>{this.renderItems(0, this.state)}</Menu>
        );
    }

    renderItems = (index, items) => {
        return Object.keys(items).map(itemName => {
            const item = items[itemName];
            if (!item.isVisible || item.isVisible()) {
                return item.children
                    ? <Submenu key={itemName} label={item.label} disabled={item.isDisabled}>{this.renderItems(index + 1, item.children)}</Submenu>
                    :
                    <Item key={itemName} onClick={item.onClick ? item.onClick : () => this.props.handleContextMenuButtonClick(itemName)} disabled={item.isDisabled}>
                        <div style={{ display: 'flex', marginRight: '10px' }}>
                            <span>{item.label}</span>
                            {item.isChecked && item.isChecked() && <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '10px' }} />}
                        </div>
                        {item.shortcut && <div style={{ marginLeft: 'auto', color: 'var(--grey-100)' }}>{item.shortcut}</div>}
                    </Item>;
            } else return null;
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
}

export default connect(mapStateToProps)(MapContextMenu);