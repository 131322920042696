import React, { Component } from 'react';
// Composants
import CustomFieldForm from '../Administration/DataManagement/CustomFieldForm';
import { Button, Card, Dimmer, Dropdown, Form, Grid, Input, Label, Loader, Menu, Message, Select } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropDownWithCheckboxes from '../Utils/DropDownWithCheckboxes';
import CustomFieldCategoryForm from '../Forms/Users/CustomFieldCategoryForm';
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setCustomFieldCategories, setOrganizationCustomFields } from '../../actionCreators/usersActions';
import { setProjects } from '../../actionCreators/projectsActions';
import i18n from '../../locales/i18n';
// Ressources
import { faFont, faListUl, faPenField, faPlus, faSquareRootAlt, faTimesCircle, faToggleOn, faTrash, faArrowUpRightFromSquare, faCalendarDays, faFunction, faCheck, faFilter, faTag, faTree, faTablePicnic, faFlowerTulip, faShapes, faHome, faEdit, faRightLeft, faTimes } from '@fortawesome/pro-solid-svg-icons';
// Services
import CustomFieldsService from '../../services/CustomFieldsService';
import OrganizationsService from '../../services/OrganizationsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import DatesUtil from '../../utils/DatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';

const breakpointColumnsObj = {
    default: 3,
    1550: 2,
    950: 1
};

const CUSTOM_FIELD_TYPES = [
    { key: 'boolean', label: i18n.t("Booléen"), icon: faToggleOn },
    { key: 'text', label: i18n.t("Texte"), icon: faFont },
    { key: 'url', label: i18n.t("URL"), icon: faArrowUpRightFromSquare },
    { key: 'number', label: i18n.t("Nombre"), icon: faSquareRootAlt },
    { key: 'formula', label: i18n.t("Formule"), icon: faFunction },
    { key: 'date', label: i18n.t("Date"), icon: faCalendarDays },
    { key: 'list', label: i18n.t("Liste déroulante"), icon: faListUl }
];

const NO_TAGS = { key: i18n.t("Aucun tag"), isChecked: true };
const initialFiltersState = {
    elements: [
        { key: 'trees', label: i18n.t("Arbres"), category: 'Arbre', isChecked: true },
        { key: 'greenspaces', label: i18n.t("Espaces verts"), category: 'Espace vert', isChecked: true },
        { key: 'furnitures', label: i18n.t("Mobilier urbain"), category: 'Mobilier', isChecked: true },
    ],
    types: CUSTOM_FIELD_TYPES.map(cft => ({ key: cft.key, isChecked: true })),
    tags: []
};

// TODO Mode organisation gérer les catégories (websocket)
class CustomFieldList extends Component {
    state = {
        activeCategory: 'general',
        hoveredCategoryId: null,
        customFields: [],
        customFieldToEdit: null,
        customFieldCategoryToEdit: null,
        removeId: 0,
        removeCategoryId: 0,
        duplicateId: 0,
        isDeleting: false,
        isDeletingCategory: false,
        isCreating: false,
        isCreatingCategory: false,
        isDuplicating: false,
        search: '',
        sort: 'recent',
        filters: initialFiltersState,
        isLoading: false,
        isTransferring: false,
        selectedElements: [],
        selectedOrganization: null,
        categoryDropdown: {
            isOpen: false,
            position: null,
            categoryId: null
        }
    };

    render() {
        const { isDarkTheme, isOnline, loginAsData, customFieldCategories, organizations, activeOrganization } = this.props;
        const {
            activeCategory, customFields, customFieldToEdit, customFieldCategoryToEdit, removeId, removeCategoryId, isDeleting, isDeletingCategory, isCreating,
            isCreatingCategory, search, sort, filters, isTransferring, isLoading, selectedElements, selectedOrganization
        } = this.state;
        const customFieldToRemove = !isDeleting && customFields.find(cf => cf.id === removeId);
        const customFieldCategoryToRemove = !isDeletingCategory && (customFieldCategories || []).find(ucfc => ucfc.id === removeCategoryId);
        const activeCustomFieldCategoryId = activeCategory === 'general' ? null : customFieldCategories.find(ucfc => ucfc.id === activeCategory)?.id ?? 'general';
        const filteredCustomFields = this.getFilteredCustomFields();
        const checkIcon = <FontAwesomeIcon icon={faCheck} style={{ position: 'relative', left: '4px' }} />;

        const organizationOptions = organizations
            .filter(organization => organization.id !== activeOrganization.id)
            .map(organization => ({ text: organization.label, value: organization.id }))

        return (
            <>
                {isTransferring && isLoading ?
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Loader content={i18n.t("Transfert des champs personnalisés en cours...")} />
                    </Dimmer>
                    :
                    <>
                        {customFieldToEdit || isCreating ?
                            <CustomFieldForm
                                customField={customFieldToEdit} isCreating={isCreating} cancel={this.cancel} updateCustomFieldList={this.updateCustomFieldList}
                                tags={this.getAllTags()} categories={[{ id: 0, label: i18n.t("Général") }, ...(customFieldCategories || [])]} defaultCategory={activeCustomFieldCategoryId}
                            />
                            : customFieldCategoryToEdit || isCreatingCategory ?
                                <CustomFieldCategoryForm
                                    customFieldCategoryToEdit={customFieldCategoryToEdit} setActiveCategory={(category) => this.setState({ activeCategory: category })}
                                    customFieldTypes={CUSTOM_FIELD_TYPES} cancel={() => this.setState({ customFieldCategoryToEdit: null, isCreatingCategory: false })}
                                />
                                : <div style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                    {!isTransferring ?
                                        <>
                                            {organizations?.length > 1 &&
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        color='blue' disabled={!isOnline || loginAsData?.readOnly}
                                                        onClick={() => this.setState(prevState => ({ isTransferring: !prevState.isTransferring, selectedElements: prevState.isTransferring ? [] : prevState.selectedElements }))}
                                                        style={{ width: isMobileOnly && '100%', marginRight: 0 }}
                                                    >
                                                        <FontAwesomeIcon icon={faRightLeft} style={{ marginRight: '10px' }} />{i18n.t("Transférer")}
                                                    </Button>
                                                </div>}
                                        </>
                                        :
                                        <div style={{ display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', alignItems: 'center', gap: '10px', width: '100%' }}>
                                            <Select
                                                placeholder={i18n.t("Sélectionnez l'organisation cible...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flex: 1, width: isMobileOnly && '100%' }}
                                                options={organizationOptions} value={selectedOrganization} onChange={(_, { value }) => this.setState({ selectedOrganization: value })}
                                            />
                                            <div style={{ display: 'flex', gap: '10px', width: isMobileOnly && '100%' }}>
                                                <Button color='red' style={{ flex: isMobileOnly && 1, padding: '11px' }} onClick={() => this.setState({ isTransferring: false, selectedElements: [], selectedOrganization: null })}>
                                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                </Button>
                                                <Button color='green' style={{ flex: isMobileOnly && 1, padding: '11px' }} disabled={!selectedElements?.length || !selectedOrganization} onClick={this.transferElements}>
                                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                                </Button>
                                            </div>
                                        </div>}
                                    {this.renderTabs(filteredCustomFields)}
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: isMobileOnly ? 'column' : 'row', gap: '10px', marginBottom: '10px' }}>
                                        <Form style={{ flexGrow: 1, width: isMobileOnly && '100%' }}>
                                            <Form.Field
                                                control={Input} type='text' placeholder={i18n.t("Rechercher...")} name='search' value={search || ''} autoComplete='off'
                                                onChange={(_, { value }) => this.setState({ search: value })} style={{ width: '100%' }}
                                            />
                                        </Form>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <DropDownWithCheckboxes
                                                icon={faShapes} buttonStyle={{ height: '100%', margin: 0 }} title={i18n.t("Filtrer par éléments")}
                                                options={filters.elements.map(e => ({ label: e.label, isChecked: e.isChecked, onClick: () => this.toggleFilter('elements', e.key) }))}
                                                menuStyle={{ minWidth: '200px' }}
                                            />
                                            <DropDownWithCheckboxes
                                                icon={faFilter} buttonStyle={{ height: '100%', margin: 0 }} title={i18n.t("Filtrer par types")}
                                                options={filters.types.map(t => ({ label: CUSTOM_FIELD_TYPES.find(cft => cft.key === t.key).label, isChecked: t.isChecked, onClick: () => this.toggleFilter('types', t.key) }))}
                                                menuStyle={{ minWidth: '200px' }}
                                            />
                                            <DropDownWithCheckboxes
                                                icon={faTag} buttonStyle={{ height: '100%', margin: 0 }} title={i18n.t("Filtrer par tags")} disabled={!filters.tags.length}
                                                options={filters.tags.map(t => ({ label: t.key, isChecked: t.isChecked, onClick: () => this.toggleFilter('tags', t.key) }))}
                                                menuStyle={{ minWidth: '200px', left: isMobileOnly && '-75px' }}
                                            />
                                            <Dropdown floating button icon='sort' className='icon button--secondary' direction='left' title={i18n.t("Trier les champs")} style={{ margin: 0 }}>
                                                <Dropdown.Menu>
                                                    <Dropdown.Header content={`${i18n.t("Tirer les champs")} (${filteredCustomFields.length})`} />
                                                    <Dropdown.Divider style={{ backgroundColor: isDarkTheme && 'var(--black-80)', margin: '5px 0px' }} />
                                                    <Dropdown.Item
                                                        text={this.renderDropdownText(i18n.t("Le plus récent"), sort, 'recent', checkIcon, null)}
                                                        onClick={() => this.setState({ sort: 'recent' })}
                                                    />
                                                    <Dropdown.Item
                                                        text={this.renderDropdownText(i18n.t("Le plus ancien"), sort, 'old', checkIcon, null)}
                                                        onClick={() => this.setState({ sort: 'old' })}
                                                    />
                                                    <Dropdown.Item
                                                        text={this.renderDropdownText(i18n.t("Nom"), sort, 'name', checkIcon, null)}
                                                        onClick={() => this.setState({ sort: 'name' })}
                                                    />
                                                    <Dropdown.Item
                                                        text={this.renderDropdownText(i18n.t("Type"), sort, 'type', checkIcon, null)}
                                                        onClick={() => this.setState({ sort: 'type' })}
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <Button
                                            className='button--primary' title={i18n.t("Créer un champ personnalisé")} disabled={!isOnline || loginAsData?.readOnly}
                                            onClick={() => this.setState({ isCreating: true })} style={{ width: isMobileOnly && '100%', marginRight: 0 }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} /> {i18n.t("Créer")}
                                        </Button>
                                    </div>
                                    {filteredCustomFields.filter(cf => cf.customFieldCategoryId === activeCustomFieldCategoryId).length > 0 ?
                                        <Masonry breakpointCols={breakpointColumnsObj} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
                                            {this.renderCustomFields(filteredCustomFields.filter(cf => cf.customFieldCategoryId === activeCustomFieldCategoryId))}
                                        </Masonry>
                                        :
                                        <div style={{ position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', pointerEvents: 'none' }}>
                                            <FontAwesomeIcon icon={faPenField} size='6x' style={{ marginTop: 'auto' }} />
                                            <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                                        </div>}
                                </div>}
                        {customFieldToRemove &&
                            <Dimmer
                                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ removeId: 0 }); }}
                            >
                                <Grid style={{ height: '100%' }}>
                                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                        <Grid.Column textAlign='center'>
                                            <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                                <Message.Content style={{ marginTop: '10px' }}>
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                        <div style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{customFieldToRemove.label}</div>
                                                    </div>
                                                    <div style={{ marginBottom: '10px' }}>{i18n.t("Êtes-vous certain de vouloir supprimer ce champs personnalisé ? Vous ne pourrez plus le récupérer par la suite.")}</div>
                                                    <Button color='grey' onClick={() => this.setState({ removeId: 0 })}>
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                    </Button>
                                                    <Button color='red' disabled={!isOnline} onClick={() => this.removeCustomField(removeId)}>
                                                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                    </Button>
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Dimmer>}
                        {customFieldCategoryToRemove &&
                            <Dimmer
                                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ removeCategoryId: 0 }); }}
                            >
                                <Grid style={{ height: '100%' }}>
                                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                        <Grid.Column textAlign='center'>
                                            <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                                <Message.Content style={{ marginTop: '10px' }}>
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                        <div style={{ color: isDarkTheme ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{customFieldCategoryToRemove.label}</div>
                                                    </div>
                                                    <div style={{ marginBottom: '10px' }}>{i18n.t("Êtes-vous certain de vouloir supprimer cette catégorie ? Vous ne pourrez plus le récupérer par la suite.")}</div>
                                                    <div style={{ marginBottom: '10px' }}>{i18n.t("Les champs seront transférés vers la catégorie \"Général\".")}</div>
                                                    <Button color='grey' onClick={() => this.setState({ removeCategoryId: 0 })}>
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                    </Button>
                                                    <Button color='red' disabled={!isOnline} onClick={this.deleteCustomFieldCategory}>
                                                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                    </Button>
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Dimmer>}
                        {isTransferring &&
                            <Label color='blue' style={{ width: 'fit-content', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '20px' }}>
                                {i18n.t("Cliquez pour sélectionner les éléments à transférer")}
                            </Label>}
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        this.loadCustomFields();
    }

    componentDidUpdate = (prevProps) => {
        const { organizationCustomFields, customFieldCategories } = this.props;

        if (prevProps.customFieldCategories && customFieldCategories
            && JSON.stringify(prevProps.customFieldCategories) !== JSON.stringify(customFieldCategories)
            && !customFieldCategories.find(cfc => cfc.id === this.state.activeCategory))
            this.setState({ activeCategory: 'general' });

        if (prevProps.organizationCustomFields && organizationCustomFields && JSON.stringify(prevProps.organizationCustomFields) !== JSON.stringify(organizationCustomFields))
            this.setState({ customFields: JSON.parse(JSON.stringify(organizationCustomFields)) });
    }

    loadCustomFields = () => {
        if (this.props.organizationCustomFields) {
            const customFields = this.props.organizationCustomFields;
            this.setState(prevState => ({ customFields, filters: { ...prevState.filters, tags: [NO_TAGS, ...this.getAllTags(customFields).map(t => ({ key: t, isChecked: true }))] } }));
        } else CustomFieldsService.getOrganizationCustomFields().then(organizationCustomFields => {
            if (organizationCustomFields) {
                this.props.setOrganizationCustomFields(organizationCustomFields);
                this.setState({ customFields: organizationCustomFields });
                this.setState(prevState => ({ customFields: organizationCustomFields, filters: { ...prevState.filters, tags: [NO_TAGS, ...this.getAllTags(organizationCustomFields).map(t => ({ key: t, isChecked: true }))] } }));
            }
        });
    }

    renderTabs = (filteredCustomFields) => {
        const { customFieldCategories, isOnline, loginAsData, isDarkTheme } = this.props;
        const { activeCategory, hoveredCategoryId, categoryDropdown } = this.state;

        const handleDropdownClick = (action, category) => {
            this.setState({ categoryDropdown: { isOpen: false, position: null, categoryId: null }, hoveredCategoryId: null });
            if (action === 'edit') this.setState({ customFieldCategoryToEdit: category });
            else if (action === 'delete') this.setState({ removeCategoryId: category.id });
        };

        return (
            <div style={{ position: 'relative', display: 'flex', width: '100%', marginBottom: '10px' }}>
                <Menu attached='top' tabular style={{ margin: 0, padding: '0 5px', overflowX: 'auto', overflowY: 'hidden', marginTop: '10px' }}>
                    <Menu.Item
                        name='general' active={activeCategory === 'general'}
                        onClick={() => { if (activeCategory !== 'general') this.setState({ activeCategory: 'general' }) }}
                    >
                        <FontAwesomeIcon icon={faHome} style={{ marginRight: '10px' }} /> {!isMobileOnly && i18n.t("Général")} ({filteredCustomFields.filter(fcf => !fcf.customFieldCategoryId).length})
                    </Menu.Item>
                    {(customFieldCategories || []).map(ucfc => {
                        const nbFields = filteredCustomFields.filter(fcf => fcf.customFieldCategoryId === ucfc.id).length;
                        return (
                            <Menu.Item
                                name={ucfc.id} active={activeCategory === ucfc.id}
                                onMouseEnter={() => this.setState({ hoveredCategoryId: ucfc.id })}
                                onMouseLeave={() => this.setState({ hoveredCategoryId: null, categoryDropdown: { isOpen: false, position: null, categoryId: null } })}
                                onClick={() => { if (activeCategory !== ucfc.id) this.setState({ activeCategory: ucfc.id }) }}
                                style={{ paddingRight: '30px' }}
                            >
                                <FontAwesomeIcon icon={ucfc.icon} style={{ marginRight: '10px', color: ucfc.color }} />
                                {!isMobileOnly && <div style={{ color: ucfc.color, marginRight: '5px' }}>{ucfc.label}</div>}
                                <div style={{ color: ucfc.color }}>({nbFields})</div>
                                {(hoveredCategoryId === ucfc.id || isMobile) &&
                                    <div style={{ display: 'flex', alignItems: 'center', height: '100%', position: 'absolute', bottom: 0, right: '5px', fontSize: '10px' }}>
                                        <Dropdown
                                            icon='ellipsis vertical' floating button direction='left' className='icon'
                                            onClick={(e) => this.setState(prevState => ({ categoryDropdown: { isOpen: !prevState.categoryDropdown.isOpen, position: !prevState.categoryDropdown.isOpen ? e.target.getBoundingClientRect() : null, categoryId: !prevState.categoryDropdown.isOpen ? ucfc.id : null } }))}
                                            style={{ padding: '5px 0', backgroundColor: 'transparent', color: isDarkTheme ? 'white' : 'black' }}
                                        >
                                            {categoryDropdown.isOpen ?
                                                <Dropdown
                                                    open icon={null}
                                                    style={{
                                                        position: 'fixed',
                                                        top: categoryDropdown.position.top + categoryDropdown.position.height + window.scrollY + 10,
                                                        left: isMobile ? categoryDropdown.position.left - 50 : categoryDropdown.position.left,
                                                        zIndex: 2000,
                                                    }}
                                                >
                                                    <Dropdown.Menu style={{ position: 'relative' }}>
                                                        <Dropdown.Item
                                                            icon='edit' text={i18n.t("Modifier")} disabled={!isOnline}
                                                            onClick={() => handleDropdownClick('edit', customFieldCategories.find(cfc => cfc.id === categoryDropdown.categoryId))}
                                                        />
                                                        <Dropdown.Item
                                                            icon='trash' text={i18n.t("Supprimer")} disabled={!isOnline}
                                                            onClick={() => handleDropdownClick('delete', customFieldCategories.find(cfc => cfc.id === categoryDropdown.categoryId))}
                                                        />
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                : <Dropdown.Menu></Dropdown.Menu>}
                                        </Dropdown>
                                    </div>}
                            </Menu.Item>
                        );
                    })}
                </Menu>
                <Menu tabular>
                    <Menu.Item
                        name='add' active title={i18n.t("Créer une catégorie")} disabled={!isOnline || loginAsData?.readOnly}
                        onClick={() => this.setState({ isCreatingCategory: true })}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Menu.Item>
                </Menu>
            </div>);
    }

    renderCustomFields = (customFields = []) => {
        const { isDarkTheme } = this.props;
        const { isDeleting, removeId, isDuplicating, duplicateId, selectedElements, isTransferring } = this.state;

        const titleStyle = { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' };

        return customFields
            .map((customField, index) => {
                const { id, label, type } = customField;
                const isLoading = (isDeleting && removeId === customField.id) || (isDuplicating && duplicateId === customField.id);
                const icon = customField.category === 'Arbre' ? faTree : customField.category === 'Espace vert' ? faFlowerTulip : faTablePicnic;

                return (
                    <Card
                        key={index} style={{ width: '100%', position: 'relative', ...(isTransferring ? { boxShadow: 'none', border: selectedElements?.includes(id) ? 'solid 1px var(--primary-100)' : 'dashed 1px var(--grey-100)' } : {}) }}
                        onClick={isTransferring ? this.selectElement(id) : null}
                    >
                        {isLoading &&
                            <div style={{
                                position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                backgroundColor: isDarkTheme ? 'rgb(40,40,40,0.75)' : 'rgb(200,200,200,0.75)', pointerEvents: isLoading && 'none', cursor: 'pointer',
                                borderRadius: !isMobileOnly && '10px', zIndex: 10
                            }}
                            >
                                <Loader active inline size='small' />
                            </div>}
                        <Grid style={{ margin: 0 }}>
                            <Grid.Row style={isMobileOnly ? { paddingTop: '14px', paddingBottom: 0 } : null}>
                                <Grid.Column computer={2} tablet={2} mobile={4}>
                                    <FontAwesomeIcon size='2x' icon={CUSTOM_FIELD_TYPES.find(cft => cft.key === type).icon} />
                                </Grid.Column>
                                <Grid.Column computer={12} tablet={12} mobile={8} style={{ display: 'flex', alignItems: 'center' }}>
                                    {!isMobileOnly && <h3 style={titleStyle} title={label}>{label}</h3>}
                                </Grid.Column>
                                <Grid.Column computer={2} tablet={2} mobile={4} style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                    <Dropdown icon='ellipsis vertical' disabled={isTransferring} floating button direction='left' className='icon' style={{ fontSize: '10px', marginLeft: 'auto', marginRight: '16px' }}>
                                        <Dropdown.Menu>
                                            <Dropdown.Menu scrolling>
                                                {this.renderOptions(customField)}
                                            </Dropdown.Menu>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Grid.Column>
                            </Grid.Row>
                            {isMobileOnly &&
                                <Grid.Row style={{ paddingTop: '7px' }}>
                                    <Grid.Column width={16} style={{ display: 'flex', alignItems: 'center' }}>
                                        <h3 style={titleStyle} title={label}>{label}</h3>
                                    </Grid.Column>
                                </Grid.Row>}
                            <Grid.Row style={{ paddingTop: 0 }}>
                                <Grid.Column width={16} style={{ display: 'flex', overflow: 'auto' }}>
                                    {customField.tags?.length ? customField.tags.map(tag =>
                                        <Label className='label--secondary'>
                                            {tag}
                                        </Label>)
                                        : <Label color='grey'>
                                            {i18n.t("Aucun tag")}
                                        </Label>}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ paddingTop: 0 }}>
                                <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                    <small style={{ color: 'var(--grey-60)' }}>{DatesUtil.convertUTCDateToLocaleDate(customField.modificationDate || customField.creationDate)}</small>
                                    <FontAwesomeIcon icon={icon} style={{ marginLeft: 'auto' }} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card>
                );
            });
    }

    renderOptions = (customField) => {
        const { isOnline, loginAsData } = this.props;
        return (<>
            <Dropdown.Item icon='edit' text={i18n.t("Éditer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ customFieldToEdit: customField })} />
            <Dropdown.Item icon='copy' text={i18n.t("Dupliquer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.duplicateCustomField(customField)} />
            <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ removeId: customField.id })} />
        </>);
    }

    renderDropdownText = (label, condition, value, icon1, icon2) => {
        return (<div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginRight: '30px' }}>{label}</div>
            <div style={{ marginLeft: 'auto' }}>{condition === value ? icon1 : icon2}</div>
        </div>);
    }

    cancel = () => this.setState({ customFieldToEdit: null, isCreating: false });
    removeCustomField = (id) => {
        let { customFields } = this.state;
        this.setState({ isDeleting: true });
        setTimeout(() => {
            CustomFieldsService.deleteCustomFields([id]).then(response => {
                if (response) customFields = customFields.filter(cf => cf.id !== id);
                this.props.setOrganizationCustomFields(customFields);
                this.setState({ customFields, isDeleting: false, removeId: 0 });
                WebSocketUtil.removeOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, [id]);
            });
        }, 250);
    }

    updateCustomFieldList = (customField) => {
        const { customFields } = this.state;
        const customFieldIndex = customFields.findIndex(cf => cf.id === customField.id);
        if (customFieldIndex !== -1) {
            customFields[customFieldIndex] = customField;
            WebSocketUtil.updateOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, [customField]);
        }
        else {
            customFields.push(customField);
            WebSocketUtil.sendOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, [customField]);
        }

        this.props.setOrganizationCustomFields(customFields);
        this.setState(prevState => ({
            customFields,
            filters: {
                ...prevState.filters,
                tags: [prevState.filters.tags[0], ...this.getAllTags(customFields).map(t => ({ key: t, isChecked: prevState.filters.tags.find(ft => ft.key === t)?.isChecked ?? true }))]
            }
        }));
    };

    duplicateCustomField = (customField) => {
        this.setState({ isDuplicating: true, duplicateId: customField.id }, () => {
            const customFieldToDuplicate = JSON.parse(JSON.stringify(customField));
            customFieldToDuplicate.label = `${customFieldToDuplicate.label} - ${i18n.t("Copie")}`;
            delete customFieldToDuplicate.id;
            customFieldToDuplicate.creationDate = DatesUtil.getUTCDate();
            customFieldToDuplicate.modificationDate = null;
            if (customFieldToDuplicate.dropdownCustomFieldValues?.length)
                customFieldToDuplicate.dropdownCustomFieldValues.forEach(dcfv => delete dcfv.id);

            CustomFieldsService.addCustomField(customFieldToDuplicate).then((customField) => {
                this.updateCustomFieldList(customField);
                this.setState({ isDuplicating: false, duplicateId: 0 });
                WebSocketUtil.sendOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, [customField]);
            });
        });
    }

    deleteCustomFieldCategory = () => {
        const { customFieldCategories } = this.props;
        const { activeCategory } = this.state;

        const customFieldCategory = customFieldCategories.find(ucfc => ucfc.id === activeCategory);
        if (customFieldCategory) {
            this.setState({ isDeletingCategory: true });
            const linkedCustomFields = this.props.organizationCustomFields.filter(ucf => ucf.customFieldCategoryId === customFieldCategory.id);
            if (linkedCustomFields.length) {
                linkedCustomFields.forEach(lcf => lcf.customFieldCategoryId = null);
                CustomFieldsService.updateCustomFields(linkedCustomFields, false).then(updatedOrganizationCustomFields => {
                    let organizationCustomFields = [];
                    if (updatedOrganizationCustomFields) {
                        this.props.organizationCustomFields.forEach(ucf => {
                            const updatedUserCustomField = updatedOrganizationCustomFields.find(uucf => uucf.id === ucf.id);
                            organizationCustomFields.push(updatedUserCustomField || ucf);
                        });

                        WebSocketUtil.updateOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, organizationCustomFields);
                    } else organizationCustomFields = this.props.organizationCustomFields;

                    this.props.setOrganizationCustomFields(organizationCustomFields);

                    CustomFieldsService.deleteCustomFieldCategory(customFieldCategory.id).then(() => {
                        this.setState({ activeCategory: 'general', customFields: organizationCustomFields, isDeletingCategory: false }, () => {
                            this.props.setCustomFieldCategories(customFieldCategories.filter(ucfc => ucfc.id !== customFieldCategory.id));
                            WebSocketUtil.removeOrganizationCustomFieldCategories(this.props.webSocketHubs, this.props.activeOrganization.id, [customFieldCategory.id]);
                        });
                    });
                });
            } else
                CustomFieldsService.deleteCustomFieldCategory(customFieldCategory.id).then(() =>
                    this.setState({ activeCategory: 'general', isDeletingCategory: false }, () => {
                        this.props.setCustomFieldCategories(customFieldCategories.filter(ucfc => ucfc.id !== customFieldCategory.id));
                        WebSocketUtil.removeOrganizationCustomFieldCategories(this.props.webSocketHubs, this.props.activeOrganization.id, [customFieldCategory.id]);
                    }));
        }
    }

    getFilteredCustomFields = () => {
        const { customFields, search, filters, sort } = this.state;
        let filteredCustomFields = [];

        // Recherche
        if (search.trim() === '') filteredCustomFields = customFields || [];
        else filteredCustomFields = customFields.filter(cf => FormattersUtil.getNormalizedString(cf.label).toLowerCase().includes(FormattersUtil.getNormalizedString(search).toLowerCase()));

        // Filtre
        if (filters.elements.some(e => !e.isChecked)) filteredCustomFields = filteredCustomFields.filter(fcf => filters.elements.find(e => e.category === fcf.category && e.isChecked));
        if (filters.types.some(t => !t.isChecked)) filteredCustomFields = filteredCustomFields.filter(fcf => filters.types.find(t => t.key === fcf.type && t.isChecked));
        if (filters.tags.some(t => !t.isChecked)) {
            const noTags = filters.tags.find(t => t.key === NO_TAGS.key);
            filteredCustomFields = [
                ...noTags.isChecked ? filteredCustomFields.filter(fcf => !fcf.tags?.length) : [],
                ...filteredCustomFields.filter(fcf => fcf.tags?.length && fcf.tags.some(fcft => filters.tags.find(t => t.key === fcft && t.isChecked)))
            ];
        }

        // Tri
        if (sort === 'recent') filteredCustomFields.sort((a, b) => new Date(b.modificationDate || b.creationDate) - new Date(a.modificationDate || a.creationDate));
        else if (sort === 'old') filteredCustomFields.sort((a, b) => new Date(a.modificationDate || a.creationDate) - new Date(b.modificationDate || b.creationDate));
        else if (sort === 'name') filteredCustomFields.sort((a, b) => FormattersUtil.getNormalizedString(a.label).localeCompare(FormattersUtil.getNormalizedString(b.label)));
        else if (sort === 'type') {
            const typesOrder = CUSTOM_FIELD_TYPES.map(cft => cft.key);
            filteredCustomFields = filteredCustomFields.sort((a, b) => typesOrder.indexOf(a.type) - typesOrder.indexOf(b.type));
        }

        return filteredCustomFields;
    }

    toggleFilter = (property, value) => {
        const { filters } = this.state;
        let filterToUpdate = filters[property].find(f => f.key === value);
        if (filterToUpdate) {
            filterToUpdate.isChecked = !filterToUpdate.isChecked;
            this.setState({ filters });
        }
    }

    getAllTags = (customFields) => {
        customFields = customFields || this.state.customFields;
        return [...new Set(customFields.flatMap(cf => cf.tags || []))];
    }

    selectElement = (id) => () => this.setState(prevState => ({
        selectedElements: prevState.selectedElements.includes(id) ? prevState.selectedElements.filter(el => el !== id) : [...prevState.selectedElements, id]
    }));

    transferElements = () => {
        const { selectedOrganization, selectedElements } = this.state;

        this.setState({ isLoading: true });
        OrganizationsService.transferOrganizationData({ targetOrganization: selectedOrganization, customFields: true, elementIds: selectedElements }).then(() => {
            const transferredCustomFields = this.state.customFields.filter(customField => selectedElements.includes(customField.id))
            this.setState(prevState => ({
                isLoading: false, isTransferring: false, selectedOrganization: null, selectedElements: [],
                customFields: prevState.customFields.filter(customField => !selectedElements.includes(customField.id))
            }));

            WebSocketUtil.removeOrganizationCustomFields(this.props.webSocketHubs, this.props.activeOrganization.id, transferredCustomFields);
            WebSocketUtil.sendOrganizationCustomFields(this.props.webSocketHubs, selectedOrganization, transferredCustomFields);
        });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        customFields: state.customFields,
        organizationCustomFields: state.organizationCustomFields,
        customFieldCategories: state.customFieldCategories,
        loginAsData: state.loginAsData,
        organizations: state.organizations,
        activeOrganization: state.activeOrganization,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setOrganizationCustomFields,
    setCustomFieldCategories,
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldList);