import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Accordion } from 'semantic-ui-react';
// Utils
import { shouldCreateStep, steps } from '../../utils/TutorialsUtil';

class TutorialList extends Component {
    render() {
        return (<Accordion fluid styled>{this.renderSteps()}</Accordion>);
    }

    renderSteps = () => {
        let i = 0, stepsToRender = [];
        steps.forEach(step => {
            if (i > 0 && (!step.rights || shouldCreateStep(this.props.activeOrganization?.subscription, step.rights))) {
                stepsToRender.push(
                    <Accordion.Title key={i} style={{ cursor: 'default' }}>
                        <span style={{ marginRight: '10px' }}>{i}.</span>
                        <span dangerouslySetInnerHTML={{ __html: step.title }}></span>
                        {/* <MiniButton icon='video camera' color='green' disabled className='floating-right' /> */}
                    </Accordion.Title>);
                i++;
            } else if (i === 0) i++;
        });
        stepsToRender.pop();
        return stepsToRender;
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization
    };
}

export default connect(mapStateToProps, null)(TutorialList);