import React, { Component } from 'react';
// Librairies
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';

class BooleanEditor extends Component {
    render() {
        const { row, column, onRowChange } = this.props;

        return (
            <select
                className='rdg-text-editor' autoFocus
                value={row[column.key] || ''}
                onKeyDown={(e) => this.handleKeyDown(e, row, column, onRowChange)}
                onChange={e => { this.handleDropDownChange(e.target.value, row, column, onRowChange) }}
            >
                {column.customField && (<option value=''></option>)}
                <option value={i18n.t("Oui")}>{i18n.t("Oui")}</option>
                <option value={i18n.t("Non")}>{i18n.t("Non")}</option>
            </select>
        );
    }

    handleDropDownChange = (targetValue, row, column, onRowChange) => {
        let elementsToModify = this.props.elementsToModify;
        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        const index = elementsToModify.findIndex(element => element.id === row.elementId);
        const feature = index === -1
            ? JSON.parse(JSON.stringify(this.props.elements[row.id].feature))
            : elementsToModify[index];

        if (column.customField) this.props.updateElementCustomFields(feature, column.key, targetValue === i18n.t("Oui") ? 'true' : (targetValue === i18n.t("Non") ? 'false' : ''))
        else feature.properties[column.key] = targetValue === i18n.t("Oui") ? true : false;
        // Permet de gérer le cas des arbres "Classé"
        if (column.key === 'isIndexed') {
            let propertyName = amenityFormulaType === 'Wallonie' ? 'patrimonialCoefficientId' : 'plantationCoefficientId';
            let propertyValue = 0;
            if (propertyName === 'patrimonialCoefficientId') propertyValue = targetValue === i18n.t("Oui") ? 6 : (feature.properties.isRemarkable ? 3 : 0);
            else propertyValue = targetValue === i18n.t("Oui") ? (amenityFormulaType === 'Bruxelles' ? 12 : 17) : 0;
            feature.properties[propertyName] = propertyValue;
        }
        if (column.key === 'isRemarkable' && amenityFormulaType === 'Wallonie' && !feature.properties.isIndexed)
            feature.properties.patrimonialCoefficientId = targetValue === i18n.t("Oui") ? 3 : 0;
        if (index === -1) elementsToModify.push(feature);

        // Obligé de sauvegarder dans l'historique ici car le dropdown unfocus dès la sélection d'une valeur
        this.props.changeElementsToModify(elementsToModify);
        let newModifications = [{ elementId: row.elementId, property: column.key, oldValue: row[column.key], customField: column.customField }];
        let newRow = { ...row, [column.key]: targetValue || '' };
        if (column.key === 'isIndexed') {
            let propertyName = amenityFormulaType === 'Wallonie' ? 'patrimonialCoefficient' : 'plantationCoefficient';
            newModifications.push({ elementId: row.elementId, property: propertyName, oldValue: row[propertyName] });
            if (propertyName === 'patrimonialCoefficient') newRow[propertyName] = targetValue === i18n.t("Oui") ? 1.5 : (feature.properties.isRemarkable ? 1.2 : 0);
            else newRow[propertyName] = targetValue === i18n.t("Oui") ? (amenityFormulaType === 'Bruxelles' ? 1.5 : 15) : 0;
        }
        if (column.key === 'isRemarkable' && amenityFormulaType === 'Wallonie' && !feature.properties.isIndexed) {
            newModifications.push({ elementId: row.elementId, property: 'patrimonialCoefficient', oldValue: row.patrimonialCoefficient });
            newRow.patrimonialCoefficient = targetValue === i18n.t("Oui") ? 1.2 : (newRow.patrimonialCoefficient === 1.2 ? 0 : newRow.patrimonialCoefficient);
        }
        this.props.pushToModificationsHistory(newModifications);
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow, true);
    }

    handleKeyDown = (e, row, column, onRowChange) => {
        if (e.ctrlKey && e.key === 'v') {
            navigator.clipboard.readText().then(value => {
                if (value === i18n.t("Oui") || value === i18n.t("Non"))
                    this.handleDropDownChange(value, row, column, onRowChange);
            });
        } else if ([37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
            this.props.updateSelectedRow(row);
            onRowChange(row, true);
        } else if (e.keyCode === 8) this.handleDropDownChange(i18n.t("Non"), row, column, onRowChange);
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        plantationCoefficients: state.plantationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients
    }
}

export default connect(mapStateToProps)(BooleanEditor);