import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../locales/i18n';

class DropDownEditor extends Component {
    render() {
        const { row, column, onRowChange, propertyOptions } = this.props;
        const selectValue = propertyOptions.find(x => x.label === row[column.key]);

        return (
            <select
                className='rdg-text-editor' autoFocus
                value={selectValue?.id || ''}
                onKeyDown={(e) => this.handleKeyDown(e, row, column, onRowChange, propertyOptions)}
                onChange={e => { this.handleDropDownChange(e.target.value, row, column, onRowChange, propertyOptions) }}
            >
                <option value=''></option>
                {propertyOptions.map(option => (<option key={option.label} value={option.id}>{option.label}</option>))}
            </select>
        );
    }

    getRecommendedAnnualMaintenanceFrequency = (targetValue, row, column) => {
        if (!['dominantComposition', 'managementClass'].includes(column.key)) return;

        const dominantComposition = column.key === 'dominantComposition'
            ? this.props.dominantCompositions.find(dc => dc.id === Number(targetValue))
            : this.props.dominantCompositions.find(dc => dc.label === row.dominantComposition);
        const recommendedAnnualMaintenanceFrequency = column.key === 'dominantComposition'
            ? dominantComposition?.dominantCompositionManagementClasses
                .find(dcmc => dcmc.managementClassId === (this.props.managementClasses.find(mc => mc.value === Number(row.managementClass))?.id))
                ?.recommendedAnnualMaintenanceFrequency
            : dominantComposition?.dominantCompositionManagementClasses
                .find(dcmc => dcmc.managementClassId === Number(targetValue))
                ?.recommendedAnnualMaintenanceFrequency;

        return { dominantCompositionId: dominantComposition.id, recommendedAnnualMaintenanceFrequency };
    }

    handleDropDownChange = (targetValue, row, column, onRowChange, propertyOptions) => {
        const { dominantCompositionId, recommendedAnnualMaintenanceFrequency } = this.getRecommendedAnnualMaintenanceFrequency(targetValue, row, column) || {};
        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.elementId);
        const feature = index === -1
            ? JSON.parse(JSON.stringify(this.props.elements[row.id].feature))
            : elementsToModify[index];

        const value = column.customField ? targetValue : Number(targetValue);
        const amenityFormulaType = this.props.project.projectFormulaVersions.find(pfv => pfv.formulaId === 4)?.formulaType;
        if (column.customField) this.props.updateElementCustomFields(feature, column.key, value);
        else feature.properties[column.key + 'Id'] = value;
        if (['dominantComposition', 'managementClass'].includes(column.key))
            feature.properties.annualMaintenanceFrequency = recommendedAnnualMaintenanceFrequency;
        else if (['patrimonialCoefficient', 'plantationCoefficient'].includes(column.key)) {
            if (amenityFormulaType === 'Wallonie' && column.key === 'patrimonialCoefficient') {
                feature.properties.isIndexed = value === 6;
                feature.properties.isRemarkable = value === 3;
            } else if (amenityFormulaType !== 'Wallonie' && column.key === 'plantationCoefficient') feature.properties.isIndexed = [12, 17].includes(value);
        }
        if (index === -1) elementsToModify.push(feature);

        // Obligé de sauvegarder dans l'historique ici car le dropdown unfocus dès la sélection d'une valeur
        this.props.changeElementsToModify(elementsToModify);
        const newModifications = [{ elementId: row.elementId, property: column.key, oldValue: row[column.key], customField: column.customField }];
        const newValue = propertyOptions.find(x => x.id === Number(value));
        const newRow = { ...row, [column.key]: newValue?.label || newValue?.label === 0 ? newValue?.label : null };
        if (['dominantComposition', 'managementClass'].includes(column.key)) {
            if (column.key === 'dominantComposition') {
                newModifications.push({ elementId: row.elementId, property: 'dominantCompositionId', oldValue: row.dominantCompositionId });
                newRow.dominantCompositionId = dominantCompositionId;
            }
            newModifications.push({ elementId: row.elementId, property: 'annualMaintenanceFrequency', oldValue: row.annualMaintenanceFrequency });
            newRow.annualMaintenanceFrequency = recommendedAnnualMaintenanceFrequency;
        } else if (['patrimonialCoefficient', 'plantationCoefficient'].includes(column.key)) {
            newModifications.push({ elementId: row.elementId, property: 'isIndexed', oldValue: row.isIndexed });
            if (amenityFormulaType === 'Wallonie' && column.key === 'patrimonialCoefficient') {
                newRow.isIndexed = value === 6 ? i18n.t("Oui") : i18n.t("Non");
                newRow.isRemarkable = value === 3 ? i18n.t("Oui") : i18n.t("Non");
            } else if (amenityFormulaType !== 'Wallonie' && column.key === 'plantationCoefficient') newRow.isIndexed = [12, 17].includes(value) ? i18n.t("Oui") : i18n.t("Non");
        }
        this.props.pushToModificationsHistory(newModifications);
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow, true);
    }

    handleKeyDown = (e, row, column, onRowChange, propertyOptions) => {
        if (e.ctrlKey && e.key === 'v') {
            navigator.clipboard.readText().then(clipboardValue => {
                let value = propertyOptions.find(option => option.label === clipboardValue)?.id;
                value = value ? value.toFixed() : '';
                if (value || (!['coverType', 'vigor', 'healthReview'].includes(column.key) && value === ''))
                    this.handleDropDownChange(value, row, column, onRowChange, propertyOptions);
            });
        } else if ([37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
            this.props.updateSelectedRow(row);
            onRowChange(row, true);
        } else if (e.keyCode === 8 && !['coverType', 'vigor', 'healthReview'].includes(column.key))
            this.handleDropDownChange('', row, column, onRowChange, propertyOptions);
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses
    };
};

export default connect(mapStateToProps)(DropDownEditor);