import React, { Component } from 'react';
// Composants
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import MicrosoftLogin from "react-microsoft-login";
import 'semantic-ui-css/semantic.min.css';
import { Button, Icon } from 'semantic-ui-react';
import UrbasenseButton from './UrbasenseButton';
// Librairies
import { isMobileOnly } from 'react-device-detect';
// Services
import AppSettings from '../../../AppSettings';
import UsersService from '../../../services/UsersService';
import FormattersUtil from '../../../utils/FormattersUtil';
import Cookies from 'universal-cookie';

class ExternalAuthenticationForm extends Component {
    state = {
        isLoading: false,
        externalAuthentication: []
    }

    render() {
        const { isLoading, externalAuthentication } = this.state;
        const title = FormattersUtil.checkString(this.props.title) ? this.props.title : '';

        return (
            <>
                {externalAuthentication.length > 0 &&
                    <div>
                        {title && <h3 style={{ marginTop: 0, marginBottom: '5px' }}>{title}</h3>}
                        <div style={{ display: isMobileOnly && 'flex', justifyContent: isMobileOnly ? 'center' : 'flex-start' }}>
                            {this.renderExternalAuthentication()}
                            <UrbasenseButton disabled={isLoading} history={this.props.history} logUser={this.props.logUser} changeActiveItem={this.props.changeActiveItem} />
                        </div>
                    </div>}
            </>
        );
    }

    componentDidMount = () => {
        UsersService.getExternalAuthentications().then(response => {
            if (response) this.setState({ externalAuthentication: response });
        });
    }

    renderExternalAuthentication = () => {
        let fields = [];
        const { externalAuthentication, isLoading } = this.state;
        externalAuthentication.forEach(provider => {
            switch (provider.name.toUpperCase()) {
                case 'GOOGLE':
                    fields.push(
                        <GoogleLogin
                            key={provider.name} clientId={AppSettings.getGoogleClientId()} cookiePolicy={'single_host_origin'}
                            onSuccess={(response) => this.handleResponseGoogle(response, provider)}
                            onFailure={(response) => this.handleResponseGoogle(response, provider)}
                            render={renderProps => (
                                <Button
                                    key={provider.name} color='google plus' style={{ padding: '10px', width: !isMobileOnly && '100%' }}
                                    onClick={renderProps.onClick} disabled={isLoading}
                                >
                                    <Icon name='google' style={{ margin: 0 }} /> Google
                                </Button>
                            )}
                        />
                    );
                    break;
                case 'FACEBOOK':
                    fields.push(
                        <FacebookLogin
                            key={provider.name} fields='name,email'
                            appId={AppSettings.getFacebookClientId()}
                            callback={(response) => this.handleResponseFacebook(response, provider)}
                            render={renderProps => (
                                <Button
                                    key={provider.name} color='facebook' style={{ padding: '10px', margin: !isMobileOnly && '10px 0', width: !isMobileOnly && '100%' }}
                                    onClick={renderProps.onClick} disabled={isLoading}
                                >
                                    <Icon name='facebook' style={{ margin: 0 }} /> Facebook
                                </Button>
                            )}
                        />
                    );
                    break;
                case 'MICROSOFT':
                    fields.push(
                        <MicrosoftLogin
                            key={provider.name} clientId={AppSettings.getMicrosoftClientId()} prompt='select_account'
                            authCallback={(_, data) => this.handleResponseMicrosoft(data, provider)}
                            children={
                                <Button key={provider.name} color='blue' disabled={isLoading} style={{ padding: '10px', margin: !isMobileOnly && '0 0 10px 0', width: !isMobileOnly && '100%' }}>
                                    <Icon name='microsoft' style={{ margin: 0 }} /> Outlook
                                </Button>}
                        />
                    );
                    break;
                default: break;
            }
        });
        return fields;
    }

    handleResponseGoogle = (response, provider) => {
        if (response?.tokenId) {
            let externalAuthentication = {
                TokenId: response.tokenId,
                UserId: null,
                Email: response.profileObj.email,
                FirstName: response.profileObj.givenName,
                LastName: response.profileObj.familyName,
                LoginProvider: provider.name
            }
            this.handleSubmit(externalAuthentication);
        }
    }

    handleResponseMicrosoft = (response, provider) => {
        const account = response?.account;
        if (response?.uniqueId && account && FormattersUtil.checkEmail(account.username)) {
            let externalAuthentication = {
                TokenId: null,
                UserId: response.uniqueId,
                Email: account.username,
                FirstName: account.name.split(' ')[0],
                LastName: account.name.replace(account.name.split(' ')[0] + ' ', ''),
                LoginProvider: provider.name
            }

            const sessionStorage = window.sessionStorage;
            if (sessionStorage) sessionStorage.removeItem('msal.idtoken'); // Fix le bug du spam
            this.handleSubmit(externalAuthentication);
        }
    }

    handleResponseFacebook = (response, provider) => {
        if (response?.userID) {
            let externalAuthentication = {
                TokenId: null,
                UserId: response.userID,
                Email: response.email,
                FirstName: response.name.split(' ')[0],
                LastName: response.name.replace(response.name.split(' ')[0] + ' ', ''),
                LoginProvider: provider.name
            }
            this.handleSubmit(externalAuthentication);
        }
    }

    handleSubmit = (externalAuthentication) => {
        this.setState({ isLoading: true });
        externalAuthentication = { ...externalAuthentication, invitationId: new Cookies().get('invitation')?.id || null }
        UsersService.externalAuthentication(externalAuthentication).then(res => {
            if (res) {
                this.props.logUser(res.token);
                this.props.changeActiveItem('home');
                this.props.history.push('/');
            }
            this.setState({ isLoading: false });
        });
    }
}

export default ExternalAuthenticationForm;