import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import { showToast } from '../utils/ToastsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class AIService {
    static getPredictions(data) {
        return Axios.post(BACKEND_ENDPOINT + 'ai/', data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
        }).then(response => {
            return response.data;
        }, () => {
            showToast('ai_scan_failed');
            return;
        });
    }

    static getFieldPrediction(data) {
        return Axios.post(BACKEND_ENDPOINT + 'ai/trees/', data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
        }).then(response => {
            return response;
        }, (error) => {
            return error?.response;
        });
    }

    static testCustomApi(url) {
        return Axios.get(BACKEND_ENDPOINT + 'ai/custom/?url=' + url, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
        }).then(response => {
            return response;
        }, () => {
            return;
        });
    }
}