import React, { Component } from 'react';
// Librairies
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
// Ressources
import logoMonoWhite from '../../resources/images/logo-mono-white.jpg';
// Semantic UI
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
// Services
import UsersService from '../../services/UsersService';
import FormattersUtil from '../../utils/FormattersUtil';

const initialError = {
    hidden: true,
    messages: [],
    email: false
}

class ForgotPassword extends Component {
    state = {
        email: '',
        error: initialError,
        success: false,
        isLoading: false
    };

    render() {
        const { email, isLoading } = this.state;
        return (
            <Grid textAlign='center' style={{ margin: '0', flexGrow: 1 }} verticalAlign='middle' className='grality-background'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' className='text-light' textAlign='center'>
                        <Image style={{ borderRadius: '30px', marginTop: 0, marginRight: '3px' }} src={logoMonoWhite} /> {i18n.t("Mot de passe oublié")}
                    </Header>
                    <Form size='large' style={{ borderRadius: '5px' }} loading={isLoading} error success>
                        <Segment>
                            <Form.Input
                                fluid icon='mail' iconPosition='left' placeholder={i18n.t("Adresse e-mail")}
                                name='email' value={email} onChange={this.handleChange} error={this.state.error.email}
                            />
                            <Button className='btn-primary' fluid size='large' onClick={this.handleSubmit} style={{ marginTop: '10px' }}>
                                {i18n.t("Valider")}
                            </Button>
                            {!this.state.error.hidden &&
                                <Message
                                    error style={{ textAlign: 'left' }} hidden={this.state.error.hidden}
                                    header={i18n.t("Erreur")} list={this.state.error.messages}
                                />}
                            {this.state.success &&
                                <Message
                                    success style={{ textAlign: 'left' }} hidden={!this.state.success}
                                    header={i18n.t("Demande réussie")}
                                    content={i18n.t("Vous allez recevoir un e-mail de réinitialisation dans quelques secondes. Merci de vérifier votre boîte mail ainsi que la section 'Spams'.")}
                                />}
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }

    componentDidMount = () => {
        this.props.changeActiveItem('forgotPassword');
        this.props.setProject(null);
    }

    componentDidUpdate = () => { // Si l'utilisateur est déjà connecté, on l'empêche d'accéder au composant login
        if (this.props.logged !== false) {
            this.props.changeActiveItem('home');
            this.props.history.push('/');
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            error: initialError
        });
    }

    handleSubmit = (e) => {
        let flag = true;
        let emailError = false;
        let messages = [];
        const email = this.state.email;

        // Vérifications
        if (email.length === 0 || !FormattersUtil.checkEmail(email)) {
            messages = [...(messages || []), i18n.t("L'adresse email est invalide")];
            emailError = true;
            flag = false;
        }

        // Si toutes les vérifications sont bonnes
        if (flag) {
            const forgotPassword = { email: email.trim() };

            this.setState({ isLoading: true });
            UsersService.forgotPassword(forgotPassword).then(() => {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        hidden: true,
                        messages: []
                    },
                    success: true
                }));
                this.setState({ isLoading: false });
            });
        } else this.setState({ // Si les vérifications ne sont pas bonnes, on affiche des messages d'erreurs
            error: {
                hidden: false,
                messages: messages,
                email: emailError
            }
        });
    };
}

const mapDispatchToProps = {
    setProject
};

export default connect(null, mapDispatchToProps)(ForgotPassword);