import React, { Component } from 'react';
// Semantic UI
import { Button, Icon } from 'semantic-ui-react';

class MiniButton extends Component {
    render() {
        const { id, icon, title, color, disabled, onClick, loading, className, as, htmlFor } = this.props;
        return (
            <Button
                id={id} type='button' size='mini' color={color} title={title} className={className}
                as={as} htmlFor={htmlFor} style={{ padding: 4, marginTop: 2, marginBottom: 2 }}
                disabled={disabled} loading={loading} onClick={onClick}
            >
                <Icon name={icon} style={{ marginLeft: 0, marginRight: 0 }} />
            </Button>
        );
    }
}

export default MiniButton;