import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Grid, Input, Message } from 'semantic-ui-react';
// Librairies
import { connect } from 'react-redux';
import { setUserInfos } from '../../../actionCreators/usersActions';
import i18n from '../../../locales/i18n';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
// Services
import UsersService from '../../../services/UsersService';
import FormattersUtil from '../../../utils/FormattersUtil';

const initialError = {
    hidden: true,
    messages: [],
    currentPassword: false,
    newPassword: false,
    newPasswordConfirmation: false
};

const initialData = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
};

class PasswordForm extends Component {
    state = {
        isLoading: false,
        data: initialData,
        error: initialError
    }

    render() {
        const { isLoading } = this.state;
        const { currentPassword, newPassword, newPasswordConfirmation } = this.state.data;
        const error = this.state.error;
        const { hasPassword } = this.props.userInfos;
        const isValidBtnDisabled = !this.props.isOnline || this.props.loginAsData || (currentPassword.length === 0 && hasPassword) || newPassword.length === 0 ||
            newPasswordConfirmation.length === 0 || currentPassword === newPassword;

        return (
            <Form loading={isLoading} onSubmit={this.handleSubmit} error>
                <Grid>
                    {hasPassword &&
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field
                                    name='currentPassword' control={Input} label={i18n.t("Mot de passe (actuel)") + '* : '}
                                    placeholder={i18n.t("Saisissez votre mot de passe")} disabled={this.props.loginAsData}
                                    value={currentPassword} type='password' error={error.currentPassword} onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column>
                            <Form.Field
                                name='newPassword' control={Input} label={i18n.t("Mot de passe (nouveau)") + '* : '}
                                placeholder={i18n.t("Saisissez un mot de passe")} disabled={this.props.loginAsData}
                                value={newPassword} type='password' error={error.newPassword} onChange={this.handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field
                                name='newPasswordConfirmation' control={Input} label={i18n.t("Mot de passe (confirmation)") + '* : '}
                                placeholder={i18n.t("Confirmez le mot de passe")} disabled={this.props.loginAsData}
                                value={newPasswordConfirmation} type='password' error={error.newPasswordConfirmation} onChange={this.handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Message
                                error style={{ textAlign: 'left' }} hidden={error.hidden}
                                header='Erreur' list={error.messages}
                            />
                            <Button type='submit' color='green' className='form-button' disabled={isValidBtnDisabled}>
                            <FontAwesomeIcon icon={faCheck} style={{ marginLeft: 0, marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    handleSubmit = () => {
        const { hasPassword } = this.props.userInfos;
        let flag = true;
        let fields = {
            currentPassword: this.state.data.currentPassword,
            newPassword: this.state.data.newPassword,
            newPasswordConfirmation: this.state.data.newPasswordConfirmation
        }
        let errors = {
            currentPassword: this.state.error.currentPassword,
            newPassword: this.state.error.newPassword,
            newPasswordConfirmation: this.state.error.newPasswordConfirmation
        }
        let messages = [];

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            flag = false;
        }

        if (!FormattersUtil.checkString(fields.currentPassword) && hasPassword)
            addError('currentPassword', i18n.t("Le mot de passe actuel est invalide"));

        if (fields.newPassword !== fields.newPasswordConfirmation)
            addError('password', i18n.t("Les mots de passe ne correspondent pas"));
        else if (fields.newPassword.length < 8)
            addError('password', i18n.t("Le mot de passe doit contenir au moins 8 caractères"));
        else if (fields.currentPassword === fields.newPassword)
            addError('password', i18n.t("Le nouveau mot de passe doit être différent de l'actuel"));

        if (flag) {
            const changePassword = {
                currentPassword: fields.currentPassword.trim(),
                newPassword: fields.newPassword.trim()
            };

            this.setState({ isLoading: true });
            UsersService.changePassword(changePassword).then(response => {
                this.setState({ isLoading: false });
                if (response) {
                    this.props.setUserInfos({ ...this.props.userInfos, hasPassword: true });
                    this.setState({ data: initialData, error: initialError });
                } else { // Sinon on affiche la liste des messages d'erreurs
                    this.setState({
                        error: {
                            hidden: false,
                            messages: [i18n.t("Le mot de passe actuel est invalide")],
                            currentPassword: errors.currentPassword,
                            newPassword: errors.newPassword,
                            newPasswordConfirmation: errors.newPasswordConfirmation
                        }
                    });
                }
            });
        } else { // Si les vérifications ne sont pas bonnes
            this.setState({ // On affiche les messages d'erreurs correspondants
                error: {
                    hidden: false,
                    messages: messages,
                    currentPassword: errors.currentPassword,
                    newPassword: errors.newPassword,
                    newPasswordConfirmation: errors.newPasswordConfirmation
                }
            });
        }
    }

    handleChange = (e, { name, value }) => {
        this.setState(prevState => ({
            data: { ...prevState.data, [name]: value },
            error: { ...prevState.error, [name]: false }
        }));
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        userInfos: state.userInfos,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setUserInfos
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);