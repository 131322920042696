import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
// Librairies
import { isMobileOnly } from 'react-device-detect';
// Redux
import { connect } from 'react-redux';
// Semantic UI
import { Form, Select, Grid, Segment, Input } from 'semantic-ui-react';
import i18n from '../../../../locales/i18n';
// Utils
import FormattersUtil from '../../../../utils/FormattersUtil';
import { faTrees } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step5 extends Component {
    state = {
        properties: {
            dominantEssence: {
                id: 0,
                vernacularName: null,
                gender: null,
                species: null,
                cultivar: null,
                averageHealthReviewId: 0,
                averageHeight: 0,
                averageCircumference: 0,
                averageCrownDiameter: 0,
                nbTrees: 0,
                density: 0,
                distanceBetweenTrunks: 0
            },
            dominantEssenceId: 0,
            error: {
                hidden: true,
                messages: [],
                averageHeight: false,
                averageCircumference: false,
                averageCrownDiameter: false,
                density: false
            }
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.greenSpaces;
        const healthReviews = this.props.healthReviews.map(hr => { return { text: hr.value + ' (' + hr.description + ')', value: hr.id } })
        const nbMaxTrees = this.getNbMaxTrees();

        const vernacularNames = FormattersUtil.sortByAlphabeticalOrder(
            [...new Set(this.props.essences
                .map(item => item.vernacularName))]
                .filter(x => x)
                .map(x => { return { text: x, value: x } })
        );

        const genders = FormattersUtil.sortByAlphabeticalOrder(
            [...new Set(this.props.essences
                .map(item => item.gender))]
                .map(x => { return { text: x, value: x } })
        );

        const species = FormattersUtil.sortByAlphabeticalOrder(
            [...new Set(this.props.essences
                .filter(x => this.props.properties.dominantEssence && (x.gender === this.props.properties.dominantEssence.gender && x.species))
                .map(x => x.species))]
                .map(x => { return { text: x, value: x } })
        );

        const cultivars = FormattersUtil.sortByAlphabeticalOrder(
            this.props.essences
                .filter(x => this.props.properties.dominantEssence && (x.gender === this.props.properties.dominantEssence.gender
                    && x.species === this.props.properties.dominantEssence.species
                    && x.cultivar))
                .map(x => x.cultivar)
                .map(x => { return { text: x, value: x } })
        );

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16
        };

        return (
            <Grid id='cat--wooden-massif' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--primary-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Espace vert', 'Massif arboré') }}>
                <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--primary-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                    <FontAwesomeIcon icon={faTrees} style={{ alignSelf: 'center', marginRight: '5px' }} />
                    <span className='no-themed'>{i18n.t("Massif arboré")}</span>
                </Grid.Row>
                <Grid.Column mobiler={16} tablet={16} computer={16} style={isMobileOnly ? { padding: '10px 4px' } : null}>
                    {requiredFields.dominantEssence &&
                        <Segment style={{ padding: '7px' }}>
                            <label style={{ fontSize: '.92857143em', fontWeight: 'bold', marginTop: '10px', marginLeft: '7px' }}>{i18n.t("Essence dominante")} :</label>
                            <Grid style={{ margin: 0 }}>
                                <Grid.Column {...columnProps}>
                                    <Form.Field
                                        control={Select} label={<label>{i18n.t("Nom vernaculaire")} <InfoIcon content={i18n.t("Nom courant donné en français")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder={i18n.t("Sélectionnez un nom vernaculaire")}
                                        name='vernacularName' options={vernacularNames} value={this.props.properties.dominantEssence ? (this.props.properties.dominantEssence.vernacularName || '') : ''}
                                        selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        disabled={vernacularNames.length < 1} onChange={this.props.handleDominantEssenceChange}
                                    />
                                </Grid.Column>
                                <Grid.Column {...columnProps}>
                                    <Form.Field
                                        control={Select} label={i18n.t("Genre") + ' : '} placeholder={i18n.t("Sélectionnez un genre")}
                                        name='gender' options={genders} value={this.props.properties.dominantEssence ? (this.props.properties.dominantEssence.gender || '') : ''}
                                        clearable selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        error={this.props.error.gender} onChange={this.props.handleDominantEssenceChange}
                                    />
                                </Grid.Column>
                                <Grid.Column {...columnProps}>
                                    <Form.Field
                                        control={Select} label={i18n.t("Espèce") + ' : '} placeholder={i18n.t("Sélectionnez une espèce")}
                                        name='species' options={species} value={this.props.properties.dominantEssence ? (this.props.properties.dominantEssence.species || '') : ''}
                                        selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        disabled={species.length < 1} onChange={this.props.handleDominantEssenceChange}
                                    />
                                </Grid.Column>
                                <Grid.Column {...columnProps}>
                                    <Form.Field
                                        control={Select} label={i18n.t("Cultivar") + ': '} placeholder={i18n.t("Sélectionnez un cultivar")}
                                        name='cultivar' options={cultivars} value={this.props.properties.dominantEssence ? (this.props.properties.dominantEssence.cultivar || '') : ''}
                                        selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        disabled={cultivars.length < 1} onChange={this.props.handleDominantEssenceChange}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Segment>}
                </Grid.Column>
                {requiredFields.averageHealthReview &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez une cote")}
                            label={<label>{i18n.t("Cote sanitaire moyenne")} <InfoIcon content={i18n.t("Note globale de l'arbre sur 10")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='averageHealthReviewId' options={healthReviews} value={this.props.properties.averageHealthReviewId || ''} clearable
                            selectOnBlur={false} search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                            error={this.props.error.averageHealthReviewId} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.averageHeight &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Input} type='number' step='0.1' label={i18n.t("Hauteur moyenne") + ' (m) : '} placeholder='Ex: 15'
                            name='averageHeight' value={Math.round((this.props.properties.averageHeight / 100) * 100) / 100 || ''}
                            error={this.props.error.averageHeight} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.averageCircumference &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Input} type='number' step='0.1' placeholder='Ex: 120'
                            label={<label>{i18n.t("Circonférence moyenne des troncs")} (cm) <InfoIcon content={i18n.t("Mesure de la circonférence de l'axe à 150 cm (à 130cm pour la France)")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='averageCircumference' value={this.props.properties.averageCircumference || ''}
                            error={this.props.error.averageCircumference} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.averageCrownDiameter &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Input} type='number' step='0.1' placeholder='Ex: 1'
                            label={<label>{i18n.t("Diamètre moyen des couronnes")} (m) <InfoIcon content={i18n.t("Distance moyenne entre les extrémités de la couronne")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='averageCrownDiameter' value={Math.round((this.props.properties.averageCrownDiameter / 100) * 100) / 100 || ''}
                            error={this.props.error.averageCrownDiameter} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.density &&
                    <>
                        <Grid.Column {...columnProps} computer={5} tablet={5} mobile={16}>
                            <Form.Field
                                control={Input} type='number' step='1' label={<label>{i18n.t("Nombre d'arbres") + ' :'}<div style={{ color: this.props.properties.nbTrees <= nbMaxTrees ? 'grey' : 'orange', float: 'right' }}>{`(${i18n.t("Maximum estimé")} : ${this.props.properties.averageCrownDiameter ? nbMaxTrees : i18n.t("Indéterminé")})`}</div></label>}
                                placeholder='Ex: 350' name='nbTrees' value={this.props.properties.nbTrees || ''}
                                error={this.props.error.density} onChange={this.props.handleDensityChange}
                            />
                        </Grid.Column>
                        <Grid.Column {...columnProps} computer={5} tablet={5} mobile={16}>
                            <Form.Field
                                control={Input} type='number' step='0.01' label={i18n.t("Densité") + ' (/ha) :'} placeholder='Ex: 700'
                                name='density' value={this.props.properties.density || ''}
                                error={this.props.error.density} onChange={this.props.handleDensityChange}
                            />
                        </Grid.Column>
                        <Grid.Column {...columnProps} computer={5} tablet={5} mobile={16}>
                            <Form.Field
                                control={Input} type='number' step='1' label={i18n.t("Distance moyenne entre les troncs") + ' (m) :'} placeholder='Ex: 4'
                                name='distanceBetweenTrunks' value={this.props.properties.distanceBetweenTrunks || ''}
                                error={this.props.error.density} onChange={this.props.handleDensityChange}
                            />
                        </Grid.Column>
                    </>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré'))}
            </Grid>
        );
    }

    getNbMaxTrees = () => {
        if (!this.props.layer || !this.props.properties.surface || !this.props.properties.averageCrownDiameter) return 0;
        const surface = this.props.properties.surface;
        const averageCrownDiameter = this.props.properties.averageCrownDiameter;
        return Math.round((surface / (Math.PI * Math.pow((averageCrownDiameter / 200), 2)) * 1.2));
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        essences: state.essences,
        healthReviews: state.healthReviews,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step5);