import React, { Component } from 'react';
// Composants
import { Button, Icon } from 'semantic-ui-react';
import NewWindow from 'react-new-window';
import UrbasenseForm from './UrbasenseForm';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../locales/i18n';
// Ressources
import UrbasenseLogo from '../../../resources/pngs/urbasense.png';

const height = 600;
const width = 500;

class UrbasenseButton extends Component {
    state = {
        isFormOpened: false,
        window: null
    };

    render() {
        const { style, isLinking, isLinked, disabled } = this.props;
        const { isFormOpened } = this.state;
        var left = (window.screen.width / 2) - (width / 2);
        var top = (window.screen.height / 2) - (height / 2);

        return (
            <>
                <Button
                    color={isLinking ? isLinked ? 'blue' : 'white' : null} className={!isLinking && 'button--urbasense'} size={isLinking && 'mini'} icon={isLinking}
                    labelPosition={isLinking && 'left'} disabled={disabled} onClick={this.handleClick}
                    style={!isLinking
                        ? { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', color: 'white', ...(style || {}) }
                        : { padding: '8px 8px', margin: 0, width: isMobileOnly ? '100%' : null }}
                >
                    {!isLinking
                        ? <><img src={UrbasenseLogo} alt='urbasense' style={{ width: '14px', marginRight: '5px', filter: 'brightness(0) invert(1)' }} /> Urbasense</>
                        : <><Icon name='linkify' />{isLinked ? i18n.t("Délier") : i18n.t("Lier")}</>}

                </Button>
                {isFormOpened &&
                    <NewWindow
                        features={{ top, left, height, width }} title={`${i18n.t("Connexion")} - Urbasense`}
                        onOpen={this.handleOpen} onUnload={() => this.setState({ isFormOpened: false })}
                    >
                        <UrbasenseForm window={window} isLinking={this.props.submit ? true : false} submit={(response) => this.submit(response)} />
                    </NewWindow>}
            </>
        );
    }

    handleClick = () => {
        if (!this.state.isFormOpened) this.setState({ isFormOpened: true });
    }

    handleOpen = (window) => {
        this.setState({ window });
    }

    submit = (response) => {
        this.state.window.close();
        this.setState({ isFormOpened: false });
        if (this.props.submit) this.props.submit(response);
        else {
            this.props.logUser(response);
            this.props.changeActiveItem('home');
            this.props.history.push('/');
        }
    }
}

export default UrbasenseButton;