import React, { Component } from 'react';
// Composants
import { Button, Modal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../locales/i18n';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { isChrome } from 'react-device-detect';
// Ressources
import ChromePNG from '../../resources/pngs/chrome.png';

const LOCAL_STORAGE_ITEM_NAME = 'chromeModalClosed';
class ChromeModal extends Component {
    state = {
        open: false
    }

    render() {
        const { open } = this.state;

        return (
            <Modal size='tiny' open={open}>
                <Modal.Header style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={ChromePNG} alt='chrome' style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                    {i18n.t("Passez à Chrome !")}
                </Modal.Header>
                <Modal.Content>
                    <p>{i18n.t("Grality est développé et testé sur Chrome, nous ne garantissons donc pas un fonctionnement nominal sur les autres navigateurs. Afin de vous garantir une meilleure expérience, nous vous conseillons d'utiliser le navigateur Chrome.")}</p>
                    <p>{i18n.t("Vous n'avez pas Chrome ?")} <a href='https://www.google.com/intl/fr/chrome/' target='_blank' rel='noopener noreferrer' style={{ cursor: 'pointer' }}>{i18n.t("Cliquez ici pour le télécharger.")}</a></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' color='green' onClick={this.close}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("J'ai compris")}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    componentDidMount = () => {
        const alreadyRead = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
        const showModal = !isChrome && !alreadyRead;
        if (showModal) this.setState({ open: true });
    }

    close = () => {
        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, true);
        this.setState({ open: false });
    }
}

export default ChromeModal;