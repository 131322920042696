import React, { Component } from 'react';
// Composants
import InfoIcon from '../../../Utils/InfoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Grid, Select } from 'semantic-ui-react'
// Librairies
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../../../locales/i18n';
// Ressources
import { faCalculator } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step6 extends Component {
    state = {
        properties: {
            plantationCoefficientId: 0,
            situationCoefficientId: 0,
            patrimonialCoefficientId: 0
        },
        error: {
            hidden: true,
            messages: [],
        }
    }

    render() {
        const { project, formulas } = this.props;
        const requiredFields = this.props.requiredFields.trees;
        let amenityFormulaType;

        let plantationCoefficients; let situationCoefficients; let patrimonialCoefficients;
        if (project) {
            let propertyName;
            const formula = formulas.find(formula => formula.id === 4);
            if (formula?.formulaVersions) {
                const formulaVersion = formula.formulaVersions.find(fv => project.projectFormulaVersions.find(pfv => pfv.formulaVersionId === fv.id));
                if (formulaVersion) {
                    const formulaLabel = formulaVersion.label.toLowerCase();
                    amenityFormulaType = formulaLabel.includes('wallonie') ? 'Wallonie'
                        : formulaLabel.includes('bruxelles') ? 'Bruxelles'
                            : 'France';
                    propertyName = formulaLabel.includes('wallonie') ? 'descriptionWln'
                        : formulaLabel.includes('bruxelles') ? 'descriptionBxl'
                            : 'descriptionFr';
                }
            }

            plantationCoefficients = this.props.plantationCoefficients.filter(x => x[propertyName]);
            situationCoefficients = this.props.situationCoefficients.filter(x => x[propertyName]);

            plantationCoefficients = plantationCoefficients.map(x => {
                let value = x.value;
                if (x[propertyName]) value += ' (' + x[propertyName] + ')';
                return { text: value, value: x.id }
            });

            situationCoefficients = situationCoefficients.map(x => {
                let value = x.value;
                if (x[propertyName]) value += ' (' + x[propertyName] + ')';
                return { text: value, value: x.id }
            });

            patrimonialCoefficients = this.props.patrimonialCoefficients.map(x => {
                let value = x.value;
                if (x.description) value += ' (' + x.description + ')';
                return { text: value, value: x.id }
            });
        }

        const columnProps = {
            stretched: true,
            computer: 8,
            tablet: 8,
            mobile: 16,
            style: isMobileOnly ? { padding: '4px' } : null
        };

        return (
            <Grid id='cat--coefficients' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--pink-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Arbre', 'Coefficients') }}>
                {!isMobileOnly &&
                    <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--pink-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                        <FontAwesomeIcon icon={faCalculator} style={{ alignSelf: 'center', marginRight: '5px' }} />
                        <span className='no-themed'>{i18n.t("Coefficients")}</span>
                        <InfoIcon content={i18n.t("Les coefficients sont utilisés pour le calcul de la valeur d'agrément")} iconStyle={{ marginLeft: '3px', alignSelf: 'center' }} />
                    </Grid.Row>}
                {requiredFields.plantationCoefficient &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un coefficient de plantation")}
                            label={<label>{i18n.t("Coefficient de plantation")} <InfoIcon content={i18n.t("Situation dans le paysage / degré d'isolement")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='plantationCoefficientId' options={plantationCoefficients} disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.plantationCoefficientId || '') : ''}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.situationCoefficient &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un coefficient de situation")}
                            label={<label>{i18n.t("Coefficient de situation")} <InfoIcon content={i18n.t("Environnement dans lequel il est situé")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='situationCoefficientId' options={situationCoefficients} disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.situationCoefficientId || '') : ''}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.patrimonialCoefficient && (!this.props.project || amenityFormulaType === 'Wallonie') &&
                    <Grid.Column {...columnProps}>
                        <Form.Field
                            control={Select} placeholder={i18n.t("Sélectionnez un coefficient patrimonial")}
                            label={<label>{i18n.t("Coefficient patrimonial")} <InfoIcon content={i18n.t("Importance patrimoniale")} iconStyle={{ marginLeft: '3px' }} /> :</label>}
                            name='patrimonialCoefficientId' options={patrimonialCoefficients} disabled={this.props.properties.isEmpty}
                            value={!this.props.properties.isEmpty ? (this.props.properties.patrimonialCoefficientId || '') : ''}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Arbre' && dfc.label === 'Coefficients'))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        plantationCoefficients: state.plantationCoefficients,
        situationCoefficients: state.situationCoefficients,
        patrimonialCoefficients: state.patrimonialCoefficients,
        project: state.project,
        formulas: state.formulas,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step6);