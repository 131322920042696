import React, { Component } from 'react';
import i18n from '../../../locales/i18n';

export default class BooleanEditor extends Component {
    render() {
        const { row, column, onRowChange } = this.props;

        return (
            <select
                className='rdg-text-editor' style={{ textAlignLast: 'center' }}
                value={row[column.key] || ''} autoFocus
                onKeyDown={(e) => this.handleKeyDown(e, row, column, onRowChange)}
                onChange={e => { this.handleDropDownChange(e.target.value, row, column, onRowChange) }}
            >
                <option value={i18n.t("Oui")}>{i18n.t("Oui")}</option>
                <option value={i18n.t("Non")}>{i18n.t("Non")}</option>
            </select>
        );
    }

    handleDropDownChange = (targetValue, row, column, onRowChange) => {
        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.id);
        if (index === -1) {
            let element = JSON.parse(JSON.stringify(this.props.elements.find(element => element.id === row.id)));
            element[column.key] = targetValue === i18n.t("Oui") ? true : false;
            elementsToModify.push(element);
        } else elementsToModify[index][column.key] = targetValue === i18n.t("Oui") ? true : false;

        // Obligé de sauvegarder dans l'historique ici car le dropdown unfocus dès la sélection d'une valeur
        this.props.pushToModificationsHistory([{ property: column.key, elementId: row.id, oldValue: row[column.key] }]);
        this.props.changeElementsToModify(elementsToModify);
        const newRow = { ...row, [column.key]: targetValue || '' };
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow, true);
    }

    handleKeyDown = (e, row, column, onRowChange) => {
        if (e.ctrlKey && e.key === 'v') {
            navigator.clipboard.readText().then(value => {
                if (value === i18n.t("Oui") || value === i18n.t("Non"))
                    this.handleDropDownChange(value, row, column, onRowChange);
            });
        } else if ([37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
            this.props.updateSelectedRow(row);
            onRowChange(row, true);
        } else if (e.keyCode === 8) this.handleDropDownChange(i18n.t("Non"), row, column, onRowChange);
    }
}