import React, { Component } from 'react';
// Composants
import { Button, Checkbox } from 'semantic-ui-react';
import { faCheck, faCheckCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { isMobileOnly } from 'react-device-detect';
// Utils
import ProjectsUtil from '../../utils/ProjectsUtil';

class ProjectLogTypeList extends Component {
    state = {
        selectedProjectTypes: []
    };

    render() {
        const { isDarkTheme } = this.props;
        const { selectedProjectTypes } = this.state;
        const projectLogTypes = ProjectsUtil.getProjectLogTypes();
        const areAllSelected = selectedProjectTypes.length === Object.keys(projectLogTypes).length;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: isDarkTheme ? 'var(--black-100)' : 'var(--white-100)', borderRadius: '5px', padding: '10px', textAlign: 'left' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <h3 style={{ flex: 1, margin: 0 }}>{i18n.t("Filtrer les types ({{nb}}/{{nbMax}})", { nb: selectedProjectTypes.length, nbMax: Object.keys(projectLogTypes).length })}</h3>
                    <Checkbox
                        checked={areAllSelected} onChange={() => this.setState({ selectedProjectTypes: areAllSelected ? [] : Object.keys(projectLogTypes) })}
                        label={i18n.t("Tout sélectionner")} style={{ marginLeft: 'auto' }}
                    />
                </div>
                <div style={{ height: '500px', maxHeight: !isMobileOnly && '500px', flex: isMobileOnly && 1, overflowY: 'auto', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '10px' }}>{this.renderProjectLogTypes(projectLogTypes)}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: isMobileOnly ? 'auto' : '20px' }}>
                    <Button color='grey' onClick={this.props.cancel}>
                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                    </Button>
                    <Button color='blue' onClick={() => this.props.submit(selectedProjectTypes)}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />
                        {i18n.t("Appliquer")}
                    </Button>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.setState({ selectedProjectTypes: this.props.selectedProjectTypes });
    }

    renderProjectLogTypes = (projectLogTypes) => {
        return Object.keys(projectLogTypes).map((projectLogType, index) => {
            const isSelected = this.state.selectedProjectTypes.includes(projectLogType);
            const description = this.props.projectTypesDescriptions ? this.props.projectTypesDescriptions[projectLogType] : null;

            return (
                <Button key={index} size='small' style={{
                    display: 'flex', alignItems: 'center', width: isMobileOnly ? '100%' : 'calc(50% - 10px)', margin: 0,
                    backgroundColor: isSelected ? 'var(--primary-100)' : 'var(--black-40)', color: 'var(--white-100)'
                }} onClick={() => this.toggleProjectLogType(projectLogType)}
                >
                    <div style={{ flex: 1, textAlign: 'left' }}>
                        <FontAwesomeIcon icon={projectLogTypes[projectLogType].icon} style={{ marginRight: '7px' }} />{projectLogTypes[projectLogType].label}{description !== null && ` (${description})`}
                    </div>
                    <FontAwesomeIcon icon={isSelected ? faCheckCircle : faCircle} />
                </Button>
            );
        });
    }

    toggleProjectLogType = (projectLogType) => {
        let selectedProjectTypes = [...this.state.selectedProjectTypes];
        const index = selectedProjectTypes.indexOf(projectLogType);
        if (index === -1) selectedProjectTypes.push(projectLogType);
        else selectedProjectTypes.splice(index, 1);
        this.setState({ selectedProjectTypes });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(ProjectLogTypeList);