import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Grid, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faFloppyDiskCircleXmark, faTimes } from '@fortawesome/pro-solid-svg-icons';
// Librairies
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setExitFormWithChanges } from '../../actionCreators/componentsActions';
import { isMobileOnly } from 'react-device-detect';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class ExitFormPopup extends Component {
    render() {
        return (
            <Dimmer active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed' }}>
                <Grid style={{ height: '100%', maxWidth: isMobileOnly && '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='center'>
                            <Message compact className='tableConfirmation'>
                                <Message.Header>{i18n.t("Des modifications n'ont pas été sauvegardées, que faire ?")}</Message.Header>
                                <Message.Content style={{ marginTop: '10px', display: 'flex', flexDirection: isMobileOnly ? 'column' : 'row', gap: isMobileOnly && '10px' }}>
                                    <Button color='green' onClick={() => {
                                        this.props.exitFormWithChanges.submit();
                                        this.props.setExitFormWithChanges(null);
                                        if (this.props.hideForm) this.props.hideForm(true);
                                        this.props.close();
                                    }}>
                                        <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                    </Button>
                                    <Button color='red' onClick={() => {
                                        if (this.props.exitFormWithChanges.cancel)
                                            this.props.exitFormWithChanges.cancel();
                                        else if (this.props.hideForm) this.props.hideForm(true);
                                        this.props.close();
                                    }}>
                                        <FontAwesomeIcon icon={faFloppyDiskCircleXmark} style={{ marginRight: '10px' }} />{i18n.t("Ne pas sauvegarder")}
                                    </Button>
                                    <Button color='grey' onClick={() => {
                                        this.props.close();
                                    }}>
                                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    componentWillUnmount = () => {
        this.props.setExitFormWithChanges(null);
    }
}

const mapStateToProps = (state) => {
    return {
        exitFormWithChanges: state.exitFormWithChanges
    };
};

const mapDispatchToProps = {
    setExitFormWithChanges
};

export default connect(mapStateToProps, mapDispatchToProps)(ExitFormPopup);