import React, { Component } from 'react';
import FormattersUtil from '../../../utils/FormattersUtil';

export default class SmartTextEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        startValue: null,
        endValue: null
    };

    render() {
        const { row, column, onRowChange } = this.props;
        const propertyOptions = this.props.propertyOptions;

        return (
            <input
                className='rdg-text-editor' ref={this.autoFocusAndSelect} value={row[column.key] || ''}
                onChange={e => this.handleValueChange(e, row, column, onRowChange, propertyOptions)} onBlur={this.handleBlur}
            />
        );
    }

    componentDidMount = () => this.setState({ startValue: this.props.row[this.props.column.key] });

    componentDidUpdate = () => { // Permet de mettre à jour la startValue lorsqu'on modifie le champs lié
        if (this.inputRef.current && document.activeElement !== this.inputRef.current && this.state.startValue !== this.props.row[this.props.column.key])
            this.setState({ startValue: this.props.row[this.props.column.key] });
    }

    componentWillUnmount = () => { if (!this.blurred) this.handleBlur(); } // L'event blur n'est pas déclenché lorsqu'on appuie sur enter/tab

    handleBlur = () => {
        const { startValue, endValue } = this.state;
        const { row, column, onClose, pushToModificationsHistory } = this.props;
        if (startValue !== endValue && endValue) {
            pushToModificationsHistory([
                { elementId: row.elementId, property: column.key, oldValue: startValue, customField: column.customField }
            ]);
            this.setState({ startValue: endValue });
        }
        onClose(true);
        this.blurred = true;
        setTimeout(() => this.blurred = false, 100);
    }

    handleValueChange = (e, row, column, onRowChange, propertyOptions) => {
        let values = e.target.value;
        if (values.substring(values.length - 2, values.length) === ',,')
            values = values.substring(0, values.length - 1);

        if (values.substring(values.length - 1, values.length) === ',' && values.includes('-') && column.key !== 'tags')
            values = values.substring(0, values.length - 1);
        values = values.split(',');
        let valuesId = [];
        let newValue = '';
        values.forEach((value, i) => {
            if (value.substring(0, 2).trim() === '-') value = value.substring(2);

            let linkedValue;
            if (value?.trim())
                linkedValue = propertyOptions.find(x => FormattersUtil.getNormalizedString(x.label) === FormattersUtil.getNormalizedString(value.trim()));
            if (linkedValue) {
                newValue += newValue === '' ? linkedValue.label : ', ' + linkedValue.label;
                if (!valuesId.includes(linkedValue.id)) valuesId.push(linkedValue.id);
            } else {
                if (value === '') newValue += newValue === '' ? value : ',' + value;
                else {
                    if (i === values.length - 1) newValue += newValue === '' ? '- ' + value : ', -' + value;
                    else newValue += newValue === '' ? value : ', ' + value.trim();
                }
            }
        });

        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.id);
        if (index === -1) {
            let element = JSON.parse(JSON.stringify(this.props.elements.find(element => element.id === row.id)));
            element[column.key] = valuesId;
            elementsToModify.push(element);
        } else elementsToModify[index][column.key] = valuesId;

        this.setState({ endValue: newValue });
        this.props.changeElementsToModify(elementsToModify);
        const newRow = { ...row, [column.key]: newValue || null };
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow);
    }

    autoFocusAndSelect = (input) => {
        if (input) {
            input.focus();
            input.select();
            setTimeout(() => {
                input.setSelectionRange(input.value.length, input.value.length);
            }, 100);
            this.inputRef.current = input;
        }
    }
}