export const setPhotosGalleries = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PHOTOS_GALLERIES', data: action });
    return Promise.resolve();
};

export const setFilesGalleries = (action) => async (dispatch, getState) => {
    dispatch({ type: 'SET_FILES_GALLERIES', data: action });
    return Promise.resolve();
};

export const setPlace = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_PLACE', data: properties });
    return Promise.resolve();
};

export const setElementHistory = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ELEMENT_HISTORY', data: properties });
    return Promise.resolve();
};

export const setActionHistory = (properties) => async (dispatch, getState) => {
    dispatch({ type: 'SET_ACTION_HISTORY', data: properties });
    return Promise.resolve();
};

export const setLayer = (layer) => async (dispatch, getState) => {
    dispatch({ type: 'SET_LAYER', data: layer });
    return Promise.resolve();
};

export const setDefaultFieldCategories = (defaultFieldCategories) => async (dispatch, getState) => {
    dispatch({ type: 'SET_DEFAULT_FIELD_CATEGORIES', data: defaultFieldCategories });
    return Promise.resolve();
};

export const setCustomFields = (customFields) => async (dispatch, getState) => {
    dispatch({ type: 'SET_CUSTOM_FIELDS', data: customFields });
    return Promise.resolve();
};

export const setLockedElements = (lockedElements) => async (dispatch, getState) => {
    dispatch({ type: 'SET_LOCKED_ELEMENTS', data: lockedElements });
    return Promise.resolve();
};

export const setFormulasResults = (formulasResults) => async (dispatch, getState) => {
    dispatch({ type: 'SET_FORMULAS_RESULTS', data: formulasResults });
    return Promise.resolve();
};