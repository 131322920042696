import React, { Component } from 'react';
// Composants
import { HorizontalBar } from 'react-chartjs-2';
import DropDownWithCheckboxes from '../Utils/DropDownWithCheckboxes';
import DatePickerWithPeriod from '../Utils/DatePickerWithPeriod';
// Librairies
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import { faShapes } from '@fortawesome/pro-solid-svg-icons';
import { addDays, addMonths, addWeeks, addYears, endOfDay, endOfMonth, endOfWeek, endOfYear, format, lastDayOfMonth, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../locales/i18n';
// Utils
import DatesUtil from '../../utils/DatesUtil';

class ActionsChart extends Component {
    state = {
        data: null,
        options: null,
        period: 'month',
        startDate: null,
        endDate: null,
        elements: ['trees', 'greenspaces', 'furnitures']
    }

    render() {
        const { data, options, period, startDate, endDate, elements } = this.state;

        return (
            <div className='modal-content'>
                <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center' }}>
                    <DatePickerWithPeriod period={period} startDate={startDate} endDate={endDate} forceTime={true} setDates={this.setDates} setPeriod={this.setPeriod} />
                    <DropDownWithCheckboxes
                        icon={faShapes} label={i18n.t("Éléments")} options={[
                            { label: i18n.t("Arbres"), isChecked: elements.includes('trees'), onClick: () => this.toggleElementsSelection('trees') },
                            { label: i18n.t("Espaces verts"), isChecked: elements.includes('greenspaces'), onClick: () => this.toggleElementsSelection('greenspaces') },
                            { label: i18n.t("Mobilier urbain"), isChecked: elements.includes('furnitures'), onClick: () => this.toggleElementsSelection('furnitures') }
                        ]} style={{ marginLeft: isMobileOnly && '5px' }}
                    />
                </div>
                <div className='modal-content-body'>
                    {data && options
                        ? <HorizontalBar data={data} options={options} />
                        : i18n.t("Aucune donnée à afficher")}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.arrowClick = false;
        this.updateChart();
    }

    setDates = (startDate, endDate, arrowClick = false) => {
        this.arrowClick = arrowClick;
        if (!startDate || !endDate) this.setState({ startDate, endDate });
        else this.updateChart(startDate, endDate, this.state.period);
    }

    setPeriod = (period) => {
        this.setState({ period }, () => {
            const { startDate, endDate } = this.state;
            if (startDate && endDate) this.updateChart(startDate, endDate, period);
        });
    }

    toggleElementsSelection = (element) => {
        let { startDate, endDate, period, elements } = this.state;
        if (elements.includes(element)) elements = elements.filter(e => e !== element);
        else elements.push(element);
        this.setState({ elements }, () => this.updateChart(startDate, endDate, period));
    }

    updateChart = (startDate, endDate, period = 'month') => {
        let { elements } = this.state;
        const themeColor = this.props.isDarkTheme ? 'white' : 'black';
        let periods = [];

        startDate = startDate || startOfDay(new Date());
        if (!endDate) {
            endDate = addMonths(startDate, 11);
            endDate = lastDayOfMonth(endDate);
        }
        endDate = endOfDay(endDate);

        // Définition des périodes
        switch (period) {
            case 'day':
                periods.push({ label: format(startDate, 'dd/MM/yy'), startPeriod: startDate, endPeriod: endOfDay(startDate) });
                const nbDays = DatesUtil.daysBetweenTwoDates(endDate, startDate);
                for (let i = 1; i <= nbDays; i++) {
                    const startPeriod = startOfDay(addDays(startDate, i));
                    const endPeriod = i < nbDays ? endOfDay(startPeriod) : endDate;
                    periods.push({ label: format(startPeriod, 'dd/MM/yy'), startPeriod, endPeriod });
                }
                break;
            case 'week':
                const nbWeeks = DatesUtil.weeksBetweenTwoDates(endDate, startDate);
                periods.push({ label: `${format(startDate, 'dd/MM/yy')} au ${format(nbWeeks ? endOfWeek(startDate, { weekStartsOn: 1 }) : endDate, 'dd/MM/yy')}`, startPeriod: startDate, endPeriod: nbWeeks ? endOfWeek(startDate, { weekStartsOn: 1 }) : endDate });
                for (let i = 1; i <= nbWeeks; i++) {
                    const startPeriod = startOfWeek(addWeeks(startDate, i), { weekStartsOn: 1 });
                    const endPeriod = i < nbWeeks ? endOfWeek(startPeriod, { weekStartsOn: 1 }) : endDate;
                    periods.push({ label: `${format(startPeriod, 'dd/MM/yy')} au ${format(endPeriod, 'dd/MM/yy')}`, startPeriod, endPeriod });
                }
                break;
            case 'month':
                const nbMonths = DatesUtil.monthsBetweenTwoDates(endDate, startDate);
                periods.push({ label: format(startDate, 'MM/yyyy'), startPeriod: startDate, endPeriod: nbMonths ? endOfMonth(startDate) : endDate });
                for (let i = 1; i <= nbMonths; i++) {
                    const startPeriod = startOfMonth(addMonths(startDate, i));
                    const endPeriod = i < nbMonths ? endOfMonth(startPeriod) : endDate;
                    periods.push({ label: format(startPeriod, 'MM/yyyy'), startPeriod, endPeriod });
                }
                break;
            case 'year':
                const nbYears = DatesUtil.yearsBetweenTwoDates(endDate, startDate);
                periods.push({ label: format(startDate, 'yyyy'), startPeriod: startDate, endPeriod: nbYears ? endOfYear(startDate) : endDate });
                for (let i = 1; i <= nbYears; i++) {
                    const startPeriod = startOfYear(addYears(startDate, i));
                    const endPeriod = i < nbYears ? endOfYear(startPeriod) : endDate;
                    periods.push({ label: format(startPeriod, 'yyyy'), startPeriod, endPeriod });
                }
                break;
            default:
                break;
        }

        // Création des datasets
        let datasets = [];
        const projectActions = this.props.projectActions.filter(projectAction => (
            (projectAction.action.categories.includes('Arbre') && elements.includes('trees'))
            || (projectAction.action.categories.includes('Espace vert') && elements.includes('greenspaces'))
            || (projectAction.action.categories.includes('Mobilier') && elements.includes('furnitures'))
        ));
        projectActions.forEach(projectAction => {
            if (projectAction.projectActionElements.length) {
                projectAction.projectActionElements.forEach(pae => {
                    pae.projectActionElementRecurrences.forEach(paer => {
                        let dataset = datasets.find(d => d.label === projectAction.action.label) || {
                            label: projectAction.action.label,
                            backgroundColor: this.props.actions.find(action => action.id === projectAction.action.id).color,
                            borderColor: 'rgba(0, 0, 0, 1)',
                            borderWidth: 1,
                            data: periods.map(() => 0)
                        };

                        for (let i = 0; i < periods.length; i++) {
                            const period = periods[i];
                            const paerDate = DatesUtil.convertUTCDateToDate(paer.date);

                            if (paerDate.getTime() >= period.startPeriod.getTime() && paerDate.getTime() <= period.endPeriod.getTime()) {
                                dataset.data[i]++;
                                break;
                            }
                        };

                        if (!datasets.find(d => d.label === projectAction.action.label)) datasets.push(dataset);
                    });
                });
            }
        });

        const data = { labels: periods.map(period => period.label), datasets: datasets.filter(dataset => dataset.data.find(value => value)) };
        let options = {
            animation: { duration: this.arrowClick ? 0 : 1000 },
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: { fontColor: themeColor },
                    scaleLabel: { display: true, labelString: i18n.t("Nombre de récurrences"), fontColor: themeColor }
                }],
                yAxes: [{
                    stacked: true,
                    ticks: { beginAtZero: true, min: 0, fontColor: themeColor },
                    scaleLabel: { display: true, labelString: i18n.t("Périodes"), fontColor: themeColor }
                }]
            },
            legend: {
                position: isMobileOnly ? 'bottom' : 'right',
                labels: { fontColor: themeColor }
            },
            tooltips: { filter: (tooltipItem, data) => data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] ? true : false },
            layout: { padding: { top: 20 } },
            plugins: {
                labels: { render: 'value', showZero: false, fontColor: themeColor }
            }
        };

        this.setState({ startDate, endDate, data, options });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        actions: state.actions,
        projectActions: state.projectActions
    };
};

export default connect(mapStateToProps)(ActionsChart);