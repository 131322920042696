export default class FurnituresUtil {
    static getFields(isPublicFields) {
        let fields = {
            projectReference: true,
            actions: true,
            longitude: true,
            latitude: true,
            place: true,
            customReference: true,
            tags: true,
            description: true,
            condition: true,
            type: true
        };

        if (isPublicFields) {
            delete fields.projectReference;
            delete fields.customReference;
            delete fields.actions;
        }

        return fields;
    }
}
