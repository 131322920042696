import React, { Component } from 'react';
// Composants
import Dropzone from 'react-dropzone';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
// Services
import StylesUtil from '../../utils/StylesUtil';

class ImportElementsDropZone extends Component {
    state = {
        maxItems: 4,
        files: [],
        dropZone: {
            isDragAccept: false,
            isDragActive: false,
            isDragReject: false,
            isTooManyItems: false,
            message: ''
        }
    }

    render() {
        const { format } = this.props;
        const { dropZone, maxItems } = this.state;
        let dropzoneStyle = StylesUtil.getDropZoneStyle(dropZone);
        dropzoneStyle.height = '100%';
        dropzoneStyle.justifyContent = 'center';
        if (!this.props.isOnline) dropzoneStyle.borderColor = 'grey';
        const tooManyItemsString = maxItems === 1
            ? i18n.t("Vous ne pouvez pas mettre plus de 1 fichier")
            : i18n.t("Vous ne pouvez pas mettre plus de {{count}} fichiers", { count: maxItems });
        const extensions = format === 'excel' ? ['.xls', '.xlsx'] : ['.shp', '.shx', '.dbf'];
        const extensionsLabel = format === 'excel' ? '(.xls ou .xlsx)' : '(.shp, .shx et .dbf)';
        const label = this.props.format === 'excel'
            ? `${i18n.t("Glissez-déposez ou cliquez pour importer un fichier")} ${extensionsLabel}`
            : `${i18n.t("Glissez-déposez ou cliquez pour importer des fichiers")} ${extensionsLabel}`;

        return (
            <>
                <Dropzone onDrop={this.handleDrop} accept={{ '': extensions }} useFsAccessApi={false} disabled={!this.props.isOnline}>
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ flex: 1 }}>
                            <div {...getRootProps()} style={dropzoneStyle}>
                                <input {...getInputProps()} />
                                {dropZone.isDragAccept && (<p>{dropZone.message}</p>)}
                                {dropZone.isDragReject && (<p>{dropZone.message}</p>)}
                                {dropZone.isTooManyItems && (<p>{tooManyItemsString}</p>)}
                                <p style={{ margin: 0 }}>{label}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </>
        )
    }

    componentDidMount = () => {
        const { format, files } = this.props;
        if (format === 'excel') this.setState({ maxItems: 1 });
        this.setState({ files: files || [] });
    }

    componentDidUpdate = (prevProps) => {
        const { format, files } = this.props;
        if (prevProps.format !== format) {
            if (prevProps.format !== format) {
                this.props.handleConfigChange(null, { name: 'files', value: [] });
                this.setState({ files: [] });
            }

            this.setState({ maxItems: format === 'excel' ? 1 : 4 });
        }

        if (prevProps.files && files && (prevProps.files !== files || prevProps.files.length !== files.length))
            this.setState(prevState => ({ files: files, dropZone: { ...prevState.dropZone, isDragReject: format === 'shapefiles' && files.length !== 3, message: format === 'shapefiles' ? this.getMissingShapeFilesMessage(files) : '' } }));
    }

    handleDrop = async (files) => {
        if (!files.length) return;
        const { format } = this.props;
        let isValid = true;

        let newFiles = this.state.files;
        if (format === 'excel') {
            const excelFile = files.find(file => file.name.endsWith('.xls') || file.name.endsWith('.xlsx'));
            isValid = excelFile ? true : false;
            newFiles = files;
        } else {
            newFiles = [...newFiles.filter(newFile => !files.find(file => file.name === newFile.name)), ...files];
            isValid = newFiles.length === 3;
        }


        if (isValid && newFiles.length <= this.state.maxItems) {
            this.props.handleConfigChange(null, { name: 'files', value: newFiles });
            let dropZone = {
                isDragAccept: false,
                isDragReject: false,
                message: 'ok'
            };
            this.setState({
                files: newFiles,
                dropZone: {
                    isDragAccept: dropZone.isDragAccept,
                    isDragActive: true,
                    isDragReject: dropZone.isDragReject,
                    isTooManyItems: false,
                    message: dropZone.message
                }
            });
            return;
        } else if (!isValid && newFiles.length <= this.state.maxItems) {
            let message = '';
            if (format === 'shapefiles') message = this.getMissingShapeFilesMessage(newFiles);

            this.props.handleConfigChange(null, { name: 'files', value: newFiles });
            this.setState(prevState => ({
                files: newFiles,
                dropZone: {
                    ...prevState.dropZone,
                    isDragAccept: false,
                    isDragReject: true,
                    isDragActive: true,
                    isTooManyItems: false,
                    message
                }
            }));
        } else {
            this.setState(prevState => ({
                dropZone: {
                    ...prevState.dropZone,
                    isDragAccept: false,
                    isDragReject: false,
                    isDragActive: true,
                    isTooManyItems: true
                }
            }));
        }
    }

    getMissingShapeFilesMessage = (files) => {
        if (!files?.length) return '';
        const shapefilesExtensions = ['.shp', '.shx', '.dbf'];
        const missingFiles = shapefilesExtensions.filter(extension => !files.find(file => file.name.endsWith(extension)));
        return files.length > 1
            ? i18n.t("Vous devez fournir le fichier {{missingFile}} en plus des fichiers {{providedFiles}}", { missingFile: missingFiles[0], providedFiles: files.map(file => '.' + file.name.split('.')[1]).join(', ') })
            : i18n.t("Vous devez fournir les fichiers {{missingFiles}} en plus du fichier {{providedFile}}", { missingFiles: missingFiles.join(', '), providedFile: '.' + files[0].name.split('.')[1] });
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline
    };
};

export default connect(mapStateToProps)(ImportElementsDropZone);