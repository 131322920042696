import React, { Component } from 'react';
// Librairies
import { format, startOfDay, subYears } from 'date-fns';
import { connect } from 'react-redux';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

class NumberEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        startValue: 0,
        endValue: 0
    };

    render() {
        const { row, column, onRowChange } = this.props;

        return (
            <input
                className='rdg-text-editor' ref={this.autoFocusAndSelect}
                value={row[column.key] || row[column.key] === 0 ? row[column.key] : ''}
                type='number' step={column.key === 'age' ? '1' : '0.1'} min={0}
                onChange={e => this.handleNumberChange(e, row, column, onRowChange)} onBlur={this.handleBlur}
            />
        );
    }

    componentDidMount = () => this.setState({
        startValue: ['nbTrees', 'density', 'distanceBetweenTrunks'].includes(this.props.column.key)
            ? { nbTrees: this.props.row.nbTrees, density: this.props.row.density, distanceBetweenTrunks: this.props.row.distanceBetweenTrunks }
            : this.props.column.key === 'age'
                ? { age: this.props.row.age, plantingDate: this.props.row.plantingDate }
                : this.props.row[this.props.column.key]
    });

    componentDidUpdate = () => { // Permet de mettre à jour la startValue lorsqu'on modifie le champs lié
        const startValue = ['nbTrees', 'density', 'distanceBetweenTrunks'].includes(this.props.column.key)
            ? { nbTrees: this.props.row.nbTrees, density: this.props.row.density, distanceBetweenTrunks: this.props.row.distanceBetweenTrunks }
            : this.props.column.key === 'age'
                ? { age: this.props.row.age, plantingDate: this.props.row.plantingDate }
                : this.props.row[this.props.column.key];
        if (this.inputRef.current && document.activeElement !== this.inputRef.current && (
            typeof this.state.startValue === 'object'
                ? JSON.stringify(this.state.startValue) !== JSON.stringify(startValue)
                : this.state.startValue !== this.props.row[this.props.column.key]
        ))
            this.setState({ startValue });
    }

    componentWillUnmount = () => { if (!this.blurred) this.handleBlur(); } // L'event blur n'est pas déclenché lorsqu'on appuie sur enter/tab

    handleBlur = () => {
        const { startValue, endValue } = this.state;
        const { row, column, onClose, pushToModificationsHistory } = this.props;

        if (endValue && (typeof endValue === 'object' ? JSON.stringify(startValue) !== JSON.stringify(endValue) : startValue !== endValue)) {
            if (startValue && typeof startValue === 'object') {
                pushToModificationsHistory(
                    Object.keys(startValue).map(property => ({
                        elementId: row.elementId, property, oldValue: startValue[property], customField: column.customField
                    }))
                );
            } else pushToModificationsHistory([
                { elementId: row.elementId, property: column.key, oldValue: startValue, customField: column.customField }
            ]);
            this.setState({ startValue: endValue });
        }
        onClose(true);
        this.blurred = true;
        setTimeout(() => this.blurred = false, 100);
    }

    handleNumberChange = (e, row, column, onRowChange) => {
        let elementsToModify = this.props.elementsToModify;
        const index = elementsToModify.findIndex(element => element.id === row.elementId);
        const feature = index === -1
            ? JSON.parse(JSON.stringify(this.props.elements[row.id].feature))
            : elementsToModify[index];

        const value = column.customField ? e.target.value : Number(e.target.value);
        if (column.key === 'trunkHeight')
            feature.properties.dimensions[column.key] = Number(e.target.value * 100);
        else if (column.key === 'circumference')
            feature.properties[column.key] = FormattersUtil.getTrunkCircumference(e.target.value, 'circumference', this.props.project?.trunkCircumferenceUnit);
        else if (['averageHeight', 'averageCircumference', 'averageCrownDiameter'].includes(column.key))
            feature.properties[column.key] = Number(['averageHeight', 'averageCrownDiameter'].includes(column.key) ? e.target.value * 100 : e.target.value);
        else if (column.key === 'age') {
            if (e.target.value === '') {
                feature.properties[column.key] = null;
                feature.properties.plantingDate = null;
            } else feature.properties.plantingDate = subYears(startOfDay(new Date()), value);
        } else if (column.customField) this.props.updateElementCustomFields(feature, column.key, value);
        else feature.properties[column.key] = value;
        if (['nbTrees', 'density', 'distanceBetweenTrunks'].includes(column.key)) {
            if (column.key !== 'nbTrees')
                feature.properties.nbTrees = column.key === 'density'
                    ? feature.properties.density / 10000 * feature.properties.surface
                    : feature.properties.surface / Math.pow(feature.properties.distanceBetweenTrunks, 2);
            if (column.key !== 'density') feature.properties.density = feature.properties.nbTrees / feature.properties.surface * 10000;
            if (column.key !== 'distanceBetweenTrunks') feature.properties.distanceBetweenTrunks = Math.sqrt(feature.properties.surface / feature.properties.nbTrees);

            feature.properties.nbTrees = Math.round(feature.properties.nbTrees);
            feature.properties.density = Math.round(feature.properties.density * 100) / 100;
            feature.properties.distanceBetweenTrunks = Math.round(feature.properties.distanceBetweenTrunks * 100) / 100;
        }
        if (index === -1) elementsToModify.push(feature);

        this.setState({
            endValue: ['nbTrees', 'density', 'distanceBetweenTrunks'].includes(column.key)
                ? { nbTrees: feature.properties.nbTrees, density: feature.properties.density, distanceBetweenTrunks: feature.properties.distanceBetweenTrunks }
                : column.key === 'age'
                    ? { age: feature.properties.age, plantingDate: feature.properties.plantingDate }
                    : value
        });
        this.props.changeElementsToModify(elementsToModify);
        const newRow = { ...row, [column.key]: column.key === 'age' ? (e.target.value === '' ? null : value) : value };
        if (['nbTrees', 'density', 'distanceBetweenTrunks'].includes(column.key)) {
            newRow.nbTrees = feature.properties.nbTrees;
            newRow.density = feature.properties.density;
            newRow.distanceBetweenTrunks = feature.properties.distanceBetweenTrunks;
        } else if (column.key === 'age')
            newRow.plantingDate = e.target.value !== '' ? format(feature.properties.plantingDate, 'dd/MM/yyyy') : '';
        this.props.updateSelectedRow(newRow);
        onRowChange(newRow);
    }

    autoFocusAndSelect = (input) => {
        if (input) {
            input.focus();
            input.select();
            this.inputRef.current = input;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
    };
};

export default connect(mapStateToProps)(NumberEditor);