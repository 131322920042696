import React, { Component } from 'react';
// Components
import { Form, Select } from 'semantic-ui-react';
import InfoIcon from '../Utils/InfoIcon';
// Librairies
import { stringSimilarity } from "string-similarity-js";
import i18n from '../../locales/i18n';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';

const NB_MAX_RESULTS = 25;

class ImprovedSearch extends Component {
    state = {
        isSearchLoading: false,
        searchQuery: '',
        searchResult: []
    };

    render() {
        const { label, placeholder, isRequired, selectedValue, value, error, handleChange, style } = this.props;
        const { isSearchLoading, searchQuery, searchResult } = this.state;

        return (
            <div style={{ ...(style || {}) }}>
                {label?.length > 0 && <label>
                    {label}
                    {isRequired && '*'}
                    <InfoIcon content={i18n.t("La recherche affiche les {{count}} meilleurs résultats", { count: NB_MAX_RESULTS })} iconStyle={{ margin: '0 3px' }} />
                    :
                </label>}
                <Form.Field
                    control={Select}
                    id='KAOQzi4L' placeholder={placeholder}
                    options={selectedValue ? [selectedValue] : []} value={value || ''}
                    search={() => !isSearchLoading ? searchResult : []} onSearchChange={this.handleSearchChange}
                    noResultsMessage={isSearchLoading ? i18n.t("Recherche en cours...") : searchQuery.length < 3 ? i18n.t("Saisissez au moins {{count}} caractère", { count: 3 }) : !searchResult.length ? i18n.t("Aucun résultat trouvé") : 'error'}
                    selectOnBlur={false} selectOnNavigation={false} loading={isSearchLoading}
                    error={error} onChange={handleChange}
                />
            </div>
        );
    }

    componentDidMount = () => this.searchTimeout = null;
    componentWillUnmount = () => {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
    }

    search = (searchQuery = '') => {
        const { values } = this.props;
        let results = [];
        if (isNaN(searchQuery) && searchQuery.length > 2) {
            const queryTerms = FormattersUtil.getNormalizedString(searchQuery, { spaceBetweenWords: true }).split(/(?:,| )+/);

            let containsPerfectMatches = false;
            results = values.map(v => {
                let matchscore = 0;
                let isPerfectMatch = false;
                let includes = false;
                if (v.searchparams) {
                    const valueParams = v.searchparams.map(param => param.toLowerCase());
                    const matchScores = queryTerms.map(term => {
                        let matchMultiplier = 1;
                        const paramScores = valueParams.map((param, index) => {
                            const similarityScore = stringSimilarity(term, param);
                            const secondScore = (param.startsWith(term) ? 0.25 : 0) * matchMultiplier;
                            if (secondScore) matchMultiplier++;

                            if (term === param) {
                                isPerfectMatch = true;
                                containsPerfectMatches = true;
                            }

                            if (term.includes(param) || param.includes(term)) includes = true;
                            return (similarityScore + secondScore) / (index + 1);
                        });

                        return Math.max(...paramScores);
                    });

                    matchscore = matchScores.reduce((sum, score) => sum + score, 0) / matchScores.length;
                }

                return { ...v, matchscore, isPerfectMatch, includes };
            });

            results = !containsPerfectMatches
                ? results.filter(result => result.matchscore >= 0.5)
                : results.filter(result => result.isPerfectMatch || result.includes);
            results.sort((a, b) => b.matchscore - a.matchscore);
        } else if (!isNaN(searchQuery)) {
            results = values.map(v => {
                const valueParams = v.searchparams.map(param => param.toLowerCase());
                return valueParams.find(param => !isNaN(param) && param === searchQuery.trim()) ? v : null;
            });

            results = results.filter(result => result);
        }

        return results.slice(0, NB_MAX_RESULTS);
    }

    handleSearchChange = (_, { searchQuery }) => {
        this.setState({ isSearchLoading: false, searchQuery });
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            if (searchQuery?.length < 3) this.setState({ searchResult: [] });
            else this.setState({ isSearchLoading: true }, () => {
                const searchResult = this.search(searchQuery);
                this.searchTimeout = setTimeout(() => this.setState({ searchQuery: '', searchResult, isSearchLoading: false }), 2000);
            });
        }, 500);
    }
}

export default ImprovedSearch;