// Librairies
import { differenceInCalendarDays, differenceInCalendarMonths, differenceInCalendarWeeks, differenceInCalendarYears } from 'date-fns';
import { DateTime } from 'luxon';

export default class DatesUtil {
    static convertDateStringToDate(input) {
        if (!input || (!DateTime.fromFormat(input, 'dd/MM/yyyy').isValid && !DateTime.fromFormat(input, 'dd/MM/yyyy, hh:mm:ss').isValid)) return;
        input = input.includes(',') ? input.split(',') : [input];
        let parts = input[0].split(input[0].includes('-') ? '-' : '/');
        if (parts[2].length > 2) parts.reverse();
        return new Date(parts.join('/') + (input[1] ? `,${input[1]}` : ''));
    }

    static convertUTCDateToLocaleDate(input) {
        return this.convertUTCDateToDate(input).toLocaleString();
    }

    static convertUTCDateToDate(input) {
        if (!input || typeof input !== 'string') return input;
        if (!input.toUpperCase().includes('Z') && !isNaN(new Date(input + 'Z').getTime())) input += 'Z';
        let date = new Date(input);
        date.setMilliseconds(999);
        return date;
    }

    static getFormattedLocaleDateString(input, fullDate = false) {
        if (!input) return;
        const localeDate = this.convertUTCDateToDate(input);
        const day = localeDate.getDate() > 9 ? localeDate.getDate() : '0' + localeDate.getDate();
        const month = localeDate.getMonth() + 1 > 9 ? localeDate.getMonth() + 1 : '0' + (localeDate.getMonth() + 1);
        const year = localeDate.getFullYear();
        let formattedLocaleDate = `${day}/${month}/${year}`;
        if (fullDate) {
            const hours = localeDate.getHours() > 9 ? localeDate.getHours() : '0' + localeDate.getHours();
            const minutes = localeDate.getMinutes() > 9 ? localeDate.getMinutes() : '0' + localeDate.getMinutes();
            formattedLocaleDate += `, ${hours}:${minutes}`
        }
        return formattedLocaleDate;
    }

    static getFormattedLocaleTimeString(input) {
        if (!input) return;
        const localeDate = this.convertUTCDateToDate(input);
        const hour = localeDate.getHours() >= 10 ? localeDate.getHours() : '0' + localeDate.getHours();
        const minute = localeDate.getMinutes() >= 10 ? localeDate.getMinutes() : '0' + localeDate.getMinutes();
        const second = localeDate.getSeconds() >= 10 ? localeDate.getSeconds() : '0' + localeDate.getSeconds();
        return hour + ':' + minute + ':' + second;
    }

    static addMonths(date, months) {
        const d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() !== d) date.setDate(0);
        return date;
    }

    static daysBetweenTwoDates(date1, date2) {
        return differenceInCalendarDays(date1, date2);
    }

    static weeksBetweenTwoDates(date1, date2) {
        return differenceInCalendarWeeks(date1, date2);
    }

    static monthsBetweenTwoDates(date1, date2) {
        return differenceInCalendarMonths(date1, date2);
    }

    static yearsBetweenTwoDates(date1, date2) {
        return differenceInCalendarYears(date1, date2);
    }

    static getToday() {
        return new Date(new Date().setHours(23, 59, 59, 999));
    }

    static getUTCDate(date) {
        date = date || new Date();
        if (typeof date === 'string') date = new Date(date);
        const utcTimestamp = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getMilliseconds());
        return new Date(utcTimestamp);
    }

    static getMonday = (d) => {
        const date = new Date(d);
        const day = date.getDay()
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    static isISOString = (str) => {
        if (typeof (str) !== 'string') return false;
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        const d = new Date(str);
        return !isNaN(d.getTime()) && d.toISOString() === str;
    }
}
